import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import DoctorReviewsForm from "./DoctorReviewsForm";
import PatientReviewForm from "./PatientReviewForm";
import { formatDateDMY } from "../../util/Helper";

const ReviewsTabs = ({ selectedReport, onRefresh, setIsReviewFormOpen, isDummyData }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  return (
    <div className="review-form-tabs">
      <div className="review-content-wrapper">
        <p>Test Report ID : {selectedReport?.id}</p>
        <p>Visit ID : {selectedReport?.visitId}</p>
        <p>Doctor Review Date : {formatDateDMY(selectedReport.doctorReviewDate)}</p>
        <p>Patient Action Date : {formatDateDMY(selectedReport?.patientReviewDate)}</p>
      </div>
      <Tabs
        value={currentTabIndex}
        onChange={handleChange}
        variant="standard"
        textColor="primary"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        <Tab
          label="Doctor Reviews"
          sx={{
            padding: "12px",
            minHeight: "24px",
            borderRadius: "5px",
            "&.Mui-selected": {
              color: "#5353e9",
              border: "1px solid #1111119c",
            },
          }}
        />
        <Tab
          label="Patient Action"
          disabled={!selectedReport?.doctorReviewFlag}
          sx={{
            borderRadius: "5px",
            padding: "12px",
            minHeight: "24px",
            "&.Mui-selected": {
              color: "#5353e9",
              border: "1px solid #1111119c",
            },
          }}
        />
      </Tabs>
      {currentTabIndex === 0 && (
        <Box sx={{ marginTop: "10px" }}>
          <DoctorReviewsForm
            selectedReport={selectedReport}
            onRefresh={onRefresh}
            setIsReviewFormOpen={setIsReviewFormOpen}
            isDummyData={isDummyData}
          />
        </Box>
      )}
      {currentTabIndex === 1 && (
        <Box sx={{ marginTop: "10px" }}>
          <PatientReviewForm
            selectedReport={selectedReport}
            onRefresh={onRefresh}
            setIsReviewFormOpen={setIsReviewFormOpen}
            isDummyData={isDummyData}
          />
        </Box>
      )}
      <div>
        <Box sx={{ marginTop: "10px", border: "1px solid grey", width: "35%", borderRadius:"10px", padding:"10px" }}>
          <p>The following test results are not normal in this test reports: <br/>
            Lipid HDL 36 should be {">"} 40<br/>
            All other test results are normal.
          </p>
        </Box>
      </div>
    </div>
  );
};

export default ReviewsTabs;
