import React from "react";
import "./PaymentHistory.css";

export const PaymentHistory = ({onClose}) => {
  return (
    <div className="payment-history">
      <div className="div-8">
        <div className="heading">
          <span className="text-wrapper-59">Dec 20, 2023</span>
        </div>
        
        <div style={{padding: "0px 20px 20px 20px"}}>
          <div className="overlap-17">
            <div className="amount-rs">
              <span className="text-wrapper-53">Amount: Rs 1251</span>
            </div>
            <div className="paid-on">
              <span className="text-wrapper-54">Paid on</span>
            </div>
            <div style={{display : "flex" , alignItems : "center"}}>
              <img className="calendar" alt="Calendar" src="/img/calendar-2.png" />
              <div className="span-wrapper-9">
                <span className="text-wrapper-53">Dec 20, 2023 10:20 PM</span>
              </div>
              
            </div>
            <div style={{border: "1px solid #E3E3F2" , margin: "5px 0px 5px 0px"}}></div>
            <div style={{display : "flex" , justifyContent : "space-between" , alignItems : "center"}}>
              <div className="payment-mode">
                <span className="text-wrapper-55">Payment Mode: </span>
                <span className="text-wrapper-56">Netbanking</span>
              </div>
              <div className="ref-no">
                <span className="text-wrapper-57">Ref No:</span>
                <span className="text-wrapper-58"> PT23089BUK23</span>
              </div>
            </div>
          </div>
        </div>

        <div className="heading-2">
          <span className="text-wrapper-59">Nov 22, 2023</span>
        </div>


        <div style={{padding: "0px 20px 20px 20px"}}>
          <div className="overlap-17">
            <div className="amount-rs">
              <span className="text-wrapper-53">Amount: Rs 1251</span>
            </div>
            <div className="paid-on">
              <span className="text-wrapper-54">Paid on</span>
            </div>
            <div style={{display : "flex" , alignItems : "center"}}>
              <img className="calendar" alt="Calendar" src="/img/calendar-2.png" />
              <div className="span-wrapper-9">
                <span className="text-wrapper-53">Nov 22, 2023 11:25 AM</span>
            </div>
              
            </div>
            <div style={{border: "1px solid #E3E3F2" , margin: "5px 0px 5px 0px"}}></div>
            <div style={{display : "flex" , justifyContent : "space-between" , alignItems : "center"}}>
              <div className="payment-mode">
                <span className="text-wrapper-55">Payment Mode: </span>
                <span className="text-wrapper-56">Netbanking</span>
              </div>
              <div className="ref-no">
                <span className="text-wrapper-57">Ref No:</span>
                <span className="text-wrapper-58"> PT23089BUK23</span>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  );
};
