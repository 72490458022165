/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconSettings = ({ style, iconSettings = "/img/icon-settings-1.png" }) => {
  return <img className="icon-settings" style={style} alt="Icon settings" src={iconSettings} />;
};

IconSettings.propTypes = {
  iconSettings: PropTypes.string,
};
