export default Notification =  [
    {
      "id": "n1",
      "senderName": "Pankaj Suthar",
      "avatar": "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
      "whatFor": "Resources_Product_Growth_Org Design.paper",
      "whatType": "invited",
      "receivedTime": "2 weeks ago",
      "read": true
    },
    {
      "id": "n2",
      "senderName": "Emily Johnson",
      "avatar": "https://images.unsplash.com/photo-1606810386934-5864ea0f2e64?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Marketing Campaign Proposal",
      "whatType": "requested",
      "receivedTime": "5 days ago",
      "read": false
    },
    {
      "id": "n3",
      "senderName": "Alex Smith",
      "avatar": "https://images.unsplash.com/photo-1595432042887-970e91686422?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Quarterly Sales Report",
      "whatType": "shared",
      "receivedTime": "1 month ago",
      "read": true
    },
    {
      "id": "n4",
      "senderName": "Jessica Miller",
      "avatar": "https://images.unsplash.com/photo-1628744238051-b0951f165a0e?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "New Product Concept",
      "whatType": "invited",
      "receivedTime": "2 days ago",
      "read": false
    },
    {
      "id": "n5",
      "senderName": "Michael Brown",
      "avatar": "https://images.unsplash.com/photo-1624478643257-e8e7f7bfec9b?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Monthly Financial Report",
      "whatType": "shared",
      "receivedTime": "3 weeks ago",
      "read": true
    },
    {
      "id": "n6",
      "senderName": "Sara Lee",
      "avatar": "https://images.unsplash.com/photo-1622407407267-d7d23c40f7db?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Team Building Event",
      "whatType": "requested",
      "receivedTime": "4 days ago",
      "read": true
    },
    {
      "id": "n7",
      "senderName": "John Anderson",
      "avatar": "https://images.unsplash.com/photo-1630512302010-0db9bb9ad566?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Client Presentation",
      "whatType": "invited",
      "receivedTime": "1 week ago",
      "read": false
    },
    {
      "id": "n8",
      "senderName": "Laura Martinez",
      "avatar": "https://images.unsplash.com/photo-1600336894192-27e810f5c063?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Product Design Mockup",
      "whatType": "requested",
      "receivedTime": "2 months ago",
      "read": true
    },
    {
      "id": "n9",
      "senderName": "Chris Taylor",
      "avatar": "https://images.unsplash.com/photo-1600880902833-43a1b79f49ad?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Market Research Analysis",
      "whatType": "shared",
      "receivedTime": "6 days ago",
      "read": false
    },
    {
      "id": "n10",
      "senderName": "Sophia Wright",
      "avatar": "https://images.unsplash.com/photo-1599557413777-df6a7e8ee879?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Project Proposal",
      "whatType": "invited",
      "receivedTime": "3 weeks ago",
      "read": true
    },
    {
      "id": "n11",
      "senderName": "David Turner",
      "avatar": "https://images.unsplash.com/photo-1627568307116-ef14e7b784ac?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Budget Planning",
      "whatType": "requested",
      "receivedTime": "4 months ago",
      "read": true
    },
    {
      "id": "n12",
      "senderName": "Olivia Green",
      "avatar": "https://images.unsplash.com/photo-1626159070251-6578b5ad8846?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Product Launch Strategy",
      "whatType": "invited",
      "receivedTime": "1 week ago",
      "read": false
    },
    {
      "id": "n13",
      "senderName": "Daniel Harris",
      "avatar": "https://images.unsplash.com/photo-1595932592447-38d62aa6df4f?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Performance Review",
      "whatType": "shared",
      "receivedTime": "2 months ago",
      "read": true
    },
    {
      "id": "n14",
      "senderName": "Grace Martinez",
      "avatar": "https://images.unsplash.com/photo-1607451920727-1ca84d29bffc?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Website Redesign Ideas",
      "whatType": "requested",
      "receivedTime": "1 week ago",
      "read": false
    },
    {
      "id": "n15",
      "senderName": "Matthew Robinson",
      "avatar": "https://images.unsplash.com/photo-1616605205019-3d7cbb2f3d5e?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Market Trends Report",
      "whatType": "invited",
      "receivedTime": "2 weeks ago",
      "read": true
    },
    {
      "id": "n16",
      "senderName": "Emma Carter",
      "avatar": "https://images.unsplash.com/photo-1626331532839-8be9a8065f05?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Project Status Update",
      "whatType": "shared",
      "receivedTime": "3 days ago",
      "read": false
    },
    {
      "id": "n17",
      "senderName": "Christopher White",
      "avatar": "https://images.unsplash.com/photo-1601563530172-202b05ae4a42?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Business Expansion Plan",
      "whatType": "requested",
      "receivedTime": "2 months ago",
      "read": true
    },
    {
      "id": "n18",
      "senderName": "Ava Clark",
      "avatar": "https://images.unsplash.com/photo-1595505305966-1a856c920ea4?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Employee Satisfaction Survey",
      "whatType": "invited",
      "receivedTime": "3 weeks ago",
      "read": false
    },
    {
      "id": "n19",
      "senderName": "James Johnson",
      "avatar": "https://images.unsplash.com/photo-1618373929763-c3a42e5cc945?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "Financial Projections",
      "whatType": "shared",
      "receivedTime": "4 months ago",
      "read": true
    },
    {
      "id": "n20",
      "senderName": "Sophie Smith",
      "avatar": "https://images.unsplash.com/photo-1623509487269-8d453a1aabb0?ixid=MnwxMjA3fDB8MHx0",
      "whatFor": "New Product Launch Strategy",
      "whatType": "requested",
      "receivedTime": "1 week ago",
      "read": false
    }
  ]