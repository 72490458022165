// const CorporateInvoice = () => {
//   return <div>Invoice</div>;
// };

// export default CorporateInvoice;

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
// import OrderDetailModel from "./OrderDetailModel";
import AppLogo from "../../../static/img/AppLogoWithName.png";
// import NoOrderImage from "./Images/no-order.jpeg"
import { toast } from "react-toastify";
import CorporateInvoiceModal from "./CorporateInvoiceModal";
import Spinner from "../../shared/Spinner";
// import CorporateOrderDetailModel from "./CorporateOrderDetailModel";
// import "./style.css"

const CorporateInvoice = () => {
  const [corporatemodal, setcorporateModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const Corporatetoggle = () => setcorporateModal(!corporatemodal);
  const [corporateOrder, setcorporateOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCorporateOrderDetail = async () => {
    await AxiosInstance.get("corporate/get-subscription-invoice")
      .then((response) => {
        if (response) {
          setcorporateOrder(response);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error:", err);
        setLoading(false);
      });
  };

  const viewCorporateOrderDetail = (order) => {
    // const order = filteredCorporateOrders.find(
    //   (order) => order.invoiceId === id
    // );
    setSelectedOrder(order);
    setcorporateModal(true);
  };

  useEffect(() => {
    setLoading(true);
    getCorporateOrderDetail();
  }, []);

  const formatDateToYYYYMMDD = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Function to format date to dd-mm-yyyy
  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };

  const filteredCorporateOrders = [
    // {
    //   invoiceId: "1234",
    //   date: "03-04-2024",
    //   time: "11:03",
    //   typeOfClient: "Corporate",
    //   clientName: "Amazon",
    //   clientPhoneNumber: "1234567890",
    //   clientEmail: "amazon@gmail.com",
    //   clientAddress: "Mumbai",
    //   planName: "GBP1",
    //   category: "Invidual/Self",
    //   noOfEmployees: "23",
    //   totalPrice: "43884",
    // },
  ];

  console.log("filess", corporateOrder, selectedOrder);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Invoice</h3>
        {/* <div
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <b>Filter By Date :</b>
          <span style={{ marginLeft: "10px" }}>
            <input
              type="date"
              className="form-control"
              id="dateInput"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </span>
        </div> */}
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you Invoices.</b>
      </p>
      <div>
        <Modal isOpen={corporatemodal} toggle={Corporatetoggle}>
          <ModalHeader
            style={{
              backgroundColor: "black",
              color: "white",
              border: "1px solid lightgrey",
            }}
            toggle={Corporatetoggle}
          >
            <img src={AppLogo} alt="Lab" />
          </ModalHeader>
          <ModalBody>
            {selectedOrder && <CorporateInvoiceModal order={selectedOrder} />}
          </ModalBody>
        </Modal>
      </div>

      {loading ? (
        <Spinner></Spinner>
      ) : corporateOrder?.length > 0 ? (
        corporateOrder?.map((order) => (
          <>
            <TableContainer
              style={{ marginTop: "2%", border: "1px solid black" }}
            >
              <Table key={order.orderId}>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: " rgb(83, 83, 233)",
                      color: "white",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                    }}
                  >
                    <p style={{ fontSize: "18px" }}>
                      <b>Invoice Date :</b>{" "}
                      {order.invoiceDate
                        ? formatDateToDDMMYYYY(order.invoiceDate)
                        : ""}
                    </p>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "rgb(83, 83, 233)",
                      color: "white",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                    }}
                  >
                    <p style={{ fontSize: "18px" }}>
                      <b>Invoice Time :</b> {formatTime(order.invoiceTime)}
                    </p>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: " rgb(83, 83, 233)",
                      color: "white",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                    }}
                  >
                    <p style={{ fontSize: "18px" }}>
                      <b>Client Type :</b> {order.clientType}
                    </p>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{
                        backgroundColor: "whitesmoke",
                        fontWeight: "bolder",
                        textAlign: "center",
                        fontSize: "20px",
                        border: "1px solid lightgrey",
                      }}
                    >
                      Inovice Copy
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "whitesmoke",
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      Invoice Status
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{
                        border: "1px solid lightgrey",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => viewCorporateOrderDetail(order)}
                      >
                        View Invoice Copy
                      </a>
                    </TableCell>
                    <TableCell style={{ border: "1px solid lightgrey" }}>
                      {order.invoiceStatus}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ))
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "x-large",
            fontWeight: 500,
            color: "#c60176",
            margin: "50px",
          }}
        >
          No Invoice Generated Yet
        </div>
      )}
    </>
  );
};
export default CorporateInvoice;
