import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PerformanceTable from "./performanceTable";
import MonthlyPerformance from "./monthlyPerformace";
import { Form, FormGroup, FormFeedback, FormText, Label, Input, Row, Col, Button } from "reactstrap";

const Performance = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (e, tabIndex) => {
      console.log(tabIndex);
      setCurrentTabIndex(tabIndex);
    };
  return (
      <>
      <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }}>
        <Tab
          label="Performance"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 0 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
        <Tab
          label="Monthly Performance"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 1 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
      </Tabs>
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <PerformanceTable></PerformanceTable>
        </Box>
      )}
      {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <MonthlyPerformance></MonthlyPerformance>
        </Box>
      )}
    </>
  );
};
export default Performance;
