/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconSupport = ({ style, iconSupport = "/img/icon-support.png" }) => {
  return <img className="icon-support" style={style} alt="Icon support" src={iconSupport} />;
};

IconSupport.propTypes = {
  iconSupport: PropTypes.string,
};
