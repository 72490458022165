/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconFilledOverview } from "../IconFilledOverview";
import { IconOverview } from "../IconOverview";
import "./NavsTestReports.css";
import { NavLink } from "react-router-dom";

export const NavsTestReports = ({ property1, style, frameStyle, iconFilledOverviewIconFilledOverview = "/img/filledBeaker.png", overviewStyle }) => {
  return (
    <div
      className={`navs-testReports ${property1}`}
      style={style}
    >
      <div className="menu-item">
        <div
          className="frame"
          style={frameStyle}
        >
          <div className="frame-wrapper">
            <div className="div">
              {property1 === "default" && (
                <IconFilledOverview
                  iconFilledOverview={iconFilledOverviewIconFilledOverview}
                />
              )}

              {property1 === "variant-2" && (
                <IconOverview
                  iconOverview="/img/icon-lab-test.png"
                />
              )}

              {/* <div className="overview" style={overviewStyle}>
                Test Reports
              </div> */}

              <div className="component-8">
                <div className="menu-item-8">
                  <div className="frame-49">
                    <div className="frame-50">
                      <div className="frame-51">
                        <NavLink
                          to="/lab-test-records"
                          className="text-wrapper-106 menu-item overview"
                        >
                          MR-Digital Wallet
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsTestReports.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconFilledOverviewIconFilledOverview: PropTypes.string,
};
