import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const PatientTestResultModal = ({ testResultData }) => {
  const parseDesirableValue = (desirableValue) => {
    let minValue = null;
    let maxValue = null;

    const cleanedValue = desirableValue.replace(/,/g, "");

    if (cleanedValue.includes(">")) {
      minValue = parseFloat(cleanedValue.replace(">", "").trim());
    } else if (cleanedValue.includes("<")) {
      maxValue = parseFloat(cleanedValue.replace("<", "").trim());
    } else if (cleanedValue.includes("-")) {
      [minValue, maxValue] = cleanedValue
        .split("-")
        .map((val) => parseFloat(val.trim()));
    } else if (cleanedValue.toLowerCase().includes("to")) {
      [minValue, maxValue] = cleanedValue
        .toLowerCase()
        .split("to")
        .map((val) => parseFloat(val.trim()));
    }
    return { minValue, maxValue };
  };

  const isValueOutOfRange = (value, { minValue, maxValue }) => {
    if (minValue !== null && maxValue === null) {
      return value < minValue;
    }
    if (maxValue !== null && minValue === null) {
      return value > maxValue;
    }
    if (minValue !== null && maxValue !== null) {
      return value < minValue || value > maxValue;
    }
    return false; // default case
  };
  useEffect(() => {
    const hasOutOfRange = testResultData.preventiveTestResult.some((test) => {
      const { minValue, maxValue } = parseDesirableValue(test.desirableValue);
      const value = parseFloat(test.value);
      return isValueOutOfRange(value, { minValue, maxValue });
    });
  }, [testResultData]);

  const groupedTests = testResultData?.preventiveTestResult.reduce(
    (acc, test) => {
      const key = test.whyTheseTestAreDone;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(test);
      return acc;
    },
    {}
  );
  console.log("testResultRowData", testResultData);
  return (
    <div>
      <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
        <Table>
          <TableRow>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Test Name</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Test Value</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Unit</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Interpretation</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Desirable Value</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Why These Tests Are Done ?</b>
              </p>
            </TableCell>
          </TableRow>
          {Object.keys(groupedTests).map((key, index) => {
            const tests = groupedTests[key];

            return (
              <React.Fragment key={index}>
                <TableBody>
                  {tests.map((test, testIndex) => {
                    const { minValue, maxValue } = parseDesirableValue(
                      test.desirableValue
                    );
                    const value = parseFloat(test.value);
                    const isOutOfRange = isValueOutOfRange(value, {
                      minValue,
                      maxValue,
                    });
                    return (
                      <TableRow key={testIndex}>
                        <TableCell
                          style={{
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {test.testName}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            color: isOutOfRange ? "red" : "black",
                          }}
                        >
                          {test.value}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {test.unit}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {test.interpretation}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {test.desirableValue}
                        </TableCell>
                        {testIndex === 0 && (
                          <TableCell
                            rowSpan={tests.length}
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              border: "1px solid lightgrey",
                              fontSize: "16px",
                            }}
                          >
                            {key}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </React.Fragment>
            );
          })}
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientTestResultModal;
