import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis } from "recharts";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getHospitalVisitMinTimeApi } from "../../constants/apiList";
import CustomXAxisTick from "../../shared/CustomXAxisTick";
import { usePagination } from "../../util/utils";

const VisitsMeanTimeGraph = () => {
  const [totalVisits, setTotalVisits] = useState();
  const [highlightedLabel, setHighlightedLabel] = useState(null);
  const [visitData, setVisitData] = useState([]);
  const { currentPage, totalPages, currentData, handleNextPage, handlePreviousPage } = usePagination(visitData, (item = 4));

  const handleAxisLabel = (event) => {
    const selectedValue = event.value;
    const result = visitData?.find((entry) => entry?.visitNo === event?.value);
    if (result) {
      setTotalVisits(result);
      setHighlightedLabel(selectedValue);
    }
  };

  const getHospitalVisitMonthly = async () => {
    AxiosInstance.get(`${getHospitalVisitMinTimeApi}`)
      .then((res) => {
        setVisitData(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (visitData.length > 0) {
      setTotalVisits(visitData[visitData.length - 1]);
      setHighlightedLabel(visitData[visitData.length - 1]?.visitNo);
    }
  }, [visitData]);

  useEffect(() => {
    getHospitalVisitMonthly();
  }, []);

  return (
    <>
      <div className="graph-card-title">How often I'm falling sick?.</div>
      <div className="legend-data-wrapper">
        <div className="legend-data">
          <p>Elapsed Time : {totalVisits?.visitsDistance}</p>
        </div>
      </div>
      <div style={{ margin: "0 auto", width: "100%", display: "flex", alignItems: "end" }}>
        <IconButton
          onClick={handlePreviousPage}
          disabled={currentPage - 1 < 0}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "10px", fontSize: "10px" }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <div className="chart-wrapper">
          <ResponsiveContainer
            width="100%"
            height={150}
          >
            <AreaChart
              data={currentData}
              margin={{
                top: 15,
                right: 26,
                left: 15,
              }}
            >
              <CartesianGrid strokeDasharray="3 5" />
              <XAxis
                dataKey="visitNo"
                interval={"preserveStartEnd"}
                cursor="pointer"
                onClick={handleAxisLabel}
                textAnchor="middle"
                tick={
                  <CustomXAxisTick
                    highlightedLabel={highlightedLabel}
                    boxWidth={25}
                  />
                }
              />
              <Area
                dataKey="visitsDistance"
                strokeWidth={2}
                fill={"lightgreen"}
                label={{ position: "top", fontSize: "12px" }}
                stroke={"#8884d8"}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage + 1 >= totalPages}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "10px", fontSize: "10px" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <div className="x-axis-color-label">
        <p>Hospital Visit Number</p>
      </div>
      <hr className="card-notes-divider" />
      <div className="graph-footer">
        <p className="graph-note">Note: Please click on the number to see the data.</p>
      </div>
    </>
  );
};

export default VisitsMeanTimeGraph;
