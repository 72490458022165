import React from "react";
import "./DoctorScreens.css";

const ConsultantPatients = () => {
  const demoPatient = [
    {
      id: 142,
      recommendation:
        "Please consult your doctor and discuss about your child's risk for CVD considering the family histoy and other Lifestyle risk factos and take appropriate measures as suggested by you doctor.",
      importance:
        "Consideirng age, your child is not at risk for CVD, Howevver it is still important to further assess and validate the Lifestyle risk assessment findings that suggests that your child could be at risk for CVD at a later stage in life. ",
      whyYou:
        "A family history of CVD and several other Lifestyle risk factors suggests your child is at moderate risk for CVD. An early intervention could substantially reduce future risk.",
      preventiveTest: "CVD Risk Assessment (Early Risk Detection)",
      medicalCondition: "CVD-Early",
      lrid: "CVD-02-02",
      patientId: 334,
      dateRecommended: "2024-05-27",
      intensity: "Medium",
      bookForTest: null,
      filePath: "902/corporateReport/DILIP_VERMA.pdf",
      attachReport: "View Report",
      viewTestDoctorResults: "View",
      finalStatus: "Open",
    },
  ];
  return (
    <>
      <div>No Patient For Consultation</div>
    </>
  );
};

export default ConsultantPatients;
