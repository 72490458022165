import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Delete } from "../../icons/DeleteIcon";
import ModelPopUp from "../../shared/model/ModelPopUp";
import "./AddMoreFormMain.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Watch } from "@mui/icons-material";
import { familyHistory } from "../LifeStyleRiskAssessment/components/RisksDiseaseMapColumns";

function AllergiesList({ Allergies, onUpdateAllergies, handleRadioChange, selectedOption, fieldError }) {
  // const [Allergies, setAllergies] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [howBadisEffect, setAllergyStatus] = useState("");
  const [familyRelation, setFamilyRelation] = useState();
  const [firstName, setFirstName] = useState()
  const [familyInfo, setFamilyInfo] = useState(null)
  const [age, setAge] = useState(null)

  const validationSchema = yup.object().shape({
    ReactionOrSideEffects: yup.string().required("Please enter Reaction Or Side Effects"),
    Medicine: yup.string().required("Please enter Medicine / Food"),
    //howBadisEffect: yup.string().required("Please select Effect").nullable(false),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ReactionOrSideEffects: "", Medicine: "", howBadisEffect: "" },
  });

  const addMoreAllergyFields = () => {
    setIsOpen(true);
  };
  const AddAllergiesList = () => {
    const Medicine = watch("Medicine");
    const ReactionOrSideEffects = watch("ReactionOrSideEffects");
    //howBadisEffect = howBadisEffect;
    const allergy = {
      MedicineOrFood: Medicine,
      Reaction: ReactionOrSideEffects,
      effect: howBadisEffect,
    };
    onUpdateAllergies([...Allergies, allergy]);
    reset({
      Medicine: "",
      ReactionOrSideEffects: "",
      howBadisEffect: "",
    });
    toast.success("Allergy added successfully");
    setIsOpen(false);
    setLoading(true);
    reset();
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const deleteAllergy = (indexToDelete) => {
    console.log("Deleting allergy with ID:", indexToDelete);
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this Allergy?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {

        if (result.isConfirmed) {
          const updatedAllergies = Allergies.filter((allergy, index) => index !== indexToDelete);
          onUpdateAllergies(updatedAllergies);
          toast.success("Allergy deleted successfully");
        }
      });
  };

  const getRelationship = () => {
     AxiosInstance.get("/family/getPatientFamilyProfile")
       .then((response) => {
         console.log("responseFamily: ", response);
         setFamilyInfo(response)
         setFamilyRelation(response?.relationShipWithUser);
         setFirstName(response?.firstName)
       })
       .catch((error) => {
         console.log(error);
       });
     AxiosInstance.get("profile/get")
       .then((response) => {
         setAge(response?.profile?.age);
       })
       .catch((error) => {
         console.error("Error:", error);
       });
  }
  useEffect(() => { 
    getRelationship();
  },[])
  return (
    <>
      {/* <div style={{ marginBottom: "20px" }}>
        <FormControl component="fieldset">
            <FormLabel component="legend" style={{ fontSize: "16px", fontWeight: 500, color: "black" }}>
              Do you have any allergy ?
            </FormLabel>
          <RadioGroup aria-label="options" name="options" value={selectedOption} onChange={handleRadioChange} row>
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div> */}
      {/* {selectedOption == true && ( */}
      <div className="health_Service_Providers_Information">
        <div className="info_header_wrapper">
          <div className="info_provider_content" style={{ width: "100%" }}>
            <h3>Allergies</h3>
            {/* <+p>List all reactions to medicines, foods and other agents.</p> */}
            <div style={{ marginBottom: "20px" }}>
              <FormControl component="fieldset">
                {familyInfo != null && age < 13 ? (
                  <FormLabel component="legend" style={{ fontSize: "16px", fontWeight: 500, color: "black" }}>
                    Does <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span> have any
                    allergy ?
                  </FormLabel>
                ) : (
                  <FormLabel component="legend" style={{ fontSize: "16px", fontWeight: 500, color: "black" }}>
                    Do you have any allergy ?
                  </FormLabel>
                )}
                <RadioGroup aria-label="options" name="options" value={selectedOption} onChange={handleRadioChange} row>
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            {selectedOption == true && (
              <div className="health_Service_Providers_Information">
                <div className="info_header_wrapper">
                  <div className="info_provider_content">
                    <h3>Allergies</h3>
                    <p>List all reactions to medicines, foods and other agents.</p>
                  </div>
                  <div className="info_provider_button">
                    <button type="button" onClick={addMoreAllergyFields}>
                      + Add Allergy
                    </button>
                  </div>
                </div>
                <div className="add_more_header_wrapper">
                  <div className="field_header equal-column">Medicine / Food</div>
                  <div className="field_header equal-column">Reaction or Side Effects</div>
                  <div className="field_header equal-column">How Bad is the Effect</div>
                  <div className="field_header small-column"></div>
                </div>
                {Allergies &&
                  Allergies.map((input, index) => {
                    return (
                      <div key={index} className="add_more_fields_wrapper">
                        <p className="equal-column tableData">{input.MedicineOrFood}</p>
                        <p className="equal-column tableData">{input.Reaction}</p>
                        <p className="equal-column tableData">{input.effect}</p>
                        <button type="button" className="remove_button small-column tableData" onClick={() => deleteAllergy(index)}>
                          <Delete />
                        </button>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* // )} */}
      <ModelPopUp isOpen={isOpen} setIsOpen={setIsOpen} heading="Add New Allergy" handleSubmit={handleSubmit(AddAllergiesList)}>
        <form className="hospital-doctor-add-form">
          <div className={`form-group mt-3 ${errors.ReactionOrSideEffects ? "required-border" : ""}`}>
            <label>Please enter Medicine / Food</label>
            <Controller
              name="Medicine"
              control={control}
              render={({ field }) => <TextField {...field} type="text" placeholder="Please enter Medicine / Food" fullWidth label="Please enter Medicine / Food" />}
            />
            <p className="error">{errors.Medicine?.message}</p>
          </div>
          <div className={`form-group mt-3 ${errors.ReactionOrSideEffects ? "required-border" : ""}`}>
            <label>Reaction or Side Effects</label>
            <Controller
              name="ReactionOrSideEffects"
              control={control}
              render={({ field }) => <TextField {...field} type="text" placeholder="Reaction Or SideEffects" fullWidth label="Reaction Or SideEffects" />}
            />
            <p className="error">{errors.ReactionOrSideEffects?.message}</p>
          </div>
          <div className={`form-group mt-3 ${errors.howBadisEffect ? "required-border" : ""}`}>
            <label>How bad is the effect? (give drop down for the three conditions shown below)</label>
            <>
              <Controller
                name="howBadisEffect"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="relationship-label"
                    id="relationship"
                    name="howBadisEffect"
                    value={howBadisEffect}
                    style={{ width: "100%" }}
                    howBadisEffectlabel="Relationship"
                    onChange={(e) => {
                      setAllergyStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={""} disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={"Not so severe"}>Not so severe</MenuItem>
                    <MenuItem value={"Severe"}>Severe</MenuItem>
                    <MenuItem value={"Life Threatening"}>Life Threatening</MenuItem>
                  </Select>
                )}
              />
            </>
            <p className="error">{errors.howBadisEffect?.message}</p>
          </div>
        </form>
      </ModelPopUp>
    </>
  );
};
export default AllergiesList;
