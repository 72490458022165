import { useEffect, useState, useMemo } from "react";

export const addZeroIfNeeded = (num) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
};

export const defaultTo = (value, defaultValue) => {
  if (value !== undefined && value !== null) {
    return value;
  }

  return defaultValue;
};

export const getCenturyWithSuffix = (year) => {
  const century = Math.ceil(year / 100);
  let suffix = "";

  if (century >= 11 && century <= 13) {
    suffix = "th";
  } else {
    switch (century % 10) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
    }
  }

  return `${century}${suffix} century`;
};

export const isTablet = () => false;

export const validateDocumentFileType = (fileType) => {
  if (!fileType) {
    return 0;
  }

  return ["image/png", "image/jpeg", "image/jpg", "application/pdf"].includes(fileType);
};

export const getNestedPropertyByPath = (obj, path) => {
  const keys = path.split(".");
  let result = obj;

  for (let key of keys) {
    result = result?.[key];
  }
  return result;
};

export function getPaymentSuccessBaseUrl() {
  const baseUrl = window.location.href;
  let arr = baseUrl.split("/");
  let newPath = arr[0] + "//" + arr[2] + `/dashboard?isUserSubscribed=${true}`;
  return newPath;
}

export function getPaymentCancelBaseUrl() {
  const baseUrl = window.location.href;
  let arr = baseUrl.split("/");
  let newPath = arr[0] + "//" + arr[2] + "/homeBeforeLogin";
  return newPath;
}

export const customSort = (a, b) => {
  const yearComparison = a.year - b.year;
  return yearComparison === 0 ? a.month.localeCompare(b.month) : yearComparison;
};

export const sliceData = (startIndex, data) => {
  let endIndex = startIndex + 4;
  if (endIndex + 4 > data.length) {
    endIndex = data.length;
  }
  return data.slice(startIndex, endIndex);
};

export function getLoginBaseUrl() {
  const baseUrl = window.location.href;
  let arr = baseUrl.split("/");
  let newPath = arr[0] + "//" + arr[2] + `/login-email`;
  return newPath;
}

export const usePagination = (initialData, itemsPerPage = 4) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalItems = initialData?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = initialData?.slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  useEffect(() => {
    setCurrentPage(totalPages - 1);
  }, [initialData, totalPages]);

  const paginationObject = useMemo(
    () => ({
      currentPage,
      totalItems,
      totalPages,
      currentData,
      handleNextPage,
      handlePreviousPage,
    }),
    [currentPage, totalItems, totalPages, currentData, handleNextPage, handlePreviousPage]
  );

  return paginationObject;
};

const calculateTrendLine = (data, xKey, yKey) => {
  const n = data?.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumX2 = 0;

  data?.forEach((point) => {
    sumX += point[xKey];
    sumY += point[yKey];
    sumXY += point[xKey] * point[yKey];
    sumX2 += point[xKey] * point[xKey];
  });

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  const trendLineData = data?.map((point) => ({
    [xKey]: point[xKey],
    [yKey]: point[yKey],
    TrendLine: slope * point[xKey] + intercept,
  }));

  return trendLineData;
};

export default calculateTrendLine;

export const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
