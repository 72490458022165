import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, Grid } from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
// import "./Admin.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
const ThyroidModel = ({ open, onClose }) => {
  const [errors, setErrors] = useState({});
  const [slowHeartRate, setSlowHeartRate] = useState(null);
  const [feelingTired, setFeelingTired] = useState(null);
  const [unexplainedWeightGain, setUnExplainedWeightGain] = useState(null);
  const [feelingSensitiveToCold, setFeelingSensitiveToCold] = useState(null);
  const [drySkinAndDryHair, setDrySkinAndDryHair] = useState(null);
  const [feelingDepressed, setFeelingDepressed] = useState(null);
  const [heavyMenstrualPeriods, setHeavyMenstrualPeriods] = useState(null);
  const [fasterHeartRate, setFasterHeartRate] = useState(null);
  const [difficultySleeping, setDifficultySleeping] = useState(null);
  const [unexplainedWeightLoss, setUnexplainedWeightLoss] = useState(null);
  const [feelingSensitiveToHeat, setFeelingSensitiveToHeat] = useState(null);
  const [sweatySkin, setSweatySkin] = useState(null);
  const [feelingAnxious, setFeelingAnxious] = useState(null);
  const [irregularMenstrualCycles, setIrregularMenstrualCycles] = useState(null);
  const [lackOfPeriods, setLackOfPeriods] = useState(null);


  const handleSlowHeartRate = (e) => {
    const value = e.target.value == "true";
    setSlowHeartRate(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      slowHeartRate: false,
    }));
  };
  const handleFeelingTired = (e) => {
    const value = e.target.value == "true";
    setFeelingTired(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      feelingTired: false,
    }));
  };
  const handleUnExplainedWeightGain = (e) => {
    const value = e.target.value == "true";
    setUnExplainedWeightGain(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      unexplainedWeightGain: false,
    }));
  };
  const handleFeelingSensitiveToCold = (e) => {
    const value = e.target.value == "true";
    setFeelingSensitiveToCold(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      feelingSensitiveToCold: false,
    }));
  };
  const handleDrySkinAndDryHair = (e) => {
    const value = e.target.value == "true";
    setDrySkinAndDryHair(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      drySkinAndDryHair: false,
    }));
  };
  const handleFeelingDepressed = (e) => {
    const value = e.target.value == "true";
    setFeelingDepressed(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      feelingDepressed: false,
    }));
  };
  const handleHeavyMenstrualPeriods = (e) => {
    const value = e.target.value == "true";
    setHeavyMenstrualPeriods(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      heavyMenstrualPeriods: false,
    }));
  };
  const handleFasterHeartRate = (e) => {
    const value = e.target.value == "true";
    setFasterHeartRate(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      fasterHeartRate: false,
    }));
  };
  const DifficultySleeping = (e) => {
    const value = e.target.value == "true";
    setDifficultySleeping(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      difficultySleeping: false,
    }));
  };
  const handleUnexplainedWeightLoss = (e) => {
    const value = e.target.value == "true";
    setUnexplainedWeightLoss(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      unexplainedWeightLoss: false,
    }));
  };
    const handleFeelingSensitiveToHeat = (e) => {
      const value = e.target.value == "true";
      setFeelingSensitiveToHeat(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        feelingSensitiveToHeat: false,
      }));
    };
    const handleSweatySkin = (e) => {
      const value = e.target.value == "true";
      setSweatySkin(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        sweatySkin: false,
      }));
    };
    const handleFeelingAnxious = (e) => {
      const value = e.target.value == "true";
      setFeelingAnxious(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        feelingAnxious: false,
      }));
    };
  const handleIrregularMenstrualCycles = (e) => {
    const value = e.target.value == "true";
    setIrregularMenstrualCycles(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      irregularMenstrualCycles: false,
    }));
  };

     const handleLackOfPeriods = (e) => {
       const value = e.target.value == "true";
       setLackOfPeriods(value);
       setErrors((prevErrors) => ({
         ...prevErrors,
         lackOfPeriods: false,
       }));
     };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(`form/getThyroidSymptoms`);
        setSlowHeartRate(response?.slowHeartRate);
        setFeelingTired(response?.feelingTired);
        setUnExplainedWeightGain(response?.unexplainedWeightGain);
        setFeelingSensitiveToCold(response?.feelingSensitiveToCold);
        setDrySkinAndDryHair(response?.drySkinAndDryHair);
        setFeelingDepressed(response?.feelingDepressed);
        setHeavyMenstrualPeriods(response?.heavyMenstrualPeriods);
        setFasterHeartRate(response?.fasterHeartRate);
        setDifficultySleeping(response?.difficultySleeping);
        setUnexplainedWeightLoss(response?.unexplainedWeightLoss);
        setFeelingSensitiveToHeat(response?.feelingSensitiveToHeat);
        setSweatySkin(response?.sweatySkin);
        setFeelingAnxious(response?.feelingAnxious);
        setIrregularMenstrualCycles(response?.irregularMenstrualCycles);
        setLackOfPeriods(response?.lackOfPeriods);
      } catch (err) {}
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    const Checkerrors = {};
    if (slowHeartRate == null) {
      Checkerrors.slowHeartRate = true;
    }
    if (feelingTired == null) {
      Checkerrors.feelingTired = true;
    }
    if (unexplainedWeightGain == null) {
      Checkerrors.unexplainedWeightGain = true;
    }
    if (feelingSensitiveToCold == null) {
      Checkerrors.feelingSensitiveToCold = true;
    }
    if (drySkinAndDryHair == null) {
      Checkerrors.drySkinAndDryHair = true;
    }
    if (feelingDepressed == null) {
      Checkerrors.feelingDepressed = true;
    }
    if (heavyMenstrualPeriods == null) {
      Checkerrors.heavyMenstrualPeriods = true;
    }
    if (fasterHeartRate == null) {
      Checkerrors.fasterHeartRate = true;
    }
    if (difficultySleeping == null) {
      Checkerrors.difficultySleeping = true;
    }
    if (unexplainedWeightLoss == null) {
      Checkerrors.unexplainedWeightLoss = true;
    }
    if (feelingSensitiveToHeat == null) {
      Checkerrors.feelingSensitiveToHeat = true;
    }
    if (sweatySkin == null) {
      Checkerrors.sweatySkin = true;
    }
    if (feelingAnxious == null) {
      Checkerrors.feelingAnxious = true;
    }
    if (irregularMenstrualCycles == null) {
      Checkerrors.irregularMenstrualCycles = true;
    }
    if (lackOfPeriods == null) {
      Checkerrors.lackOfPeriods = true;
    }

    setErrors(Checkerrors);
    if (Object.keys(Checkerrors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        slowHeartRate: slowHeartRate === true, // true if the value is selected as true, otherwise false
        feelingTired: feelingTired === true,
        unexplainedWeightGain: unexplainedWeightGain === true,
        feelingSensitiveToCold: feelingSensitiveToCold === true,
        feelingDepressed: feelingDepressed === true,
        heavyMenstrualPeriods: heavyMenstrualPeriods === true,
        fasterHeartRate: fasterHeartRate === true,
        difficultySleeping: difficultySleeping === true,
        unexplainedWeightLoss: unexplainedWeightLoss === true,
        feelingSensitiveToHeat: feelingSensitiveToHeat === true,
        sweatySkin: sweatySkin === true,
        feelingAnxious: feelingAnxious === true,
        irregularMenstrualCycles: irregularMenstrualCycles === true,
        lackOfPeriods: lackOfPeriods === true,
        drySkinAndDryHair: drySkinAndDryHair === true,
      };
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined || formData[key] === "") {
          formData[key] = false;
        }
      });
      try {
        await AxiosInstance.post(`form/addThyroidSymptoms`, formData)
          .then((response) => {
            toast.success("Form Submitted Successfully");
            onClose();
          })
          .catch((err) => {
            console.log("Upload error:", err);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{ minHeight: "600px" }}>
      <DialogTitle>Do you have any of the Symptoms? (Hypothyroidism)</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {/* Question 1 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.slowHeartRate}>
              <FormLabel>1. Slower than usual heart rate</FormLabel>
              <RadioGroup row value={slowHeartRate} onChange={handleSlowHeartRate}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Question 2 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.feelingTired}>
              <FormLabel>2. Feeling tired often (Fatigue)</FormLabel>
              <RadioGroup row value={feelingTired} onChange={handleFeelingTired}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Question 3 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.unexplainedWeightGain}>
              <FormLabel>3. Unexplained weight gain</FormLabel>
              <RadioGroup row value={unexplainedWeightGain} onChange={handleUnExplainedWeightGain}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Question 4 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.feelingSensitiveToCold}>
              <FormLabel>4. Feeling sensitive to cold</FormLabel>
              <RadioGroup row value={feelingSensitiveToCold} onChange={handleFeelingSensitiveToCold}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Question 5 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.drySkinAndDryHair}>
              <FormLabel>5. Dry skin and Dry hair</FormLabel>
              <RadioGroup row value={drySkinAndDryHair} onChange={handleDrySkinAndDryHair}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Question 6 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.feelingDepressed}>
              <FormLabel>6.Feeling depressed quite often</FormLabel>
              <RadioGroup row value={feelingDepressed} onChange={handleFeelingDepressed}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Question 7 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.heavyMenstrualPeriods}>
              <FormLabel>7. Heavy menstrual periods</FormLabel>
              <RadioGroup row value={heavyMenstrualPeriods} onChange={handleHeavyMenstrualPeriods}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <DialogTitle>Do you have any of the Symptoms? (Hyperthyroidism)</DialogTitle>
          {/* Question 8 */}
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.fasterHeartRate}>
              <FormLabel>1. Faster than usual heart rate</FormLabel>
              <RadioGroup row value={fasterHeartRate} onChange={handleFasterHeartRate}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.difficultySleeping}>
              <FormLabel>2. Difficulty sleeping</FormLabel>
              <RadioGroup row value={difficultySleeping} onChange={DifficultySleeping}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.unexplainedWeightLoss}>
              <FormLabel>3. unexplained weight loss</FormLabel>
              <RadioGroup row value={unexplainedWeightLoss} onChange={handleUnexplainedWeightLoss}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.feelingSensitiveToHeat}>
              <FormLabel>4. Feeling sensitive to heat</FormLabel>
              <RadioGroup row value={feelingSensitiveToHeat} onChange={handleFeelingSensitiveToHeat}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.sweatySkin}>
              <FormLabel>5. Sweaty skin</FormLabel>
              <RadioGroup row value={sweatySkin} onChange={handleSweatySkin}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.feelingAnxious}>
              <FormLabel>6. Feeling anxious, irritable or nervous</FormLabel>
              <RadioGroup row value={feelingAnxious} onChange={handleFeelingAnxious}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.irregularMenstrualCycles}>
              <FormLabel>7. Irregular Menstrual cycles</FormLabel>
              <RadioGroup row value={irregularMenstrualCycles} onChange={handleIrregularMenstrualCycles}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors.lackOfPeriods}>
              <FormLabel>8. Lack of periods</FormLabel>
              <RadioGroup row value={lackOfPeriods} onChange={handleLackOfPeriods}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ThyroidModel;
