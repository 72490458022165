import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const CorporateSubscriptionPlans = () => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [validationMessages, setValidationMessages] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { newEmployees } = location.state || {};

  const handleEmployeeChange = (planIndex, detailIndex, value) => {
    const updatedPlans = [...subscription];
    updatedPlans[planIndex].planDetails[detailIndex].employees = value;
    setSubscription(updatedPlans);

    // Reset validation message for this specific field
    setValidationMessages((prev) => ({
      ...prev,
      [`${planIndex}-${detailIndex}`]: "",
    }));
  };

  const handleRowClick = (planIndex, detailIndex) => {
    const selectedDetail = subscription[planIndex].planDetails[detailIndex];
    const rowId = `${planIndex}-${detailIndex}`;

    if (selectedDetail.comingSoon) return; // Skip rows with comingSoon === true

    setSelectedRow((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        // Deselect row if already selected
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        // Select row if not already selected
        return [...prevSelectedRows, rowId];
      }
    });

    setShowSubmitButton(true); // Always show submit button if there’s a selection
  };

  const handleSubmit = () => {
    const selectedDetails = selectedRow
      .map((rowId) => {
        const [planIndex, detailIndex] = rowId.split("-").map(Number);
        const selectedDetail = subscription[planIndex].planDetails[detailIndex];

        // Check if the "Number of Employees" is valid
        if (!selectedDetail.employees || selectedDetail.employees <= 0) {
          setValidationMessages((prev) => ({
            ...prev,
            [`${planIndex}-${detailIndex}`]: "This field cannot be empty.",
          }));
          return null;
        }

        // Calculate the total price for this row
        const totalPrice = selectedDetail.employees * selectedDetail.price;

        return {
          planId: selectedDetail.id,
          numberOfEmployees: Number(selectedDetail.employees),
          totalPrice: totalPrice,
        };
      })
      .filter(Boolean); // Filter out any invalid rows

    console.log("Selected Rows Data:", selectedDetails);

    // Send to API
    AxiosInstance.post("corporate/create-subscription-invoice", selectedDetails)
      .then((response) => {
        toast.success("Invoices Created Successfully");
        setTimeout(() => {
          navigate("/corporate-invoice");
        }, 1000);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  const getPlanBackgroundColor = (planType) => {
    if (planType.includes("BASIC PLAN")) return "#8bc34a61"; // Basic Plan
    if (planType.includes("ADVANCE PLAN")) return "#03a9f445"; // Advance Plan
    if (planType.includes("PREMIUM PLAN")) return "#ff572252"; // Premium Plan
    if (planType.includes("Lifestyle Coaching")) return "#ff149342"; // Lifestyle Coaching
    return "inherit"; // Default color
  };

  const transformData = (data) => {
    const groupedPlans = {};

    // Sort the data by `id` before grouping
    const sortedData = data.sort((a, b) => a.id - b.id);

    sortedData.forEach((plan) => {
      const {
        id,
        planType,
        planName,
        planCategory,
        monthlyPrice,
        yearlyPrice,
        status,
      } = plan;

      if (!groupedPlans[planType]) {
        groupedPlans[planType] = {
          planType,
          planDetails: [],
        };
      }

      groupedPlans[planType].planDetails.push({
        id,
        name: planName,
        category: planCategory,
        cost: monthlyPrice,
        price: yearlyPrice,
        comingSoon: status === "COMING SOON",
        employees: 0,
      });
    });

    return Object.values(groupedPlans);
  };

  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("corporate/get-subscription-plan")
      .then((response) => {
        if (response) {
          const transformedData = transformData(response);
          setSubscription(transformedData);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error:", err);
        setLoading(false);
      });
  }, []);

  console.log("subs", subscription, newEmployees);

  return (
    <>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "dimgray" }}>
                  <TableCell sx={{ padding: "8px", color: "white" }}>
                    Plan Name
                  </TableCell>
                  <TableCell sx={{ padding: "8px", color: "white" }}>
                    Category
                  </TableCell>
                  <TableCell sx={{ padding: "8px", color: "white" }}>
                    Monthly Price
                  </TableCell>
                  <TableCell sx={{ padding: "8px", color: "white" }}>
                    Yearly Price
                  </TableCell>
                  <TableCell
                    sx={{ padding: "8px", color: "white" }}
                  ></TableCell>
                  <TableCell sx={{ padding: "8px", color: "white" }}>
                    Number of Employees
                  </TableCell>
                  <TableCell sx={{ padding: "8px", color: "white" }}>
                    Total Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscription.map((plan, planIndex) => (
                  <React.Fragment key={plan.planType}>
                    {/* Plan Type Header Row */}
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{
                          backgroundColor: getPlanBackgroundColor(
                            plan.planType
                          ),
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {plan.planType}
                      </TableCell>
                    </TableRow>
                    {/* Plan Details Rows */}
                    {plan.planDetails.map((detail, detailIndex) => {
                      const rowId = `${planIndex}-${detailIndex}`;
                      const isSelected = selectedRow.includes(rowId);
                      return (
                        <TableRow
                          key={detailIndex}
                          onClick={() => handleRowClick(planIndex, detailIndex)}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: isSelected
                              ? "lightblue"
                              : "inherit",
                            "&:hover": {
                              backgroundColor: detail.comingSoon
                                ? "inherit"
                                : "lightgray",
                            },
                          }}
                        >
                          <TableCell>{detail.name}</TableCell>
                          <TableCell>{detail.category}</TableCell>
                          <TableCell>{detail.cost}</TableCell>
                          <TableCell>{detail.price}</TableCell>
                          <TableCell>
                            {detail.comingSoon ? "COMING SOON" : ""}
                          </TableCell>
                          <TableCell>
                            {detail.comingSoon ? (
                              ""
                            ) : (
                              <TextField
                                size="small"
                                variant="outlined"
                                value={detail.employees ?? 0}
                                onChange={(e) =>
                                  handleEmployeeChange(
                                    planIndex,
                                    detailIndex,
                                    e.target.value
                                  )
                                }
                                inputProps={{
                                  style: {
                                    padding: "4px 8px",
                                    fontSize: "0.875rem",
                                  },
                                }}
                              />
                            )}
                            {validationMessages[rowId] && (
                              <Typography color="error" sx={{ mt: 1 }}>
                                {validationMessages[rowId]}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            {detail.comingSoon
                              ? ""
                              : detail.employees
                              ? detail.employees * detail.price
                              : 0}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Submit Button */}
          {showSubmitButton && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CorporateSubscriptionPlans;
