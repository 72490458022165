import React, { useEffect, useState, useMemo } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getTestPrice,
  getGeneralInformation,
  uploadPriceList,
} from "../../constants/apiList";
import TablePagination from "@mui/material/TablePagination";
import { event, set } from "react-ga";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import FilterListIcon from "@mui/icons-material/FilterList";
import { GrRefresh } from "react-icons/gr";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const PhysiotherapistPriceList = () => {
  const [rowData, setRowData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [labName, setLabName] = useState("");
  const [file, setFile] = useState(null);
  const [testResult, setTestResult] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [showDateField, setshowDateField] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [filterLabName, setFilterLabName] = useState("");
  const [filterLabId, setFilterLabId] = useState("");
  const [recordsListData, setRecordsListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showExplaination, setShowExplaination] = useState(false);

  const getTestPriceDetail = async () => {
    await AxiosInstance.get(`${getTestPrice}`)
      .then((response) => {
        if (response != null) {
          setRowData(response);
          setTestResult(response?.length);
          setRecordsListData(response);
        } else {
          setRowData("");
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const GetGeneralInformation = async () => {
    await AxiosInstance.get(`${getGeneralInformation}`)
      .then((response) => {
        setLabName(response.labName);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const TestReport = async (file) => {
    let newFormData = new FormData();
    if (file) {
      newFormData.append("file", file);
    } else {
      console.log("File not found");
    }
    await AxiosInstance.post(`${uploadPriceList}`, newFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        toast.success("File uploaded successfully");
        getTestPriceDetail();
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  //   useEffect(() => {
  //     getTestPriceDetail();
  //     GetGeneralInformation();
  //   }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    TestReport(selectedFile);
  };
  const handleLabNameFilterChange = (event) => {
    setShowTextField(event.target.checked);
  };
  const handleDateFilterChange = (event) => {
    setshowDateField(event.target.checked);
  };
  const filterData = (selectedDate) => {
    const lowerCaseLab = selectedDate.toLowerCase();
    if (lowerCaseLab === "") {
      return recordsListData;
    } else {
      const filterLab = recordsListData.filter((item) =>
        item?.testName?.toLowerCase().includes(lowerCaseLab)
      );
      return filterLab;
    }
  };

  const handleDateChange = (event) => {
    setShowExplaination(false);
    const selectedDate = event.target.value;
    setFilterLabName(selectedDate);
  };

  const filteredData = filterData(filterLabName);

  filteredData?.sort(function (a, b) {
    let keyA = new Date(a.collectionDate),
      keyB = new Date(b.collectionDate);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  const handleLabNameChange = (event) => {
    setShowExplaination(false);
    const labName = event.target.value;
    setFilterLabId(labName);
  };

  const filterLab = (labname) => {
    const lowercaseLabName = labname?.toLowerCase();
    if (lowercaseLabName === "") {
      return recordsListData;
    } else {
      const filteredLab = recordsListData.filter((item) =>
        item?.testId?.toLowerCase().includes(lowercaseLabName)
      );
      return filteredLab;
    }
  };
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const FilterLabName = filterLab(filterLabId);
  const visibleRows2 = useMemo(
    () => stableSort(FilterLabName, getComparator(order, orderBy)),
    [FilterLabName, order, orderBy]
  );
  const visibleRows = useMemo(
    () => stableSort(filteredData, getComparator(order, orderBy)),
    [filteredData, order, orderBy]
  );
  return (
    <>
      <div style={{ display: "flex" }}>
        <h2 style={{ marginTop: "2%" }}>
          This Page Will be available only after review of your general
          information.
        </h2>
        {/* <div style={{ marginInlineStart: "auto" }}>
          <div>
            <b>Attach Price for all your Tests.</b>
          </div>
          <div style={{ border: "1px solid lightgrey", padding: "15px", marginTop: "10px"}}>
            <input type="file" onChange={handleFileChange}></input>
          </div>
        </div> */}
      </div>
      {/* <div style={{ display: "flex", border: "1px solid black", padding: "15px", width:"55%" }}>
        <b>Lab Name : </b>&nbsp; {labName}
      </div>
      <div>
        <div>
          <Row>
            {testResult > 3 ? (
              <Col style={{ marginTop: "-20px", marginBottom: "3%", display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginInlineStart: "auto" }}>
                  <a href="#" style={{ fontSize: "20px", color: "black", border: "1px solid lightgrey", padding: "10px" }} onClick={() => setShowFilters(!showFilters)}>
                    Search <FilterListIcon />
                  </a>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <br />
                {showFilters && (
                  <div style={{ border: "1px solid lightgrey", padding: "10px", marginTop: "-10px" }}>
                    <input type="checkbox" id="dateFilter" name="dateFilter" onChange={handleDateFilterChange} /> &nbsp;
                    <label htmlFor="dateFilter">Search by Test Name</label>
                    <br />
                    {showDateField && (
                      <div>
                        <label>
                          <span className="text-style"></span>
                        </label>
                        &nbsp;&nbsp;
                        <input className="input-date" type="text" name="testName" placeholder="Enter Test Name" value={filterLabName} onChange={handleDateChange} />
                      </div>
                    )}
                    <input type="checkbox" id="labNameFilter" name="labNameFilter" onChange={handleLabNameFilterChange} />
                    &nbsp;&nbsp;
                    <label htmlFor="labNameFilter">Search by Test Id</label>
                    <br />
                    {showTextField && (
                      <div>
                        <label>
                          <span className="text-style"></span>
                        </label>
                        &nbsp;&nbsp;
                        <input className="input-date" type="text" name="testId" placeholder="Enter Test Id" value={filterLabId} onChange={handleLabNameChange} />
                      </div>
                    )}
                  </div>
                )}
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
      {rowData.length === 0 && <p style={{ marginTop: "1%" }}>
          <b>This screen will show you daily home collection service orders.</b>
        </p>}
      <table class="table  table-bordered table-striped" style={{ marginTop: "4%" }}>
        <thead class="thead-dark">
          {!rowData && <b>Example for ExcelSheet </b>}
          <tr>
            <th scope="col" style={{ backgroundColor: "#5353E9", color: "white", textAlign: "center" }}>
              Sr No.
            </th>
            <th scope="col" style={{ backgroundColor: "#5353E9", color: "white", textAlign: "center" }}>
              Test Id
            </th>
            <th scope="col" style={{ backgroundColor: "#5353E9", color: "white", textAlign: "center" }}>
              Test
            </th>
            <th scope="col" style={{ backgroundColor: "#5353E9", color: "white", textAlign: "center" }}>
              MRP
            </th>
            <th scope="col" style={{ backgroundColor: "#5353E9", color: "white", textAlign: "center" }}>
              B2B Price
            </th>
          </tr>
        </thead>
        <tbody>
          {rowData.length === 0 ? (
            <tr>
              <th scope="row" style={{ textAlign: "center" }}>
                </th>
                <th scope="row" style={{ textAlign: "center" }}>
                </th>
                <td style={{ textAlign: "center" }}>Lipid</td>
                <td style={{ textAlign: "center" }}>300</td>
                <td style={{ textAlign: "center" }}>200</td>
            </tr>
          ) : (
            (filterLabId !== "" ? visibleRows2 : visibleRows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((test, index) => (
              <tr key={index}>
                <th scope="row" style={{ textAlign: "center" }}>
                  {index + 1 + page * rowsPerPage}
                </th>
                <td style={{ textAlign: "center" }}>{test.testId}</td>
                <td style={{ textAlign: "center" }}>{test.testName}</td>
                <td style={{ textAlign: "center" }}>{test.testPrice}</td>
                <td style={{ textAlign: "center" }}>{test.b2bPrice}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </>
  );
};
export default PhysiotherapistPriceList;
