import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import NewsFeedModal from "../../components/NewsFeedModal/NewsFeedModal";
import { SettingsModal } from "../../components/SettingsModal/SettingsModal";
import TermAndPlociesModal from "../../components/Term&PoliciesModal/Term&PoliciesModal";
import VideoPopup from "../../components/VideoPopup/VideoPopup";
import InfoModel from "../../shared/simpleModel/InfoModel";
import "./Menu.css";
import pdfFileIcon1 from "../../img/pdf-file-icon.png";
import videoSquare from "../../../static/img/video-square.png";
import setting2 from "../../../static/img/setting-2.png";
import shieldTick from "../../../static/img/shield-tick.png";
import messageQuestion from "../../../static/img/message-question.png";
import documentText from "../../../static/img/document-text.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

export const Menu = () => {
  const [VideoPopupInfoModel, setVideoPopupInfoModel] = useState(false);
  const [NewsFeedInfoModel, setNewsFeedInfoModel] = useState(false);
  const [SettingsInfoModel, setSettingsInfoModel] = useState(false);
  const [TermsInfoModel, setTermsInfoModel] = useState(false);
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    // This effect runs when the component mounts (screen entry)
    setStartTime(new Date());

    // This function will run when the component unmounts (screen exit)
    return () => {
      const endTime = new Date();
      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      const data = {
        screenName: "Menu Screen",
        startTime: formatDateTime(startTime),
        endTime: formatDateTime(endTime),
      };

      // Send data to your API using fetch or Axios here
      // Example using fetch:
      AxiosInstance.post("general/save-app-usage-detail", data)
        .then((response) => {})
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
        });
    };
  }, []); // Empty dependency array ensures that this effect runs only once (on mount and unmount)

  const VideoPopupModel = () => {
    setVideoPopupInfoModel((prev) => !prev);
  };

  const NewsFeedModel = () => {
    setNewsFeedInfoModel((prev) => !prev);
  };

  const SettingsModel = () => {
    setSettingsInfoModel((prev) => !prev);
  };

  const TermsModel = () => {
    setTermsInfoModel((prev) => !prev);
  };

  const MenuPageItems = [
    {
      title: "Brand Story",
      titleIcon: pdfFileIcon1,
      link: "https://livingscopehealth.com/app/brandstory/",
      target: "_blank",
      actionType: "link",
    },
    {
      title: "Video",
      titleIcon: videoSquare,
      link: "video-square.png",
      actionType: "button",
      callFun: VideoPopupModel,
    },
    {
      title: "News Feed",
      titleIcon: documentText,
      actionType: "button",
      callFun: NewsFeedModel,
    },
    {
      title: "Help Center",
      titleIcon: messageQuestion,
      link: "/help-center",
      target: "",
      actionType: "link",
    },
    {
      title: "Settings & Privacy",
      titleIcon: setting2,
      actionType: "button",
      callFun: SettingsModel,
    },
    {
      title: "Terms & Policies",
      titleIcon: shieldTick,
      actionType: "button",
      callFun: TermsModel,
    },
  ];

  return (
    <>
      <h2 className="pageTitle">Menu</h2>
      <div className="menuPageWrapper">
        {MenuPageItems.map((item, index) => {
          if (item?.actionType == "link") {
            return (
              <Link
                to={item?.link}
                target={`${!!item?.target ? item?.target : ""}`}
                className="menuPageItem"
              >
                <div className="menuPageImg">
                  <img src={item?.titleIcon} />
                </div>
                <p>{item?.title}</p>
              </Link>
            );
          } else {
            return (
              <div
                className="menuPageItem"
                onClick={item?.callFun}
              >
                <div className="menuPageImg">
                  <img src={item?.titleIcon} />
                </div>
                <p>{item?.title}</p>
              </div>
            );
          }
        })}
      </div>

      {VideoPopupInfoModel && (
        <InfoModel
          open={VideoPopupInfoModel}
          setOpen={setVideoPopupInfoModel}
          heading="Videos"
          buttonText="Ok"
        >
          <VideoPopup></VideoPopup>
        </InfoModel>
      )}
      {NewsFeedInfoModel && (
        <InfoModel
          open={NewsFeedInfoModel}
          setOpen={setNewsFeedInfoModel}
          heading="News Feed"
          buttonText="Ok"
        >
          <div className="popup-description">
            <NewsFeedModal></NewsFeedModal>
          </div>
        </InfoModel>
      )}
      {TermsInfoModel && (
        <InfoModel
          open={TermsInfoModel}
          setOpen={setTermsInfoModel}
          heading="Terms & Policies"
          buttonText="Ok"
        >
          <TermAndPlociesModal />
        </InfoModel>
      )}
      {SettingsInfoModel && (
        <InfoModel
          open={SettingsInfoModel}
          setOpen={setSettingsInfoModel}
          heading="Settings & Privacy"
          buttonText="Ok"
          refresh={true}
        >
          <div className="popup-description">
            <SettingsModal />
          </div>
        </InfoModel>
      )}
    </>
  );
};
