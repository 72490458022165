import React from "react";
import "./CancelSubscriptionModal.css";

const CancelSubscriptionModalModal = ({onClose}) => {
    return (
        <div className="CancelSubscription">
            <div className="description">
                Are you sure you want to cancel the subscription ?
            </div>
            <div className="description">
                Cancelling subscription will end your current plan and you will need to buy again to access your account.
            </div>
            <div></div>
            <div style={{display: "flex" , alignItems: "center" , justifyContent: "space-around" , marginTop: "20px"}}>
                <div className="no" onClick={onClose}>No</div>
                <div className="yes">Yes</div>
            </div>
        </div>
    )
}

export default CancelSubscriptionModalModal;