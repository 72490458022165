import React, { useEffect, useState } from "react";
import "./RiskTracker.css";
import { getRiskAssessmentMappingApi } from "../../constants/apiList";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
const RiskTracker = () => {
  const [riskTrackerList, setRiskTrackerList] = useState([]);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);

  const getRiskAssessment = async () => {
    await AxiosInstance.get(`${getRiskAssessmentMappingApi}`)
      .then((res) => {
        setRiskTrackerList(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    getRiskAssessment();
  }, []);
  const handleCommentChange = (e, index) => {
    const updatedList = [...riskTrackerList];
    updatedList[index].feedback = e.target.value; // Update feedback for a specific item
    setRiskTrackerList(updatedList);
  };
  const onSaveComment = (item) => {
    const data = {
      lrid: item.lrid,
      dateRecommended: item.dateRecommended,
      medicalCondition: item.medicalCondition,
      preventiveTest: item.preventiveTest,
      feedback: item.feedback,
    };
    AxiosInstance.post("riskAssessment/saveRiskTrackerResult", data)
      .then((response) => {
        toast.success("Feedback Added Successfully");
        getRiskAssessment();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {familyRelation?.relationShipWithUser != "wife" &&
        familyRelation != null && (
          <>
            <h4 style={{ color: "blue" }}>
              The health Insights/Information presented below is for your child{" "}
              <span style={{ fontSize: "larger" }}>
                {familyInfo?.firstName &&
                  familyInfo.firstName.charAt(0).toUpperCase() +
                    familyInfo.firstName.slice(1).toLowerCase()}
              </span>
            </h4>
            <br />
          </>
        )}
      {riskTrackerList.length > 0 && (
        <div className="">
          <p style={{ float: "inline-end", color: "red" }}>
            Note: If you are putting "Comment" then click on Save button.
          </p>
          {riskTrackerList.map((item, index) => (
            <>
              <table
                class="table table-bordered"
                style={{ border: "2px solid black" }}
              >
                <thead class="">
                  <tr class="table-active">
                    <th scope="col" style={{ width: "25%" }}>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID</b>
                      </p>
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test</b>
                      </p>
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      <p style={{ fontSize: "18px" }}>
                        <b>Date</b>
                      </p>
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition Risk</b>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{item.lrid}</th>
                    <th>{item.preventiveTest}</th>
                    <th>{item.dateRecommended}</th>
                    <th>{item.medicalCondition}</th>
                  </tr>
                  <tr class="table-active">
                    <th>
                      <p style={{ fontSize: "18px" }}>
                        <b>Action Item 1</b>
                      </p>
                    </th>
                    <td colspan="2">
                      <p style={{ fontSize: "18px" }}>
                        <b>Action Item 2</b>
                      </p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>Action Item 3</b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>{item.actionItemOne}</th>
                    <th colspan="2">{item.actionItemTwo}</th>
                    <th>{item.actionItemThree}</th>
                  </tr>
                  <tr class="table-active">
                    <th>
                      <p style={{ fontSize: "18px" }}>
                        <b>Action Item 1 Status</b>
                      </p>
                    </th>
                    <th colspan="2">
                      <p style={{ fontSize: "18px" }}>
                        <b>Action Item 2 Status</b>
                      </p>
                    </th>
                    <th>
                      <p style={{ fontSize: "18px" }}>
                        <b>Action Item 3 Status</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th>{item.actionItemOneStatus}</th>
                    <th colspan="2">{item.actionItemTwoStatus}</th>
                    <th>{item.actionItemThreeStatus}</th>
                  </tr>
                  <tr class="table-active" colspan="2">
                    <th colspan="2">
                      <p style={{ fontSize: "18px" }}>
                        <b>Comments</b>
                      </p>
                    </th>
                    <th colspan="2">
                      <p style={{ fontSize: "18px" }}>
                        <b>Your Comments / Feedback </b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2">{item.comment}</th>
                    <th colspan="2">
                      <input
                        type="text"
                        class="form-control"
                        onChange={(e) => handleCommentChange(e, index)} // Pass index to update specific feedback
                        value={item.feedback || ""}
                      />
                    </th>
                  </tr>
                </tbody>
              </table>
              <button
                type="button"
                onClick={() => onSaveComment(item)}
                className="btn btn-info"
                style={{
                  marginBottom: "15px",
                  marginTop: "-4px",
                  float: "inline-end",
                  width: "10%",
                  fontSize: "20px",
                }}
              >
                Save
              </button>
              <br></br>
            </>
          ))}
        </div>
      )}
    </>
  );
};
export default RiskTracker;
