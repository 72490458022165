import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import InfoModel from "../../shared/simpleModel/InfoModel";
import "./SubscriptionPageAfterLogin.css";

export const SubscriptionPageAfterLogin = () => {
  const [isInfoModeOpen, setIsInfoModeOpen] = useState(false);
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    // This effect runs when the component mounts (screen entry)
    setStartTime(new Date());

    // This function will run when the component unmounts (screen exit)
    return () => {
      const endTime = new Date();
      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      const data = {
        screenName: "Subscription Plans",
        startTime: formatDateTime(startTime),
        endTime: formatDateTime(endTime),
      };

      // Send data to your API using fetch or Axios here
      // Example using fetch:
      AxiosInstance.post("general/save-app-usage-detail", data)
        .then((response) => {})
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
        });
    };
  }, []); // Empty dependency array ensures that this effect runs only once (on mount and unmount)

  const AddToCartPopup = () => {
    setIsInfoModeOpen((prev) => !prev);
  };
  return (
    <div className="SubscriptionPageAfterLogin">
      <Row>
        <Col lg={6}>
          <div className="overlap-group">
            <div className="tier-BRONZE">Tier 1 - BRONZE</div>
            <div className="store-your-medical">Store your medical records on Cloud for safe and easy access whenever and wherever you need.</div>
            <div className="rs-per-user">
              <span className="text-wrapper">₹109</span>
              <span className="text-wrapper-2"> per user/month </span>
              {/* <span className="after-days-free"> (after 30 days free trial)</span> */}
            </div>

            <div className="subsgroup-wrapper">
              <button className="addtocart">Add to Cart</button>
            </div>

            <div>
              <div className="subsgroup-3">
                <img
                  className="akar-icons-circle"
                  alt="Akar icons circle"
                  src="/img/akar-icons-circle-check-fill-6.png"
                />
                <span className="substext-wrapper-3">Get a quick view of all your hospital visits</span>
              </div>
              <div className="subsgroup-3">
                <img
                  className="akar-icons-circle"
                  alt="Akar icons circle"
                  src="/img/akar-icons-circle-check-fill-6.png"
                />
                <span className="substext-wrapper-3">Store and access all your health records</span>
              </div>

              <div className="subsgroup-3">
                <img
                  className="akar-icons-circle"
                  alt="Akar icons circle"
                  src="/img/akar-icons-circle-check-fill-6.png"
                />
                <span className="substext-wrapper-3">Store and access all your Lab/ test reports</span>
              </div>
              <div className="subsgroup-3">
                <img
                  className="akar-icons-circle"
                  alt="Akar icons circle"
                  src="/img/akar-icons-circle-check-fill-6.png"
                />
                <span className="substext-wrapper-3"> A comprehensive view of each and every hospital visit recorded with us.</span>
              </div>
            </div>
            <p className="start-your-health">Start your Health Journey TODAY</p>
          </div>
        </Col>

        <Col lg={6}>
          <div className="overlap-group">
            <div className="tier-BRONZE">Tier 2 - SILVER</div>
            <div className="understand-and">Understand and Manage your Health</div>
            <div className="subsgroup-wrapper">
              <button
                className="silveraddtocart"
                onClick={AddToCartPopup}
              >
                Add to Cart
              </button>
            </div>

            <InfoModel
              open={isInfoModeOpen}
              setOpen={setIsInfoModeOpen}
              buttonText="Ok"
            >
              <div className="popup-description">
                <p>
                  Please join BRONZE health service first. You will be promoted to join this advanced health service only after you have received a minimum participation performance score (PPS) in
                  Bronze service, showing your personal commitment to improve your health.
                </p>
              </div>
            </InfoModel>

            <div>
              <div className="subsgroup-3">
                <img
                  className="akar-icons-circle"
                  alt="Akar icons circle"
                  src="/img/akar-icons-circle-check-fill-6.png"
                />
                <span className="substext-wrapper-3">All Bronze Level Services included</span>
              </div>
              <div className="subsgroup-3">
                <img
                  className="akar-icons-circle"
                  alt="Akar icons circle"
                  src="/img/akar-icons-circle-check-fill-6.png"
                />
                <span className="substext-wrapper-3">Get a quick overview of your health</span>
              </div>
              <div className="subsgroup-3">
                <img
                  className="akar-icons-circle"
                  alt="Akar icons circle"
                  src="/img/akar-icons-circle-check-fill-6.png"
                />
                <span className="substext-wrapper-3">Understand your health trends to manage risks</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
