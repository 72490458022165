import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { toast } from "react-toastify";
import documentText from "../../../static/img/document-text.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import VectorPlusPurple from "../../img/Vector-plus-purple.png";

const AddMedicalReports = ({ setOtherdocIds, otherdocIds, isOpen }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;

    const fileArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setSelectedFiles((prevFiles) => [...prevFiles, ...fileArray]);

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    AxiosInstance.post("document/upload", formData)
      .then((response) => {
        if (response != undefined) {
          const updatedIds = [...otherdocIds];
          response.map((element) => {
            updatedIds.push(element.id);
          });
          setOtherdocIds(updatedIds);
        }
        toast.success("Medical Report uploaded successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    if (isOpen == false) {
      setSelectedFiles([]);
    }
  }, [isOpen]);

  const handleDelete = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <div className="justify-content-between mt-5 mb-5 other-medical-wrapper">
      <div className="other-medical-records mb-4">
        <h5 className="mb-3">Store Other Medical Reports</h5>
        <p>Please click and attach any other medical reports such as X-Ray, MRI, CT SCAN,(other than prescription reports) below</p>
      </div>
      <div style={{ display: "flex", marginTop: "10px" }}>
        {selectedFiles.map((fileUrl, index) => (
          <div
            key={index}
            style={{ marginRight: "10px" }}
            className="documentIconReport"
          >
            {/* <img src={fileUrl} alt={`preview-${index}`} style={{ width: '100px', height: '100px' }} /> */}
            <img
              className="link-icon"
              src={documentText}
              style={{ width: "50px", height: "50px" }}
            />
            <RxCrossCircled
              onClick={() => handleDelete(index)}
              className="reportIconDelete"
            />
          </div>
        ))}
      </div>
      <div className="medical-file-upload-blue medical-file-upload mt-4">
        <input
          type="file"
          accept="image/*"
          id="fileupload"
          className="file-upload-input"
          name="file"
          onChange={handleFileChange}
          multiple
        />
        <img
          src={VectorPlusPurple}
          alt={VectorPlusPurple}
        />
        Add Medical Reports
      </div>
    </div>
  );
};

export default AddMedicalReports;
