import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Card } from "@mui/material";
import ReactSpeedometer from "react-d3-speedometer";
import { kidneyRiskStageText } from "../../KidneyRiskAssessment/kidneyRiskStageText";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { getRiskDiseaseMappingApi } from "../../../constants/apiList";
import Swal from "sweetalert2";

function getColorForScore(totalScore) {
  if (totalScore >= 0 && totalScore < 20) {
    return '#009484';
  } else if (totalScore >= 20 && totalScore < 45) {
    return '#CADB2A';
  } else if (totalScore >= 45 && totalScore < 65) {
    return '#FAA63B';
  } else if (totalScore >= 65 && totalScore <= 90) {
    return '#F05E22';
  } else {
    return 'transparent';
  }
}


const IndianDiabetes = () => {
  const [riskModifiableData, setModifiableData] = useState();
  const [riskNonModifiableData, setNonModifiableData] = useState();
  const [nonModifiableDataValue, setNonModifiableDataValue] = useState(false)
  const [nonModifiableDataRiskLevel, setNonModifiableDataRiskLevel] = useState(false)
  const [formData, setFormData] = useState({
    gender: "",
    age: 0,
    ageValue: 0,
    waistValue: 0,
    waistCircumference: 0,
    physicalActivity: 0,
    familyDiabetes: 0,
    totalScore: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: parseInt(value) };

    let totalScore = 0;
    for (const key in updatedFormData) {
      if (key !== "totalScore" && key !== "weight") {
        totalScore += updatedFormData[key];
      }
    }
    setFormData({ ...updatedFormData, totalScore });
  };
  const getRiskDiseaseMapping = async () => {
    AxiosInstance.get(`${getRiskDiseaseMappingApi}`)
      .then((res) => {
        setModifiableData(res?.modifiableRiskDiseaseMapping);
        setNonModifiableData(res?.unModifiableRiskDiseaseMapping);
        setNonModifiableDataValue(res?.unModifiableRiskDiseaseMapping[0].diabetes.value);
        setNonModifiableDataRiskLevel(res?.unModifiableRiskDiseaseMapping[0].diabetes.riskLevel)
      })
      .catch(() => {
        console.log("Error");
      });
  };

  const ReviewUnderstood = () => {
    const lrid1 = "Diabetes-01";
    const lrid2 = "Diabetes-02";
    console.log("valueeee", nonModifiableDataValue);
    if (nonModifiableDataValue == true) {
      if (nonModifiableDataRiskLevel == "Low") {
        AxiosInstance.post(`/riskAssessment/reviewAndUnderstood/${lrid2}`)
          .then((response) => {
            console.log("response", response);
            Swal.fire({
              text: "Your Response has been Saved!",
              icon: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        AxiosInstance.post(`/riskAssessment/reviewAndUnderstood/${lrid1}`)
          .then((response) => {
            console.log("response", response);
            Swal.fire({
              text: "Your Response has been Saved!",
              icon: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const IndianDiabetesFormData = {
      totalScore: formData.totalScore,
      riskLevel: null,
      flagDiabetesRiskAssessmentAda: false
    }
    AxiosInstance.post("riskAssessment/saveDiabetesRiskAssessmentResult", IndianDiabetesFormData)
      .then((response) => {
        toast.success("Your Data Has been recorded Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTotalScoreChange = (e) => {
    const newFormData = { ...formData, totalScore: e.target.value };
    setFormData(newFormData);
  };

  const backgroundColor = getColorForScore(formData.totalScore);

  useEffect(() => {
    getRiskDiseaseMapping();
    AxiosInstance.get("riskAssessment/getDiabetesRiskAssessmentResult")
      .then((response) => {
        let ageScore = 0;
        if (response.age < 35) {
          ageScore = 0;
        } else if (response.age >= 35 && response.age < 50) {
          ageScore = 20;
        } else {
          ageScore = 30;
        }
        let waistScore = 0;
        if (response.gender === "female") {
          if (response.yourWaistCircumference == "<80 cm") {
            waistScore = 0;
          } else if (response.yourWaistCircumference == "80-89 cm") {
            waistScore = 10;
          } else {
            waistScore = 20;
          }
        } else {
          if (response.yourWaistCircumference == "<90 cm") {
            waistScore = 0;
          } else if (response.yourWaistCircumference == "90-99 cm") {
            waistScore = 10;
          } else {
            waistScore = 20;
          }
        }
        let physicalActivity = 0; 
        if (response.yourLevelOfPhysicalActivity == "Regular vigorous exercise") {
          physicalActivity = 0;
        } else if (response.yourLevelOfPhysicalActivity == "Moderate exercise") {
          physicalActivity = 10;
        } else if (response.yourLevelOfPhysicalActivity == "Regular mild exercise") {
          physicalActivity = 20;
        } else {
          physicalActivity = 30;
        }
        let diabetes = 0;
        if (response.diabetesPersonRelationship == null) {
          diabetes = 0;
        }
        else if (response.diabetesPersonRelationship == "Father" || response.diabetesPersonRelationship == "Mother") {
          diabetes = 10;
        }
        else {
           diabetes = 20;
        }
        const totalScore = ageScore + waistScore + diabetes + physicalActivity;
        setFormData({
          ...formData,
          gender: response.gender,
          ageValue: response.age,
          waistValue: response.yourWaistCircumference,
          familyDiabetes: response.diabetesPersonRelationship,
          physicalActivity: response.yourLevelOfPhysicalActivity,
          totalScore: totalScore,
        });
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  },[])

  return (
    <div>
      <h4 className="cardiac-title">Indian Diabetes Risk Calculator</h4>

      <form onSubmit={handleSubmit}>
        <Card className="diabetes-risk-wrapper" style={{ minHeight: "565px" }}>
          <Row className="diabetes-risk-row" style={{marginTop:"80px"}}>
            <Col lg={5}>
              <p className="field-label">What is your gender?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>
                    {formData.gender == "male" ? "Male" : "Female"}
                  </FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">What is your age?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>
                    {formData.ageValue}
                  </FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">What is your Waist Circumference?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  {formData.gender === "male" ? (
                    <FormControl>{formData.waistValue === "<90 cm" ? "Less than 90cm" : formData.waistValue === "90-99 cm" ? "Between 90cm and 99cm" : "Greater than or equal to 100cm"}</FormControl>
                  ) : formData.gender === "female" ? (
                    <FormControl>{formData.waistValue === "<80 cm" ? "Less than 80cm" : formData.waistValue === "80-89 cm" ? "Between 80cm and 89cm" : "Greater than or equal to 90cm"}</FormControl>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">What is your level of physical activity?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>
                    {formData.physicalActivity}
                  </FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">Do you have a family history of Diabetes?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>
                    {formData.familyDiabetes === "Father : Mother"
                      ? "Both Parents are Diabetics"
                      : formData.familyDiabetes === "Father" || formData.familyDiabetes === "Mother"
                      ? "One Parent is Diabetic"
                      : "No Diabetics in parents"}
                  </FormControl>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="diabetes-risk-row">
            <Col lg={12} className="text-center bg-red">
              {/* <Button variant="contained" type="submit">
                Save
              </Button> */}
            </Col>
          </Row>

          </Card>

          <Row
            className="mt-2 diabetes-risk-row"
            style={{ padding: "15px" , alignItems: "center"}}
          >
            <Col lg={5}>
              <p className="field-label" style={{fontSize: "25px", textAlign:"center"}}>Total Score </p>
            </Col>
            <Col lg={7}>
              <input className="total-score" value={formData.totalScore} style={{ backgroundColor }}
         onChange={handleTotalScoreChange}></input>
            </Col>
          </Row>

          <div className="diabetes-risk-wrapper" style={{minHeight: "250px"}}>
            <Row>
              <Col lg={8}>
                <div className="relative-egfr-block">
                  <ReactSpeedometer
                    height={200}
                    needleHeightRatio={0.7}
                    value={formData.totalScore}
                    currentValueText={`Score : ${formData.totalScore}`}
                    minValue={0}
                    maxValue={90}
                    customSegmentStops={[0, 20, 45, 65, 90]}
                    segmentColors={["#009484", "#CADB2A", "#FAA63B", "#F05E22"]}
                    ringWidth={47}
                    needleTransitionDuration={3333}
                    needleTransition="easeElastic"
                    needleColor={"#F05E22"}
                    textColor={"#F05E22"}
                    innerRadius={0}
                    segmentTransitionDuration={2000}
                  />
                  
                </div>
              </Col>

              <Col lg={4}>
                  <div className="labels-footer">
                    {kidneyRiskStageText.map((entry, index) => (
                      <p key={index}>
                        <span style={{ backgroundColor: `${entry.color}` }}></span>
                        {entry.label}
                      </p>
                    ))}
                  </div>  
              </Col>
            </Row>
          </div>
        </form>
     <button className="btn btn-primary" onClick={ReviewUnderstood} style={{marginTop:"20px"}}>Reviewed and Understood</button>
    </div>
  );
};

export default IndianDiabetes;
