import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getMedicineMonthly } from "../../constants/apiList";
import SymptomList from "../../shared/SymptomList";
import { monthAbbreviations, usePagination } from "../../util/utils";

const MedicinesCloudGraph = () => {
  const [selectedCommData, setSelectedCommData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState();
  const [medicinesData, setMedicinesData] = useState();
  const { currentPage, totalItems, totalPages, currentData, handleNextPage, handlePreviousPage } = usePagination(medicinesData);

  const handleAxisLabel = (index, selectedRow) => {
    setSelectedCommData(selectedRow.medicinesMonthCumsum);
    setSelectedMonth(selectedRow.monthYear);
  };

  const getMedicineMonthlyData = async () => {
    AxiosInstance.get(`${getMedicineMonthly}`)
      .then((res) => {
        const modifiedData = res
          .map((item) => ({
            ...item,
            monthYear: `${monthAbbreviations[item.month - 1]}'${String(item.year).slice(2)}`,
            medicinesMonthCumsum: JSON.parse(item.medicinesMonthCumsum.replace(/'/g, '"')),
          }))
          .filter((item) => Object.keys(item.medicinesMonthCumsum).length > 0);
        setMedicinesData(modifiedData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (medicinesData?.length > 0) {
      setSelectedCommData(medicinesData[medicinesData?.length - 1]?.medicinesMonthCumsum);
      setSelectedMonth(medicinesData[medicinesData?.length - 1].monthYear);
    }
  }, [medicinesData]);

  useEffect(() => {
    getMedicineMonthlyData();
  }, []);

  return (
    <div className="medicines">
      <div className="graph-card-title">
        Which Medicines i'm taking as of (<span className="month-year-as-of">{selectedMonth && selectedMonth}</span>)
      </div>
      <div style={{ width: "100%" }}>
        <SymptomList cumulativeData={selectedCommData} />
      </div>
      <div style={{ margin: "0 auto", width: "100%", display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "end" }}>
        <IconButton
          onClick={handlePreviousPage}
          disabled={currentPage - 1 < 0}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "15px", fontSize: "10px" }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <div className="month-slider-wrapper">
          <ul className="slider-months">
            {currentData?.map((element, index) => {
              return (
                <li
                  key={index}
                  className={`month-item ${selectedMonth === element.monthYear ? "active" : ""}`}
                  onClick={() => handleAxisLabel(index, element)}
                >
                  <Button
                    variant="string"
                    sx={{ padding: 0, minWidth: "auto" }}
                  >
                    {element?.monthYear}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage + 1 >= totalPages}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "15px", fontSize: "10px" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <hr className="card-notes-divider" />
      <div className="graph-footer">
        <p className="graph-note">Note: Please click on the month to see the data.</p>
      </div>
    </div>
  );
};

export default React.memo(MedicinesCloudGraph);
