/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import PropTypes from "prop-types";
import React, { useState } from "react";
import InfoModel from "../../shared/simpleModel/InfoModel";
import { GridAlt } from "../GridAlt";
import LogoutModal from "../LogoutModal/LogoutModal";
import "./style.css";

export const Component = ({ property1, style, override = <GridAlt gridAlt="/img/grid-alt-1.png" />, overviewStyle, text = "Chronology of Events" }) => {
  const [LogoutInfoModel, setLogoutInfoModel] = useState(false);

  const LogoutModel = () => {
    setLogoutInfoModel((prev) => !prev);
  };

  const onClose = () => {
    setLogoutInfoModel((prev) => !prev);
  };
  return (
    <div
      className={`component ${property1}`}
      style={style}
    >
      <div className="menu-item-5">
        <div className="frame-14">
          <div className="frame-15">
            <div
              className="frame-16"
              onClick={LogoutModel}
            >
              {override}
              <div
                className="overview-2"
                style={overviewStyle}
              >
                {property1 === "single-menu-selected" && <>Overview</>}

                {property1 === "single-menu-unelected" && <>{text}</>}
              </div>
            </div>

            <InfoModel
              open={LogoutInfoModel}
              setOpen={setLogoutInfoModel}
              heading="Confirm Logout"
              headerIcon={<ErrorRoundedIcon style={{ color: "#d80000", fontSize: "40px" }} />}
            >
              <div className="popup-description">
                <LogoutModal onClose={onClose}></LogoutModal>
              </div>
            </InfoModel>
          </div>
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {
  property1: PropTypes.oneOf(["single-menu-selected", "single-menu-unelected"]),
  text: PropTypes.string,
};
