import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhoneIcon from "@mui/icons-material/Phone";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getProfile } from "../../configration/HandleCookies";
import ToastMessage from "../../shared/ToastMessage";
import "./ContactUsModal.css";

const ContactUsModal = ({ onContactUsModalClose }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [error, setError] = useState("");

  useEffect(() => {
    // const userDetails = JSON.parse(getProfile());
    const getuserDetails = window.sessionStorage.getItem("userDetails");
    let userDetails = JSON.parse(getuserDetails);
    if (userDetails) {
      const { firstName, lastName, email, phoneNumber, message } = userDetails;

      setFormValues({
        name: firstName + " " + lastName || "",
        email: email || "",
        phoneNumber: phoneNumber || "",
        message: message || "",
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "message" && value.trim() && error) {
      setError("");
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formValues.message.trim()) {
      setError("Please enter the message");
      return;
    }

    AxiosInstance.post("general/save-contact-us", formValues)
      .then((response) => {
        toast.success("Your Enquiry has been sent to our team");
        setTimeout(() => {
          onContactUsModalClose();
        }, 1000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="ContactUs">
      <ToastMessage />
      <Row>
        <Col lg={4}>
          <div className="cardcontainer">
            <div>
              <ApartmentIcon style={{ width: "50px", height: "50px" }}></ApartmentIcon>
            </div>
            <div className="officeheading">Corporate Office:</div>
            <div>HD-090, Chromium, Jogeshwari-Vikhroli Link Road (JVLR), Near L&T Junction, Milind Nagar, Powai-400076</div>
          </div>
        </Col>

        <Col lg={4}>
          <div className="cardcontainer">
            <div>
              <ApartmentIcon style={{ width: "50px", height: "50px" }}></ApartmentIcon>
            </div>
            <div
              className="officeheading"
              style={{ marginBottom: "24px" }}
            >
              Regional Office:
            </div>
            <div>15 Unaddeep Duplex, Sussen Tarsali Road, Baroda, Gujarat 390010, India</div>
          </div>
        </Col>

        <Col lg={4}>
          <div className="cardcontainer">
            <div>
              <PhoneIcon style={{ width: "50px", height: "50px" }}></PhoneIcon>
            </div>
            <div className="officeheading">Customer Care:</div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
              <EmailIcon style={{ color: "blue", marginRight: "10px" }}></EmailIcon>
              <span style={{ color: "blue" }}>customercare@livingscopehealth.com</span>
            </div>
            <div style={{ display: "flex" }}>
              <LocalPhoneIcon style={{ color: "blue", marginRight: "10px" }}></LocalPhoneIcon>
              <span style={{ color: "blue" }}>+91 9137171010</span>
            </div>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col lg={12}>
          <div className="officeheading">Ask Us any Questions</div>
        </Col>
      </Row>

      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg={4}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              margin="normal"
            />
          </Col>

          <Col lg={4}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleChange}
              margin="normal"
            />
          </Col>

          <Col lg={4}>
            <TextField
              fullWidth
              label="Phone Numer"
              variant="outlined"
              name="phoneNumber"
              type="number"
              value={formValues.phoneNumber}
              onChange={handleChange}
              margin="normal"
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <TextField
              fullWidth
              label="Message"
              variant="outlined"
              name="message"
              multiline
              rows={2}
              value={formValues.message}
              onChange={handleChange}
              margin="normal"
              error={Boolean(error)}
              helperText={error}
            />
          </Col>
        </Row>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          style={{ marginTop: "20px", padding: "10px", backgroundColor: "#5353e9" }}
        >
          Send Enquiry
        </Button>
      </form>
    </div>
  );
};

export default ContactUsModal;
