import React, { useState, useEffect } from 'react';
import './SuccessComponent.css'; // Import CSS file for styling

const SuccessComponent = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  // useEffect(() => {
  //   // Simulating a successful order placement
  //   const timer = setTimeout(() => {
  //     setShowSuccess(true);
  //   }, 1000); // Display after 1 second

  //   // Clean up the timer
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div className="success-component">
      <svg className="tick-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0V0z"/>
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
      </svg>
      <p className="tick-icon" >Your order has been placed successfully!</p>
    </div>
  );
};

export default SuccessComponent;
