import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { vaccineReportsData } from "../../shared/dummyData/vaccineReportsData";
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import MuiDialog from "../../shared/MuiDialog";
import { vaccineNames } from "../../shared/dummyData/vaccineNames";

const VaccineSchedule = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isReport, setIsReport] = React.useState(false);
  const [todayDate] = React.useState(new Date().toISOString().split("T")[0]);

  const [formData, setFormData] = useState({
    reportAvailble: false,
    vaccineName: "",
    actualDate: "",
    hospitalName: "",
    doctorName: "",
  });

  const handleUploadReport = () => {
    setIsOpen(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file ? file.name : null);
  };

  const handleInputChange = (event) => {
    console.log("🚀 ~ event:", event);
    const { name, value, type } = event.target;
    if (type === "file") {
      setSelectedFile(
        event.target.files[0] ? event.target.files[0].name : null
      );
    } else if (name === "upload") {
      setIsReport(value === "yes");
    } else {
      // Update value using react-hook-form's setValue
      setFormData(name, value);
    }
  };

  // const handleRadioChange = (event) => {
  //   setIsReport(event.target.value === "yes");
  // };

  const onSubmit = () => {
    console.log("formData:", formData);
  };

  const columnNames = [
    // { id: "age", label: "Age of Vaccination" },
    { id: "vaccine", label: "Vaccine Name" },
    { id: "preferred_date", label: "Preferred Date" },
    { id: "max_date", label: "Max Date" },
    { id: "to_be_given_date", label: "Planned Date" },
    { id: "given_date", label: "Actual Date" },
    { id: "hospital_name", label: "Hospital Name" },
    { id: "doctor_name", label: "Doctor Name" },
    { id: "attach_report", label: "Attach Report" },
    { id: "status", label: "Status" },
  ];

  // const handleVaccineChange = (event) => {
  //   setVaccineName(event.target.value);
  // };

  return (
    <div>
      <Button variant="outlined" onClick={handleUploadReport}>
        Upload Report
      </Button>

      <MuiDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Upload Report"
        onSubmit={onSubmit}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
          <FormControl component="fieldset">
            <Typography variant="h6" gutterBottom>
              Do you have vaccination report?
            </Typography>
            <RadioGroup
              row
              aria-label="upload"
              name="upload"
              defaultValue="no"
              onChange={handleInputChange}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {!isReport && (
            <p>
              Input vaccine name and planned date for vaccination as mentioned
              by your child's pediatrician.
            </p>
          )}
          {isReport && (
            <div>
              <input
                type="file"
                {...register("file")}
                id="file"
                name="file"
                onChange={handleInputChange}
                style={{ display: "none" }}
              />
              <label htmlFor="file">
                <Button variant="contained" component="span">
                  Upload Report
                </Button>
              </label>
              {selectedFile && <p>Selected file: {selectedFile}</p>}
            </div>
          )}
          <>
            {/* <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="vaccineName">Vaccine Name</InputLabel>
                <Select
                  id="vaccineName"
                  label="Vaccine Name"
                  onChange={handleInputChange}
                  value={formData.vaccineName}
                  {...register("vaccineName")}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {vaccineNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <Select
                  id="vaccineName"
                  label="Vaccine Name"
                  value={formData.vaccineName}
                  onChange={handleInputChange}
                  {...register("vaccineName")}
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Vaccine
                  </MenuItem>
                  {vaccineNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            <FormControl sx={{ m: 1, minWidth: 300 }} size="medium">
              <InputLabel id="vaccineName">Vaccine Name</InputLabel>
              <Select
                id="vaccineName"
                label="Vaccine Name"
                onChange={handleInputChange}
                value={formData.vaccineName}
                {...register("vaccineName")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {vaccineNames.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
          <TextField
            id="plannedDate"
            label="Planned Date"
            type="date"
            {...register("plannedDate")}
            fullWidth
            onChange={handleInputChange}
            margin="normal"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: todayDate,
            }}
          />
          {!!isReport && (
            <>
              <TextField
                id="actualDate"
                label="Actual Date"
                type="date"
                {...register("actualDate")}
                fullWidth
                onChange={handleInputChange}
                size="small"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!!isReport}
              />
              <TextField
                id="hospitalName"
                label="Hospital Name"
                {...register("hospitalName")}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="normal"
                disabled={!!isReport}
              />
              <TextField
                id="doctorName"
                label="Doctor Name"
                {...register("doctorName")}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="normal"
                disabled={!!isReport}
              />
            </>
          )}
        </form>
      </MuiDialog>

      <TableContainer
        sx={{ marginTop: "30px" }}
        component={Paper}
        className="report-table"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {columnNames.map((column) => (
                <TableCell
                  component="th"
                  key={column.id}
                  align="center"
                  className="border"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vaccineReportsData.map((row, rowIndex) =>
              row.entries.map((entry, index) => {
                return (
                  <TableRow key={`${rowIndex}-${index}`}>
                    {row.age && index === 0 && (
                      <TableCell
                        scope="row"
                        align="left"
                        rowSpan={row.entries.length}
                        className="border-r border-l"
                      >
                        {row.age}
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.entries[index].vaccine}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.preferredDate[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.maxDate[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.plannedDate[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.actualDate[index]}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.hospitalName[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.doctorName[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.status[index]}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VaccineSchedule;
