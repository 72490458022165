import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Form, FormGroup, FormFeedback, FormText, Label, Input, Row, Col, Button } from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./style.css"

const MonthlyPerformance = () => {
     const rows = [
       { monthYear: "Feb 2024", averageScore: 4.5, areaOfImprovement: "Communication skills" },
       { monthYear: "March 2024", averageScore: 4, areaOfImprovement: "Communication skills" },
       { monthYear: "April 2024", averageScore: 3.5, areaOfImprovement: "Communication skills" },
     ];
    return (
      <>
        <h3>Monthly Performance</h3>
        <TableContainer>
          <Table style={{ border: "1px solid lightgrey", marginTop:"20px" }}>
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">Month/Year</TableCell>
                <TableCell className="tableCell">Average Score</TableCell>
                <TableCell className="tableCell">Area of Improvement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ border: "1px solid lightgrey" }}>{row.monthYear}</TableCell>
                  <TableCell style={{ border: "1px solid lightgrey" }}>{row.averageScore}</TableCell>
                  <TableCell style={{ border: "1px solid lightgrey" }}>{row.areaOfImprovement}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
};
export default MonthlyPerformance;
