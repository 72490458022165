// CartContext.js

import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(false);

  const triggerRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  return (
    <CartContext.Provider value={{ refresh, triggerRefresh }}>
      {children}
    </CartContext.Provider>
  );
};
