import PropTypes from "prop-types";
import React from "react";
import { IconOverview } from "../IconOverview";
import "./NavsWeeklyLog.css";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IconSupport } from "../IconSupport/IconSupport";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

export const NavsWeeklyLog = ({
  property1,
  style,
  frameStyle,
  overviewStyle,
}) => {
    const [familyInfo, setFamilyInfo] = useState(null);
    const [familyRelation, setFamilyRelation] = useState(null);
  const [motherDetail, setMotherDetail] = useState(null);

    const getMotherDetail = () => {
      AxiosInstance.get("/family/getChildMotherDetail")
        .then((response) => {
          setMotherDetail(response);
        })
        .catch((error) => {
          console.log(error);
        });
    };

 
  useEffect(() => {
    if (familyInfo?.age >= 0 && familyInfo?.age < 3) {
      getMotherDetail();
    }
      AxiosInstance.get("profile/get")
        .then((response) => {
          setFamilyInfo(response?.profile);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      AxiosInstance.get("/family/getPatientFamilyProfile")
        .then((response) => {
          setFamilyRelation(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
 return (
   <div className={`navs-weeklyLog ${property1}`} style={style}>
     {familyInfo?.age === null ? (
       <div className="menu-item">
         <div className="frame" style={frameStyle}>
           <div className="frame-wrapper">
             <div className="div">
               {property1 === "default" && <IconSupport></IconSupport>}

               {property1 === "variant-2" && <IconSupport></IconSupport>}

               <div className="component-8">
                 <div className="menu-item-8">
                   <div className="frame-49">
                     <div className="frame-50">
                       <div className="frame-51">
                         <NavLink to="/weekly-log" className="text-wrapper-106 menu-item overview">
                           Daily Log
                         </NavLink>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     ) : familyInfo?.age >= 0 && familyInfo?.age <= 3 ? (
       motherDetail?.diabetes != null || motherDetail?.hyperTension != null ? (
         <div className="menu-item">
           <div className="frame" style={frameStyle}>
             <div className="frame-wrapper">
               <div className="div">
                 {property1 === "default" && <IconSupport></IconSupport>}

                 {property1 === "variant-2" && <IconSupport></IconSupport>}

                 <div className="component-8">
                   <div className="menu-item-8">
                     <div className="frame-49">
                       <div className="frame-50">
                         <div className="frame-51">
                           <NavLink to="/weekly-log" className="text-wrapper-106 menu-item overview">
                             Daily Log
                           </NavLink>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       ) : null
     ) : (
      <div className="menu-item">
        <div className="frame" style={frameStyle}>
          <div className="frame-wrapper">
            <div className="div">
              {property1 === "default" && <IconSupport></IconSupport>}

              {property1 === "variant-2" && <IconSupport></IconSupport>}

              <div className="component-8">
                <div className="menu-item-8">
                  <div className="frame-49">
                    <div className="frame-50">
                      <div className="frame-51">
                        <NavLink
                          to="/weekly-log"
                          className="text-wrapper-106 menu-item overview"
                        >
                          Daily Log
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     )}
   </div>
 );
};

NavsWeeklyLog.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconFilledOverviewIconFilledOverview: PropTypes.string,
};
