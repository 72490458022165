import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import "./LabServices.css";
import TestRequestForm from "../TestCart/TestCart";
import BuildPackage from "../BuildPackage/BuildPackage";
import { useLocation } from "react-router-dom";
import OrderDetail from "../OrderDetail/OrderDetail";
import SaveItem from "../SaveForLater/SaveItem";
import { useCart } from "../TestCart/CartContext";
import PaymentDetail from "../PaymentDetail/PaymentDetail";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
const LabServices = () => {
  const { refresh, triggerRefresh } = useCart();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [forModelOpen, setForModelOpen] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);
  // const [orderId, setOrderId] = useState('');
  const location = useLocation();
  const handleTabChange = (e, tabIndex) => {
    // if(tabIndex === 4){
    //   setOrderId('')
    // }
    console.log(tabIndex);
    triggerRefresh();
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const page  = urlParams.get('page');
    if (page === "testcart") {
      setCurrentTabIndex(1);
    }
  }, [location.search]);
   useEffect(() => {
     AxiosInstance.get("profile/get")
       .then((response) => {
         setFamilyInfo(response?.profile);
       })
       .catch((error) => {
         console.error("Error:", error);
       });
     AxiosInstance.get("/family/getPatientFamilyProfile")
       .then((response) => {
         setFamilyRelation(response);
       })
       .catch((error) => {
         console.log(error);
       });
   }, []);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          sx={{ backgroundColor: "steelblue" }}
        >
          <Tab
            label="Select tests"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Test Cart"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
                <Tab
                  label="Save for later"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "large",
                    color: currentTabIndex === 2 ? "white" : "black",
                    "&.Mui-selected": {
                      color: "white",
                    },
                  }}
                />
            <Tab
              label="Order History"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                color: currentTabIndex === 3 ? "white" : "black",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
              />
            <Tab
              label="Payment History"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                color: currentTabIndex === 4 ? "white" : "black",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
              />
        </Tabs><br/>
        {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
          <>
            <h4 style={{ color: "blue" }}>
              The health Insights/Information presented below is for your child{" "}
              <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
            </h4>
            <br />
          </>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <TestRequestForm setCurrentTabIndex={setCurrentTabIndex}></TestRequestForm>
          </Box>
        )}
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <BuildPackage setCurrentTabIndex={setCurrentTabIndex}></BuildPackage>
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <SaveItem isVisible={false} setCurrentTabIndex={setCurrentTabIndex}></SaveItem>
          </Box>
        )}
        {currentTabIndex === 3 && (
          <Box sx={{ p: 3 }}>
            <OrderDetail setCurrentTabIndex={setCurrentTabIndex}></OrderDetail>
          </Box>
        )}
        {currentTabIndex === 4 && (
          <Box sx={{ p: 3 }}>
            <PaymentDetail setCurrentTabIndex={setCurrentTabIndex}></PaymentDetail>
          </Box>
        )}
  
      </Box>
    </>
  );
};

export default LabServices;
