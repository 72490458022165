import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import "./VaccinationServices.css";
import BuildPackage from "../BuildPackage/BuildPackage";
import VaccineReports from "./VaccineReports";
import VaccineSchedule from "./VaccineSchedule";
import SpecialSituationSchedule from "./SpecialSituationSchedule";
import Vaccinations from "./Vaccinations";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const VaccinationServices = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
     AxiosInstance.get("/family/getPatientFamilyProfile")
       .then((response) => {
         setFamilyRelation(response);
       })
       .catch((error) => {
         console.log(error);
       });
  }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }}>
          <Tab
            label="Recommendation"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Schedule"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Special Situation Schedule"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 2 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
        <br />
        {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
          <>
            <h4 style={{ color: "blue" }}>
              The health Insights/Information presented below is for your child{" "}
              <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
            </h4>
            <br />
          </>
        )}
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <Vaccinations></Vaccinations>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <VaccineReports></VaccineReports>
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <SpecialSituationSchedule></SpecialSituationSchedule>
          </Box>
        )}
      </Box>
    </>
  );
};

export default VaccinationServices;
