import * as yup from "yup";

export const searchPatientSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z]*$/, "Only alphabetical characters are allowed")
    .optional(),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]*$/, "Only alphabetical characters are allowed")
    .optional(),
  hwPatientId: yup
    .string()
    .matches(/^[A-Za-z0-9]*$/, "Only alphabetical characters are allowed")
    .optional(),
});

export const migratePatientSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z]*$/, "Only alphabetical characters are allowed")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]*$/, "Only alphabetical characters are allowed")
    .required("Last name is required"),
  mobileNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile is required")
    .min(10)
    .max(10),
  email: yup.string().email("Invalid email address").required("Email is required"),
  secretKey: yup.string().required("Secret Key required"),
});

export const searchPatientMobileSchema = yup.object().shape({
  mobileNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile is required")
    .min(10)
    .max(10),
});
