import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../Header";
import { IconLogout } from "../../components/IconLogout";
import { SideNav } from "../../components/SideNav";
import ToastMessage from "../../shared/ToastMessage";

const RootLayOut = () => {
  const initialURL = sessionStorage.getItem("initialURL");
  const cme_doctors = sessionStorage.getItem("cme_doctors");
  return (
    <>
      <ToastMessage />
      <div className="main-container">
        {(initialURL == null && cme_doctors == null) && (
          <>
        <div className="sidebar">
          <SideNav
            component={
              <IconLogout
                iconLogout="/img/icon-logout.png"
                style={{
                  height: "21px",
                  left: "unset",
                  minWidth: "21px",
                  position: "relative",
                  top: "unset",
                  width: "unset",
                }}
              />
            }
            navsEventsProperty1="variant-2"
            navsOverviewProperty1="variant-2"
            navsTrendsProperty1="variant-2"
            NavsBenchmarkProperty1="variant-2"
            property1="dark"
          />
        </div>
          </>
        )}
        <div className="content">
          <div className="header">
            <Header />
          </div>
          <div className="scrollable-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default RootLayOut;
