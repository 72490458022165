import React from "react";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const HealthIssuesFrequencyYearly = ({ yearlyTopSymptoms }) => {
  if (!yearlyTopSymptoms || yearlyTopSymptoms.length === 0) {
    return null;
  }

  const allKeys = Array.from(new Set(yearlyTopSymptoms.flatMap((entry) => Object.keys(entry.symptomsMonthCumsum))));

  const getBarColor = (index) => {
    const colors = ["#FFA500", "#5DA5E8", "#1967D2 ", "#43A047"]; // Add more colors if needed
    return colors[index % colors.length];
  };

  return (
    <>
      <div className="graph-card-title">
        Medical Issues as of (<span className="month-year-as-of">2024</span>)
      </div>
      <ResponsiveContainer
        width="100%"
        height={250}
      >
        <BarChart
          data={yearlyTopSymptoms}
          margin={{ top: 20, right: 0, left: 20, bottom: 5 }}
          barGap={0}
          stackOffset="none"
        >
          <XAxis dataKey="year" />
          <YAxis label={{ value: "Symptoms Count", angle: -90, position: "insideBottomLeft" }} />
          <Tooltip
            cursor={false}
            formatter={(value, name) => {
              let issueName = name.split(".")[1];
              return [`${issueName} : ${value}`];
            }}
          />
          <Legend
            verticalAlign="bottom"
            height={36}
            payload={[{ value: "YEAR", type: "line", id: "legend-1", color: "#8884d8" }]}
          />
          {allKeys.map((key, index) => (
            <Bar
              key={key}
              dataKey={`symptomsMonthCumsum.${key}`}
              stackId="a"
              barSize={40}
              fill={getBarColor(index)}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <div className="graph-footer">
        <p className="graph-note">Note : Please click on the year to see the data</p>
      </div>
    </>
  );
};

export default React.memo(HealthIssuesFrequencyYearly);
