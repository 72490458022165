import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import { ElementOverview } from "../ElementOverview/ElementOverview";
import { TrendsToWatch } from "../TrendsToWatch";
import "./ChronologyOfEvents.css";
import { MedicalHistory } from "../MedicalHistory/MedicalHistory";
import { PreventiveTable } from "../PreventiveTestReport/preventiveRecord";
import { ElementChronologyOfLabTests } from "../ElementChronologyOfLabTests";
import { ElementCaseHistory } from "../ElementCaseHistory/ElementCaseHistory";
import { capitalize } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useEffect, useState } from "react";
import { SelfTestReport } from "./SelfTestReport";

const ChronologyOfEvents = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyRelation, setFamilyRelation] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  const [familyInfo, setFamilyInfo] = useState(null);
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          sx={{ backgroundColor: "steelblue" }}
        >
          <Tab
            label="Attach Prescription Records"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Attach Lab Test Report"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Self Test Report"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 2 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Preventive Test Record"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 3 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
        <br />
        {familyRelation?.relationShipWithUser != "wife" &&
          familyRelation != null && (
            <>
              <h4 style={{ color: "blue" }}>
                The health Insights/Information presented below is for your
                child &nbsp;{" "}
                <span style={{ fontSize: "larger" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>
                <h5>
                  please ensure that you are uploading Medical Records for your
                  child{" "}
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  on this screen.
                </h5>
              </h4>
              <br />
            </>
          )}
        {familyRelation?.accessNeeded == true && (
          <>
            <h4 style={{ color: "blue" }}>
              Ask{" "}
              <span style={{ fontSize: "larger" }}>
                {familyInfo?.firstName &&
                  familyInfo.firstName.charAt(0).toUpperCase() +
                    familyInfo.firstName.slice(1).toLowerCase()}
              </span>{" "}
              to fill this form.
            </h4>
          </>
        )}
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <MedicalHistory></MedicalHistory>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <ElementChronologyOfLabTests></ElementChronologyOfLabTests>
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <SelfTestReport></SelfTestReport>
          </Box>
        )}
        {currentTabIndex === 3 && (
          <Box sx={{ p: 3 }}>
            <PreventiveTable></PreventiveTable>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ChronologyOfEvents;
