export const vaccinationsColumns = [
    { id: "vaccine", label: "Vaccine" },
    { id: "Birth", label: "Birth" },
    { id: "6w", label: "6w" },
    { id: "10w", label: "10w" },
    { id: "14w", label: "14w" },
    { id: "6m", label: "6m" },
    { id: "7m", label: "7m" },
    { id: "9m", label: "9m" },
    { id: "12m", label: "12m" },
    { id: "13m", label: "13m" },
    { id: "15m", label: "15m" },
    { id: "16-18m", label: "16-18m" },
    { id: "18-24m", label: "18-24m" },
    { id: "2-4y", label: "2-4y" },
    { id: "4-6y", label: "4-6y" },
    { id: "9-14y", label: "9-14y" },
    { id: "15-18y", label: "15-18y" },
  ];