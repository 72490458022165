import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import ToastMessage from "../../shared/ToastMessage";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const BloodPressure = () => {
  const [systolicBloodPressureGoal, setsystolicBloodPressureGoal] = useState();
  const [diastolicBloodPressureGoal, setdiastolicBloodPressureGoal] =
    useState();
  const [systolicBloodPressureAm, setsystolicBloodPressureAm] = useState();
  const [systolicBloodPressurePm, setsystolicBloodPressurePm] = useState();
  const [diastolicBloodPressureAm, setdiastolicBloodPressureAm] = useState();
  const [diastolicBloodPressurePm, setdiastolicBloodPressurePm] = useState();
  const [DsystolicBloodPressureAm, DsetsystolicBloodPressureAm] = useState();
  const [DsystolicBloodPressurePm, DsetsystolicBloodPressurePm] = useState();
  const [DdiastolicBloodPressureAm, DsetdiastolicBloodPressureAm] = useState();
  const [DdiastolicBloodPressurePm, DsetdiastolicBloodPressurePm] = useState();
  const [pulseRateAm, setPulseRateAm] = useState();
  const [DpulseRateAm, DsetPulseRateAm] = useState();
  const [pulseRatePm, setPulseRatePm] = useState();
  const [DpulseRatePm, DsetPulseRatePm] = useState();
  const [bmi, setbmi] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [heightcm, setheightcm] = useState("");
  const [heightFeet, setheightFeet] = useState("");
  const [heightInches, setheightInches] = useState("");
  const [heightUnit, setheightUnit] = useState("ft");
  const [weightUnit, setweightUnit] = useState("Kg");
  const [editWeightEnabled, setEditWeightEnabled] = useState(false);
  const [editFeetEnabled, setEditFeetEnabled] = useState(false);
  const [editInchesEnabled, setEditInchesEnabled] = useState(false);
  const [editCmEnabled, setEditCmEnabled] = useState(false);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);
  const [motherDetail, setMotherDetail] = useState(null);
  const date = new Date().toISOString().split("T")[0];

   const onHeightChange = (e) => {
     const newHeight = parseFloat(e.target.value);
     setHeight(newHeight);
   };
  const onWeightChange = (e) => {
    const newWeight = parseFloat(e.target.value);
    setWeight(newWeight);
  };
   const calculateBmi = (height, weight) => {
     if (!height || !weight) return "";
     const bmi = weight / ((height / 100) * (height / 100));
     return parseFloat(bmi.toFixed(1));
   };

  const handlesystolicBloodPressureGoalChange = (event) => {
    setsystolicBloodPressureGoal(event.target.value);
  };

  const handlediastolicBloodPressureGoalChange = (event) => {
    setdiastolicBloodPressureGoal(event.target.value);
  };
  const handlebmi = (event) => {
    
    setbmi(event.target.value);
  }
  let checkWeight = weight?.toString();
  const doctorFlag = true;
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const formData = {
      weight: weight,
      weightUnit: weightUnit,
      height: heightUnit === "ft" ? heightFeet + ":" + heightInches : heightcm,
      heightUnit: heightUnit,
      systolicBloodPressureGoal: systolicBloodPressureGoal,
      diastolicBloodPressureGoal: diastolicBloodPressureGoal,
      bmi: bmiValue ? bmiValue : bmi,
    };
    if (DdiastolicBloodPressureAm || DdiastolicBloodPressurePm || DsystolicBloodPressureAm || DsystolicBloodPressurePm || DpulseRateAm || DpulseRatePm) {
      const formData1 = {
        systolicBloodPressureAm: DsystolicBloodPressureAm,
        systolicBloodPressurePm: DsystolicBloodPressurePm,
        diastolicBloodPressureAm: DdiastolicBloodPressureAm,
        diastolicBloodPressurePm: DdiastolicBloodPressurePm,
        pulseRateAm: DpulseRateAm,
        pulseRatePm: DpulseRatePm,
        doctorFlag: true,
      };
          if (
            systolicBloodPressureAm?.length > 3 ||
            DsystolicBloodPressureAm?.length > 3 ||
            DsystolicBloodPressurePm?.length > 3 ||
            systolicBloodPressurePm?.length > 3 ||
            diastolicBloodPressureAm?.length > 3 ||
            DdiastolicBloodPressureAm?.length > 3 ||
            DdiastolicBloodPressurePm?.length > 3 ||
            diastolicBloodPressurePm?.length > 3
          ) {
            toast.error("Systolic & Diastolic cannot be greater then 3 number");
          } else if (checkWeight?.length > 3 || checkWeight <= 0) {
            toast.error("Enter Valid Weight (non negative and not greater then 3 number");
          } else if (heightUnit === "ft" && (heightFeet?.length > 1 || heightInches.length > 2 || heightInches <= 0 || heightFeet <= 0)) {
            toast.error("Enter the Feet and Inches in Range (4'10 - 6'4)");
          } else if (heightUnit === "cm" && (heightcm?.length > 3 || heightcm <= 0)) {
            toast.error("Centimeters height cannot be greater the 3 number");
          } else {
            AxiosInstance.post("dailyLog/saveBloodPressure", formData1)
              .then((response) => {
                toast.success("Form Data submitted successfully");
              })
              .catch((error) => {
                console.log(error);
              });
          }
    }
    if (systolicBloodPressureAm || systolicBloodPressurePm || diastolicBloodPressureAm || diastolicBloodPressurePm || pulseRateAm || pulseRatePm) {
      const formData1 = {
        systolicBloodPressureAm: systolicBloodPressureAm,
        systolicBloodPressurePm: systolicBloodPressurePm,
        diastolicBloodPressureAm: diastolicBloodPressureAm,
        diastolicBloodPressurePm: diastolicBloodPressurePm,
        pulseRateAm: pulseRateAm,
        pulseRatePm: pulseRatePm,
        doctorFlag: false,
      };
          if (
            systolicBloodPressureAm?.length > 3 ||
            DsystolicBloodPressureAm?.length > 3 ||
            DsystolicBloodPressurePm?.length > 3 ||
            systolicBloodPressurePm?.length > 3 ||
            diastolicBloodPressureAm?.length > 3 ||
            DdiastolicBloodPressureAm?.length > 3 ||
            DdiastolicBloodPressurePm?.length > 3 ||
            diastolicBloodPressurePm?.length > 3
          ) {
            toast.error("Systolic & Diastolic cannot be greater then 3 number");
          } else if (checkWeight?.length > 3 || checkWeight <= 0) {
            toast.error("Enter Valid Weight (non negative and not greater then 3 number");
          } else if (heightUnit === "ft" && (heightFeet?.length > 1 || heightInches.length > 2 || heightInches <= 0 || heightFeet <= 0)) {
            toast.error("Enter the Feet and Inches in Range (4'10 - 6'4)");
          } else if (heightUnit === "cm" && (heightcm?.length > 3 || heightcm <= 0)) {
            toast.error("Centimeters height cannot be greater the 3 number");
          } else {
            AxiosInstance.post("dailyLog/saveBloodPressure", formData1)
              .then((response) => {
                toast.success("Form Data submitted successfully");
              })
              .catch((error) => {
                console.log(error);
              });
          }
    }
    if (checkWeight?.length > 3 || checkWeight <= 0) {
      toast.error("Enter Valid Weight (non negative and not greater then 3 number")
    } else if (heightUnit === "ft" && heightUnit === "ft" && (heightFeet?.length > 1 || heightInches.length > 2 || heightInches <= 0 || heightFeet <= 0)) {
      toast.error("Enter the Feet and Inches in Range (4'10 - 6'4)");
    } else if (heightUnit === "cm" &&( heightcm?.length > 3 || heightcm <= 0)) {
      toast.error("Centimeters height cannot be greater the 3 number");
    } else {
      AxiosInstance.post("dailyLog/saveBloodPressureGoalAndWeight", formData)
        .then((response) => {
          toast.success("Height and Weight is saved!")
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    AxiosInstance.get("dailyLog/getDailyLogBloodPressure")
      .then((response) => {
        // setProfileDetails(response);
        setsystolicBloodPressureGoal(
          response?.systolicBloodPressureGoal
            ? response.systolicBloodPressureGoal
            : systolicBloodPressureGoal
        );
        setdiastolicBloodPressureGoal(
          response?.diastolicBloodPressureGoal
            ? response.diastolicBloodPressureGoal
            : diastolicBloodPressureGoal
        );
        setbmi(
          response?.bmi
            ? response.bmi
            :bmi
        )
         const heightData = response.height.split(":");
         if (heightData.length === 2) {
           setheightFeet(heightData[0]);
           setheightInches(heightData[1]);
         }
         setWeight(response.weight || "");
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);
  let heightbmi;
  if (heightcm) {
    heightbmi = heightcm;
  } else if (heightFeet && heightInches) {
    heightbmi = heightFeet * 30.48 + heightInches * 2.54;
  } else {
    heightbmi = null;
  }
  const bmiValue = heightbmi !== null ? calculateBmi(heightbmi, weight) : null;
   useEffect(() => {
     AxiosInstance.get("profile/get")
       .then((response) => {
         setFamilyInfo(response?.profile);
       })
       .catch((error) => {
         console.error("Error:", error);
       });
      AxiosInstance.get("/family/getPatientFamilyProfile")
        .then((response) => {
          setFamilyRelation(response);
        })
        .catch((error) => {
          console.log(error);
        });
      AxiosInstance.get("/family/getChildMotherDetail")
        .then((response) => {
          setMotherDetail(response);
        })
        .catch((error) => {
          console.log(error);
        });
   }, []);
  return (
    <>
      {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
        <>
          <h4 style={{ color: "blue", marginTop: "-35px" }}>
            Please fill this form for your child{" "}
            <span style={{ fontSize: "xx-large" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
          </h4>
          <br />
        </>
      )}
      <ToastMessage></ToastMessage>
      <div>
        {(familyRelation?.relationShipWithUser == "son" || familyRelation?.relationShipWithUser == "daughter") ? (
          <>
            <div style={{ color: "blue", marginTop: "-20px" }}>
              <p>
                Your child
                <b>
                  <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
                </b>
                &nbsp; is at risk for high blood pressure because of following risk factor(s)
              </p>
              <ul style={{ listStyle: "disc" }}>
                <li>Family History of hypertension </li>
                {motherDetail?.diabetes != null ? <li>Family History of Diabetes (mother)</li> : ""}
              </ul>
              <p>Please ensure that your child’s blood pressure is measured by the pediatrician and record the same here in the daily log. </p>
            </div>
            <br />
            <p>Thereafter, ensure that BP is measured on an annual basis and recorded here for future monitoring of risk and preventive treatment at the right age.</p>
          </>
        ) : (
          <>
            <h3>INSTRUCTIONS</h3>
            <p>Measure your blood pressure twice a day-morning and late afternoon at about the same time every day.</p>
            <p>For best result, sit comfortably with both feet on the floor for at least 10 minutes before taking a measurement.</p>
            <p>When you measure your blood pressure , rest your arm on a table so the blood pressure cuff is about the same height as you heart</p>
            <p>Record your blood pressure on this log and show to your doctor at every visit.</p>
          </>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: "20px" }}>
          {(familyInfo?.age < 3 && familyInfo?.age != null) ? <span> <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>'s Blood Pressure Goal - </span> : <span>My Blood Pressure Goal - </span>}
          <span>
            Systolic
            <input
              style={{ marginLeft: "5px", width: "100px", textIndent: "10px", textAlign: "center" }}
              type="number"
              onChange={handlesystolicBloodPressureGoalChange}
              value={systolicBloodPressureGoal}
            ></input>
          </span>
          <span style={{ marginLeft: "10px" }}>
            Diastolic
            <input
              style={{ marginLeft: "5px", width: "100px", textIndent: "10px", textAlign: "center" }}
              type="number"
              onChange={handlediastolicBloodPressureGoalChange}
              value={diastolicBloodPressureGoal}
            ></input>
          </span>
        </div>
        {(familyInfo?.age > 3 || familyInfo == null || familyInfo?.age == null) && (
          <>
            <div>
              <TableContainer className="risk-table-wrapper">
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#aaa" }}>
                    <TableRow>
                      <TableCell>Today's Date</TableCell>
                      <TableCell align="center">Reading measurement at Home</TableCell>
                      <TableCell align="center">AM</TableCell>
                      <TableCell align="center">PM</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell rowSpan={2}>{new Date().toLocaleDateString()}</TableCell>
                      <TableCell scope="row" style={{ textAlign: "center" }}>
                        Systolic
                      </TableCell>
                      <TableCell align="center" className={formSubmitted && systolicBloodPressureAm?.length > 3 ? "required-border" : ""}>
                        <input
                          style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                          type="number"
                          value={systolicBloodPressureAm}
                          onChange={(e) => setsystolicBloodPressureAm(e.target.value)}
                        ></input>
                      </TableCell>
                      <TableCell align="center" className={formSubmitted && systolicBloodPressurePm?.length > 3 ? "required-border" : ""}>
                        <input
                          style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                          value={systolicBloodPressurePm}
                          type="number"
                          onChange={(e) => setsystolicBloodPressurePm(e.target.value)}
                        ></input>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row" style={{ textAlign: "center" }}>
                        Diastolic
                      </TableCell>
                      <TableCell align="center" className={formSubmitted && diastolicBloodPressureAm?.length > 3 ? "required-border" : ""}>
                        <input
                          style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                          value={diastolicBloodPressureAm}
                          type="number"
                          onChange={(e) => setdiastolicBloodPressureAm(e.target.value)}
                        ></input>
                      </TableCell>
                      <TableCell align="center" className={formSubmitted && diastolicBloodPressurePm?.length > 3 ? "required-border" : ""}>
                        <input
                          style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                          value={diastolicBloodPressurePm}
                          type="number"
                          onChange={(e) => setdiastolicBloodPressurePm(e.target.value)}
                        ></input>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell scope="row" style={{ textAlign: "center" }}>
                        Pulse Rate
                      </TableCell>
                      <TableCell align="center">
                        <input style={{ width: "100px", textIndent: "10px", textAlign: "center" }} value={pulseRateAm} type="number" onChange={(e) => setPulseRateAm(e.target.value)}></input>
                      </TableCell>
                      <TableCell align="center">
                        <input style={{ width: "100px", textIndent: "10px", textAlign: "center" }} value={pulseRatePm} type="number" onChange={(e) => setPulseRatePm(e.target.value)}></input>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}

        <div>
          <TableContainer className="risk-table-wrapper">
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#aaa" }}>
                <TableRow>
                  <TableCell>Today's Date</TableCell>
                  {familyInfo?.age > 3 || familyInfo == null || familyInfo?.age == null ? (
                    <TableCell align="center">Reading measurement by Doctor </TableCell>
                  ) : (
                    <TableCell align="center">Reading measurement by Pediatrician </TableCell>
                  )}
                  <TableCell align="center">AM</TableCell>
                  <TableCell align="center">PM</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={2}>{new Date().toLocaleDateString()}</TableCell>
                  <TableCell scope="row" style={{ textAlign: "center" }}>
                    Systolic
                  </TableCell>
                  <TableCell align="center" className={formSubmitted && DsystolicBloodPressureAm?.length > 3 ? "required-border" : ""}>
                    <input
                      style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                      type="number"
                      value={DsystolicBloodPressureAm}
                      onChange={(e) => DsetsystolicBloodPressureAm(e.target.value)}
                    ></input>
                  </TableCell>
                  <TableCell align="center" className={formSubmitted && DsystolicBloodPressurePm?.length > 3 ? "required-border" : ""}>
                    <input
                      style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                      value={DsystolicBloodPressurePm}
                      type="number"
                      onChange={(e) => DsetsystolicBloodPressurePm(e.target.value)}
                    ></input>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row" style={{ textAlign: "center" }}>
                    Diastolic
                  </TableCell>
                  <TableCell align="center" className={formSubmitted && DdiastolicBloodPressureAm?.length > 3 ? "required-border" : ""}>
                    <input
                      style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                      value={DdiastolicBloodPressureAm}
                      type="number"
                      onChange={(e) => DsetdiastolicBloodPressureAm(e.target.value)}
                    ></input>
                  </TableCell>
                  <TableCell align="center" className={formSubmitted && DdiastolicBloodPressurePm?.length > 3 ? "required-border" : ""}>
                    <input
                      style={{ width: "100px", textIndent: "10px", textAlign: "center" }}
                      value={DdiastolicBloodPressurePm}
                      type="number"
                      onChange={(e) => DsetdiastolicBloodPressurePm(e.target.value)}
                    ></input>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell scope="row" style={{ textAlign: "center" }}>
                    Pulse Rate
                  </TableCell>
                  <TableCell align="center">
                    <input style={{ width: "100px", textIndent: "10px", textAlign: "center" }} value={DpulseRateAm} type="number" onChange={(e) => DsetPulseRateAm(e.target.value)}></input>
                  </TableCell>
                  <TableCell align="center">
                    <input style={{ width: "100px", textIndent: "10px", textAlign: "center" }} value={DpulseRatePm} type="number" onChange={(e) => DsetPulseRatePm(e.target.value)}></input>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div>
          <TableContainer className="risk-table-wrapper">
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#aaa" }}>
                <TableRow>
                  <TableCell>Today's Date</TableCell>
                  <TableCell align="center">Weight</TableCell>
                  <TableCell align="center">Height</TableCell>
                  <TableCell style={{ marginLeft: "30px" }}>BMI</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{new Date().toLocaleDateString()}</TableCell>
                  <TableCell align="center">
                    {editWeightEnabled ? (
                      <input
                        style={{
                          textAlign: "center",
                          width: "100px",
                          textIndent: "10px",
                          marginRight: "10px",
                          border: formSubmitted && checkWeight?.length > 3 ? "2px solid red" : "",
                        }}
                        type="number"
                        value={weight}
                        onChange={(e) => {
                          setWeight(e.target.value);
                          onWeightChange(e);
                        }}
                      />
                    ) : (
                      <>
                        <input
                          style={{
                            textAlign: "center",
                            width: "100px",
                            textIndent: "10px",
                            marginRight: "10px",
                            border: formSubmitted && checkWeight?.length > 3 ? "2px solid red" : "",
                          }}
                          type="number"
                          onChange={(e) => {
                            setWeight(e.target.value);
                            onWeightChange(e);
                          }}
                        />
                      </>
                    )}
                    <select onChange={(e) => setweightUnit(e.target.value)} defaultValue="kg">
                      <option value="kg">kg</option>
                      <option value="lbs">lbs</option>
                    </select>
                  </TableCell>
                  {heightUnit == "ft" ? (
                    <TableCell align="center">
                      {editFeetEnabled ? (
                        <>
                          <input
                            style={{
                              textAlign: "center",
                              border: formSubmitted && heightFeet?.length > 1 ? "2px solid red" : "",
                              width: "50px",
                              textIndent: "10px",
                              marginRight: "10px",
                            }}
                            onChange={(e) => {
                              setheightFeet(e.target.value);
                              onHeightChange(e);
                            }}
                          ></input>
                          <span>feet</span>
                          <input
                            style={{
                              textAlign: "center",
                              border: formSubmitted && heightInches?.length > 2 ? "2px solid red" : "",
                              width: "50px",
                              textIndent: "10px",
                              margin: "0px 10px 0px 10px",
                            }}
                            onChange={(e) => setheightInches(e.target.value)}
                          ></input>
                          <span style={{ marginRight: "20px" }}>inches</span>
                          <select
                            onChange={(e) => {
                              setheightUnit(e.target.value);
                              onHeightChange(e);
                            }}
                          >
                            <option value="ft">ft</option>
                            <option value="cm">cm</option>
                          </select>
                        </>
                      ) : (
                        <>
                          <input
                            style={{
                              textAlign: "center",
                              border: formSubmitted && heightFeet?.length > 1 ? "2px solid red" : "",
                              width: "50px",
                              textIndent: "10px",
                              marginRight: "10px",
                            }}
                            onChange={(e) => setheightFeet(e.target.value)}
                          ></input>
                          <span>feet</span>
                          <input
                            style={{
                              textAlign: "center",
                              border: formSubmitted && heightInches?.length > 2 ? "2px solid red" : "",
                              width: "50px",
                              textIndent: "10px",
                              margin: "0px 10px 0px 10px",
                            }}
                            onChange={(e) => {
                              setheightInches(e.target.value);
                              onHeightChange(e);
                            }}
                          ></input>
                          <span style={{ marginRight: "20px" }}>inches</span>
                          <select
                            onChange={(e) => {
                              setheightUnit(e.target.value);
                              onHeightChange(e);
                            }}
                          >
                            <option value="ft">ft</option>
                            <option value="cm">cm</option>
                          </select>
                        </>
                      )}
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      {editCmEnabled ? (
                        <>
                          <input
                            style={{ textAlign: "center", width: "50px", textIndent: "10px", border: formSubmitted && heightcm?.length > 3 ? "2px solid red" : "" }}
                            onChange={(e) => setheightcm(e.target.value)}
                          ></input>
                          <span style={{ marginRight: "20px" }}>cm</span>
                          <select onChange={(e) => setheightUnit(e.target.value)}>
                            <option value="ft">ft</option>
                            <option value="cm">cm</option>
                          </select>
                        </>
                      ) : (
                        <>
                          <input
                            style={{ textAlign: "center", width: "50px", textIndent: "10px", border: formSubmitted && heightcm?.length > 3 ? "2px solid red" : "" }}
                            onChange={(e) => setheightcm(e.target.value)}
                          ></input>
                          <span style={{ marginRight: "15px", marginLeft: "15px" }}>cm</span>
                          <select onChange={(e) => setheightUnit(e.target.value)}>
                            <option value="ft">ft</option>
                            <option value="cm">cm</option>
                          </select>
                        </>
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    <input type="text" value={bmiValue} onChange={handlebmi} style={{ width: "50px", textAlign: "center" }} disabled></input>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            sx={{
              background: "#5353e9",
              color: "#fff",
              minWidth: "180px",
              "&:hover": { background: "#c60176" },
            }}
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default BloodPressure;
