import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import "../LoginOtp/style.css";
import "./DoctorScreens.css";

export const DoctorLoginWithOtp = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");

  const onNumberChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setNumber(result);
  };

  const login = async () => {
    const doctorType = "BRAND_PARTNER";
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}doctor-verify/sendOtp/${number}/${doctorType}`
      )
      .then((response) => {
        toast.success("OTP sent Successfully");
        setTimeout(() => {
          navigate(`/doctor-verication-otp/${number}`);
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="login-phone-screen">
      <div className="doctorBackground">
        <ToastMessage />
        <div className="group-18">
          <img
            className="health-buddy"
            alt="Health buddy"
            src="/img/AppLogoWithName.png"
          />
        </div>

        <div className="loginContainer">
          <div className="overlap-9">
            <div className="text-wrapper-19">Doctor's Login</div>
            <p className="text-wrapper-46">
              Kindly provide a valid phone number as you will receive a message
              containing the OTP code.
            </p>

            <div className="group-17">
              <div className="text-wrapper-23">Phone Number</div>

              <div className="overlap-group-7">
                <img
                  className="icon-feather-phone-3"
                  alt="Icon feather phone"
                  src="/img/icon-feather-phone.png"
                />
                <input
                  type="tel"
                  onChange={onNumberChange}
                  value={number}
                  className="phoneNumberInput"
                  placeholder="Phone Number"
                  maxLength="10"
                ></input>
              </div>
            </div>

            <button
              className={`${number === "" ? "group-16disabled" : "group-16"}`}
              disabled={number === ""}
              onClick={login}
            >
              Send OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
