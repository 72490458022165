import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Label } from "@mui/icons-material";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

function CorporateGeneralInformation() {
  // State to store form data and validation errors
  const [formData, setFormData] = useState({
    organizationName: "",
    corporateAddress: "",
    officeState: "",
    officeCity: "",
    officePinCode: "",
    totalLocations: "",
    totalEmployees: "",
    industrySector: "",
    contactName: "",
    corporatePhone: "",
    contactMobile: "",
    email: "",
    subscriptionStartDate: "",
    file: null,
    locations: [
      {
        locationAddress: "",
        locationEmployees: "",
        state: "",
        city: "",
        pinCode: "",
      }, // Initial row
    ],
  });

  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle change for each input field
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error on change
    // Show alert if a file is successfully selected
    if (type === "file" && files.length > 0) {
      setFileName(files[0].name); // Store the file name
      toast.success("File attached successfully.");
    }
  };

  // Handle change for each location row
  const handleLocationChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedLocations = [...prevData.locations];
      updatedLocations[index][name] = value;
      return { ...prevData, locations: updatedLocations };
    });
    setErrors((prevErrors) => {
      const updatedLocationErrors = prevErrors.locations
        ? [...prevErrors.locations]
        : [];
      if (updatedLocationErrors[index]) {
        updatedLocationErrors[index][name] = "";
      }
      return { ...prevErrors, locations: updatedLocationErrors };
    });
  };

  // Add a new row for location
  const addLocationRow = () => {
    setFormData((prevData) => ({
      ...prevData,
      locations: [
        ...prevData.locations,
        {
          locationAddress: "",
          locationEmployees: "",
          state: "",
          city: "",
          pinCode: "",
        },
      ],
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      locations: [...(prevErrors.locations || []), {}], // Initialize error object for new row
    }));
  };

  // Validate form data
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Check required fields in main form
    const requiredFields = [
      "organizationName",
      "corporateAddress",
      "officeState",
      "officeCity",
      "officePinCode",
      "totalLocations",
      "totalEmployees",
      "industrySector",
      "contactName",
      "corporatePhone",
      "contactMobile",
      "email",
      "subscriptionStartDate",
      "file",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
        valid = false;
      }
    });

    // Check required fields in each location
    newErrors.locations = formData.locations.map((location) => {
      const locationErrors = {};
      if (!location.locationAddress)
        locationErrors.locationAddress = "This field is required";
      if (!location.locationEmployees)
        locationErrors.locationEmployees = "This field is required";
      if (!location.state) locationErrors.state = "This field is required";
      if (!location.city) locationErrors.city = "This field is required";
      if (!location.pinCode) locationErrors.pinCode = "This field is required";
      if (Object.keys(locationErrors).length > 0) valid = false;
      return locationErrors;
    });

    setErrors(newErrors);
    return valid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    console.log("handle");
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      const submissionData = {
        organizationName: formData.organizationName,
        totalNumberOfLocation: Number(formData.totalLocations),
        industrySector: formData.industrySector,
        corporateOfficeAddress: formData.corporateAddress,
        officeState: formData.officeState,
        officeCity: formData.officeCity,
        officePinCode: formData.officePinCode,
        totalNumberOfEmployees: Number(formData.totalEmployees),
        contactName: formData.contactName,
        corporatePhoneNumber: formData.corporatePhone,
        contactMobile: formData.contactMobile,
        email: formData.email,
        subscriptionStartDate: formData.subscriptionStartDate,
        corporateOfficeLocations: formData.locations.map((location) => ({
          ...(location.id && { id: location.id }),
          address: location.locationAddress,
          numberOfEmployees: Number(location.locationEmployees),
          state: location.state,
          city: location.city,
          pinCode: location.pinCode,
        })),
      };
      AxiosInstance.post("corporate/create-general-info", submissionData)
        .then((response) => {
          AxiosInstance.get("corporate/get-general-info/")
            .then((response) => {
              if (response != null) {
                console.log("corporate get data", response);
              }
            })
            .catch((err) => {
              console.log("err: ", err);
            });
          const excelfile = new FormData();
          excelfile.append("file", formData.file);
          AxiosInstance.post(
            "corporate/employee-list-import?empType=New",
            excelfile
          )
            .then((response) => {
              toast.success("Details Saved successfully.");
              setIsSubmitting(false);
            })
            .catch((err) => {
              console.log("err: ", err);
              setIsSubmitting(false);
            });
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  useEffect(() => {
    AxiosInstance.get("corporate/get-general-info/")
      .then((response) => {
        if (response) {
          setFormData({
            organizationName: response.organizationName || "",
            corporateAddress: response.corporateOfficeAddress || "",
            officeState: response.officeState || "",
            officeCity: response.officeCity || "",
            officePinCode: response.officePinCode || "",
            totalLocations: response.totalNumberOfLocation || "",
            totalEmployees: response.totalNumberOfEmployees || "",
            industrySector: response.industrySector || "",
            contactName: response.contactName || "",
            corporatePhone: response.corporatePhoneNumber || "",
            contactMobile: response.contactMobile || "",
            email: response.email || "",
            subscriptionStartDate: response.subscriptionStartDate || "",
            file: null, // Assuming no initial file
            locations: response.corporateOfficeLocations.map((location) => ({
              ...(location.id && { id: location.id }),
              locationAddress: location.address || "",
              locationEmployees: location.numberOfEmployees || "",
              state: location.state || "",
              city: location.city || "",
              pinCode: location.pinCode || "",
            })),
          });
        }
      })
      .catch((err) => {
        console.log("Error loading corporate data:", err);
      });
  }, []);

  return (
    <Paper elevation={3} style={{ padding: "20px", margin: "auto" }}>
      <Typography variant="h5" gutterBottom>
        Company Information
      </Typography>

      <Typography variant="subtitle1">
        Please complete the basic information about your organization below.
      </Typography>

      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        {/* Organization Details */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Organization Name"
            name="organizationName"
            variant="outlined"
            value={formData.organizationName}
            onChange={handleChange}
            error={!!errors.organizationName}
            helperText={errors.organizationName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Corporate Office Address"
            name="corporateAddress"
            variant="outlined"
            value={formData.corporateAddress}
            onChange={handleChange}
            error={!!errors.corporateAddress}
            helperText={errors.corporateAddress}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="State"
            name="officeState"
            variant="outlined"
            value={formData.officeState}
            onChange={handleChange}
            error={!!errors.officeState}
            helperText={errors.officeState}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="City"
            name="officeCity"
            variant="outlined"
            value={formData.officeCity}
            onChange={handleChange}
            error={!!errors.officeCity}
            helperText={errors.officeCity}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Pin Code"
            name="officePinCode"
            variant="outlined"
            value={formData.officePinCode}
            onChange={handleChange}
            error={!!errors.officePinCode}
            helperText={errors.officePinCode}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Total Number of Locations"
            name="totalLocations"
            variant="outlined"
            type="number"
            value={formData.totalLocations}
            onChange={handleChange}
            error={!!errors.totalLocations}
            helperText={errors.totalLocations}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Total Number of Employees"
            name="totalEmployees"
            variant="outlined"
            type="number"
            value={formData.totalEmployees}
            onChange={handleChange}
            error={!!errors.totalEmployees}
            helperText={errors.totalEmployees}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Industry Sector(s)"
            name="industrySector"
            variant="outlined"
            value={formData.industrySector}
            onChange={handleChange}
            error={!!errors.industrySector}
            helperText={errors.industrySector}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Contact Name"
            name="contactName"
            variant="outlined"
            value={formData.contactName}
            onChange={handleChange}
            error={!!errors.contactName}
            helperText={errors.contactName}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Corporate Phone Number"
            name="corporatePhone"
            variant="outlined"
            value={formData.corporatePhone}
            onChange={handleChange}
            error={!!errors.corporatePhone}
            helperText={errors.corporatePhone}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Contact Mobile"
            name="contactMobile"
            variant="outlined"
            value={formData.contactMobile}
            onChange={handleChange}
            error={!!errors.contactMobile}
            helperText={errors.contactMobile}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>

        {/* Location Subscription */}
        <Grid item xs={12}>
          <Paper
            elevation={3}
            style={{
              padding: "16px",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          >
            <Grid item xs={12} className="mb-2">
              <Typography variant="h6">
                Which office location(s) would like to subscribe for Uncurl:
                Health?
              </Typography>
            </Grid>

            {formData.locations.map((location, index) => (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                key={index}
                style={{ marginBottom: "16px" }}
              >
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    variant="outlined"
                    type="text"
                    value={location.state}
                    onChange={(e) => handleLocationChange(index, e)}
                    error={
                      !!(errors.locations && errors.locations[index]?.state)
                    }
                    helperText={
                      errors.locations && errors.locations[index]?.state
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    variant="outlined"
                    type="text"
                    value={location.city}
                    onChange={(e) => handleLocationChange(index, e)}
                    error={
                      !!(errors.locations && errors.locations[index]?.city)
                    }
                    helperText={
                      errors.locations && errors.locations[index]?.city
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Pin Code"
                    name="pinCode"
                    variant="outlined"
                    type="number"
                    value={location.pinCode}
                    onChange={(e) => handleLocationChange(index, e)}
                    error={
                      !!(errors.locations && errors.locations[index]?.pinCode)
                    }
                    helperText={
                      errors.locations && errors.locations[index]?.pinCode
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="locationAddress"
                    variant="outlined"
                    value={location.locationAddress}
                    onChange={(e) => handleLocationChange(index, e)}
                    error={
                      !!(
                        errors.locations &&
                        errors.locations[index]?.locationAddress
                      )
                    }
                    helperText={
                      errors.locations &&
                      errors.locations[index]?.locationAddress
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Number of Employees"
                    name="locationEmployees"
                    variant="outlined"
                    type="number"
                    value={location.locationEmployees}
                    onChange={(e) => handleLocationChange(index, e)}
                    error={
                      !!(
                        errors.locations &&
                        errors.locations[index]?.locationEmployees
                      )
                    }
                    helperText={
                      errors.locations &&
                      errors.locations[index]?.locationEmployees
                    }
                  />
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={addLocationRow}
                style={{
                  backgroundColor: "#ffeb3b",
                  color: "black",
                  height: "100%",
                }}
              >
                ADD more location
              </Button>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item xs={6} sm={5}>
            <Typography variant="h6">
              When would you like the Subscription to Start?
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              name="subscriptionStartDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={formData.subscriptionStartDate}
              onChange={handleChange}
              error={!!errors.subscriptionStartDate}
              helperText={errors.subscriptionStartDate}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={3}
            style={{
              padding: "16px",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Please select the appropriate subscription plan name for each
                employee from the "Corporate Subscription Plans" page.
              </Typography>
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ marginBottom: "10px" }}
              >
                Once uploaded you can check this list in the "Employee List"
                Tab.
              </Typography>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6">
                  Please upload a list of employees:
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  component="label"
                  color="secondary"
                  startIcon={<UploadFileIcon />}
                  fullWidth
                >
                  Upload Employee List
                  <input
                    type="file"
                    name="file"
                    hidden
                    onChange={handleChange}
                  />
                </Button>
                {errors.file && (
                  <Typography color="error">{errors.file}</Typography>
                )}
                {fileName && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginTop: "8px" }}
                  >
                    Attached file: {fileName}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: "8px" }}
                >
                  <strong>Note:</strong> Please attach the Excel sheet in the
                  following sequence - Employee ID (if any), Location City,
                  First Name, Last Name, Middle Initial (if any), Mobile Number,
                  Email, Subscription Plan Name.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className="mb-2"
            fullWidth
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" /> // Spinner inside button
            ) : (
              "Submit"
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CorporateGeneralInformation;
