import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import BloodPressure from "../BloodPressure/BloodPressure";
import BloodSugar from "../BloodSugar/BloodSugar";
import DailyLogTrendingCharts from "../DailyLogTrendingCharts/DailyLogTrendingCharts";
import "./WeeklyLog.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
const WeeklyLog = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);
  const [motherDetail, setMotherDetail] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
    const getMotherDetail = () => {
      AxiosInstance.get("/family/getChildMotherDetail")
        .then((response) => {
          setMotherDetail(response);
        })
        .catch((error) => {
          console.log(error);
        });
    };

  useEffect(() => {
    if (familyInfo?.age >= 0 && familyInfo?.age < 3) {
      getMotherDetail();
    }
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
     AxiosInstance.get("/family/getPatientFamilyProfile")
       .then((response) => {
         setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  let tabs = [];
  let contents = [];
  if (familyInfo?.age === null) {
    tabs.push(
      <Tab
        key="blood-pressure"
        label="Blood Pressure"
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "large",
          color: currentTabIndex === 0 ? "white" : "black",
          "&.Mui-selected": {
            color: "white",
          },
        }}
      />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="blood-pressure-content">
        <BloodPressure></BloodPressure>
      </Box>
    );
    tabs.push(
      <Tab
        key="blood-sugar"
        label="Blood Sugar"
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "large",
          color: currentTabIndex === 1 ? "white" : "black",
          "&.Mui-selected": {
            color: "white",
          },
        }}
      />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="blood-sugar-content">
        <BloodSugar></BloodSugar>
      </Box>
    );
    tabs.push(
      <Tab
        key="trending-charts"
            label="Trending Charts"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 2 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
    );
      contents.push(
        <Box sx={{ p: 3 }} key="trending-charts-content">
          <DailyLogTrendingCharts></DailyLogTrendingCharts>
        </Box>
      );
  } else if (familyInfo?.age >= 0 && familyInfo?.age < 3) {
    if (motherDetail?.hyperTension?.startsWith("Hypertension")) {
    tabs.push(
      <Tab
        key="blood-pressure"
        label="Blood Pressure"
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "large",
          color: currentTabIndex === 0 ? "white" : "black",
          "&.Mui-selected": {
            color: "white",
          },
        }}
      />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="blood-pressure-content">
        <BloodPressure></BloodPressure>
      </Box>
    );
  }
  if (motherDetail?.diabetes?.startsWith("Diabetes")) {
    tabs.push(
      <Tab
        key="blood-sugar"
        label="Blood Sugar"
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "large",
          color: currentTabIndex === 1 ? "white" : "black",
          "&.Mui-selected": {
            color: "white",
          },
        }}
      />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="blood-sugar-content">
        <BloodSugar></BloodSugar>
      </Box>
    );
  }
  if (motherDetail?.hyperTension?.startsWith("Hypertension") || motherDetail?.diabetes?.startsWith("Diabetes")) {
    tabs.push(
      <Tab
        key="trending-charts"
        label="Trending Charts"
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "large",
          color: currentTabIndex === 2 ? "white" : "black",
          "&.Mui-selected": {
            color: "white",
          },
        }}
      />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="trending-charts-content">
        <DailyLogTrendingCharts></DailyLogTrendingCharts>
      </Box>
    );
  }
} else {
  if (familyInfo?.firstName || familyInfo == undefined) {
    tabs.push(
      <Tab
        key="blood-pressure"
        label="Blood Pressure"
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "large",
          color: currentTabIndex === 0 ? "white" : "black",
          "&.Mui-selected": {
            color: "white",
          },
        }}
      />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="blood-pressure-content">
        <BloodPressure></BloodPressure>
      </Box>
    );
    tabs.push(
      <Tab
        key="blood-sugar"
        label="Blood Sugar"
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "large",
          color: currentTabIndex === 1 ? "white" : "black",
          "&.Mui-selected": {
            color: "white",
          },
        }}
      />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="blood-sugar-content">
        <BloodSugar></BloodSugar>
      </Box>
    );
    tabs.push(
      <Tab
        key="trending-charts"
           label="Trending Charts"
           sx={{
             textTransform: "capitalize",
             fontWeight: 600,
             fontSize: "large",
             color: currentTabIndex === 2 ? "white" : "black",
             "&.Mui-selected": {
               color: "white",
             },
           }}
         />
    );
    contents.push(
      <Box sx={{ p: 3 }} key="trending-charts-content">
        <DailyLogTrendingCharts></DailyLogTrendingCharts>
      </Box>
    );
  }
  }
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }}>
          {tabs}
        </Tabs><br/>
        {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
          <>
            <h4 style={{ color: "blue" }}>
              The health Insights/Information presented below is for your child{" "}
              <span style={{ fontSize: "xx-large" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
            </h4>
            <br />
          </>
        )}
        {contents[currentTabIndex]}
      </Box>
    </>
  );
};

export default WeeklyLog;
