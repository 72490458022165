import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { getMedicineYearly } from "../../../constants/apiList";
import SymptomList from "../../../shared/SymptomList";
import { usePagination } from "../../../util/utils";

const MedicinesCloudGraphYearly = () => {
  const [yearlyMedicinesCum, setYearlyMedicinesCum] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [medicinesData, setMedicinesData] = useState();
  const { currentPage, totalItems, totalPages, currentData, handleNextPage, handlePreviousPage } = usePagination(medicinesData);

  const handleAxisLabel = (selectedRow, index) => {
    setYearlyMedicinesCum(selectedRow.medicinesMonthCumsum);
    setSelectedYear(selectedRow.year);
  };

  const getMedicinesYearlyData = async () => {
    AxiosInstance.get(`${getMedicineYearly}`)
      .then((res) => {
        const modifiedData = res
          .map((item) => ({
            ...item,
            medicinesMonthCumsum: JSON.parse(item.medicinesMonthCumsum.replace(/'/g, '"')),
          }))
          .filter((item) => Object.keys(item.medicinesMonthCumsum).length > 0);
        setMedicinesData(modifiedData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (medicinesData?.length > 0) {
      setYearlyMedicinesCum(medicinesData[medicinesData?.length - 1].medicinesMonthCumsum);
      setSelectedYear(medicinesData[medicinesData?.length - 1].year);
    }
  }, [medicinesData]);

  useEffect(() => {
    getMedicinesYearlyData();
  }, []);

  return (
    <>
      <div className="graph-card-title">
        Which Medicines i'm taking? (<span className="month-year-as-of">{selectedYear && selectedYear}</span>)
      </div>
      <SymptomList cumulativeData={yearlyMedicinesCum} />
      <div style={{ margin: "0 auto", width: "100%", display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "end" }}>
        <IconButton
          onClick={handlePreviousPage}
          disabled={currentPage - 1 < 0}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "15px", fontSize: "10px" }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <div className="month-slider-wrapper">
          <ul className="slider-months">
            {currentData?.map((element, index) => {
              return (
                <li
                  key={index}
                  className={`month-item ${selectedYear === element.year ? "active" : ""}`}
                  onClick={() => handleAxisLabel(element, index)}
                >
                  <Button
                    variant="string"
                    sx={{ padding: 0, minWidth: "auto" }}
                  >
                    {element?.year}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage + 1 >= totalPages}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "15px", fontSize: "10px" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <hr />
      <div className="graph-footer">
        <p className="graph-note">Note : Please click on the year to see the data</p>
      </div>
    </>
  );
};

export default React.memo(MedicinesCloudGraphYearly);
