import React from "react";
import healthPoster from "../../img/health-poster.jpg";

const VideoPopup = () => {
    return (
        <div>
                   <video
                      id="video"
                      controls={true}
                      style={{marginBottom: "10px"}}
                      poster={healthPoster}
                    >
                      <source
                        src="/img/AnimationScreen1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <video
                      id="video"
                      controls={true}
                      style={{marginBottom: "10px"}}
                    >
                      <source
                        src="http://uncurlhealth-videos.s3.amazonaws.com/WhatsApp+Video+2023-08-24+at+4.30.44+PM.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <video
                      id="video"
                      controls={true}
                    >
                      <source
                        src="https://uncurlhealth-videos.s3.amazonaws.com/celebrity_video.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
        </div>
    )
}

export default VideoPopup;