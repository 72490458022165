import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Box from "@mui/material/Box";
import MUITable from "../../shared/MUITable";
import Spinner from "../../shared/Spinner";
import InfoModel from "../../shared/simpleModel/InfoModel";
const columns = [
  {
    id: "dateRecommended",
    label: "Date Recommended",
    isDate: true,
  },
  {
    id: "medicalCondition",
    label: "Medical Condition",
  },
  {
    id: "lrid",
    label: "LRID",
  },
  {
    id: "preventiveTest",
    label: "Preventive Test",
  },
  {
    id: "testName",
    label: "Test Name",
  },
  {
    id: "filePath",
    label: "Test Report",
    isLink: true,
  },
  {
    id: "viewTestResults",
    label: "Test Result",
    isLink: true,
  },
];
const PreventiveRecord = ({ setcurrentTabIndex, setselectedRowData }) => {
  const [filterDate, setFilterDate] = useState("");
  const [testResult, setTestResult] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [recordsListData, setRecordsListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [isDummyData, setIsDummyData] = useState(false);
  const [lrid, setLrid] = useState("");
  const [fileName, setFileName] = useState(null);
  const [allergies, setAllergies] = useState([]);
  const [reportUrl, setReportUrl] = useState(null);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [isLoadingPreventiveReport, setIsLoadingPreventiveReport] =
    useState(false);
  const [preventiveReportmodalIsOpen, setpreventiveReportmodalIsOpen] =
    useState(false);
  const [imageUrl, setImageUrl] = useState();

  const closeModal = () => {
    setpreventiveReportmodalIsOpen(false);
  };
  useEffect(() => {
    getTestRecordsList();
    AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        const allergiesArray = JSON.parse(response.allergies);
        setAllergies(allergiesArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let checkLrid = [];
  let updateStatus;
  const getTestRecordsList = async () => {
    AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
      .then((response) => {
        checkLrid = response.map((obj) => obj.lrid);
      })
      .catch((error) => {
        console.log(error);
      });
    AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices").then(
      (response) => {
        let check = response.map((obj) => obj.lrid);
      }
    );
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(
        "riskAssessment/getLifestylePreventiveCareServices"
      );
      const responseData = response;
      let check = response.map((obj) => obj.lrid);
      updateStatus = check.filter((id) => checkLrid.includes(id))
        ? "Complete"
        : "Incomplete";
      const filteredData = response.filter((obj) => obj.filePath);
      const testReportList = filteredData?.map((obj) => {
        const isComplete = check.some((id) => checkLrid.includes(obj.lrid));
        const taskStatus = isComplete ? "Complete" : "Incomplete";
        const setLridNew = obj.lrid;
        setLrid(setLridNew);
        return {
          ...obj,
          dateRecommended: obj.dateRecommended,
          medicalCondition: obj.medicalCondition,
          lrid: obj.lrid,
          preventiveTest: obj.preventiveTest,
          attachReport: "View Report",
          testName: obj.bookForTest,
          viewTestResults: "View",
          //    attachReport: (
          //      <div>
          //        <label htmlFor="fileUpload" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline" }}>
          //          View Report
          //        </label>
          //        {isLoading == true ? (
          //          <div>
          //            <Spinner />
          //            <br />
          //            <p>Please wait your prescription is uploading</p>
          //          </div>
          //        ) : (
          //          ""
          //        )}
          //      </div>
          //    ),
          //    taskStatus: taskStatus,
        };
      });
      setRecordsListData(testReportList);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching test records:", error);
      setIsLoading(false);
    }
  };
  const handleFileChange = async (event, selectedRowData) => {
    event.preventDefault();
    const file = event.target.files[0];
    const LRID = selectedRowData.rowData.lrid;
    setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("lrid", LRID);
      setLoading(true);
      AxiosInstance.post("/test-report/uploadPreventiveReport", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag(true);
          setIsOpen(false);
          setLoading(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An error occurred while uploading the file.");
          setLoading(false);
        });
    }
  };
  // };
  useEffect(() => {
    if (!!selectedRowData && selectedRowData.columnName === "filePath") {
      downloadPreventiveReport(selectedRowData.rowData.filePath);
    }
    // else if (!!selectedRowData && selectedRowData.columnName === "link") {
    //   downloadReport(selectedRowData.rowData.link);
    // } else if (
    //   !!selectedRowData &&
    //   selectedRowData.columnName === "customColumn"
    // ) {
    //   testReviewHandler(selectedRowData.rowData);
    // }
  }, [selectedRowData]);
  const downloadPreventiveReport = (url) => {
    if (url === null) {
      return;
    }

    const downloadApi = "test-report/downloadPreventive";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };
  const handleRowSelection = (rowData) => {
    setSelectedRowData(rowData);
    setselectedRowData(rowData); // Pass selected row data to parent
  };
  const onInputClick = (event) => {};
  console.log("preventiveDta", recordsListData, selectedRowData);
  const closeReportModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <InfoModel
        open={modalIsOpen}
        setOpen={closeReportModal}
        maxWidth={"md"}
        contentLabel="Image Popup"
        haveCloseIcon={true}
      >
        {reportUrl && (
          <iframe
            src={reportUrl}
            title="Report Viewer"
            style={{ width: "100%", height: "600px", border: "none" }}
          />
        )}
      </InfoModel>

      <div style={{ marginBottom: "20px" }}>
        {allergies &&
          allergies?.map((allergy, index) => (
            <div key={index} style={{ animationDelay: `${index * 0.5}s` }}>
              <span
                style={{
                  color: "red",
                  display: "inline-block",
                  animation: "moveLeftRight 15s infinite",
                }}
              >
                <b>Note :</b> Patient is having Allergy from
                {allergy.MedicineOrFood}, reaction is {allergy.Reaction} and
                effect is {allergy.effect}.
              </span>
            </div>
          ))}
      </div>
      {isReviewFormOpen && (
        <ReviewsTabs
          currentTab={1}
          onRefresh={getTestRecordsList}
          selectedReport={selectedReport}
          setIsReviewFormOpen={setIsReviewFormOpen}
          isDummyData={isDummyData}
        />
      )}
      <Box sx={{ width: "100%" }}>
        <Row style={{ alignItems: "end" }}>
          <Col md={8}>
            <div style={{ display: "none" }}>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="fileUpload"
                className="file-upload-input"
                name="file"
                onChange={(event) => handleFileChange(event, selectedRowData)}
                onClick={(event) => onInputClick(selectedRowData)}
              />
              {isLoading == true ? (
                <div>
                  <Spinner />
                  <br />
                  <p>Please wait your prescription is uploading</p>
                </div>
              ) : (
                <>Attach</>
              )}
            </div>
          </Col>
          <Col md={4} className="upload-button"></Col>
        </Row>
      </Box>
      {!!recordsListData && (
        <MUITable
          data={recordsListData}
          columns={columns}
          isLoading={isLoading}
          filterDate={filterDate}
          setSelectedRowData={handleRowSelection}
          setcurrentTabIndex={setcurrentTabIndex}
        />
      )}
      <InfoModel
        open={preventiveReportmodalIsOpen}
        setOpen={closeModal}
        maxWidth={"md"}
        contentLabel="Image Popup"
        haveCloseIcon={true}
      >
        {!isLoadingPreventiveReport && (
          <div className="otherDocImg">
            {/* <img src={`${imageUrl}`} /> */}
            <object
              data={`${imageUrl}#toolbar=0&navpanes=0`}
              type="application/pdf"
              style={{ height: "100vh", width: "100%" }}
              scrolling="no"
            >
              <p>
                It appears you don't have a PDF plugin for this browser. You can
                <a href={`${imageUrl}`}>click here to download the PDF file.</a>
              </p>
            </object>
          </div>
        )}
        {isLoadingPreventiveReport && <Spinner />}
      </InfoModel>
    </>
  );
};

export default PreventiveRecord;
