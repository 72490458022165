import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import MyLifestyle from "./MyLifestyle";
import LifestyleCoaching from "./LifestyleCoaching";

const FullIntervention = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState();
  const [familyRelation, setFamilyRelation] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  //   useEffect(() => {
  //     AxiosInstance.get("profile/get")
  //       .then((response) => {
  //         console.log("response: ", response);
  //         if (response != null) {
  //           setFamilyInfo(response.profile);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //     AxiosInstance.get("/family/getPatientFamilyProfile")
  //       .then((response) => {
  //         setFamilyRelation(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }, []);
  return (
    <div style={{}}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Tab
            label="My Lifestyle"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "end",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
          <Tab
            label="Lifestyle Coaching"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <MyLifestyle></MyLifestyle>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <LifestyleCoaching></LifestyleCoaching>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default FullIntervention;
