import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "../../shared/CustomTooltip";

function CustomizedTick(props) {
  const { x, y, stroke, payload } = props;
  const lines = payload.value.split(" ");

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fill="#666"
        textAnchor="middle"
        fontSize={12}
        style={{ textTransform: "capitalize" }}
      >
        {lines.map((line, index) => (
          <tspan
            key={index}
            x="0"
            dy={index > 0 ? 15 : 0}
          >
            {line}
          </tspan>
        ))}
      </text>
    </g>
  );
}

const HealthIssuesFrequency = ({ majorSymptomsData, currentMonth }) => {
  const HealthIssuesFrequencyData =
    majorSymptomsData &&
    Object.keys(majorSymptomsData).map((key) => ({
      frequencyIssue: key,
      frequencyCount: majorSymptomsData[key],
    }));

  return (
    <div className="div-medical-issues">
      <div className="graph-card-title">
        Which are the major Medical Issues as of (<span className="month-year-as-of">{currentMonth && currentMonth}</span>)
      </div>
      <div style={{ width: "100%", height: "250px", paddingLeft: 0 }}>
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          <BarChart
            data={HealthIssuesFrequencyData}
            margin={{
              top: 15,
              right: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis
              domain={[0, 10]}
              label={{ value: "Frequency", angle: -90, position: "center" }}
            />
            <XAxis
              tick={<CustomizedTick />}
              dataKey="frequencyIssue"
              angle={-55}
              tickMargin={10}
            />
            <Legend
              verticalAlign="bottom"
              height={30}
              align="center"
              payload={[{ value: "Symptoms", type: "line", id: "legend-1", color: "#8884d8" }]}
            />
            <YAxis />
            <Tooltip
              cursor={false}
              content={<CustomTooltip />}
            />
            <Bar
              dataKey="frequencyCount"
              fill="#8884d8"
              barSize={40}
              label={{ position: "top" }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default HealthIssuesFrequency;
