import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
} from "reactstrap";
import { event } from "react-ga";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { LinkedCamera } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GeneralInfo from "./generalInfo";
import LabAddress from "./labAddress";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ServicePincode from "./ServicePincode";

const GenralInfoPage = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleTabChange = (e, tabIndex, row = null) => {
    if (row) {
      setSelectedRow(row); // Update the selected row
    }
    setCurrentTabIndex(tabIndex);
  };
  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={handleTabChange}
        sx={{ backgroundColor: "steelblue" }}
      >
        <Tab
          label="Main Office"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 0 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
        <Tab
          label="Location Addresses"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 1 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
        <Tab
          label="Service Pincode"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 2 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
      </Tabs>
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <GeneralInfo handleTabChange={handleTabChange}></GeneralInfo>
        </Box>
      )}
      {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <LabAddress handleTabChange={handleTabChange}></LabAddress>
        </Box>
      )}
      {currentTabIndex === 2 && (
        <Box sx={{ p: 3 }}>
          <ServicePincode selectedRow={selectedRow}></ServicePincode>
        </Box>
      )}
    </>
  );
};
export default GenralInfoPage;
