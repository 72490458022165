import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  getLabOrderDetailApi,
  getLabOrderDetailByIdApi,
} from "../../constants/apiList";
import AppLogo from "../../../static/img/AppLogoWithName.png";
import NoOrderImage from "./images/no-order.jpeg";
import { useNavigate } from "react-router-dom";
import "./DoctorScreens.css";
import Spinner from "../../shared/Spinner";

const DoctorOrders = () => {
  const [rowData, setRowData] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const toggle = () => setModal(!modal);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [buttonStyle, setButtonStyle] = useState({
    transform: "scale(1)",
    transition: "transform 0.3s ease-in-out",
    boxShadow: "0px 0px 10px 0px red",
  });

  const getLabOrderDetail = async () => {
    await AxiosInstance.get(`${getLabOrderDetailApi}`)
      .then((response) => {
        if (response != null) {
          setRowData(response);
        } else {
          setRowData([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  const viewOrderDetail = (id) => {
    setOrderId(id);
    setModal(!modal);
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "Issues") {
      Swal.fire({
        html: `
        <div>
          <p>Mention what issue you are facing to execute this home collection service.</p>
          <input type="text" id="swal-input1" class="swal2-input">
          <p><b>Note: </b>Please call our Account Manager for resolving if needed.</p>
        </div>
      `,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
      });
    }
  };
  useEffect(() => {
    // getLabOrderDetail();
    // const today = new Date().toISOString().substr(0, 10);
    // setSelectedDate(today);
    setIsLoading(true);
    const userDetails = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userDetails);
    const userType = parsedData.userType;
    console.log("userType", userType);
    AxiosInstance.get(`doctor/orderDetails/${userType}`)
      .then((response) => {
        if (response != null) {
          setIsLoading(false);
          setOrders(response);
        } else {
          setIsLoading(false);
          setOrders([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
      });
  }, []);
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const filteredOrders = rowData.filter((order) => order.date === selectedDate);

  const handleRowClick = (order, index) => {
    setSelectedOrder(order);
    setClickedRowIndex(index);
  };
  const handleDoctorReviewClick = (order) => {
    navigate("/online-consultation", { state: { order } });
  };
  console.log("orders", orders, selectedOrder);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        {/* <div
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            display: "flex",
            alignItems: "center",
            width: "25%",
          }}
        >
          <b>Date :</b>
          <span style={{ marginLeft: "10px" }}>
            <input
              type="date"
              className="form-control"
              id="dateInput"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </span>
        </div> */}
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you your orders from Uncurl: Health</b>
      </p>
      {isLoading ? (
        <Spinner />
      ) : orders?.length > 0 ? (
        <>
          <TableContainer
            style={{ marginTop: "2%", border: "1px solid black" }}
          >
            <Table>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Date</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Time</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>ID</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Service Type</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Status</b>
                  </p>
                </TableCell>
              </TableRow>
              <TableBody>
                {orders?.map((orders, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(orders, index)}
                    // style={{
                    //   cursor: "pointer",
                    //   backgroundColor:
                    //     clickedRowIndex === index ? "red" : "whitesmoke",
                    //   transition: "background-color 0.3s ease",
                    // }}
                    // onMouseEnter={(e) => {
                    //   if (clickedRowIndex !== index)
                    //     e.currentTarget.style.backgroundColor = "red";
                    // }}
                    // onMouseLeave={(e) => {
                    //   if (clickedRowIndex !== index)
                    //     e.currentTarget.style.backgroundColor = "whitesmoke";
                    // }}
                  >
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.createdOn ? formatDate(orders.createdOn) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.time ? formatTime(orders.time) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.orderType}
                    </TableCell>
                    {/* <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.statusForDoctor === "Doctor Review" ||
                      orders.statusForDoctor === "Questions" ? (
                        <Button
                          variant="contained"
                          style={{
                            boxShadow: "0px 0px 10px 0px red",
                          }}
                          className="doctor-review-button"
                          onMouseEnter={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 20px 5px red";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 10px 0px red";
                          }}
                          onClick={() => handleDoctorReviewClick(orders)}
                        >
                          {orders.statusForDoctor}
                        </Button>
                      ) : (
                        orders.statusForDoctor
                      )}
                    </TableCell> */}
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.statusForDoctor === "Doctor Review" ||
                      orders.statusForDoctor === "Questions" ? (
                        <Button
                          variant="contained"
                          style={{
                            boxShadow: "0px 0px 10px 0px red",
                          }}
                          className="doctor-review-button"
                          onMouseEnter={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 20px 5px red";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 10px 0px red";
                          }}
                          onClick={() => handleDoctorReviewClick(orders)}
                        >
                          {orders.statusForDoctor}
                        </Button>
                      ) : (
                        <>
                          {orders.statusForDoctor === "Order Closed" ? (
                            <div>
                              {orders.statusForDoctor} (
                              {formatDate(orders.closedDate)}/
                              {formatTime(orders.closedTime)})
                            </div>
                          ) : (
                            orders.statusForDoctor
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                No Orders Available!
              </h4>
              <img src={NoOrderImage} alt="Lab" style={{ width: "25%" }} />
            </span>
          </div>
        </>
      )}
    </>
  );
};
export default DoctorOrders;
