import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import iconAttachment from "../../img/icon-attachment.png";
import { useEffect, useState } from "react";
import { GrRefresh } from "react-icons/gr";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { strings } from "../../constants/strings";
import icon_file_upload from "../../img/icon_file_upload.png";
import MUITable from "../../shared/MUITable";
import SampleIllustration from "../../shared/SampleIllustration";
import ModelPopUp from "../../shared/model/ModelPopUp";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./style.css";
import Spinner from "../../shared/Spinner";

const columns = [
  {
    id: "patientId",
    label: "Patient Id",
  },
  {
    id: "visitDate",
    label: "Visit Date",
    isDate: true,
  },
  {
    id: "hospitalName",
    label: "Hospital Name",
  },
  {
    id: "visitType",
    label: "Visit Type",
  },
  {
    id: "physicianName",
    label: "Doctor Name",
  },
  {
    id: "testName",
    label: "Test Name",
  },
  {
    id: "attachReport",
    label: "Attach Test Report",
    isLink: true,
    ReportIcon: iconAttachment,
  },
  {
    id: "status",
    label: "Status",
  },
];

export default function EnhancedTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [isTestReportLoading, setIsTestReportLoading] = useState(false);
  const [recordsListData, setRecordsListData] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [fullDate, getFullDate] = useState([]);
  const [testRecordListData, setTestRecordListData] = useState();
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDummyData, setIsDummyData] = useState(false);
  //const [uploadFlag, setUploadFlag] = useState(false);
  const uploadTestReport = () => {
    setIsOpen((prev) => !prev);
  };
  const onInputClick = (event) => {
    event.target.value = "";
  };
  const handleFileChange = (event) => {
    
  };

  const handleSubmit = (event, visitPrimaryId) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file?.name);
    if (!file) {
      toast.error("Please select a file before submitting.");
      return;
    }
    let fileCheck = fileName?.split(".")?.pop();
    if (fileCheck == "gif" || fileCheck == "jfif") {
      toast.error("Gif and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("visitId", visitPrimaryId);
      setIsLoading(true);
      AxiosInstance.post("test-report/withPrescriptionUpload", formData)
        .then((response) => {
          console.log("test report response" , response);
          toast.success("Document Uploaded Successfully");
          setUploadFlag(true);
          setIsOpen(false);
          setIsLoading(false);
          setSelectedFile(null);
          setFileName(null);
          getHospitalVisitList();
        })
        .catch((error) => {
          setError("An error occurred while uploading the file.");
          setIsLoading(false);
        });
    }
  };

  const handleTestReportSubmitWitoutPrescription = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file?.name);
    if (!file) {
      toast.error("Please select a file before submitting.");
      return;
    }
    let fileCheck = fileName?.split(".")?.pop();
    if (fileCheck == "gif" || fileCheck == "jfif") {
      toast.error("Gif and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      setIsTestReportLoading(true);
      AxiosInstance.post("test-report/upload", formData)
        .then((response) => {
          console.log("test report response" , response);
          toast.success("Document Uploaded Successfully");
          setIsTestReportLoading(false);
          setFileName(null);
        })
        .catch((error) => {
          setError("An error occurred while uploading the file.");
          setIsTestReportLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isOpen == false) {
      setSelectedFile(null);
      setFileName(null);
    }
  }, [isOpen]);

  const daysToShow = 6;

  const goToPreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - daysToShow);
    setCurrentDate(newDate);
    renderCalendarDays(newDate);
  };

  const goToNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + daysToShow);
    setCurrentDate(newDate);
    renderCalendarDays(newDate);
  };

  const renderCalendarDays = (newDate) => {
    const days = [];
    getFullDate(days);

    for (let i = 0; i < daysToShow; i++) {
      const date = new Date(newDate);
      date.setDate(date.getDate() + i);
      days.push(date);
    }
  };
  let uploadStatus;
   const getHospitalVisitList = () => {
     setIsLoading(true);
     AxiosInstance.get("visit/list")
       .then((response) => {
        console.log("respo" , response)
         setTestRecordListData(response);
         if (response?.length == 1) {
           response?.slice(0, 1).map((date) => {
             renderCalendarDays(date?.testReport?.collectionDate);
           });
         } else {
           response?.slice(0, 1).map((date) => {
             renderCalendarDays(date?.testReport?.collectionDate);
           });
         }
         const testReportList = response?.map((obj) => {
          const Status = obj.testReportStatusFlag;
            let visitType;
            if (obj.isOutPatient) {
              visitType = "OutPatient";
            } else {
              visitType = "Inpatient/Hospitalized";
            }
           if (Status == true) {
             uploadStatus = "Complete";
           } else {
             uploadStatus = "Incomplete";
           }
          return {
            ...obj.testReport,
            visitPrimaryId: obj.visitPrimaryId,
            patientId: obj.patientId,
            visitDate: obj.hospitalVisitDate,
            visitType: visitType,
            hospitalName: obj.hospitalName,
            physicianName: obj.physicianName,
            testName: obj.testNames,
            attachReport: (
              <span>
                <label htmlFor={`fileUpload-${obj.visitPrimaryId}`} style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline", marginLeft: "-10px" }}>
                  Attach Report
                </label>
                {isLoading == true ? (
                  <div>
                    <Spinner />
                    <br />
                    <p>Please wait your prescription is uploading</p>
                  </div>
                ) : (
                  ""
                )}
              </span>
            ),
            status: uploadStatus,
          };
          });
         setRecordsListData(testReportList);
         console.log('testReportList: ', testReportList);
         setIsLoading(false);
       })
       .catch((error) => {
         setIsLoading(false);
       });
     setIsLoading(false);
   };

  const getTestRecordsList = async () => {
    setIsLoading(true);
    await AxiosInstance.get(`test-report/list`)
      .then((response) => {
        setCurrentDate(response[0].testReport.collectionDate);
        setIsDummyData(response[0].sampleData);
        setTestRecordListData(response);
        if (response?.length == 1) {
          response?.slice(0, 1).map((date) => {
            renderCalendarDays(date?.testReport?.collectionDate);
          });
        } else {
          response?.slice(0, 1).map((date) => {
            renderCalendarDays(date?.testReport?.collectionDate);
          });
        }

        setFilterDate("");
        const testReportList = response?.map((obj) => {
          return {
            ...obj.testReport,
            testDone: obj.testResult,
            sampleData: obj.sampleData,
          };
        });
        setRecordsListData(testReportList);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setFilterDate(selectedDate);
  };

  useEffect(() => {
    getHospitalVisitList();
    if (isOpen == false) {
      setSelectedFile(null);
    }
  }, [isOpen, uploadStatus]);

  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));

  const handleMailtoClick = (e) => {
    e.preventDefault();
    const subject = "UHID-" + encodeURIComponent(userDetails?.upId);
    const body = encodeURIComponent("**Don't delete the subject, it is required for us to process your request.");
    const mailtoLink = `mailto:myhealthrecord@livingscopehealth.in?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  console.log("record" , recordsListData )

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Row style={{ alignItems: "end" }}>
          <Col md={12}>
            <div className="text-content">
              <p className="text-style" style={{ fontSize: "18px" }}>
                Please upload or mail all test reports for tests recommended to you by your doctor for all the hospital visits that you have listed in the Medical History screen of the App.
              </p>
            </div>
          </Col>
        </Row>

        {/* {recordsListData?.slice(0, 1).map((dummyData) => {
          if (dummyData?.sampleData == true) {
            return (
              <>
                <SampleIllustration
                  titleHeader={strings?.sampleIllustrationLabHistory?.title}
                  noteText1={strings?.sampleIllustrationLabHistory?.theInformationPresented}
                  sampleText={strings?.sampleIllustrationLabHistory?.sampleBasedOnDummyData}
                  noteText2={strings?.sampleIllustrationLabHistory?.andIsShowJustCase}
                />
              </>
            );
          }
        })}  */}

        {/* <div className="timeline-calender-container">
          <div className="navigation">
            <TableContainer
              key=""
              component={Paper}
              className="main-table-container"
            >
              <Table
                sx={{ minWidth: 650 }}
                aria-label="caption table"
                stickyHeader
              >
                <TableHead>
                  <TableRow className="calendar-table-row">
                    <TableCell className="table-header-tablecell">
                      <span
                        className="prev-next-button"
                        onClick={goToPreviousWeek}
                      >
                        <KeyboardArrowLeftIcon />
                      </span>
                    </TableCell>
                    {fullDate?.map((day) => (
                      <TableCell className="table-header-tablecell">{day.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}</TableCell>
                    ))}
                    <TableCell className="table-header-tablecell">
                      <span
                        className="prev-next-button"
                        onClick={goToNextWeek}
                      >
                        <KeyboardArrowRightIcon />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: "0px", borderBottom: "none" }}></TableCell>
                    {fullDate.map((row) => {
                      let date1 = row;

                      const targetDate = new Date(date1);

                      const matchingObjects = recordsListData?.filter((obj) => {
                        const collectionDate = new Date(obj?.collectionDate);

                        const collectionDateDay = collectionDate?.getDate();
                        const collectionDateMonth = collectionDate?.getMonth();
                        const collectionDateYear = collectionDate?.getFullYear();

                        const targetDateDay = targetDate?.getDate();
                        const targetDateMonth = targetDate?.getMonth();
                        const targetDateYear = targetDate?.getFullYear();
                        return collectionDateDay === targetDateDay && collectionDateMonth === targetDateMonth && collectionDateYear === targetDateYear;
                      });

                      const testDoneValue = [];
                      matchingObjects?.map((items) => {
                        for (let value of items?.testDone) {
                          testDoneValue.push(value);
                        }
                      });

                      return (
                        <TableCell
                          className="calendar-table-cell"
                          style={{ borderBottom: "none" }}
                        >
                          {testDoneValue?.slice(0, 1).map((row) => {
                            return (
                              <>
                                <span className="test-done">
                                  <span>{row?.parentTestName}</span>
                                </span>
                              </>
                            );
                          })}
                          {testDoneValue.length != 0 ? <span className="test-done">{testDoneValue?.length} others</span> : ""}
                        </TableCell>
                      );
                    })}
                    <TableCell style={{ width: "0px", borderBottom: "none" }}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>  */}
        {testRecordListData?.length > 3 ? (
          <Col className="date-filter-box input-date-field input-date-icon date-type-field-small">
            <label>
              <span className="text-style">Filter by Date </span>
            </label>
            <input className="input-date" type="date" value={filterDate} onChange={handleDateChange} />

            <GrRefresh className="refresh-list" onClick={getHospitalVisitList} />
          </Col>
        ) : (
          ""
        )}

        {!!recordsListData && <MUITable data={recordsListData} columns={columns} isLoading={isLoading} attachLabRecordFilterDate={filterDate} />}
      </Box>
      {isLoading == false ? recordsListData?.length == 0 ? <div className="no-data-found">No Data Found</div> : "" : ""}
      {recordsListData.map((record) => (
      <div style={{ display: "none" }} key={record.visitPrimaryId}>
        <input
          type="file"
          accept=".jpg, .jpeg, .pdf, .png"
          id={`fileUpload-${record.visitPrimaryId}`}
          className="file-upload-input"
          name={`file-${record.visitPrimaryId}`}
          onChange={(event) => handleSubmit(event ,  record.visitPrimaryId)}
          // onClick={(event) => handleFileChange(event)}
        />
        {isLoading == true ? (
          <div>
            <Spinner />
            <br />
            <p>Please wait your prescription is uploading</p>
          </div>
        ) : (
          <>Attach</>
        )}
      </div>))}
      <p>
        <b>{"Email Test Reports"}</b>
      </p>
      {"If convenient, you may simply email the test report as an attachment to the email address below. Please ensure that the attached test report is not password protected."}
      {"You may even simply forward the email (with test report attached) that you may have received from the lab."}<br></br>
      <Link to="#" onClick={handleMailtoClick}>
        <span className="click-mail-link">myhealthrecord@livingscopehealth.in</span>
      </Link>
    </>
  );
}
