import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLogoWithName from "../../../static/img/AppLogoWithName.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { clearCookies, getAccessToken } from "../../configration/HandleCookies";
import { strings } from "../../constants/strings";
import InfoModel from "../../shared/simpleModel/InfoModel";
import AddToCart from "./AddToCart";
import { getPaymentCancelBaseUrl, getPaymentSuccessBaseUrl } from "../../util/utils";

const BuySubscription = () => {
  const [isOpen, setIsOpen] = useState(false);

  // let auth = { token: getAccessToken() };
  let token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();

  const createCheckoutSession = async () => {
    await AxiosInstance.get(`payment/create-checkout-session/1?successURL=${getPaymentSuccessBaseUrl()}&cancelURL=${getPaymentCancelBaseUrl()}`)
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const logOutUser = () => {
    // clearCookies();
    sessionStorage.clear();
    navigate("/homeBeforeLogin");
  };

  const getSubscriptions = () => {
    AxiosInstance.get(`subscription/get-detail`)
      .then((response) => {
        if (response?.subscriptionStatus == "NO_SUBSCRIPTION" || response?.subscriptionStatus == "EXPIRED_SUBSCRIPTION") {
          setIsOpen(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <>
      {token && (
        <div className="SubscriptionPageBeforeLogin">
          <div className="overlap-3">
            <img
              className="group-3"
              alt="Group"
              src={AppLogoWithName}
            />
          </div>
          <div className="buy-subscription-wrapper">
            <AddToCart
              onPayClick={createCheckoutSession}
              buttonText="Pay Now"
            />
            <Button onClick={logOutUser}>Logout</Button>
            <InfoModel
              open={isOpen}
              setOpen={setIsOpen}
              buttonText="Ok"
              maxWidth="xs"
              heading={strings.message.planActivation}
            >
              <div className="popup-description">{strings.message.notHavePlan}</div>
            </InfoModel>
          </div>
        </div>
      )}
    </>
  );
};

export default BuySubscription;
