import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Avatar,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import CmeIndiaLogo from "./Images/cme_india.jpg";
import LivingScopeHealthLogo from "./Images/LivingScopeHealthLogo.png";
import axios from "axios";
import { toast } from "react-toastify";
import "./CME_Doctors.css";
import { MdCheckCircle } from "react-icons/md";

const stateList = [
  {
    id: 1,
    name: "Andhra Pradesh",
  },
  {
    id: 2,
    name: "Arunachal Pradesh",
  },
  {
    id: 3,
    name: "Assam",
  },
  {
    id: 4,
    name: "Bihar",
  },
  {
    id: 5,
    name: "Chattisgarh",
  },
  {
    id: 6,
    name: "Goa",
  },
  {
    id: 7,
    name: "Gujarat",
  },
  {
    id: 8,
    name: "Haryana",
  },
  {
    id: 9,
    name: "Himachal Pradesh",
  },
  {
    id: 10,
    name: "Jharkhand",
  },
  {
    id: 11,
    name: "Karnataka",
  },
  {
    id: 12,
    name: "Kerala",
  },
  {
    id: 13,
    name: "Madhya Pradesh",
  },
  {
    id: 14,
    name: "Maharashtra",
  },
  {
    id: 15,
    name: "Manipur",
  },
  {
    id: 16,
    name: "Meghalaya",
  },
  {
    id: 17,
    name: "Mizoram",
  },
  {
    id: 18,
    name: "Nagaland",
  },
  {
    id: 19,
    name: "Odisha",
  },
  {
    id: 20,
    name: "Punjab",
  },
  {
    id: 21,
    name: "Rajasthan",
  },
  {
    id: 22,
    name: "Sikkim",
  },
  {
    id: 23,
    name: "Tamil Nadu",
  },
  {
    id: 24,
    name: "Telangana",
  },
  {
    id: 25,
    name: "Tripura",
  },
  {
    id: 26,
    name: "Uttarakhand",
  },
  {
    id: 27,
    name: "Uttar Pradesh",
  },
  {
    id: 28,
    name: "West Bengal",
  },
  {
    id: 29,
    name: "Andaman and Nicobar Islands  (UT)",
  },
  {
    id: 30,
    name: "Chandigarh  (UT)",
  },
  {
    id: 31,
    name: "Dadra and Nagar Haveli and Daman & Diu  (UT)",
  },
  {
    id: 32,
    name: "Delhi  (UT)",
  },
  {
    id: 33,
    name: "Jammu & Kashmir  (UT)",
  },
  {
    id: 34,
    name: "Ladakh  (UT)",
  },
  {
    id: 35,
    name: "Lakshadweep  (UT)",
  },
  {
    id: 36,
    name: "Puducherry  (UT)",
  },
  {
    id: 37,
    name: "Other Territory  (UT)",
  },
];
const CMEDoctorsInformation = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    practicingAs: "",
    highestMedicalDegree: "",
    sex: "",
    address: {
      addressHome: "",
      state: "",
      city: "",
      pinCode: "",
    },
    specialInterest: "",
    awards: "",
    researchInterest: "",
    areaOfInterest: "",
    bio: "",
  });

  const [errors, setErrors] = useState({});
  const [stateData, setStateData] = useState([]);
  const inputRefs = {
    name: useRef(),
    mobile: useRef(),
    email: useRef(),
    addressHome: useRef(),
    practicingAs: useRef(),
    highestMedicalDegree: useRef(),
    sex: useRef(),
    state: useRef(),
    city: useRef(),
    pinCode: useRef(),
  };
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    getStateList();
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userDetails) {
      setFormData(() => ({
        name: userDetails.doctorName || "",
        mobile: userDetails.phoneNumber || "",
        email: userDetails.email || "",
        practicingAs: userDetails.practicingAs || "",
        highestMedicalDegree: userDetails.highestMedicalDegree || "",
        sex: userDetails.gender || "",
        address: {
          addressHome: userDetails.address || "",
          state: userDetails.state || "",
          city: userDetails.city || "",
          pinCode: userDetails.pinCode || "",
        },
        specialInterest: userDetails.specialInterest || "",
        awards: userDetails.awards || "",
        researchInterest: userDetails.researchInterest || "",
        areaOfInterest: userDetails.areaOfInterest || "",
        bio: userDetails.bio || "",
      }));
      setSelectedFileName(userDetails.profilePhoto || "");
    }
  }, []);

  const getStateList = () => {
    setStateData(stateList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name in formData.address) {
      setFormData({
        ...formData,
        address: { ...formData.address, [name]: value },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setErrors({ ...errors, [name]: "" });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setSelectedFileName(file ? file.name : "");
    let fileType = file?.name?.split(".")?.pop();
    if (
      fileType == "gif" ||
      fileType == "jfif" ||
      fileType == "csv" ||
      fileType == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const ProfilePic = new FormData();
      ProfilePic.append("file", file);
      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}doctor-verify/upload-profile-pic/${formData.mobile}`,
          ProfilePic,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Profile uploaded successfully");
      } catch (response) {
        console.error("API Error:", response);
      }
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.address.addressHome)
      newErrors.addressHome = "Address is required";
    if (!formData.practicingAs)
      newErrors.practicingAs = "Practicing as is required";
    if (!formData.highestMedicalDegree)
      newErrors.highestMedicalDegree = "Highest Medical Degree is required";
    if (!formData.sex) newErrors.sex = "Sex is required";
    if (!formData.address.state) newErrors.state = "State is required";
    if (!formData.address.city) newErrors.city = "City is required";
    if (!formData.address.pinCode) newErrors.pinCode = "Pin Code is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const firstErrorField = Object.keys(newErrors)[0];
      inputRefs[firstErrorField].current.focus(); // Focus on the first field with an error
    } else {
      // Handle form submission (e.g., send data to an API or save it)
      const DoctorDetail = {
        doctorName: formData.name,
        phoneNumber: formData.mobile,
        email: formData.email,
        gender: formData.sex,
        address: formData.address.addressHome,
        state: formData.address.state,
        city: formData.address.city,
        pinCode: formData.address.pinCode,
        practicingAs: formData.practicingAs,
        highestMedicalDegree: formData.highestMedicalDegree,
        specialInterest: formData.specialInterest,
        awards: formData.awards,
        researchInterest: formData.researchInterest,
        areaOfInterest: formData.areaOfInterest,
        bio: formData.bio,
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}doctor-verify/saveCmeDoctorInfo`,
          DoctorDetail
        )
        .then((response) => {
          setIsSubmitted(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      console.log(formData, DoctorDetail);
    }
  };

  // Media query for mobile responsiveness
  const isMobile = useMediaQuery("(max-width:600px)");
  console.log("form", formData);

  return (
    <>
      {!isSubmitted ? (
        <Paper
          style={{
            padding: isMobile ? "8px" : "16px",
            margin: "auto",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: "16px" }}
          >
            <Grid item xs={3} sm={2} md={2}>
              <img
                src={CmeIndiaLogo}
                alt="Logo"
                style={{ width: 70, height: 70 }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8} style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontWeight: "bold", color: "purple" }}
              >
                Member Profile
              </Typography>
            </Grid>

            {/* Empty Grid Item for Alignment */}
            <Grid item xs={1} sm={2} md={2} />
          </Grid>
          <Typography variant="h6" gutterBottom>
            Part-1 (All Fields are Mandatory)
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={isMobile ? 2 : 3}>
              {/* Compulsory Fields */}
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Name ( as should be printed in all your certificates)"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name}
                  inputRef={inputRefs.name}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                  inputRef={inputRefs.mobile}
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      backgroundColor: "#d3d3d37a",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email}
                  inputRef={inputRefs.email}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl required fullWidth error={!!errors.sex}>
                  <InputLabel>Sex</InputLabel>
                  <Select
                    name="sex"
                    value={formData.sex}
                    onChange={handleChange}
                    inputRef={inputRefs.sex}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  <FormHelperText>{errors.sex}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl required fullWidth error={!!errors.state}>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="state"
                    value={formData.address.state}
                    onChange={handleChange}
                    inputRef={inputRefs.state}
                  >
                    {stateData.map((state) => (
                      <MenuItem key={state.id} value={state.name}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.state}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="City"
                  name="city"
                  value={formData.address.city}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city}
                  inputRef={inputRefs.city}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Pin Code"
                  name="pinCode"
                  value={formData.address.pinCode}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.pinCode}
                  helperText={errors.pinCode}
                  inputRef={inputRefs.pinCode}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Address"
                  name="addressHome"
                  value={formData.address.addressHome}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.addressHome}
                  helperText={errors.addressHome}
                  inputRef={inputRefs.addressHome}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Highest Medical Degree with Specialty"
                  name="highestMedicalDegree"
                  value={formData.highestMedicalDegree}
                  onChange={handleChange}
                  inputRef={inputRefs.highestMedicalDegree}
                  fullWidth
                  error={!!errors.highestMedicalDegree}
                  helperText={errors.highestMedicalDegree}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Practicing as"
                  name="practicingAs"
                  value={formData.practicingAs}
                  onChange={handleChange}
                  inputRef={inputRefs.practicingAs}
                  fullWidth
                  error={!!errors.practicingAs}
                  helperText={errors.practicingAs}
                />
              </Grid>
            </Grid>

            {/* Optional Fields */}
            <Grid container spacing={isMobile ? 2 : 3} mt={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" gutterBottom>
                  Part-2 (Optional)
                </Typography>
                <div style={{ marginBottom: "10px" }}>
                  Note: You may simply copy and paste information below from
                  your existing bio
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Areas of special interest in medical practice"
                  name="specialInterest"
                  value={formData.specialInterest}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={isMobile ? 1 : 2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Any awards/ special recognition"
                  name="awards"
                  value={formData.awards}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={isMobile ? 1 : 2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Interest in research work ?
                  </FormLabel>
                  <RadioGroup
                    row
                    name="researchInterest"
                    value={formData.researchInterest}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formData.researchInterest === "Yes" && (
                <Grid item xs={12}>
                  <TextField
                    label="State Areas of Interest"
                    name="areaOfInterest"
                    value={formData.areaOfInterest}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={isMobile ? 1 : 2}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Brief Bio-data"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={isMobile ? 2 : 4}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  id="photograph-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="photograph-upload">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    component="span"
                  >
                    Attach Photograph
                  </Button>
                  {selectedFileName && (
                    <Typography variant="body2" style={{ marginTop: "8px" }}>
                      {selectedFileName}
                    </Typography>
                  )}
                </label>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size={isMobile ? "medium" : "large"}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="center"
              style={{
                marginTop: "16px",
                color: "black",
                fontSize: isMobile ? "12px" : "14px",
              }}
            >
              <strong>Living Scope Health</strong> has been retained as
              Technology Partner by CME INDIA to collect this information from
              all its registered members.
            </Typography>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "end",
              marginTop: "10px",
            }}
          >
            <span>
              <a href="https://livingscopehealth.com/" target="blank">
                <img
                  className="shape"
                  alt="Shape"
                  src={LivingScopeHealthLogo}
                  width="250px"
                />
              </a>
            </span>
          </div>
        </Paper>
      ) : (
        <div className="SubmittedText">
          <div>
            <MdCheckCircle size={30} color="green" />
          </div>
          <strong style={{ fontSize: "20px", color: "green" }}>
            Thanks for submitting the form!
          </strong>
          <div style={{ fontSize: "16px" }}>Your Detail has been Saved!</div>
        </div>
      )}
    </>
  );
};

export default CMEDoctorsInformation;
