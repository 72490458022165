import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Col, Input, Row, Toast } from "reactstrap";
import "./formStyle.css";
import {
  Checkbox,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputField from "./InputField";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { nameValidation } from "../../util/validations";
import { strings } from "../../constants/strings";
import { yupResolver } from "@hookform/resolvers/yup";
import iconDate from "../../../static/img/icon-filled-events-3.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import Swal from "sweetalert2";

const BiographicalSketch = ({ onBack }) => {
  const [environmentStep, setEnvironmentStep] = useState(0);
  const [applies, setApplies] = useState("");
  const [otherApplies, setOtherApplies] = useState("");
  const [isOtherAppliesSelected, setIsOtherAppliesSelected] = useState(false);
  const [highestLevelOfEducation, sethighestLevelOfEducation] = useState("");
  const [employmentStatus, setemploymentStatus] = useState("");
  const [monthlySalary, setmonthlySalary] = useState(null);
  const [annualIncome, setannualIncome] = useState("");
  const [financiallyStable, setfinanciallyStable] = useState(null);
  const [freeFromPsychologicalStress, setfreeFromPsychologicalStress] =
    useState(null);
  const [cleanAir, setcleanAir] = useState(null);
  const [pollutedAir, setpollutedAir] = useState(null);
  const [occupation, setoccupation] = useState("");
  const [timeSpentInTraffic, settimeSpentInTraffic] = useState("");
  const [workMode, setworkMode] = useState("");
  const [otherworkMode, setOtherworkMode] = useState("");
  const [isOtherworkModeSelected, setIsOtherworkModeSelected] = useState(false);
  const [timeSpentOnLaptop, settimeSpentOnLaptop] = useState("");
  const [relationshipStatus, setrelationshipStatus] = useState("");
  const [militaryService, setmilitaryService] = useState(null);
  const [familyMembers, setFamilyMembers] = useState("");
  const [otherFamilyValue, setOtherFamilyValue] = useState("");
  const [isOtherfamilyMembersSelected, setIsOtherfamilyMembersSelected] =
    useState(false);
  const [takeCaresWhenIll, settakeCaresWhenIll] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [otherValue, setOtherValue] = useState("");
  const [isOtheraccommodationSelected, setIsOtheraccommodationSelected] =
    useState(false);
  const [totalChildrenInHousehold, settotalChildrenInHousehold] =
    useState(null);
  const [agesOfChildrenInHousehold, setagesOfChildrenInHousehold] =
    useState("");
  const [livingArrangement, setlivingArrangement] = useState("");
  const [problemsWith, setproblemsWith] = useState("");
  const [busyLifeStyle1, setBusyLifeStyle1] = useState(null);
  const [busyLifeStyle2, setBusyLifeStyle2] = useState(null);
  const [busyLifeStyle3, setBusyLifeStyle3] = useState(null);
  const [busyLifeStyle4, setBusyLifeStyle4] = useState(null);
  const [busyLifeStyle5, setBusyLifeStyle5] = useState(null);
  const [busyLifeStyle6, setBusyLifeStyle6] = useState(null);
  const [busyLifeStyle7, setBusyLifeStyle7] = useState(null);
  const [busyLifeStyle8, setBusyLifeStyle8] = useState(null);
  const [depressed, setdepressed] = useState("");
  const [timeSpentOnMobile, settimeSpentOnMobile] = useState("");
  const [timeSpentOnTv, settimeSpentOnTv] = useState("");
  const [intensityOfDepression, setintensityOfDepression] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldErrors2, setFieldErrors2] = useState({});
  const [fieldErrors3, setFieldErrors3] = useState({});
  const [familyRelation, setFamilyRelation] = useState();
  const [familyInfo, setFamilyInfo] = useState();
  const [environmentCompleteFlag, setenvironmentCompleteFlag] = useState(null);
  const handleannualIncomeChange = (event) => {
    setannualIncome(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      annualIncome: false,
    }));
  };

  const handlepollutedAirChange = (event) => {
    const value = event.target.value === "true";
    setpollutedAir(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      pollutedAir: false,
    }));
  };

  const handlecleanAirChange = (event) => {
    const value = event.target.value === "true";
    setcleanAir(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      cleanAir: false,
    }));
  };

  const handlefreeFromPsychologicalStressChange = (event) => {
    const value = event.target.value === "true";
    setfreeFromPsychologicalStress(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      freeFromPsychologicalStress: false,
    }));
  };

  const handlefinanciallyStableChange = (event) => {
    const value = event.target.value === "true";
    setfinanciallyStable(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      financiallyStable: false,
    }));
  };

  const handlemonthlySalaryChange = (event) => {
    setmonthlySalary(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      monthlySalary: false,
    }));
  };

  const handlehighestLevelOfEducationChange = (event) => {
    sethighestLevelOfEducation(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      highestLevelOfEducation: false,
    }));
  };

  const handleemploymentStatusChange = (event) => {
    setemploymentStatus(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      employmentStatus: false,
    }));
  };

  const handleoccupationChange = (event) => {
    setoccupation(event.target.value);
  };

  const handletimeSpentInTrafficChange = (event) => {
    settimeSpentInTraffic(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      timeSpentInTraffic: false,
    }));
  };

  const handletimeSpentOnLaptopChange = (event) => {
    settimeSpentOnLaptop(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      timeSpentOnLaptop: false,
    }));
  };

  const handlerelationshipStatusChange = (event) => {
    setrelationshipStatus(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      relationshipStatus: false,
    }));
  };

  const handlemilitaryServiceChange = (event) => {
    const value = event.target.value === "true";
    setmilitaryService(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      militaryService: false,
    }));
  };

  const handletakeCaresWhenIllChange = (event) => {
    settakeCaresWhenIll(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      takeCaresWhenIll: false,
    }));
  };

  const handlelivingArrangementChange = (event) => {
    setlivingArrangement(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      livingArrangement: false,
    }));
  };

  const handleagesOfChildrenInHouseholdChange = (event) => {
    const value = event.target.value;

    // Toggle the selected family member
    setagesOfChildrenInHousehold((prevMembers) => {
      if (prevMembers.includes(value)) {
        return prevMembers.filter((member) => member !== value);
      } else {
        return [...prevMembers, value];
      }
    });
  };

  const handletotalChildrenInHouseholdChange = (event) => {
    settotalChildrenInHousehold(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      totalChildrenInHousehold: false,
    }));
  };

  const handleproblemsWithChange = (event) => {
    const value = event.target.value;
    setproblemsWith((prevMembers) => {
      if (value === "None Of the Above") {
        return prevMembers.includes(value) ? [] : [value];
      } else {
        const updatedMembers = prevMembers.includes(value)
          ? prevMembers.filter((member) => member !== value)
          : [...prevMembers, value];

        return updatedMembers.filter(
          (member) => member !== "None Of the Above"
        );
      }
    });
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      problemsWith: false,
    }));
  };

  const handletimeSpentOnMobileChange = (event) => {
    settimeSpentOnMobile(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      timeSpentOnMobile: false,
    }));
  };

  const handletimeSpentOnTvChange = (event) => {
    settimeSpentOnTv(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      timeSpentOnTv: false,
    }));
  };

  const handleintensityOfDepressionChange = (event) => {
    setintensityOfDepression(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      intensityOfDepression: false,
    }));
  };

  const handledepressedChange = (event) => {
    setdepressed(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      depressed: false,
    }));
  };

  const handleFamilyMemberChange = (event) => {
    const value = event.target.value;
    // Toggle the selected family member
    setFamilyMembers((prevMembers) => {
      if (prevMembers.includes(value)) {
        return prevMembers.filter((member) => member !== value);
      } else {
        return [...prevMembers, value];
      }
    });

    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      familyMembers: false,
    }));
  };

  const handleBusyLifeStyle1 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle1(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle1: false,
    }));
  };

  const handleBusyLifeStyle2 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle2(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle2: false,
    }));
  };

  const handleBusyLifeStyle3 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle3(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle3: false,
    }));
  };

  const handleBusyLifeStyle4 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle4(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle4: false,
    }));
  };
  const handleBusyLifeStyle5 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle5(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle5: false,
    }));
  };
  const handleBusyLifeStyle6 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle6(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle6: false,
    }));
  };
  const handleBusyLifeStyle7 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle7(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle7: false,
    }));
  };
  const handleBusyLifeStyle8 = (event) => {
    const value = event.target.value === "true";
    setBusyLifeStyle8(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      busyLifeStyle8: false,
    }));
  };

  const handleFamilyMemberChange2 = (event) => {
    const isChecked = event.target.checked;

    setIsOtherfamilyMembersSelected(isChecked);

    if (isChecked) {
      setFamilyMembers((prevMembers) => {
        return prevMembers.filter((member) => member !== "None");
      });
    } else {
      // Remove the "Other" value
      setFamilyMembers((prevMembers) => {
        return prevMembers.filter(
          (member) => !member.startsWith("otherValue:")
        );
      });
      // Optionally, clear the input value for "Other"
      setOtherFamilyValue(""); // Ensure you have a state to handle `otherInput2`
    }
    // setOtherFamilyValue(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      familyMembers: false,
    }));
  };

  const handleOtherFamilyValueChange = (event) => {
    const value = event.target.value;
    setOtherFamilyValue(value);
  };

  const handleAccommodationChange = (event) => {
    const value = event.target.value;
    if (value === "Other") {
      setIsOtheraccommodationSelected(true);
      setAccommodation("Other");
    } else {
      setIsOtheraccommodationSelected(false);
      setAccommodation(value);
      setOtherValue("");
    }
    setAccommodation(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      accommodation: false,
    }));
  };

  const handleOtherValueChange = (event) => {
    const value = event.target.value;
    setOtherValue(value);
    setAccommodation(`Other: ${value}`);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      accommodation: false,
    }));
  };

  const handleInputBlurChildren = () => {
    const trimmedValue = otherValue.trim();
    if (trimmedValue !== "") {
      setOtherValue(trimmedValue);
      setAccommodation(`Other: ${trimmedValue}`);
    }
  };

  const handleAppliesChange = (event) => {
    const value = event.target.value;
    // If "Other" is selected, clear otherValue

    if (value === "Other") {
      setIsOtherAppliesSelected(true);
      setApplies("Other");
    } else {
      setIsOtherAppliesSelected(false);
      setApplies(value);
      setOtherApplies("");
    }
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      applies: false,
    }));
  };

  const handleOtherAppliesValueChange = (event) => {
    const value = event.target.value;
    setOtherApplies(value);
    setApplies(`Other: ${value}`);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      applies: false,
    }));
  };

  const handleInputBlur = () => {
    const trimmedValue = otherApplies.trim();
    if (trimmedValue !== "") {
      setOtherApplies(trimmedValue);
      setApplies(`Other: ${trimmedValue}`);
    }
  };

  const handleWorkModeChange = (event) => {
    const value = event.target.value;
    // If "Other" is selected, clear otherValue
    if (value === "Other") {
      setIsOtherworkModeSelected(true);
      setworkMode("Other");
    } else {
      setIsOtherworkModeSelected(false);
      setworkMode(value);
      setOtherworkMode("");
    }
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      workMode: false,
    }));
  };

  const handleInputBlur2 = () => {
    const trimmedValue = otherworkMode.trim();
    if (trimmedValue !== "") {
      setOtherworkMode(trimmedValue);
      setworkMode(`Other: ${trimmedValue}`);
    }
  };

  const handleInputBlurFamily = () => {
    const trimmedValue = otherFamilyValue.trim();
    if (trimmedValue !== "") {
      setFamilyMembers((prevMembers) => {
        // Remove existing "otherValue:" entries
        const filteredMembers = prevMembers.filter(
          (member) => !member.startsWith("otherValue:")
        );
        return [...filteredMembers, "otherValue:" + trimmedValue].filter(
          (member) => member !== "None"
        );
      });
    }
  };

  const handleOtherWorkModeValueChange = (event) => {
    const value = event.target.value;
    setOtherworkMode(value);
    setApplies(`Other: ${value}`);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      workMode: false,
    }));
  };

  const handleEnvironmentStep1 = () => {
    const errors = {};

    if (applies === "" || (applies === "Other" && otherApplies.trim() === "")) {
      errors.applies = true;
    }
    if (highestLevelOfEducation === "") {
      errors.highestLevelOfEducation = true;
    }
    if (applies == "Working professional" && employmentStatus === "") {
      errors.employmentStatus = true;
    }
    if (
      applies == "Working professional" &&
      (monthlySalary == null || !monthlySalary)
    ) {
      errors.monthlySalary = true;
    }
    if (applies == "Working professional" && annualIncome === "") {
      errors.annualIncome = true;
    }
    if (financiallyStable == null) {
      errors.financiallyStable = true;
    }
    if (freeFromPsychologicalStress == null) {
      errors.freeFromPsychologicalStress = true;
    }
    if (cleanAir == null) {
      errors.cleanAir = true;
    }
    if (pollutedAir == null) {
      errors.pollutedAir = true;
    }
    if (timeSpentInTraffic === "") {
      errors.timeSpentInTraffic = true;
    }
    if (
      applies == "Working professional" &&
      (workMode === "" || (workMode === "Other" && otherworkMode.trim() === ""))
    ) {
      errors.workMode = true;
    }
    if (timeSpentOnLaptop === "") {
      errors.timeSpentOnLaptop = true;
    }
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        workProfile: applies,
        highestLevelOfEducation: highestLevelOfEducation,
        employmentStatus: employmentStatus,
        monthlySalary: monthlySalary,
        annualIncome: annualIncome,
        financiallyStable: financiallyStable,
        freeFromPsychologicalStress: freeFromPsychologicalStress,
        cleanAir: cleanAir,
        pollutedAir: pollutedAir,
        occupation: occupation,
        timeSpentInTraffic: timeSpentInTraffic,
        workMode: workMode,
        timeSpentOnLaptop: timeSpentOnLaptop,
        relationshipStatus: relationshipStatus,
        militaryService: militaryService ? militaryService : false,
        familyMembers: familyMembers ? familyMembers.join(";") : familyMembers,
        takeCaresWhenIll: takeCaresWhenIll,
        livingAccommodation: accommodation,
        totalChildrenInHousehold: totalChildrenInHousehold,
        agesOfChildrenInHousehold: agesOfChildrenInHousehold
          ? agesOfChildrenInHousehold.join(";")
          : agesOfChildrenInHousehold,
        livingArrangement: livingArrangement,
        problemsWith: problemsWith ? problemsWith.join(";") : problemsWith,
        fellDepressed: depressed,
        intensityOfDepression: intensityOfDepression,
        timeSpentOnMobile: timeSpentOnMobile,
        timeSpentOnTv: timeSpentOnTv,
        tryingToMultitask: busyLifeStyle1 ? busyLifeStyle1 : false,
        feelExhaustedAndOverwhelmed: busyLifeStyle2 ? busyLifeStyle2 : false,
        scheduleYourTasksInAdvance: busyLifeStyle3 ? busyLifeStyle3 : false,
        hardTimeFocusTheMoment: busyLifeStyle4 ? busyLifeStyle4 : false,
        hardlyTakeTimeOffFromWork: busyLifeStyle5 ? busyLifeStyle5 : false,
        lifeIsOutOfBalance: busyLifeStyle6 ? busyLifeStyle6 : false,
        feelGuilty: busyLifeStyle7 ? busyLifeStyle7 : false,
        doNotGetTimeToBreathe: busyLifeStyle8 ? busyLifeStyle8 : false,
        environmentCompleteFlag: environmentCompleteFlag ? true : false,
      };

      AxiosInstance.post("form/addEnvironmentSketch", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };

  const handleEnvironmentStep2 = () => {
    const errors2 = {};

    if (relationshipStatus === "") {
      errors2.relationshipStatus = true;
    }
    if (militaryService == null) {
      errors2.militaryService = true;
    }
    if (familyMembers === "" || familyMembers.length === 0) {
      errors2.familyMembers = true;
    }
    if (takeCaresWhenIll === "") {
      errors2.takeCaresWhenIll = true;
    }
    if (
      accommodation === "" ||
      (accommodation === "Other" && otherValue.trim() === "")
    ) {
      errors2.accommodation = true;
    }
    if (totalChildrenInHousehold == null || !totalChildrenInHousehold) {
      errors2.totalChildrenInHousehold = true;
    }
    if (livingArrangement === "") {
      errors2.livingArrangement = true;
    }
    if (problemsWith === "") {
      errors2.problemsWith = true;
    }
    if (busyLifeStyle1 == null) {
      errors2.busyLifeStyle1 = true;
    }
    if (busyLifeStyle2 == null) {
      errors2.busyLifeStyle2 = true;
    }
    if (busyLifeStyle3 == null) {
      errors2.busyLifeStyle3 = true;
    }
    if (busyLifeStyle4 == null) {
      errors2.busyLifeStyle4 = true;
    }
    if (busyLifeStyle5 == null) {
      errors2.busyLifeStyle5 = true;
    }
    if (busyLifeStyle6 == null) {
      errors2.busyLifeStyle6 = true;
    }
    if (busyLifeStyle7 == null) {
      errors2.busyLifeStyle7 = true;
    }
    if (busyLifeStyle8 == null) {
      errors2.busyLifeStyle8 = true;
    }
    setFieldErrors2(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        workProfile: applies,
        highestLevelOfEducation: highestLevelOfEducation,
        employmentStatus: employmentStatus,
        monthlySalary: monthlySalary,
        annualIncome: annualIncome,
        financiallyStable: financiallyStable,
        freeFromPsychologicalStress: freeFromPsychologicalStress,
        cleanAir: cleanAir,
        pollutedAir: pollutedAir,
        occupation: occupation,
        timeSpentInTraffic: timeSpentInTraffic,
        workMode: workMode,
        timeSpentOnLaptop: timeSpentOnLaptop,
        relationshipStatus: relationshipStatus,
        militaryService: militaryService ? militaryService : false,
        familyMembers: familyMembers ? familyMembers.join(";") : familyMembers,
        takeCaresWhenIll: takeCaresWhenIll,
        livingAccommodation: accommodation,
        totalChildrenInHousehold: totalChildrenInHousehold,
        agesOfChildrenInHousehold: agesOfChildrenInHousehold
          ? agesOfChildrenInHousehold.join(";")
          : agesOfChildrenInHousehold,
        livingArrangement: livingArrangement,
        problemsWith: problemsWith ? problemsWith.join(";") : problemsWith,
        fellDepressed: depressed,
        intensityOfDepression: intensityOfDepression,
        timeSpentOnMobile: timeSpentOnMobile,
        timeSpentOnTv: timeSpentOnTv,
        tryingToMultitask: busyLifeStyle1 ? busyLifeStyle1 : false,
        feelExhaustedAndOverwhelmed: busyLifeStyle2 ? busyLifeStyle2 : false,
        scheduleYourTasksInAdvance: busyLifeStyle3 ? busyLifeStyle3 : false,
        hardTimeFocusTheMoment: busyLifeStyle4 ? busyLifeStyle4 : false,
        hardlyTakeTimeOffFromWork: busyLifeStyle5 ? busyLifeStyle5 : false,
        lifeIsOutOfBalance: busyLifeStyle6 ? busyLifeStyle6 : false,
        feelGuilty: busyLifeStyle7 ? busyLifeStyle7 : false,
        doNotGetTimeToBreathe: busyLifeStyle8 ? busyLifeStyle8 : false,
        environmentCompleteFlag: environmentCompleteFlag ? true : false,
      };
      AxiosInstance.post("form/addEnvironmentSketch", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };

  const handleEnvironmentStepBack = () => {
    setEnvironmentStep((prev) => prev - 1);
  };

  const onEnvironmentSubmit = (e) => {
    e.preventDefault();
    const errors3 = {};

    if (depressed === "") {
      errors3.depressed = true;
    }
    if (timeSpentOnMobile === "") {
      errors3.timeSpentOnMobile = true;
    }
    if (timeSpentOnTv === "") {
      errors3.timeSpentOnTv = true;
    }
    if (intensityOfDepression === "") {
      errors3.intensityOfDepression = true;
    }
    setFieldErrors3(errors3);
    if (depressed == "" || timeSpentOnMobile == "" || timeSpentOnTv == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
      if (depressed == true) {
        if (intensityOfDepression == "") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Fill all the Mandatory Fields!",
          });
        }
      }
    } else {
      const formData = {
        workProfile: applies,
        highestLevelOfEducation: highestLevelOfEducation,
        employmentStatus: employmentStatus,
        monthlySalary: monthlySalary,
        annualIncome: annualIncome,
        financiallyStable: financiallyStable,
        freeFromPsychologicalStress: freeFromPsychologicalStress,
        cleanAir: cleanAir,
        pollutedAir: pollutedAir,
        occupation: occupation,
        timeSpentInTraffic: timeSpentInTraffic,
        workMode: workMode,
        timeSpentOnLaptop: timeSpentOnLaptop,
        relationshipStatus: relationshipStatus,
        militaryService: militaryService ? militaryService : false,
        familyMembers: familyMembers ? familyMembers.join(";") : familyMembers,
        takeCaresWhenIll: takeCaresWhenIll,
        livingAccommodation: accommodation,
        totalChildrenInHousehold: totalChildrenInHousehold,
        agesOfChildrenInHousehold: agesOfChildrenInHousehold
          ? agesOfChildrenInHousehold.join(";")
          : agesOfChildrenInHousehold,
        livingArrangement: livingArrangement,
        problemsWith: problemsWith ? problemsWith.join(";") : problemsWith,
        fellDepressed: depressed,
        intensityOfDepression: intensityOfDepression,
        timeSpentOnMobile: timeSpentOnMobile,
        timeSpentOnTv: timeSpentOnTv,
        tryingToMultitask: busyLifeStyle1 ? busyLifeStyle1 : false,
        feelExhaustedAndOverwhelmed: busyLifeStyle2 ? busyLifeStyle2 : false,
        scheduleYourTasksInAdvance: busyLifeStyle3 ? busyLifeStyle3 : false,
        hardTimeFocusTheMoment: busyLifeStyle4 ? busyLifeStyle4 : false,
        hardlyTakeTimeOffFromWork: busyLifeStyle5 ? busyLifeStyle5 : false,
        lifeIsOutOfBalance: busyLifeStyle6 ? busyLifeStyle6 : false,
        feelGuilty: busyLifeStyle7 ? busyLifeStyle7 : false,
        doNotGetTimeToBreathe: busyLifeStyle8 ? busyLifeStyle8 : false,
        environmentCompleteFlag: true,
      };
      AxiosInstance.post("form/addEnvironmentSketch", formData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Form Submitted Successfully!",
          });
          setTimeout(() => {
            onBack();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let lastValue1;
  let value1;
  useEffect(() => {
    AxiosInstance.get("form/environmentSketch")
      .then((response) => {
        const appliesMatch = response?.workProfile.match(/Other: ([^;\s]+)/);
        if (appliesMatch) {
          const value = appliesMatch[1];
          setOtherApplies(value);
          setIsOtherAppliesSelected(true);
        } else {
          setIsOtherAppliesSelected(false);
        }
        setApplies(response?.workProfile ? response.workProfile : applies);
        const workModeMatch = response?.workMode.match(/Other: ([^;\s]+)/);
        if (workModeMatch) {
          const value = workModeMatch[1];
          setOtherworkMode(value);
          setIsOtherworkModeSelected(true);
        } else {
          setIsOtherworkModeSelected(false);
        }
        const familyMembersmatch =
          response.familyMembers.match(/otherValue:([^;]+)/g);
        if (familyMembersmatch) {
          lastValue1 = familyMembersmatch[familyMembersmatch.length - 1];
          value1 = lastValue1.slice(11);
          setIsOtherfamilyMembersSelected(true);
        } else {
        }
        setOtherFamilyValue(value1);
        const livingAccommodationMatch =
          response?.livingAccommodation.match(/Other: ([^;\s]+)/);
        if (livingAccommodationMatch) {
          const value = livingAccommodationMatch[1];
          setOtherValue(value);
          setIsOtheraccommodationSelected(true);
        } else {
          setIsOtheraccommodationSelected(false);
        }
        sethighestLevelOfEducation(
          response?.highestLevelOfEducation
            ? response.highestLevelOfEducation
            : highestLevelOfEducation
        );
        setemploymentStatus(
          response?.employmentStatus
            ? response.employmentStatus
            : employmentStatus
        );
        setmonthlySalary(
          response?.monthlySalary ? response.monthlySalary : monthlySalary
        );
        setannualIncome(
          response?.annualIncome ? response.annualIncome : annualIncome
        );
        setfinanciallyStable(
          response?.financiallyStable !== null
            ? response.financiallyStable
            : financiallyStable
        );
        setfreeFromPsychologicalStress(
          response?.freeFromPsychologicalStress !== null
            ? response.freeFromPsychologicalStress
            : freeFromPsychologicalStress
        );
        setcleanAir(response?.cleanAir !== null ? response.cleanAir : cleanAir);
        setpollutedAir(
          response?.pollutedAir !== null ? response.pollutedAir : pollutedAir
        );
        setoccupation(response?.occupation ? response.occupation : occupation);
        settimeSpentInTraffic(
          response?.timeSpentInTraffic
            ? response.timeSpentInTraffic
            : timeSpentInTraffic
        );
        setworkMode(response?.workMode ? response.workMode : workMode);
        settimeSpentOnLaptop(
          response?.timeSpentOnLaptop
            ? response.timeSpentOnLaptop
            : timeSpentOnLaptop
        );
        setrelationshipStatus(
          response?.relationshipStatus
            ? response.relationshipStatus
            : relationshipStatus
        );
        setmilitaryService(
          response?.militaryService !== null
            ? response.militaryService
            : militaryService
        );
        setFamilyMembers(
          response?.familyMembers
            ? response.familyMembers.split(";")
            : familyMembers
        );
        settakeCaresWhenIll(
          response?.takeCaresWhenIll
            ? response.takeCaresWhenIll
            : takeCaresWhenIll
        );
        setAccommodation(
          response?.livingAccommodation
            ? response.livingAccommodation
            : accommodation
        );
        settotalChildrenInHousehold(
          response?.totalChildrenInHousehold
            ? response.totalChildrenInHousehold
            : totalChildrenInHousehold
        );
        setagesOfChildrenInHousehold(
          response?.agesOfChildrenInHousehold
            ? response.agesOfChildrenInHousehold.split(";")
            : agesOfChildrenInHousehold
        );
        setlivingArrangement(
          response?.livingArrangement
            ? response.livingArrangement
            : livingArrangement
        );
        setproblemsWith(
          response?.problemsWith
            ? response.problemsWith.split(";")
            : problemsWith
        );
        setdepressed(
          response?.fellDepressed ? response.fellDepressed : depressed
        );
        setintensityOfDepression(
          response?.intensityOfDepression
            ? response.intensityOfDepression
            : intensityOfDepression
        );
        settimeSpentOnMobile(
          response?.timeSpentOnMobile
            ? response.timeSpentOnMobile
            : timeSpentOnMobile
        );
        settimeSpentOnTv(
          response?.timeSpentOnTv ? response.timeSpentOnTv : timeSpentOnTv
        );
        setBusyLifeStyle1(
          response?.tryingToMultitask !== null
            ? response.tryingToMultitask
            : busyLifeStyle1
        );
        setBusyLifeStyle2(
          response?.feelExhaustedAndOverwhelmed !== null
            ? response.feelExhaustedAndOverwhelmed
            : busyLifeStyle2
        );
        setBusyLifeStyle3(
          response?.scheduleYourTasksInAdvance !== null
            ? response.scheduleYourTasksInAdvance
            : busyLifeStyle3
        );
        setBusyLifeStyle4(
          response?.hardTimeFocusTheMoment !== null
            ? response.hardTimeFocusTheMoment
            : busyLifeStyle4
        );
        setBusyLifeStyle5(
          response?.hardlyTakeTimeOffFromWork !== null
            ? response.hardlyTakeTimeOffFromWork
            : busyLifeStyle5
        );
        setBusyLifeStyle6(
          response?.lifeIsOutOfBalance !== null
            ? response.lifeIsOutOfBalance
            : busyLifeStyle6
        );
        setBusyLifeStyle7(
          response?.feelGuilty !== null ? response.feelGuilty : busyLifeStyle7
        );
        setBusyLifeStyle8(
          response?.doNotGetTimeToBreathe !== null
            ? response.doNotGetTimeToBreathe
            : busyLifeStyle8
        );
        setenvironmentCompleteFlag(response.environmentCompleteFlag);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const getRelationship = () => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getRelationship();
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className="profile_section">
      <ToastMessage></ToastMessage>

      {environmentStep == 0 && (
        <Grid container spacing={2} mb={5}>
          <Grid item>
            {familyRelation?.accessNeeded == true ? (
              <span style={{ color: "red", fontSize: "x-large" }}>
                Note : Ask{" "}
                <span style={{ fontSize: "larger" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>{" "}
                to fill this form.
              </span>
            ) : (
              ""
            )}
            <div className="register_title">
              <h2>Environment Sketch</h2>
              {/* <h5>Tier 2</h5> */}
            </div>
          </Grid>
        </Grid>
      )}

      <form onSubmit={onEnvironmentSubmit} className="registration_tier1">
        {environmentStep == 0 && (
          //&& age >= 18
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>1. Employment/Work Environment </h4>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ color: fieldErrors.applies ? "red" : "inherit" }}
                  >
                    {/* <span className={applies ? "" : "mandatoryQues"}> */}
                    Which of these applies to you :{/* </span> */}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={applies}
                    onChange={handleAppliesChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Working professional"
                      control={<Radio />}
                      label="Working professional"
                    />
                    <FormControlLabel
                      value="Student"
                      control={<Radio />}
                      label="Student"
                    />
                    <FormControlLabel
                      value="Home maker"
                      control={<Radio />}
                      label="Home maker"
                    />
                    <FormControlLabel
                      value="Retired"
                      control={<Radio />}
                      label="Retired"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio checked={isOtherAppliesSelected} />}
                      label="Other"
                    />
                  </RadioGroup>
                  {/* Render input field for "Other" only if "Other" is selected */}
                  {/* {console.log("otherApplies", otherApplies)} */}
                  {isOtherAppliesSelected && (
                    <input
                      type="text"
                      placeholder="Other Applies to you:"
                      value={otherApplies}
                      onChange={handleOtherAppliesValueChange}
                      onBlur={handleInputBlur}
                    />
                  )}
                </FormControl>
              </Grid>
              {applies == "Working professional" && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors.employmentStatus ? "red" : "inherit",
                      }}
                    >
                      Employment Status (working Professionals) ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={employmentStatus}
                      onChange={handleemploymentStatusChange}
                    >
                      <FormControlLabel
                        value="Currently Working"
                        control={<Radio />}
                        label="Currently Working"
                      />
                      <FormControlLabel
                        value="On medical leave"
                        control={<Radio />}
                        label="On medical leave"
                      />
                      <FormControlLabel
                        value="On disability"
                        control={<Radio />}
                        label="On disability"
                      />
                      <FormControlLabel
                        value="Unemployed"
                        control={<Radio />}
                        label="Unemployed"
                      />
                      <FormControlLabel
                        value="Not Applicable"
                        control={<Radio />}
                        label="Not Applicable"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              {applies == "Working professional" && (
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <FormLabel
                      style={{
                        color: fieldErrors.monthlySalary ? "red" : "inherit",
                      }}
                    >
                      What is your monthly salary (Net in hand after taxes) ?
                    </FormLabel>
                    <div
                      style={{
                        display: "flex",
                        color: fieldErrors.monthlySalary ? "red" : "inherit",
                      }}
                    >
                      <input
                        type="number"
                        value={monthlySalary}
                        onChange={handlemonthlySalaryChange}
                        style={{ width: "150px", marginRight: "10px" }}
                      ></input>
                      <select style={{ width: "100px", textIndent: "10px" }}>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>
                  </FormControl>
                  <div style={{ marginTop: "20px" }}>
                    <label>Occupation (If Applicable)</label>
                    <input
                      onChange={handleoccupationChange}
                      value={occupation}
                    ></input>
                  </div>
                </Grid>
              )}
              {applies == "Working professional" && (
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <FormLabel
                      style={{
                        color: fieldErrors.annualIncome ? "red" : "inherit",
                      }}
                    >
                      What is your annual income (Gross Salary)?
                    </FormLabel>
                    <Select
                      inputProps={{ "aria-label": "Without label" }}
                      value={annualIncome}
                      onChange={handleannualIncomeChange}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value={"< 7.5 lakh"}>&lt; 7.5 lakh</MenuItem>
                      <MenuItem value={"7.5 to 15 lakh"}>
                        7.5 to 15 lakh
                      </MenuItem>
                      <MenuItem value={"15 to 30 lakh"}>15 to 30 lakh</MenuItem>
                      <MenuItem value={"> 30 lakh"}>&gt; 30 lakh</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} mb={5}>
              {applies == "Working professional" && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors.workMode ? "red" : "inherit",
                      }}
                    >
                      What is your work mode (for working professionals only) ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={workMode}
                      onChange={handleWorkModeChange}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Work from home"
                        control={<Radio />}
                        label="Work from home"
                      />
                      <FormControlLabel
                        value="Office"
                        control={<Radio />}
                        label="Office"
                      />
                      <FormControlLabel
                        value="Hybrid"
                        control={<Radio />}
                        label="Hybrid"
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio checked={isOtherworkModeSelected} />}
                        label="Other"
                      />
                    </RadioGroup>
                    {/* Render input field for "Other" only if "Other" is selected */}
                    {isOtherworkModeSelected && (
                      <input
                        type="text"
                        placeholder="Other Option"
                        value={otherworkMode}
                        onChange={handleOtherWorkModeValueChange}
                        onBlur={handleInputBlur2}
                      />
                    )}
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.highestLevelOfEducation
                        ? "red"
                        : "inherit",
                    }}
                  >
                    Highest level of education completed :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={highestLevelOfEducation}
                    name="radio-buttons-group"
                    onChange={handlehighestLevelOfEducationChange}
                  >
                    <FormControlLabel
                      value="Grade"
                      control={<Radio />}
                      label="Grade"
                    />
                    <FormControlLabel
                      value="High School"
                      control={<Radio />}
                      label="High School"
                    />
                    <FormControlLabel
                      value="2 Year Degree"
                      control={<Radio />}
                      label="2 Year Degree"
                    />
                    <FormControlLabel
                      value="4 Year Degree"
                      control={<Radio />}
                      label="4 Year Degree"
                    />
                    <FormControlLabel
                      value="Graduate Degree"
                      control={<Radio />}
                      label="Graduate Degree"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.financiallyStable ? "red" : "inherit",
                    }}
                  >
                    I feel that I am doing well in my career and I am atleast
                    financially stable.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={financiallyStable}
                    onChange={handlefinanciallyStableChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.freeFromPsychologicalStress
                        ? "red"
                        : "inherit",
                    }}
                  >
                    I am quite happy and contented in life and free from any
                    kind of Psychological stress.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={freeFromPsychologicalStress}
                    onChange={handlefreeFromPsychologicalStressChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.cleanAir ? "red" : "inherit",
                    }}
                  >
                    Where I live, the air is relatively clean and free from dust
                    or chemicals.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={cleanAir}
                    onChange={handlecleanAirChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.pollutedAir ? "red" : "inherit",
                    }}
                  >
                    Where I live, the air is not so polluted.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={pollutedAir}
                    onChange={handlepollutedAirChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.timeSpentInTraffic ? "red" : "inherit",
                    }}
                  >
                    How much time do you spend in traffic every day to go to
                    workplace or place of study ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentInTraffic}
                    onChange={handletimeSpentInTrafficChange}
                  >
                    <FormControlLabel
                      value="< 15 minutes"
                      control={<Radio />}
                      label="< 15 minutes"
                    />
                    <FormControlLabel
                      value="15-30 minutes"
                      control={<Radio />}
                      label="15-30 minutes"
                    />
                    <FormControlLabel
                      value="30-60 minutes"
                      control={<Radio />}
                      label="30-60 minutes"
                    />
                    <FormControlLabel
                      value="More than an hour"
                      control={<Radio />}
                      label="More than an hour"
                    />
                    <FormControlLabel
                      value="Does not apply."
                      control={<Radio />}
                      label="Does not apply."
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.timeSpentOnLaptop ? "red" : "inherit",
                    }}
                  >
                    How many hours do you typically spend daily on a laptop for
                    your work (for students and working professionals only) ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentOnLaptop}
                    onChange={handletimeSpentOnLaptopChange}
                  >
                    <FormControlLabel
                      value="< 1"
                      control={<Radio />}
                      label="< 1"
                    />
                    <FormControlLabel
                      value="1-2"
                      control={<Radio />}
                      label="1-2"
                    />
                    <FormControlLabel
                      value="2-4"
                      control={<Radio />}
                      label="2-4"
                    />
                    <FormControlLabel
                      value="4-6"
                      control={<Radio />}
                      label="4-6"
                    />
                    <FormControlLabel
                      value="6-8"
                      control={<Radio />}
                      label="6-8"
                    />
                    <FormControlLabel
                      value=">10"
                      control={<Radio />}
                      label=">10"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={onBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep1}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 1 && (
          // && relationship != "son" || relationship != "daughter"
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>2. Family and Other Relations </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.relationshipStatus
                        ? "red"
                        : "inherit",
                    }}
                  >
                    Relationship Status :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={relationshipStatus}
                    onChange={handlerelationshipStatusChange}
                  >
                    <FormControlLabel
                      value="Single"
                      control={<Radio />}
                      label="Single"
                    />
                    <FormControlLabel
                      value="Married"
                      control={<Radio />}
                      label="Married"
                    />
                    <FormControlLabel
                      value="Separated"
                      control={<Radio />}
                      label="Separated"
                    />
                    <FormControlLabel
                      value="Divorced"
                      control={<Radio />}
                      label="Divorced"
                    />
                    <FormControlLabel
                      value="Widowed"
                      control={<Radio />}
                      label="Widowed"
                    />
                    <FormControlLabel
                      value="Partnered"
                      control={<Radio />}
                      label="Partnered"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.militaryService ? "red" : "inherit",
                    }}
                  >
                    Did you serve in the military?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={militaryService}
                    onChange={handlemilitaryServiceChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.familyMembers ? "red" : "inherit",
                    }}
                  >
                    Who all are in your family (living with you)?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Mother")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Mother"
                      value="Mother"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Father")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Father"
                      value="Father"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Spouse")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Spouse"
                      value="Spouse"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Children")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Children"
                      value="Children"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Grand Father")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Grand Father"
                      value="Grand Father"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={familyMembers.includes("Grand Mother")}
                          onChange={handleFamilyMemberChange}
                        />
                      }
                      label="Grand Mother"
                      value="Grand Mother"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            isOtherfamilyMembersSelected &&
                            !(
                              Array.isArray(familyMembers) &&
                              familyMembers.some((item) => /None/i.test(item))
                            )
                          }
                          onChange={handleFamilyMemberChange2}
                        />
                      }
                      label="Other"
                      value="Other"
                    />
                  </FormGroup>

                  {isOtherfamilyMembersSelected && (
                    <input
                      type="text"
                      value={otherFamilyValue}
                      onChange={handleOtherFamilyValueChange}
                      onBlur={handleInputBlurFamily}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <label
                  style={{
                    color: fieldErrors2.takeCaresWhenIll ? "red" : "inherit",
                  }}
                >
                  Who takes care when you are ill ?
                </label>
                <input
                  type="text"
                  value={takeCaresWhenIll}
                  onChange={handletakeCaresWhenIllChange}
                ></input>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.accommodation ? "red" : "inherit",
                    }}
                  >
                    Living Accommodation :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={accommodation}
                    onChange={handleAccommodationChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="House"
                      control={<Radio />}
                      label="House"
                    />
                    <FormControlLabel
                      value="Apartment"
                      control={<Radio />}
                      label="Apartment"
                    />
                    <FormControlLabel
                      value="Extended Care Facility"
                      control={<Radio />}
                      label="Extended Care Facility"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio checked={isOtheraccommodationSelected} />}
                      label="Other"
                    />
                  </RadioGroup>
                  {/* Render input field for "Other" only if "Other" is selected */}
                  {isOtheraccommodationSelected && (
                    <input
                      type="text"
                      placeholder="Accomadation"
                      value={otherValue}
                      onChange={handleOtherValueChange}
                      onBlur={handleInputBlurChildren}
                    />
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <label
                  style={{
                    color: fieldErrors2.totalChildrenInHousehold
                      ? "red"
                      : "inherit",
                  }}
                >
                  How many children live in your household ?
                </label>
                <input
                  type="number"
                  value={totalChildrenInHousehold}
                  onChange={handletotalChildrenInHouseholdChange}
                ></input>
              </Grid>
              <Grid item xs={12} md={3}>
                {/* <label>If you have children living in your household, what are their ages  ?</label> 
                        <TextField></TextField> */}
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    If you have children living in your household, what are
                    their ages ?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={agesOfChildrenInHousehold.includes(
                            "Between 0 to 5"
                          )}
                        />
                      }
                      label="Between 0 to 5"
                      value="Between 0 to 5"
                      onChange={handleagesOfChildrenInHouseholdChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={agesOfChildrenInHousehold.includes(
                            "Between 5 to 18"
                          )}
                        />
                      }
                      label="Between 5 to 18"
                      value="Between 5 to 18"
                      onChange={handleagesOfChildrenInHouseholdChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={agesOfChildrenInHousehold.includes(
                            "Above 18"
                          )}
                        />
                      }
                      label="Above 18"
                      value="Above 18"
                      onChange={handleagesOfChildrenInHouseholdChange}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.livingArrangement ? "red" : "inherit",
                    }}
                  >
                    Living Arrangement :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={livingArrangement}
                    onChange={handlelivingArrangementChange}
                  >
                    <FormControlLabel
                      value="Alone"
                      control={<Radio />}
                      label="Alone"
                    />
                    <FormControlLabel
                      value="With family"
                      control={<Radio />}
                      label="With family"
                    />
                    <FormControlLabel
                      value="With friends"
                      control={<Radio />}
                      label="With friends"
                    />
                    <FormControlLabel
                      value="Old Age Home"
                      control={<Radio />}
                      label="Old Age Home"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.problemsWith ? "red" : "inherit",
                    }}
                  >
                    Do you have problems with ?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={problemsWith.includes("Hearing")} />
                      }
                      label="Hearing"
                      value="Hearing"
                      onChange={handleproblemsWithChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={problemsWith.includes("Speech")} />
                      }
                      label="Speech"
                      value="Speech"
                      onChange={handleproblemsWithChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={problemsWith.includes("Sight")} />
                      }
                      label="Sight"
                      value="Sight"
                      onChange={handleproblemsWithChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={problemsWith.includes("None Of the Above")}
                        />
                      }
                      label="None Of the Above"
                      value="None Of the Above"
                      onChange={handleproblemsWithChange}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    How Busy is Your Lifestyle?
                  </FormLabel>
                  <Row>
                    <Col lg={10}></Col>
                    <Col lg={2}>
                      <div
                        style={{
                          display: "flex",
                          gap: "65px",
                          marginTop: "-35px",
                        }}
                      >
                        <div style={{ fontWeight: "bold" }}>Yes</div>
                        <div style={{ fontWeight: "bold" }}>No</div>
                      </div>
                    </Col>
                  </Row>
                  <FormGroup>
                    <FormControl style={{ width: "100%" }}>
                      {/* Question 1 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle1
                                ? "red"
                                : "inherit",
                            }}
                          >
                            1. You are always trying to multitask
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="multitask"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            onChange={handleBusyLifeStyle1}
                            value={busyLifeStyle1}
                          >
                            <FormControlLabel
                              value={"true"}
                              control={<Radio />}
                              // label="Ys"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>
                      {/* Question 2 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle2
                                ? "red"
                                : "inherit",
                            }}
                          >
                            2. You feel exhausted and overwhelmed at the end of
                            the day (or at any time of the day)
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="exhausted"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            value={busyLifeStyle2}
                            onChange={handleBusyLifeStyle2}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              // label="Ys"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>

                      {/* Question 3 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle3
                                ? "red"
                                : "inherit",
                            }}
                          >
                            3. You have to schedule your tasks or work weeks in
                            advance
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="schedule"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            value={busyLifeStyle3}
                            onChange={handleBusyLifeStyle3}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              // label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>

                      {/* Question 4 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle4
                                ? "red"
                                : "inherit",
                            }}
                          >
                            4. You have a hard time focusing and enjoying the
                            moment
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="focus"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            value={busyLifeStyle4}
                            onChange={handleBusyLifeStyle4}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              // label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>

                      {/* Question 5 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle5
                                ? "red"
                                : "inherit",
                            }}
                          >
                            5. You hardly take time off from your work or
                            schedule
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="timeOff"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            value={busyLifeStyle5}
                            onChange={handleBusyLifeStyle5}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              // label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>

                      {/* Question 6 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle6
                                ? "red"
                                : "inherit",
                            }}
                          >
                            6. At times you feel your life is out of balance
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="imbalance"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            value={busyLifeStyle6}
                            onChange={handleBusyLifeStyle6}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              // label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>

                      {/* Question 7 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle7
                                ? "red"
                                : "inherit",
                            }}
                          >
                            7. You feel guilty a lot of the time for not having
                            enough time to do things that you really want to do
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="guilty"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            value={busyLifeStyle7}
                            onChange={handleBusyLifeStyle7}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              // label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>

                      {/* Question 8 */}
                      <Row>
                        <Col lg={10}>
                          <FormLabel
                            style={{
                              fontWeight: "normal",
                              color: fieldErrors2.busyLifeStyle8
                                ? "red"
                                : "inherit",
                            }}
                          >
                            8. You feel that you don't even get time to breathe
                            (literally!) between tasks throughout the day
                          </FormLabel>
                        </Col>
                        <Col lg={2}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="noBreathe"
                            style={{
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              gap: "45px",
                            }}
                            value={busyLifeStyle8}
                            onChange={handleBusyLifeStyle8}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              // label="/Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              // label="No"
                            />
                          </RadioGroup>
                        </Col>
                      </Row>
                    </FormControl>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep2}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 2 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>3. Optional Questions </h4>
                  <p>
                    The following questions are optional. You may choose not to
                    answer but answering them may help us give you better
                    insight into your chronic diseases risk evaluation.{" "}
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.depressed ? "red" : "inherit",
                    }}
                  >
                    Do you ever feel depressed ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={depressed}
                    onChange={handledepressedChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {depressed === "yes" && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors3.intensityOfDepression
                          ? "red"
                          : "inherit",
                      }}
                    >
                      If you ever feel depressed then what is your level of
                      intensity of depression (1 = very low , 2 = low , 3 =
                      Medium , 4 = Moderate , 5 = High ) ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={intensityOfDepression}
                      onChange={handleintensityOfDepressionChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="1"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="2"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="3"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="4"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="5"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.timeSpentOnMobile ? "red" : "inherit",
                    }}
                  >
                    How many hours do you think you typically spend daily on
                    your mobile/cell ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentOnMobile}
                    onChange={handletimeSpentOnMobileChange}
                  >
                    <FormControlLabel
                      value="<1"
                      control={<Radio />}
                      label="<1"
                    />
                    <FormControlLabel
                      value="1-2"
                      control={<Radio />}
                      label="1-2"
                    />
                    <FormControlLabel
                      value="2-4"
                      control={<Radio />}
                      label="2-4"
                    />
                    <FormControlLabel
                      value="4-6"
                      control={<Radio />}
                      label="4-6"
                    />
                    <FormControlLabel
                      value=">6 hours"
                      control={<Radio />}
                      label=">6 hours"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.timeSpentOnTv ? "red" : "inherit",
                    }}
                  >
                    How many hours do you spend watching TV on a weekly basis ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={timeSpentOnTv}
                    onChange={handletimeSpentOnTvChange}
                  >
                    <FormControlLabel
                      value="<1"
                      control={<Radio />}
                      label="<1"
                    />
                    <FormControlLabel
                      value="1-3"
                      control={<Radio />}
                      label="1-3"
                    />
                    <FormControlLabel
                      value="3-5"
                      control={<Radio />}
                      label="3-5"
                    />
                    <FormControlLabel
                      value="5-7"
                      control={<Radio />}
                      label="5-7"
                    />
                    <FormControlLabel
                      value="7-10"
                      control={<Radio />}
                      label="7-10"
                    />
                    <FormControlLabel
                      value=">10"
                      control={<Radio />}
                      label=">10 hours"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="submit" className="base">
                  Submit
                </button>
              </div>
            </Grid>
          </>
        )}
      </form>
    </div>
  );
};

export default BiographicalSketch;
