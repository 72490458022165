import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "./style.css";

const InfoModel = (props) => {
  const { open, setOpen, navigateTo, children, heading, buttonText, headerIcon, maxWidth, refresh, haveCloseIcon } = props;
  const navigate = useNavigate();
  const [fullWidth, setFullWidth] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate(navigateTo);
    if (refresh) {
      window.location.reload();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="draggable-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        {headerIcon && <div className="popup-header-icon">{headerIcon}</div>}
        {heading && <div className="popup-heading">{heading}</div>}
      </DialogTitle>
      {haveCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>{children}</DialogContent>
      {buttonText && (
        <DialogActions className="footer-action">
          <Button onClick={handleClose}>{buttonText}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default InfoModel;
