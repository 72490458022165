import React from "react";
import "./Corporate.css"; // Import the styles

const Stairs = () => {
  // Data for the steps
  const steps = [
    { count: 62, label: "Very Low" },
    { count: 180, label: "Low" },
    { count: 220, label: "Medium" },
    { count: 10, label: "Moderate" },
    { count: 3, label: "High" },
  ];

  return (
    <>
      <div className="stairs-container">
        {steps.map((step, index) => (
          <>
            <div
              key={index}
              className="step-wrapper"
              style={{ marginTop: `${index * -15}px` }}
            >
              <div className="step-count">{step.count}</div>
              <div className="step">
                <div className="step-label">{step.label}</div>
              </div>
            </div>
          </>
        ))}
      </div>

      <div className="total">Total Employees Subscription = 475</div>
    </>
  );
};

export default Stairs;
