import React from "react";
import "./AddToCart.css";

const AddToCart = ({ onPayClick, userDetails, buttonText }) => {
  const parsedUserDetails = userDetails;

  return (
    <div className="addToCart">
      <div className="WhiteContainer">
        <div>
          <div className="overlap-4">
            <p className="text-wrapper-11">Bronze Health Service (per month)</p>
            <div className="text-wrapper-12">₹ 109</div>
          </div>
          <div className="overlap-4">
            <div className="text-wrapper-11">3 Month Subscription</div>
            <div className="text-wrapper-12">₹ 327</div>
          </div>
          <div className="overlap-4">
            <div className="text-wrapper-11">Total</div>
            <div className="text-wrapper-12">₹ 327</div>
          </div>
          <div style={{ border: "1px solid lightgrey", margin: "5px 0px 5px 0px" }}></div>
          <div className="overlap-4">
            <div className="text-wrapper-11">GST (18%)</div>
            <div className="text-wrapper-12">₹ 59</div>
          </div>
          <div style={{ border: "1px solid lightgrey", margin: "5px 0px 5px 0px" }}></div>
          <div className="overlap-4">
            <div className="text-wrapper-11">Total Amount to Pay</div>
            <div className="text-wrapper-12">₹ 386</div>
          </div>

          <button
            className="PayNowButton"
            onClick={onPayClick}
          >
            {buttonText ? buttonText : parsedUserDetails?.token ? "Pay Now" : "Create Account"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
