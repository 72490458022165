import React , {useMemo} from 'react';

import "./NotificationModel.css"; 
import Notification from "./Notification";
import NameCircle from './NameCircle';

const NotificationModel = () => {
    const sectionListData = useMemo(() => {
        return [
          {
            title: "Unread",
            data: Notification.filter(nt => !nt.read),
          },
          {
            title: "Read",
            data: Notification.filter(nt => nt.read),
          },
        ];
      }, []);
      
    return (
        <div className="NotificationModel">
            <div>
                {sectionListData.map((notifications , index) => (
                    <div>

                        {notifications.title == "Unread" ? 
                        <div> 
                            <div className='NotificationTitle'>{notifications.title}</div>
                             
                             {notifications.data.map((a,i) => (
                                <div className="UnreadnameContainer">
                                    <div><NameCircle key={i} name={a.senderName}></NameCircle></div>
                                    <div>
                                        <div><span>{a.senderName}</span> <span className='whatFor'>{a.whatType}</span><span className='whatFor'> {a.whatFor}</span></div>
                                        <div className='receivedTime'>{a.receivedTime}</div>
                                    </div>
                                </div>

                             ))}
                         </div> : 
                         <div>
                            <div className='NotificationTitle'>{notifications.title}</div>
                             
                             {notifications.data.map((a,i) => (
                                <div className="nameContainer">
                                    <div><NameCircle key={i} name={a.senderName}></NameCircle></div>
                                    <div>
                                        <div><span>{a.senderName}</span> <span className='whatFor'>{a.whatType}</span><span className='whatFor'> {a.whatFor}</span></div>
                                        <div className='receivedTime'>{a.receivedTime}</div>
                                    </div>
                                </div>

                             ))}
                        </div>}

                    </div>
                )) }
            </div>
        </div>
    )
}

export default NotificationModel;