import { Checkbox, FormControlLabel, Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Delete } from "../../icons/DeleteIcon";
import ModelPopUp from "../../shared/model/ModelPopUp";
import "./AddMoreFormMain.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { CheckBox } from "@mui/icons-material";
import iconDate from "../../../static/img/icon-filled-events-3.png";
import "./formStyle.css"
import InputField from "./InputField";

function Immunizations() { 
    const [HeartDiseaseChecked , setHeartDiseaseChecked] =  useState(false)

  return (
    <>
      <div className="health_Service_Providers_Information">
        <div className="info_header_wrapper">
          <div className="info_provider_content">
            <h3>Immunizations (Only for Children below 15 year)</h3>
            <p>Please list your most recent immunizations. Please include your best estimate of the month and year of each immunization.</p>
          </div>
        </div>

        <Grid container spacing={2}>
            <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Hepatitis A"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Measles"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Mumps"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Rubella"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Hepatitis B"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
               <InputField
                  name=""
                  label="Pnuemovax"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Tdap"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Varicella"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Other"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
               <InputField
                  name=""
                  label="Other"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Other"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <InputField
                  name=""
                  label="Other"
                  // control={control}
                  // errors={errors}
                  placeholder="eg . Apr'23"
                  icon={iconDate}
                  className={`inputField`}
                />
              </Grid>
        </Grid>

      </div>
    </>
  );
}

export default Immunizations;
