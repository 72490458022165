import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import iconArrowRight from "../../../static/img/icon-arrow-right-5.png";
import iconBP from "../../../static/img/icon-bp.png";
import iconHeartBeat from "../../../static/img/icon-heart-beat.png";
import iconHeight from "../../../static/img/icon-height.png";
import iconPulse from "../../../static/img/icon-pulse.png";
import iconRelax from "../../../static/img/icon-relax.png";
import tempIcon from "../../../static/img/icon-temp.png";
import iconWeight from "../../../static/img/icon-weight.png";
import iconMedicine from "../../../static/img/medcine-1-3.png";
import spo2 from "../../../static/img/spo2-1.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getAccessToken } from "../../configration/HandleCookies";
import { strings } from "../../constants/strings";
import SampleIllustration from "../../shared/SampleIllustration";
import Spinner from "../../shared/Spinner";
import { formatDateMonthNameFirstThreeChar } from "../../util/Helper";
import SampleReport from "../MedicalHistory/SampleReport";
import LabsTestDocumentPopup from "./LabsTestDocumentPopup";
import ReportDocumentPopup from "./ReportDocumentPopup";
import "./style.css";

export const ElementCaseHistory = () => {
  const [visitData, setVisitData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForDoc, setIsModalOpenForDoc] = useState(false);
  const [isModalOpenForLabTest, setIsModalOpenForLabTest] = useState(false);
  const [reportTitle, setReportTitle] = useState("");
  const [visitDates, setVisitDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const getVisitId = searchParams.get("visitId");
  const getVisitDate = searchParams.get("visitDate");
  const [selectedDate, setSelectedDate] = useState("");
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [allergies, setAllergies] = useState([]);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);

  const getVisitsList = async (option) => {
    setIsLoading(true);
    if (getVisitId) {
      await AxiosInstance.get(`visit/list?date=${getVisitDate}`)
        .then((response) => {
          setVisitData(response[0]);
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      await AxiosInstance.get("visit/latest")
        .then((response) => {
          if (response != null) {
            setVisitData(response);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const getVisitDateList = async () => {
    await AxiosInstance.get("visit/visit-datelist")
      .then((response) => {
        setVisitDates(response);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const openModal = (title) => {
    setIsModalOpen(true);
    setReportTitle(title);
    downloadReport(visitData?.link);
  };

  const openModalDocReport = (title) => {
    setIsModalOpenForDoc(true);
    setReportTitle(title);
  };

  const openModalLabTest = (title) => {
    setIsModalOpenForLabTest(true);
    setReportTitle(title);
  };

  const handleUpdatedDate = async (selectedDate) => {
    setIsLoading(true);
    setImageUrl();
    await AxiosInstance.get(`visit/list?date=${selectedDate}`)
      .then((response) => {
        setVisitData(response[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    getVisitsList();
    getVisitDateList();
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        const allergiesArray = JSON.parse(response.allergies);
        setAllergies(allergiesArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(allergies);

  // let token = getAccessToken();
  let token = window.sessionStorage.getItem("token");

  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const downloadReport = (url) => {
    if (url === null) {
      setIsLoadingDoc(false);
    } else {
      setIsLoadingDoc(true);
      const downloadApi = "prescription/download";
      fetch(
        `${process.env.REACT_APP_SERVER_URL}${downloadApi}/?path=${url}`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }
          const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
          );
          if (contentDispositionHeader) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
              contentDispositionHeader
            );
            if (matches != null && matches[1]) {
              const filename = matches[1].replace(/['"]/g, "");
              response.blob().then((blobData) => {
                const blobUrl = window.URL.createObjectURL(blobData);
                setImageUrl(blobUrl);
                setIsLoadingDoc(false);
              });
            } else {
              toast.error("Filename not found in Content-Disposition header");
              setIsLoadingDoc(false);
            }
          } else {
            toast.error("Content-Disposition header not found in the response");
            setIsLoadingDoc(false);
          }
        })
        .catch((error) => {
          setIsLoadingDoc(false);
        });
    }
  };
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
     AxiosInstance.get("/family/getPatientFamilyProfile")
       .then((response) => {
         setFamilyRelation(response);
       })
       .catch((error) => {
         console.log(error);
       });
  }, []);
  return (
    <>
      <br />
      {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
        <>
          <h4 style={{ color: "blue" }}>
            The health Insights/Information presented below is for your child{" "}
            <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
          </h4>
          <br />
        </>
      )}
      {!isLoading && (
        <>
          {visitData?.sampleData == true ? (
            <SampleIllustration
              titleHeader={strings?.sampleIllustrationCaseHistory?.title}
              noteText1={strings?.sampleIllustrationCaseHistory?.theInformationPresented}
              sampleText={strings?.sampleIllustrationCaseHistory?.sampleBasedOnDummyData}
              noteText2={strings?.sampleIllustrationCaseHistory?.andIsShowJustCase}
            />
          ) : (
            ""
          )}
          <div className="div-history-status">
            <div className="case-history-header">
              <div className="header-content">
                <div className="header-content-text">
                  <p>Visit ID</p>
                  <h6>{visitData?.visitId}</h6>
                </div>
                <div className="header-content-text">
                  <p>Hospital Name</p>
                  <h6>{visitData?.hospitalName}</h6>
                </div>
                <div className="header-content-text">
                  <p>Attending Physician</p>
                  <h6>{visitData?.physicianName}</h6>
                </div>
                <div className="header-content-text">
                  <p>Date</p>
                  <h6>{visitData?.hospitalVisitDate ? formatDateMonthNameFirstThreeChar(visitData?.hospitalVisitDate) : ""}</h6>
                </div>
              </div>
              <div className="shade-center" />
            </div>
            {visitData?.sampleData == false && (
              <div className="filer-by-date">
                <label>
                  <span className="text-style">Filter by Date </span>
                </label>

                <TextField
                  sx={{
                    width: { sm: 200 },
                  }}
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Select Date"
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                    handleUpdatedDate(e.target.value);
                  }}
                  value={selectedDate}
                >
                  {visitDates?.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
          </div>
          <div style={{ marginTop: "10px" }}>
            {allergies &&
              allergies?.map((allergy, index) => (
                <div key={index} style={{ animationDelay: `${index * 0.5}s` }}>
                  <span
                    style={{
                      color: "red",
                      display: "inline-block",
                      animation: "moveLeftRight 15s infinite",
                    }}
                  >
                    <b>Note :</b> Patient is having Allergy from {allergy.MedicineOrFood}, reaction is {allergy.Reaction} and effect is {allergy.effect}.
                  </span>
                </div>
              ))}
          </div>
          <div className="case-history-container">
            <div className="case-history-column">
              <div className="inner-card small-card-third medical-box-color">
                <div className="inner-card-content">
                  <h3>Medical Condition</h3>
                  {visitData?.sampleData == true ? (
                    <p className="description">Hypertension is a common medical condition characterized by elevated blood pressure levels consistently exceeding the normal range.</p>
                  ) : (
                    <>
                      <p>{visitData?.diagnosisReport?.medicalCondition == null || visitData?.diagnosisReport?.medicalCondition == "" ? "No Medical Condition" : visitData?.diagnosisReport?.medicalCondition}</p>
                      <div className="symtoms-wrapper">
                        <span>
                          <b>Symptoms : </b>
                        </span>
                        <span className="sysmtoms">{visitData?.diagnosisReport?.symptoms == null || visitData?.diagnosisReport?.medicalCondition == "" ? "No Symptoms" : visitData?.diagnosisReport?.symptoms}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="inner-card big-card">
                <div className="inner-card-content">
                  <h3>Initial Examination Findings</h3>
                  <div className="examination-findings-wrapper">
                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={tempIcon} alt="Temp" />
                      </div>
                      <div className="examination-content">
                        <p>Temp</p>
                        <h5>{visitData && visitData?.initialExamination?.temperature}</h5>
                      </div>
                    </div>
                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={spo2} alt="SPO2" />
                      </div>
                      <div className="examination-content">
                        <p>SPO2</p>
                        <h5>{visitData && visitData?.initialExamination?.spo2reading}</h5>
                      </div>
                    </div>
                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={iconHeight} alt="Height (cm)" />
                      </div>
                      <div className="examination-content">
                        <p>Height (cm)</p>
                        <h5>{visitData && visitData?.initialExamination?.height}</h5>
                      </div>
                    </div>
                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={iconWeight} alt="Weight" />
                      </div>
                      <div className="examination-content">
                        <p>Weight (Kg)</p>
                        <h5>{visitData && visitData?.initialExamination?.weight}</h5>
                      </div>
                    </div>

                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={iconBP} alt="BP: Systolic" />
                      </div>
                      <div className="examination-content">
                        <p>BP: Systolic</p>
                        <h5>{visitData && visitData?.initialExamination?.bpSys}</h5>
                      </div>
                    </div>
                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={iconHeartBeat} alt="Temp" />
                      </div>
                      <div className="examination-content">
                        <p>Diastolic</p>
                        <h5>{visitData && visitData?.initialExamination?.bpDia}</h5>
                      </div>
                    </div>

                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={iconRelax} alt="Temp" />
                      </div>
                      <div className="examination-content">
                        <p>RR</p>
                        <h5>{visitData && visitData?.initialExamination?.respiratoryRate}</h5>
                      </div>
                    </div>
                    <div className="examination-inner">
                      <div className="examination-icon">
                        <img src={iconPulse} alt="iconPulse" />
                      </div>
                      <div className="examination-content">
                        <p>Pulse</p>
                        <h5>{visitData && visitData?.initialExamination?.pulse}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="case-history-column">
              <div className="inner-card small-card-third">
                <div className="inner-card-content diagnosis-box-color">
                  <h3>Diagnosis</h3>
                  {visitData?.sampleData == true ? (
                    <ul className="diagnosis-list">
                      <li>Allergic Rhinitis</li>
                      <li>Plantar Fasciitis</li>
                    </ul>
                  ) : (
                    <ul className="diagnosis-list">
                      <li>{visitData?.diagnosisReport?.diagnosis == null || visitData?.diagnosisReport?.diagnosis == "" ? "No Diagnosis" : visitData?.diagnosisReport?.diagnosis}</li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="inner-card big-card" style={{height:"27%"}}>
                <div className="inner-card-content">
                  <h3>Medicines</h3>
                  <div style={{height:"30%", marginTop:"20px", overflowY:"scroll"}}>
                    {visitData?.medicineInfo?.length <= 0 && (
                      <div className="medicines-inner">
                        <h6 className="no-data">medicine details not available</h6>
                      </div>
                    )}
                    {visitData?.medicineInfo?.length > 0 &&
                      visitData?.medicineInfo?.map((medicine, i) => {
                        return (
                          <div className="medicines-inner" key={i}>
                            <div className="medicines-icon">
                              <img src={iconMedicine} alt="Medicine" />
                            </div>
                            <div className="medicines-content">
                              <h5>{medicine?.medicineName}</h5>
                              <p>{(medicine.strength ?? 0) + " Tablet • " + (medicine?.days ?? 0) + " days"}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="inner-card small-card-third">
                <div className="inner-card-content">
                  <h3>Tests Recommended</h3>
                  <div className="test-recommended">
                    {visitData?.testNames?.length == 0
                      ? "No Tests Recommended"
                      : visitData?.testNames?.map((item, index) => {
                          if (item !== "") {
                            return (
                              <div key={index} className="test-names">
                                <p>{item}</p>
                              </div>
                            );
                          }
                          return <p>No Tests found</p>;
                        })}
                  </div>
                </div>
              </div>
            </div>
            <div className="case-history-column">
              <div className="inner-card small-card-third">
                <div className="inner-card-content">
                  <h3>Physician Notes</h3>
                  <div className="examination-notes">
                    <p>{visitData?.diagnosisReport?.notes ? visitData?.diagnosisReport?.notes : "Physician notes not available"}</p>
                  </div>
                </div>
              </div>
              <div className="inner-card small-card-third">
                <div className="inner-card-content">
                  <h3>Follow up Date</h3>
                  <div className="examination-notes">
                    <p>{visitData?.followupDate ? visitData?.followupDate : "Follow up Date is not available"}</p>
                  </div>
                  <br />
                  <h5>Follow up comments : </h5>
                  <div className="examination-notes">
                    <p>{visitData?.followupComment ? visitData?.followupComment : "Follow up Comment is not available"}</p>
                  </div>
                </div>
              </div>
              <div className="inner-card small-card-third">
                <div className="inner-card-content">
                  <h3>Reports</h3>
                  <ul className="reports-list">
                    <li>
                      <Link onClick={() => openModal("Prescriptions")}>
                        Prescriptions
                        <img src={iconArrowRight} alt="Arrow Right" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => openModalLabTest("Labs / Tests")}>
                        Labs / Tests
                        <img src={iconArrowRight} alt="Arrow Right" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => openModalDocReport("Other")}>
                        Other
                        <img src={iconArrowRight} alt="Arrow Right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {isModalOpen && (
                <SampleReport
                  reportTitle={reportTitle}
                  isOpen={isModalOpen}
                  // onClose={closeModal}
                  setIsModalOpen={setIsModalOpen}
                  imageUrl={imageUrl}
                  isLoadingDoc={isLoadingDoc}
                  setIsLoadingDoc={setIsLoadingDoc}
                />
              )}
              {isModalOpenForDoc && (
                <ReportDocumentPopup
                  reportTitle={reportTitle}
                  isModalOpenForDoc={isModalOpenForDoc}
                  // onClose={closeModal}
                  setIsModalOpenForDoc={setIsModalOpenForDoc}
                  visitData={visitData}
                />
              )}
              {isModalOpenForLabTest && (
                <LabsTestDocumentPopup
                  reportTitle={reportTitle}
                  isModalOpenForLabTest={isModalOpenForLabTest}
                  // onClose={closeModal}
                  setIsModalOpenForLabTest={setIsModalOpenForLabTest}
                  visitData={visitData}
                />
              )}
            </div>
          </div>
        </>
      )}
      {isLoading && <Spinner />}
    </>
  );
};
