import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { usePagination } from "../../util/utils"; // Assuming you have a pagination utility
import SymptomList from "../../shared/SymptomList"; // Assuming this is your component to display symptoms

const CorporateSymptomsMonthly = ({ setYearlyTopSymptoms, symptomsMonth }) => {
  const [monthlySymptoms, setMonthlySymptoms] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const {
    currentPage,
    totalPages,
    currentData,
    handleNextPage,
    handlePreviousPage,
  } = usePagination(monthlySymptoms);

  // Function to format month display
  const formatMonth = (monthName) => {
    const date = new Date();
    const monthIndex = new Date(monthName + " 1").getMonth(); // Get month index (0-11)
    return `${
      monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase()
    } '${date.getFullYear().toString().slice(-2)}`; // Return formatted month
  };

  // Function to prepare the symptoms data for display
  const prepareMonthlySymptoms = (data) => {
    if (!data || typeof data !== "object") {
      console.error("Invalid symptomsMonth data:", data);
      return; // Exit if data is invalid
    }

    const monthOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const preparedData = Object.entries(data)
      .map(([month, symptoms]) => ({
        month,
        symptoms: symptoms.map((symptom) => {
          try {
            // Attempt to parse symptom if it's a JSON string
            return typeof symptom === "string"
              ? JSON.parse(symptom.replace(/'/g, '"'))
              : symptom;
          } catch (error) {
            console.error("Failed to parse symptom:", symptom, error);
            return {}; // Return an empty object if parsing fails
          }
        }),
      }))
      .sort(
        (a, b) => monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month)
      ); // Sort by month order

    setMonthlySymptoms(preparedData);

    // Automatically select the latest month
    if (preparedData.length > 0) {
      setSelectedMonth(preparedData[preparedData.length - 1]);
    }
  };

  useEffect(() => {
    prepareMonthlySymptoms(symptomsMonth);
  }, [symptomsMonth]);

  const handleAxisLabel = (selectedRow) => {
    setSelectedMonth(selectedRow);
  };

  console.log("symptoms", symptomsMonth, currentData, selectedMonth);

  return (
    <>
      <div className="chart-item">
        <div className="graph-card-title">
          What kind of Symptoms employees experience? (
          <span className="month-year-as-of">
            {selectedMonth && formatMonth(selectedMonth.month)}
          </span>
          )
        </div>
        <SymptomList cumulativeData={selectedMonth?.symptoms} />
        <div
          style={{
            margin: "0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            alignItems: "end",
          }}
        >
          <IconButton
            onClick={handlePreviousPage}
            disabled={currentPage - 1 < 0}
            sx={{
              padding: "5px",
              minWidth: "auto",
              marginBottom: "15px",
              fontSize: "10px",
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <div className="month-slider-wrapper">
            <ul className="slider-months">
              {currentData?.map((element, index) => (
                <li
                  key={index}
                  className={`month-item ${
                    selectedMonth?.month === element.month ? "active" : ""
                  }`}
                  onClick={() => handleAxisLabel(element)}
                >
                  <Button
                    variant="string"
                    sx={{ padding: 0, minWidth: "auto" }}
                  >
                    {formatMonth(element.month)}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage + 1 >= totalPages}
            sx={{
              padding: "5px",
              minWidth: "auto",
              marginBottom: "15px",
              fontSize: "10px",
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        <hr />
        <div className="graph-footer">
          <p className="graph-note">
            Note: Please click on the month to see the data
          </p>
        </div>
      </div>
    </>
  );
};

export default CorporateSymptomsMonthly;
