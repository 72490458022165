/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { IconEvents } from "../IconEvents";
import { IconFilledEvents } from "../IconFilledEvents";
import "./style.css";
import { NavLink, useLocation } from "react-router-dom";
import uploadTestImage from "../../img/upload-test-image.jpg";
import { strings } from "../../constants/strings";

export const NavsEvents = ({
  property1,
  style,
  frameStyle,
  medicalhistorytab,
  labhistorytab,
  casehistorytab,
}) => {
  const divRef = useRef();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isProfileRegister = searchParams.get("isUhidGenerated");

  return (
    // <div
    //   className={`navs-events property-1-1-${property1}`}
    //   style={style}
    // >
    //   <div className="div-wrapper">
    //     <div className="frame-2">
    //       <div className="frame-3">
    //         <div className="frame-4">
    //           {property1 === "default" && <IconFilledEvents iconFilledEvents="/img/icon-filled-events-3.png" />}
    //           {property1 === "variant-2" && <IconEvents iconEvents="/img/icon-events.png" />}
    //           <div className="chronology-of-events">Chronology of Events</div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="component-5">
    //       <div className="menu-item-5">
    //         <div className="frame-49">
    //           <div className="frame-50">
    //             <div className="frame-51">
    //               <div className="ellipse-15" />
    //               <NavLink
    //                 to="/medical-history"
    //                 className="text-wrapper-106 menu-item"
    //                 style={{ color: labhistorytab }}
    //               >
    //                 Medical History
    //               </NavLink>
    //             </div>
    //           </div>
    //           {isProfileRegister && (
    //             <div
    //               className="medicalHistoryWalk"
    //               ref={divRef}
    //             >
    //               <div className="popup-description">
    //                 <img src={uploadTestImage} />
    //                 <h3>{strings.testReportWalkthrough.uploadTestReports}</h3>
    //                 <p>{strings.testReportWalkthrough.pleaseClickAnd}</p>
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>

    //     <div className="component-6">
    //       <div className="menu-item-6">
    //         <div className="frame-49">
    //           <div className="frame-52">
    //             <div className="frame-51">
    //               <div className="ellipse-15" />
    //               <NavLink
    //                 to="/lab-history"
    //                 className="text-wrapper-106 menu-item"
    //                 style={{ color: casehistorytab }}
    //               >
    //                 Lab History
    //               </NavLink>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="component-7">
    //       <div className="menu-item-7">
    //         <div className="frame-49">
    //           <div className="frame-50">
    //             <div className="frame-51">
    //               <div className="ellipse-15" />
    //               <NavLink
    //                 to="/case-history"
    //                 className="text-wrapper-106 menu-item"
    //                 style={{ color: medicalhistorytab }}
    //               >
    //                 Case History
    //               </NavLink>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={`navs-events ${property1}`} style={style}>
      <div className="menu-item">
        <div className="frame" style={frameStyle}>
          <div className="frame-wrapper" style={{marginTop:"20px"}}>
            <div className="div">
              {property1 === "default" && (
                <IconFilledEvents iconFilledEvents="/img/icon-filled-events-3.png" />
              )}

              {property1 === "variant-2" && (
                <IconEvents iconEvents="/img/icon-events.png" />
              )}

              <div className="component-8">
                <div className="menu-item-8">
                  <div className="frame-49">
                    <div className="frame-50">
                      <div className="frame-51">
                        <NavLink
                          to="/events"
                          className="text-wrapper-106 menu-item overview"
                        >
                          Upload Medical Records
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  {/* <div className="component-6">
         <div className="menu-item-6">
           <div className="frame-49">
             <div className="frame-52">
               <div className="frame-51">
                 <div className="ellipse-15" />
                 <NavLink
                    to="/lab-history"
                    className="text-wrapper-106 menu-item"
                    style={{ color: casehistorytab }}
                  >
                    Lab History
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsEvents.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  medicalhistorytab: PropTypes.string,
  labhistorytab: PropTypes.string,
  casehistorytab: PropTypes.string,
};
