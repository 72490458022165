import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "./Admin.css";
import { toast } from "react-toastify";
import AccountManagerCommentModel from "./AccountManagerCommentModel";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
const ApprovalsInvoice = () => {
  const [LabDetails, setLabDetails] = useState();
  const [labName, setLabName] = useState("")
  const [labPhoneNumber, setLabPhoneNumber] = useState("")
  const [invoiceData, setInvoiceData] = useState([]); 
  const [openModal, setOpenModal] = useState(false);
   const [openCommentModal, setOpenCommentModal] = useState(false); // Modal open state
   const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [formState, setFormState] = useState({
    labName: "",
    labId: "",
  });

 const handleOpenCommentModal = (invoice) => {
   setSelectedInvoice(invoice); // Set the selected invoice data
   setOpenCommentModal(true); // Open the modal
 };

 // Close the comment modal
 const handleCloseCommentModal = () => {
   setOpenCommentModal(false); // Close the modal
   setSelectedInvoice(null); // Clear selected invoice data
 };

  
  useEffect(() => {
    AxiosInstance.get("admin/get-unApproved-invoice-labs")
      .then((response) => {
        setLabPhoneNumber(response?.labContact);
        if (response != null) {
          setLabDetails(response);
        } else {
          setLabDetails([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);
   const handleCloseModal = () => setOpenModal(false);
  useEffect(() => {
    if (formState?.labId) {
      AxiosInstance.get(`admin/get-unApprove-invoice/${formState?.labId}`)
        .then((response) => {
          if (response) {
            setInvoiceData(response);
          } else {
            setInvoiceData([]);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
     }
   }, [formState.labId]);

   useEffect(() => {
      if (LabDetails?.length > 0 && !formState.labId) {
        const firstLab = LabDetails[0];
        setFormState({
          labName: firstLab.labName,
          labId: firstLab.labId,
        });
        setLabPhoneNumber(firstLab?.labContact)
        setLabName(firstLab?.labOwnerName);
      }
   }, [LabDetails]);

    const handleSubmit = (invoiceDetails) => {
      setOpenModal(false);
    };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "labName") {
      const selectedLab = LabDetails.find((lab) => lab.labName === value);
      setFormState({
        ...formState,
        labName: value,
        labContactNo: selectedLab ? selectedLab.labContact : "",
        labId: selectedLab ? selectedLab.labId : null,
      });
      setLabPhoneNumber(selectedLab?.labContact);
      setLabName(selectedLab?.labOwnerName);
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  let token = window.sessionStorage.getItem("token");
  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

const handleInvoiceDownload = async (docLink) => {
  const downloadApi = "admin/download-invoice-file";
  let token = window.sessionStorage.getItem("token");
  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const encodedPath = encodeURIComponent(docLink);
    const responseDoc = await fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedPath}`, requestOptions);

    if (!responseDoc.ok) {
      throw new Error(`Network response was not ok: ${responseDoc.status}`);
    }

    const contentDispositionHeader = responseDoc.headers.get("Content-Disposition");

    if (contentDispositionHeader) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        const filename = matches[1].replace(/['"]/g, "");
        responseDoc.blob().then((blobData) => {
          const blobUrl = window.URL.createObjectURL(blobData);

          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = filename;
          a.click();

          window.URL.revokeObjectURL(blobUrl);
        });
      } else {
        console.error("Filename not found in Content-Disposition header");
      }
    } else {
      console.error("Content-Disposition header not found in the response");
    }
  } catch (error) {
    console.error("Error fetching or downloading the file:", error);
  }
};

 const reloadInvoiceData = () => {
   if (formState?.labId) {
     AxiosInstance.get(`admin/get-unApprove-invoice/${formState?.labId}`)
       .then((response) => {
         if (response) {
           setInvoiceData(response);
         } else {
           setInvoiceData([]);
         }
       })
       .catch((err) => {
         console.log("err: ", err);
       });
   }
 };

  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <h5>Select Lab Name : </h5>
        <hr style={{ border: "1px solid #000000", width: "12%" }}></hr>
        <Card className="LabOrderDetailCard">
          <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="labName">
                <b>Lab Name:</b>
              </Label>
              <Input id="labName" name="labName" type="select" value={formState.labName} onChange={handleChange}>
                {LabDetails?.map((lab) => (
                  <option key={lab.labId} value={lab.labName}>
                    {lab.labName}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="labName">
                  <b>Lab Owner Name:</b>
                </Label>
                <Input id="labName" name="labName" type="text" value={labName} disabled></Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="labName">
                  <b>Lab Phone Number:</b>
                </Label>
                <Input id="labPhoneNumber" name="labPhoneNumber" type="text" value={labPhoneNumber} disabled></Input>
              </FormGroup>
            </Col>
          </Row>
        </Card>
        <br></br>
        {formState?.labId && (
          <Card className="LabOrderDetailCard">
            <h3>Invoice</h3>
            <p style={{ marginTop: "1%" }}>
              <b>System will automatically generate the invoice information, and you will be able to attach your invoice for payment.</b>
            </p>
            <TableContainer>
              <Table style={{ border: "1px solid lightgrey", marginTop: "20px", whiteSpace: "nowrap" }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="tableCell">LSH Order ID</TableCell>
                    <TableCell className="tableCell">Invoice Month/Year</TableCell>
                    <TableCell className="tableCell">Total Orders</TableCell>
                    <TableCell className="tableCell">Total Amount</TableCell>
                    <TableCell className="tableCell">Invoice File</TableCell>
                    <TableCell className="tableCell">Invoice Date</TableCell>
                    <TableCell className="tableCell">Invoice Amount</TableCell>
                    <TableCell className="tableCell">Total Confirmed?</TableCell>
                    <TableCell className="tableCell">Invoice Approval</TableCell>
                    <TableCell className="tableCell">Account Mgr. Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ border: "1px solid black" }}>{row.orderId}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.orderDate}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.totalQuantity}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.totalAmount}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleInvoiceDownload(row.invoiceFile);
                          }}
                        >
                          {row.invoiceFile}
                        </a>
                      </TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.invoiceDate}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.invoiceAmount}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.totalConfirmed}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.approval}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>
                        {row.managerComment ? (
                          row.managerComment
                        ) : (
                          <Button variant="contained" onClick={() => handleOpenCommentModal(row)}>
                            Add Comment
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}
      </Form>
      {selectedInvoice && (
        <AccountManagerCommentModel
          open={openCommentModal}
          onClose={handleCloseCommentModal}
          selectedInvoice={selectedInvoice}
          onCommentSubmit={reloadInvoiceData}
        />)}
    </>
  );
};
export default ApprovalsInvoice;
