import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Avatar,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import axios from "axios";
import { toast } from "react-toastify";
import { MdCheckCircle } from "react-icons/md";
import { Person } from "@mui/icons-material";
import "./DoctorScreens.css";
import ToastMessage from "../../shared/ToastMessage";

const stateList = [
  {
    id: 1,
    name: "Andhra Pradesh",
  },
  {
    id: 2,
    name: "Arunachal Pradesh",
  },
  {
    id: 3,
    name: "Assam",
  },
  {
    id: 4,
    name: "Bihar",
  },
  {
    id: 5,
    name: "Chattisgarh",
  },
  {
    id: 6,
    name: "Goa",
  },
  {
    id: 7,
    name: "Gujarat",
  },
  {
    id: 8,
    name: "Haryana",
  },
  {
    id: 9,
    name: "Himachal Pradesh",
  },
  {
    id: 10,
    name: "Jharkhand",
  },
  {
    id: 11,
    name: "Karnataka",
  },
  {
    id: 12,
    name: "Kerala",
  },
  {
    id: 13,
    name: "Madhya Pradesh",
  },
  {
    id: 14,
    name: "Maharashtra",
  },
  {
    id: 15,
    name: "Manipur",
  },
  {
    id: 16,
    name: "Meghalaya",
  },
  {
    id: 17,
    name: "Mizoram",
  },
  {
    id: 18,
    name: "Nagaland",
  },
  {
    id: 19,
    name: "Odisha",
  },
  {
    id: 20,
    name: "Punjab",
  },
  {
    id: 21,
    name: "Rajasthan",
  },
  {
    id: 22,
    name: "Sikkim",
  },
  {
    id: 23,
    name: "Tamil Nadu",
  },
  {
    id: 24,
    name: "Telangana",
  },
  {
    id: 25,
    name: "Tripura",
  },
  {
    id: 26,
    name: "Uttarakhand",
  },
  {
    id: 27,
    name: "Uttar Pradesh",
  },
  {
    id: 28,
    name: "West Bengal",
  },
  {
    id: 29,
    name: "Andaman and Nicobar Islands  (UT)",
  },
  {
    id: 30,
    name: "Chandigarh  (UT)",
  },
  {
    id: 31,
    name: "Dadra and Nagar Haveli and Daman & Diu  (UT)",
  },
  {
    id: 32,
    name: "Delhi  (UT)",
  },
  {
    id: 33,
    name: "Jammu & Kashmir  (UT)",
  },
  {
    id: 34,
    name: "Ladakh  (UT)",
  },
  {
    id: 35,
    name: "Lakshadweep  (UT)",
  },
  {
    id: 36,
    name: "Puducherry  (UT)",
  },
  {
    id: 37,
    name: "Other Territory  (UT)",
  },
];
const DoctorProfile = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobile: "",
    email: "",
    practicingAs: "",
    highestMedicalDegree: "",
    sex: "",
    address: {
      addressHome: "",
      state: "",
      city: "",
      pinCode: "",
    },
    specialInterest: "",
    awards: "",
    researchInterest: "",
    areaOfInterest: "",
    bio: "",
  });

  const [errors, setErrors] = useState({});
  const [stateData, setStateData] = useState([]);
  const inputRefs = {
    firstName: useRef(),
    lastName: useRef(),
    mobile: useRef(),
    email: useRef(),
    addressHome: useRef(),
    practicingAs: useRef(),
    highestMedicalDegree: useRef(),
    sex: useRef(),
    state: useRef(),
    city: useRef(),
    pinCode: useRef(),
    bio: useRef(),
  };
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [profileData, setProfileData] = useState();
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [highestMedicalDegree, setHighestMedicalDegree] = useState([]);
  useEffect(() => {
    getStateList();
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    setFormData(() => ({
      firstName: userDetails.firstName || "",
      middleName: userDetails.middleName || "",
      lastName: userDetails.lastName || "",
      mobile: userDetails.phoneNumber || "",
      email: userDetails.email || "",
      practicingAs: userDetails.practicingAs || "",
      highestMedicalDegree: userDetails.highestMedicalDegree || "",
      sex: userDetails.gender || "",
      address: {
        addressHome: userDetails.address || "",
        state: userDetails.state || "",
        city: userDetails.city || "",
        pinCode: userDetails.pinCode || "",
      },
      specialInterest: userDetails.specialInterest || "",
      awards: userDetails.awards || "",
      researchInterest: userDetails.researchInterest || "",
      areaOfInterest: userDetails.areaOfInterest || "",
      bio: userDetails.bio || "",
    }));
    // downloadProfileData();
    // downloadProfilePic();
    getHighestMedicalDegreeList();
  }, []);

  const getHighestMedicalDegreeList = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}doctor-verify/getDoctorsSpecialization`
      )
      .then((response) => {
        console.log("highest", response);
        if (response?.data) {
          setHighestMedicalDegree(response.data.data);
        } else {
          setHighestMedicalDegree([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const downloadProfileData = () => {
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    AxiosInstance.get("doctor/getDoctorProfile")
      .then((response) => {
        console.log("response: ", response);
        if (response == null) {
          setFormData(() => ({
            firstName: userDetails.firstName || "",
            middleName: userDetails.middleName || "",
            lastName: userDetails.lastName || "",
            mobile: userDetails.phoneNumber || "",
            email: userDetails.email || "",
            practicingAs: userDetails.practicingAs || "",
            highestMedicalDegree: userDetails.highestMedicalDegree || "",
            sex: userDetails.gender || "",
            address: {
              addressHome: userDetails.address || "",
              state: userDetails.state || "",
              city: userDetails.city || "",
              pinCode: userDetails.pinCode || "",
            },
            specialInterest: userDetails.specialInterest || "",
            awards: userDetails.awards || "",
            researchInterest: userDetails.researchInterest || "",
            areaOfInterest: userDetails.areaOfInterest || "",
            bio: userDetails.bio || "",
          }));
        } else {
          setProfileData(response);
          setFormData(() => ({
            firstName: response.firstName || "",
            middleName: response.middleName || "",
            lastName: response.lastName || "",
            mobile: response.phoneNumber || "",
            email: response.email || "",
            practicingAs: response.practicingAs || "",
            highestMedicalDegree: response.highestMedicalDegree || "",
            sex: response.gender || "",
            address: {
              addressHome: response.doctorAddress.address || "",
              state: response.doctorAddress.state || "",
              city: response.doctorAddress.city || "",
              pinCode: response.doctorAddress.zipcode || "",
            },
            specialInterest: response.specialInterest || "",
            awards: response.awards || "",
            researchInterest: response.researchInterest ? "Yes" : "No",
            areaOfInterest: response.areaOfInterest || "",
            bio: response.bio || "",
          }));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Error parsing token:", error);
      return null;
    }
  };

  const downloadProfilePic = () => {
    let token = window.sessionStorage.getItem("token");

    let myHeaders = new Headers();
    myHeaders.append("accept", "multipart/mixed");
    myHeaders.append("Authorization", "Bearer " + token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const decodedToken = parseJwt(token);
    const userId = decodedToken?.ud;
    fetch(
      `${process.env.REACT_APP_SERVER_URL}doctor/download-doctor-profile-pic/${userId}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        // toast.success("File Downloaded successfully");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setProfilePicUrl(blobUrl);
            });
          } else {
            toast.error("Filename not found ");
          }
        } else {
          console.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error("Error fetching or downloading the file:", error);
      });
  };

  const getStateList = () => {
    setStateData(stateList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name in formData.address) {
      setFormData({
        ...formData,
        address: { ...formData.address, [name]: value },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setErrors({ ...errors, [name]: "" });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    // setSelectedFileName(file ? file.name : "");
    if (file) {
      setSelectedFileName(file.name);
      setSelectedFile(URL.createObjectURL(file)); // Create a URL for the selected file
    }
    let fileType = file?.name?.split(".")?.pop();
    if (
      fileType == "gif" ||
      fileType == "jfif" ||
      fileType == "csv" ||
      fileType == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const ProfilePic = new FormData();
      ProfilePic.append("file", file);
      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/doctor-verify/upload-doctor-profile-pic/${formData.mobile}`,
          ProfilePic,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Profile uploaded successfully");
      } catch (response) {
        console.error("API Error:", response);
      }
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.address.addressHome)
      newErrors.addressHome = "Address is required";
    if (!formData.practicingAs)
      newErrors.practicingAs = "Practicing as is required";
    if (!formData.highestMedicalDegree)
      newErrors.highestMedicalDegree = "Highest Medical Degree is required";
    if (!formData.sex) newErrors.sex = "Sex is required";
    if (!formData.address.state) newErrors.state = "State is required";
    if (!formData.address.city) newErrors.city = "City is required";
    if (!formData.address.pinCode) newErrors.pinCode = "Pin Code is required";
    if (!formData.bio) newErrors.bio = "Bio is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const firstErrorField = Object.keys(newErrors)[0];
      inputRefs[firstErrorField].current.focus(); // Focus on the first field with an error
    } else {
      // Handle form submission (e.g., send data to an API or save it)
      const DoctorDetail = {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        phoneNumber: formData.mobile,
        email: formData.email,
        gender: formData.sex,
        doctorAddress: {
          address: formData.address.addressHome,
          city: formData.address.city,
          state: formData.address.state,
          zipcode: formData.address.pinCode,
        },
        practicingAs: formData.practicingAs,
        specialInterest: formData.specialInterest,
        awards: formData.awards,
        researchInterest: formData.researchInterest == "Yes" ? true : false,
        areaOfInterest: formData.areaOfInterest,
        highestMedicalDegree: formData.highestMedicalDegree,
        bio: formData.bio,
      };
      console.log("doctor Details", DoctorDetail);
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}doctor-verify/saveDoctorProfileInfo`,
          DoctorDetail
        )
        .then((response) => {
          setIsSubmitted(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  // Media query for mobile responsiveness
  const isMobile = useMediaQuery("(max-width:600px)");
  console.log("form", formData, profileData, profilePicUrl);

  return (
    <>
      <ToastMessage></ToastMessage>
      {!isSubmitted ? (
        <Paper
          style={{
            padding: isMobile ? "8px" : "16px",
            margin: "auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Part-1 (All Fields are Mandatory)
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <input
                  accept="image/*"
                  id="photograph-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label className="profilePhotoContainer">
                  <Avatar
                    alt="Profile Image"
                    //   src={selectedFile ? selectedFile : profilePicUrl}
                    src={selectedFile}
                    style={{
                      width: "80px",
                      height: "80px",
                      border: "2px solid purple",
                    }}
                  >
                    {/* {!profilePicUrl && !selectedFile && (
                        <Person style={{ fontSize: "50px" }} />
                      )} */}
                    {!selectedFile && <Person style={{ fontSize: "50px" }} />}
                    {/* Default user icon */}
                  </Avatar>
                  <label htmlFor="photograph-upload">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      Upload Photo
                    </Button>
                  </label>
                </label>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  inputRef={inputRefs.firstName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Middle Name"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  inputRef={inputRefs.lastName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                  inputRef={inputRefs.mobile}
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      backgroundColor: "#d3d3d37a",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email}
                  inputRef={inputRefs.email}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl required fullWidth error={!!errors.sex}>
                  <InputLabel>Sex</InputLabel>
                  <Select
                    name="sex"
                    value={formData.sex}
                    onChange={handleChange}
                    inputRef={inputRefs.sex}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  <FormHelperText>{errors.sex}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl required fullWidth error={!!errors.state}>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="state"
                    value={formData.address.state}
                    onChange={handleChange}
                    inputRef={inputRefs.state}
                  >
                    {stateData.map((state) => (
                      <MenuItem key={state.id} value={state.name}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.state}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="City"
                  name="city"
                  value={formData.address.city}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city}
                  inputRef={inputRefs.city}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Pin Code"
                  name="pinCode"
                  value={formData.address.pinCode}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.pinCode}
                  helperText={errors.pinCode}
                  inputRef={inputRefs.pinCode}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Address"
                  name="addressHome"
                  value={formData.address.addressHome}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.addressHome}
                  helperText={errors.addressHome}
                  inputRef={inputRefs.addressHome}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {/* <TextField
                  required
                  label="Highest Medical Degree with Specialty"
                  name="highestMedicalDegree"
                  value={formData.highestMedicalDegree}
                  onChange={handleChange}
                  inputRef={inputRefs.highestMedicalDegree}
                  fullWidth
                  error={!!errors.highestMedicalDegree}
                  helperText={errors.highestMedicalDegree}
                /> */}
                <FormControl
                  required
                  fullWidth
                  error={!!errors.highestMedicalDegree}
                >
                  <InputLabel>Highest Medical Degree with Specialty</InputLabel>
                  <Select
                    name="highestMedicalDegree"
                    value={formData.highestMedicalDegree}
                    onChange={handleChange}
                    inputRef={inputRefs.highestMedicalDegree}
                  >
                    {highestMedicalDegree?.map((degree) => (
                      <MenuItem key={degree.id} value={degree.id}>
                        {degree.qualification}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.highestMedicalDegree}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  label="Practicing as"
                  name="practicingAs"
                  value={formData.practicingAs}
                  onChange={handleChange}
                  inputRef={inputRefs.practicingAs}
                  fullWidth
                  error={!!errors.practicingAs}
                  helperText={errors.practicingAs}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Brief Bio-data"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                  inputRef={inputRefs.bio}
                  fullWidth
                  error={!!errors.bio}
                  helperText={errors.bio}
                  multiline
                  rows={isMobile ? 2 : 4}
                />
              </Grid>
            </Grid>

            {/* Optional Fields */}
            <Grid container spacing={isMobile ? 2 : 3} mt={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" gutterBottom>
                  Part-2 (Optional)
                </Typography>
                <div style={{ marginBottom: "10px" }}>
                  Note: You may simply copy and paste information below from
                  your existing bio
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Areas of special interest in medical practice"
                  name="specialInterest"
                  value={formData.specialInterest}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={isMobile ? 1 : 2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Any awards/ special recognition"
                  name="awards"
                  value={formData.awards}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={isMobile ? 1 : 2}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Interest in research work ?
                  </FormLabel>
                  <RadioGroup
                    row
                    name="researchInterest"
                    value={formData.researchInterest}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formData.researchInterest === "Yes" && (
                <Grid item xs={12}>
                  <TextField
                    label="State Areas of Interest"
                    name="areaOfInterest"
                    value={formData.areaOfInterest}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={isMobile ? 1 : 2}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size={isMobile ? "medium" : "large"}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      ) : (
        <div className="SubmittedProfile">
          <div>
            <MdCheckCircle size={30} color="green" />
          </div>
          <strong style={{ fontSize: "20px", color: "green" }}>
            Thanks for submitting the form!
          </strong>
          <div style={{ fontSize: "16px" }}>Your Profile has been Saved!</div>
        </div>
      )}
    </>
  );
};

export default DoctorProfile;
