import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import PreventiveCareServices from "../LifeStyleRiskAssessment/components/PreventiveCareServices";
import LifeStyleModificationRecommendation from "../LifeStyleRiskAssessment/components/LifeStyleModificationRecommendation";
import FullRecommendation from "../LifeStyleRiskAssessment/components/FullRecommendation";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const PreventiveCare = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
   useEffect(() => {
     AxiosInstance.get("profile/get")
       .then((response) => {
         console.log("response: ", response);
         setFamilyInfo(response?.profile);
       })
       .catch((error) => {
         console.error("Error:", error);
       });
       AxiosInstance.get("/family/getPatientFamilyProfile")
         .then((response) => {
           setFamilyRelation(response);
         })
         .catch((error) => {
           console.log(error);
         });
   }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }}>
          <Tab
            label="Preventive Tests"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          {(familyInfo?.age >= 3 || familyInfo == undefined || familyInfo?.age == null) && (
         <Tab
           label="Full Recommendation"
           sx={{
             textTransform: "capitalize",
             fontWeight: 600,
             fontSize: "large",
             color: currentTabIndex === 1 ? "white" : "black",
             "&.Mui-selected": {
               color: "white",
             },
           }}
         />
          )}
          {/* {(familyInfo?.age >= 3 || familyInfo == undefined) && (
         <Tab
           label="Lifestyle Modifications"
           sx={{
             textTransform: "capitalize",
             fontWeight: 600,
             fontSize: "large",
             color: currentTabIndex === 2 ? "white" : "black",
             "&.Mui-selected": {
               color: "white",
             },
           }}
         />
          )} */}
        </Tabs><br/>
        {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
          <>
            <h4 style={{ color: "blue" }}>
              The health Insights/Information presented below is for your child{" "}
              <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
            </h4>
            <br />
          </>
        )}
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <PreventiveCareServices></PreventiveCareServices>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <FullRecommendation></FullRecommendation>
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <LifeStyleModificationRecommendation></LifeStyleModificationRecommendation>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PreventiveCare;
