import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const PhysiotherapistOnlineConsultation = () => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [order, setOrder] = useState(location?.state?.order || {});

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

//   useEffect(() => {
//     if (Object.keys(order).length === 0) {
//       setOrder(location?.state?.order || {});
//     }
//   }, [location.state]);

  return (
    <>
      <div>No Patient for Online Consultation</div>
    </>
  );
};

export default PhysiotherapistOnlineConsultation;
