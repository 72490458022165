import React from "react";
import "./FAQ.css";

const FAQ = () => {
  return (
    <div>
      <div style={{ padding: "10px 20px 10px 20px" }}>
        <div className="question">How does your health services work?</div>
        <div
          className="answer"
          style={{ marginBottom: "10px" }}
        >
          We have various levels of health services like Bronze, Silver. More advanced level of health services will be launched in the future. Each health service has distinct features designed to
          give you specific set of insights about your health. You start with our basic health service like Bronze and then move to advanced health services to get better and deeper health insights.
          The eventual aim is to help you manage your lifestyle choices and prevent chronic diseases from affecting your health and life.
        </div>
        <div className="answer">
          In our Advanced Health Plans, Powerful AI models continuously scans and investigates all your health records to create a holistic picture of your health for future health predictions and
          disease prevention
        </div>
      </div>

      <div style={{ padding: "0px 20px 10px 20px" }}>
        <div className="question">Which health service plan should I sign up for?</div>
        <div className="answer">We recommend you start with our basic plan i.e., “Bronze” first, before moving on to other advanced health service plans.</div>
      </div>

      <div style={{ padding: "0px 20px 10px 20px" }}>
        <div className="question">How can I get customized health insights so that I can better manage and control my health and live a healthy life?</div>
        <div className="answer">
          The benefits of our health services depend on you taking charge of your health first. This basically means you will have to actively participate throughout your association with us. We need
          all your historical medical records and test reports. This means past, present and future medical records.{" "}
        </div>

        <div className="answer">
          {" "}
          <p>
            We need to know what kind of health issues you had in the past. From time to time, we will require you to participate in our customized health surveys to know how you are doing. The more
            you tell us about yourself and your past health issues and provide us with all medical records and test reports, the better and more accurate will be our AI model’s prediction about your
            current and future health risks. We will also be able to give you suitable preventive health measures including. lifestyle choices to improve your health.
          </p>{" "}
        </div>

        <div className="answer">
          Once you have given us historical medical records and test reports, you have to then keep adding medical records and test reports as and when you visit a hospital Or clinic or get any tests
          done. Ideally you should add information regarding hospital visit Or tests done within one week of you visit to the hospital or lab for tests.
        </div>
      </div>

      <div style={{ padding: "0px 20px 10px 20px" }}>
        <div className="question">How do I improve my Participation Score (PS) to get maximum health benefits?</div>

        <div className="answer">
          Your Participation Score is simply calculated based on how much of your past as well as present medical records as well as hospital visit information (such as medical issues, prescription
          records, Doctor diagnosis, tests recommended) you have provided to us. You get customized health insights including risk factors, lifestyle recommendations etc. based On all these records.
          We cannot provide you with customized health insights unless you Participate and provide us with these records for our AI platform’s pattern and deeper Analysis. The PS score is continuously
          assessed and upgraded as your keep adding these information in the future.
        </div>
      </div>
    </div>
  );
};

export default FAQ;
