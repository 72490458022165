import React, { useEffect, useState } from "react";
import "./MyUsage.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

export const MyUsage = ({ onClose }) => {
  const [MyUsageDetail, setMyUsageDetail] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  useEffect(() => {
    AxiosInstance.get("general/get-app-usage-detail")
      .then((response) => {
        // Handle the successful response here
        setMyUsageDetail(response);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    if (MyUsageDetail.length > 0) {
      // Parse dates and sort the array by startTime
      const sortedArray = MyUsageDetail.map((item) => ({
        ...item,
        startTime: new Date(item.startTime),
        endTime: new Date(item.endTime),
      })).sort((a, b) => b.startTime - a.startTime); // Sort in reverse order

      // Group sessions by screenName and date, and calculate total time and session durations
      const groupedData = {};
      sortedArray.forEach((item) => {
        const dateKey = item.startTime.toISOString().split("T")[0];
        const screenName = item.screenName;

        if (!groupedData[dateKey]) {
          groupedData[dateKey] = {};
        }

        if (!groupedData[dateKey][screenName]) {
          groupedData[dateKey][screenName] = {
            totalDayTime: 0,
            sessions: [],
          };
        }

        const sessionDuration = Math.round((item.endTime - item.startTime) / (1000 * 60)); // in minutes
        groupedData[dateKey][screenName].totalDayTime += sessionDuration;
        groupedData[dateKey][screenName].sessions.push({
          startTime: item.startTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
          endTime: item.endTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
          totalTime: sessionDuration,
        });
      });

      // Convert grouped data to the desired format and reverse the array
      const resultArray = [];
      for (const dateKey in groupedData) {
        const date = new Date(dateKey).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
        for (const screenName in groupedData[dateKey]) {
          const { totalDayTime, sessions } = groupedData[dateKey][screenName];
          resultArray.push({
            date: `${date}`,
            screenName,
            totalDayTime: Math.round(totalDayTime),
            sessions,
          });
        }
      }
      resultArray.sort((a, b) => new Date(b.date) - new Date(a.date));

      setSortedData(resultArray); // Reverse the array to display current dates at the top
    }
  }, [MyUsageDetail]);

  return (
    <div className="my-usage">
      <div className="div-6">
        {sortedData?.map((data, i) => (
          <div
            style={{ padding: "20px" }}
            key={i}
          >
            <div className="overlap-16">
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <div className="text-wrapper-44">{data.date}</div>
                <div className="text-wrapper-45">Plan: Bronze</div>
              </div>
              <div className="sessions-2">
                <span className="text-wrapper-46">{data.screenName}</span>
              </div>
              {data?.sessions.map((item, i) => (
                <div key={i}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <img
                        className="clock-2"
                        alt="Clock"
                        src="/img/clock-3.png"
                      />
                      <span className="text-wrapper-44">
                        {item.startTime} - {item.endTime}
                      </span>
                    </div>
                    <div className="element-min-3">
                      <span className="text-wrapper-47">{item.totalTime} min</span>
                    </div>
                  </div>
                  <div style={{ border: "1px solid #E3E3F2", margin: "5px 0px 5px 0px" }}></div>
                </div>
              ))}
              <div>
                <div className="total-day-time">
                  <span className="text-wrapper-48">Total Day Time:</span> <span className="text-wrapper-49">{data.totalDayTime} min</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
