import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { getLifestyleRiskAssessmentApi } from "../../constants/apiList";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const CorporateRiskTable = () => {
  const [lifeStyleData, setLifeStyleData] = useState({});

  const getLifestyleRiskAssessment = async () => {
    await AxiosInstance.get(`${getLifestyleRiskAssessmentApi}`)
      .then((res) => {
        setLifeStyleData(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getLifestyleRiskAssessment();
  }, []);

  const matchingObjects = {};
  for (const [key, value] of Object.entries(lifeStyleData)) {
    if (value !== null && typeof value === "object") {
      const objValues = Object.values(value);
      if (objValues.includes(lifeStyleData.overallLifestyleRiskScore)) {
        matchingObjects[key] = value;
      }
    }
  }
  const matchingObjectsArray = Object.values(matchingObjects);
  const keysArray = matchingObjectsArray.map((obj) => Object.keys(obj));

  function getColorByLabel(label) {
    switch (label) {
      case "Very Low":
        return "#008000";
      case "Low":
        return "#90EE90";
      case "Medium":
        return "#CADB2A";
      case "Moderate":
        return "#FFA500";
      case "High":
        return "#ff0000";
      case "No Risk":
        return "#916D98";
      case "None":
        return "#916D98";
      case "N/A":
        return "#1C80E3";
      default:
        return null;
    }
  }
  return (
    <div>
      <p style={{ float: "right" }}>
        ( <b>No-Risk : </b>{" "}
        <span
          className="risk-level-circle"
          style={{ background: getColorByLabel("No Risk") }}
        ></span>{" "}
        )
      </p>
      <TableContainer component={Paper} className="risk-management-table">
        <Table aria-label="a dense table" size="small">
          <TableHead style={{ backgroundColor: "grey" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Risk (Y/N)</TableCell>
              <TableCell align="center">Average Risk Level</TableCell>
              <TableCell align="center">No. of Employees</TableCell>
              <TableCell align="center">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="data-heading">
              <TableCell colSpan={3}>A. Personal & Family History</TableCell>
              <TableCell>
                {" "}
                {!!lifeStyleData.personalRiskDate
                  ? lifeStyleData.personalRiskDate
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Personal</TableCell>
              <TableCell scope="row" align="center">
                {!!lifeStyleData.personalRisk ? "Y" : "N"}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("personalAndFamilyRiskMap") && (
                  <>
                    {Object.keys(lifeStyleData?.personalAndFamilyRiskMap).map(
                      (key, index) => (
                        <div key={index}>
                          {" "}
                          {
                            <span
                              className="risk-level-circle"
                              style={{
                                background: getColorByLabel(
                                  lifeStyleData?.personalAndFamilyRiskMap[key]
                                ),
                              }}
                            ></span>
                          }
                        </div>
                      )
                    )}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Family</TableCell>
              <TableCell scope="row" align="center">
                {!!lifeStyleData.familyRisk ? "Y" : "N"}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("personalAndFamilyRiskMap") &&
                  (lifeStyleData?.familyRisk ? (
                    Object.keys(lifeStyleData?.personalAndFamilyRiskMap).map(
                      (key) => (
                        <span
                          key={key}
                          className="risk-level-circle"
                          style={{
                            background: getColorByLabel(
                              lifeStyleData?.personalAndFamilyRiskMap[key]
                            ),
                          }}
                        ></span>
                      )
                    )
                  ) : (
                    <span
                      className="risk-level-circle"
                      style={{ background: getColorByLabel("No Risk") }}
                    ></span>
                  ))}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow className="data-heading">
              <TableCell colSpan={3}>B. Lifestyle/Habits</TableCell>
              <TableCell>
                {!!lifeStyleData?.personalRiskDate
                  ? lifeStyleData?.personalRiskDate
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Overweight/Obesity</TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("overweightObesityValue") && (
                  <>
                    {Object.keys(lifeStyleData?.overweightObesityValue).map(
                      (key, val) => {
                        return <>{key === "true" ? "Y" : "N"}</>;
                      }
                    )}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("overweightObesityValue") && (
                  <>
                    {Object.keys(lifeStyleData?.overweightObesityValue).map(
                      (key) => (
                        <>
                          {
                            <span
                              className="risk-level-circle"
                              style={{
                                background: getColorByLabel(
                                  lifeStyleData?.overweightObesityValue[key]
                                ),
                              }}
                            ></span>
                          }
                        </>
                      )
                    )}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Tobacco</TableCell>
              <TableCell scope="row" align="center">
                {!!lifeStyleData?.tobaccoRisk ? "Y" : "N"}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("tobaccoRiskScore") && (
                  <>
                    {Object.keys(lifeStyleData?.tobaccoRiskScore).map((key) => (
                      <>
                        {
                          <span
                            className="risk-level-circle"
                            style={{
                              background: getColorByLabel(
                                lifeStyleData?.tobaccoRiskScore[key]
                              ),
                            }}
                          ></span>
                        }
                      </>
                    ))}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Alcohol consumption</TableCell>
              <TableCell scope="row" align="center">
                {!!lifeStyleData?.alcoholConsumptionRisk ? "Y" : "N"}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty(
                  "alcoholConsumptionRiskScore"
                ) && (
                  <>
                    {Object.keys(
                      lifeStyleData?.alcoholConsumptionRiskScore
                    ).map((key) => (
                      <>
                        {
                          <span
                            className="risk-level-circle"
                            style={{
                              background: getColorByLabel(
                                lifeStyleData?.alcoholConsumptionRiskScore[key]
                              ),
                            }}
                          ></span>
                        }
                      </>
                    ))}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Unhealthy Diet</TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("unhealthyDietRiskMap") && (
                  <>
                    {Object.keys(lifeStyleData?.unhealthyDietRiskMap).map(
                      (key) => (
                        <span key={key}>{key == "true" ? "Y" : "N"}</span>
                      )
                    )}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("unhealthyDietRiskMap") && (
                  <>
                    {Object.keys(lifeStyleData?.unhealthyDietRiskMap).map(
                      (key) => (
                        <span key={key}>
                          {
                            <span
                              className="risk-level-circle"
                              style={{
                                background: getColorByLabel(
                                  lifeStyleData?.unhealthyDietRiskMap[key]
                                ),
                              }}
                            ></span>
                          }
                        </span>
                      )
                    )}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Sedentary/Lack of Exercise</TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty(
                  "sedentaryLackOfExerciseMapValue"
                ) && (
                  <>
                    {Object.keys(
                      lifeStyleData?.sedentaryLackOfExerciseMapValue
                    ).map((key, val) => {
                      return <>{key === "true" ? "Y" : "N"}</>;
                    })}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty(
                  "sedentaryLackOfExerciseMapValue"
                ) && (
                  <>
                    {Object.keys(
                      lifeStyleData?.sedentaryLackOfExerciseMapValue
                    ).map((key) => (
                      <>
                        {
                          <span
                            className="risk-level-circle"
                            style={{
                              background: getColorByLabel(
                                lifeStyleData?.sedentaryLackOfExerciseMapValue[
                                  key
                                ]
                              ),
                            }}
                          ></span>
                        }
                      </>
                    ))}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Drugs</TableCell>
              <TableCell scope="row" align="center">
                {!!lifeStyleData?.drugsRisk ? "Y" : "N"}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("drugsRiskScore") &&
                  (lifeStyleData?.drugsRisk ? (
                    Object.keys(lifeStyleData?.drugsRiskScore).map((key) => (
                      <span
                        key={key}
                        className="risk-level-circle"
                        style={{
                          background: getColorByLabel(
                            lifeStyleData?.drugsRiskScore[key]
                          ),
                        }}
                      ></span>
                    ))
                  ) : (
                    <span
                      className="risk-level-circle"
                      style={{ background: getColorByLabel("No Risk") }}
                    ></span>
                  ))}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Sleep</TableCell>
              <TableCell scope="row" align="center">
                {!!lifeStyleData?.sleepRisk ? "Y" : "N"}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("sleepRiskScore") && (
                  <>
                    {Object.keys(lifeStyleData?.sleepRiskScore).map((key) => (
                      <>
                        {
                          <span
                            className="risk-level-circle"
                            style={{
                              background: getColorByLabel(
                                lifeStyleData.sleepRiskScore[key]
                              ),
                            }}
                          ></span>
                        }
                      </>
                    ))}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Self Monitoring</TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("selfMonitoringRiskScore") && (
                  <>
                    {Object.keys(lifeStyleData.selfMonitoringRiskScore).map(
                      (key) => (
                        <span key={key}>{key == "true" ? "Y" : "N"}</span>
                      )
                    )}
                  </>
                )}
                {/* {lifeStyleData.hasOwnProperty("selfMonitoringRiskScore") && !!lifeStyleData?.selfMonitoringRiskScore ? "Y" : "N"} */}
              </TableCell>
              <TableCell scope="row" align="center">
                {lifeStyleData.hasOwnProperty("selfMonitoringRiskScore") && (
                  <>
                    {Object.keys(lifeStyleData?.selfMonitoringRiskScore).map(
                      (key) => (
                        <>
                          {
                            <span
                              className="risk-level-circle"
                              style={{
                                background: getColorByLabel(
                                  lifeStyleData?.selfMonitoringRiskScore[key]
                                ),
                              }}
                            ></span>
                          }
                        </>
                      )
                    )}
                  </>
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                -
              </TableCell>
            </TableRow>
            {/* <TableRow className="data-heading">
                  <TableCell colSpan={4}>C. Environment</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Employment</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Living arrangement</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          marginTop: "15px",
        }}
      >
        <div style={{ fontWeight: "500", fontSize: "18px" }}>
          Overall Lifestyle Risk Score :{" "}
        </div>
        <div
          className="risk-management-footer"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid black",
            padding: "15px",
            backgroundColor: getColorByLabel(
              lifeStyleData?.overallLifestyleRiskScore
            ),
          }}
        >
          <h5> {lifeStyleData?.overallLifestyleRiskScore}</h5>
          {/* <TextField
                name="risk-score"
                size="small"
                value={lifeStyleData?.overallLifestyleRiskScore}
                sx={{
                  backgroundColor: "#fff",
                  border: "1px solid #e5e5e5",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              /> */}
        </div>
      </div>
    </div>
  );
};

export default CorporateRiskTable;
