import React, { useEffect, useState } from "react";
import "./PaymentDetail.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getPaymentDetailListApi } from "../../constants/apiList";
import MUITable from "../../shared/MUITable";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import OrderPaymentDetail from "./OrderPaymentDetail";
const PaymentDetail = ({ setCurrentTabIndex }) => {
  const [modal, setModal] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [amountData, setAmountData] = useState("");
  const toggle = () => setModal(!modal);
  const columns = [
    // { id: "stripeOrderId", label: "stripeOrderId", isLink: false },
    { id: "paymentAmount", label: "Amount (INR)", isLink: false },
    { id: "paymentStatus", label: "Payment Status", isLink: false },
    { id: "createdOn", label: "Created On", isLink: false },
    {
      id: "viewPayment",
      label: "View Detail",
      numeric: true,
      disablePadding: false,
      isLink: true,
    },
  ];
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [fetchedPaymentDetails, setFetchedPaymentDetails] = useState([]);
  const [paymentDetail, setPaymentDetail] = useState([]);
  useEffect(() => {
    const getBuildPackageDate = async () => {
      try {
        const response = await AxiosInstance.get(getPaymentDetailListApi);
        setFetchedPaymentDetails(response);
      } catch (err) {
        console.log("err: ", err);
      }
    };
    getBuildPackageDate();
  }, []);

  useEffect(() => {
    if (fetchedPaymentDetails.length > 0) {
      const updatedPaymentDetails = fetchedPaymentDetails.map((detail) => ({
        ...detail,
        viewPayment: "viewPayment",
      }));
      setPaymentDetail((prevDetails) => [
        ...prevDetails,
        ...updatedPaymentDetails,
      ]);
    }
  }, [fetchedPaymentDetails]);
  useEffect(() => {
    if (selectedRowData && selectedRowData.columnName === "viewPayment") {
      setModal(!modal);
      // setCurrentTabIndex(4);
      setPaymentId(selectedRowData.rowData.paymentOrderId);
      setCreatedDate(selectedRowData.rowData.createdOn);
      setAmountData(selectedRowData.rowData.paymentAmount);
    }
  }, [selectedRowData, setCurrentTabIndex, setPaymentId]);
  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Payment Detail</ModalHeader>
          <ModalBody>
            <OrderPaymentDetail
              paymentId={paymentId}
              amount={amountData}
              createdOn={createdDate}
            />
          </ModalBody>
        </Modal>
      </div>
      {paymentDetail.length > 0 && (
        <MUITable
          data={paymentDetail}
          columns={columns}
          setSelectedRowData={setSelectedRowData}
        />
      )}
    </>
  );
};

export default PaymentDetail;
