import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { IconMail } from "../../components/IconMail";
import { IconSolidPassword } from "../../components/IconSolidPassword";
import { IconUser } from "../../components/IconUser";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { setCookies } from "../../configration/HandleCookies";
import IndiaFlag from "../../img/india-flag.png";
import ToastMessage from "../../shared/ToastMessage";
import "./style.css";

export const SignUpBeforePay = ({ onSignUpCloseModal }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setNumber] = useState("");
  const [flagTermsAndCondition, setTermsandPolicycheckedChecked] =
    useState(false);
  const [yearschecked, setyearsChecked] = useState(false);
  const [flagWhatsappNotification, setWhatsAppChecked] = useState(false);
  const [
    flagTermsAndConditionerrorMessage,
    setflagTermsAndConditionErrorMessage,
  ] = useState("");
  const [ageerrorMessage, setageErrorMessage] = useState("");
  const [firstNameerrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastNameerrorMessage, setLastNameErrorMessage] = useState("");
  const [phoneNumbererrorMessage, setphoneNumberErrorMessage] = useState("");
  const [emailerrorMessage, setemailErrorMessage] = useState("");
  const [passworderrorMessage, setpasswordErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [referralCode, setReferralCode] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const TermsandPolicyhandleChange = (event) => {
    setTermsandPolicycheckedChecked(event.target.checked);
    setflagTermsAndConditionErrorMessage("");
  };

  const yearscheckedChange = (event) => {
    setyearsChecked(event.target.checked);
    setageErrorMessage("");
  };

  const WhatsAppcheckedChange = (event) => {
    setWhatsAppChecked(event.target.checked);
  };

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameErrorMessage("");
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameErrorMessage("");
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setemailErrorMessage("");
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setpasswordErrorMessage("");
  };

  const onPhoneNumberChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setNumber(result);
    if (result) {
      setphoneNumberErrorMessage("");
    }
  };

  const onReferralCodeChange = (e) => {
    setReferralCode(e.target.value);
  };

  const login = async () => {
    await AxiosInstance.post("api/auth/signin", { email, password })
      .then((response) => {
        // setCookies(response);
        window.sessionStorage.setItem("token", response?.token);
        // window.sessionStorage.setItem("token", response.token);
        // window.sessionStorage.setItem("userDetails", JSON.stringify(response));
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const SignUp = async () => {
    if (firstName == (undefined || null || "")) {
      setFirstNameErrorMessage("Please enter the First Name");
      return;
    }
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
    if (!regex.test(firstName)) {
      setFirstNameErrorMessage(
        "Please enter a combination of alphabets and numbers."
      );
      return;
    }
    if (lastName == (undefined || null || "")) {
      setLastNameErrorMessage("Please enter the Last Name");
      return;
    }
    const regex1 = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
    if (!regex1.test(lastName)) {
      setLastNameErrorMessage(
        "Please enter a combination of alphabets and numbers."
      );
      return;
    }
    if (phoneNumber == (undefined || null || "")) {
      setphoneNumberErrorMessage("Please enter the phone number");
      return;
    }
    const regex2 = /^\d{10}$/;
    if (!regex2.test(phoneNumber)) {
      setphoneNumberErrorMessage("Phone number must be 10 digits long.");
      return;
    }
    if (email == (undefined || null || "")) {
      setemailErrorMessage("Please enter the email");
      return;
    }
    if (password.includes(" ")) {
      setpasswordErrorMessage("Blank spaces are not allowed.");
      return;
    }
    if (password == (undefined || null || "")) {
      setpasswordErrorMessage("Please enter the password");
      return;
    }
    const regex3 =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{6,}$/;
    if (!regex3.test(password)) {
      setpasswordErrorMessage(
        "Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 6 characters long."
      );
      return;
    }
    if (!flagTermsAndCondition) {
      setflagTermsAndConditionErrorMessage(
        "Please read and accept the Terms & Conditions and Privacy Policy."
      );
      return;
    }
    if (!yearschecked) {
      setageErrorMessage("Please accept the age agreement.");
      return;
    }

    console.log("Logos", {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      flagTermsAndCondition,
      flagWhatsappNotification,
      referralCode,
    });

    await AxiosInstance.post("api/auth/signup", {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      flagTermsAndCondition,
      flagWhatsappNotification,
      referralCode,
    })
      .then((response) => {
        setTimeout(() => {
          onSignUpCloseModal();
        }, 1000);
        toast.success(response);
        const loginData = {
          username: email,
          password: password,
        };
        login(loginData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="SignUpBeforePay">
      <div>
        <ToastMessage></ToastMessage>
        <div>
          <div>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <Row>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">First Name</div>

                    <div className="overlap-group-3">
                      <IconUser
                        className="icon-user-instance"
                        iconUser="/img/icon-user.png"
                      />
                      <input
                        type="text"
                        onChange={onFirstNameChange}
                        value={firstName}
                        className="text-wrapper-7"
                        placeholder="First Name"
                      ></input>
                    </div>
                  </div>
                  {firstNameerrorMessage && (
                    <div className="error-message">{firstNameerrorMessage}</div>
                  )}
                </Col>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">Last Name</div>

                    <div className="overlap-group-3">
                      <IconUser
                        className="icon-user-instance"
                        iconUser="/img/icon-user.png"
                      />
                      <input
                        type="text"
                        onChange={onLastNameChange}
                        value={lastName}
                        className="text-wrapper-7"
                        placeholder="Last Name"
                      ></input>
                    </div>
                  </div>
                  {lastNameerrorMessage && (
                    <div className="error-message">{lastNameerrorMessage}</div>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <Row>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">Phone Number</div>
                    <div className="overlap-group-2">
                      <img
                        className="icon-feather-phone"
                        alt="Icon feather phone"
                        src={IndiaFlag}
                      />
                      <input
                        type="tel"
                        onChange={onPhoneNumberChange}
                        value={phoneNumber}
                        className="text-wrapper-7"
                        placeholder="Phone Number"
                        maxLength="10"
                      ></input>
                    </div>
                  </div>
                  {phoneNumbererrorMessage && (
                    <div className="error-message">
                      {phoneNumbererrorMessage}
                    </div>
                  )}
                </Col>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">Email</div>
                    <div className="overlap-group-2">
                      <IconMail iconMail="/img/icon-mail.png" />
                      <input
                        type="email"
                        onChange={onEmailChange}
                        value={email}
                        className="text-wrapper-7"
                        placeholder="Email"
                      ></input>
                    </div>
                  </div>
                  {emailerrorMessage && (
                    <div className="error-message">{emailerrorMessage}</div>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <Row>
                <Col>
                  <div className="text-wrapper-5">Password</div>
                  <div>
                    <div className="overlap-group-2">
                      <IconSolidPassword iconSolidPassword="/img/icon-solid-password.png" />
                      <input
                        type={showPassword ? "text" : "password"}
                        onChange={onPasswordChange}
                        value={password}
                        className="text-wrapper-7"
                        placeholder="Password"
                      ></input>
                      {showPassword == false ? (
                        <VisibilityOffIcon onClick={togglePasswordVisibility} />
                      ) : (
                        <RemoveRedEyeIcon onClick={togglePasswordVisibility} />
                      )}
                    </div>
                  </div>
                  {passworderrorMessage && (
                    <div className="error-message">{passworderrorMessage}</div>
                  )}
                </Col>
                <Col>
                  <div className="text-wrapper-5">Referral Code</div>
                  <div>
                    <div className="overlap-group-2">
                      <IconSolidPassword iconSolidPassword="/img/coupon-code.png" />
                      <input
                        type="text"
                        onChange={onReferralCodeChange}
                        value={referralCode}
                        className="text-wrapper-7"
                        placeholder="Referral Code"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="group-9">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={flagTermsAndCondition}
                  onChange={TermsandPolicyhandleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  className="check"
                />
                <div className="i-agree-to-the-terms">
                  <span className="text-wrapper-9">I agree to the </span>
                  <a href="https://livingscopehealth.com/terms/" target="blank">
                    <span className="text-wrapper-10">
                      Terms &amp; Conditions
                    </span>
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://livingscopehealth.com/privacy/"
                    target="blank"
                  >
                    <span className="text-wrapper-10">Privacy Policy</span>
                  </a>
                </div>
              </div>
              {flagTermsAndConditionerrorMessage && (
                <div className="error-message">
                  {flagTermsAndConditionerrorMessage}
                </div>
              )}
            </div>
            <div className="group-9">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={yearschecked}
                  onChange={yearscheckedChange}
                  inputProps={{ "aria-label": "controlled" }}
                  className="check"
                />
                <p className="p">I am 18 years old or more.</p>
              </div>
              {ageerrorMessage && (
                <div className="error-message">{ageerrorMessage}</div>
              )}
            </div>
            <div className="group-9">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={flagWhatsappNotification}
                  onChange={WhatsAppcheckedChange}
                  inputProps={{ "aria-label": "controlled" }}
                  className="check"
                />
                <p className="p">Get your health notifications on WhatsApp.</p>
              </div>
            </div>
            <div className="group">
              <button
                className={`${
                  firstName === "" ? "signUpButtondisabled" : "signUpButton"
                }`}
                disabled={firstName === ""}
                onClick={SignUp}
              >
                Submit
              </button>
            </div>
            <div className="group-10">
              <span>Already have a Account?</span>
              <Link to="/login-email">
                <span className="login">Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
