import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import DoctorPhoto from "./Img/DoctorPhoto.jpg";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import "./TestResult.css";
import Spinner from "../../shared/Spinner";
import DoctorCard from "./DoctorCard";
const cellStyle = {
  fontWeight: "bolder",
  border: "1px solid lightgrey",
  fontSize: "20px",
};
const TestResults = ({ selectedRowData }) => {
  const [modal, setModal] = useState(false);
  const [preventiveTest, setPreventiveTest] = useState([]);
  const [hasOutOfRangeValue, setHasOutOfRangeValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [doctorData, setDoctorData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const handleDoctorSelect = (doctor) => {
    setSelectedDoctor(doctor); // Set the selected doctor data
    setOpen(true);
    setModal(!modal);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const toggle = () => setModal(!modal);

  useEffect(() => {
    setIsLoading(true);
    const filePath = selectedRowData ? selectedRowData.rowData.filePath : "";
    AxiosInstance.get(
      `/preventive-test/getPreventiveTestResult?filePath=${filePath}`
    )
      .then((response) => {
        console.log("preventive result", response);
        AxiosInstance.get(
          `preventive-test/getPreventiveTestResult?filePath=${filePath}`
        )
          .then((response) => {
            console.log("preventive result", response);
            setPreventiveTest(response);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [selectedRowData]);

  const parseDesirableValue = (desirableValue) => {
    let minValue = null;
    let maxValue = null;

    const cleanedValue = desirableValue.replace(/,/g, "");

    if (cleanedValue.includes(">")) {
      minValue = parseFloat(cleanedValue.replace(">", "").trim());
    } else if (cleanedValue.includes("<")) {
      maxValue = parseFloat(cleanedValue.replace("<", "").trim());
    } else if (cleanedValue.includes("-")) {
      [minValue, maxValue] = cleanedValue
        .split("-")
        .map((val) => parseFloat(val.trim()));
    } else if (cleanedValue.toLowerCase().includes("to")) {
      [minValue, maxValue] = cleanedValue
        .toLowerCase()
        .split("to")
        .map((val) => parseFloat(val.trim()));
    }
    return { minValue, maxValue };
  };

  const isValueOutOfRange = (value, { minValue, maxValue }) => {
    if (minValue !== null && maxValue === null) {
      return value < minValue;
    }
    if (maxValue !== null && minValue === null) {
      return value > maxValue;
    }
    if (minValue !== null && maxValue !== null) {
      return value < minValue || value > maxValue;
    }
    return false; // default case
  };
  useEffect(() => {
    const hasOutOfRange = preventiveTest.some((test) => {
      const { minValue, maxValue } = parseDesirableValue(test.desirableValue);
      const value = parseFloat(test.value);
      return isValueOutOfRange(value, { minValue, maxValue });
    });
    setHasOutOfRangeValue(hasOutOfRange);
  }, [preventiveTest]);

  useEffect(() => {
    AxiosInstance.get("doctor/getAllDoctorsDetail")
      .then((response) => {
        if (response) {
          setDoctorData(response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const bookConsultationDetail = () => {
    setModal(!modal);
  };
  console.log("testResultRowData", selectedRowData, selectedDoctor);

  const surcharge = 0;
  const taxRate = 0.18;
  const tax = selectedDoctor && parseInt(selectedDoctor.amount * taxRate);
  const total =
    selectedDoctor && parseInt(selectedDoctor.amount + surcharge + tax);
  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };
  const handlePayment = () => {
    const amount = parseInt(total);
    const doctorId = selectedDoctor.userId;

    AxiosInstance.post(
      `payment/create-order/${amount}/${doctorId}?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}&preventiveReportId=${selectedRowData.rowData.id.toString()} `
    )
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setOpen(!open);
  };

  const groupedTests = preventiveTest.reduce((acc, test) => {
    const key = test.whyTheseTestAreDone;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(test);
    return acc;
  }, {});

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          {preventiveTest.length > 0 ? (
            <>
              <TableContainer
                style={{ marginTop: "2%", border: "1px solid black" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: " rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Test Name</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: " rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Test Value</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: " rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Unit</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Interpretation</b>
                      </p>
                    </TableCell>

                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Desirable Value</b>
                      </p>
                    </TableCell>

                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Why These Tests Are Done ?</b>
                      </p>
                    </TableCell>
                  </TableRow>
                  {Object.keys(groupedTests).map((key, index) => {
                    const tests = groupedTests[key];

                    return (
                      <React.Fragment key={index}>
                        <TableBody>
                          {tests.map((test, testIndex) => {
                            const { minValue, maxValue } = parseDesirableValue(
                              test.desirableValue
                            );
                            const value = parseFloat(test.value);
                            const isOutOfRange = isValueOutOfRange(value, {
                              minValue,
                              maxValue,
                            });
                            return (
                              <TableRow key={testIndex}>
                                <TableCell
                                  style={{
                                    fontWeight: "bolder",
                                    border: "1px solid lightgrey",
                                    fontSize: "20px",
                                  }}
                                >
                                  {test.testName}
                                </TableCell>
                                <TableCell
                                  style={{
                                    ...cellStyle,
                                    color: isOutOfRange ? "red" : "black",
                                  }}
                                >
                                  {test.value}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bolder",
                                    border: "1px solid lightgrey",
                                    fontSize: "20px",
                                  }}
                                >
                                  {test.unit}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bolder",
                                    border: "1px solid lightgrey",
                                    fontSize: "20px",
                                  }}
                                >
                                  {test.interpretation}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bolder",
                                    border: "1px solid lightgrey",
                                    fontSize: "20px",
                                  }}
                                >
                                  {test.desirableValue}
                                </TableCell>
                                {testIndex === 0 && (
                                  <TableCell
                                    rowSpan={tests.length}
                                    align="center"
                                    style={{
                                      fontWeight: "bolder",
                                      border: "1px solid lightgrey",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {key}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </React.Fragment>
                    );
                  })}
                </Table>
              </TableContainer>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <Row>
                  <Col lg={2}>
                    <h5
                      style={{
                        color: hasOutOfRangeValue ? "orangered" : "green",
                      }}
                    >
                      Final Conclusion:
                    </h5>
                  </Col>
                  <Col lg={10}>
                    {hasOutOfRangeValue
                      ? "You have one or more values that are not normal as shown in red font. We recommend that you consult a doctor for an informed decision."
                      : "All the test results are Normal. You do not require doctor consultation."}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div>Test Result Not Available</div>
          )}

          {hasOutOfRangeValue && (
            <>
              <center>
                <div>
                  <b>
                    Book for an online test report review with a doctor
                    in our panel
                  </b>
                </div>
                <Button
                  onClick={bookConsultationDetail}
                  style={{
                    marginTop: "1%",
                    width: "15%",
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                  }}
                >
                  Book Here
                </Button>
              </center>

              <div style={{ margin: "10px 0px 10px 0px" }}>
                <b>Why Online Test Report Review ?</b>
                <div>
                  <ul
                    style={{
                      listStyle: "square",
                      marginTop: "5px",
                      paddingLeft: "1rem",
                    }}
                  >
                    <li>
                      The online test review is designed to save you money as
                      well as the hasle of again going to see your doctor at his
                      or her clinic or hospital for test report review.
                    </li>
                    <li>
                      It will be much faster and more convenient to get the
                      reports reviewed online by a doctor in our panel.
                    </li>
                    <li>
                      We estimate that you will end up saving anywhere from Rs
                      500-2000 if you add up total cost of travel, your personal
                      time, etc.
                    </li>
                    <li>
                      You will get valuable reviews and advice from a qualified
                      and experienced doctor in our panel.
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <b>How online test review will work ?</b>
                <div>
                  Once you book for an online test review with a Doctor in our
                  Panel and pay for the charges, a notification will be sent to
                  the doctor from the Uncurl:health application. The doctor will
                  login to the app and review your test report in our app and
                  write comments and diagnosis.You will then receive a
                  notification from the app prompting you to login and review
                  doctor's comments, diagnosis and next recommended steps
                  including any medicines or additional tests that may be
                  requied. You will also be able to write your comments for the
                  doctor. If you still have any futher questions regarding the
                  test results or diagnosis or treatment suggested by doctor,
                  you will be able to send your query through the app and get
                  clarification from the doctor in no time. All this will be
                  stored in the app for your future reference.
                </div>
              </div>

              <div>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  centered
                  style={{
                    maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
                    minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
                    width: "auto", // Automatically adjusts the width based on content
                  }}
                >
                  <ModalHeader
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      border: "1px solid lightgrey",
                    }}
                    toggle={toggle}
                  >
                    <center>Select Doctor to Book</center>
                  </ModalHeader>
                  <ModalBody
                    style={{
                      padding: "20px",
                      maxHeight: "80vh", // Adjust the height as needed
                      overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
                    }}
                  >
                    {/* <div>
                      <div>
                        <b>Professional Title:</b> Consultant Physician,
                        Diabetologist, and Metabolic Disease Specialist
                      </div>
                      <div>
                        <b>Summary:</b> Highly experienced and dedicated
                        Consultant Physician with 7 years of experience in
                        managing complex medical cases, specializing in Diabetes
                        and Metabolic Diseases. Proven track record of
                        delivering personalized care and effective treatment
                        plans. Director of Metroplus Pharma and Clinic,
                        Punawale, Pune.
                      </div>
                      <div>
                        <b>Education:</b>
                        <ul style={{ listStyle: "circle" }}>
                          <li>MBBS</li>
                          <li>
                            FCPS (Fellowship of the College of Physicians and
                            Surgeons)
                          </li>
                          <li>FID (Fellowship in Diabetes)</li>
                        </ul>
                      </div>
                      <div>
                        <b>Work Experience:</b>
                        <ul style={{ listStyle: "circle" }}>
                          <li>
                            Consultant Physician, Metroplus Pharma and Clinic,
                            Punawale, Pune (7 years)
                          </li>
                          <li>
                            Managed a wide range of medical cases, with a focus
                            on Diabetes and Metabolic Diseases.
                          </li>
                          <li>
                            Developed and implemented effective treatment plans,
                            resulting in improved patient outcomes.
                          </li>
                          <li>
                            Built and maintained strong relationships with
                            patients, families, and healthcare professionals
                          </li>
                        </ul>
                      </div>
                      <div style={{ margin: "10px 0px 10px 0px" }}>
                        <h5>Online Test Review Charges : Rs 300</h5>
                      </div>

                      <div>
                        <center>
                          <Button
                            // onClick={() =>
                            //   handleSubmit(order.purchaseOrderId, order.typeOfClient)
                            // }
                            onClick={handleOpen}
                            sx={{
                              marginTop: "2%",
                              background: "#5353e9",
                              color: "#fff",
                              padding: "10px",
                              "&:hover": { background: "#c60176" },
                            }}
                          >
                            Pay now
                          </Button>
                        </center>
                      </div>
                    </div> */}
                    <DoctorCard
                      doctorData={doctorData}
                      onDoctorSelect={handleDoctorSelect}
                    ></DoctorCard>
                    {/* <OrderDetailModel /> */}
                  </ModalBody>
                </Modal>
              </div>
              <Modal
                isOpen={open}
                toggle={handleClose}
                centered
                modalClassName="custom-modal"
              >
                <ModalHeader
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid lightgrey",
                  }}
                  toggle={handleClose}
                >
                  <Typography variant="h6" component="h2">
                    Complete your Payment
                  </Typography>
                </ModalHeader>
                <ModalBody className="modal-body-centered">
                  <Typography
                    variant="h5"
                    sx={{ color: "purple" }}
                    component="h5"
                  >
                    Uncurl: Health Services
                  </Typography>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="h6">Order Value:</Typography>
                      <Typography variant="body1">Surcharge:</Typography>
                      <Typography variant="body1">Tax (18%):</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="h6">
                        ₹{selectedDoctor && selectedDoctor.amount}
                      </Typography>
                      <Typography variant="body1">₹{surcharge}</Typography>
                      <Typography variant="body1">
                        ₹{tax && tax.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Line above the Total */}
                  <Box
                    sx={{
                      borderTop: "2px solid lightgrey",
                      mt: 2,
                      mb: 2,
                      width: "100%",
                    }}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        ₹{total && total.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Line below the Total */}
                  <Box
                    sx={{
                      borderTop: "2px solid lightgrey",
                      mt: 2,
                      mb: 2,
                      width: "100%",
                    }}
                  />
                  <Typography variant="body1" sx={{ mb: 3 }}>
                    This amount will be charged to your account.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#5353e9",
                      "&:hover": { backgroundColor: "#c60176" },
                    }}
                    onClick={handlePayment} // Add your payment handling function here
                  >
                    Confirm & Pay
                  </Button>
                </ModalBody>
              </Modal>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TestResults;
