import PropTypes from "prop-types";
import React from "react";
import { IconOverview } from "../IconOverview";
import "./NavsHealthReportCard.css";
import { NavLink } from "react-router-dom";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useState, useEffect } from "react";
import { IconSupport } from "../IconSupport/IconSupport";

export const NavsHealthReportCard = ({
  property1,
  style,
  frameStyle,
  overviewStyle,
}) => {
  const [familyInfo, setFamilyInfo] = useState(null);
    useEffect(() => {
      AxiosInstance.get("profile/get")
        .then((response) => {
          setFamilyInfo(response?.profile);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);
  return (
    <>
      {(familyInfo?.age >= 3|| familyInfo == undefined || familyInfo?.age == null) && (
    <div className={`navs-healthReportCard ${property1}`} style={style}>
      <div className="menu-item">
        <div className="frame" style={frameStyle}>
          <div className="frame-wrapper">
            <div className="div">
              {property1 === "default" && <IconSupport></IconSupport>}

              {property1 === "variant-2" && <IconSupport></IconSupport>}

              <div className="component-8">
                <div className="menu-item-8">
                  <div className="frame-49">
                    <div className="frame-50">
                      <div className="frame-51">
                        <NavLink
                          to="/health-report-card"
                          className="text-wrapper-106 menu-item overview"
                        >
                          Health Report Card
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      )}
    </>
  );
};

NavsHealthReportCard.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconFilledOverviewIconFilledOverview: PropTypes.string,
};
