import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PhysiotherapistPerformanceTable from "./PhysiotherapistPerformanceTable";
import PhysiotherapistMonthlyPerformance from "./PhysiotherapistMonthlyPerformance";

const PhysiotherapistPerformance = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={handleTabChange}
        sx={{ backgroundColor: "steelblue" }}
      >
        <Tab
          label="Performance"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 0 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
        <Tab
          label="Monthly Performance"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 1 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
      </Tabs>
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <PhysiotherapistPerformanceTable></PhysiotherapistPerformanceTable>
        </Box>
      )}
      {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <PhysiotherapistMonthlyPerformance></PhysiotherapistMonthlyPerformance>
        </Box>
      )}
    </>
  );
};
export default PhysiotherapistPerformance;
