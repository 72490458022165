import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
// import { vaccineReportsData } from "../../shared/dummyData/vaccineReportsData";
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import MuiDialog from "../../shared/MuiDialog";
import { vaccineNames } from "../../shared/dummyData/vaccineNames";
import { differenceInDays, parse, isValid } from "date-fns";
import Swal from "sweetalert2";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const VaccineReports = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isReport, setIsReport] = React.useState(false);
  const [todayDate] = React.useState(new Date().toISOString().split("T")[0]);
  const [birthDate, setbirthDate] = useState();
  const [userAddedVaccineData, setUserAddedVaccineData] = useState([]);
  const [combinedVaccineData, setcombinedVaccineData] = useState([]);

  const [formData, setFormData] = useState({
    vaccineName: "",
    preferredDate: "",
  });

  const handleUploadReport = () => {
    setIsOpen(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file ? file.name : null);
  };

  const handleInputChange = (event) => {
    console.log("🚀 ~ event:", event);
    // const { name, value, type } = event.target;
    // if (type === "file") {
    //   setSelectedFile(
    //     event.target.files[0] ? event.target.files[0].name : null
    //   );
    // } else if (name === "upload") {
    //   setIsReport(value === "yes");
    // } else {
    //   // Update value using react-hook-form's setValue
    //   setFormData(name, value);
    // }
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleRadioChange = (event) => {
  //   setIsReport(event.target.value === "yes");
  // };

  const onSubmit = () => {
    console.log("formData:", formData);

    const newVaccineReport = {
      id: formData.vaccineName.toLowerCase().replace(/\s/g, "-"), // Generate a simple ID
      entries: [{ vaccine: formData.vaccineName }],
      preferredDate: [formData.preferredDate], // Use birthDate logic or some other default value
      maxDate: "-", // Adjust this as needed
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: "Incomplete",
      fileName: null,
    };

    // Update the vaccine reports data
    setUserAddedVaccineData((prevData) => [...prevData, newVaccineReport]);

    // Reset form data
    setFormData({
      vaccineName: "",
      plannedDate: "",
    });
  };

  const columnNames = [
    // { id: "age", label: "Age of Vaccination" },
    { id: "vaccine", label: "Vaccine Name" },
    { id: "preferred_date", label: "Preferred Date" },
    { id: "max_date", label: "Catch Up (Max) Date" },
    { id: "to_be_given_date", label: "Planned Date" },
    { id: "given_date", label: "Actual Date" },
    { id: "hospital_name", label: "Hospital Name" },
    { id: "doctor_name", label: "Doctor Name" },
    { id: "attach_report", label: "Attach Report" },
    { id: "status", label: "Status" },
  ];

  // const handleVaccineChange = (event) => {
  //   setVaccineName(event.target.value);
  // };

  const handleBirthDateChange = (event) => {
    setbirthDate(event.target.value);
  };

  console.log("birthDate", birthDate);

  const formattedDate = (date) => {
    const newDate = new Date(date);

    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = newDate.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const addYears = (date, years) => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + years);

    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = newDate.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const addDuration = (date, years, months, weeks) => {
    const [year, month, day] = date.split("-");
    const newDate = new Date(`${year}-${month}-${day}`);
    newDate.setFullYear(newDate.getFullYear() + years);
    newDate.setMonth(newDate.getMonth() + months);
    newDate.setDate(newDate.getDate() + weeks * 7);

    const formattedDay = String(newDate.getDate()).padStart(2, "0");
    const formattedMonth = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const formattedYear = newDate.getFullYear();

    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  };

  const showPopup = (message) => {
    // Your popup logic, for example:
    // alert(message);
    Swal.fire({
      text: message,
      icon: "success",
    });
  };

  useEffect(() => {
    const initialVaccineReportsData = [
      {
        // age: "Birth",
        id: "bcg",
        entries: [
          { vaccine: "BCG" },
          // { vaccine: "OPV-0" },
          // { vaccine: "Hep B-1 (Birth Dose)" },
        ],
        preferredDate: [birthDate && formattedDate(birthDate)],
        maxDate: [birthDate && addYears(birthDate, 6)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
        fileName: null,
      },
      {
        id: "hb-1",
        entries: [{ vaccine: "Hepatitis-B (HB 1)" }],
        preferredDate: [birthDate && formattedDate(birthDate)],
        maxDate: [birthDate && addDuration(birthDate, 17, 9, 2)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hb-2",
        entries: [{ vaccine: "Hepatitis-B (HB 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 6)],
        maxDate: [birthDate && addDuration(birthDate, 17, 10, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hb-3",
        entries: [{ vaccine: "Hepatitis-B (HB 3)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 10)],
        maxDate: [birthDate && addDuration(birthDate, 17, 11, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hb-4",
        entries: [{ vaccine: "Hepatitis-B (HB 4)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 14)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "polio-opv",
        entries: [{ vaccine: "Polio (OPV)" }],
        preferredDate: [birthDate && formattedDate(birthDate)],
        maxDate: [birthDate && addDuration(birthDate, 0, 11, 2)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "polio-ipv1",
        entries: [{ vaccine: "Polio (IPV 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 6)],
        maxDate: [birthDate && addDuration(birthDate, 1, 1, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "polio-ipv2",
        entries: [{ vaccine: "Polio (IPV 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 10)],
        maxDate: [birthDate && addDuration(birthDate, 1, 2, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "polio-ipv3",
        entries: [{ vaccine: "Polio (IPV 3)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 14)],
        maxDate: [birthDate && addDuration(birthDate, 1, 3, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "polio-ipvB1",
        entries: [{ vaccine: "Polio (IPV B1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 4, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "dpt-1",
        entries: [{ vaccine: "DTwP/DTaP (DPT 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 6)],
        maxDate: [birthDate && addDuration(birthDate, 1, 1, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "dpt-2",
        entries: [{ vaccine: "DTwP/DTaP (DPT 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 10)],
        maxDate: [birthDate && addDuration(birthDate, 1, 2, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "dpt-3",
        entries: [{ vaccine: "DTwP/DTaP (DPT 3)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 14)],
        maxDate: [birthDate && addDuration(birthDate, 1, 3, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "dpt-b1",
        entries: [{ vaccine: "DTwP/DTaP (DPT B1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 4, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hib-1",
        entries: [{ vaccine: "Hib 1" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 6)],
        maxDate: [birthDate && addDuration(birthDate, 1, 0.1, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hib-2",
        entries: [{ vaccine: "Hib 2" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 10)],
        maxDate: [birthDate && addDuration(birthDate, 1, 2, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hib-3",
        entries: [{ vaccine: "Hib 3" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 14)],
        maxDate: [birthDate && addDuration(birthDate, 1, 3, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hib-b1",
        entries: [{ vaccine: "Hib B1" }],
        preferredDate: [birthDate && addDuration(birthDate, 4, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "pcv-1",
        entries: [{ vaccine: "PCV 1" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 6)],
        maxDate: [birthDate && addDuration(birthDate, 0, 7, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "pcv-2",
        entries: [{ vaccine: "PCV 2" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 10)],
        maxDate: [birthDate && addDuration(birthDate, 0, 8, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "pcv-3",
        entries: [{ vaccine: "PCV 3" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 14)],
        maxDate: [birthDate && addDuration(birthDate, 0, 9, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "pcv-b",
        entries: [{ vaccine: "PCV B" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 6, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "rv-1",
        entries: [{ vaccine: "Rotavirus (RV 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 6)],
        maxDate: [birthDate && addDuration(birthDate, 0, 7, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "rv-2",
        entries: [{ vaccine: "Rotavirus (RV 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 10)],
        maxDate: [birthDate && addDuration(birthDate, 0, 8, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "rv-3",
        entries: [{ vaccine: "Rotavirus (RV 3)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 14)],
        maxDate: [birthDate && addDuration(birthDate, 0, 9, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "Influenza-1",
        entries: [{ vaccine: "Influenza (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 6, 0)],
        maxDate: "-",
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "Influenza-2",
        entries: [{ vaccine: "Influenza (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 7, 0)],
        maxDate: "-",
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "AnnualInfluenza-1",
        entries: [{ vaccine: "Annual Influenza (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 6, 0)],
        maxDate: "-",
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "AnnualInfluenza-2",
        entries: [{ vaccine: "Annual Influenza (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 2, 6, 0)],
        maxDate: "-",
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "AnnualInfluenza-3",
        entries: [{ vaccine: "Annual Influenza (Dose 3)" }],
        preferredDate: [birthDate && addDuration(birthDate, 3, 6, 0)],
        maxDate: "-",
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "AnnualInfluenza-4",
        entries: [{ vaccine: "Annual Influenza (Dose 4)" }],
        preferredDate: [birthDate && addDuration(birthDate, 4, 6, 0)],
        maxDate: "-",
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "AnnualInfluenza-5",
        entries: [{ vaccine: "Annual Influenza (Dose 5)" }],
        preferredDate: [birthDate && addDuration(birthDate, 5, 6, 0)],
        maxDate: "-",
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "mmr-1",
        entries: [{ vaccine: "MMR (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 9, 0)],
        maxDate: [birthDate && addDuration(birthDate, 0, 14, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "mmr-2",
        entries: [{ vaccine: "MMR (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 15, 0)],
        maxDate: [birthDate && addDuration(birthDate, 0, 15, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "mmr-3",
        entries: [{ vaccine: "MMR (Dose 3)" }],
        preferredDate: [birthDate && addDuration(birthDate, 4, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "tcv",
        entries: [{ vaccine: "TCV" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 6, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "Hepatitis-A1",
        entries: [{ vaccine: "Hepatitis-A (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 1, 6, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "Hepatitis-A2",
        entries: [{ vaccine: "Hepatitis-A (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 6, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "Vericella-1",
        entries: [{ vaccine: "Vericella (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 3, 0)],
        maxDate: [birthDate && addDuration(birthDate, 1, 6, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "Vericella-2",
        entries: [{ vaccine: "Vericella (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 6, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "TDap",
        entries: [{ vaccine: "TDaP" }],
        preferredDate: [birthDate && addDuration(birthDate, 9, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "Td",
        entries: [{ vaccine: "Td" }],
        preferredDate: [birthDate && addDuration(birthDate, 15, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hpv-1",
        entries: [{ vaccine: "HPV (Dose-1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 9, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 13, 6, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },
      {
        id: "hpv-2",
        entries: [{ vaccine: "HPV (Dose-1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 9, 6, 0)],
        maxDate: [birthDate && addDuration(birthDate, 14, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        status: "Incomplete",
        fileName: null,
      },

      // {
      //   // age: "6 week",
      //   entries: [
      //     { vaccine: "DTWP/DTaP-1" },
      //     { vaccine: "IPV-1" },
      //     { vaccine: "Hib-1" },
      //     { vaccine: "Hep B-2" },
      //     { vaccine: "Rota-1" },
      //     { vaccine: "PCV-1" },
      //   ],
      //   preferredDate: ["", "", "", "", ""],
      //   maxDate: ["", "", "", ""],
      //   plannedDate: ["", "", "", ""],
      //   actualDate: ["", "", "", ""],
      //   hospitalName: ["", "", "", ""],
      //   doctorName: ["", "", "", ""],
      //   status: ["", "", "", ""],
      // },
      // {
      //   // age: "10 week",
      //   entries: [
      //     { vaccine: "DTWP/DTaP-2" },
      //     { vaccine: "IPV-2" },
      //     { vaccine: "Hib-2" },
      //     { vaccine: "Hep B-3" },
      //     { vaccine: "Rota-2" },
      //     { vaccine: "PCV-2" },
      //   ],
      //   preferredDate: ["", "", "", "", ""],
      //   maxDate: ["", "", "", ""],
      //   plannedDate: ["", "", "", ""],
      //   actualDate: ["", "", "", ""],
      //   hospitalName: ["", "", "", ""],
      //   doctorName: ["", "", "", ""],
      //   status: ["", "", "", ""],
      // },
      // {
      //   // age: "14 week",
      //   entries: [
      //     { vaccine: "DTWP/DTaP-3" },
      //     { vaccine: "IPV-3" },
      //     { vaccine: "Hib-3" },
      //     { vaccine: "Hep B-4" },
      //     { vaccine: "Rota-3" },
      //     { vaccine: "PCV-3" },
      //   ],
      //   preferredDate: ["", "", "", "", ""],
      //   maxDate: ["", "", "", ""],
      //   plannedDate: ["", "", "", ""],
      //   actualDate: ["", "", "", ""],
      //   hospitalName: ["", "", "", ""],
      //   doctorName: ["", "", "", ""],
      //   status: ["", "", "", ""],
      // },
      // {
      //   // age: "6 Month",
      //   entries: [{ vaccine: "Influenza(IIV)-1" }],
      //   preferredDate: [""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "7 Month",
      //   entries: [
      //     { vaccine: "Influenza(IIV)-2" },
      //     { vaccine: "1 dose every year till 5 year" },
      //     { vaccine: "Year 2" },
      //     { vaccine: "Year 3" },
      //     { vaccine: "Year 4" },
      //     { vaccine: "Year 5" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "6-9 month",
      //   entries: [{ vaccine: "TCV" }],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "9 month",
      //   entries: [
      //     { vaccine: "MMR-1" },
      //     { vaccine: "MCV-1" },
      //     { vaccine: "MCV-1" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "12 month",
      //   entries: [
      //     { vaccine: "Hep-1" },
      //     { vaccine: "MCV-2" },
      //     { vaccine: "JE-1" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "13 month",
      //   entries: [{ vaccine: "JE-2" }],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "15 month",
      //   entries: [
      //     { vaccine: "MMR-2" },
      //     { vaccine: "Vercella-1" },
      //     { vaccine: "PCV booster" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "16-18 month",
      //   entries: [
      //     { vaccine: "DTWP/DTaP-B1" },
      //     { vaccine: "Hib-B1" },
      //     { vaccine: "IPV-B1" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "18-19 month",
      //   entries: [
      //     { vaccine: "Hep A-2 (for killed vaccine only" },
      //     { vaccine: "Vericella-2" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "4-6 year",
      //   entries: [
      //     { vaccine: "DTWP/DTaP-B2" },
      //     { vaccine: "IPV-B2" },
      //     { vaccine: "MMR-3" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
      // {
      //   // age: "10-15 year",
      //   entries: [
      //     { vaccine: "Tdap" },
      //     { vaccine: "HPV-1" },
      //     { vaccine: "HPV-2" },
      //   ],
      //   preferredDate: ["", ""],
      //   maxDate: [""],
      //   plannedDate: [""],
      //   actualDate: [""],
      //   hospitalName: [""],
      //   doctorName: [""],
      //   status: [""],
      // },
    ];
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("-");
      return new Date(`${year}-${month}-${day}`).getTime();
    };

    // Filter out undefined dates and sort the data by preferredDate
    const sortedVaccineReportsData = initialVaccineReportsData
      .filter((report) => report.preferredDate[0])
      .sort(
        (a, b) => parseDate(a.preferredDate[0]) - parseDate(b.preferredDate[0])
      );

    const today = new Date();
    let upcomingVaccines = [];

    initialVaccineReportsData.forEach((report) => {
      report.preferredDate.forEach((date) => {
        if (date) {
          const [day, month, year] = date.split("-");
          const preferredDate = new Date(`${year}-${month}-${day}`);
          const daysDifference = differenceInDays(preferredDate, today);
          if (daysDifference >= 0 && daysDifference <= 7) {
            upcomingVaccines.push(
              report.entries.map((entry) => entry.vaccine).join(", ")
            );
          }
        }
      });
    });

    if (upcomingVaccines.length > 0) {
      showPopup(
        `This is a Vaccination Reminder from Uncurl health. Your child Parul is recommended for ${upcomingVaccines.join(
          ", "
        )} 
            vaccine on June 10, 2024.Please ensure she receives the vaccine by the recommended date. It is important that your child receives the vaccine on time.
        `
      );
    }
    setcombinedVaccineData(
      birthDate
        ? [...sortedVaccineReportsData, ...userAddedVaccineData]
        : [...initialVaccineReportsData, ...userAddedVaccineData]
    );
  }, [birthDate, userAddedVaccineData]);

  // const combinedVaccineData = [...vaccineReportsData, ...userAddedVaccineData];

  const handleFileSubmit = (event, id) => {
    console.log("id", id);
    const file = event.target.files[0];
    const updatedData = combinedVaccineData.map((report) => {
      if (report.id === id) {
        return { ...report, status: "Completed", fileName: file.name };
      }
      return report;
    });
    setcombinedVaccineData(updatedData);
  };

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        if (response) {
          setbirthDate(response?.profile.dob);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log("combined", combinedVaccineData);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div>
            If you want to add new Vaccine then add this by clicking on it
          </div>
          <Button variant="outlined" onClick={handleUploadReport}>
            Add Vaccine
          </Button>
        </div>

        <div>
          Birth Date :
          <input
            type="date"
            value={birthDate}
            onChange={handleBirthDateChange}
          ></input>
        </div>
      </div>

      <MuiDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Add Vaccine"
        onSubmit={onSubmit}
      >
        <form className="mt-1">
          {/* <FormControl component="fieldset">
            <Typography variant="h6" gutterBottom>
              Do you have vaccination report?
            </Typography>
            <RadioGroup
              row
              aria-label="upload"
              name="upload"
              defaultValue="no"
              onChange={handleInputChange}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl> */}
          {!isReport && (
            <p>
              Input vaccine name and preferred date for vaccination as mentioned
              by your child's pediatrician.
            </p>
          )}
          {/* {isReport && (
            <div>
              <input
                type="file"
                {...register("file")}
                id="file"
                name="file"
                onChange={handleInputChange}
                style={{ display: "none" }}
              />
              <label htmlFor="file">
                <Button variant="contained" component="span">
                  Upload Report
                </Button>
              </label>
              {selectedFile && <p>Selected file: {selectedFile}</p>}
            </div>
          )} */}
          <>
            {/* <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="vaccineName">Vaccine Name</InputLabel>
                <Select
                  id="vaccineName"
                  label="Vaccine Name"
                  onChange={handleInputChange}
                  value={formData.vaccineName}
                  {...register("vaccineName")}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {vaccineNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <Select
                  id="vaccineName"
                  label="Vaccine Name"
                  value={formData.vaccineName}
                  onChange={handleInputChange}
                  {...register("vaccineName")}
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Vaccine
                  </MenuItem>
                  {vaccineNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            {/* <FormControl sx={{ m: 1, minWidth: 300 }} size="medium">
              <InputLabel id="vaccineName">Vaccine Name</InputLabel>
              <Select
                id="vaccineName"
                label="Vaccine Name"
                onChange={handleInputChange}
                value={formData.vaccineName}
                {...register("vaccineName")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {vaccineNames.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </>
          <TextField
            id="vaccineName"
            label="Vaccine Name"
            name="vaccineName"
            type="text"
            {...register("vaccineName")}
            value={formData.vaccineName}
            fullWidth
            onChange={handleInputChange}
            margin="normal"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: todayDate,
            }}
          />
          <TextField
            id="preferredDate"
            label="Preferred Date"
            name="preferredDate"
            type="date"
            {...register("preferredDate")}
            fullWidth
            value={formData.preferredDate}
            onChange={handleInputChange}
            margin="normal"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: todayDate,
            }}
          />
          {/* <Button type="submit" variant="contained" color="primary">
            Submit
          </Button> */}
          {/* {!!isReport && (
            <>
              <TextField
                id="actualDate"
                label="Actual Date"
                type="date"
                {...register("actualDate")}
                fullWidth
                onChange={handleInputChange}
                size="small"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!!isReport}
              />
              <TextField
                id="hospitalName"
                label="Hospital Name"
                {...register("hospitalName")}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="normal"
                disabled={!!isReport}
              />
              <TextField
                id="doctorName"
                label="Doctor Name"
                {...register("doctorName")}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="normal"
                disabled={!!isReport}
              />
            </>
          )} */}
        </form>
      </MuiDialog>

      <TableContainer
        sx={{ marginTop: "30px", border: "1px solid black" }}
        component={Paper}
        className="report-table"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {columnNames.map((column) => (
                <TableCell
                  component="th"
                  key={column.id}
                  align="center"
                  className="border"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedVaccineData.map((row, rowIndex) =>
              row.entries.map((entry, index) => {
                return (
                  <TableRow key={`${rowIndex}-${index}`}>
                    {row.age && index === 0 && (
                      <TableCell
                        scope="row"
                        align="left"
                        rowSpan={row.entries.length}
                        className="border-r border-l"
                      >
                        {row.age}
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.entries[index].vaccine}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.preferredDate[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.maxDate[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      <input type="date"></input>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.actualDate[index]}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.hospitalName[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.doctorName[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      <span>
                        {row.fileName ? (
                          row.fileName
                        ) : (
                          <label
                            htmlFor={row.id}
                            style={{
                              cursor: "pointer",
                              color: "rgb(83, 83, 233)",
                              textDecoration: "underline",
                              marginLeft: "-10px",
                            }}
                          >
                            Attach Report
                          </label>
                        )}
                      </span>
                      <div>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .pdf, .png"
                          id={row.id}
                          className="file-upload-input"
                          name={row.id}
                          onChange={(event) => handleFileSubmit(event, row.id)}
                          style={{ display: "none" }}
                          // onClick={(event) => handleFileChange(event)}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.status}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          // onClick={ReviewAndUnderstood}
          sx={{
            background: "#5353e9",
            color: "#fff",
            minWidth: "180px",
            "&:hover": { background: "#c60176" },
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default VaccineReports;
