import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Card } from "@mui/material";
import { heightWeightRangeObj } from "../../../util/Helper";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../../shared/ToastMessage";

const AmericanDiabetes = () => {
  const [heightUnit, setHeightUnit] = useState("ft");
  const [weightUnit, setWeightUnit] = useState(); 
  const [AdagetData, setAdagetData] = useState();
  const [formData, setFormData] = useState({
    gender: 0,
    age: 0,
    weight: 0,
    heightFeet: 0,
    heightInches: 0,
    heightCm: 0,
    heightUnit: 0,
    gestationalDiabetes: 0,
    familyDiabetes: 0,
    highBloodPressure: 0,
    physicalActivity: 0,
    totalScore: 0,
  });
  const [riskLevel, setRiskLevel] = useState({
    below5: "No risk for having type 2 pre-diabetes/diabetes.",
    Above5: "Increased risk for having type 2 pre-diabetes/diabetes."
  })

  function convertWeightToLbs(kg) {
    const conversionFactor = 2.20462;
    const lbs = kg * conversionFactor;
    return lbs.toFixed(2);
  }
  const convertCmToFeetAndInches = (cm) => {
    const totalInches = cm / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = Math.round(totalInches % 12);
    return { feet, inches };
  };

  function getPointsFromHeightAndWeight(height, weight) {
    const heightObject = heightWeightRangeObj.find((obj) => obj.height === height);
    if (!heightObject) {
      toast.error("Height not found in data.");
      return "";
    }

    const weightRanges = heightObject.weight;
    for (let i = 0; i < heightObject.weight.length; i++) {
      const range = weightRanges[i].range.split("-").map((str) => Number(str.trim()));
      if (weight >= range[0] && weight <= (range[1] || range[0])) {
        return weightRanges[i].points;
      } else {
        console.log("No points found for weight: ", weight);
      }
    }
    return 0;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const ADAFormData = {
      totalScore: formData.totalScore,
      riskLevel: formData.totalScore >= 5 ? riskLevel.Above5 : riskLevel.below5,
      flagDiabetesRiskAssessmentAda: true
    }
    AxiosInstance.post("riskAssessment/saveDiabetesRiskAssessmentResult", ADAFormData)
      .then((response) => {
        toast.success("Your Data Has been recorded Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let weightInLbs;
  useEffect(() => {
    AxiosInstance.get("riskAssessment/getDiabetesRiskAssessmentResult")
      .then((response) => {
        let ageCategory;
        const age = response.age;
        if (age < 40) {
          ageCategory = 0;
        } else if (age >= 40 && age < 50) {
          ageCategory = 1;
        } else if (age >= 50 && age < 60) {
          ageCategory = 2;
        } else {
          ageCategory = 3;
        }
        const [feet, inches] = response?.currentHeight.split(":").map((part) => parseInt(part, 10));
        setFormData({
          gender: response.gender ? (response?.gender == "female" ? 0 : 1) : 0,
          age: response.age,
          ageCheck: ageCategory ? ageCategory : 0,
          weightUnit: response.weightUnit ? (response.weightUnit == "Kg" ? "kgs" : "lbs") : 0,
          weight: response.weight || 0,
          heightFeet: feet || 0,
          heightInches: inches || 0,
          heightUnit:response.heightUnit,
          heightCm: response.currentHeight || 0,
          gestationalDiabetes: response.gestationalDiabetes ? (response.gestationalDiabetes ? 1 : 0) : 0,
          familyDiabetes: response.diabetesInBrotherFatherMotherOrSister ? (response.diabetesInBrotherFatherMotherOrSister ? 1 : 0) : 0,
          highBloodPressure: response.everDiagnosesWithHighBloodPressure ? (response.everDiagnosesWithHighBloodPressure ? 1 : 0) : 0,
          physicalActivity: response.thirtyMinutesOfPhysicalExercise ? (response.thirtyMinutesOfPhysicalExercise ? 1 : 0) : 0,
          totalScore: response.totalScore || 0,
        });
        const [feetStr, inchesStr] = response.currentHeight.split(":");
        let weightPoints;
         if (response.weightUnit === "Kg") {
            weightInLbs = convertWeightToLbs(response.weight);
           if (response.heightUnit == 'cm') {
             const { feet, inches } = convertCmToFeetAndInches(response.currentHeight);
             const heightInFeetAndInches = `${feet}'${inches}`;
             weightPoints = getPointsFromHeightAndWeight(heightInFeetAndInches, weightInLbs);
           } else {
              const feet = parseInt(feetStr);
              const inches = parseInt(inchesStr);
              const heightInFeetAndInches = `${feet}'${inches}`;
              weightPoints = getPointsFromHeightAndWeight(heightInFeetAndInches, weightInLbs);
           }
         } else {
             if (response.heightUnit == "cm") {
               const { feet, inches } = convertCmToFeetAndInches(response.currentHeight);
               const heightInFeetAndInches = `${feet}'${inches}`;
               weightPoints = getPointsFromHeightAndWeight(heightInFeetAndInches, response.weight);
             } else {
               const feet = parseInt(feetStr);
               const inches = parseInt(inchesStr);
               const heightInFeetAndInches = `${feet}'${inches}`;
               weightPoints = getPointsFromHeightAndWeight(heightInFeetAndInches, response.weight);
             }
         }
          let genderPoint = (response.gender == "female" ? 0 : 1)
          const formScore = genderPoint + ageCategory +
          (response.diabetesInBrotherFatherMotherOrSister == 1 ? 1 : 0) +
          (response.everDiagnosesWithHighBloodPressure == 1 ? 1 : 0) +
          (response.thirtyMinutesOfPhysicalExercise == 1 ? 0 : 1) +
          (response.gestationalDiabetes == 1 ? 1 : 0);
          const totalScore = weightPoints + formScore;
         setFormData((prevState) => ({ ...prevState, totalScore }));
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  },[])

  return (
    <div>
      <ToastMessage></ToastMessage>
      <h4 className="cardiac-title">Diabetes Risk Assessment (Based on American Diabetes Association)</h4>
      <form onSubmit={handleSubmit}>
        <Card className="diabetes-risk-wrapper justify-content-center" style={{ minHeight: "500px", justifyContent: "center" }}>
          <Row className="diabetes-risk-row justify-content-center" style={{ justifyContent: "center" }}>
            <Col lg={5}>
              <p className="field-label">What is your gender?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.gender == 0 ? "Female" : "Male"}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">How old are you?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.age}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">What is your current height ?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.heightUnit === "ft" ? `${formData.heightFeet} Feet ${formData.heightInches} Inches` : `${formData.heightCm} cm`}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">What is your current weight category?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.weightUnit === "kgs" ? `${formData.weight} Kg` : `${formData.weight} lbs`}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label"> If you are a women have you ever been diagnosed with gestational diabetes?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.gestationalDiabetes == 0 ? "No" : "Yes"}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">Do you have a mother, father, sister, or brother with diabetes?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.familyDiabetes == 0 ? "No" : "Yes"}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">Have you ever been diagnosed with high blood pressure?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.highBloodPressure == 0 ? "No" : "Yes"}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="mb-3 diabetes-risk-row">
            <Col lg={5}>
              <p className="field-label">Do you get at least 30 minutes of physical activity in a typical day for most (&lt;=5 days) of the week?</p>
            </Col>
            <Col lg={7}>
              <div className="fields-wrapper">
                <div className="field-box">
                  <FormControl>{formData.physicalActivity == 0 ? "No" : "Yes"}</FormControl>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row className="mb-3 diabetes-risk-row text-center">
            <Col lg={12}>
              {/* <Button variant="contained" type="submit">
                Save
              </Button> */}
          {/* </Col>
          </Row> */}
        </Card>
        <Row className="mt-2 diabetes-risk-row" style={{ padding: "15px", alignItems: "center" }}>
          {/* <div style={{display: "flex" , alignItems: "center"}}> */}
          <Col lg={5}>
            <p className="field-label" style={{ fontSize: "25px", textAlign:"center" }}>
              Total Score{" "}
            </p>
          </Col>
          <Col lg={7}>
            <input className="total-score" value={formData.totalScore} style={formData.totalScore >= 5 ? { backgroundColor: "#FAA63B" } : { backgroundColor: "#CADB2A" }}></input>
          </Col>

          {/* </div> */}
        </Row>

        <div className="diabetes-risk-wrapper" style={{ minHeight: "250px" }}>
          <Row className="diabetes-risk-row">
            <Col lg={12}>
              {formData.totalScore >= 5 ? (
                <div>
                  <div style={{ marginTop: "10px", marginBottom: "2px" }}>
                    <b>Risk Level</b>
                  </div>
                  <p className="field-label">{riskLevel.Above5}</p>
                  <div style={{ marginTop: "20px" }}>
                    <p className="field-label">
                      <b>Recommendation</b>
                    </p>
                  </div>
                  <p className="field-label">Please consult your doctor for further screening and validation using sugar tests.</p>
                  <p className="field-label">Don't forget to upload all your test reports</p>
                </div>
              ) : (
                <div>
                  <div style={{ marginTop: "10px", marginBottom: "2px" }}>
                    <b>Risk Level</b>
                  </div>
                  <p className="field-label">{riskLevel.below5}</p>
                  <div style={{ marginTop: "20px" }}>
                    <p className="field-label">
                      <b>Recommendation</b>
                    </p>
                  </div>
                  <p className="field-label">Please take proactive measures to improve your lifestyle habits.</p>
                  <p className="field-label">Don't forget to upload all your test reports</p>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default AmericanDiabetes;
