import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const ServicePincode = ({ selectedRow }) => {
  console.log("selected row", selectedRow);
  return (
    <>
      {!selectedRow && (
        <>
          <p style={{ marginTop: "1%", marginBottom: "2%" }}>
            <b>
              This screen will show you all Service pincodes after you have
              uploaded your excel sheet in Location Address tab .
            </b>
          </p>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            No lab Address selected.
          </Typography>
        </>
      )}
      {selectedRow && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {/* Lab Details Card */}
          <Card sx={{ backgroundColor: "#f5f5f5" }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                Lab Details
              </Typography>
              <Typography variant="body1">
                <strong>Address:</strong> {selectedRow.labAddress}
              </Typography>
              <Typography variant="body1">
                <strong>Phone Number:</strong> {selectedRow.labPhoneNumber}
              </Typography>
            </CardContent>
          </Card>

          {/* Service Area Pincode Table */}
          <TableContainer component={Paper}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                p: 2,
                backgroundColor: "#1976d2",
                color: "white",
              }}
            >
              Service Area Pincodes
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "large" }}>
                    Pincode
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRow.locationLabPinCode.serviceAreaPinCode.map(
                  (pincode, index) => (
                    <TableRow key={index}>
                      <TableCell>{pincode}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};
export default ServicePincode;
