import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  VaccinationDescribe,
  VaccinationsData,
} from "../../shared/dummyData/VaccinationsData";
import { vaccinationsColumns } from "../../shared/tableColumns/vaccinationsColumns";

const Vaccinations = () => {
  const vaccineNames = Object.keys(VaccinationsData);

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {vaccinationsColumns.map((column, index) => {
                return (
                  <TableCell
                    key={column.id}
                    className="whitespace-nowrap text-xs"
                    sx={{
                      padding: "5px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: index == 0 ? "left" : "center",
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {vaccineNames.map((vaccine) => {
              const rowData = VaccinationsData[vaccine];
              let colSpanCount = 0;

              return (
                <TableRow key={vaccine}>
                  <TableCell
                    className="whitespace-nowrap text-xs"
                    sx={{
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      padding: "5px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {rowData.label}
                  </TableCell>
                  {vaccinationsColumns.map((column) => {
                    if (column.id === "vaccine") return null;
                    const cellData = rowData[column.id];
                    if (cellData && cellData.colSpan) {
                      colSpanCount = cellData.colSpan - 1;
                      return (
                        <TableCell
                          key={`${vaccine}-${column.id}`}
                          sx={{
                            background: cellData.color || "",

                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "5px",
                            fontSize: "12px",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                          className="whitespace-nowrap"
                          colSpan={cellData.colSpan}
                        >
                          {cellData.label}
                        </TableCell>
                      );
                    } else if (colSpanCount > 0) {
                      colSpanCount--;
                      return null;
                    } else {
                      return (
                        <TableCell
                          key={`${vaccine}-${column.id}`}
                          sx={{
                            background: cellData ? cellData.color : "",

                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "5px",
                            fontSize: "12px",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                          className="whitespace-nowrap text-xs"
                        >
                          {cellData?.label || ""}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
            <hr />
            <TableRow>
              <TableCell
                sx={{
                  background: "#FFFF9B",
                  height: "60px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
                colSpan={5}
              >
                Recommended age
              </TableCell>
              <TableCell
                sx={{
                  background: "#C8E7A3",
                  height: "60px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
                colSpan={6}
              >
                Catch up age range
              </TableCell>
              <TableCell
                sx={{
                  background: "#9BC3E6",
                  height: "60px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
                colSpan={6}
              >
                Vaccination in special situations
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="bg-slate-100 rounded-3xl mt-5 p-8">
        <ul>
          {VaccinationDescribe.map((item) => {
            return (
              <li
                key={item.id}
                className="text-black list-decimal text-left mb-2"
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Vaccinations;
