import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import "../VerificationScreen/style.css";
import "./DoctorScreens.css";

export const DoctorVerficationScreen = () => {
  const navigate = useNavigate();
  const { number } = useParams();
  const [FirstDigit, setFirstDigit] = useState("");
  const [SecondDigit, setSecondDigit] = useState("");
  const [ThirdDigit, setThirdDigit] = useState("");
  const [FourthDigit, setFourthDigit] = useState("");
  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);
  const [timer, setTimer] = useState(30); // Initial timer value in seconds
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0 && !showResend) {
      // Start the countdown timer if not showing the "Resend" message
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      // Timer has expired, show the "Resend" message
      setShowResend(true);
      clearInterval(interval); // Clear the interval to stop the countdown
    }

    // Clean up the interval when the component is unmounted or when showResend becomes true
    return () => clearInterval(interval);
  }, [timer, showResend]);

  const FirstDigitChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setFirstDigit(result);
    if (result.length > 0) {
      secondInputRef.current.focus();
    }
  };

  const SecondDigitChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setSecondDigit(result);
    if (result.length > 0) {
      thirdInputRef.current.focus();
    }
  };

  const ThirdDigitChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setThirdDigit(result);
    if (result.length > 0) {
      fourthInputRef.current.focus();
    }
  };

  const FourthDigitChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setFourthDigit(result);
  };

  const Verfication = async () => {
    const otp = FirstDigit + SecondDigit + ThirdDigit + FourthDigit;
    const doctorType = "BRAND_PARTNER";
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}doctor-verify/validateOtp/${number}/${otp}/${doctorType}`
      )
      .then((response) => {
        // setCookies(response.data.data);
        // window.sessionStorage.setItem("token", response?.data?.data?.token);
        // window.sessionStorage.setItem("token", response.data.data.token);
        window.sessionStorage.setItem(
          "userDetails",
          JSON.stringify(response.data.data)
        );
        toast.success("OTP Verified Successfully");
        setTimeout(() => {
          navigate("/doctor-profile");
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleResendClick = () => {
    // Reset the timer to 30 seconds and start the countdown again
    setTimer(30);
    setShowResend(false);

    setFirstDigit("");
    setSecondDigit("");
    setThirdDigit("");
    setFourthDigit("");

    // Focus on the first input element
    firstInputRef.current.focus();
    const doctorType = "BRAND_PARTNER";
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}doctor-verify/sendOtp/${number}/${doctorType}`
      )
      .then((response) => {
        toast.success("OTP sent Successfully");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="verification-screen">
      <div className="doctorBackground">
        <ToastMessage></ToastMessage>
        <div className="group-18">
          <img
            className="health-buddy"
            alt="Health buddy"
            src="/img/AppLogoWithName.png"
          />
        </div>

        <div className="VerifyAccountContainer">
          <div className="overlap-9">
            <div className="text-wrapper-19"> Verify Account!</div>
            {/* <p className="text-wrapper-46">
             We have sent you a 4 digit code on  <span className="text-wrapper-56">+91 {number}</span>
          </p> */}

            <div className="overlap-26">
              <div className="group-42">
                <div className="element-digit-code-2">4-digit Code</div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="tel"
                    onChange={FirstDigitChange}
                    value={FirstDigit}
                    ref={firstInputRef}
                    className="group-43"
                    maxLength="1"
                  />
                  <input
                    type="tel"
                    onChange={SecondDigitChange}
                    value={SecondDigit}
                    ref={secondInputRef}
                    className="group-43"
                    maxLength="1"
                  />
                  <input
                    type="tel"
                    onChange={ThirdDigitChange}
                    value={ThirdDigit}
                    ref={thirdInputRef}
                    className="group-43"
                    maxLength="1"
                  />
                  <input
                    type="tel"
                    onChange={FourthDigitChange}
                    value={FourthDigit}
                    ref={fourthInputRef}
                    className="group-43"
                    maxLength="1"
                  />
                </div>
              </div>
            </div>

            <div>
              {showResend ? (
                <p className="don-t-have-an">
                  <span>Didn't receive code?</span>
                  <span className="resend" onClick={handleResendClick}>
                    Resend
                  </span>
                </p>
              ) : (
                <p className="don-t-have-an">Resend Code in {timer} sec</p>
              )}
            </div>

            <button
              className={`${
                FirstDigit === "" ? "group-16disabled" : "group-16"
              }`}
              disabled={FirstDigit === ""}
              onClick={Verfication}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
