import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardText, FormGroup, Label, Input } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  getLabOrderDetailByIdApi,
  getLabOrderDetailApi,
} from "../../constants/apiList";
import "./style.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";

const CorporateOrderDetailModel = ({ order }) => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [patientDetail, setPatientDetail] = useState(null);
  const [labDetail, setLabDetail] = useState(null);
  const [testDetail, setTestDetail] = useState(null);
  //   const getPaymentDetail = async () => {
  //     await AxiosInstance.get(`${getLabOrderDetailByIdApi}/${orderId}`)
  //       .then((response) => {
  //         if (response != null) {
  //           setOrderDetail(response);
  //           setPatientDetail(response.patientDetail);
  //           setLabDetail(response.labDetail);
  //           setTestDetail(response.testDetail);
  //         } else {
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err: ", err);
  //       });
  //   };

  //   useEffect(() => {
  //     getPaymentDetail();
  //   }, []);
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  console.log("specific order", order);
  return (
    <>
      <div className="PurchaseOrderHeading">Purchase Order</div>
      <Row>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Phone</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value={7506753773}
              type="number"
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Email</b>
            </Label>
            <Input disabled value="anjan@livingscopehealth.com" type="email" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>State</b>
            </Label>
            <Input disabled value="Maharastra" type="text" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>GSTIN</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="27AAFCL3118G1ZP"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Address</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="HD 090, Chromium, Milind Nagar, Powai-400076"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr style={{ border: "1px solid #000000" }}></hr>
      <Card className="LabOrderDetailCard">
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="todayDate">
                <b>Date:</b>
              </Label>
              <div>{order.date}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientType">
                <b>Type of Client:</b>
              </Label>
              <div>{order.typeOfClient}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientName">
                <b>Client Name:</b>
              </Label>
              <div>{order.clientName}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientEmail">
                <b>Client Email:</b>
              </Label>
              <div>{order.clientEmail}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientContactNo">
                <b>Client Contact No:</b>
              </Label>
              <div>{order.clientContact}</div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="testCollectionDate">
                <b>Test Collection Date:</b>
              </Label>
              <div>{order.testCollectionDate}</div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="testCollectionTime">
                <b>Test Collection Time:</b>
              </Label>
              <div>{order?.time ? formatTime(order.time) : ""}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="typeOfTest">
                <b>Type Of Test:</b>
              </Label>
              <div>{order.typeOfTest}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="medicalCondition">
                <b>Medical Condition:</b>
              </Label>
              <div>
                {order.medicalCondition
                  ? order.medicalCondition
                  : "Not Available"}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientAddress">
                <b>Client Address (Test collection site):</b>
              </Label>
              <div>{order.clientAddress}</div>
            </FormGroup>
          </Col>
        </Row>
      </Card>

      <TableContainer
        component={Paper}
        sx={{ border: "1px solid black", borderRadius: "5px" }}
        elevation={5}
        className="mt-3 mb-3"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Test Name
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                HSN/SAC
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Price Unit
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Taxable Amount
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                GST (18%)
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ borderRight: "1px solid black" }}>
                {order.testNames}
              </TableCell>
              <>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.hsnSac}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.quantity}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.priceUnit}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.taxableAmount}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.gst}
                </TableCell>
                <TableCell
                // rowSpan={testNames.length}
                >
                  {order.totalAmount}
                </TableCell>
              </>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Row>
        <b style={{ textDecoration: "underline" }}>Notes to Lab:</b>
        <ul className="Labnotes">
          <li>
            Please call our client promptly at the contact number provided above
            to coordinate the tests collection as per date and time mentioned
            above
          </li>
          <li>
            After tests are done and reports are available, please upload them
            on Uncurl: health app platform.
          </li>
          <li>
            If there are any questions regarding this order or the PO amount
            shown above does not match as per your quote, then please contact
            Living Scope Health at the number provided and resolve.
            <b>
              You are not supposed to discuss anything with our client regarding
              this purchase order or the amount stated in it
            </b>
          </li>
          <li>
            Invoice also to be attached on Uncurl: health app platform. Payment
            will be done though our payment portal within seven days after
            submission of invoice and all test reports on our Uncurl: health
            platform.
          </li>
        </ul>

        <div>
          <b>
            Note: This is an electronically generated order and so does not
            require any signature for approval
          </b>
        </div>
      </Row>
      {/* <Card
        style={{
          padding: "15px",
          border: "1px solid black",
          margin: "10px 0px 10px 0px",
        }}
        elevation={5}
      >
        {patientDetail && (
          <>
            <div style={{ textAlign: "center" }}>
              <h4>Lifestyle Health Risk Test Request Form</h4>
              <hr />
            </div>
            <Row style={{ marginTop: "3%" }}>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Living Scope Order Id : </b>
                  {orderDetail.orderId}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Date : </b>
                  {orderDetail.date}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>patient ID : UHID : </b>
                  {orderDetail.patientId + " : " + patientDetail.patientUpId}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Patient Age : </b>
                  {patientDetail.patientAge}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Patient Name : </b>
                  {patientDetail.patientName}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Patient Gender : </b>
                  {patientDetail.patientGender}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Patient Contact Number : </b>
                  {patientDetail.patientContactNumber}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Lab Name : </b>
                  {labDetail.labName}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Patient Address : </b>
                  {patientDetail.address.addressLine1 +
                    ", " +
                    patientDetail.address.city +
                    " ( " +
                    patientDetail.address.state +
                    ", " +
                    patientDetail.address.zipcode +
                    ", " +
                    patientDetail.address.country +
                    ")"}
                </CardText>
              </Col>
              <Col lg={6}>
                <CardText className="mt-1">
                  <b>Lab Contact Number : </b>
                  {labDetail.labPhoneNumber}
                </CardText>
              </Col>
            </Row>
          </>
        )}
        {labDetail && (
          <>
            <Row>
              <Col lg={12}>
                <CardText className="mt-3">
                  <b>Test Name : </b>
                  {orderDetail.testDetail
                    .map((testName) => testName.testName)
                    .join(", ")}
                </CardText>
                {orderDetail.testDetail.some(
                  (test) => test.testName === "Lipid Profile"
                ) && (
                  <div>
                    <CardText>
                      <br></br>
                      <b>Tests to be included (if any):</b> Tests should include
                      Total Cholesterol, LDL Cholesterol, HDL Cholesterol,
                      Triglyceride, Very Low-Density Lipoprotein, Non-HDL
                      Cholesterol.
                    </CardText>
                    <CardText>
                      <b>Preferred Date and Time:</b>
                    </CardText>
                  </div>
                )}
              </Col>
            </Row>
          </>
        )}
        {orderDetail && (
          <>
            <Row>
              <Col lg={12}>
                <CardText className="mt-3">
                  <b>Note to Lab : </b>
                </CardText>
                <div>
                  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>
                      Please call the patient promptly to set up home
                      collection.
                    </li>
                    <li>
                      After tests are done and reports are available, please
                      upload on Uncurl: health app platform mentioning Patient
                      ID.
                    </li>
                    <li>
                      If there are any questions or issues regarding this order,
                      call our customer support team at{" "}
                    </li>
                    <li>
                      Please send bill reflecting Living Scope Order ID as well
                      as Patient ID
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card> */}
    </>
  );
};

export default CorporateOrderDetailModel;
