import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Chip,
  TablePagination,
  TextField,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  backgroundColor: "#1976d2",
  color: "#fff",
  textTransform: "uppercase",
});

const StyledTableRow = styled(TableRow)(({ index }) => ({
  backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#fff",
}));

const FilterInput = styled(TextField)({
  marginTop: "10px",
  marginLeft: "auto",
  width: "300px",
  backgroundColor: "#e0f7fa",
  borderRadius: "4px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1976d2",
    },
    "&:hover fieldset": {
      borderColor: "#004ba0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#004ba0",
    },
  },
  "& input": {
    color: "#1976d2",
  },
});

const CorporateEmployeesList = () => {
  const [loading, setLoading] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [newEmployeesFile, setNewEmployeesFile] = useState(null);
  const [leftEmployeesFile, setLeftEmployeesFile] = useState(null);
  const [isNewSubmitting, setisNewSubmitting] = useState(false);
  const [isLeftSubmitting, setisLeftSubmitting] = useState(false);
  const [newEmployees, setnewEmployees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const rowsPerPage = 5;

  const statusColor = (status) =>
    status === "Active" ? "green" : status === "Left" ? "orangered" : "gray";

  const getEmplyoeesListDetail = async () => {
    await AxiosInstance.get("corporate/get-employees-list/")
      .then((response) => {
        if (response) {
          setEmployeesList(response);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error:", err);
        setLoading(false);
      });
  };

  const getNewEmployees = async () => {
    await AxiosInstance.get("corporate/get-newEmployee-invoice")
      .then((response) => {
        if (response) {
          setnewEmployees(response);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  // Helper function to get previous month and year
  const getPreviousMonthName = () => {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today.toLocaleString("default", { month: "long" });
  };

  // Check if the modal should open based on new employees and date
  useEffect(() => {
    const today = new Date();
    const isFirstOfMonth = today.getDate() === 1;

    if (newEmployees.length > 0 && isFirstOfMonth) {
      setIsModalOpen(true);
    }
  }, [newEmployees]);

  // Close modal handler
  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/corporate-subscription-plans", { state: { newEmployees } });
  };

  useEffect(() => {
    setLoading(true);
    getEmplyoeesListDetail();
    getNewEmployees();
  }, []);

  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0");
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
    setCurrentPage(0); // Reset to first page when filter changes
  };

  const handleNewEmployeesFileChange = (event) => {
    setNewEmployeesFile(event.target.files[0]);
    const file = event.target.files[0];
    setisNewSubmitting(true);
    const newEmployeesFiles = new FormData();
    newEmployeesFiles.append("file", file);
    AxiosInstance.post(
      "corporate/employee-list-import?empType=New",
      newEmployeesFiles
    )
      .then((response) => {
        toast.success("New Employees List uploaded successfully.");
        setisNewSubmitting(false);
        getEmplyoeesListDetail();
      })
      .catch((err) => {
        console.log("err: ", err);
        setisNewSubmitting(false);
      });
  };

  const handleLeftEmployeesFileChange = (event) => {
    setLeftEmployeesFile(event.target.files[0]);
    const file = event.target.files[0];
    setisLeftSubmitting(true);
    const LeftEmployeesFiles = new FormData();
    LeftEmployeesFiles.append("file", file);
    AxiosInstance.post(
      "corporate/employee-list-import?empType=Left",
      LeftEmployeesFiles
    )
      .then((response) => {
        toast.success("Left Employees List uploaded successfully.");
        setisLeftSubmitting(false);
        getEmplyoeesListDetail();
      })
      .catch((err) => {
        setisLeftSubmitting(false);
        console.log("err: ", err);
      });
  };

  const filteredEmployees = employeesList.filter(
    (employee) =>
      employee.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
      employee.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
      employee.employeeId.toString().includes(filterText) ||
      employee.status.toLowerCase().includes(filterText.toLowerCase())
  );

  const paginatedEmployees = filteredEmployees.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  console.log("new", newEmployees);

  return (
    <>
      {/* Modal for Payment Reminder */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            p: 4,
            boxShadow: 24,
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Payment Reminder
          </Typography>
          <Typography>
            Please pay for all new employees added in {getPreviousMonthName()}{" "}
            {new Date().getFullYear()}
          </Typography>
          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseModal}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
      {loading ? (
        <Spinner />
      ) : employeesList.length > 0 ? (
        <Box>
          <Typography variant="h6" gutterBottom>
            This page provides a current list of all employees of your
            organization for whom you have requested subscription for
            Uncurl:health app.
          </Typography>
          <Typography variant="body2" gutterBottom>
            1. You may at any time add new employees by simply uploading a
            revised list with new employee's name added to it.
          </Typography>
          <Typography variant="body2" gutterBottom>
            2. A month end invoice will be generated for payment for all new
            employees added to the initial list.
          </Typography>
          <Typography variant="body2" gutterBottom>
            3. New employees will be added for subscription on the fifth of each
            month only after subscription payment has been received and credited
            to each employee's account in Uncurl: health.
          </Typography>
          <Typography variant="body2" gutterBottom>
            4. Please note that once added to the plan, employees cannot be
            removed from the plan unless they leave the organization.
          </Typography>
          <Typography variant="body2" gutterBottom>
            5. Once an employee leaves the organization, their unused
            subscription plan amount will be added to your WALLET that you may
            use for payment purposes later.
          </Typography>
          <Typography variant="body2" color="purple" gutterBottom>
            6. List of new employees must contain - Employee ID (if any),
            Location City, First Name, Last Name, Middle Initial (if any),
            Mobile Number, Email, Subscription Plan Name.
          </Typography>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            gap={2}
          >
            <Box display="flex" gap={1}>
              {/* Upload List of New Employees */}
              <Box display="flex" flexDirection="column" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  sx={{ backgroundColor: "#4caf50", color: "white" }}
                >
                  {isNewSubmitting ? (
                    <CircularProgress size={24} color="inherit" /> // Spinner inside button
                  ) : (
                    "Upload List of New Employees"
                  )}

                  <input
                    type="file"
                    hidden
                    onChange={handleNewEmployeesFileChange}
                  />
                </Button>
                {newEmployeesFile && (
                  <Typography variant="caption" color="textSecondary">
                    Attached File: {newEmployeesFile.name}
                  </Typography>
                )}
              </Box>

              {/* Upload List of Employees Who Have Left */}
              <Box display="flex" flexDirection="column" alignItems="center">
                <Button
                  variant="contained"
                  color="secondary"
                  component="label"
                  sx={{ backgroundColor: "#f44336", color: "white" }}
                >
                  {isLeftSubmitting ? (
                    <CircularProgress size={24} color="inherit" /> // Spinner inside button
                  ) : (
                    "Upload List of Employees Who Have Left"
                  )}

                  <input
                    type="file"
                    hidden
                    onChange={handleLeftEmployeesFileChange}
                  />
                </Button>
                {leftEmployeesFile && (
                  <Typography variant="caption" color="textSecondary">
                    Attached File: {leftEmployeesFile.name}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FilterInput
                label="Filter Employees"
                variant="outlined"
                size="small"
                value={filterText}
                onChange={handleFilterChange}
                placeholder="Search by name, ID, or status"
              />
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            style={{
              marginTop: "16px",
            }}
          >
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date Added</StyledTableCell>
                  <StyledTableCell>Employee ID</StyledTableCell>
                  <StyledTableCell>Location Code/City</StyledTableCell>
                  <StyledTableCell>First Name</StyledTableCell>
                  <StyledTableCell>Last Name</StyledTableCell>
                  <StyledTableCell>Middle Initial (if any)</StyledTableCell>
                  <StyledTableCell>Mobile</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Plan Name</StyledTableCell>
                  <StyledTableCell>Date of Subscription</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedEmployees.length > 0 ? (
                  paginatedEmployees.map((row, index) => (
                    <StyledTableRow key={index} index={index}>
                      <TableCell>
                        {row.createdOn
                          ? formatDateToDDMMYYYY(row.createdOn)
                          : ""}
                      </TableCell>
                      <TableCell>{row.employeeId}</TableCell>
                      <TableCell>{row.locationCity}</TableCell>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.lastName}</TableCell>
                      <TableCell>{row.middleInitial}</TableCell>
                      <TableCell>{row.phoneNumber}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.planName}</TableCell>
                      <TableCell>
                        {row.dateOfSubscription
                          ? formatDateToDDMMYYYY(row.dateOfSubscription)
                          : ""}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={row.status}
                          style={{
                            backgroundColor: statusColor(row.status),
                            color: "white",
                          }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[7]}
            component="div"
            count={filteredEmployees.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
          />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "x-large",
            fontWeight: 500,
            color: "#c60176",
            margin: "50px",
          }}
        >
          Please Attach the Excel Sheet in General Information
        </div>
      )}
    </>
  );
};

export default CorporateEmployeesList;
