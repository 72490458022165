import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
} from "reactstrap";
import { event } from "react-ga";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { getAddressInfo } from "../../constants/apiList";
import { CircularProgress } from "@mui/material";
import Spinner from "../../shared/Spinner";

const LabAddress = ({ handleTabChange }) => {
  const [labAddress, setLabAddress] = useState();
  const [labPhoneNumber, setLabPhoneNumber] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [labPincode, setPinCode] = useState();
  const [weekDayFrom, setWeekDayFrom] = useState();
  const [weekEndFrom, setWeekEndFrom] = useState();
  const [weekDayTo, setWeekDayTo] = useState();
  const [weekEndTo, setWeekEndTo] = useState();
  const [weekCollectionTimeFrom, setWeekCollectionTimeFrom] = useState();
  const [weekCollectionTimeTo, setWeekCollectionTimeTo] = useState();
  const [weekEndCollectionTimeFrom, setWeekEndCollectionTimeFrom] = useState();
  const [weekEndCollectionTimeTo, setWeekEndCollectionTimeTo] = useState();
  const [newAddressRow, setNewAddressRow] = useState();
  const [locPincode, setLabPincode] = useState();
  const [currentAddressIndex, setCurrentAddressIndex] = useState(0);
  const [fileNames, setFileNames] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const [addressrow, setAddressRow] = useState([
    {
      id: null,
      labPinCode: "",
      labAddress: "",
      labPhoneNumber: "",
      labPinCode: "",
      workDayStartTime: "",
      workDayEndTime: "",
      workDayWeekendStartTime: "",
      workDayWeekendEndTime: "",
      locationLabPinCode: {
        locPincode: [""],
        weekCollectionTimeFrom: "",
        weekCollectionTimeTo: "",
        weekEndCollectionTimeFrom: "",
        weekEndCollectionTimeTo: "",
      },
    },
  ]);
  const addAddressRow = () => {
    setAddressRow([
      ...addressrow,
      {
        id: null,
        labPinCode: "",
        labAddress: "",
        labPhoneNumber: "",
        labPinCode: "",
        workDayStartTime: "",
        workDayEndTime: "",
        workDayWeekendStartTime: "",
        workDayWeekendEndTime: "",
        locationLabPinCode: {
          locPincode: [""],
          weekCollectionTimeFrom: "",
          weekCollectionTimeTo: "",
          weekEndCollectionTimeFrom: "",
          weekEndCollectionTimeTo: "",
        },
      },
    ]);
  };

  const [pincodeRow, setPincodeRow] = useState([]);
  const addPincode = (addressIndex) => {
    const newAddressRows = Array.from(addressrow);
    // Ensure locationLabPinCode is always treated as an array
    newAddressRows[addressIndex].locationLabPinCode =
      newAddressRows[addressIndex].locationLabPinCode || {};
    newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode =
      newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode || [];

    // Add a new empty string to serviceAreaPinCode array
    newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode = [
      ...newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode,
      "",
    ];
    setAddressRow(newAddressRows);

    const updatedPincodeRow = [...pincodeRow, { locPincode: "" }];
    setPincodeRow(updatedPincodeRow);
  };
  const newRows = [...addressrow];
  const handleAddressChange = (index, e) => {
    const newRows = [...addressrow];
    newRows[index][e.target.name] = e.target.value;
    setAddressRow(newRows);
  };
  const handleAddress1Change = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...addressrow];

    // Update the correct field within locationLabPinCode
    if (newRows[index]?.locationLabPinCode) {
      newRows[index].locationLabPinCode[name] = value;
    } else {
      // Initialize locationLabPinCode if it doesn't exist
      newRows[index].locationLabPinCode = { [name]: value };
    }

    setAddressRow(newRows);
  };

  const handlePincodeChange = (addressIndex, pincodeIndex, e) => {
    const newAddressRows = [...addressrow];
    if (
      newAddressRows[addressIndex]?.locationLabPinCode &&
      Array.isArray(
        newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode
      ) &&
      newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode
        .length > pincodeIndex
    ) {
      newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode[
        pincodeIndex
      ] = e.target.value;
      setAddressRow(newAddressRows);
    }
    const updateAddressRow = [...newAddressRows];
    setNewAddressRow(updateAddressRow);
    const newPincodeRows = [...pincodeRow];
    if (newPincodeRows.length > pincodeIndex) {
      newPincodeRows[pincodeIndex][e.target.name] = e.target.value;
      setPincodeRow(newPincodeRows);
    }
  };
  const handleLabAddress = (event) => {
    setLabAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labAddress: false,
    }));
  };
  const handlelabPhoneNumber = (event) => {
    setLabPhoneNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPhoneNumber: false,
    }));
  };
  const handlePinCode = (event) => {
    setPinCode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPincode: false,
    }));
  };
  const uploadFile = async (file, serviceAreaAddressId) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Notify the user if upload is successful
      AxiosInstance.post(
        `lab-partner/service-pinCode-import?serviceAreaAddressId=${serviceAreaAddressId}`,
        formData
      )
        .then((response) => {
          toast.success("File uploaded successfully!");
          setUploadingIndex(null);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("File upload failed. Please try again.");
    }
  };
  const handleFileChange = (index, e, serviceAreaAddressId) => {
    const file = e.target.files[0]; // Get the selected file
    const allowedExtensions = /(\.xlsx|\.xls|\.csv)$/i;
    if (file && allowedExtensions.test(file.name)) {
      // Clear previous errors
      setFileErrors((prev) => ({
        ...prev,
        [index]: null,
      }));

      // Store the file name
      setFileNames((prev) => ({
        ...prev,
        [index]: file.name,
      }));
      setUploadingIndex(index);

      // Send the file to the API
      uploadFile(file, serviceAreaAddressId);
    } else {
      // Show error message if the file is not an Excel file
      setFileErrors((prev) => ({
        ...prev,
        [index]: "Only Excel files (.xlsx, .xls, .csv) are allowed.",
      }));
      setFileNames((prev) => ({
        ...prev,
        [index]: null,
      }));
    }
    console.log("serviceAreaAddressId", serviceAreaAddressId);
  };
  const handleWeekDayFrom = (event) => {
    setWeekDayFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekDayFrom: false,
    }));
  };
  const handleWeekDayTo = (event) => {
    setWeekDayTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekDayTo: false,
    }));
  };
  const handleWeekEndFrom = (event) => {
    setWeekEndFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndFrom: false,
    }));
  };

  const handleWeekEndTo = (event) => {
    setWeekEndTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndTo: false,
    }));
  };
  const handleLocPinCode = (event) => {
    setLabPincode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      locPincode: false,
    }));
  };
  const handleWeekCollectionTimeFrom = (event) => {
    setWeekCollectionTimeFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekCollectionTimeFrom: false,
    }));
  };
  const handleWeekCollectionTimeTo = (event) => {
    setWeekCollectionTimeTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekCollectionTimeTo: false,
    }));
  };
  const handleWeekEndCollectionTimeFrom = (event) => {
    setWeekEndCollectionTimeFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndCollectionTimeFrom: false,
    }));
  };
  const handleWeekEndCollectionTimeTo = (event) => {
    setWeekEndCollectionTimeTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndCollectionTimeTo: false,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};

    addressrow.forEach((address) => {
      if (address.labAddress == undefined || address.labAddress == "") {
        error.labAddress = true;
      }
      if (address.labPhoneNumber == undefined || address.labPhoneNumber == "") {
        error.labPhoneNumber = true;
      }
      if (address.labPinCode == undefined || address.labPinCode == "") {
        error.labPinCode = true;
      }
      // if (address.weekDayFrom == undefined || address.weekDayFrom == "") {
      //   error.weekDayFrom = true;
      // }
      // if (address.weekDayTo == undefined || address.weekDayTo == "") {
      //   error.weekDayTo = true;
      // }
      // if (address.weekEndFrom == undefined || address.weekEndFrom == "") {
      //   error.weekEndFrom = true;
      // }
      // if (address.weekEndTo == undefined || address.weekEndTo == "") {
      //   error.weekEndTo = true;
      // }
      // if (address.locationLabPinCode.weekCollectionTimeFrom == undefined || address.locationLabPinCode.weekCollectionTimeFrom == "") {
      //   error.weekCollectionTimeFrom = true;
      // }
      // if (address.locationLabPinCode.weekCollectionTimeTo == undefined || address.locationLabPinCode.weekCollectionTimeTo == "") {
      //   error.weekCollectionTimeTo = true;
      // }
      // if (address.locationLabPinCode.weekEndCollectionTimeFrom == undefined || address.locationLabPinCode.weekEndCollectionTimeFrom == "") {
      //   error.weekEndCollectionTimeFrom = true;
      // }
      // if (address.locationLabPinCode.weekEndCollectionTimeTo == undefined || address.locationLabPinCode.weekEndCollectionTimeTo) {
      //   error.weekEndCollectionTimeTo = true;
      // }
    });
    // if (locPincode === undefined || locPincode == "") {
    //   error.locPincode = true;
    // }
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      setFormSubmitted(true);
      console.log("new Address Row", newAddressRow, newRows);
      const formData = newAddressRow ? newAddressRow : newRows;
      console.log("form Data", formData);
      const transformedData = formData?.map((item) => ({
        id: item?.id ? item?.id : null,
        labPinCode: item.labPinCode,
        labAddress: item.labAddress,
        labPhoneNumber: item.labPhoneNumber,
        workDayStartTime: item.workDayStartTime,
        workDayEndTime: item.workDayEndTime,
        workDayWeekendStartTime: item.workDayWeekendStartTime,
        workDayWeekendEndTime: item.workDayWeekendEndTime,
        locationLabPinCode: {
          serviceAreaPinCode: item.locationLabPinCode?.serviceAreaPinCode
            .filter((loc) => loc !== null && loc !== undefined && loc !== "")
            .map((loc) => loc),
          workDayStartTime: item.locationLabPinCode.weekCollectionTimeFrom
            ? item.locationLabPinCode.weekCollectionTimeFrom
            : "",
          workDayEndTime: item.locationLabPinCode.workDayEndTime
            ? item.locationLabPinCode.workDayEndTime
            : "",
          workDayWeekendStartTime: item.locationLabPinCode
            .workDayWeekendStartTime
            ? item.locationLabPinCode.workDayWeekendStartTime
            : "",
          workDayWeekendEndTime: item.locationLabPinCode.workDayWeekendEndTime
            ? item.locationLabPinCode.workDayWeekendEndTime
            : "",
        },
      }));
      console.log("tranformed data ", transformedData);
      AxiosInstance.post("lab-partner/lab-location-address", transformedData)
        .then((response) => {
          setLabAddress("");
          setLabPhoneNumber("");
          setPinCode("");
          setLabPincode("");
          setWeekDayFrom("");
          setWeekDayTo("");
          setWeekEndFrom("");
          setWeekEndTo("");
          setWeekCollectionTimeFrom("");
          setWeekCollectionTimeTo("");
          setWeekEndCollectionTimeFrom("");
          setWeekEndCollectionTimeTo("");
          toast.success("Information submitted successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const GetAddressInfo = async () => {
    await AxiosInstance.get(`${getAddressInfo}`)
      .then((response) => {
        // const lab = response.map((item, index) => item)
        if (response != null) {
          const updatedData = response.map((item) => ({
            ...item,
            locationLabPinCode: {
              ...item.locationLabPinCode,
              weekCollectionTimeFrom:
                item.locationLabPinCode?.workDayStartTime || "",
              workDayEndTime: item.locationLabPinCode?.workDayEndTime || "",
              workDayWeekendStartTime:
                item.locationLabPinCode?.workDayWeekendStartTime || "",
              workDayWeekendEndTime:
                item.locationLabPinCode?.workDayWeekendEndTime || "",
            },
          }));

          setAddressRow(updatedData);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    setLoading(true);
    GetAddressInfo();
  }, []);
  console.log("address ", addressrow);
  return (
    <>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <Button color="danger" outline onClick={addAddressRow}>
              Click here to add more Locations
            </Button>
          </div>
          {addressrow &&
            addressrow?.map((row, addressIndex) => (
              <>
                <Form
                  style={{
                    border: "1px solid lightgrey",
                    padding: "15px",
                    marginTop: "20px",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    fontSize: "18px",
                  }}
                >
                  <>
                    <Row className="mt-4">
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`labAddress${addressIndex}`}>
                            <b>Location Address</b>
                          </Label>
                          <Input
                            for={`labAddress${addressIndex}`}
                            name="labAddress"
                            placeholder="Enter Location Address"
                            value={row.labAddress}
                            type="text"
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              setLabAddress(e.target.value);
                              handleLabAddress(e);
                            }}
                            style={{
                              borderColor: fieldErrors.labAddress
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for={`labPhoneNumber${addressIndex}`}>
                            <b>Phone Number</b>
                          </Label>
                          <Input
                            for={`labPhoneNumber${addressIndex}`}
                            name="labPhoneNumber"
                            placeholder="Enter Location Phone Number"
                            value={row?.labPhoneNumber}
                            type="number"
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              setLabPhoneNumber(e.target.value);
                              handlelabPhoneNumber(e);
                            }}
                            style={{
                              borderColor: fieldErrors.labPhoneNumber
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for={`labPinCode${addressIndex}`}>
                            <b>Pin Code</b>
                          </Label>
                          <Input
                            for={`labPinCode${addressIndex}`}
                            name="labPinCode"
                            placeholder="Enter Location PinCode"
                            value={row?.labPinCode}
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              setPinCode(e.target.value);
                              handlePinCode(e);
                            }}
                            type="number"
                            style={{
                              borderColor: fieldErrors.labPinCode
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Working Timing From</b> (Monday - Friday){" "}
                          </Label>
                          <Input
                            for={`workDayStartTime${addressIndex}`}
                            name="workDayStartTime"
                            value={row?.workDayStartTime}
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              setWeekDayFrom(e.target.value);
                              handleWeekDayFrom(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekDayFrom
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Working Timing To</b> (Monday - Friday){" "}
                          </Label>
                          <Input
                            for={`workDayEndTime${addressIndex}`}
                            name="workDayEndTime"
                            placeholder="Enter PinCode"
                            value={row?.workDayEndTime}
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              setWeekDayTo(e.target.value);
                              handleWeekDayTo(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekDayTo
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Working Timing From</b> (Saturday & Sunday)
                          </Label>
                          <Input
                            for={`workDayWeekendStartTime${addressIndex}`}
                            name="workDayWeekendStartTime"
                            placeholder="Enter PinCode"
                            value={row?.workDayWeekendStartTime}
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              setWeekEndFrom(e.target.value);
                              handleWeekEndFrom(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekEndFrom
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Working Timing To</b> (Saturday & Sunday)
                          </Label>
                          <Input
                            for={`workDayWeekendEndTime${addressIndex}`}
                            name="workDayWeekendEndTime"
                            placeholder="Enter PinCode"
                            value={row?.workDayWeekendEndTime}
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              setWeekEndTo(e.target.value);
                              handleWeekEndTo(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekEndTo
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr style={{ width: "60%", marginInline: "auto" }}></hr>
                    <Row>
                      <Col md={7} className="mt-3">
                        <FormGroup>
                          <Label for={`LocPincode${addressIndex}`}>
                            <b>
                              List all PIN Codes where this location will
                              provide home collection service
                            </b>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {row?.locationLabPinCode &&
                      Array.isArray(
                        row.locationLabPinCode.serviceAreaPinCode
                      ) &&
                      pincodeRow.length === 0 && (
                        <>
                          <div>
                            <button
                              onClick={() => handleTabChange(null, 2, row)}
                              style={{
                                background: "none",
                                border: "none",
                                padding: 0,
                                textDecoration: "underline",
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              View All Pincodes
                            </button>
                          </div>
                        </>
                      )}
                    {addressrow[addressIndex] &&
                      pincodeRow.map((prow, index) => (
                        <>
                          <Row key={index} className="mt-2">
                            <Col md="4">
                              <FormGroup>
                                <Input
                                  id={`locPincode${index}`}
                                  name="locPincode"
                                  placeholder="Enter Pincode"
                                  value={prow.locPincode || ""}
                                  type="number"
                                  onChange={(e) => {
                                    handlePincodeChange(addressIndex, index, e);
                                    setLabPincode(e.target.value);
                                    handleLocPinCode(e);
                                  }}
                                  style={{
                                    borderColor: fieldErrors.locPincode
                                      ? "red"
                                      : "lightgrey",
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      ))}
                    <Col md={12} className="mt-2">
                      <FormGroup
                        style={{
                          gap: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          color="danger"
                          outline
                          style={{ fontSize: "15px" }}
                          onClick={() => addPincode(addressIndex)}
                        >
                          Add More Pincode
                        </Button>
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          OR
                        </span>

                        <Button
                          color="primary"
                          outline
                          style={{ fontSize: "15px" }}
                          onClick={() =>
                            document
                              .getElementById(`attachFile${addressIndex}`)
                              .click()
                          }
                        >
                          {uploadingIndex === addressIndex ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Attach Pincode File"
                          )}
                        </Button>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id={`attachFile${addressIndex}`}
                          onChange={(e) =>
                            handleFileChange(addressIndex, e, row.id)
                          }
                        />
                        {fileNames[addressIndex] && (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              marginTop: "2px",
                            }}
                          >
                            {fileNames[addressIndex]}
                          </div>
                        )}
                      </FormGroup>
                      {fileErrors[addressIndex] && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginTop: "5px",
                          }}
                        >
                          {fileErrors[addressIndex]}
                        </div>
                      )}
                    </Col>
                    <Row className="mt-5">
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Sample Collection Timing From</b>
                            <br></br> (Monday - Friday)
                          </Label>
                          <Input
                            for={`weekCollectionTimeFrom${addressIndex}`}
                            name="weekCollectionTimeFrom"
                            placeholder="Enter PinCode"
                            value={
                              row.locationLabPinCode?.weekCollectionTimeFrom
                            }
                            onChange={(e) => {
                              handleAddress1Change(addressIndex, e);
                              setWeekCollectionTimeFrom(e.target.value);
                              handleWeekCollectionTimeFrom(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekCollectionTimeFrom
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Sample Collection Timing To</b>
                            <br></br> (Monday - Friday){" "}
                          </Label>
                          <Input
                            for={`workDayEndTime${addressIndex}`}
                            name="workDayEndTime"
                            placeholder="Enter PinCode"
                            value={row.locationLabPinCode?.workDayEndTime}
                            onChange={(e) => {
                              handleAddress1Change(addressIndex, e);
                              setWeekCollectionTimeTo(e.target.value);
                              handleWeekCollectionTimeTo(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekCollectionTimeTo
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Sample Collection Timing From</b>
                            <br></br> (Saturday & Sunday)
                          </Label>
                          <Input
                            for={`workDayWeekendStartTime${addressIndex}`}
                            name="workDayWeekendStartTime"
                            placeholder="Enter PinCode"
                            value={
                              row.locationLabPinCode?.workDayWeekendStartTime
                            }
                            onChange={(e) => {
                              handleAddress1Change(addressIndex, e);
                              setWeekEndCollectionTimeFrom(e.target.value);
                              handleWeekEndCollectionTimeFrom(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekEndCollectionTimeFrom
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>
                            <b>Sample Collection Timing To</b> <br></br>
                            (Saturday & Sunday)
                          </Label>
                          <Input
                            for={`workDayWeekendEndTime${addressIndex}`}
                            name="workDayWeekendEndTime"
                            placeholder="Enter PinCode"
                            value={
                              row.locationLabPinCode?.workDayWeekendEndTime
                            }
                            onChange={(e) => {
                              handleAddress1Change(addressIndex, e);
                              setWeekEndCollectionTimeTo(e.target.value);
                              handleWeekEndCollectionTimeTo(e);
                            }}
                            type="time"
                            style={{
                              borderColor: fieldErrors.weekEndCollectionTimeTo
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}></Col>
                      <Col md={3}>
                        <FormGroup></FormGroup>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </>
                </Form>
              </>
            ))}
          <center>
            <Button
              onClick={handleSubmit}
              style={{
                marginTop: "4%",
                width: "15%",
                backgroundColor: "rgb(83, 83, 233)",
              }}
            >
              Save
            </Button>
          </center>
        </>
      )}
    </>
  );
};
export default LabAddress;
