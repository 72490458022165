import React from "react";
import "./Term&PoliciesModal.css";
import { Row, Col } from "reactstrap";

const TermAndPlociesModal = ({ onClose }) => {
  return (
    <div style={{ padding: "20px" }}>
      {/* <div className="TermAndPoliciesHeaderContainer">
                <div className="TermAndPoliciesHeading">
                    Term & Policies
                </div>

                <div onClick={onClose} style={{cursor: "pointer"}}>
                    <img src="/img/close-circle.png" height="40px" width="40px"></img>
                </div>
            </div> */}

      <Row>
        <Col lg={6}>
          <a
            target="blank"
            href="https://livingscopehealth.com/terms/"
          >
            <div style={{ backgroundColor: "#5353E9", borderRadius: "15px", padding: "40px 0px 40px 0px ", cursor: "pointer" }}>
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <img
                  height="62px"
                  width="62px"
                  alt="Setting"
                  src="/img/setting-2.png"
                />
              </div>
              <div className="termandpolicytext">Terms & Conditions</div>
            </div>
          </a>
        </Col>

        <Col lg={6}>
          <a
            target="blank"
            href="https://livingscopehealth.com/privacy/"
          >
            <div style={{ backgroundColor: "#5353E9", borderRadius: "15px", padding: "40px 0px 40px 0px ", cursor: "pointer" }}>
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <img
                  height="62px"
                  width="62px"
                  alt="Setting"
                  src="/img/setting-2.png"
                />
              </div>

              <div className="termandpolicytext">Privacy Policy</div>
            </div>
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default TermAndPlociesModal;
