import React from "react";
import "./DeleteAccountModal.css";
import { useNavigate } from "react-router";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";

const DeleteAccountModal = ({ onClose }) => {
  const navigate = useNavigate();
  const DeleteAccount = () => {
    AxiosInstance.delete("delete/account").then((response) => {     
        sessionStorage.clear();
        setTimeout(() => {
            navigate("/homeBeforeLogin");
            window.location.reload();
        },1000)
        toast.success(response)
      })
      .catch(error => {
        console.log('Error:' , error)
      })
  };
  return (
    <div className="DeleteAccount">
        <ToastMessage></ToastMessage>
      <div className="description">Are you sure you want to delete your account ?</div>
      <div className="description">This action cannot be undone. All of your data and subscription will be erased.</div>
      <div></div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "20px" }}>
        <div
          className="no"
          onClick={onClose}
        >
          No
        </div>
        <div
          className="yes"
          onClick={DeleteAccount}
        >
          Yes
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
