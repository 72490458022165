/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Component } from "../Component";
import { IconLogout } from "../IconLogout";
import { NavsBecnchmark } from "../NavsBecnchmark";
import { NavsEvents } from "../NavsEvents";
import { NavsHelpCenter } from "../NavsHelpCenter";
import { NavsOverview } from "../NavsOverview";
import { NavsSettings } from "../NavsSettings";
import { Link, NavLink } from "react-router-dom";
import "./style.css";
import { NavsTestReports } from "../NavsTestReports/NavsTestReports";
import { NavCaseHistory } from "../NavCaseHistory/NavsCaseHistory";
import { NavsTrendsToWatch } from "../NavsTrendsToWatch/NavsTrendsToWatch";
import { NavsRiskAssessment } from "../NavsRiskAssessment/NavsRiskAssessment";
import { NavsWeeklyLog } from "../NavsWeeklyLog/NavsWeeklyLog";
import { NavsHealthReportCard } from "../NavsHealthReportCard/NavsHealthReportCard";
import { NavsPreventiveCare } from "../NavsPreventiveCare/NavsPreventiveCare";
import { NavsRiskTools } from "../NavsRiskTools/NavsRiskTools";
import { NavsRiskTracker } from "../NavsRiskTracker/NavsRiskTracker";
import { NavsLabServices } from "../NavsLabServices/NavsLabServices";
import { NavsHealthOutlook } from "../NavsHealthOutlook/NavsHealthOutlook";
import ViewListIcon from "@mui/icons-material/ViewList";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DescriptionIcon from "@mui/icons-material/Description";
import InsightsIcon from "@mui/icons-material/Insights";
import { NavsVaccinations } from "../NavsVaccinations/NavsVaccinations";
import { NavsLifestyleModifications } from "../NavsLifestyleModifications/NavsLifestyleModifications";
import { FaUserDoctor } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import { NavOnlineConsultation } from "../NavOnlineConsultation/NavOnlineConsultation";
import { IoNutrition } from "react-icons/io5";

export const SideNav = ({
  property1,
  style,
  navsOverviewProperty1 = "default",
  navsTestReportsProperty1 = "variant-2",
  navsEventsProperty1 = "variant-2",
  navsOnlineConsultaionProperty1 = "variant-2",
  navsTrendsProperty1 = "variant-2",
  navsPreventiveCareProperty1 = "variant-2",
  NavsHealthReportCardProperty1 = "variant-2",
  NavsHealthOutlookProperty1 = "variant-2",
  NavsWeeklyLogs = "variant-2",
  NavsVaccination = "variant-2",
  navsRiskAssessmentProperty1 = "variant-2",
  navsRiskToolsProperty1 = "variant-2",
  navsLabServicesProperty1 = "variant-2",
  navsLifestyleModificationsProperty1 = "variant-2",
  navsRiskTrackerProperty1 = "variant-2",
  NavsBenchmarkProperty1 = "variant-2",
  medicalhistorytab,
  labhistorytab,
  casehistorytab,
  component = (
    <IconLogout
      iconLogout="/img/icon-logout.png"
      style={{
        height: "21px",
        left: "unset",
        minWidth: "21px",
        position: "relative",
        top: "unset",
        width: "unset",
      }}
    />
  ),
}) => {
  const [userDetails, setUserDetails] = useState();
  //userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  // console.log("userDetails.userType !=", userDetails.userType != "Doctor");

  useEffect(() => {
    let userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    setUserDetails(userDetails);
  }, []);

  return (
    <div className={`side-nav ${property1}`} style={style}>
      {userDetails?.userType == "TESTLAB" && (
        <Link to="/lab-screen" className="logo">
          <img className="shape" alt="Shape" src="/img/AppLogoWithName.png" />
        </Link>
      )}
      {userDetails?.userType == "DOCTOR" && (
        <Link to="/doctor-home" className="logo">
          <img className="shape" alt="Shape" src="/img/AppLogoWithName.png" />
        </Link>
      )}
      {userDetails?.userType == "DIETITIAN" && (
        <Link to="/dietician-screen" className="logo">
          <img className="shape" alt="Shape" src="/img/AppLogoWithName.png" />
        </Link>
      )}
      {userDetails?.userType == "CORPORATE" && (
        <Link to="/corporate-dashboard" className="logo">
          <img className="shape" alt="Shape" src="/img/AppLogoWithName.png" />
        </Link>
      )}
      {userDetails?.userType == "ADMIN" && (
        <Link to="/admin-screen" className="logo">
          <img className="shape" alt="Shape" src="/img/AppLogoWithName.png" />
        </Link>
      )}
      {userDetails?.userType == "PATIENT" && (
        <Link to="/home" className="logo">
          <img className="shape" alt="Shape" src="/img/AppLogoWithName.png" />
        </Link>
      )}
      {userDetails && userDetails?.userType == "DOCTOR" && (
        <>
          <div style={{ marginTop: "3rem" }}>
            <NavLink
              to="/doctor-Order"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <ViewListIcon /> My Orders
            </NavLink>
            <br />
            <NavLink
              to="/doctor-order-histoy"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <FaHistory /> Order History
            </NavLink>
            <br />
            <NavLink
              to="/online-consultation"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <FaUserDoctor /> Online Consultation
            </NavLink>
            <br />
            <NavLink
              to="/doctor-billing"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <AccountBalanceWalletIcon /> Billing
            </NavLink>
            <br />
            <NavLink
              to="/doctor-performance"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <InsightsIcon /> Performance
            </NavLink>
          </div>
        </>
      )}
      {userDetails && userDetails?.userType == "TESTLAB" && (
        <>
          <div style={{ marginTop: "3rem" }}>
            <Link to="/my-Order" className="labScreen">
              <ViewListIcon /> My Orders
            </Link>
            <br></br>
            <Link to="/billing" className="labScreen">
              <AccountBalanceWalletIcon /> Billing
            </Link>
            <br></br>
            <Link to="/invoice" className="labScreen">
              <DescriptionIcon /> Invoice
            </Link>
            <br></br>
            <Link to="performance" className="labScreen">
              <InsightsIcon /> Performance
            </Link>
          </div>
        </>
      )}
      {userDetails && userDetails?.userType == "ADMIN" && (
        <>
          <div style={{ marginTop: "3rem" }}>
            <Link to="/admin-Orders" className="labScreen">
              <ViewListIcon /> My Orders
            </Link>
            <br></br>
            <Link to="/admin-billing" className="labScreen">
              <AccountBalanceWalletIcon /> Billing
            </Link>
            <br></br>
            <Link to="/admin-invoice" className="labScreen">
              <DescriptionIcon /> Invoice
            </Link>
            <br></br>
            <Link to="/admin-performance" className="labScreen">
              <InsightsIcon /> Performance
            </Link>
          </div>
        </>
      )}
      {userDetails && userDetails?.userType == "DIETITIAN" && (
        <>
          <div style={{ marginTop: "3rem" }}>
            <NavLink
              to="/dietician-Order"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <ViewListIcon /> My Orders
            </NavLink>
            <br></br>
            <NavLink
              to="/dietician-order-history"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <FaHistory /> Order History
            </NavLink>
            <br />
            <NavLink
              to="/dietician-online-consultation"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <IoNutrition /> Online Consultation
            </NavLink>
            <br></br>
            <NavLink
              to="/dietician-billing"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <AccountBalanceWalletIcon /> Billing
            </NavLink>
            <br></br>
            <NavLink
              to="/dietician-invoice"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <DescriptionIcon /> Invoice
            </NavLink>
            <br></br>
            <NavLink
              to="/dietician-performance"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <InsightsIcon /> Performance
            </NavLink>
          </div>
        </>
      )}
      {userDetails && userDetails?.userType == "CORPORATE" && (
        <>
          <div style={{ marginTop: "3rem" }}>
            <NavLink
              to="/corporate-Order"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <ViewListIcon /> My Orders
            </NavLink>
            <br></br>
            <NavLink
              to="/corporate-order-history"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <FaHistory /> Order History
            </NavLink>
            <br />
            <NavLink
              to="/corporate-billing"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <AccountBalanceWalletIcon /> Billing
            </NavLink>
            <br></br>
            <NavLink
              to="/corporate-invoice"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <DescriptionIcon /> Invoice
            </NavLink>
            <br></br>
            <NavLink
              to="/corporate-performance"
              className={({ isActive }) =>
                isActive ? "active-link" : "labScreen"
              }
            >
              <InsightsIcon /> Performance
            </NavLink>
          </div>
        </>
      )}
      {userDetails &&
        userDetails?.userType != "DOCTOR" &&
        userDetails?.userType != "TESTLAB" &&
        userDetails?.userType != "DIETITIAN" &&
        userDetails?.userType != "ADMIN" &&
        userDetails?.userType != "CORPORATE" && (
          <>
            <div style={{ textAlign: "center" }}>
              <img
                className="vector"
                alt="Vector"
                src={
                  property1 === "light"
                    ? "/img/vector-96-3.png"
                    : "/img/vector-96.png"
                }
              />
            </div>
            <div className="groupBronze">
              <div
                style={{
                  marginLeft: "-6%",
                  width: "121%",
                  backgroundColor: "rgb(230 230 230 / 77%)",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <div className="bronze" style={{ marginTop: "10%" }}>
                  BRONZE
                </div>
                <span style={{ fontSize: "14px", color: "#775f5f" }}>
                  Manage Your Health Record
                </span>
              </div>
              <NavsEvents
                property1={navsEventsProperty1}
                // medicalhistorytab={medicalhistorytab}
                // labhistorytab={labhistorytab}
                // casehistorytab={casehistorytab}
              />
              <NavsTestReports
                frameStyle={
                  property1 === "light"
                    ? {
                        backgroundColor: "#5353e91a",
                      }
                    : undefined
                }
                iconFilledOverviewIconFilledOverview={
                  property1 === "light"
                    ? "/img/icon-filled-overview-1.png"
                    : undefined
                }
                overviewStyle={
                  property1 === "light"
                    ? {
                        color: "#5353e9",
                      }
                    : undefined
                }
                property1={navsTestReportsProperty1}
              ></NavsTestReports>
              <NavCaseHistory property1={navsEventsProperty1} />
              <NavOnlineConsultation
                property1={navsOnlineConsultaionProperty1}
              ></NavOnlineConsultation>
            </div>

            {/* {process.env.REACT_DEV_ENVIRONMENT === "true" && ( */}
            <div className="groupSilver">
              <div
                style={{
                  marginLeft: "-6%",
                  width: "121%",
                  backgroundColor: "rgb(230 230 230 / 77%)",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                <div className="silver">SILVER</div>
                <span style={{ fontSize: "14px", color: "#775f5f" }}>
                  Know Your Health Risk
                </span>
              </div>
              {process.env.REACT_DEV_ENVIRONMENT === "true" && (
                <>
                  <NavsVaccinations
                    property1={NavsVaccination}
                  ></NavsVaccinations>

                  <NavsHealthReportCard
                    property1={NavsHealthReportCardProperty1}
                  ></NavsHealthReportCard>
                  <NavsHealthOutlook
                    property1={NavsHealthOutlookProperty1}
                  ></NavsHealthOutlook>
                  <NavsWeeklyLog property1={NavsWeeklyLogs}></NavsWeeklyLog>
                </>
              )}
              <NavsRiskAssessment
                property1={navsRiskAssessmentProperty1}
              ></NavsRiskAssessment>
              {process.env.REACT_DEV_ENVIRONMENT === "true" && (
                <>
                  <div
                    style={{
                      marginLeft: "-6%",
                      width: "121%",
                      backgroundColor: "rgb(230 230 230 / 77%)",
                      textAlign: "center",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="gold">GOLD</div>
                    <span style={{ fontSize: "14px", color: "#775f5f" }}>
                      Prevent and Protect Disease
                    </span>
                  </div>
                  <NavsPreventiveCare
                    property1={navsPreventiveCareProperty1}
                  ></NavsPreventiveCare>
                  <NavsRiskTracker
                    property1={navsRiskTrackerProperty1}
                  ></NavsRiskTracker>
                  {/* {process.env.REACT_DEV_ENVIRONMENT === "true" && ( */}
                  <NavsLifestyleModifications
                    property1={navsLifestyleModificationsProperty1}
                  ></NavsLifestyleModifications>
                  {process.env.REACT_DEV_ENVIRONMENT === "true" && (
                    <>
                      <NavsLabServices
                        property1={navsLabServicesProperty1}
                      ></NavsLabServices>
                      <NavsRiskTools
                        property1={navsRiskToolsProperty1}
                      ></NavsRiskTools>
                    </>
                  )}
                </>
              )}
            </div>
            {/* )} */}
          </>
        )}
      <Component
        override={component}
        overviewStyle={{
          color: "#888888",
        }}
        property1="single-menu-unelected"
        text="Logout"
      />
    </div>
  );
};

SideNav.propTypes = {
  property1: PropTypes.oneOf(["dark", "light"]),
  navsOverviewProperty1: PropTypes.string,
  navsTestReportsProperty1: PropTypes.string,
  navsEventsProperty1: PropTypes.string,
  navsTrendsProperty1: PropTypes.string,
  navsPreventiveCareProperty1: PropTypes.string,
  NavsHealthReportCardProperty1: PropTypes.string,
  NavsHealthOutlookProperty1: PropTypes.string,
  navsRiskAssessmentProperty1: PropTypes.string,
  navsRiskTrackerProperty1: PropTypes.string,
  navsRiskToolsProperty1: PropTypes.string,
  navsLabServicesProperty1: PropTypes.string,
  navsLifestyleModificationsProperty1: PropTypes.string,
  NavsBecnchmarkProperty1: PropTypes.string,
  medicalhistorytab: PropTypes.string,
  labhistorytab: PropTypes.string,
  casehistorytab: PropTypes.string,
};
