import React from "react";
import { Controller } from "react-hook-form";

function InputField({ name, label, control, errors, icon, labelClass, isDisabled, ...rest }) {
  return (
    <div className="input-field">
      <label className={labelClass}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className="inputWithIcon">
            <input
              {...field}
              {...rest}
              disabled={isDisabled ? true : false}
            />
            {icon && (
              <img
                src={icon}
                alt="Icon"
              />
            )}
          </div>
        )}
      />
    </div>
  );
}

export default InputField;
