/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconMail = ({ style, iconMail = "/img/icon-mail-1.png" , className }) => {
  return <img className={`icon-mail ${className}`} style={style} alt="Icon mail" src={iconMail} />;
};

IconMail.propTypes = {
  iconMail: PropTypes.string,
};
