import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from "yup";
import ModelPopUp from "../../../shared/model/ModelPopUp";
import "../AddMoreFormMain.css";
import { Delete } from "../../../icons/DeleteIcon";

function AddAllergies({ AllergiesStep, onUpdateAllergies, selectedOption }) {
  const [allergies, setAllergies] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    ReactionOrSideEffects: yup.string().required("Please enter Reaction Or Side Effects"),
    Medicine: yup.string().required("Please enter Medicine / Food"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ReactionOrSideEffects: "", Medicine: "" },
  });

  const addMoreAllergyFields = () => {
    setIsOpen(true);
  };

  const AddAllergiesList = (data) => {
    const Medicine = watch("Medicine");
    const ReactionOrSideEffects = watch("ReactionOrSideEffects");
    const allergy = {
      MedicineOrFood: Medicine,
      Reaction: ReactionOrSideEffects,
    };

    setAllergies([...allergies, allergy]);

    toast.success("Allergy added successfully");
    setIsOpen(false);
    setLoading(true);
    reset();
  };

  useEffect(() => {
    let newAl = JSON.stringify(allergies);
    onUpdateAllergies((prevState) => ({
      ...prevState,
      allergies: newAl,
    }));
  }, [allergies]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const deleteAllergy = (indexToDelete) => {
    console.log("Deleting allergy with ID:", indexToDelete);
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this Allergy?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedAllergies = allergies.filter((allergy, index) => index !== indexToDelete);
          setAllergies(updatedAllergies);
          toast.success("Allergy deleted successfully");
        }
      });
  };

  return (
    <>
      {selectedOption == true && (
        <div className="health_Service_Providers_Information mt-4">
          <div className="info_header_wrapper">
            <div className="info_provider_content">
              <h3>Allergies</h3>
              <p>List all reactions to medicines, foods and other agents.</p>
            </div>
            <div className="info_provider_button">
              <button
                type="button"
                onClick={addMoreAllergyFields}
              >
                + Add Allergy
              </button>
            </div>
          </div>
          <div className="add_more_header_wrapper">
            <div className="field_header equal-column">Medicine / Food</div>
            <div className="field_header equal-column">Reaction or Side Effects</div>
            <div className="field_header small-column"></div>
          </div>
          {allergies &&
            allergies?.map((input, index) => {
              return (
                <div
                  key={index}
                  className="add_more_fields_wrapper"
                >
                  <p className="equal-column tableData">{input.MedicineOrFood}</p>
                  <p className="equal-column tableData">{input.Reaction}</p>
                  <button
                    type="button"
                    className="remove_button small-column tableData"
                    onClick={() => deleteAllergy(index)}
                  >
                    <Delete />
                  </button>
                </div>
              );
            })}
        </div>
      )}
      <ModelPopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading="Add New Allergy"
        handleSubmit={handleSubmit(AddAllergiesList)}
      >
        <form className="hospital-doctor-add-form">
          <div className={`form-group mt-3 ${errors.ReactionOrSideEffects ? "required-border" : ""}`}>
            <label>Please enter Medicine / Food</label>
            <Controller
              name="Medicine"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  placeholder="Please enter Medicine / Food"
                  fullWidth
                  label="Please enter Medicine / Food"
                />
              )}
            />
            <p className="error">{errors.Medicine?.message}</p>
          </div>
          <div className={`form-group mt-3 ${errors.ReactionOrSideEffects ? "required-border" : ""}`}>
            <label>Reaction or Side Effects</label>
            <Controller
              name="ReactionOrSideEffects"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  placeholder="Reaction Or SideEffects"
                  fullWidth
                  label="Reaction Or SideEffects"
                />
              )}
            />
            <p className="error">{errors.ReactionOrSideEffects?.message}</p>
          </div>
        </form>
      </ModelPopUp>
    </>
  );
}

export default memo(AddAllergies);
