import React from "react";
import ModelPopUp from "../../shared/model/ModelPopUp";
import VectorPlusPurple from "../../img/Vector-plus-purple.png";
import dummyReport from "../../img/dummyReport.png";
import Spinner from "../../shared/Spinner";

const SampleReport = (props) => {
  const { isOpen, setIsModalOpen, reportTitle, showSampleReport, imageUrl, isLoadingDoc, setIsLoadingDoc } = props;

  return (
    <ModelPopUp
      isOpen={isOpen}
      setIsOpen={setIsModalOpen}
      heading={`${reportTitle ? reportTitle : "Sample Report"}`}
      maxWidth="md"
    >
      {isLoadingDoc ? (
        <Spinner />
      ) : (
        <div className="popup-img">
          {showSampleReport && (
            <img
              src={dummyReport}
              alt="Image"
            />
          )}

          {!showSampleReport && imageUrl === undefined ? (
            <p>No Prescriptions found</p>
          ) : (
            <img
              src={imageUrl ? imageUrl : dummyReport}
              alt="Image"
            />
          )}
        </div>
      )}
    </ModelPopUp>
  );
};

export default SampleReport;
