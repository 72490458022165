import React, { useEffect, useRef, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
const FullRecommendation = () => {

const [age, setAge] = useState("");

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
          setAge(response.profile.age)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
    console.log("ageeee", age);
    const ReviewAndUnderstood = () => {
        const lrid1 = "CVD-04-01";
        const lrid2 = "CVD-04-02";
        if (age >= 12 && age <= 20) {
            AxiosInstance.post(`/riskAssessment/reviewAndUnderstood/${lrid1}`)
              .then((response) => {
                console.log('response: ', response);
                Swal.fire({
                  text: "Your Response has been Saved!",
                  icon: "success",
                });
              })
              .catch((error) => {
                console.log("error", error);
            });
        } else if(age > 20) {
            AxiosInstance.post(`/riskAssessment/reviewAndUnderstood/${lrid2}`)
               .then((response) => {
                 console.log("response: ", response);
                 Swal.fire({
                   text: "Your Response has been Saved!",
                   icon: "success",
                 });
                })
               .catch((error) => {
                 console.log("error", error);
            });
        }
    }
    
  return (
    <div>
      <div className="cvdContainer">
        <div className="cvdHeading">Preventive Care Recommendation for Cardiovascular Disease* (CVD) Risk</div>
        <div style={{ fontSize: "small" }}>*Includes Abnormal heart rhythms, congenital heart disease, Coronary artery disease, Heart attack, Heart failure, stroke, etc</div>
      </div>

      <div className="cvdDetails">You have been assessed with CVD Risk</div>
      <div className="cvdDetails">
        Risk Level : <b>Moderate</b>{" "}
      </div>
      <div className="cvdDetails">Risk Factors: Metallic Syndrome , Family History , Age</div>

      <div className="whatsNext">
        <h3>What's Next ?</h3>
      </div>
      <div>
        We recommend that you take <b>following steps immediately:</b>{" "}
      </div>

      <div>
        <ul style={{ listStyle: "auto", padding: "15px" }}>
          <li>
            <div style={{ marginBottom: "10px" }}>
              <b>Blood Test: </b> <span> We need to assess you further for CVD Risk. For this we recommend that you get a Blood test done with at least following checked:</span>
            </div>
            <div>
              <b>Cholesterol panel: HDL, LDL, Triglycerides, Total cholesterol</b>
            </div>
            {/* <div className="curolPathologyContainer">
              We have partnered with ‘Curol Pathology’ in Thane for Full Lab services. They provide free home test collection services too. You automatically get a discount of 20-40% when you go
              through us for any tests mentioned as a result of an assessment in the app. Please book here if you would like to set up an appointment with Curol Pathology for the Blood test. Once you
              pay the charges and schedule your appointment, you will receive a call from the Pathology lab confirming about their home visit to collect the blood sample.
              <div style={{ textAlign: "center" }}>
                <button className="bookForTestButton">Book for Test</button>
              </div>
            </div> */}
            {/* <div> </div> */}
          </li>
          <li className="liHeading">
            <b>Blood Pressure check: </b>
            <div>
              We recommend that you buy a home BP measuring device such as (OMRON HEM 7120 Fully Automatic or any other brand with good accuracy) and measure and monitor your blood pressure at home.
              We recommend that you take at least two readings, one in the morning and one in the evening for a week. Blood pressure measurements should be taken at the brachial artery (upper arm)
              with a validated and accurate device in a seated position after 5 minutes of rest.
            </div>
            <div style={{ margin: "15px 0px 15px 0px" }}>
              <b>OR</b>
            </div>
            <div>
              If you do not want to invest in a home BP measuring device then please setup an appointment with your doctor and ask him or her to measure your systolic and diastolic BP. Ask him to
              record the readings in his prescription report or you simply write it down in a piece of paper so you can later record in the app.{" "}
            </div>
          </li>
          <li className="liHeading">
            <div>
              Next record the BP readings directly in the Uncurl app, <b>“Daily Log”</b> for BP.
            </div>
          </li>
          <li className="liHeading">
            <div>Upload the Lab test report in the Uncurl app as soon as you receive it from the lab. </div>
            <div>
              <b>
                Note: If you go through us and use Curol lab for the tests then you need not upload the test report into our app. Curol Lab will directly upload your test reports into our app once
                they are ready and we will inform you.{" "}
              </b>
            </div>
          </li>
          <li className="liHeading">
            Our system will then calculate your <b>"10 Year CVD Risk (%)"</b> as well as Risk Level using an internationally recognized standard test. You will be notified as soon as this assessment
            is completed for you. It is done within 24 hours of receiving your test reports and your BP readings in the app. You can see the result in “Cardiac” tab under SILVER service.
          </li>
          <li className="liHeading">
            <b>Based on your CVD Risk calculation using the FRS, you will be guided further for your next steps.</b> Typically, if your FRS 10-year CVD Risk (%) &gt; 7.5%, you will be required to
            consult with your doctor. You doctor can have access to our CVD Risk calculation tool to verify the risk calculation done for you.
          </li>
        </ul>
      </div>

      <div>
        <div className="cvdContainer" style={{backgroundColor: "green" , color: "white"}}>
        <div className="cvdHeading">Preventive Care Recommendation for Hypertension Risk</div>
        </div>

        <div className="cvdDetails">You have been assessed with Hypertension Risk</div>
        <div className="cvdDetails">
        Risk Level : <b>Medium</b>
        </div>
        <div className="cvdDetails">Risk Factors: Family History , Age , Obesity , Diet , Sedentary lifestyle</div>

        <div className="whatsNext">
        <h3>What's Next ?</h3>
        </div>
        <div>
        We recommend that you take <b>following steps immediately:</b>
        </div>

        <div>
        <ul style={{ listStyle: "auto", padding: "15px" }}>
        <li>
        <div style={{ marginBottom: "10px" }}>
        <span> Please setup an appointment with your doctor and ask him or her to measure your systolic and diastolic BP. Ask him to record the readings in his prescription report or you simply write it down in a piece of paper so you can later record in the app. </span>
        </div>
        </li>
        <li className="liHeading">
        <b>Blood Pressure check at home (outside clinical setting) </b>
        <div>
          We highly recommend that you also buy a home BP measuring device such as (OMRON HEM 7120 Fully Automatic or any other brand with good accuracy) and measure and monitor your blood pressure at home. We recommend that you take at least two readings, one in the morning and one in the evening <b>for a week once initially diagnosed with this risk and after your doctor consultation </b>.  Blood pressure measurements should be taken at the brachial artery (upper arm) with a validated and accurate device in a seated position after 5 minutes of rest.
        </div>
        </li>
        <li className="liHeading">
        <div>
        Next record the BP readings directly in the Uncurl app, <b>“Daily Log”</b> for BP (Both measured by your doctor as well as those measured by you at home for a week).
        </div>
        </li>
        <li className="liHeading">
        <div>Please follow your doctor’s advice and regularly take any medications if prescribed by him or her for your condition. </div>
        </li>
        <li className="liHeading">
          Upload all records from the hospital in the app. 
        </li>
        </ul>
        </div>

        <div className="cvdContainer" style={{backgroundColor: "#d4101054", borderRadius: "10px" , border: '2px solid black' , marginBottom: "20px"}}> 
            <div className="cvdHeading" style={{marginBottom: "10px"}}>IMPORTANT ALERT </div>
            <div>If you get a blood pressure reading of 180/110 or higher more than once during home measurement, then immediately call up your doctor and seek medical treatment. A reading this high is considered “hypertensive crisis”.</div>
        </div>

      </div>


      <div className="customerCare">
        Call our customer support service team at 7977147047 or email us at
        <a style={{ textDecoration: "underline", color: "#5353e9" , margin: "0px 5px 0px 5px" }} href="mailto:info@livingscopehealth.com">
          info@livingscopehealth.com
        </a>
        , if you are not clear about this recommendation or need any further help.
      </div>
      <button className="btn btn-primary" onClick={ReviewAndUnderstood} style={{ width: "20%", marginLeft:"40%", marginTop:"20px" }}>
        Reviewed and Understand
      </button>
    </div>
  );
};

export default FullRecommendation;
