import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import "./style.css";
import LivingScopeHealthLogo from "../CME_Doctors_Information/Images/LivingScopeHealthLogo.png";

export const LoginOtp = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");

  const onNumberChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setNumber(result);
  };

  const login = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}api/lab-partner/auth/sendotp/${number}`
      )
      .then((response) => {
        toast.success("OTP sent Successfully");
        setTimeout(() => {
          navigate(`/verification-2/${number}`);
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  const CMEDoctorslogin = async () => {
    const doctorType = "CME";
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}doctor-verify/sendOtp/${number}/${doctorType}`)
      .then((response) => {
        toast.success("OTP sent Successfully");
        setTimeout(() => {
          navigate(`/verification-2/${number}`);
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const cme_doctors = sessionStorage.getItem("cme_doctors");

  return (
    <div className="login-phone-screen">
      <div className={cme_doctors ? "" : "overlap-8"}>
        <ToastMessage />
        <div className="group-18">
          <img
            className={cme_doctors ? "" : "health-buddy"}
            alt="Health buddy"
            src={
              cme_doctors ? LivingScopeHealthLogo : "/img/AppLogoWithName.png"
            }
          />
        </div>

        <div className="loginContainer">
          <div className="overlap-9">
            <div className="text-wrapper-19">Login</div>
            <p className="text-wrapper-46">
              Kindly provide a valid phone number as you will receive a message
              containing the OTP code.
            </p>
            {!cme_doctors && (
              <div className="group-13">
                <div className="overlap-group-6">
                  <div style={{ display: "flex" }}>
                    <Link to="/login-email" className="text-wrapper-15">
                      With Email
                    </Link>
                  </div>
                  <div className="overlap-10">
                    {" "}
                    <div className="text-wrapper-14">With OTP</div>{" "}
                  </div>
                </div>
              </div>
            )}

            <div className="group-17">
              <div className="text-wrapper-23">Phone Number</div>

              <div className="overlap-group-7">
                <img
                  className="icon-feather-phone-3"
                  alt="Icon feather phone"
                  src="/img/icon-feather-phone.png"
                />
                <input
                  type="tel"
                  onChange={onNumberChange}
                  value={number}
                  className="phoneNumberInput"
                  placeholder="Phone Number"
                  maxLength="10"
                ></input>
              </div>
            </div>

            <button
              className={`${number === "" ? "group-16disabled" : "group-16"}`}
              disabled={number === ""}
              onClick={cme_doctors ? CMEDoctorslogin : login}
            >
              Send OTP
            </button>

            {!cme_doctors && (
              <p className="don-t-have-an">
                <span className="text-wrapper-24">Don’t have an account? </span>
                <Link to="/sign-up">
                  <span className="signup">Sign Up</span>
                </Link>
              </p>
            )}

            {!cme_doctors && (
              <p className="by-clicking-here-i">
                <span className="byclick">
                  By clicking here, I state that i have read and understood the{" "}
                </span>
                <a href="https://livingscopehealth.com/terms/" target="blank">
                  <span className="termsandcondition">
                    terms &amp; conditions
                  </span>
                </a>
                <span className="byclick"> and </span>
                <a href="https://livingscopehealth.com/privacy/" target="blank">
                  <span className="termsandcondition">privacy policy</span>
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
