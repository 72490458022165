import React, { useEffect, useState } from "react";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { formatDateDMY } from "../../util/Helper";

const MyPatients = () => {
  const [patientList, setPatientList] = useState([]);
  const doctorToken = sessionStorage.getItem("token");

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const columns = [
    { label: "Patient Id", field: "patientId" },
    { label: "Reg. Date", field: "createdOn", type: "date" },
    { label: "First Name", field: "firstName" },
    { label: "Last Name", field: "lastName" },
    { label: "Email", field: "email" },
    { label: "Phone Number", field: "phoneNumber" },
    { label: "Total Visits", field: "totalHospitalVisit" },
    { label: "Test Recom.", field: "recommendedTests" },
    { label: "Test Avail.", field: "testReportAvailable" },
  ];

  const getMyPatientList = async () => {
    AxiosInstance.get(`doctorReference/doctor`)
      .then((res) => {
        setPatientList(res);
        setFilteredData(res);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    getMyPatientList();
  }, []);

  function getBaseUrl() {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = arr[0] + "//" + arr[2];
    return newPath;
  }

  const getPatientHandler = async (item) => {
    const doctorRequest = {
      doctorToken,
      patientId: item.patientId,
    };
    AxiosInstance.post(`doctorReference/doctor/patient`, doctorRequest)
      .then((res) => {
        const userDetails = encodeURIComponent(JSON.stringify(res));
        window.open(`${getBaseUrl()}/home?token=${userDetails}&doctorLogin=${true}`, "_blank");
      })
      .catch((error) => {});
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredData = patientList.filter((item) => Object.values(item).some((value) => typeof value === "string" && value.toLowerCase().includes(searchTerm)));
    setSearchTerm(searchTerm);
    setFilteredData(filteredData);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 className="mb-3">My Patients</h3>
        <input
          type="search"
          className="my-patient-search"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="patient-list-table">
        <TableContainer
          component={Paper}
          style={{ height: `${filteredData?.length > 0 ? "400px" : "auto"}` }}
        >
          <Table
            stickyHeader
            size="small"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return <TableCell key={column.field}>{column.label}</TableCell>;
                })}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <TableRow key={item.patientId}>
                    {columns.map((column) => {
                      console.log("item[column.field]", column.type);
                      if (column.type == "date") {
                        return <TableCell key={column.field}>{formatDateDMY(item[column.field])}</TableCell>;
                      } else {
                        return <TableCell key={column.field}>{item[column.field]}</TableCell>;
                      }
                    })}
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          borderColor: "#5353e9",
                          color: "#5353e9",
                          "&:hover": {
                            borderColor: "#5353e9",
                            color: "white",
                            backgroundColor: "#5353e9",
                          },
                        }}
                        onClick={() => getPatientHandler(item)}
                      >
                        View Patient
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={columns.length + 1}
                  >
                    No Patient Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <h6 className="mt-3">Total Patient : {filteredData?.length}</h6>
      </div>
    </>
  );
};

export default MyPatients;
