import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import bronzeImage from "../../../static/img/bronze-plan.jpg";
import silver1Image from "../../../static/img/silver-plan01.jpg";
import BronzeHealthPopup from "../../components/BronzeHealthPopup/BronzeHealthPopup";
import { strings } from "../../constants/strings";
import InfoModel from "../../shared/simpleModel/InfoModel";
import HeaderBeforeLogin from "./HeaderBeforeLogin";
import "./HomePageBeforeLogin.css";

export const HomePageBeforeLogin = () => {
  const [isExploreTheServicesInfoModeOpen, setExploreTheServicesIsInfoModeOpen] = useState(false);
  const [isBronzeHealthPlanInfoModeOpen, setBronzeHealthPlanIsInfoModeOpen] = useState(false);
  const [isWhereIsMyMedicalHistoryVideoInfoModeOpen, setWhereIsMyMedicalHistoryVideoIsInfoModeOpen] = useState(false);

  const ExploreTheServicesPopup = () => {
    setExploreTheServicesIsInfoModeOpen((prev) => !prev);
  };

  const BronzeHealthPlanPopup = () => {
    setBronzeHealthPlanIsInfoModeOpen((prev) => !prev);
  };

  const WhereIsMyMedicalHistoryVideoPopup = () => {
    setWhereIsMyMedicalHistoryVideoIsInfoModeOpen((prev) => !prev);
  };

  return (
    <div className="home">
      <HeaderBeforeLogin />
      <div className="plan-details-section">
        <Container maxWidth="lg">
          <h2 className="plan-pageTitle">Explore Our Health Services</h2>
          <div className="plan-details-wrapper">
            <div className="plan-detail-box">
              <div className="plan-image">
                <img src={bronzeImage} />
              </div>
              <div className="plan-detail">
                <h3>{strings.plan.tier1Bronze}</h3>
                <p>Manage all your health records for easy access.</p>
                <Link to="/SubscriptionPageBeforeLogin">
                  <Button
                    variant="contained"
                    className="plan-button"
                    sx={{
                      ":hover": {
                        bgcolor: "#5353e9",
                        color: "#fff",
                      },
                      borderColor: "#5353e9",
                      color: "#fff",
                      bgcolor: "#5353e9",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "18px",
                      minWidth: "300px",
                      margin: "0 auto",
                      textTransform: "capitalize",
                      padding: "15px",
                    }}
                  >
                    {strings.plan.startForFree}
                  </Button>
                </Link>
                <div className="lear-more-watch">
                  <Button onClick={BronzeHealthPlanPopup}>Learn more</Button>
                  <Button onClick={WhereIsMyMedicalHistoryVideoPopup}>Watch here</Button>
                </div>
              </div>
            </div>
            <div className="plan-detail-box">
              <div className="plan-image">
                <img src={silver1Image} />
              </div>
              <div className="plan-detail">
                <h3>{strings.plan.tier2Silver}</h3>
                <p>Understand and Start Managing your health.</p>
                <Button
                  variant="contained"
                  className="plan-button"
                  onClick={ExploreTheServicesPopup}
                  sx={{
                    ":hover": {
                      bgcolor: "#5353e9",
                      color: "#fff",
                    },
                    borderColor: "#5353e9",
                    color: "#fff",
                    bgcolor: "#5353e9",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "18px",
                    minWidth: "300px",
                    margin: "0 auto",
                    textTransform: "capitalize",
                    padding: "15px",
                  }}
                >
                  {strings.plan.exploreTheServices}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <InfoModel
        open={isBronzeHealthPlanInfoModeOpen}
        setOpen={setBronzeHealthPlanIsInfoModeOpen}
        buttonText="Ok"
      >
        <BronzeHealthPopup />
      </InfoModel>

      <InfoModel
        open={isWhereIsMyMedicalHistoryVideoInfoModeOpen}
        setOpen={setWhereIsMyMedicalHistoryVideoIsInfoModeOpen}
        buttonText="Ok"
      >
        <video
          id="myVideo"
          autoPlay
          controls
          muted
        >
          <source
            src="https://uncurlhealth-videos.s3.amazonaws.com/1726049_WhereIsMyMedicalRecord_101223.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </InfoModel>
      <InfoModel
        open={isExploreTheServicesInfoModeOpen}
        setOpen={setExploreTheServicesIsInfoModeOpen}
        buttonText="Ok"
      >
        <div className="popup-description">
          <p>{strings.message.pleaseJoinBronze}</p>
        </div>
      </InfoModel>
    </div>
  );
};
