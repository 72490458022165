import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import NewPatientMigration from "./NewPatientMigration";
import PatientMigration from "./PatientMigration";

const PatientMigrationWrapper = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  return (
    <div className="patients-migration-form-wrapper">
      <Container maxWidth="lg">
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="disabled tabs example"
          centered
        >
          <Tab label="Migration Patient" />
          <Tab label="Create Trial Subscription" />
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ marginTop: "30px" }}>
            <PatientMigration value={0} />
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ marginTop: "30px" }}>
            <NewPatientMigration value={1} />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default PatientMigrationWrapper;
