import React, { useEffect, useState } from 'react';
import { Button, Card } from "@mui/material";
import "./BuildPackage.css";
import { Table } from 'reactstrap';
import { BsInfoLg } from "react-icons/bs";
import { AxiosInstance } from '../../configration/AxiosConfiguration';
import { getAllTestDataApi, getBuildPackageData, setBuildPackageData } from '../../constants/apiList';
import { toast } from 'react-toastify';
import ToastMessage from "../../shared/ToastMessage";

const BuildPackage = ({setCurrentTabIndex}) => {
  const [tooltipTextDesc, setTooltipTextDesc] = useState('');
  const [tooltipTextPerformance, setTooltipTextPerformance] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState('');
  const [testNames, setTestNames] = useState([]);
  const [checkedTests, setCheckedTests] = useState([]);
  const [testData, setTestData] = useState([]);
  const [extractedTestNames, setExtractedTestNames] = useState([]);
  const [disableTestNames, setDisableTestNames] = useState([]);
  const handleToggleTooltip = (performance,description, testName) => {
    if (tooltipVisible === testName) {
      setTooltipVisible('');
    } else {
      setTooltipTextPerformance(performance);
      setTooltipTextDesc(description);
      setTooltipVisible(testName);
    }
  };
  useEffect(()=>{
    const getAllTestName = async () =>{
     await AxiosInstance.get(`${getAllTestDataApi}`).then((response)=>{
      const responseData = response.map(test => ({
        id: test.testId,
        name: test.testName,
        description:test.description
      }));
      setTestData(responseData);
      }).catch((err)=>{
        console.log('err: ', err);
      })
    }
    getAllTestName();
  },[]);
  useEffect(()=>{
    const testNames = checkedTests.map(test => test.name);
    setTestNames(testNames);
  },[checkedTests]);
  const getBuildPackageDate = async () => {
    await AxiosInstance.get(`${getBuildPackageData}`)
      .then((response) => {
        const data = response;
        if (data && data.testLabCartList && Array.isArray(data.testLabCartList)) {
          const validTestNames = data.testLabCartList
            .filter(item => item.lrid === null) 
            .flatMap(item => item.testName.split(',').map(name => name.trim()));
          const disableTest = data.testLabCartList
            .filter(item => item.lrid !== null) 
            .flatMap(item => item.testName.split(',').map(name => name.trim()));
            setDisableTestNames(disableTest);
          setExtractedTestNames(validTestNames);
        } else {
          // console.log('No valid testLabCartList found in response.');
          setTestNames([]);
        }
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }
  useEffect(() => {
    getBuildPackageDate();
  }, []);

  const handleCheckboxChange = (testName) => {
    const test = testData.find((test) => test.name === testName);
    const isChecked = checkedTests.some((testItem) => testItem.name === testName);
  
    if (isChecked) {
      const updatedCheckedTests = checkedTests.filter((testItem) => testItem.name !== testName);
      setCheckedTests(updatedCheckedTests);
    } else {
      setCheckedTests([...checkedTests, { id: test.id, name: testName }]);
    }
  };
  
  const setBuildPackageDate = async (payloadData) => {
    await AxiosInstance.post(`${setBuildPackageData}`,payloadData)
    .then((response) => {
        toast.success(response.message);
        setCurrentTabIndex(1);
      })
      .catch((err) => {
        console.log('err: ', err);
      });
    }
    useEffect(()=>{
      if(testData.length > 0){
        const validCheckedTests = extractedTestNames.map(name => {
          const test = testData.find(test => test.name === name);
          if (test) {
            return { id: test.id, name: test.name };
          } else {
          // console.warn(`Test not found for name: ${name}`);
          return null;
        }
      }).filter(test => test !== null);
  
      setTestNames(extractedTestNames);
      setCheckedTests(validCheckedTests);
    }
    else{
      // console.log('test data not found')
    }
    },[testData, extractedTestNames])
    const handleSubmit = (event) => {
    event.preventDefault();
    let payloadData = {
      testName: {}
    };
    checkedTests.forEach((test) => {
      payloadData.testName[`${test.id}`] = test.name;
    });
    setBuildPackageDate(payloadData);
  };

  return (
    <div>
      <ToastMessage></ToastMessage>
      {testData.map((test, index) => (
        <span className="popup" key={index} style={{  cursor: 'pointer', display: 'inline-block', width: '25%' }}>
          <input
            type="checkbox"
            id={test.name}
            value={test.name}
            onChange={()=>handleCheckboxChange(test.name)}
            checked={testNames.includes(test.name)}
            disabled={disableTestNames.includes(test.name)}
          />
          <label
            htmlFor={test.name}
            className='m-1'
          >
            {test.name}
          </label>
          {test.description && (
            <BsInfoLg
              className="info-icon"
              onClick={() => handleToggleTooltip(test.performance, test.description, test.name, test.id)}
            />
          )}
          {tooltipVisible === test.name && (
            <div
              className="popuptextpersonal"
              style={{
                width: test.name.includes('profile') ? '450px' : '250px'
              }}
            >
              <span>{tooltipTextDesc}</span>
            </div>
          )}
        </span>
      ))}
      <Button
        sx={{
          background: "#5353e9",
          color: "#fff",
          minWidth: "180px",
          "&:hover": { background: "#c60176" },
          marginTop: "20px"
        }}
        type="submit"
        onClick={handleSubmit}>Submit</Button>
    </div>
  );
};

export default BuildPackage;
