export const tableData = [
  {
    persistentLabel: "Prognosis of CKD by GFR and Albuminuria Categories: KDIGO 2012",
    columnSpan: 4,
    rowsSpan: 4,
  },
  {
    persistentLabel: "A1",
    persistentText: "Normal to mildly increased",
    persistentRange: "< 30 mg/g or < 3 mg/m mol",
    cellClass: "grey-cell",
  },
  {
    persistentLabel: "A2",
    persistentText: "Moderately increased",
    persistentRange: "30-300 mg/g or 3-30 mg/m mol",
    cellClass: "grey-cell",
  },
  {
    persistentLabel: "A3",
    persistentText: "Severely increased",
    persistentRange: "> 300 mg/g or > 30 mg/m mol",
    cellClass: "grey-cell",
  },
];

export const tableData2 = [
  {
    persistentLabel: "G1",
    persistentText: "Normal or high",
    persistentRange: "≥ 90",
    A1: { value: false, color: "#009484" },
    A2: { value: false, color: "#CADB2A" },
    A3: { value: false, color: "#FAA63B" },
  },
  {
    persistentLabel: "G2",
    persistentText: "Mildly decreased",
    persistentRange: "60-89",
    A1: { value: false, color: "#009484" },
    A2: { value: false, color: "#CADB2A" },
    A3: { value: false, color: "#FAA63B" },
  },
  {
    persistentLabel: "G3a",
    persistentText: "Mildly to moderately decreased",
    persistentRange: "45-59",
    A1: { value: false, color: "#CADB2A" },
    A2: { value: false, color: "#FAA63B" },
    A3: { value: false, color: "#F05E22" },
  },
  {
    persistentLabel: "G3b",
    persistentText: "Moderately to severely decreased",
    persistentRange: "30-44",
    A1: { value: false, color: "#FAA63B" },
    A2: { value: false, color: "#F05E22" },
    A3: { value: false, color: "#F05E22" },
  },
  {
    persistentLabel: "G4",
    persistentText: "Severely decreased",
    persistentRange: "15-29",
    A1: { value: false, color: "#F05E22" },
    A2: { value: false, color: "#F05E22" },
    A3: { value: false, color: "#F05E22" },
  },
  {
    persistentLabel: "G5",
    persistentText: "Kidney failure",
    persistentRange: "< 15",
    A1: { value: false, color: "#F05E22" },
    A2: { value: false, color: "#F05E22" },
    A3: { value: false, color: "#F05E22" },
  },
];
