import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getSymptomsMonthly } from "../../constants/apiList";
import SymptomList from "../../shared/SymptomList";
import { monthAbbreviations, usePagination } from "../../util/utils";

const SymptomsCloudGraph = ({ responseData, setMajorSymptomsData, setCurrentMonth }) => {
  const [selectedCommData, setSelectedCommData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState();
  const [symptomsData, setSymptomsData] = useState();

  const { currentPage, totalItems, totalPages, currentData, handleNextPage, handlePreviousPage } = usePagination(symptomsData);

  const handleAxisLabel = (index, selectedRow) => {
    setSelectedCommData(selectedRow.symptomsMonthCumsum);
    setSelectedMonth(selectedRow.monthYear);
    setMajorSymptomsData(selectedRow.symptomsMonthCumsum);
    setCurrentMonth(selectedRow.monthYear);
  };

  const getSymptomsMonthlyData = async () => {
    AxiosInstance.get(`${getSymptomsMonthly}`)
      .then((res) => {
        const modifiedData = res
          .map((item) => ({
            ...item,
            monthYear: `${monthAbbreviations[item.month - 1]}'${String(item.year).slice(2)}`,
            symptomsMonthCumsum: JSON.parse(item.symptomsMonthCumsum.replace(/'/g, '"')),
          }))
          .filter((item) => Object.keys(item.symptomsMonthCumsum).length > 0);
        setSymptomsData(modifiedData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (symptomsData?.length > 0) {
      setSelectedCommData(symptomsData[symptomsData?.length - 1]?.symptomsMonthCumsum);
      setSelectedMonth(symptomsData[symptomsData?.length - 1].monthYear);
      setMajorSymptomsData(symptomsData[symptomsData?.length - 1]?.symptomsMonthCumsum);
      setCurrentMonth(symptomsData[symptomsData?.length - 1].monthYear);
    }
  }, [symptomsData]);

  useEffect(() => {
    getSymptomsMonthlyData();
  }, []);

  return (
    <div className="symptoms-cloud">
      <div className="symptoms-cloud-inner">
        <div className="graph-card-title">
          What kind of Symptoms I have as of (<span className="month-year-as-of">{selectedMonth && selectedMonth}</span>)
        </div>
        <div style={{ width: "100%" }}>
          <SymptomList cumulativeData={selectedCommData} />
        </div>
        <div style={{ margin: "0 auto", width: "100%", display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "end" }}>
          <IconButton
            onClick={handlePreviousPage}
            disabled={currentPage - 1 < 0}
            sx={{ padding: "5px", minWidth: "auto", marginBottom: "15px", fontSize: "10px" }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <div className="month-slider-wrapper">
            <ul className="slider-months">
              {currentData?.map((element, index) => {
                return (
                  <li
                    key={index}
                    className={`month-item ${selectedMonth === element.monthYear ? "active" : ""}`}
                    onClick={() => handleAxisLabel(index, element)}
                  >
                    <Button
                      variant="string"
                      sx={{ padding: 0, minWidth: "auto" }}
                    >
                      {element?.monthYear}
                    </Button>
                  </li>
                );
              })}
            </ul>
          </div>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage + 1 >= totalPages}
            sx={{ padding: "5px", minWidth: "auto", marginBottom: "15px", fontSize: "10px" }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
      <hr className="card-notes-divider" />
      <div className="graph-footer">
        <p className="graph-note">Note: Please click on the month to see the data.</p>
      </div>
    </div>
  );
};

export default React.memo(SymptomsCloudGraph);
