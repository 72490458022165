import Axios from "axios";
import { toast } from "react-toastify";
import { clearCookies, getAccessToken } from "./HandleCookies";
import { getLoginBaseUrl } from "../util/utils";

const AxiosInstance = Axios.create({
  // baseURL: `http://3.110.70.194:8081/`,
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 200000000,
  mode: "no-cors",
  crossDomain: true,
});

AxiosInstance.interceptors.request.use(
  function (config) {
    // let token = getAccessToken();
    let token = window.sessionStorage.getItem("token");
    config.headers["accept"] = "application/json";
    config.headers["Authorization"] = "Bearer " + token;
    return Promise.resolve(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);
AxiosInstance.interceptors.response.use(
  function (response) {
    const { data, status, message } = response?.data;
    if (data === undefined && status == undefined) {
      return Promise.resolve(data);
    }
    if (status == "Success") {
      if (data == null && message !== null) {
        return Promise.resolve(message);
      }
      return Promise.resolve(data);
    } else {
      return Promise.reject(data);
    }
  },
  function (error) {
    if (error?.request?.status == 500) {
      toast.error("Internal server error");
      return Promise.reject();
    } else if (error?.request?.status == 401 || error?.request?.status == 402 || error?.request?.status == 403) {
      toast.error("Session Expired");
      // clearCookies();
      sessionStorage.clear();
      window.location.href = getLoginBaseUrl();
      return Promise.reject();
    } else if (error?.request?.status == 400) {
      const initialURL = sessionStorage.getItem("initialURL");
      if (initialURL) {
        const { data, message, status } = error.response.data;
        if (!!message) {
          if (data != null || data != undefined) {
            console.error(message);
          } else {
            console.error(message);
          }
        }
      } else {
        const { data, message, status } = error.response.data;
        if (!!message) {
          if (data != null || data != undefined) {
            toast.error(message);
          } else {
            toast.error(message);
          }
        }
      }
      return Promise.reject(data);
    }
  }
);
const AxiosInstanceForBlob = Axios.create({
  baseURL: `http://192.168.29.3:8080/`,
  timeout: 200000000,
  mode: "no-cors",
  crossDomain: true,
});

AxiosInstanceForBlob.interceptors.request.use(function (config) {
  // let token = getAccessToken();
  let token = window.sessionStorage.getItem("token");
  (config.method = "GET"), (config.responseType = "blob");
  config.headers["Authorization"] = "Bearer " + token;
  return Promise.resolve(config);
});

const CancelToken = Axios.CancelToken;

export { AxiosInstance, AxiosInstanceForBlob, CancelToken };
