/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconEvents = ({ style, iconEvents = "/img/icon-events-1.png" }) => {
  return <img className="icon-events" style={style} alt="Icon events" src={iconEvents} />;
};

IconEvents.propTypes = {
  iconEvents: PropTypes.string,
};
