import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { vaccineReportsData } from "../../shared/dummyData/vaccineReportsData";
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import MuiDialog from "../../shared/MuiDialog";
import { vaccineNames } from "../../shared/dummyData/vaccineNames";
import { differenceInDays, parse, isValid } from "date-fns";
import Swal from "sweetalert2";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const SpecialSituationSchedule = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isReport, setIsReport] = React.useState(false);
  const [todayDate] = React.useState(new Date().toISOString().split("T")[0]);
  const [birthDate, setbirthDate] = useState();
  const [userAddedVaccineData, setUserAddedVaccineData] = useState([]);
  const [combinedVaccineData, setcombinedVaccineData] = useState([]);

  const [formData, setFormData] = useState({
    vaccineName: "",
    preferredDate: "",
  });

  const handleUploadReport = () => {
    setIsOpen(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file ? file.name : null);
  };

  const handleInputChange = (event) => {
    // console.log("🚀 ~ event:", event);
    // const { name, value, type } = event.target;
    // if (type === "file") {
    //   setSelectedFile(
    //     event.target.files[0] ? event.target.files[0].name : null
    //   );
    // } else if (name === "upload") {
    //   setIsReport(value === "yes");
    // } else {
    //   // Update value using react-hook-form's setValue
    //   setFormData(name, value);
    // }
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleRadioChange = (event) => {
  //   setIsReport(event.target.value === "yes");
  // };

  const onSubmit = () => {
    console.log("formData:", formData);

    const newVaccineReport = {
      id: formData.vaccineName.toLowerCase().replace(/\s/g, "-"), // Generate a simple ID
      entries: [{ vaccine: formData.vaccineName }],
      preferredDate: [formData.preferredDate], // Use birthDate logic or some other default value
      maxDate: "-", // Adjust this as needed
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: "Incomplete",
      fileName: null,
    };

    // Update the vaccine reports data
    setUserAddedVaccineData((prevData) => [...prevData, newVaccineReport]);

    // Reset form data
    setFormData({
      vaccineName: "",
      plannedDate: "",
    });
  };
  const handleBirthDateChange = (event) => {
    setbirthDate(event.target.value);
  };

  const addDuration = (date, years, months, weeks) => {
    const [year, month, day] = date.split("-");
    const newDate = new Date(`${year}-${month}-${day}`);
    newDate.setFullYear(newDate.getFullYear() + years);
    newDate.setMonth(newDate.getMonth() + months);
    newDate.setDate(newDate.getDate() + weeks * 7);

    const formattedDay = String(newDate.getDate()).padStart(2, "0");
    const formattedMonth = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const formattedYear = newDate.getFullYear();

    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  };

  const showPopup = (message) => {
    // Your popup logic, for example:
    // alert(message);
    Swal.fire({
      text: message,
      icon: "success",
    });
  };

  const columnNames = [
    // { id: "age", label: "Age of Vaccination" },
    { id: "vaccine", label: "Vaccine Name" },
    { id: "preferred_date", label: "Preferred Date" },
    { id: "max_date", label: "Catch Up (Max) Date" },
    { id: "to_be_given_date", label: "Planned Date" },
    { id: "given_date", label: "Actual Date" },
    { id: "hospital_name", label: "Hospital Name" },
    { id: "doctor_name", label: "Doctor Name" },
    { id: "attach_report", label: "Attach Report" },
    { id: "status", label: "Status" },
  ];

  useEffect(() => {
    const initialVaccineReportsData = [
      {
        // age: "Birth",
        id: "meninggococcal-(Dose 1)",
        entries: [{ vaccine: "Meninggococcal (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 9, 0)],
        maxDate: [birthDate && addDuration(birthDate, 17, 9, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              htmlFor="meninggococcal (Dose 1)"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "meninggococcal-(Dose 2)",
        entries: [{ vaccine: "Meninggococcal (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="meninggococcal (Dose 2)"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "JE-(Dose 1)",
        entries: [{ vaccine: "JE (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 17, 11, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="JE (Dose 1)"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "JE-(Dose 2)",
        entries: [{ vaccine: "JE (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 1, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="JE (Dose 2)"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "cholera-(Dose 1)",
        entries: [{ vaccine: "Cholera (Dose 1)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 17, 11, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="cholera (Dose 1)"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "cholera-(Dose 2)",
        entries: [{ vaccine: "Cholera (Dose 2)" }],
        preferredDate: [birthDate && addDuration(birthDate, 1, 1, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="cholera (Dose 2)"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "PPSV-23",
        entries: [{ vaccine: "PPSV 23" }],
        preferredDate: [birthDate && addDuration(birthDate, 2, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="PPSV 23"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "rabies",
        entries: [{ vaccine: "Rabies" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 0, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="rabies"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
      {
        // age: "Birth",
        id: "yellowFever",
        entries: [{ vaccine: "Yellow Fever" }],
        preferredDate: [birthDate && addDuration(birthDate, 0, 9, 0)],
        maxDate: [birthDate && addDuration(birthDate, 18, 0, 0)],
        plannedDate: ["", "", ""],
        actualDate: ["", "", ""],
        hospitalName: ["", "", ""],
        doctorName: ["", "", ""],
        attachReport: (
          <span>
            <label
              for="yellowFever"
              style={{
                cursor: "pointer",
                color: "rgb(83, 83, 233)",
                textDecoration: "underline",
                marginLeft: "-10px",
              }}
            >
              Attach Report
            </label>
          </span>
        ),
        status: "Incomplete",
        fileName: null,
      },
    ];
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("-");
      return new Date(`${year}-${month}-${day}`).getTime();
    };

    // Filter out undefined dates and sort the data by preferredDate
    const sortedVaccineReportsData = initialVaccineReportsData
      .filter((report) => report.preferredDate[0])
      .sort(
        (a, b) => parseDate(a.preferredDate[0]) - parseDate(b.preferredDate[0])
      );

    const today = new Date();
    let upcomingVaccines = [];

    initialVaccineReportsData.forEach((report) => {
      report.preferredDate.forEach((date) => {
        if (date) {
          const [day, month, year] = date.split("-");
          const preferredDate = new Date(`${year}-${month}-${day}`);
          const daysDifference = differenceInDays(preferredDate, today);
          if (daysDifference >= 0 && daysDifference <= 7) {
            upcomingVaccines.push({
              vaccine: report.entries.map((entry) => entry.vaccine).join(", "),
              date: date,
            });
          }
        }
      });
    });

    if (upcomingVaccines.length > 0) {
      const vaccineNames = upcomingVaccines
        .map((v) => `${v.vaccine}`)
        .join(", ");
      const earliestDate = upcomingVaccines.reduce((earliest, current) => {
        return parseDate(current.date) < parseDate(earliest.date)
          ? current
          : earliest;
      }).date;
      showPopup(
        `This is a Vaccination Reminder from Uncurl health. Your child Parul is recommended for ${vaccineNames} on ${earliestDate}.Please ensure she receives the vaccine by the recommended date. It is important that your child receives the vaccine on time.
        `
      );
    }
    setcombinedVaccineData(
      birthDate
        ? [...sortedVaccineReportsData, ...userAddedVaccineData]
        : [...initialVaccineReportsData, ...userAddedVaccineData]
    );
  }, [birthDate, userAddedVaccineData]);

  // const handleVaccineChange = (event) => {
  //   setVaccineName(event.target.value);
  // };

  const handleFileSubmit = (event, id) => {
    const file = event.target.files[0];
    const updatedData = combinedVaccineData.map((report) => {
      if (report.id === id) {
        return { ...report, status: "Completed", fileName: file.name };
      }
      return report;
    });
    setcombinedVaccineData(updatedData);
  };

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        if (response) {
          setbirthDate(response?.profile.dob);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div>
            If you want to add new Vaccine then add this by clicking on it
          </div>
          <Button variant="outlined" onClick={handleUploadReport}>
            Add Vaccine
          </Button>
        </div>

        <div>
          Birth Date :
          <input
            type="date"
            value={birthDate}
            onChange={handleBirthDateChange}
          ></input>
        </div>
      </div>
      <MuiDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Add Vaccine"
        onSubmit={onSubmit}
      >
        <form className="mt-1">
          {!isReport && (
            <p>
              Input vaccine name and preferred date for vaccination as mentioned
              by your child's pediatrician.
            </p>
          )}
          <>
            {/* <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="vaccineName">Vaccine Name</InputLabel>
                <Select
                  id="vaccineName"
                  label="Vaccine Name"
                  onChange={handleInputChange}
                  value={formData.vaccineName}
                  {...register("vaccineName")}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {vaccineNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <Select
                  id="vaccineName"
                  label="Vaccine Name"
                  value={formData.vaccineName}
                  onChange={handleInputChange}
                  {...register("vaccineName")}
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Vaccine
                  </MenuItem>
                  {vaccineNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
          </>
          <TextField
            id="vaccineName"
            label="Vaccine Name"
            name="vaccineName"
            type="text"
            {...register("vaccineName")}
            value={formData.vaccineName}
            fullWidth
            onChange={handleInputChange}
            margin="normal"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: todayDate,
            }}
          />
          <TextField
            id="preferredDate"
            label="Preferred Date"
            name="preferredDate"
            type="date"
            {...register("preferredDate")}
            fullWidth
            value={formData.preferredDate}
            onChange={handleInputChange}
            margin="normal"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: todayDate,
            }}
          />
        </form>
      </MuiDialog>
      <TableContainer
        sx={{ marginTop: "30px", border: "1px solid black" }}
        component={Paper}
        className="report-table"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {columnNames.map((column) => (
                <TableCell
                  component="th"
                  key={column.id}
                  align="center"
                  className="border"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedVaccineData.map((row, rowIndex) =>
              row.entries.map((entry, index) => {
                return (
                  <TableRow key={`${rowIndex}-${index}`}>
                    {row.age && index === 0 && (
                      <TableCell
                        scope="row"
                        align="left"
                        rowSpan={row.entries.length}
                        className="border-r border-l"
                      >
                        {row.age}
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.entries[index].vaccine}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.preferredDate[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.maxDate[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      <input type="date"></input>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.actualDate[index]}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.hospitalName[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.doctorName[index]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      <span>
                        {row.fileName ? (
                          row.fileName
                        ) : (
                          <label
                            htmlFor={row.id}
                            style={{
                              cursor: "pointer",
                              color: "rgb(83, 83, 233)",
                              textDecoration: "underline",
                              marginLeft: "-10px",
                            }}
                          >
                            Attach Report
                          </label>
                        )}
                      </span>
                      <div>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .pdf, .png"
                          id={row.id}
                          className="file-upload-input"
                          name={row.id}
                          onChange={(event) => handleFileSubmit(event, row.id)}
                          style={{ display: "none" }}
                          // onClick={(event) => handleFileChange(event)}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`border-r ${
                        index % 2 === 1 ? "odd-row-bg-color" : ""
                      }`}
                    >
                      {row.status}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          // onClick={ReviewAndUnderstood}
          sx={{
            background: "#5353e9",
            color: "#fff",
            minWidth: "180px",
            "&:hover": { background: "#c60176" },
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default SpecialSituationSchedule;
