import React, { useEffect, useState } from "react";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Card, CardBody, CardTitle, CardSubtitle, Button, CardText } from "reactstrap";
import image1 from "./images/dco2.png"
import  image2  from './images/doc1.png';
// import { image3 } from "./images/doctor2.jpeg";

const DoctorsHomeScreen = () => {
 let userDetail = window.sessionStorage.getItem("userDetails");
 userDetail = JSON.parse(userDetail); 
 console.log('userDetail.....: ', userDetail);
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Welcome Dr. {userDetail?.firstName} {userDetail?.lastName} </h2>
      <br />
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Card style={{ width: "40rem", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
          <img src={image1} alt="Lab" />
        </Card>
        <Card style={{ width: "40rem", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
          <img src={image2} alt="Lab" />
        </Card>
      </div>
    </>
  );
};
export default DoctorsHomeScreen;
