import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getAccessToken, getProfile } from "../../configration/HandleCookies";
import ProfilePic from "../../shared/ProfilePic";
import ToastMessage from "../../shared/ToastMessage";
import InfoModel from "../../shared/simpleModel/InfoModel";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import DeleteAccountModal from "../DeleteAccountModal/DeleteAccountModal";
import "./SettingsModal.css";

export const SettingsModal = () => {
  const [profileData, setProfileData] = useState(null);
  const [ChangePasswordInfoModel, setChangePasswordInfoModel] = useState(false);
  const [DeleteAccountInfoModel, setDeleteAccountInfoModel] = useState(false);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [refreshProfile, setRefreshProfile] = useState(false);
  const [initialProfile, setInitialProfile] = useState(false);

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        if (response == null) {
          // const userDetails = JSON.parse(getProfile());
          const userDetails = window.sessionStorage.getItem("userDetails");
          setProfileData(JSON.parse(userDetails));
          setInitialProfile(true);
        } else {
          setProfileData(response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  // let token = getAccessToken();
  let token = window.sessionStorage.getItem("token");
  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const ChangePasswordModel = () => {
    setChangePasswordInfoModel((prev) => !prev);
  };

  const onChangePasswordModelClose = () => {
    setChangePasswordInfoModel((prev) => !prev);
  };

  const onDeleteAccountModel = () => {
    setDeleteAccountInfoModel((prev) => !prev);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    let fileCheck = file?.name?.split(".")?.pop();
    if (fileCheck == "pdf" || fileCheck == "xlsx" || fileCheck == "gif" || fileCheck == "jfif" || fileCheck == "xls") {
      toast.error("PDF, XLS, XLSX and GIF file are not allowed");
    } else {
      setImageDataUrl(file);
      const formData = new FormData();
      formData.append("file", file);

      AxiosInstance.post("profile/upload-profile-pic", formData)
        .then((response) => {
          toast.success("Profile updated successfully");
          setRefreshProfile((prev) => true);
          setInitialProfile((prev) => false);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  return (
    <div className="settings">
      <ToastMessage></ToastMessage>
      <div style={{ textAlign: "center" }}>
        <div className="overlap-14">
          <div className="group-38">
            <ProfilePic
              refreshProfile={refreshProfile}
              profileInformation={profileData}
              initialProfile={initialProfile}
            />

            <div className="rectangle-8" />
            <label htmlFor="upload">
              <img
                className="edit"
                alt="Edit"
                src="/img/edit-1.png"
              />
            </label>
            <input
              type="file"
              id="upload"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="john-smith">
          {!initialProfile
            ? ` ${profileData?.profile?.firstName}  ${profileData?.profile?.middleName ? profileData?.profile?.middleName : ""}  ${
                profileData?.profile?.lastName ? profileData?.profile?.lastName : ""
              } `
            : `${profileData?.firstName}  ${profileData?.lastName ? profileData?.lastName : ""}`}
        </div>
        <div className="john-sm-gmail-com-2">{initialProfile == true ? `${profileData?.email} ` : `${profileData?.profile?.email} `}</div>
        <div className="element-5">{initialProfile == true ? profileData?.phoneNumber : profileData?.profile?.contactNumber}</div>
      </div>

      <div className="group-34">
        <div className="overlap-12">
          <div style={{ padding: "12px", display: "flex", alignItems: "center" }}>
            <img
              className="cake"
              alt="Cake"
              src="/img/cake-1.png"
            />
            <span className="text-wrapper-36">{initialProfile == true ? "----" : profileData?.profile?.dob}</span>
          </div>
          <div style={{ border: "1px solid #E3E3F2" }}></div>
          <div style={{ padding: "12px", display: "flex", alignItems: "center" }}>
            <img
              className="location"
              alt="Location"
              src="/img/location-1.png"
            />
            <span className="text-wrapper-36">{initialProfile == true ? "----" : profileData?.address?.addressLine1}</span>
          </div>
        </div>
      </div>

      <div className="overlap-10">
        <div className="overlap-11">
          <div
            style={{ display: "flex", justifyContent: "space-between", padding: "12px", cursor: "pointer", alignItems: "center" }}
            onClick={ChangePasswordModel}
          >
            <span className="text-wrapper-36">Change Password</span>
            <img
              className="arrow-right"
              alt="Arrow right"
              src="/img/arrow-right-6.png"
            />
          </div>

          <InfoModel
            open={ChangePasswordInfoModel}
            setOpen={setChangePasswordInfoModel}
            heading="Change Password"
          >
            <ChangePasswordModal onChangePasswordModelClose={onChangePasswordModelClose}></ChangePasswordModal>
          </InfoModel>

          <div style={{ border: "1px solid #E3E3F2" }}></div>

          <div
            style={{ display: "flex", justifyContent: "space-between", padding: "12px", cursor: "pointer", alignItems: "center" }}
            onClick={onDeleteAccountModel}
          >
            <span className="text-wrapper-38">Delete Account</span>
            <img
              className="arrow-right-5"
              alt="Arrow right"
              src="/img/arrow-right-6.png"
            />
          </div>

          <InfoModel
            open={DeleteAccountInfoModel}
            setOpen={setDeleteAccountInfoModel}
            heading="Confirm Deletion"
            maxWidth="xs"
          >
            <div className="popup-description">
              <DeleteAccountModal onClose={onDeleteAccountModel}></DeleteAccountModal>
            </div>
          </InfoModel>
        </div>
      </div>
    </div>
  );
};
