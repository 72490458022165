import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import "../../App.css";
import "./SilverScreensProfileCards.css";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import * as Yup from "yup";
import iconAddress from "../../../static/img/icon-address-3.png";
import iconCountry from "../../../static/img/icon-country-3.png";
import iconDate from "../../../static/img/icon-filled-events-3.png";
import iconGender from "../../../static/img/icon-gender-1.png";
import iconMail from "../../../static/img/icon-mail-1.png";
import iconUser from "../../../static/img/icon-user-4.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getProfile } from "../../configration/HandleCookies";
import { strings } from "../../constants/strings";
import indiaFlag from "../../img/india-flag.png";
import Spinner from "../../shared/Spinner";
import { calculateAge } from "../../util/Helper";
import { nameValidation } from "../../util/validations";
import InputField from "./InputField";
import PhoneInputField from "./PhoneInputField";
import SelectField from "./SelectField";
import "./formStyle.css";
import AllergiesList from "./AllergiesList";
import SurgicalHistory from "./SurgicalHistory";
import Immunizations from "./Immunizations";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Swal from "sweetalert2";
import InfoModel from "../../shared/simpleModel/InfoModel";
import InfoIcon from "@mui/icons-material/Info";
import { event } from "react-ga";
import { useNavigate } from "react-router";
import FamilyMemberCard from "./FamilyMemberCard";
import { Input } from "reactstrap";

const SilverStep1 = ({ loading, onBack, fetchPersonalFamilyHistoryData }) => {
  const [presentHealthConcerns, setpresentHealthConcerns] = useState("");
  const [allergiesFlag, setallergiesFlag] = useState(null);
  const [allergies, setAllergies] = useState([]);
  const [surgicalFlag, setsurgicalFlag] = useState(null);
  const [surgeries, setSurgeries] = useState([]);
  const [personalMedicalHistory, setpersonalMedicalHistory] = useState("");
  const [hormoneTherapies, sethormoneTherapies] = useState(null);
  const [firstPregnancy, setfirstPregnancy] = useState("");
  const [gestationalDiabetes, setgestationalDiabetes] = useState(null);
  const [howOftenDoYouUsedPainkillers, sethowOftenDoYouUsedPainkillers] =
    useState("");
  const [nameOfThePainkillers, setnameOfThePainkillers] = useState("");
  const [howLongAreYouTakingPainkillers, sethowLongAreYouTakingPainkillers] =
    useState("");
  const [reproductiveHistory, setreproductiveHistory] = useState("");
  const [intravenousDrugs, setintravenousDrugs] = useState(null);
  const [usedPainKillers, setusedPainKillers] = useState(null);
  const [excessAbdominalWeight, setexcessAbdominalWeight] = useState("");
  const [previouslyReceivedRadiation, setpreviouslyReceivedRadiation] =
    useState("");
  const [otherInput1, setOtherInput1] = useState("");
  const [otherInput2, setOtherInput2] = useState("");
  const [familyData, setFamilyData] = useState({});
  const [selectedTypes, setselectedType] = useState("");
  const [familyDiseasesKnowledge, setfamilyDiseasesKnowledge] = useState(null);
  const [personalMedicalHistorystep, setpersonalMedicalHistorystep] =
    useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [familyRelation, setFamilyRelation] = useState();
  const [familyInfo, setFamilyInfo] = useState();

  // const [FormSubmittedModal, setFormSubmittedModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submittedDate, setSubmittedDate] = useState("");
  const [tooltipText, setTooltipText] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState("");
  const [checkboxVisibility, setcheckboxVisibility] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({});
  const [familyAge, setFamilyAge] = useState();

  const handlenameOfThePainkillersChange = (event) => {
    setnameOfThePainkillers(event.target.value);
  };
  const handleMouseEnter = (text, testName) => {
    setTooltipText(text);
    setTooltipVisible(testName);
  };

  const handleMouseLeave = () => {
    setTooltipVisible("");
  };

  const handlegestationalDiabetesChange = (event) => {
    const value = event.target.value === "true";
    setgestationalDiabetes(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      gestationalDiabetes: false,
    }));
  };
  const navigate = useNavigate();
  const handlehowLongAreYouTakingPainkillersChange = (event) => {
    sethowLongAreYouTakingPainkillers(event.target.value);
  };

  const handlehowOftenDoYouUsedPainkillersChange = (event) => {
    sethowOftenDoYouUsedPainkillers(event.target.value);
  };

  const handlefirstPregnancyChange = (event) => {
    setfirstPregnancy(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      firstPregnancy: false,
    }));
  };

  const handlepreviouslyReceivedRadiationChange = (event) => {
    setpreviouslyReceivedRadiation(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      previouslyReceivedRadiation: false,
    }));
  };

  const handleexcessAbdominalWeightChange = (event) => {
    setexcessAbdominalWeight(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      excessAbdominalWeight: false,
    }));
  };

  const handleusedPainKillersChange = (event) => {
    const value = event.target.value === "true";
    setusedPainKillers(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      usedPainKillers: false,
    }));
  };

  const handleintravenousDrugsChange = (event) => {
    const value = event.target.value === "true";
    setintravenousDrugs(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      intravenousDrugs: false,
    }));
  };

  const handlereproductiveHistoryChange = (event) => {
    setreproductiveHistory(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      reproductiveHistory: false,
    }));
  };

  const handlehormoneTherapiesChange = (event) => {
    const value = event.target.value === "true";
    sethormoneTherapies(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      hormoneTherapies: false,
    }));
  };
  const SilverHandleFamilySubmit = () => {
    let errors = {};
    if (allergiesFlag == null) {
      errors.allergiesFlag = true;
    }
    if (personalMedicalHistory == "") {
      errors.personalMedicalHistory = true;
    }
    if (gender != "male" && hormoneTherapies == null) {
      errors.hormoneTherapies = true;
    }
    if (gender != "male" && firstPregnancy == "") {
      errors.firstPregnancy = true;
    }
    if (gender != "male" && reproductiveHistory == "") {
      errors.reproductiveHistory = true;
    }
    if (gender != "male" && gestationalDiabetes == null) {
      errors.gestationalDiabetes = true;
    }
    if (intravenousDrugs == null) {
      errors.intravenousDrugs = true;
    }
    if (usedPainKillers == null) {
      errors.usedPainKillers = true;
    }
    if (excessAbdominalWeight == "") {
      errors.excessAbdominalWeight = true;
    }
    if (gender != "male" && previouslyReceivedRadiation == "") {
      errors.previouslyReceivedRadiation = true;
    }
    if (surgicalFlag == null) {
      errors.surgicalFlag = true;
    }
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
      if (usedPainKillers === true) {
        if (
          howOftenDoYouUsedPainkillers === "" ||
          howLongAreYouTakingPainkillers === "" ||
          nameOfThePainkillers === ""
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Fill all the Mandatory Fields!",
          });
        }
      }
    } else {
      //  setpersonalMedicalHistorystep((prevActiveStep) => prevActiveStep + 1);

      const membersWithDiseases = {};

      // Iterate through the familyData object
      for (const member in familyData) {
        // Check if the member has diseases, age, and living status
        if (
          familyData[member].diseases !== null &&
          familyData[member].diseases !== "" &&
          familyData[member].age !== null &&
          familyData[member].livingStatus !== null
        ) {
          // If the member has all attributes, add them to the new object
          membersWithDiseases[member] = {
            diseases: familyData[member].diseases,
            age: familyData[member].age,
            livingStatus: familyData[member].livingStatus,
          };
        }
      }
      const formData = {
        presentHealthConcerns: presentHealthConcerns,
        allergiesFlag: allergiesFlag,
        allergies: allergies,
        personalMedicalHistory: personalMedicalHistory,
        hormoneTherapies: hormoneTherapies ? hormoneTherapies : false,
        firstPregnancy: firstPregnancy,
        gestationalDiabetes: gestationalDiabetes ? gestationalDiabetes : false,
        reproductiveHistory: reproductiveHistory,
        intravenousDrugs: intravenousDrugs ? intravenousDrugs : false,
        usedPainKillers: usedPainKillers,
        howOftenDoYouUsedPainkillers: usedPainKillers
          ? howOftenDoYouUsedPainkillers
          : "",
        nameOfThePainkillers: nameOfThePainkillers ? nameOfThePainkillers : "",
        howLongAreYouTakingPainkillers: usedPainKillers
          ? howLongAreYouTakingPainkillers
          : "",
        excessAbdominalWeight: excessAbdominalWeight,
        previouslyReceivedRadiation: previouslyReceivedRadiation,
        surgicalFlag: surgicalFlag,
        surgicalHistory: surgeries,
        familyHistory: membersWithDiseases,
        familyDiseasesKnowledgeFlag: familyDiseasesKnowledge
          ? familyDiseasesKnowledge
          : false,
      };
      const allergiesString = JSON.stringify(formData.allergies);
      const personalMedicalHistoryString = personalMedicalHistory
        ? personalMedicalHistory.join(";")
        : personalMedicalHistory;
      const surgicalHistoryString = JSON.stringify(formData.surgicalHistory);
      const familyHistoryString = JSON.stringify(formData.familyHistory);
      const transformedData = {
        ...formData,
        allergies: allergiesFlag ? allergiesString : null,
        personalMedicalHistory: personalMedicalHistoryString,
        surgicalHistory: surgicalFlag ? surgicalHistoryString : null,
        familyHistory: familyHistoryString,
        familyDiseasesKnowledgeFlag: formData.familyDiseasesKnowledgeFlag,
      };
      setTimeout(() => {
        onBack();
      }, 1000);
      AxiosInstance.post("form/addPersonalFamilyHistory", transformedData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const SilverSubmit = () => {
    let errors = {};
    if (allergiesFlag == null) {
      errors.allergiesFlag = true;
    }
    const membersWithDiseases = {};

    // Iterate through the familyData object
    for (const member in familyData) {
      // Check if the member has diseases, age, and living status
      if (
        familyData[member].diseases !== null &&
        familyData[member].diseases !== "" &&
        familyData[member].age !== null &&
        familyData[member].livingStatus !== null
      ) {
        // If the member has all attributes, add them to the new object
        membersWithDiseases[member] = {
          diseases: familyData[member].diseases,
          age: familyData[member].age,
          livingStatus: familyData[member].livingStatus,
        };
      }
    }
    const formData = {
      presentHealthConcerns: presentHealthConcerns,
      allergiesFlag: allergiesFlag,
      allergies: allergies,
      personalMedicalHistory: personalMedicalHistory,
      hormoneTherapies: hormoneTherapies ? hormoneTherapies : false,
      firstPregnancy: firstPregnancy,
      gestationalDiabetes: gestationalDiabetes ? gestationalDiabetes : false,
      reproductiveHistory: reproductiveHistory,
      intravenousDrugs: intravenousDrugs ? intravenousDrugs : false,
      usedPainKillers: usedPainKillers,
      howOftenDoYouUsedPainkillers: usedPainKillers
        ? howOftenDoYouUsedPainkillers
        : "",
      nameOfThePainkillers: nameOfThePainkillers ? nameOfThePainkillers : "",
      howLongAreYouTakingPainkillers: usedPainKillers
        ? howLongAreYouTakingPainkillers
        : "",
      excessAbdominalWeight: excessAbdominalWeight,
      previouslyReceivedRadiation: previouslyReceivedRadiation,
      surgicalFlag: surgicalFlag,
      surgicalHistory: surgeries,
      familyHistory: membersWithDiseases,
      familyDiseasesKnowledgeFlag: familyDiseasesKnowledge
        ? familyDiseasesKnowledge
        : false,
    };
    const allergiesString = JSON.stringify(formData.allergies);
    const personalMedicalHistoryString = personalMedicalHistory
      ? personalMedicalHistory.join(";")
      : personalMedicalHistory;
    const surgicalHistoryString = JSON.stringify(formData.surgicalHistory);
    const familyHistoryString = JSON.stringify(formData.familyHistory);
    const transformedData = {
      ...formData,
      allergies: allergiesFlag ? allergiesString : null,
      personalMedicalHistory: personalMedicalHistoryString,
      surgicalHistory: surgicalFlag ? surgicalHistoryString : null,
      familyHistory: familyHistoryString,
      familyDiseasesKnowledgeFlag: formData.familyDiseasesKnowledgeFlag,
    };
    AxiosInstance.post("form/addPersonalFamilyHistory", transformedData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your Form Data is Submitted Successfully!",
        });
        setTimeout(() => {
          onBack();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const SilverhandleNext = () => {
    let errors = {};
    if (familyAge?.profile?.age <= 21 && familyAge?.profile?.age >= 3) {
      if (allergiesFlag == null) {
        errors.allergiesFlag = true;
      }
      if (personalMedicalHistory == "") {
        errors.personalMedicalHistory = true;
      }
      if (usedPainKillers == null) {
        errors.usedPainKillers = true;
      }
      if (surgicalFlag == null) {
        errors.surgicalFlag = true;
      }
      if (excessAbdominalWeight == "") {
        errors.excessAbdominalWeight = true;
      }
    }
    if (familyAge?.profile?.age > 21) {
      if (allergiesFlag == null) {
        errors.allergiesFlag = true;
      }
      if (personalMedicalHistory == "") {
        errors.personalMedicalHistory = true;
      }
      if (gender != "male" && hormoneTherapies == null) {
        errors.hormoneTherapies = true;
      }
      if (gender != "male" && firstPregnancy == "") {
        errors.firstPregnancy = true;
      }
      if (gender != "male" && reproductiveHistory == "") {
        errors.reproductiveHistory = true;
      }
      if (gender != "male" && gestationalDiabetes == null) {
        errors.gestationalDiabetes = true;
      }
      if (intravenousDrugs == null) {
        errors.intravenousDrugs = true;
      }
      if (usedPainKillers == null) {
        errors.usedPainKillers = true;
      }
      if (excessAbdominalWeight == "") {
        errors.excessAbdominalWeight = true;
      }
      if (gender != "male" && previouslyReceivedRadiation == "") {
        errors.previouslyReceivedRadiation = true;
      }
      if (surgicalFlag == null) {
        errors.surgicalFlag = true;
      }
    }
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
      if (usedPainKillers === true) {
        if (
          howOftenDoYouUsedPainkillers === "" ||
          howLongAreYouTakingPainkillers === ""
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Fill all the Mandatory Fields!",
          });
        }
      }
    } else {
      setpersonalMedicalHistorystep((prevActiveStep) => prevActiveStep + 1);
      const membersWithDiseases = {};

      // Iterate through the familyData object
      for (const member in familyData) {
        // Check if the member has diseases, age, and living status
        if (
          familyData[member].diseases !== null &&
          familyData[member].diseases !== "" &&
          familyData[member].age !== null &&
          familyData[member].livingStatus !== null
        ) {
          // If the member has all attributes, add them to the new object
          membersWithDiseases[member] = {
            diseases: familyData[member].diseases,
            age: familyData[member].age,
            livingStatus: familyData[member].livingStatus,
          };
        }
      }
      const formData = {
        presentHealthConcerns: presentHealthConcerns,
        allergiesFlag: allergiesFlag,
        allergies: allergies,
        personalMedicalHistory: personalMedicalHistory,
        hormoneTherapies: hormoneTherapies ? hormoneTherapies : false,
        firstPregnancy: firstPregnancy,
        gestationalDiabetes: gestationalDiabetes ? gestationalDiabetes : false,
        reproductiveHistory: reproductiveHistory,
        intravenousDrugs: intravenousDrugs ? intravenousDrugs : false,
        usedPainKillers: usedPainKillers,
        howOftenDoYouUsedPainkillers: usedPainKillers
          ? howOftenDoYouUsedPainkillers
          : "",
        nameOfThePainkillers: nameOfThePainkillers ? nameOfThePainkillers : "",
        howLongAreYouTakingPainkillers: usedPainKillers
          ? howLongAreYouTakingPainkillers
          : "",
        excessAbdominalWeight: excessAbdominalWeight,
        previouslyReceivedRadiation: previouslyReceivedRadiation,
        surgicalFlag: surgicalFlag,
        surgicalHistory: surgeries,
        familyHistory: membersWithDiseases,
        familyDiseasesKnowledgeFlag: familyDiseasesKnowledge
          ? familyDiseasesKnowledge
          : false,
      };
      const allergiesString = JSON.stringify(formData.allergies);
      const personalMedicalHistoryString = personalMedicalHistory
        ? personalMedicalHistory.join(";")
        : personalMedicalHistory;
      const surgicalHistoryString = JSON.stringify(formData.surgicalHistory);
      const familyHistoryString = JSON.stringify(formData.familyHistory);
      const transformedData = {
        ...formData,
        allergies: allergiesFlag ? allergiesString : null,
        personalMedicalHistory: personalMedicalHistoryString,
        surgicalHistory: surgicalFlag ? surgicalHistoryString : null,
        familyHistory: familyHistoryString,
        familyDiseasesKnowledgeFlag: formData.familyDiseasesKnowledgeFlag,
      };
      AxiosInstance.post("form/addPersonalFamilyHistory", transformedData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const SilverhandleBack = () => {
    setpersonalMedicalHistorystep((prevActiveStep) => prevActiveStep - 1);
  };

  const validationSchema = Yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control, formState, getValues, setValue, reset } =
    methods;
  const { errors } = formState;

  const onSubmit = () => {
    if (Object.keys(familyData).length === 0 && !familyDiseasesKnowledge) {
      setErrorMessage(
        "Please Confirm That your family does not have any of the above above diseases "
      );
      return;
    }
    // for (const member in familyData) {
    //   if (familyData[member].diseases != "" && familyDiseasesKnowledge) {
    //     setErrorMessage(
    //       "Please Unchecked the checkbox because your family member have disease"
    //     );
    //     return;
    //   }
    // }

    let hasDisease = false;
    for (const member in familyData) {
      if (familyData[member].diseases !== "") {
        hasDisease = true;
        break;
      }
    }
    if (!hasDisease && !familyDiseasesKnowledge) {
      setErrorMessage(
        "Please Confirm That your family does not have any of the above diseases "
      );
      return;
    }

    const membersWithDiseases = {};
    for (const member in familyData) {
      if (
        familyData[member].diseases !== null &&
        familyData[member].diseases !== "" &&
        familyData[member].age !== null &&
        familyData[member].age !== "" &&
        familyData[member].livingStatus !== null &&
        familyData[member].livingStatus !== ""
      ) {
        if (!familyData[member].age || !familyData[member].livingStatus) {
          setErrorMessage(
            "Please add Age and Living Status of Person with Disease"
          );
          Swal.fire({
            icon: "error",
            title: "error",
            text: "Please add Age and Living Status of Person with Disease",
          });
          return;
        } else {
          membersWithDiseases[member] = {
            diseases: familyData[member].diseases,
            age: familyData[member].age,
            livingStatus: familyData[member].livingStatus,
          };
        }
      }
    }
    for (const member in familyData) {
      if (
        (familyData[member].diseases == null ||
          familyData[member].diseases == "") &&
        (familyData[member].age !== "" ||
          familyData[member].livingStatus !== "")
      ) {
        Swal.fire({
          icon: "error",
          title: "error",
          text: "Please add Disease or remove other Fields",
        });
        return;
      }
    }
    const formData = {
      presentHealthConcerns: presentHealthConcerns,
      allergiesFlag: allergiesFlag,
      allergies: allergies,
      personalMedicalHistory: personalMedicalHistory,
      hormoneTherapies: hormoneTherapies ? hormoneTherapies : false,
      gestationalDiabetes: gestationalDiabetes ? gestationalDiabetes : false,
      firstPregnancy: firstPregnancy,
      reproductiveHistory: reproductiveHistory,
      intravenousDrugs: intravenousDrugs ? intravenousDrugs : false,
      usedPainKillers: usedPainKillers,
      howOftenDoYouUsedPainkillers: usedPainKillers
        ? howOftenDoYouUsedPainkillers
        : "",
      nameOfThePainkillers: nameOfThePainkillers ? nameOfThePainkillers : "",
      howLongAreYouTakingPainkillers: usedPainKillers
        ? howLongAreYouTakingPainkillers
        : "",
      excessAbdominalWeight: excessAbdominalWeight,
      previouslyReceivedRadiation: previouslyReceivedRadiation,
      surgicalFlag: surgicalFlag,
      surgicalHistory: surgeries,
      familyHistory: membersWithDiseases,
      familyDiseasesKnowledgeFlag: familyDiseasesKnowledge
        ? familyDiseasesKnowledge
        : false,
    };
    const allergiesString = JSON.stringify(formData.allergies);

    const personalMedicalHistoryString = personalMedicalHistory
      ? personalMedicalHistory.join(";")
      : personalMedicalHistory;
    const surgicalHistoryString = JSON.stringify(formData.surgicalHistory);
    const familyHistoryString = JSON.stringify(formData.familyHistory);
    const transformedData = {
      ...formData,
      allergies: allergiesFlag ? allergiesString : null,
      personalMedicalHistory: personalMedicalHistoryString,
      surgicalHistory: surgicalFlag ? surgicalHistoryString : null,
      familyHistory: familyHistoryString,
      familyDiseasesKnowledgeFlag: formData.familyDiseasesKnowledgeFlag,
    };
    AxiosInstance.post("form/addPersonalFamilyHistory", transformedData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your Form Data is Submitted Successfully!",
        });
        setTimeout(() => {
          onBack();
        }, 1000);
        fetchPersonalFamilyHistoryData();
      })
      .catch((error) => {
        console.log(error);
      });
    reset();
  };

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setGender(response.profile.gender);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  let lastMatch;
  let value;
  useEffect(() => {
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        const match =
          response.personalMedicalHistory.match(/otherValue:([^;]+)/g);
        if (match) {
          lastMatch = match[match.length - 1];
          value = lastMatch.slice(11);
          setIsCheckboxChecked(true);
        } else {
          console.log("No value found");
        }
        setOtherInput2(value);
        setpresentHealthConcerns(
          response?.presentHealthConcerns
            ? response.presentHealthConcerns
            : presentHealthConcerns
        );
        setallergiesFlag(
          response?.allergiesFlag !== null
            ? response.allergiesFlag
            : allergiesFlag
        );
        setAllergies(
          response?.allergies ? JSON.parse(response.allergies) : allergies
        );
        setpersonalMedicalHistory(
          response?.personalMedicalHistory
            ? response.personalMedicalHistory.split(";")
            : personalMedicalHistory
        );
        sethormoneTherapies(
          response?.hormoneTherapies !== null
            ? response.hormoneTherapies
            : hormoneTherapies
        );
        setfirstPregnancy(
          response?.firstPregnancy ? response.firstPregnancy : firstPregnancy
        );
        setgestationalDiabetes(
          response?.gestationalDiabetes !== null
            ? response.gestationalDiabetes
            : gestationalDiabetes
        );
        setreproductiveHistory(
          response?.reproductiveHistory
            ? response.reproductiveHistory
            : reproductiveHistory
        );
        setintravenousDrugs(
          response?.intravenousDrugs !== null
            ? response.intravenousDrugs
            : intravenousDrugs
        );
        setusedPainKillers(
          response?.usedPainKillers !== null
            ? response.usedPainKillers
            : usedPainKillers
        );
        setnameOfThePainkillers(
          response?.nameOfThePainkillers
            ? response.nameOfThePainkillers
            : nameOfThePainkillers
        );
        sethowOftenDoYouUsedPainkillers(
          response?.howOftenDoYouUsedPainkillers
            ? response.howOftenDoYouUsedPainkillers
            : howOftenDoYouUsedPainkillers
        );
        sethowLongAreYouTakingPainkillers(
          response?.howLongAreYouTakingPainkillers
            ? response.howLongAreYouTakingPainkillers
            : howLongAreYouTakingPainkillers
        );
        setexcessAbdominalWeight(
          response?.excessAbdominalWeight
            ? response.excessAbdominalWeight
            : excessAbdominalWeight
        );
        setpreviouslyReceivedRadiation(
          response?.previouslyReceivedRadiation
            ? response.previouslyReceivedRadiation
            : previouslyReceivedRadiation
        );
        setsurgicalFlag(
          response?.surgicalFlag !== null ? response.surgicalFlag : surgicalFlag
        );
        setSurgeries(
          response?.surgicalHistory
            ? JSON.parse(response.surgicalHistory)
            : surgeries
        );
        setFamilyData(
          response?.familyHistory
            ? JSON.parse(response.familyHistory)
            : familyData
        );
        setfamilyDiseasesKnowledge(
          response?.familyDiseasesKnowledgeFlag
            ? response.familyDiseasesKnowledgeFlag
            : familyDiseasesKnowledge
        );

        const parts = response?.createdOn.split("-");
        setSubmittedDate(`${parts[2]}-${parts[1]}-${parts[0]}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handlefamilyDiseasesKnowledgeChange = (event) => {
    setfamilyDiseasesKnowledge(event.target.checked);
    setErrorMessage("");
    if (!familyDiseasesKnowledge) {
      setFamilyData("");
    }
  };

  const handlepresentHealthConcernsChange = (event) => {
    setpresentHealthConcerns(event.target.value);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value === "true";
    setallergiesFlag(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      allergiesFlag: false,
    }));
  };

  const handleRadioSurgeryChange = (event) => {
    const value = event.target.value === "true";
    setsurgicalFlag(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      surgicalFlag: false,
    }));
  };

  const handleAllergiesChange = (updatedAllergies) => {
    setAllergies(updatedAllergies);
  };

  const handleSurgeriesChange = (updatedSurgeries) => {
    setSurgeries(updatedSurgeries);
  };

  const handleCheckboxChange = (event) => {
    // const value = event.target.value;
    // console.log("value", value);
    // if (event.target.value == "None") {
    //   setpersonalMedicalHistory(["None"]);
    // } else {
    //   if (event.target.checked == false) {
    //     setpersonalMedicalHistory((prevMembers) => {
    //       const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    //       const regex = new RegExp(escapedValue);
    //       if (prevMembers.some((member) => regex.test(member))) {
    //         return prevMembers.filter((member) => !regex.test(member));
    //       } else {
    //         return [...prevMembers, value];
    //       }
    //     });
    //     setFieldErrors((prevErrors) => ({
    //       ...prevErrors,
    //       personalMedicalHistory: false,
    //     }));
    //   } else {
    //     Swal.fire({
    //       title: "",
    //       html: `
    //     <div>
    //       <b>When was this Diseases ?</b><br><br>
    //       <label><input type="radio" name="diseaseStatus" value="past"> Past</label> &nbsp;&nbsp;
    //       <label><input type="radio" name="diseaseStatus" value="present"> Present</label>
    //     </div>
    //   `,
    //       preConfirm: () => {
    //         const status = document.querySelector(
    //           'input[name="diseaseStatus"]:checked'
    //         ).value;
    //         if (status === "past") {
    //           // Show calendar for selecting month and year
    //           const today = new Date();
    //           const year = today.getFullYear();
    //           const month = String(today.getMonth() + 1).padStart(2, "0");
    //           const maxDate = `${year}-${month}`;
    //           return Swal.fire({
    //             title: "Select Month and Year",
    //             html: `
    //         <input type="month" id="monthYear" class="swal2-input" max="${maxDate}">
    //         `,
    //             preConfirm: () => {
    //               const monthYearInput =
    //                 document.getElementById("monthYear").value;
    //               if (!monthYearInput) {
    //                 Swal.showValidationMessage(
    //                   "Please select a month and year"
    //                 );
    //                 return;
    //               }
    //               return monthYearInput;
    //             },
    //           }).then((result) => {
    //             const selectedMonthYear = result.value;
    //             return `${value}(past: ${selectedMonthYear})`;
    //           });
    //         } else {
    //           return `${value}(present)`;
    //         }
    //       },
    //       showCancelButton: true,
    //       didOpen: () => {
    //         Swal.getConfirmButton().setAttribute("disabled", "true");
    //         document
    //           .querySelectorAll('input[name="diseaseStatus"]')
    //           .forEach((radio) => {
    //             radio.addEventListener("change", () => {
    //               if (radio.checked) {
    //                 Swal.getConfirmButton().removeAttribute("disabled");
    //               }
    //             });
    //           });
    //       },
    //     }).then((result) => {
    //       setpersonalMedicalHistory((prevMembers) => {
    //         const updatedValue = result.value;
    //         if (prevMembers.includes(updatedValue)) {
    //           return prevMembers.filter((member) => member !== updatedValue);
    //         } else {
    //           return [...prevMembers, updatedValue];
    //         }
    //       });
    //       setFieldErrors((prevErrors) => ({
    //         ...prevErrors,
    //         personalMedicalHistory: false,
    //       }));
    //     });
    //   }
    // }
    // setFieldErrors((prevErrors) => ({
    //   ...prevErrors,
    //   personalMedicalHistory: false,
    // }));
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (value === "None") {
      if (isChecked) {
        setpersonalMedicalHistory(["None"]);
        setIsCheckboxChecked(false);
        setOtherInput2("");
      } else {
        setpersonalMedicalHistory([]);
      }
    } else {
      setpersonalMedicalHistory((prevMembers) => {
        if (!Array.isArray(prevMembers)) {
          prevMembers = []; // or some other default value
        }
        const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue);
        let newMembers = prevMembers;

        if (isChecked) {
          newMembers = prevMembers.filter((member) => member !== "None");
          Swal.fire({
            title: "",
            html: `
                        <div>
                          <b>When was this disease?</b><br><br>
                          <label><input type="radio" name="diseaseStatus" value="past"> Past</label> &nbsp;&nbsp;
                          <label><input type="radio" name="diseaseStatus" value="present"> Present</label>
                        </div>
                    `,
            preConfirm: () => {
              const status = document.querySelector(
                'input[name="diseaseStatus"]:checked'
              ).value;
              if (status === "past") {
                // Show calendar for selecting month and year
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, "0");
                const maxDate = `${year}-${month}`;
                return Swal.fire({
                  title: "Select Month and Year",
                  html: `
                                    <input type="month" id="monthYear" class="swal2-input" max="${maxDate}">
                                `,
                  preConfirm: () => {
                    const monthYearInput =
                      document.getElementById("monthYear").value;
                    if (!monthYearInput) {
                      Swal.showValidationMessage(
                        "Please select a month and year"
                      );
                      return;
                    }
                    return monthYearInput;
                  },
                }).then((result) => {
                  const selectedMonthYear = result.value;
                  return `${value}(past: ${selectedMonthYear})`;
                });
              } else {
                return `${value}(present)`;
              }
            },
            showCancelButton: true,
            didOpen: () => {
              Swal.getConfirmButton().setAttribute("disabled", "true");
              document
                .querySelectorAll('input[name="diseaseStatus"]')
                .forEach((radio) => {
                  radio.addEventListener("change", () => {
                    if (radio.checked) {
                      Swal.getConfirmButton().removeAttribute("disabled");
                    }
                  });
                });
            },
          }).then((result) => {
            if (result.isConfirmed) {
              const updatedValue = result.value;
              setpersonalMedicalHistory((prevMembers) => {
                const newList = [...prevMembers, updatedValue];
                return newList.filter((member) => member !== "None");
              });
            }
          });
        } else {
          newMembers = prevMembers.filter((member) => !regex.test(member));
        }
        return newMembers;
      });
    }
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      personalMedicalHistory: false,
    }));
  };

  const handleLivingStatusChange = (name, livingStatus) => {
    setFamilyData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        livingStatus: livingStatus,
      },
    }));
    setErrorMessage("");
  };

  const handleAgeChange = (name, age) => {
    setFamilyData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        age: age,
      },
    }));
    setErrorMessage("");
  };
  let selectedValue = "";
  let otherValue = "";
  const handleDiseaseChange = (name, disease) => {
    const isAlreadySelected = familyData[name]?.diseases?.includes(disease);
    if (!isAlreadySelected) {
      if (
        disease == "Cancer" &&
        (name == "Mother" ||
          name == "Daughter" ||
          name == "Maternal Grandmother" ||
          name == "Paternal Grandmother" ||
          name == "Sister")
      ) {
        const cancerTypes = [
          "Lung Cancer",
          "Cervical Cancer",
          "Breast Cancer",
          "Prostrate Cancer",
          "Colorectal Cancer",
        ];

        const checkboxesHTML =
          cancerTypes
            .map((type) => {
              return `<input type="checkbox" id="${type.replace(
                /\s+/g,
                ""
              )}" value="${type}"><label for="${type.replace(
                /\s+/g,
                ""
              )}">${type}</label><br>`;
            })
            .join("") +
          `<br> Other : <input type="text" id="other" placeholder="Other"><br>`;
        Swal.fire({
          title: "Which type of Cancer?",
          text: "Select one or more types",
          html: checkboxesHTML,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Submit",
          didOpen: () => {
            const submitButton = Swal.getConfirmButton();
            submitButton.disabled = true;
            const checkboxes = document.querySelectorAll(
              'input[type="checkbox"]'
            );
            checkboxes.forEach((checkbox) => {
              checkbox.addEventListener("change", () => {
                const anyChecked = Array.from(checkboxes).some(
                  (checkbox) => checkbox.checked
                );
                submitButton.disabled = !anyChecked;
              });
            });
            const otherInput = document.getElementById("other");
            otherInput.addEventListener("input", () => {
              submitButton.disabled = otherInput.value.trim() === "";
            });
            submitButton.addEventListener("click", () => {
              otherValue = otherInput.value.trim();
              if (otherValue) {
                selectedValue = otherValue;
              } else {
                selectedValue = "";
              }
              const selectedTypes = cancerTypes.filter(
                (type) =>
                  document.getElementById(type.replace(/\s+/g, "")).checked
              );
              selectedTypes.push(otherValue);
              if (selectedTypes.length > 0) {
                selectedValue += selectedTypes.join(":");
              }
            });
          },
          preConfirm: () => {
            const otherInput = document.getElementById("other");
            otherValue = otherInput.value.trim();
            const selectedTypes = cancerTypes.filter(
              (type) =>
                document.getElementById(type.replace(/\s+/g, "")).checked
            );
            selectedTypes.push(otherValue);
            return selectedTypes;
          },
        }).then((result) => {
          if (result.isConfirmed) {
            selectedValue = result.value.join(":");
            setFamilyData((prevState) => {
              const updatedDiseases = prevState[name]?.diseases || "";
              let newDiseases;
              if (updatedDiseases.includes(disease)) {
                newDiseases = updatedDiseases
                  .split(";")
                  .filter((item) => item !== disease)
                  .join(";");
              } else {
                // If the disease is not present, add it
                newDiseases = updatedDiseases
                  ? `${updatedDiseases};${disease}`
                  : disease;
              }
              if (disease === "Cancer") {
                newDiseases += `;Cancer:(${selectedValue})`;
              }
              return {
                ...prevState,
                [name]: {
                  ...prevState[name],
                  diseases: newDiseases,
                },
              };
            });
          } else {
            Swal.fire({
              title: "Information",
              text: "Please uncheck the Cancer Option",
              icon: "info",
            });
          }
        });
      } else if (
        disease == "Cancer" &&
        (name == "Father" ||
          name == "Brother" ||
          name == "Son" ||
          name == "Maternal GrandFather" ||
          name == "Paternal GrandFather	")
      ) {
        const cancerTypes = [
          "Lung Cancer",
          "Prostrate cancer",
          "Colorectal Cancer",
        ];
        const checkboxesHTML =
          cancerTypes
            .map((type) => {
              return `<input type="checkbox" id="${type.replace(
                /\s+/g,
                ""
              )}" value="${type}"><label for="${type.replace(
                /\s+/g,
                ""
              )}">${type}</label><br>`;
            })
            .join("") +
          `<br> Other : <input type="text" id="other" placeholder="Other"><br>`;
        Swal.fire({
          title: "Which type of Cancer?",
          text: "Select one or more types",
          html: checkboxesHTML,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Submit",
          didOpen: () => {
            const submitButton = Swal.getConfirmButton();
            submitButton.disabled = true;
            const checkboxes = document.querySelectorAll(
              'input[type="checkbox"]'
            );
            checkboxes.forEach((checkbox) => {
              checkbox.addEventListener("change", () => {
                const anyChecked = Array.from(checkboxes).some(
                  (checkbox) => checkbox.checked
                );
                submitButton.disabled = !anyChecked;
              });
            });
            const otherInput = document.getElementById("other");
            otherInput.addEventListener("input", () => {
              submitButton.disabled = otherInput.value.trim() === "";
            });
            submitButton.addEventListener("click", () => {
              otherValue = otherInput.value.trim();
              if (otherValue) {
                selectedValue = otherValue;
              } else {
                selectedValue = "";
              }
              const selectedTypes = cancerTypes.filter(
                (type) =>
                  document.getElementById(type.replace(/\s+/g, "")).checked
              );
              selectedTypes.push(otherValue);
              if (selectedTypes.length > 0) {
                selectedValue += selectedTypes.join(":");
              }
            });
          },
          preConfirm: () => {
            const otherInput = document.getElementById("other");
            otherValue = otherInput.value.trim();
            const selectedTypes = cancerTypes.filter(
              (type) =>
                document.getElementById(type.replace(/\s+/g, "")).checked
            );
            selectedTypes.push(otherValue);
            return selectedTypes;
          },
        }).then((result) => {
          if (result.isConfirmed) {
            let selectedValue12 = result.value.join(":");
            setFamilyData((prevState) => {
              const updatedDiseases = prevState[name]?.diseases || "";
              let newDiseases;
              if (updatedDiseases.includes(disease)) {
                newDiseases = updatedDiseases
                  .split(";")
                  .filter((item) => item !== disease)
                  .join(";");
              } else {
                // If the disease is not present, add it
                newDiseases = updatedDiseases
                  ? `${updatedDiseases};${disease}`
                  : disease;
              }
              if (disease === "Cancer") {
                newDiseases += `;Cancer:(${selectedValue12})`;
              }
              return {
                ...prevState,
                [name]: {
                  ...prevState[name],
                  diseases: newDiseases,
                },
              };
            });
          }
        });
      }
    }
    setFamilyData((prevState) => {
      const updatedMember = { ...prevState[name] };
      let currentDiseases = updatedMember.diseases || "";

      // if (
      //   currentDiseases.includes(disease) ||
      //   currentDiseases.includes(`${disease}:`)
      // ) {
      //   // Remove the disease from the list if already present
      //   const newDiseases = currentDiseases
      //     .split(";")
      //     .filter((item) => item !== disease && !item.startsWith(`${disease}:`))
      //     .join(";");
      //   updatedMember.diseases = newDiseases;
      // } else {
      //   updatedMember.diseases = currentDiseases
      //     ? `${currentDiseases};${disease}`
      //     : disease;
      // }

      // return {
      //   ...prevState,
      //   [name]: updatedMember,
      // };
      if (disease === "No Disease") {
        if (currentDiseases.includes("No Disease")) {
          // Remove "No Disease" if already present
          currentDiseases = "";
        } else {
          // Set "No Disease" and remove all other diseases
          currentDiseases = "No Disease";
        }
      } else {
        if (currentDiseases.includes(disease)) {
          // Remove the disease if already present
          currentDiseases = currentDiseases
            .split(";")
            .filter(
              (item) => item !== disease && !item.startsWith(`${disease}:`)
            )
            .join(";");
        } else {
          // Add the disease to the list if not already present
          currentDiseases = currentDiseases
            ? `${currentDiseases};${disease}`
            : disease;
        }

        // Remove "No Disease" if another disease is selected
        if (currentDiseases.includes("No Disease")) {
          currentDiseases = currentDiseases
            .split(";")
            .filter((item) => item !== "No Disease")
            .join(";");
        }
      }

      updatedMember.diseases = currentDiseases;

      return {
        ...prevState,
        [name]: updatedMember,
      };
    });
  };

  const FamilyNames = [
    "Mother",
    "Father",
    "Brother",
    "Sister",
    "Son",
    "Daughter",
    "Maternal Grandmother",
    "Maternal GrandFather",
    "Paternal Grandmother",
    "Paternal GrandFather",
  ];
  const FamilyNamesAccordingAge = [
    "Mother",
    "Father",
    "Brother",
    "Sister",
    "Maternal Grandmother",
    "Maternal GrandFather",
    "Paternal Grandmother",
    "Paternal GrandFather",
  ];
  const diseaseOptions = [
    "No Disease",
    "COVID-19 Positive",
    "Asthma",
    "Diabetes",
    "Hypertension",
    "Cardiac",
    "Stroke",
    "Lungs/COPD",
    "Cancer",
    "Colon Polyps",
    "Kidney Disease",
    "Depression",
  ];

  const handleInputChange1 = (event) => {
    const value = event.target.value;
    setOtherInput1(value);
    setpersonalMedicalHistory((prevMembers) => {
      const updatedArray = [...prevMembers.slice(0, -2), value, otherInput2];
      return updatedArray;
    });
  };

  const handleInputChange2 = (event) => {
    const value = event.target.value;
    setOtherInput2(value);
  };
  const handleInputBlur = () => {
    // const trimmedValue = otherInput2.trim();
    // if (trimmedValue !== "" && !personalMedicalHistory.includes(trimmedValue)) {
    //   setpersonalMedicalHistory([
    //     ...personalMedicalHistory,
    //     "otherValue:" + trimmedValue,
    //   ]);
    // }
    const trimmedValue = otherInput2.trim();
    if (trimmedValue !== "") {
      setpersonalMedicalHistory((prevMembers) => {
        // Remove existing "otherValue:" entries
        const filteredMembers = prevMembers.filter(
          (member) => !member.startsWith("otherValue:")
        );
        return [...filteredMembers, "otherValue:" + trimmedValue].filter(
          (member) => member !== "None"
        );
      });
    }
  };
  const CancerInfo = (event) => {
    const regex = /Cancer \(Malignancy\):\s*(.+)/g;
    const matchedDiseases = [];
    let match;
    while ((match = regex.exec(personalMedicalHistory)) !== null) {
      matchedDiseases.push(match[1].trim());
    }
    const matchedDiseasesString = matchedDiseases.join(": ");
    Swal.fire({
      title: "Information",
      text: matchedDiseasesString,
      icon: "info",
    });
  };

  const familyCancerInfo = (familyMemberName) => {
    const memberData = familyData[familyMemberName];
    const diseasesString = memberData.diseases;
    const cancerSubstring = diseasesString.match(/Cancer:\((.*?)\)/);
    const cancerTypes = [];

    if (cancerSubstring) {
      const cancers = cancerSubstring[1].split(":");
      cancerTypes.push(
        ...cancers
          .filter((disease) => disease.toLowerCase())
          .map((cancer) => cancer.trim())
      );
    }
    Swal.fire({
      title: "Cancer Information",
      html: `<p>${cancerTypes.join("<br>")}</p>`,
      icon: "info",
    });
  };

  useEffect(() => {
    const hasDiseases = Object.values(familyData).some((member) => {
      return member.diseases !== null && member.diseases !== "";
    });
    setcheckboxVisibility(!hasDiseases);
  }, [familyData]);

  let prevFamilyData = { ...familyData };

  const handleCheckboxChange1 = (event) => {
    // setIsCheckboxChecked(event.target.checked);
    const isChecked = event.target.checked;

    setIsCheckboxChecked(isChecked);

    if (isChecked) {
      setpersonalMedicalHistory((prevMembers) => {
        return prevMembers.filter((member) => member !== "None");
      });
    } else {
      // Remove the "Other" value
      setpersonalMedicalHistory((prevMembers) => {
        return prevMembers.filter(
          (member) => !member.startsWith("otherValue:")
        );
      });
      // Optionally, clear the input value for "Other"
      setOtherInput2(""); // Ensure you have a state to handle `otherInput2`
    }

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      personalMedicalHistory: false,
    }));
  };

  const getRelationship = () => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        console.log("responseFamily: ", response);
        setFamilyRelation(response?.relationShipWithUser);
        setFamilyInfo(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getRelationship();
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        setFamilyAge(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className="profile_section">
      <Grid container spacing={2} mb={5} justifyContent="space-between">
        <Grid item>
          {familyRelation != "wife" && familyRelation != null && (
            <>
              <h4 style={{ color: "blue" }}>
                The health Insights/Information presented below is for your
                child{" "}
                <span style={{ fontSize: "xx-large" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>
              </h4>
              <h4 style={{ color: "blue" }}>
                Please fill this form for your child{" "}
                <span style={{ fontSize: "xx-large" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>
              </h4>
              <br />
            </>
          )}
          {familyInfo?.accessNeeded == true ? (
            <span style={{ color: "blue", fontSize: "x-large" }}>
              Note : Ask
              <span style={{ fontSize: "larger" }}>
                {familyInfo?.firstName &&
                  familyInfo.firstName.charAt(0).toUpperCase() +
                    familyInfo.firstName.slice(1).toLowerCase()}
              </span>
              to fill this form.
            </span>
          ) : (
            ""
          )}
          {/* <div className="register_title" style={{ marginTop: "15px" }}>
            <h2>Registration</h2>
            <h5>Tier 2</h5>
          </div> */}
        </Grid>
        {submittedDate && (
          <Grid item>
            <div className="date-container register-date">
              <div className="date-label">
                <label htmlFor="date">Date Completed:</label>
              </div>
              <div className="date-input">
                <span>{submittedDate}</span>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {isLoading && (
        <div className="loaderSpinner">
          <Spinner />
        </div>
      )}
      <FormProvider {...methods}>
        {!isLoading && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="registration_tier1"
          >
            {personalMedicalHistorystep == 0 && (
              <>
                {familyAge?.profile?.age <= 21 &&
                familyAge?.profile?.age >= 3 ? (
                  ""
                ) : (
                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} md={12}>
                      <InputField
                        name=""
                        label="Present Health Concerns (If Any)"
                        control={control}
                        value={presentHealthConcerns}
                        onChange={handlepresentHealthConcernsChange}
                        //   placeholder="First Name"
                        className={`inputField`}
                      />
                    </Grid>
                  </Grid>
                )}
                <AllergiesList
                  Allergies={allergies}
                  onUpdateAllergies={handleAllergiesChange}
                  selectedOption={allergiesFlag}
                  handleRadioChange={handleRadioChange}
                  fieldError={fieldErrors}
                ></AllergiesList>
                {(familyAge?.profile?.age >= 3 || familyInfo == null) && (
                  <>
                    <div className="health_Service_Providers_Information">
                      <div className="info_header_wrapper">
                        <div className="info_provider_content">
                          <h3>Personal Medical History</h3>
                          <p
                            style={{
                              color: fieldErrors.personalMedicalHistory
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {familyAge?.profile?.age <= 21 &&
                            familyAge?.profile?.age >= 3 ? (
                              <span>
                                Please indicate whether your child{" "}
                                <span style={{ fontSize: "larger" }}>
                                  {familyInfo?.firstName &&
                                    familyInfo.firstName
                                      .charAt(0)
                                      .toUpperCase() +
                                      familyInfo.firstName
                                        .slice(1)
                                        .toLowerCase()}
                                </span>{" "}
                                had any of the following medical problems.
                              </span>
                            ) : (
                              <span>
                                Please indicate whether you have had any of the
                                following medical problems.
                              </span>
                            )}
                          </p>
                        </div>
                      </div>

                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /COVID-19 Positive/i.test(item)
                                  )
                                }
                                name="COVID-19 Positive"
                                value="COVID-19 Positive"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="COVID-19 Positive"
                          />
                        </Grid>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /Congenital Heart Disease/i.test(item)
                                  )
                                }
                                name="Congenital Heart Disease"
                                value="Congenital Heart Disease"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Congenital Heart Disease"
                          />
                        </Grid>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /Hepatitis A, B, or C/i.test(item)
                                  )
                                }
                                name="Hepatitis A, B, or C"
                                value="Hepatitis A, B, or C"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Hepatitis A, B, or C"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        {familyAge?.profile?.age <= 21 &&
                        familyAge?.profile?.age >= 3 ? (
                          ""
                        ) : (
                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      new RegExp(
                                        "Myocardial Infarction \\(Heart Attack\\)",
                                        "i"
                                      ).test(item)
                                    )
                                  }
                                  name="Myocardial Infarction (Heart Attack)"
                                  value="Myocardial Infarction (Heart Attack)"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Myocardial Infarction (Heart Attack)"
                            />
                          </Grid>
                        )}
                        {familyAge?.profile?.age <= 21 &&
                        familyAge?.profile?.age >= 3 ? (
                          ""
                        ) : (
                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      /Stroke/i.test(item)
                                    )
                                  }
                                  name="Stroke"
                                  value="Stroke"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Stroke"
                            />
                          </Grid>
                        )}
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    new RegExp(
                                      "Hypertension \\(High Blood Pressure\\)",
                                      "i"
                                    ).test(item)
                                  )
                                }
                                name="Hypertension (High Blood Pressure)"
                                value="Hypertension (High Blood Pressure)"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Hypertension (High Blood Pressure)"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    new RegExp(
                                      "Hypotension \\(Low Blood Pressure\\)",
                                      "i"
                                    ).test(item)
                                  )
                                }
                                name="Hypotension (Low Blood Pressure)"
                                value="Hypotension (Low Blood Pressure)"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Hypotension (Low Blood Pressure)"
                          />
                        </Grid>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    new RegExp(
                                      "Coagulation \\(Bleeding or Clotting\\)",
                                      "i"
                                    ).test(item)
                                  )
                                }
                                name="Coagulation (Bleeding or Clotting)"
                                value="Coagulation (Bleeding or Clotting)"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Coagulation (Bleeding/Clotting)"
                          />
                        </Grid>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /Diabetes/i.test(item)
                                  )
                                }
                                name="Diabetes"
                                value="Diabetes"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Diabetes"
                          />
                        </Grid>
                      </Grid>
                      {familyAge?.profile?.age <= 21 &&
                      familyAge?.profile?.age >= 3 ? (
                        ""
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      /Alcoholism/i.test(item)
                                    )
                                  }
                                  name="Alcoholism"
                                  value="Alcoholism"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Alcoholism"
                            />
                          </Grid>

                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      /Depression/i.test(item)
                                    )
                                  }
                                  name="Depression"
                                  value="Depression"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Depression"
                            />
                          </Grid>
                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      /Rheumatoid arthritis/i.test(item)
                                    )
                                  }
                                  name="Rheumatoid arthritis"
                                  value="Rheumatoid arthritis"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Rheumatoid arthritis"
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /High Cholesterol/i.test(item)
                                  )
                                }
                                name="High Cholesterol"
                                value="High Cholesterol"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="High Cholesterol"
                          />
                        </Grid>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /Hyperthyroidism/i.test(item)
                                  )
                                }
                                name="Hyperthyroidism"
                                value="Hyperthyroidism"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Hyperthyroidism"
                          />
                        </Grid>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /kidney injury/i.test(item)
                                  )
                                }
                                name="kidney injury"
                                value="kidney injury"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Acute renal failure/kidney injury"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        {familyAge?.profile?.age <= 21 &&
                        familyAge?.profile?.age >= 3 ? (
                          ""
                        ) : (
                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      /Lung Cancer/i.test(item)
                                    )
                                  }
                                  name="Lung Cancer"
                                  value="Lung Cancer"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Lung Cancer"
                            />
                          </Grid>
                        )}
                        {familyAge?.profile?.age <= 21 &&
                        familyAge?.profile?.age >= 3 ? (
                          ""
                        ) : (
                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      /Prostrate Cancer/i.test(item)
                                    )
                                  }
                                  name="Prostrate Cancer"
                                  value="Prostrate Cancer"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Prostrate Cancer"
                            />
                          </Grid>
                        )}
                        {familyAge?.profile?.age <= 21 &&
                        familyAge?.profile?.age >= 3 ? (
                          ""
                        ) : (
                          <Grid item md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    Array.isArray(personalMedicalHistory) &&
                                    personalMedicalHistory.some((item) =>
                                      /Colorectal Cancer/i.test(item)
                                    )
                                  }
                                  name="Colorectal Cancer"
                                  value="Colorectal Cancer"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Colorectal Cancer"
                            />
                          </Grid>
                        )}
                      </Grid>

                      <Grid container spacing={2}>
                        {familyAge?.profile?.age <= 21 &&
                        familyAge?.profile?.age >= 3
                          ? ""
                          : gender == "female" && (
                              <>
                                <Grid item md={4}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          Array.isArray(
                                            personalMedicalHistory
                                          ) &&
                                          personalMedicalHistory.some((item) =>
                                            /Breast Cancer/i.test(item)
                                          )
                                        }
                                        name="Breast Cancer"
                                        value="Breast Cancer"
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Breast Cancer"
                                  />
                                </Grid>
                                <Grid item md={4}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          Array.isArray(
                                            personalMedicalHistory
                                          ) &&
                                          personalMedicalHistory.some((item) =>
                                            /Cervical Cancer/i.test(item)
                                          )
                                        }
                                        name="Cervical Cancer"
                                        value="Cervical Cancer"
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Cervical Cancer"
                                  />
                                </Grid>
                              </>
                            )}
                        <Grid item md={4}>
                          <Checkbox
                            checked={
                              isCheckboxChecked &&
                              !(
                                Array.isArray(personalMedicalHistory) &&
                                personalMedicalHistory.some((item) =>
                                  /None/i.test(item)
                                )
                              )
                            }
                            onChange={handleCheckboxChange1}
                          />
                          Other
                          {isCheckboxChecked && (
                            <InputField
                              name=""
                              placeholder=""
                              className={`inputField`}
                              onBlur={handleInputBlur}
                              onChange={handleInputChange2}
                              value={otherInput2}
                            />
                          )}
                        </Grid>
                        <Grid item md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(personalMedicalHistory) &&
                                  personalMedicalHistory.some((item) =>
                                    /None/i.test(item)
                                  )
                                }
                                name="None"
                                value="None"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="None"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} mb={5}>
                        <Grid item xs={12} md={3}>
                          {gender !== "male" &&
                            familyAge?.profile?.age > 21 && (
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  style={{
                                    color: fieldErrors.hormoneTherapies
                                      ? "red"
                                      : "inherit",
                                  }}
                                >
                                  <span>
                                    I take Hormone therapies (estrogen &
                                    Progesterone)
                                  </span>
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  value={hormoneTherapies}
                                  onChange={handlehormoneTherapiesChange}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {gender !== "male" &&
                            familyAge?.profile?.age > 21 && (
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  style={{
                                    color: fieldErrors.firstPregnancy
                                      ? "red"
                                      : "inherit",
                                  }}
                                >
                                  <span>
                                    When did you had your first pregnancy?
                                  </span>
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  value={firstPregnancy}
                                  onChange={handlefirstPregnancyChange}
                                >
                                  <FormControlLabel
                                    value="Not Applicable"
                                    control={<Radio />}
                                    label="Not Applicable"
                                  />
                                  <FormControlLabel
                                    value="Before 30"
                                    control={<Radio />}
                                    label="Before 30"
                                  />
                                  <FormControlLabel
                                    value="After 30"
                                    control={<Radio />}
                                    label="After 30"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {gender !== "male" &&
                            familyAge?.profile?.age > 21 && (
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  style={{
                                    color: fieldErrors.reproductiveHistory
                                      ? "red"
                                      : "inherit",
                                  }}
                                >
                                  <span>
                                    Did you breastfeed your child? or Are you
                                    breastfeeding your child?
                                  </span>
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  value={reproductiveHistory}
                                  onChange={handlereproductiveHistoryChange}
                                >
                                  <FormControlLabel
                                    value="Not Applicable"
                                    control={<Radio />}
                                    label="Not Applicable"
                                  />
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {gender !== "male" &&
                            familyAge?.profile?.age > 21 && (
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  style={{
                                    color: fieldErrors.gestationalDiabetes
                                      ? "red"
                                      : "inherit",
                                  }}
                                >
                                  <span>
                                    If you are a women have you ever been
                                    diagnosed with gestational diabetes ?
                                  </span>
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  value={gestationalDiabetes}
                                  onChange={handlegestationalDiabetesChange}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        {familyAge?.profile?.age > 21 ? (
                          <Grid item xs={12} md={3}>
                            <FormControl>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                style={{
                                  color: fieldErrors.intravenousDrugs
                                    ? "red"
                                    : "inherit",
                                }}
                              >
                                {<span> I use intravenous drugs.</span>}
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={intravenousDrugs}
                                onChange={handleintravenousDrugsChange}
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid item xs={12} md={3}>
                          <FormControl>
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{
                                color: fieldErrors.usedPainKillers
                                  ? "red"
                                  : "inherit",
                              }}
                            >
                              <span>
                                Do you use painkillers (NSAID) like aspirin,
                                ibuprofen, diclofenac, etc. quite often?
                              </span>
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              value={usedPainKillers}
                              onChange={handleusedPainKillersChange}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {usedPainKillers == true && (
                          <Grid item xs={12} md={3}>
                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label">
                                Name Of the Painkillers
                              </FormLabel>
                              <Input
                                style={{ textIndent: "10px" }}
                                value={nameOfThePainkillers}
                                type="text"
                                onChange={handlenameOfThePainkillersChange}
                              ></Input>
                            </FormControl>
                          </Grid>
                        )}
                        {usedPainKillers == true && (
                          <Grid item xs={12} md={3}>
                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label">
                                How Often ?
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={howOftenDoYouUsedPainkillers}
                                onChange={
                                  handlehowOftenDoYouUsedPainkillersChange
                                }
                              >
                                <FormControlLabel
                                  value="Almost Daily"
                                  control={<Radio />}
                                  label="Almost Daily"
                                />
                                <FormControlLabel
                                  value="2-3 times a week"
                                  control={<Radio />}
                                  label="2-3 times a week"
                                />
                                <FormControlLabel
                                  value="4-6 times a week"
                                  control={<Radio />}
                                  label="4-6 times a week"
                                />
                                <FormControlLabel
                                  value="Once a Month"
                                  control={<Radio />}
                                  label="Once a Month"
                                />
                                <FormControlLabel
                                  value="Less Frequently"
                                  control={<Radio />}
                                  label="Less Frequently"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        )}
                        {usedPainKillers == true && (
                          <Grid item xs={12} md={3}>
                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label">
                                Since how long you have been taking painkillers
                                (NSAID)?
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={howLongAreYouTakingPainkillers}
                                onChange={
                                  handlehowLongAreYouTakingPainkillersChange
                                }
                              >
                                <FormControlLabel
                                  value="Since last 1 year"
                                  control={<Radio />}
                                  label="Since last 1 year"
                                />
                                <FormControlLabel
                                  value="2-5 years"
                                  control={<Radio />}
                                  label="2-5 years"
                                />
                                <FormControlLabel
                                  value="6-10 years"
                                  control={<Radio />}
                                  label="6-10 years"
                                />
                                <FormControlLabel
                                  value="10-15 years"
                                  control={<Radio />}
                                  label="10-15 years"
                                />
                                <FormControlLabel
                                  value="more than 15 years"
                                  control={<Radio />}
                                  label="more than 15 years"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={12} md={3}>
                          <FormControl>
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{
                                color: fieldErrors.excessAbdominalWeight
                                  ? "red"
                                  : "inherit",
                              }}
                            >
                              <span> What is your Waist Circumference ?</span>
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              value={excessAbdominalWeight}
                              onChange={handleexcessAbdominalWeightChange}
                            >
                              {gender === "male" ? (
                                <>
                                  <FormControlLabel
                                    value="<90 cm"
                                    control={<Radio />}
                                    label="<90 cm"
                                  />
                                  <FormControlLabel
                                    value="90-99 cm"
                                    control={<Radio />}
                                    label="90-99 cm"
                                  />
                                  <FormControlLabel
                                    value=">100 cm"
                                    control={<Radio />}
                                    label=">100 cm"
                                  />
                                </>
                              ) : (
                                <>
                                  <FormControlLabel
                                    value="<80 cm"
                                    control={<Radio />}
                                    label="<80 cm"
                                  />
                                  <FormControlLabel
                                    value="80-89 cm"
                                    control={<Radio />}
                                    label="80-89 cm"
                                  />
                                  <FormControlLabel
                                    value=">90 cm"
                                    control={<Radio />}
                                    label=">90 cm"
                                  />
                                </>
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {gender !== "male" && familyAge?.profile?.age > 21 && (
                          <Grid item xs={12} md={3}>
                            <FormControl>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                style={{
                                  color: fieldErrors.previouslyReceivedRadiation
                                    ? "red"
                                    : "inherit",
                                }}
                              >
                                <span>
                                  I have previously received radiation therapy
                                  to chests or breasts.
                                </span>
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={previouslyReceivedRadiation}
                                onChange={
                                  handlepreviouslyReceivedRadiationChange
                                }
                              >
                                <FormControlLabel
                                  value="Not Applicable"
                                  control={<Radio />}
                                  label="Not Applicable"
                                />
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <SurgicalHistory
                      Surgeries={surgeries}
                      onUpdateSurgeries={handleSurgeriesChange}
                      selectedOption={surgicalFlag}
                      handleRadioChange={handleRadioSurgeryChange}
                      fieldError={fieldErrors}
                    ></SurgicalHistory>
                  </>
                )}

                {/* <Immunizations></Immunizations> */}
                <Grid
                  container
                  spacing={0}
                  mb={0}
                  mt={3}
                  justifyContent="space-between"
                  display={"flex"}
                  gap={5}
                >
                  <div className="submit-button">
                    <button className="base" onClick={onBack}>
                      Back
                    </button>
                  </div>
                  <div className="submit-button">
                    {familyAge?.profile?.age < 3 ? (
                      <button
                        type="button"
                        className="base"
                        onClick={SilverSubmit}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="base"
                        onClick={SilverhandleNext}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </Grid>
              </>
            )}

            {personalMedicalHistorystep == 1 && (
              <>
                <div className="info_header_wrapper">
                  <div className="info_provider_content">
                    <h3>Family History</h3>
                    {familyAge?.profile &&
                    familyAge?.profile?.age >= 3 &&
                    familyAge?.profile?.age < 21 ? (
                      <p>
                        Please indicate with a check (√) who in your child’s
                        family has had the following conditions.
                        <span className="hide-on-mobile">
                          In the first column
                        </span>
                        <span className="capitalize-on-mobile">
                          &nbsp;please&nbsp;
                        </span>
                        indicate their living status. L = Living, D = Deceased,
                        U = Unknown.
                      </p>
                    ) : (
                      <p>
                        Please indicate with a check (√) who in your family has
                        had the following conditions.
                        <span className="hide-on-mobile">
                          In the first column
                        </span>
                        <span className="capitalize-on-mobile">
                          &nbsp;please&nbsp;
                        </span>
                        indicate their living status. L = Living, D = Deceased,
                        U = Unknown.
                      </p>
                    )}
                  </div>
                </div>
                {familyAge?.profile &&
                familyAge?.profile?.age >= 3 &&
                familyAge?.profile?.age < 21 ? (
                  <div style={{ marginBottom: "20px" }}>
                    Note: Please talk to your family members if you are not sure
                    as this information is vital to predict any potential
                    lifestyle diseases your child may have in the future. Please
                    safeguard your child’s future health by providing this
                    information as accurately as possible. We will be able to
                    provide timely preventive actions necessary in case we
                    foresee any future health risks based on the information
                    provided.
                  </div>
                ) : (
                  <div style={{ marginBottom: "20px" }}>
                    Note: Please talk to your family members if you are not sure
                    as this information is vital to predict any potential
                    lifestyle diseases you may have
                  </div>
                )}

                <div className="desktop-view">
                  <TableContainer
                    component={Paper}
                    style={{ maxHeight: 450, marginBottom: "30px" }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#E5E5EB" }}>
                          <TableCell></TableCell>
                          <TableCell
                            style={{ color: "#757575", width: "50px" }}
                          >
                            Living Staus
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Disease At What Age ?
                          </TableCell>
                          <TableCell
                            style={{ color: "#757575", minWidth: "100px" }}
                          >
                            No Disease
                          </TableCell>
                          <TableCell
                            style={{ color: "#757575", minWidth: "100px" }}
                          >
                            COVID-19 Positive
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Asthma
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Diabetes
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Hypertension (High Blood Pressure)
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Coronary Artery / Heart Disease
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Stroke
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Lungs/COPD
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Cancer
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Colon Polyps
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Kidney Disease
                          </TableCell>
                          <TableCell style={{ color: "#757575" }}>
                            Depression
                          </TableCell>
                          {/* Add more headers as needed */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Add rows and data here */}
                        {familyAge?.profile?.age < 13 &&
                        familyAge?.profile?.age >= 3
                          ? FamilyNamesAccordingAge.map(
                              (familyMemberName, index) => {
                                const familyMemberData =
                                  familyData[familyMemberName];
                                const livingStatus =
                                  familyMemberData?.livingStatus || "";
                                const age = familyMemberData?.age || "";
                                const diseases =
                                  familyMemberData?.diseases || "";
                                return (
                                  <>
                                    <TableRow key={index}>
                                      <TableCell>{familyMemberName}</TableCell>
                                      <TableCell>
                                        <select
                                          value={livingStatus}
                                          onChange={(e) =>
                                            handleLivingStatusChange(
                                              familyMemberName,
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">Select</option>
                                          <option value="L">Living</option>
                                          <option value="D">Deceased</option>
                                          <option value="U">Unknown</option>
                                        </select>
                                      </TableCell>
                                      <TableCell>
                                        <div style={{ display: "flex" }}>
                                          <input
                                            type="number"
                                            style={{
                                              width: "50px",
                                              textIndent: "10px",
                                              marginRight: "5px",
                                            }}
                                            value={age}
                                            onChange={(e) =>
                                              handleAgeChange(
                                                familyMemberName,
                                                e.target.value
                                              )
                                            }
                                          ></input>
                                          <span>yrs</span>
                                        </div>
                                      </TableCell>
                                      {diseaseOptions.map(
                                        (disease, diseaseIndex) => (
                                          <>
                                            <TableCell key={diseaseIndex}>
                                              {disease != "Cancer" && (
                                                <Checkbox
                                                  checked={familyData[
                                                    familyMemberName
                                                  ]?.diseases?.includes(
                                                    disease
                                                  )}
                                                  onChange={() =>
                                                    handleDiseaseChange(
                                                      familyMemberName,
                                                      disease
                                                    )
                                                  }
                                                />
                                              )}
                                              {disease === "Cancer" && (
                                                <React.Fragment>
                                                  <Checkbox
                                                    checked={familyData[
                                                      familyMemberName
                                                    ]?.diseases?.includes(
                                                      "Cancer"
                                                    )}
                                                    onChange={() =>
                                                      handleDiseaseChange(
                                                        familyMemberName,
                                                        "Cancer"
                                                      )
                                                    }
                                                  />
                                                  {familyData[
                                                    familyMemberName
                                                  ]?.diseases?.includes(
                                                    "Cancer"
                                                  ) && (
                                                    <a
                                                      href="#"
                                                      onClick={() =>
                                                        familyCancerInfo(
                                                          familyMemberName
                                                        )
                                                      }
                                                    >
                                                      Info
                                                    </a>
                                                  )}
                                                </React.Fragment>
                                              )}
                                            </TableCell>
                                          </>
                                        )
                                      )}

                                      {/* Add more cells as needed */}
                                    </TableRow>
                                  </>
                                );
                              }
                            )
                          : FamilyNames.map((familyMemberName, index) => {
                              const familyMemberData =
                                familyData[familyMemberName];
                              const livingStatus =
                                familyMemberData?.livingStatus || "";
                              const age = familyMemberData?.age || "";
                              const diseases = familyMemberData?.diseases || "";
                              return (
                                <>
                                  <TableRow key={index}>
                                    <TableCell>{familyMemberName}</TableCell>
                                    <TableCell>
                                      <select
                                        value={livingStatus}
                                        onChange={(e) =>
                                          handleLivingStatusChange(
                                            familyMemberName,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">Select</option>
                                        <option value="L">Living</option>
                                        <option value="D">Deceased</option>
                                        <option value="U">Unknown</option>
                                      </select>
                                    </TableCell>
                                    <TableCell>
                                      <div style={{ display: "flex" }}>
                                        <input
                                          type="number"
                                          style={{
                                            width: "50px",
                                            textIndent: "10px",
                                            marginRight: "5px",
                                          }}
                                          value={age}
                                          onChange={(e) =>
                                            handleAgeChange(
                                              familyMemberName,
                                              e.target.value
                                            )
                                          }
                                        ></input>
                                        <span>yrs</span>
                                      </div>
                                    </TableCell>
                                    {diseaseOptions.map(
                                      (disease, diseaseIndex) => (
                                        <>
                                          <TableCell key={diseaseIndex}>
                                            {disease != "Cancer" && (
                                              <Checkbox
                                                checked={familyData[
                                                  familyMemberName
                                                ]?.diseases?.includes(disease)}
                                                onChange={() =>
                                                  handleDiseaseChange(
                                                    familyMemberName,
                                                    disease
                                                  )
                                                }
                                              />
                                            )}
                                            {disease === "Cancer" && (
                                              <React.Fragment>
                                                <Checkbox
                                                  checked={familyData[
                                                    familyMemberName
                                                  ]?.diseases?.includes(
                                                    "Cancer"
                                                  )}
                                                  onChange={() =>
                                                    handleDiseaseChange(
                                                      familyMemberName,
                                                      "Cancer"
                                                    )
                                                  }
                                                />
                                                {familyData[
                                                  familyMemberName
                                                ]?.diseases?.includes(
                                                  "Cancer"
                                                ) && (
                                                  <a
                                                    href="#"
                                                    onClick={() =>
                                                      familyCancerInfo(
                                                        familyMemberName
                                                      )
                                                    }
                                                  >
                                                    Info
                                                  </a>
                                                )}
                                              </React.Fragment>
                                            )}
                                          </TableCell>
                                        </>
                                      )
                                    )}

                                    {/* Add more cells as needed */}
                                  </TableRow>
                                </>
                              );
                            })}
                        {/* {FamilyNames.map((familyMemberName, index) => {
                          const familyMemberData = familyData[familyMemberName];
                          const livingStatus = familyMemberData?.livingStatus || "";
                          const age = familyMemberData?.age || "";
                          const diseases = familyMemberData?.diseases || "";
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell>{familyMemberName}</TableCell>
                                <TableCell>
                                  <select value={livingStatus} onChange={(e) => handleLivingStatusChange(familyMemberName, e.target.value)}>
                                    <option value="">Select</option>
                                    <option value="L">Living</option>
                                    <option value="D">Deceased</option>
                                    <option value="U">Unknown</option>
                                  </select>
                                </TableCell>
                                <TableCell>
                                  <div style={{ display: "flex" }}>
                                    <input
                                      type="number"
                                      style={{
                                        width: "50px",
                                        textIndent: "10px",
                                        marginRight: "5px",
                                      }}
                                      value={age}
                                      onChange={(e) => handleAgeChange(familyMemberName, e.target.value)}
                                    ></input>
                                    <span>yrs</span>
                                  </div>
                                </TableCell>
                                {diseaseOptions.map((disease, diseaseIndex) => (
                                  <>
                                    <TableCell key={diseaseIndex}>
                                      {disease != "Cancer" && (
                                        <Checkbox checked={familyData[familyMemberName]?.diseases?.includes(disease)} onChange={() => handleDiseaseChange(familyMemberName, disease)} />
                                      )}
                                      {disease === "Cancer" && (
                                        <React.Fragment>
                                          <Checkbox checked={familyData[familyMemberName]?.diseases?.includes("Cancer")} onChange={() => handleDiseaseChange(familyMemberName, "Cancer")} />
                                          {familyData[familyMemberName]?.diseases?.includes("Cancer") && (
                                            <a href="#" onClick={() => familyCancerInfo(familyMemberName)}>
                                              Info
                                            </a>
                                          )}
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                  </>
                                ))}

                                {/* Add more cells as needed */}
                        {/* </TableRow>
                            </>
                          );
                        })} */}

                        {/* Add more rows and data as needed */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                {/* Mobile View */}
                <div className="mobile-view">
                  {familyAge?.profile?.age >= 3 && familyAge?.profile?.age < 13
                    ? FamilyNamesAccordingAge.map((familyMemberName, index) => {
                        const familyMemberData =
                          familyData[familyMemberName] || {}; // Ensure familyMemberData is defined
                        return (
                          <FamilyMemberCard
                            key={index}
                            familyMemberName={familyMemberName}
                            familyMemberData={familyMemberData}
                            handleLivingStatusChange={handleLivingStatusChange}
                            handleAgeChange={handleAgeChange}
                            handleDiseaseChange={handleDiseaseChange}
                            familyCancerInfo={familyCancerInfo}
                            // diseaseOptions={diseaseOptions} // Pass diseaseOptions to the card component
                          />
                        );
                      })
                    : FamilyNames.map((familyMemberName, index) => {
                        const familyMemberData =
                          familyData[familyMemberName] || {}; // Ensure familyMemberData is defined
                        return (
                          <FamilyMemberCard
                            key={index}
                            familyMemberName={familyMemberName}
                            familyMemberData={familyMemberData}
                            handleLivingStatusChange={handleLivingStatusChange}
                            handleAgeChange={handleAgeChange}
                            handleDiseaseChange={handleDiseaseChange}
                            familyCancerInfo={familyCancerInfo}
                            // diseaseOptions={diseaseOptions} // Pass diseaseOptions to the card component
                          />
                        );
                      })}
                </div>

                <Grid container spacing={2} mb={5}>
                  {checkboxVisibility && (
                    <Grid item>
                      <div>
                        <span style={{ color: "black", fontWeight: "500" }}>
                          Note:
                        </span>
                        If you are sure that none of your family members ever
                        had any of the above disease then please confirm below
                      </div>
                    </Grid>
                  )}
                  {checkboxVisibility && (
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox checked={familyDiseasesKnowledge} />}
                        label="I confirm that no one in my family ever had any of the above diseases to the best of my knowledge"
                        value={familyDiseasesKnowledge}
                        onChange={handlefamilyDiseasesKnowledgeChange}
                      />
                      {errorMessage && (
                        <div style={{ color: "red" }}>{errorMessage}</div>
                      )}
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  spacing={0}
                  mb={0}
                  mt={3}
                  justifyContent="space-between"
                  display={"flex"}
                  gap={5}
                >
                  <div className="submit-button">
                    <button className="base" onClick={SilverhandleBack}>
                      Back
                    </button>
                  </div>
                  <div className="submit-button">
                    <button type="submit" className="base">
                      Submit
                    </button>
                  </div>
                </Grid>
              </>
            )}
          </form>
        )}
      </FormProvider>
      {/* <InfoModel
        open={FormSubmittedModal}
        setOpen={setFormSubmittedModal}
        buttonText="Next"
      >
        <video id="video" autoPlay loop style={{ height: "400px" }}>
          <source src="/img/FormSubmitted.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}
        >
          Thanks For Submitting The Form
        </div>
      </InfoModel> */}
    </div>
  );
};

export default SilverStep1;
