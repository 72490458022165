import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import LifeStyleModificationRecommendation from "../LifeStyleRiskAssessment/components/LifeStyleModificationRecommendation";
import Nutrition from "./Nutrition";
import PhysicalExercise from "./PhysicalExercise";
import FullIntervention from "./FullIntervention";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useLocation } from "react-router-dom";
import Environment from "./Environment";

const LifeStyleModifications = () => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (location.state?.tabIndex !== undefined) {
      setCurrentTabIndex(location.state.lifestyletabIndex);
    }
  }, [location.state]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          sx={{ backgroundColor: "steelblue" }}
        >
          <Tab
            label="Diagnosis"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Nutrition"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Physical Exercise"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 2 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Environment"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 3 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />

          <Tab
            label="Lifestyle"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 4 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
        <br />
        {familyRelation?.relationShipWithUser != "wife" &&
          familyRelation != null && (
            <>
              <h4 style={{ color: "blue" }}>
                The health Insights/Information presented below is for your
                child{" "}
                <span style={{ fontSize: "larger" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>
              </h4>
              <br />
            </>
          )}
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <LifeStyleModificationRecommendation></LifeStyleModificationRecommendation>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <Nutrition></Nutrition>
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <PhysicalExercise></PhysicalExercise>
          </Box>
        )}
        {currentTabIndex === 3 && (
          <Box sx={{ p: 3 }}>
            <Environment></Environment>
          </Box>
        )}

        {currentTabIndex === 4 && (
          <Box sx={{ p: 3 }}>
            <FullIntervention></FullIntervention>
          </Box>
        )}
      </Box>
    </>
  );
};

export default LifeStyleModifications;
