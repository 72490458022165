import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Tooltip,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import { Col, Row } from "reactstrap";

// Styled component for highlighted card
const HighlightedCard = styled(Card)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? "#f0f0f0" : "#fff",
  border: isSelected ? "2px solid #3f51b5" : "1px solid #ccc",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  height: "100%",
  flexGrow: 1,
}));

const DoctorCard = ({ doctorData, onDoctorSelect }) => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const handleCardClick = (doctor) => {
    setSelectedDoctor(doctor); // Set selected doctor locally
  };

  const handleBookHereClick = () => {
    if (selectedDoctor) {
      onDoctorSelect(selectedDoctor); // Send selected doctor data to parent component
    }
  };

  const truncateBio = (bio, maxLength) => {
    if (bio.length > maxLength) {
      return bio.substring(0, maxLength) + "...";
    }
    return bio;
  };

  const getAvatarColor = (id) => {
    const colors = ["#FFC300", "#33A1FD", "#DAF7A6", "#FF33F6", "#33FF95"];
    return colors[id % colors.length]; // Cycles through the colors array
  };

  return (
    <div>
      <Row className="d-flex justify-content-start">
        {doctorData?.map((doctor) => (
          <Col
            key={doctor?.userId}
            lg="4"
            md="6"
            sm="12"
            className="mb-4 d-flex"
          >
            <HighlightedCard
              isSelected={selectedDoctor?.userId === doctor?.userId}
              onClick={() => handleCardClick(doctor)}
              sx={{ maxWidth: "100%", minHeight: "350px" }}
            >
              <CardContent>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ marginBottom: "8px" }}
                >
                  <Grid item>
                    <Avatar
                      sx={{
                        bgcolor: getAvatarColor(doctor?.userId), // Dynamic background color
                        color: "white", // Text color
                      }}
                    >
                      {doctor?.firstName?.[0] || ""}
                      {doctor?.lastName?.[0] || ""}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {doctor?.firstName} {doctor?.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                >
                  <strong>Practicing As:</strong> {doctor?.practicingAs}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                >
                  <strong>Degree:</strong> {doctor?.highestMedicalDegree}
                </Typography>
                {doctor.awards && (
                  <Typography
                    variant="body1"
                    style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                  >
                    <strong>Awards:</strong> {doctor?.awards}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                >
                  <strong>Test Report Review Charge:</strong> ₹{doctor?.amount}
                </Typography>
                <Tooltip title={doctor?.bio} arrow>
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: "8px",
                      fontSize: "1.1rem",
                      cursor: "pointer",
                      display: "-webkit-box",
                      WebkitLineClamp: 3, // Limit to 3 lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <strong>Bio:</strong> {truncateBio(doctor?.bio, 100)}
                  </Typography>
                </Tooltip>
              </CardContent>
            </HighlightedCard>
          </Col>
        ))}
      </Row>

      {selectedDoctor && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBookHereClick}
          >
            Book Here
          </Button>
        </div>
      )}
    </div>
  );
};

export default DoctorCard;
