import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardText } from 'reactstrap';
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getLabOrderDetailByIdApi, getLabOrderDetailApi } from "../../constants/apiList";

const OrderDetailModel = ({ orderId }) => {
    const [orderDetail, setOrderDetail] = useState(null);
    const [patientDetail, setPatientDetail] = useState(null);
    const [labDetail, setLabDetail] = useState(null);
    const [testDetail, setTestDetail] = useState(null);
    const getPaymentDetail = async () => {
      await AxiosInstance.get(`${getLabOrderDetailByIdApi}/${orderId}`)
        .then((response) => {
          if (response != null) {
            setOrderDetail(response);
            setPatientDetail(response.patientDetail);
            setLabDetail(response.labDetail);
            setTestDetail(response.testDetail);
          } else {
          }
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    };

    useEffect(() => {
        getPaymentDetail();
    }, []);
    return (
      <>
        <Card
          style={{
            padding: "15px",
            border: "1px solid black",
            margin: "10px 0px 10px 0px",
          }}
          elevation={5}
        >
          {patientDetail && (
            <>
              <div style={{ textAlign: "center" }}>
                <h4>Lifestyle Health Risk Test Request Form</h4>
                <hr />
              </div>
              <Row style={{ marginTop: "3%" }}>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Living Scope Order Id : </b>
                    {orderDetail.orderId}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Date : </b>
                    {orderDetail.date}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>patient ID : UHID : </b>
                    {orderDetail.patientId + " : " + patientDetail.patientUpId}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Age : </b>
                    {patientDetail.patientAge}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Name : </b>
                    {patientDetail.patientName}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Gender : </b>
                    {patientDetail.patientGender}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Contact Number : </b>
                    {patientDetail.patientContactNumber}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Lab Name : </b>
                    {labDetail.labName}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Address : </b>
                    {patientDetail.address.addressLine1 +
                      ", " +
                      patientDetail.address.city +
                      " ( " +
                      patientDetail.address.state +
                      ", " +
                      patientDetail.address.zipcode +
                      ", " +
                      patientDetail.address.country +
                      ")"}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Lab Contact Number : </b>
                    {labDetail.labPhoneNumber}
                  </CardText>
                </Col>
              </Row>
            </>
          )}
          {labDetail && (
            <>
              <Row>
                <Col lg={12}>
                  <CardText className="mt-3">
                    <b>Test Name : </b>
                    {orderDetail.testDetail.map((testName) => testName.testName).join(", ")}
                  </CardText>
                  {orderDetail.testDetail.some((test) => test.testName === "Lipid Profile") && (
                    <div>
                    <CardText>
                      <br></br>
                      <b>Tests to be included (if any):</b> Tests should include Total Cholesterol, LDL Cholesterol, HDL Cholesterol, Triglyceride, Very  Low-Density Lipoprotein, Non-HDL Cholesterol.
                    </CardText>
                    <CardText>
                      <b>Preferred Date and Time:</b> 
                    </CardText>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
          {orderDetail && (
            <>
              <Row>
                <Col lg={12}>
                  <CardText className="mt-3">
                    <b>Note to Lab : </b>
                  </CardText>
                  <div>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                      <li>Please call the patient promptly to set up home collection.</li>
                      <li>After tests are done and reports are available, please upload on Uncurl: health app platform mentioning Patient ID.</li>
                      <li>If there are any questions or issues regarding this order, call our customer support team at </li>
                      <li>Please send bill reflecting Living Scope Order ID as well as Patient ID</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Card>
      </>
    );
};

export default OrderDetailModel;
