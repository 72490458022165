import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Row } from "reactstrap";
import {
  Card,
  FormLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import "../profileRegistration/formStyle.css";
import "./LifeStyle.css";
import Spinner from "../../shared/Spinner";

const ChildrenExercise = () => {
  const [myFitness, setmyFitness] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("form/lifestyleSketchDietForChildren")
      .then((response) => {
        if (response != null) {
          const fieldsToParse = [
            "moderateIntensity",
            "vigorousIntensity",
            "mildIntensity",
            "youStoppedDoingPhysicalActivity",
            "agreeStatement",
            "doYouFeelDoingPhysicalActOrSportWill",
            "duringTypicalWeek",
            "duringTypicalWeekSomeoneInFamily",
            "agreeStatementSecond",
            "journeyToAndFromSchool",
            "howDoYouTravel",
            "whatDoYouDoInSchool",
          ];

          // Loop through each field and parse if it's a valid JSON string
          fieldsToParse.forEach((field) => {
            if (
              response.hasOwnProperty(field) &&
              typeof response[field] === "string"
            ) {
              try {
                response[field] = JSON.parse(response[field]); // Parses as array or object
              } catch (error) {
                console.error(`Error parsing ${field}:`, error);
                response[field] = null; // Fallback to null if parsing fails
              }
            }
          });
          setmyFitness(response);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  console.log("fitness", myFitness);
  const filteredVigorousIntensity = myFitness?.vigorousIntensity?.filter(
    (row) => row.name && row.days && row.timeEachDay && row.totalTime
  );

  const filteredModerateIntensity = myFitness?.moderateIntensity?.filter(
    (row) => row.name && row.days && row.timeEachDay && row.totalTime
  );

  const filteredMildIntensity = myFitness?.mildIntensity?.filter(
    (row) => row.name && row.days && row.timeEachDay && row.totalTime
  );
  return (
    <div>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Child Exercise</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. After school I would normally prefer to :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.afterSchoolWouldPreferTo}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">2. Watching TV is :</label>
                  <span className="inputDiet">{myFitness?.watchingTvIs}</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. Walking or cycling to get somewhere (such as going to
                    school, shops or friends) is :{" "}
                    <span className="inputDiet">
                      {myFitness?.walkingToGetSomeWhere}
                    </span>
                  </label>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    4. Compared with other boys or girls your age, would you say
                    that you were :{" "}
                    <span className="inputDiet">
                      {myFitness?.comparedWithOtherChild}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    5. How do you usually travel to school :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.howDoYouTravelToSchool}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    6. Who do you usually travel to school with :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.whoDoYouTravelWithSchool}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    7. Do you usually go anywhere else on your way home from
                    school :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.doYouGoAnywhereElseFromHome}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>
                {" "}
                Your Child stopped from doing a physical activity because...
              </h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. There is a programme on TV that you want to watch :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.youStoppedDoingPhysicalActivity
                        ?.physicalActivity4A
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    2. You don’t think you are very good at physical activity :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.youStoppedDoingPhysicalActivity
                        ?.physicalActivity4B
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. Other children make fun of you when you are physically
                    active :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.youStoppedDoingPhysicalActivity
                        ?.physicalActivity4C
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    4. You don’t like physical activity :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.youStoppedDoingPhysicalActivity
                        ?.physicalActivity4D
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    5. You don’t have the equipment you need :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.youStoppedDoingPhysicalActivity
                        ?.physicalActivity4E
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    6. You are scared that you might get hurt :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.youStoppedDoingPhysicalActivity
                        ?.physicalActivity4F
                    }
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Do you agree with the following statements</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. I can ask my parent or another adult to sign me up for a
                    sport, dance class, or other physical activity :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatement?.agreeStatemen5A}
                    </span>
                  </label>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    2. I can do something active even if it is hot or cold
                    outside :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.agreeStatement?.agreeStatemen5B}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. I can do something active even if I have a lot of
                    homework :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.agreeStatement?.agreeStatemen5C}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    4. I can ask my parent or another adult to take me to play a
                    sport or do a physical activity :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatement?.agreeStatemen5D}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    5. I can ask my best friend to do something active with me :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.agreeStatement?.agreeStatemen5E}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    6. I can do something active no matter how tired I feel :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.agreeStatement?.agreeStatemen5F}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    7. I am not allowed to play outside because my parents think
                    it’s not safe :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8A}
                    </span>
                  </label>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    8. At school there are playgrounds or fields where I can run
                    around :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8B}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    9. There is somewhere at home where I can go outside and
                    play :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8C}
                    </span>
                  </label>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    10. It is safe to walk or play alone in my neighbourhood
                    during the day :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8D}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    11. There are other children near my home for me to go out
                    and play with :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8E}
                    </span>
                  </label>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    12. There are playgrounds, parks, or sports halls close to
                    my home that I can use :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8F}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    13. It is difficult to walk or play near my house because I
                    don’t feel safe :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8G}
                    </span>
                  </label>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    14. I always have to tell my parents where I am where I am
                    going :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8H}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    15. If I am going out I always have to be back by a certain
                    time :{" "}
                    <span className="inputDiet">
                      {myFitness?.agreeStatementSecond?.agreeStatementSecond8I}
                    </span>
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>
                Your Child feels that doing physical activity or sports will...
              </h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. make you stronger :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.doYouFeelDoingPhysicalActOrSportWill
                        ?.doYouFeelDoingPhysicalActOrSportWill6A
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    2. keep you from getting too heavy :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.doYouFeelDoingPhysicalActOrSportWill
                        ?.doYouFeelDoingPhysicalActOrSportWill6B
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. make you very tired :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.doYouFeelDoingPhysicalActOrSportWill
                        ?.doYouFeelDoingPhysicalActOrSportWill6C
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    4. make you feel like you are not good at sports :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.doYouFeelDoingPhysicalActOrSportWill
                        ?.doYouFeelDoingPhysicalActOrSportWill6D
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    5. make you look better :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.doYouFeelDoingPhysicalActOrSportWill
                        ?.doYouFeelDoingPhysicalActOrSportWill6E
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    6. Take up too much time :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.doYouFeelDoingPhysicalActOrSportWill
                        ?.doYouFeelDoingPhysicalActOrSportWill6F
                    }
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>During a typical week, do the following things happen...</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. My friends do physical activities or play sports with me
                    :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.duringTypicalWeek?.duringTypicalWeek7A}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    2. I ask my friends to play outside or play sports with me :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.duringTypicalWeek?.duringTypicalWeek7B}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. My friends ask me to play outside or play sports with
                    them :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.duringTypicalWeek?.duringTypicalWeek7C}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    4. The teacher talks about exercise and sports in lessons :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.duringTypicalWeek?.duringTypicalWeek7D}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    5. The teachers organise or play games with us, apart from
                    PE :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.duringTypicalWeek?.duringTypicalWeek7E}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    6. The teacher tells me to exercise or play sports :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.duringTypicalWeek?.duringTypicalWeek7F}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>
                During a normal week, someone in my family (like my parents or
                other family members)…
              </h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. Encourages me to do physical activities or play sports :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.duringTypicalWeekSomeoneInFamily
                        ?.duringTypicalWeekSomeoneInFamily9A
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    2. Does a physical activity or plays sports with me :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.duringTypicalWeekSomeoneInFamily
                        ?.duringTypicalWeekSomeoneInFamily9B
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. Takes me to a place where I can do activities or play
                    sports :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.duringTypicalWeekSomeoneInFamily
                        ?.duringTypicalWeekSomeoneInFamily9C
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    4. Watches me take part in physical activities or sports :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.duringTypicalWeekSomeoneInFamily
                        ?.duringTypicalWeekSomeoneInFamily9D
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    5. Tells me that I am doing well in physical activities or
                    sports :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.duringTypicalWeekSomeoneInFamily
                        ?.duringTypicalWeekSomeoneInFamily9E
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    6. Tells me that physical activity is good for my health :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.duringTypicalWeekSomeoneInFamily
                        ?.duringTypicalWeekSomeoneInFamily9F
                    }
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>
                Your Child agree with the following statements about your
                journey to and from school…
              </h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. I can chat to my friends on my journey to school :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.journeyToAndFromSchool
                        ?.journeyToAndFromSchool10A
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    2. My parents think it is not safe to walk or cycle to
                    school :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.journeyToAndFromSchool
                        ?.journeyToAndFromSchool10B
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. My friends encourage me to walk or cycle to school :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.journeyToAndFromSchool
                        ?.journeyToAndFromSchool10C
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    4. My journey to school gives me exercise :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.journeyToAndFromSchool
                        ?.journeyToAndFromSchool10D
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    5. There are nice things to look at on my way to school :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.journeyToAndFromSchool
                        ?.journeyToAndFromSchool10E
                    }
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    6. Walking or cycling to school takes up too much time :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.journeyToAndFromSchool
                        ?.journeyToAndFromSchool10F
                    }
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    7. My parents encourage me to walk or cycle to school :
                  </label>
                  <span className="inputDiet">
                    {
                      myFitness?.journeyToAndFromSchool
                        ?.journeyToAndFromSchool10G
                    }
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5> Your Child usually travel to…</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    1. Friends in the neighbourhood :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.howDoYouTravel?.howDoYouTravel11A}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">2. The park :</label>
                  <span className="inputDiet">
                    {myFitness?.howDoYouTravel?.howDoYouTravel11B}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">
                    3. Other members of your family :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.howDoYouTravel?.howDoYouTravel11C}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">4. The shops :</label>
                  <span className="inputDiet">
                    {myFitness?.howDoYouTravel?.howDoYouTravel11D}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5> When you are at school what do you mostly do at…</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg={6}>
                  <label className="questionLabel">1. Morning break :</label>
                  <span className="inputDiet">
                    {myFitness?.whatDoYouDoInSchool?.whatDoYouDoInSchool12A}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    2. Lunch break (apart from eating lunch) :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.whatDoYouDoInSchool?.whatDoYouDoInSchool12B}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Moderate Intensity Exercise</h5>
            </div>
            <div
              style={{
                padding: "0px 10px 10px 10px",
                fontWeight: "500",
                color: "crimson",
              }}
            >
              Note: This includes physical activities that get you breathing
              harder and your heart beating faster. Examples of exercise include
              setting aside time for things like: jogging, dancing, bike riding,
              aerobic classes, swimming, working out to an exercise video.
              Exercise does not include what you do at work.
            </div>
            <div className="dietContent">
              <TableContainer
                component={Paper}
                sx={{ border: "1px solid #ccc" }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell>Sports/Exercise</TableCell>
                      <TableCell>No. of days (in a week)</TableCell>
                      <TableCell>Time each day (min.)</TableCell>
                      <TableCell>Total Time (min)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredModerateIntensity?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.days}</TableCell>
                        <TableCell>{row.timeEachDay}</TableCell>
                        <TableCell>{row.totalTime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Vigorous Intensity Exercise</h5>
            </div>
            <div
              style={{
                padding: "0px 0px 10px 10px",
                fontWeight: "500",
                color: "crimson",
              }}
            >
              Note: This includes physical activities like Race walking,
              jogging, running , Bicycling 10 mph or faster , Swimming laps ,
              Aerobic dancing , Jumping rope , Heavy gardening (continuous
              digging or hoeing with heart rate increases) , Hiking uphill or
              with a heavy backpack.
            </div>
            <div className="dietContent" style={{ paddingRight: "0px" }}>
              <TableContainer
                component={Paper}
                sx={{ border: "1px solid #ccc" }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell>Sports/Exercise</TableCell>
                      <TableCell>No. of days (in a week)</TableCell>
                      <TableCell>Time each day (min.)</TableCell>
                      <TableCell>Total Time (min)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredVigorousIntensity?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.days}</TableCell>
                        <TableCell>{row.timeEachDay}</TableCell>
                        <TableCell>{row.totalTime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Regular mild or Mild Intensity</h5>
            </div>
            <div
              style={{
                padding: "0px 10px 10px 10px",
                fontWeight: "500",
                color: "crimson",
              }}
            >
              Note: This is any physical exercise that gets your heart rate less
              than 50% higher than your resting heart rate. Examples includes-
              walking slowly, using computer, standing light work (cooking,
              washing dishes), fishing, playing most instruments, etc. Use this
              definition to answer the questions below.
            </div>
            <div className="dietContent">
              <TableContainer
                component={Paper}
                sx={{ border: "1px solid #ccc" }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell>Sports/Exercise</TableCell>
                      <TableCell>No. of days (in a week)</TableCell>
                      <TableCell>Time each day (min.)</TableCell>
                      <TableCell>Total Time (min)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredMildIntensity?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.days}</TableCell>
                        <TableCell>{row.timeEachDay}</TableCell>
                        <TableCell>{row.totalTime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Last Seven Days Trend</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. Compared to how physically active you have been over the
                    last 3 months, how would you describe the last 7 days :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.lastWeekActiveStatus}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    2. What forms of activity do you undertake regularly :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.activityUndertakeRegularly}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    3. How many hours did you exercise in the last 7 days :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.exerciseLastWeekPerDay}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Sedentary Exercise</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. How many total hours do you sit (sitting time) in a day?
                    (include time sitting while working, watching movies or
                    video, talking on mobile, sitting while commuting for work,
                    etc.) :
                    <span className="inputDiet">
                      {myFitness?.sedentaryExerciseTotalHourPerDay}
                    </span>
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default ChildrenExercise;
