import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Delete } from "../../icons/DeleteIcon";
import ModelPopUp from "../../shared/model/ModelPopUp";
import "./AddMoreFormMain.css";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";

function PhysiciansAndSpecialistsList() {
  const [physiciansData, setPhysiciansData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    doctorName: yup.string().required("Doctor Name is required"),
    specialization: yup.string().required("Specialization is required"),
    // visitDate: yup.string().required("Visit Date is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      doctorName: "",
      specialization: "",
      visitDate: "",
    },
  });

  const addMoreSpecialistFields = () => {
    setIsOpen(true);
  };

  const AddPhysicians_SpecialistsList = async (data) => {
    await AxiosInstance.post("doctor/add-external-doctor", data).then((response) => {
      toast.success("Physicians / Specialists Added successfully");
      setIsOpen(false);
      setLoading((prev) => !prev);
    });
    reset();
  };

  const getLabList = async () => {
    await AxiosInstance.get("doctor/list")
      .then((response) => {
        setPhysiciansData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getLabList();
  }, [loading]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const removePhysiciansFields = (doctorId) => {
    const Id = doctorId;
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this Physicians / Specialists?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (doctorId) => {
        if (doctorId.isConfirmed) {
          await AxiosInstance.delete(`doctor/delete/${Id}`).then((response) => {
            setLoading((prev) => !prev);
            toast.success(response);
          });
        }
      });
  };

  return (
    <div>
      <div className="health_Service_Providers_Information">
        <div className="info_header_wrapper">
          <div className="info_provider_content">
            <h3>Physicians/Specialists</h3>
            <p>Please List the various specialists that you usually consult with for various medical treatments.</p>
          </div>
          <div className="info_provider_button">
            <button
              type="button"
              onClick={addMoreSpecialistFields}
            >
              + Add Physicians
            </button>
          </div>
        </div>
        <div className="add_more_header_wrapper">
          <div className="field_header equal-column">Specialists</div>
          <div className="field_header equal-column">Name</div>
          {/* <div className="field_header equal-column">Last Visit</div> */}
          <div className="field_header small-column"></div>
        </div>
        {physiciansData &&
          physiciansData.map((input, index) => {
            return (
              <div
                key={index}
                className="add_more_fields_wrapper"
              >
                <p className="equal-column tableData">{input.specialization}</p>
                <p className="equal-column tableData">{input.doctorName}</p>
                {/* <p className="equal-column tableData">{input.visitDate}</p> */}
                <button
                  type="button"
                  className="remove_button small-column tableData"
                  onClick={() => removePhysiciansFields(input.id)}
                >
                  <Delete />
                </button>
              </div>
            );
          })}
      </div>
      <ModelPopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading="Add Doctor, Physicians/Specialists"
        handleSubmit={handleSubmit(AddPhysicians_SpecialistsList)}
      >
        <form className="hospital-doctor-add-form">
          <div className={`form-group ${errors.specialization ? "error" : ""}`}>
            <label>Specialization</label>
            <Controller
              name="specialization"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  placeholder="Doctor Specialization"
                  fullWidth
                  label="Doctor Specialization"
                />
              )}
            />
            <p className="error">{errors.specialization?.message}</p>
          </div>
          <div className={`form-group ${errors.doctorName ? "error" : ""}`}>
            <label>Doctor Name</label>
            <Controller
              name="doctorName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  placeholder="Doctor Name"
                  fullWidth
                  label="Doctor Name"
                />
              )}
            />
            <p className="error">{errors.doctorName?.message}</p>
          </div>
          {/* <div className={`form-group input-date-icon ${errors.visitDate ? "error" : ""}`}>
            <label>Visit Date</label>
            <Controller
              name="visitDate"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="date"
                  placeholder="Visit Date"
                />
              )}
            />
            <p className="error">{errors.visitDate?.message}</p>
          </div> */}
        </form>
      </ModelPopUp>
    </div>
  );
}

export default PhysiciansAndSpecialistsList;
