import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, Grid } from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
// import "./Admin.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
const PCODModel = ({ open, onClose }) => {
  const [errors, setErrors] = useState({})
  const [missedPeriods, setMissedPeriods] = useState(null);
  const [notHavingPeriodAtAll, setNotHavingPeriodAtAll] = useState(null);
  const [heavyBleeding, setHeavyBleeding] = useState(null);
  const [unpredictableOvulation, setUnpredictableOvulation] = useState(null);
  const [troubleGettingPregnant, setTroubleGettingPregnant] = useState(null);
  const [excessiveAcne, setExcessiveAcne] = useState(null);
  const [excessiveHairGrowth, setExcessiveHairGrowth] = useState(null);
  const [patchesOfDarkSkin, setPatchesOfDarkSkin] = useState(null);
  const [skinTags, setSkinTags] = useState(null);
  const [unexplainedWeightGain, setUnExplainedWeightGain] = useState(null);
    
  const handleMissedPeriod = (e) => {
    const value = e.target.value == "true";
    setMissedPeriods(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      missedPeriods: false,
    }));
  };
  const handleNotHavingPeriodAtAll = (e) => {
    const value = e.target.value == "true";
    setNotHavingPeriodAtAll(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      notHavingPeriodAtAll: false,
    }));
  };
  const handleHeavyBleeding = (e) => {
    const value = e.target.value == "true";
    setHeavyBleeding(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      heavyBleeding: false,
    }));
  };
  const handleUnpredictableOvulation = (e) => {
    const value = e.target.value == "true";
    setUnpredictableOvulation(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      unpredictableOvulation: false,
    }));
  };
  const handleTroubleGettingPregnant = (e) => {
    const value = e.target.value == "true";
    setTroubleGettingPregnant(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      troubleGettingPregnant: false,
    }));
  };
  const handleExcessiveAcne = (e) => {
    const value = e.target.value == "true";
    setExcessiveAcne(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      excessiveAcne: false,
    }));
  };
  const handleExcessiveHairGrowth = (e) => {
    const value = e.target.value == "true";
    setExcessiveHairGrowth(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      excessiveHairGrowth: false,
    }));
  };
  const handlePatchesOfDarkSkin = (e) => {
    const value = e.target.value == "true";
    setPatchesOfDarkSkin(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      patchesOfDarkSkin: false,
    }));
  };
  const handleSetSkinTags = (e) => {
    const value = e.target.value == "true";
    setSkinTags(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      skinTags: false,
    }));
  };
  const handleUnExplainedWeightGain = (e) => {
    const value = e.target.value == "true";
    setUnExplainedWeightGain(value)
    setErrors((prevErrors) => ({
      ...prevErrors,
      unexplainedWeightGain: false,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(`form/getPcodPcosSymtoms`);
        setMissedPeriods(response?.missedPeriods);
        setNotHavingPeriodAtAll(response?.notHavingPeriodAtAll);
        setHeavyBleeding(response?.heavyBleeding)
        setUnpredictableOvulation(response?.unpredictableOvulation);
        setTroubleGettingPregnant(response?.troubleGettingPregnant);
        setExcessiveAcne(response?.excessiveAcne);
        setExcessiveHairGrowth(response?.excessiveHairGrowth);
        setPatchesOfDarkSkin(response?.patchesOfDarkSkin);
        setSkinTags(response?.skinTags);
        setUnExplainedWeightGain(response?.unexplainedWeightGain);
      } catch (err) {
      }
    };

    fetchData();
  }, []);

 const handleSubmit = async () => {
   const Checkerrors = {};
   if (missedPeriods == null) {
     Checkerrors.missedPeriods = true;
   }
   if (notHavingPeriodAtAll == null) {
     Checkerrors.notHavingPeriodAtAll = true;
   }
   if (heavyBleeding == null) {
     Checkerrors.heavyBleeding = true;
   }
   if (unpredictableOvulation == null) { 
     Checkerrors.unpredictableOvulation = true;
   }
   if (troubleGettingPregnant == null) { 
     Checkerrors.troubleGettingPregnant = true;
   }
   if (excessiveAcne == null) { 
     Checkerrors.excessiveAcne = true;
   }
   if (excessiveHairGrowth == null) { 
     Checkerrors.excessiveHairGrowth = true;
   }
   if (patchesOfDarkSkin == null) { 
     Checkerrors.patchesOfDarkSkin = true;
   }
   if (skinTags == null) { 
     Checkerrors.skinTags = true;
   }
   if (unexplainedWeightGain == null) { 
     Checkerrors.unexplainedWeightGain = true;
   }
   
     setErrors(Checkerrors);
   if (Object.keys(Checkerrors).length > 0) {
     Swal.fire({
       icon: "error",
       title: "Oops...",
       text: "Fill all the Mandatory Fields!",
     });
   } else {
     const formData = {
       missedPeriods: missedPeriods === true, // true if the value is selected as true, otherwise false
       notHavingPeriodAtAll: notHavingPeriodAtAll === true,
       heavyBleeding: heavyBleeding === true,
       unpredictableOvulation: unpredictableOvulation === true,
       troubleGettingPregnant: troubleGettingPregnant === true,
       excessiveAcne: excessiveAcne === true,
       excessiveHairGrowth: excessiveHairGrowth === true,
       patchesOfDarkSkin: patchesOfDarkSkin === true,
       skinTags: skinTags === true,
       unexplainedWeightGain: unexplainedWeightGain === true,
     };
     Object.keys(formData).forEach((key) => {
       if (formData[key] === undefined || formData[key] === "") {
         formData[key] = false;
       }
     });
     try {
       await AxiosInstance.post(`form/addPcodPcosSymptoms`, formData)
         .then((response) => {
           toast.success("Form Submitted Successfully");
           onClose();
         })
         .catch((err) => {
           console.log("Upload error:", err);
         });
     } catch (error) {
       console.error("Error:", error);
     }
   }
 };
    return (
      <Dialog open={open} onClose={onClose} fullWidth sx={{ minHeight: "600px" }}>
        <DialogTitle>Do you have any of the following symptoms?</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Question 1 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.missedPeriods}>
                <FormLabel>1. Irregular or missed periods (longer than 40 days between periods)</FormLabel>
                <RadioGroup row value={missedPeriods} onChange={handleMissedPeriod}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Question 2 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.notHavingPeriodAtAll}>
                <FormLabel>2. Not having period at all</FormLabel>
                <RadioGroup row value={notHavingPeriodAtAll} onChange={handleNotHavingPeriodAtAll}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Question 3 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.heavyBleeding}>
                <FormLabel>3. Heavy bleeding during periods</FormLabel>
                <RadioGroup row value={heavyBleeding} onChange={handleHeavyBleeding}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Question 4 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.unpredictableOvulation}>
                <FormLabel>4. Unpredictable ovulation</FormLabel>
                <RadioGroup row value={unpredictableOvulation} onChange={handleUnpredictableOvulation}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Question 5 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.troubleGettingPregnant}>
                <FormLabel>5. Trouble getting pregnant (trying unsuccessfully for 12 months or more)</FormLabel>
                <RadioGroup row value={troubleGettingPregnant} onChange={handleTroubleGettingPregnant}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Question 6 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.excessiveAcne}>
                <FormLabel>6.Excessive acne (checkA back, chest, face, other)</FormLabel>
                <RadioGroup row value={excessiveAcne} onChange={handleExcessiveAcne}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Question 7 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.excessiveHairGrowth}>
                <FormLabel>7. Excessive hair growth (arms, chest, face, abdomen)</FormLabel>
                <RadioGroup row value={excessiveHairGrowth} onChange={handleExcessiveHairGrowth}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Question 8 */}
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.patchesOfDarkSkin}>
                <FormLabel>8. Patches of dark skin (neck, armpits, groin, under your breasts)</FormLabel>
                <RadioGroup row value={patchesOfDarkSkin} onChange={handlePatchesOfDarkSkin}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.skinTags}>
                <FormLabel>9. Skin tags (little flaps of extra skin on your neck or armpits)</FormLabel>
                <RadioGroup row value={skinTags} onChange={handleSetSkinTags}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.unexplainedWeightGain}>
                <FormLabel>10. Sudden unexplained weight gain</FormLabel>
                <RadioGroup row value={unexplainedWeightGain} onChange={handleUnExplainedWeightGain}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
};
export default PCODModel;
