import React from "react";
import DataTableWrapper from "../../components/table_wrapper_component/DataTableWrapper";
import "./style.css";

export const ElementLabTestRecords = () => {
  return (
    <>
      <DataTableWrapper />
    </>
  );
};
