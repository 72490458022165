/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconFilledHelp } from "../IconFilledHelp";
import { IconSupport } from "../IconSupport";
import "./style.css";

export const NavsHelpCenter = ({ property1, style, text = "Help Center" }) => {
  return (
    <div className={`navs-help-center property-1-4-${property1}`} style={style}>
      <div className="menu-item-2">
        <div className="frame-5">
          <div className="frame-6">
            <div className="frame-7">
              {property1 === "default" && (
                <IconFilledHelp
                  iconFilledHelp="/img/icon-filled-help.png"
                  style={{
                    height: "21px",
                    left: "unset",
                    minWidth: "21px",
                    position: "relative",
                    top: "unset",
                    width: "unset",
                  }}
                />
              )}

              {property1 === "variant-2" && (
                <IconSupport
                  iconSupport="/img/icon-support.png"
                  style={{
                    height: "21px",
                    left: "unset",
                    minWidth: "21px",
                    position: "relative",
                    top: "unset",
                    width: "unset",
                  }}
                />
              )}

              <div className="help-center">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsHelpCenter.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  text: PropTypes.string,
};
