import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getOrderDetailApi } from "../../constants/apiList";
import { Col, Row, Card, CardText } from 'reactstrap';

const OrderDetailInfo = ({ orderId }) => {
    const [orderDetail, setOrderDetail] = useState(null);
    const [labDetail, setLabDetail] = useState(null);
    console.log('labDetail: ', labDetail);
    const [patientDetail, setPatientDetail] = useState(null);
    console.log('patientDetail: ', patientDetail);
    console.log('orderDetail: ', orderDetail);

    const getPaymentDetail = async () => {
        try {
            const response = await AxiosInstance.get(`${getOrderDetailApi}/${orderId}`);
            setOrderDetail(response);
            setLabDetail(response.labDetail);
            setPatientDetail(response.patientDetail);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    useEffect(() => {
        getPaymentDetail();
    }, []);

    // if (!orderDetail) {
    //     return <div>Loading...</div>;
    // }

    return (<>
        {patientDetail && (<>
            <Card
                style={{
                    padding: "15px",
                    border: "1px solid black",
                    margin: "10px 0px 10px 0px",
                }}
                elevation={5}
            >
                <Row>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Patient Name : </b>{patientDetail.patientName}</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Patient Contact Number : </b>{patientDetail.patientContactNumber}</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>patient Age : </b>{patientDetail.patientAge}</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Patient Gender : </b>{patientDetail.patientGender}</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>UHID : </b>{patientDetail.patientUpId}</CardText>
                    </Col>
                </Row>
            </Card>
        </>)}
        {labDetail && (<>
            <Card
                style={{
                    padding: "15px",
                    border: "1px solid black",
                    margin: "10px 0px 10px 0px",
                }}
                elevation={5}
            >
                <Row>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Lab Name : </b>{labDetail.labName}</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Lab PinCode : </b>{labDetail.labPinCode}</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>labAddress : </b>{labDetail.labAddress}</CardText>
                    </Col>
                </Row>
            </Card>
        </>)}
        {orderDetail && (<>
            <Card
                style={{
                    padding: "15px",
                    border: "1px solid black",
                    margin: "10px 0px 10px 0px",
                }}
                elevation={5}
            >
                <Row>
                    {/* <Col lg={6}>
                    <CardText className="mt-1"><b>Lab Name : </b>{orderDetail.labName}</CardText>
                </Col> */}
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Test Price : </b>{orderDetail.totalPrice} INR</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Order Id : </b>{orderDetail.orderId}</CardText>
                    </Col>
                    <Col lg={12}>
                        <div className="mt-1">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th><b>Sr No.</b></th>
                                        <th><b>Test Name</b></th>
                                        <th><b>Test Price</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetail.testDetail.map((detail, idx) => (
                                        <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>{detail.testName}</td>
                                            <td>{detail.testPrice ? `${detail.testPrice} INR` : 'Price not available'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>)}
    </>
    );
};

export default OrderDetailInfo;
