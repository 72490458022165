import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, MenuItem, Button } from "@mui/material";
import "./formStyle.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import { calculateAge } from "../../util/Helper";

const defaultValues = {
  profile: {
    firstName: "",
    lastName: "",
    middleName: "",
    aadharNumber: "",
    age: "",
    dob: "",
    gender: "",
    preferredLanguage: "",
    contactNumber: "",
    email: "",
    alternatePhoneNumber: "",
    generation: "",
    registrationDate: "",
  },
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    birthPlace: "",
  },
  upId: "",
};

const GenderDobModal = ({ open, handleClose }) => {
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [profile, setProfile] = useState();
  const [generation, setGeneration] = useState("");
  const [age, setAge] = useState("");

  const handleGenerationAge = (date) => {
    const ageGeneration = date && calculateAge(date);
    setGeneration(ageGeneration.generation);
    setAge(ageGeneration.age);
  };

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setProfile(response?.profile == null ? defaultValues : response);
        const apiGender = response?.profile.gender; // Adjust based on your API response structure
        if (apiGender) {
          setGender(apiGender.toLowerCase());
        }
        setDob(response?.profile.dob);
        if (response?.profile.dob) {
          handleGenerationAge(response?.profile.dob);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleSubmit = async () => {
    const sessionData = JSON.parse(sessionStorage.getItem("userDetails"));
    const { firstName, lastName, phoneNumber, email } = sessionData;
    const updatedValues = { ...profile };

    // Update gender and dob in the profile
    updatedValues.profile.gender = gender;
    updatedValues.profile.dob = dob;
    updatedValues.profile.age = age;

    // Optionally update generation if dob is present
    if (dob) {
      updatedValues.profile.generation = generation;
    }
    updatedValues.profile.firstName = firstName;
    updatedValues.profile.lastName = lastName;
    updatedValues.profile.contactNumber = phoneNumber;
    updatedValues.profile.email = email;
    try {
      await AxiosInstance.post("profile/save", updatedValues).then(() => {
        toast.success("Details Submitted Successfully");
        handleClose();
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDateChange = (event) => {
    setDob(event.target.value);
    const ageGeneration =
      event.target.value && calculateAge(event.target.value);
    setGeneration(ageGeneration.generation);
    setAge(ageGeneration.age);
  };

  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className="modalBox">
        <h5>
          Before we proceed with the survey, kindly update/review
          your below details.
        </h5>
        <TextField
          select
          label="Gender"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          className="ModalformField"
          variant="outlined"
          required
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
        </TextField>
        <TextField
          label="Date of Birth"
          type="date"
          value={dob}
          onChange={handleDateChange}
          className="ModalformField"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          required
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className="Modalbutton"
          disabled={!gender || !dob}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default GenderDobModal;
