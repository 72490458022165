export const vaccineNames = [
    "BCG",
    "OPV-0",
    "Hep B-1 (Birth Dose)",
    "DTWP/DTaP-1",
    "IPV-1",
    "Hib-1",
    "Hep B-2",
    "Rota-1",
    "PCV-1",
    "DTWP/DTaP-2",
    "IPV-2",
    "Hib-2",
    "Hep B-3",
    "Rota-2",
    "PCV-2",
    "DTWP/DTaP-3",
    "IPV-3",
    "Hib-3",
    "Hep B-4",
    "Rota-3",
    "PCV-3",
    "Influenza(IIV)-1",
    "Influenza(IIV)-2",
    "1 dose every year till 5 year",
    "Year 2",
    "Year 3",
    "Year 4",
    "Year 5",
    "TCV",
    "MMR-1",
    "MCV-1",
    "MCV-1",
    "Hep-1",
    "MCV-2",
    "JE-1",
    "JE-2",
    "MMR-2",
    "Vercella-1",
    "PCV booster",
    "DTWP/DTaP-B1",
    "Hib-B1",
    "IPV-B1",
    "Hep A-2 (for killed vaccine only",
    "Vericella-2",
    "DTWP/DTaP-B2",
    "IPV-B2",
    "MMR-3",
    "Tdap",
    "HPV-1",
    "HPV-2",
  ];