import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import "./style.css";

const RoleSelectionPage = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setSelectedRole(selectedRole);
    if (selectedRole === "user") {
      navigate("/login-email");
    } else if (selectedRole === "TESTLAB") {
      navigate("/lab-sign-in");
    } else if (selectedRole === "DOCTOR") {
      navigate("/doctor-sign-in");
    } else if (selectedRole === "DIETITIAN") {
      navigate("/dietician-sign-in");
    } else if (selectedRole === "CORPORATE") {
      navigate("/corporate-sign-in");
    } else if (selectedRole === "PHYSICIAN") {
      navigate("/physiotherapist-sign-in");
    } else if (selectedRole === "admin") {
      navigate("/admin-sign-in");
    }else if (selectedRole === "GYM") {
      navigate("/gym-sign-in");
    }else {
      navigate("/research-sign-in");
    }
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/get-partner-type`)
      .then((response) => {
        // Transform role names
        const transformedRoles = response.data.data.map((role) => {
          let transformedRole = role.partnerType
            .toLowerCase()
            .replace(/_/g, " ");
          transformedRole = transformedRole
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          // Special cases for specific roles
          if (role.partnerType === "PHYSICIAN") {
            transformedRole = "Physiotherapist";
          } else if (role.partnerType === "TESTLAB") {
            transformedRole = "Medical Lab";
          }

          return {
            id: role.id,
            partnerType: role.partnerType,
            displayName: transformedRole,
          };
        });
        setRoles(transformedRoles);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  console.log("roles", roles);

  return (
    <>
      <div className="background-container">
        <div className="logo-container">
          <img alt="Health buddy" src="/img/AppLogoWithName.png" />
        </div>
        <div
          className="content-container"
          style={{ backgroundColor: "rgb(221 221 232)" }}
        >
          <div style={{ padding: "6%" }}>
            <b>
              Please select your role first form dropdown below for proper
              authorization.
            </b>
            <br></br>
            <br></br>
            <select
              class="custom-select"
              id="inputGroupSelect03"
              aria-label="Example select with button addon"
              style={{
                textAlign: "center",
                marginLeft: "30%",
                border: "1px solid blue",
              }}
              onChange={handleRoleChange}
              value={selectedRole}
            >
              <option selected>Select Role</option>
              <option value="user">User</option>
              <option value="admin">Admin</option>
              {/* <option value="medicalLab">Medical Lab</option>
              <option value="doctor">Doctors</option>
              <option value="dieticians">Dieticians</option>
              <option value="physiotherapist">Physiotherapist</option>
              <option value="corporate">Corporate</option> */}
              {roles?.map((role) => (
                <option key={role.id} value={role.partnerType}>
                  {role.displayName}
                </option>
              ))}
            </select>
            <br></br>
            <br></br>
            <div
              style={{
                border: "1px dotted black",
                padding: "20px",
                backgroundColor: "rgb(185 184 197)",
                borderRadius: "10px",
                marginTop: "15px",
              }}
            >
              <b>Role Definitions</b>
              <hr
                style={{
                  width: "20%",
                  marginTop: "10px",
                  backgroundColor: "black",
                  height: "2px",
                }}
              ></hr>
              <b>User : </b> This is for consumers or our clients using Uncurl
              health app.<br></br>
              <b>Medical Labs : </b> This is for our brand partners providing
              Medical Lab Service to our client.<br></br>
              <b>Doctors : </b> This is for Doctors or Physicians who have
              joined our network to provide services to their patients using
              Uncurl app or providing other services to our clients.
              <br></br>
              <b>Dieticians : </b> This is Dieticians who are providing Diet
              counseling preventive service to our clients.<br></br>
              <b>Research : </b> This is for medical research practitioners who
              are using Uncurl platform for cutting edge medical research
              studies.<br></br>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RoleSelectionPage;
