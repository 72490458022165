import PropTypes from "prop-types";
import React from "react";
import { IconOverview } from "../IconOverview";
import "./NavsRiskAssessment.css";
import { NavLink } from "react-router-dom";
import { IconSupport } from "../IconSupport/IconSupport";

export const NavsRiskAssessment = ({ property1, style, frameStyle }) => {
  return (
    <div className={`navs-NavsRiskAssessment ${property1}`} style={style}>
      <div className="frame" style={frameStyle}>
        <div className="frame-wrapper">
          <div className="div">
            {property1 === "default" && <IconSupport></IconSupport>}

            {property1 === "variant-2" && <IconSupport></IconSupport>}

            <div className="component-8">
              <div className="menu-item-8">
                <div className="frame-49">
                  <div className="frame-50">
                    <div className="frame-51">
                      <NavLink
                        to="/risk-assessment"
                        className="text-wrapper-106 menu-item overview"
                      >
                        Risk Assessment
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsRiskAssessment.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconFilledOverviewIconFilledOverview: PropTypes.string,
};
