import { MenuItem, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Delete } from "../../icons/DeleteIcon";
import ModelPopUp from "../../shared/model/ModelPopUp";
import "./AddMoreFormMain.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";

function LabsAndTestCenterList() {
  const [labData, setLabData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [hospitalsnew, setLabAndTestCenterNew] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cityNames, setCityNames] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const validationSchema = yup.object().shape({
    labName: yup.string().required("Please Select Lab Name"),
    city: yup.string().required("Please enter Location / Address"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { labName: "", city: "" },
  });

  const addMoreLabsAndTestCenterFields = () => {
    setIsOpen(true);
  };

  const AddLabsAndTestCentersList = async (data) => {
    await AxiosInstance.post("lab/add-new-lab", data).then((response) => {
      toast.success("Labs and Test Center Added successfully");
      setIsOpen(false);
      setLoading(true);
    });
    reset();
  };

  const getLabList = async () => {
    await AxiosInstance.get("lab/list")
      .then((response) => {
        setLabData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getLabList();
  }, [loading]);

  const getLabAndTestCenterCity = async () => {
    if (selectedValue?.label.length >= 3) {
      await AxiosInstance.get(`master-labs/list?search=${encodeURIComponent(selectedValue?.label)}`)
        .then((data) => {
          setLabAndTestCenterNew(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedValue?.label.trim() === "") {
      setLabAndTestCenterNew([]);
      return;
    }
    setLoading(true);
    getLabAndTestCenterCity();
  }, [selectedValue]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const removeLabsFields = (labId) => {
    const Id = labId;
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this Labs / Test Centers?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (labId) => {
        if (labId.isConfirmed) {
          await AxiosInstance.delete(`lab/delete/${Id}`).then((response) => {
            setLoading((prev) => !prev);
            toast.success(response);
          });
        }
      });
  };

  const getCityList = async () => {
    try {
      const response = await AxiosInstance.get("lab/city/list");
      setCityNames(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCityList();
  }, []);

  const structuredCityData = cityNames.map((city) => ({
    label: city,
  }));

  return (
    <div>
      <div className="health_Service_Providers_Information">
        <div className="info_header_wrapper">
          <div className="info_provider_content">
            <h3>Labs / Test Centers</h3>
            <p>Please List the Labs/Test Centers that you usually use for medical tests.</p>
          </div>
          <div className="info_provider_button">
            <button
              type="button"
              onClick={addMoreLabsAndTestCenterFields}
            >
              + Add Lab/Test Centers
            </button>
          </div>
        </div>
        <div className="add_more_header_wrapper">
          <div className="field_header equal-column">Lab / Test Centers</div>
          <div className="field_header equal-column">Location / Address</div>
          <div className="field_header small-column"></div>
        </div>
        {labData &&
          labData.map((input, index) => {
            return (
              <div
                key={index}
                className="add_more_fields_wrapper"
              >
                <p className="equal-column tableData">{input.labName}</p>
                <p className="equal-column tableData">{input.city}</p>

                <button
                  type="button"
                  className="remove_button small-column tableData"
                  onClick={() => removeLabsFields(input.id)}
                >
                  <Delete />
                </button>
              </div>
            );
          })}
      </div>
      <ModelPopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading="Add New Labs / Test Center"
        handleSubmit={handleSubmit(AddLabsAndTestCentersList)}
      >
        <form className="hospital-doctor-add-form">
          <label>Please enter Location / Address</label>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Autocomplete
                disablePortal
                options={structuredCityData}
                getOptionLabel={(option) => option?.label || ""}
                clearOnEscape={true}
                onChange={(e, newValue) => {
                  field.onChange(newValue?.label);
                  setSelectedValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus={true}
                    label="Please enter Location / Address"
                  />
                )}
              />
            )}
          />
          <p className="error">{errors.city?.message}</p>
          <div className={`form-group ${errors?.labName ? "error" : ""}`}>
            <label>Lab Name</label>
            <Controller
              name="labName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-select-currency"
                  select
                  label="Select Lab Name"
                  fullWidth
                >
                  {hospitalsnew.length === 0 ? (
                    <MenuItem
                      className="no-data-found"
                      value=""
                    >
                      No record found
                    </MenuItem>
                  ) : (
                    hospitalsnew.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.labName}
                      >
                        {option.labName}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              )}
            />
            <p className="error">{errors.labName?.message}</p>
          </div>
        </form>
      </ModelPopUp>
    </div>
  );
}

export default LabsAndTestCenterList;
