import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import "./LifeStyleRiskAssessment.css";
import RiskScore from "./components/RiskScore";
import RisksDiseaseMap from "./components/RisksDiseaseMap";
import SilverScreensProfileCards from "../profileRegistration/SilverScreensProfileCards";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const LifeStyleRiskAssessmentSurvey = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 1 }}>
            <SilverScreensProfileCards
              RiskScoreNext={() => {
                setCurrentTabIndex((prev) => prev + 1);
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default LifeStyleRiskAssessmentSurvey;
