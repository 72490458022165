import React from "react";
import { Paper, Checkbox } from "@mui/material";

const FamilyMemberCard = ({
  familyMemberName,
  familyMemberData,
  handleLivingStatusChange,
  handleAgeChange,
  handleDiseaseChange,
  familyCancerInfo,
}) => {
  const { livingStatus = "", age = "", diseases = "" } = familyMemberData || {};
  const diseaseOptions = [
    { label: "No Disease", value: "No Disease" },
    { label: "COVID-19 Positive", value: "COVID-19 Positive" },
    { label: "Asthma", value: "Asthma" },
    { label: "Diabetes", value: "Diabetes" },
    { label: "Hypertension (High Blood Pressure)", value: "Hypertension" },
    { label: "Coronary Artery / Heart Disease", value: "Cardiac" },
    { label: "Stroke", value: "Stroke" },
    { label: "Lungs/COPD", value: "Lungs/COPD" },
    { label: "Cancer", value: "Cancer" },
    { label: "Colon Polyps", value: "Colon Polyps" },
    { label: "Kidney Disease", value: "Kidney Disease" },
    { label: "Depression", value: "Depression" },
    { label: "PCOD/PCOS", value: "PCOD/PCOS" },
    { label: "Thyroid", value: "Thyroid" },
  ];

  return (
    // <Paper className="card-container">
    //   <div className="card-header">{familyMemberName}</div>
    //   <div className="card-row">
    //     <span>Living Status:</span>
    //     <select
    //       value={livingStatus}
    //       onChange={(e) =>
    //         handleLivingStatusChange(familyMemberName, e.target.value)
    //       }
    //     >
    //       <option value="">Select</option>
    //       <option value="L">Living</option>
    //       <option value="D">Deceased</option>
    //       <option value="U">Unknown</option>
    //     </select>
    //   </div>
    //   <div className="card-row">
    //     <span>Disease At What Age ?</span>
    //     <div style={{ display: "flex" }}>
    //       <input
    //         type="number"
    //         style={{ width: "65px", textIndent: "10px", marginRight: "5px" }}
    //         value={age}
    //         onChange={(e) => handleAgeChange(familyMemberName, e.target.value)}
    //       />
    //       <span>yrs</span>
    //     </div>
    //   </div>
    //   {diseaseOptions.map((disease, diseaseIndex) => (
    //     <div className="card-row" key={diseaseIndex}>
    //       <span>{disease}:</span>
    //       {disease !== "Cancer" && (
    //         <Checkbox
    //           checked={diseases.includes(disease)}
    //           onChange={() => handleDiseaseChange(familyMemberName, disease)}
    //         />
    //       )}
    //       {disease === "Cancer" && (
    //         <React.Fragment>
    //           <div>
    //             <Checkbox
    //               checked={diseases.includes("Cancer")}
    //               onChange={() =>
    //                 handleDiseaseChange(familyMemberName, "Cancer")
    //               }
    //             />
    //             {diseases.includes("Cancer") && (
    //               <a
    //                 href="#"
    //                 onClick={() => familyCancerInfo(familyMemberName)}
    //               >
    //                 Info
    //               </a>
    //             )}
    //           </div>
    //         </React.Fragment>
    //       )}
    //     </div>
    //   ))}
    // </Paper>
    <Paper className="card-container">
      <div className="card-header">{familyMemberName}</div>
      <div className="card-row">
        <span>Living Status:</span>
        <select
          value={livingStatus}
          onChange={(e) =>
            handleLivingStatusChange(familyMemberName, e.target.value)
          }
        >
          <option value="">Select</option>
          <option value="L">Living</option>
          <option value="D">Deceased</option>
          <option value="U">Unknown</option>
        </select>
      </div>
      <div className="card-row">
        <span>Disease At What Age ?</span>
        <div style={{ display: "flex" }}>
          <input
            type="number"
            style={{ width: "65px", textIndent: "10px", marginRight: "5px" }}
            value={age}
            onChange={(e) => handleAgeChange(familyMemberName, e.target.value)}
          />
          <span>yrs</span>
        </div>
      </div>
      {diseaseOptions.map((disease, diseaseIndex) => (
        <div className="card-row" key={diseaseIndex}>
          <span>{disease.label}:</span>
          {disease.value !== "Cancer" && (
            <Checkbox
              checked={diseases.includes(disease.value)}
              onChange={() =>
                handleDiseaseChange(familyMemberName, disease.value)
              }
            />
          )}
          {disease.value === "Cancer" && (
            <React.Fragment>
              <div>
                <Checkbox
                  checked={diseases.includes("Cancer")}
                  onChange={() =>
                    handleDiseaseChange(familyMemberName, "Cancer")
                  }
                />
                {diseases.includes("Cancer") && (
                  <a
                    href="#"
                    onClick={() => familyCancerInfo(familyMemberName)}
                  >
                    Info
                  </a>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      ))}
    </Paper>
  );
};

export default FamilyMemberCard;
