import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getSymptomsYearly } from "../../constants/apiList";
import SymptomList from "../../shared/SymptomList";
import { usePagination } from "../../util/utils";

const CorporatreSymptomsYearly = ({ setYearlyTopSymptoms }) => {
  const [yearlySymptomsCum, setYearlySymptomsCum] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [symptomsData, setSymptomsData] = useState();
  const {
    currentPage,
    totalPages,
    currentData,
    handleNextPage,
    handlePreviousPage,
  } = usePagination(symptomsData);

  const handleAxisLabel = (selectedRow, index) => {
    setYearlySymptomsCum(selectedRow.symptomsMonthCumsum);
    setSelectedYear(selectedRow.year);
  };
  const Data = [
    {
      year: 2017,
      month: 12,
      patientId: 372,
      medicinesMonthCumsum: null,
      symptomsMonthCumsum:
        "{'insomnia': 1, 'sleep_difficulty': 1,'indigestion': 1, 'bloating': 1, 'gas': 1,'cold_intolerance': 1 ,'pain': 1, 'breahlessness': 1 }",
    },
    {
      year: 2019,
      month: 10,
      patientId: 372,
      medicinesMonthCumsum: null,
      symptomsMonthCumsum:
        "{'limb': 1, 'pain': 1, 'breahlessness': 1, 'chest': 1, 'tenderness': 1}",
    },
  ];

  const getSymptomsYearlyData = async () => {
    // AxiosInstance.get(`${getSymptomsYearly}`)
    //   .then((res) => {
    //     const modifiedData = res.map((item) => ({
    //       ...item,
    //       symptomsMonthCumsum: JSON.parse(
    //         item.symptomsMonthCumsum.replace(/'/g, '"')
    //       ),
    //     }));
    //     const topSymptomsYearly = modifiedData.map((entry) => {
    //       const { patientId, year, month, symptomsMonthCumsum, ...rest } =
    //         entry;
    //       const firstThreeKeys = Object.keys(symptomsMonthCumsum).slice(0, 3);
    //       const extractedSymptoms = Object.fromEntries(
    //         firstThreeKeys.map((key) => [key, symptomsMonthCumsum[key]])
    //       );
    //       return {
    //         patientId,
    //         year,
    //         month,
    //         symptomsMonthCumsum: extractedSymptoms,
    //         ...rest,
    //       };
    //     });
    //     setSymptomsData(modifiedData);
    //     setYearlyTopSymptoms(topSymptomsYearly);
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });

    const modifiedData = Data.map((item) => ({
      ...item,
      symptomsMonthCumsum: JSON.parse(
        item.symptomsMonthCumsum.replace(/'/g, '"')
      ),
    }));
    setSymptomsData(modifiedData);
    // setYearlyTopSymptoms(topSymptomsYearly);
  };

  useEffect(() => {
    if (symptomsData?.length > 0) {
      setYearlySymptomsCum(
        symptomsData[symptomsData?.length - 1].symptomsMonthCumsum
      );
      setSelectedYear(symptomsData[symptomsData?.length - 1].year);
    }
  }, [symptomsData]);

  useEffect(() => {
    getSymptomsYearlyData();
  }, []);

  return (
    <>
      <div className="chart-item" >
        <div className="graph-card-title">
          What kind of Symptoms employees experience ? (
          <span className="month-year-as-of">
            {selectedYear && selectedYear}
          </span>
          )
        </div>
        <SymptomList cumulativeData={yearlySymptomsCum} />
        <div
          style={{
            margin: "0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            alignItems: "end",
          }}
        >
          <IconButton
            onClick={handlePreviousPage}
            disabled={currentPage - 1 < 0}
            sx={{
              padding: "5px",
              minWidth: "auto",
              marginBottom: "15px",
              fontSize: "10px",
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <div className="month-slider-wrapper">
            <ul className="slider-months">
              {currentData?.map((element, index) => {
                return (
                  <li
                    key={index}
                    className={`month-item ${
                      selectedYear === element.year ? "active" : ""
                    }`}
                    onClick={() => handleAxisLabel(element, index)}
                  >
                    <Button
                      variant="string"
                      sx={{ padding: 0, minWidth: "auto" }}
                    >
                      {element.year}
                    </Button>
                  </li>
                );
              })}
            </ul>
          </div>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage + 1 >= totalPages}
            sx={{
              padding: "5px",
              minWidth: "auto",
              marginBottom: "15px",
              fontSize: "10px",
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        <hr />
        <div className="graph-footer">
          <p className="graph-note">
            Note : Please click on the year to see the data
          </p>
        </div>
      </div>
    </>
  );
};

export default CorporatreSymptomsYearly;
