import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Form, FormGroup, FormFeedback, FormText, Label, Input, Row, Col, Button } from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./style.css";
import InvoiceModal from "./InvoiceModal";
const Invoice = () => {
  const [rowData, setRowData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [tableData, setTableData] = useState(rowData);

  const handleOpenModal = (orderId) => {
    setSelectedOrderId(orderId);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  const getInvoiceDetail = async() => {
    await AxiosInstance.get("lab-partner/getLabInvoice")
    .then((response) => {
      if (response != null) {
        setRowData(response);
      } else {
        setRowData([]);
      }
    })
    .catch((err) => {
      console.log('err: ', err);
    });
  }

  const handleSubmit = (invoiceDetails) => {
    setOpenModal(false);
    getInvoiceDetail()
  };
  
  useEffect(()=>{
    getInvoiceDetail();
  }, []);
  
  const handleInvoiceDownload = async (docLink) => {
    const downloadApi = "admin/download-invoice-file";
    let token = window.sessionStorage.getItem("token");
    let myHeaders = new Headers();
    myHeaders.append("accept", "multipart/mixed");
    myHeaders.append("Authorization", "Bearer " + token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const encodedPath = encodeURIComponent(docLink);
      const responseDoc = await fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedPath}`, requestOptions);

      if (!responseDoc.ok) {
        throw new Error(`Network response was not ok: ${responseDoc.status}`);
      }

      const contentDispositionHeader = responseDoc.headers.get("Content-Disposition");

      if (contentDispositionHeader) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
        if (matches != null && matches[1]) {
          const filename = matches[1].replace(/['"]/g, "");
          responseDoc.blob().then((blobData) => {
            const blobUrl = window.URL.createObjectURL(blobData);

            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = filename;
            a.click();

            window.URL.revokeObjectURL(blobUrl);
          });
        } else {
          console.error("Filename not found in Content-Disposition header");
        }
      } else {
        console.error("Content-Disposition header not found in the response");
      }
    } catch (error) {
      console.error("Error fetching or downloading the file:", error);
    }
  };
    return (
      <>
        <h3>Invoice</h3>
        <p style={{ marginTop: "1%" }}>
          <b>
            {/* An example will be shown below for invoice.  */}
            System will automatically generate the invoice information and you will be able to attach your invoice for payment.</b>
        </p>
        <TableContainer>
          <Table style={{ border: "1px solid lightgrey", marginTop: "20px" , whiteSpace : "nowrap"}}>
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">LSH Order ID </TableCell>
                <TableCell className="tableCell">Invoice Month/Year</TableCell>
                <TableCell className="tableCell">Total Orders</TableCell>
                <TableCell className="tableCell">Total Amount</TableCell>
                <TableCell className="tableCell">Action</TableCell>
                <TableCell className="tableCell">Invoice File</TableCell>
                <TableCell className="tableCell">Invoice Date</TableCell>
                <TableCell className="tableCell">Invoice Amount</TableCell>
                <TableCell className="tableCell">Total Confirmed?</TableCell>
                <TableCell className="tableCell">Invoice Approval</TableCell>
                <TableCell className="tableCell">Comments</TableCell>
                <TableCell className="tableCell">Account Mgr. Comments</TableCell>
                <TableCell className="tableCell">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.orderId}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.orderDate}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.totalQuantity}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.totalAmount}</TableCell>
                  <TableCell style={{ border: "1px solid black" }}>
                   {row.totalConfirmed != "Yes" && <Button variant="contained" onClick={() => handleOpenModal(row.orderId)}>
                      Add Invoice
                    </Button>} 
                </TableCell>
                  <TableCell style={{ border: "1px solid black" }}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleInvoiceDownload(row.invoiceFile);
                      }}
                    >
                    {row.invoiceFile}
                    </a>
                  </TableCell>
                  <TableCell style={{ border: "1px solid black" }}>{row.invoiceDate}</TableCell>
                  <TableCell style={{ border: "1px solid black" }}>{row.invoiceAmount}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.totalConfirmed}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.approval}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.comments}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.managerComment ? row.managerComment : "-"}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedOrderId && (
        <InvoiceModal
          open={openModal}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          orderId={selectedOrderId}
        />
      )}
      </>
    );
};
export default Invoice;
