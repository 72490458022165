import React, { useEffect, useState } from 'react';

const BrowserCheck = ({ url, onBrowserCheck }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Detect mobile devices
    const isMobile = /android|iphone|ipad|ipod/i.test(userAgent);
    if (!isMobile) {
      onBrowserCheck(false);
      return;
    }

    const isAndroid = /android/i.test(userAgent);
    const isChrome = /chrome|crios/i.test(userAgent);
    const isIPhone = /iphone|ipad|ipod/i.test(userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

    if ((isAndroid && !isChrome) || (isIPhone && !isSafari)) {
      setShowMessage(true);
      onBrowserCheck(true);
    } else {
      onBrowserCheck(false);
    }
  }, [onBrowserCheck]);

  const handleOpenInChrome = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      // Try to open in Chrome, fallback to Play Store
      const chromeIntent = `intent://${url.replace(/^https?:\/\//, '')}#Intent;scheme=http;package=com.android.chrome;end`;
      const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.android.chrome';

      window.location.href = chromeIntent;

      setTimeout(() => {
        if (!document.hidden) {
          window.location.href = playStoreUrl;
        }
      }, 1000);
    } else if (/iphone|ipad|ipod/i.test(userAgent)) {
      // Try to open in Chrome, fallback to App Store
      const chromeUrl = `googlechrome://${url}`;
      const appStoreUrl = 'https://apps.apple.com/us/app/google-chrome/id535886823';

      window.location.href = chromeUrl;

      setTimeout(() => {
        if (!document.hidden) {
          window.location.href = appStoreUrl;
        }
      }, 1000);
    }
  };

  return (
    <div>
      {showMessage && (
        <div className="browser-warning">
          <p>For a better experience, please open this link in Chrome.</p>
          <button onClick={handleOpenInChrome}>Open in Chrome</button>
        </div>
      )}
    </div>
  );
};

export default BrowserCheck;
