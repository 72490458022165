import PropTypes from "prop-types";
import React from "react";
import { IconOverview } from "../IconOverview";
import "./NavsTrendsToWatch.css";
import { NavLink } from "react-router-dom";
import { IconSupport } from "../IconSupport/IconSupport";

export const NavsTrendsToWatch = ({ property1, style, frameStyle, overviewStyle }) => {
  return (
    <div
      className={`navs-trendsToWatch ${property1}`}
      style={style}
    >
      <div className="menu-item">
        <div
          className="frame"
          style={frameStyle}
        >
          <div className="frame-wrapper">
            <div className="div">
              {property1 === "default" && (
                <IconSupport></IconSupport>
              )}

              {property1 === "variant-2" && (
                <IconSupport></IconSupport>
              )}

              <div className="component-8">
                <div className="menu-item-8">
                  <div className="frame-49">
                    <div className="frame-50">
                      <div className="frame-51">
                        <NavLink
                          to="/trends-to-watch"
                          className="text-wrapper-106 menu-item overview"
                        >
                          Trends To Watch
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsTrendsToWatch.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconFilledOverviewIconFilledOverview: PropTypes.string,
};
