import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { IconMail } from "../../components/IconMail";
import { IconUser } from "../../components/IconUser";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { setCookies } from "../../configration/HandleCookies";
import IndiaFlag from "../../img/india-flag.png";
import ToastMessage from "../../shared/ToastMessage";
import "./style.css";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import Spinner from "../../shared/Spinner";

// Update: Function should accept props
export const MedicalLabSignUpModel = ({ open, onClose, onSuccess }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setNumber] = useState("");
  const [labName, setLabName] = useState("");
  const [firstNameerrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastNameerrorMessage, setLastNameErrorMessage] = useState("");
  const [phoneNumbererrorMessage, setphoneNumberErrorMessage] = useState("");
  const [emailerrorMessage, setemailErrorMessage] = useState("");
  const [labNameerrorMessage, setLabNameErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameErrorMessage("");
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameErrorMessage("");
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setemailErrorMessage("");
  };

  const onPhoneNumberChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setNumber(result);
    if (result) {
      setphoneNumberErrorMessage("");
    }
  };

  const onLabNameChange = (e) => {
    setLabName(e.target.value);
    setLabNameErrorMessage("");
  };

  const getSubscriptions = async () => {
    return await AxiosInstance.get(`subscription/get-detail`)
      .then((response) => {
        return response.subscriptionStatus;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SignUp = async () => {
    if (firstName === "" || firstName == null || firstName == undefined) {
      setFirstNameErrorMessage("Please enter the First Name");
      return;
    }
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
    if (!regex.test(firstName)) {
      setFirstNameErrorMessage(
        "Please enter a combination of alphabets and numbers."
      );
      return;
    }
    if (lastName === "" || lastName == null || lastName == undefined) {
      setLastNameErrorMessage("Please enter the Last Name");
      return;
    }
    const regex1 = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;

    if (!regex1.test(lastName)) {
      setLastNameErrorMessage(
        "Please enter a combination of alphabets and numbers."
      );
      return;
    }
    if (phoneNumber === "" || phoneNumber == null || phoneNumber == undefined) {
      setphoneNumberErrorMessage("Please enter the phone number");
      return;
    }
    const regex2 = /^\d{10}$/;
    if (!regex2.test(phoneNumber)) {
      setphoneNumberErrorMessage("Phone number must be 10 digits long.");
      return;
    }
    if (email === "" || email == null || email == undefined) {
      setemailErrorMessage("Please enter the email");
      return;
    }

    if (labName === "" || labName == null || labName == undefined) {
      setLabNameErrorMessage("Please enter the Lab Name");
      return;
    }
    const regex3 = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
    if (!regex3.test(labName)) {
      setLabNameErrorMessage(
        "Please enter a combination of alphabets and numbers."
      );
      return;
    }

    const formData = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      labName: labName,
    };
    setLoading(true);

    await AxiosInstance.post("api/lab-partner/auth/signup", formData)
      .then((response) => {
        toast.success(response);
        setLoading(false);
        onClose(); // Close modal after successful signup
        onSuccess();
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{ minHeight: "400px" }}
      className="sign-up"
    >
      <DialogTitle>Medical Lab Partner's Sign Up</DialogTitle>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <DialogContent>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <Row>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">First Name</div>
                    <div className="overlap-group-3">
                      <IconUser
                        className="icon-user-instance"
                        iconUser="/img/icon-user.png"
                      />
                      <input
                        type="text"
                        onChange={onFirstNameChange}
                        value={firstName}
                        className="text-wrapper-7"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  {firstNameerrorMessage && (
                    <div className="error-message">{firstNameerrorMessage}</div>
                  )}
                </Col>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">Last Name</div>
                    <div className="overlap-group-3">
                      <IconUser
                        className="icon-user-instance"
                        iconUser="/img/icon-user.png"
                      />
                      <input
                        type="text"
                        onChange={onLastNameChange}
                        value={lastName}
                        className="text-wrapper-7"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  {lastNameerrorMessage && (
                    <div className="error-message">{lastNameerrorMessage}</div>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <Row>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">Phone Number</div>
                    <div className="overlap-group-2">
                      <img
                        className="icon-feather-phone"
                        alt="Icon feather phone"
                        src={IndiaFlag}
                      />
                      <input
                        type="tel"
                        onChange={onPhoneNumberChange}
                        value={phoneNumber}
                        className="text-wrapper-7"
                        placeholder="Phone Number"
                        maxLength="10"
                      />
                    </div>
                  </div>
                  {phoneNumbererrorMessage && (
                    <div className="error-message">
                      {phoneNumbererrorMessage}
                    </div>
                  )}
                </Col>

                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">Email</div>
                    <div className="overlap-group-2">
                      <IconMail iconMail="/img/icon-mail.png" />
                      <input
                        type="email"
                        onChange={onEmailChange}
                        value={email}
                        className="text-wrapper-7"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  {emailerrorMessage && (
                    <div className="error-message">{emailerrorMessage}</div>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <Row>
                <Col lg={6}>
                  <div>
                    <div className="text-wrapper-5">Lab Name</div>
                    <div className="overlap-group-3">
                      {/* <IconUser
                    className="icon-user-instance"
                    iconUser="/img/icon-user.png"
                  /> */}
                      <MedicalServicesIcon className="icon-user-instance" />

                      <input
                        type="text"
                        onChange={onLabNameChange}
                        value={labName}
                        className="text-wrapper-7"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  {labNameerrorMessage && (
                    <div className="error-message">{labNameerrorMessage}</div>
                  )}
                </Col>
              </Row>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={SignUp} color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
