import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import InfoModel from "../../shared/simpleModel/InfoModel";
import { strings } from "../../constants/strings";
import { useLocation } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Dashboard = () => {
  const [PPSscore, setPPSscore] = useState(null);
  const [loginDetails, setLoginDetails] = useState({});
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [TestReportDetail, setTestReportDetail] = useState(null);
  const [MedicalHistoryDetails, setMedicalHistoryDetails] = useState(null);
  const [otherReportsCount, setOtherReportsCount] = useState(null);
  const [generationType, setGenerationType] = useState(null);
  const [profileDetails, setProfileDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    // This effect runs when the component mounts (screen entry)
    setStartTime(new Date());

    // This function will run when the component unmounts (screen exit)
    return () => {
      const endTime = new Date();
      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      const data = {
        screenName: "Dashboard Screen",
        startTime: formatDateTime(startTime),
        endTime: formatDateTime(endTime),
      };

      // Send data to your API using fetch or Axios here
      // Example using fetch:
      AxiosInstance.post("general/save-app-usage-detail", data)
        .then((response) => {})
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
        });
    };
  }, []); // Empty dependency array ensures that this effect runs only once (on mount and unmount)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isUserSubscribed = searchParams.get("isUserSubscribed");

  useEffect(() => {
    AxiosInstance.get("general/get-pps-score")
      .then((response) => {
        setPPSscore(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    AxiosInstance.get("general/login-count")
      .then((response) => {
        setLoginDetails(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    AxiosInstance.get("subscription/get-detail")
      .then((response) => {
        setSubscriptionDetail(response);
        if (isUserSubscribed) setIsOpen(true);
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    AxiosInstance.get("general/test-report-count")
      .then((response) => {
        setTestReportDetail(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    AxiosInstance.get("general/visit-count")
      .then((response) => {
        setMedicalHistoryDetails(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    AxiosInstance.get("general/attachment-count")
      .then((response) => {
        setOtherReportsCount(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    AxiosInstance.get("general/generation")
      .then((response) => {
        setGenerationType(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    AxiosInstance.get("profile/get")
      .then((response) => {
        setProfileDetails(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  function formatDate(startDate) {
    if (!startDate) {
      return null;
    }

    const formattedDate = new Date(startDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return formattedDate;
  }

  const formattedDate = formatDate(subscriptionDetail?.subscriptionDetail && subscriptionDetail.subscriptionDetail.startDate);

  const formatted = (unformattedDate) => {
    const [year, month, day] = unformattedDate.split("-");
    const formattedDateString = `${month}/${day}/${year}`;
    const formattedDate2 = new Date(formattedDateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate2;
  };

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const joinedDateString = loginDetails.joinedDate ? loginDetails.joinedDate : "";
  const joinedDate = new Date(joinedDateString);
  const afterSevenDays = new Date(joinedDate);
  afterSevenDays.setDate(loginDetails.joinedDate ? joinedDate.getDate() + 7 : "");

  const currentDate = new Date();

  return (
    <div className="dashboard">
      <div className="column-parent">
        <div className="ppsheading"> PARTICIPATION PERFORMANCE SCORE (PPS)</div>

        {!(TestReportDetail?.count > 0 || MedicalHistoryDetails?.count > 0) && currentDate <= afterSevenDays ? (
          <div className="overlap-7">
            <div className="sampleillustrationheading">SAMPLE ILLUSTRATION</div>
            <p className="the-participation">
              The participation performance score report presented below is just a<span className="sampleillus"> SAMPLE ILLUSTRATION </span>
              <span className="the-participation">
                based on dummy data. This will be replaced by your actual performance score based on your participation on our platform. Please first fill the registration form in
              </span>
              <span className="profilescreen"> Profile Screen </span>
              <span className="the-participation"> followed by </span>
              <span className="profilescreen">Medical History</span>
              <span className="the-participation"> and </span>
              <span className="profilescreen">Lab History</span>
              <span className="the-participation">in respective screens. You will then see your personalised report here.</span>
            </p>
          </div>
        ) : (
          ""
        )}

        <div className="this-gives-you-a">
          This gives you a picture of where you are currently in your journey towards achieving full control and ownership of your health by constantly participating and sharing needed medical
          information and records with us. This is the foundational step for achieving robust health in the future. A high score here implies you are well on your way to fully utilizing our services
          to maximize your health benefits
        </div>

        <Row style={{ padding: "20px 0px 20px 0px" }}>
          <Col lg={4}>
            <div className="dashboard-div-history-status">
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="span-wrapper-2">Membership Level</div>
                  <div className="span-wrapper-3">
                    {subscriptionDetail?.subscriptionDetail
                      ? capitalizeFirstLetter(subscriptionDetail.subscriptionDetail.subscriptionType ? subscriptionDetail.subscriptionDetail.subscriptionType : "")
                      : "---"}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="span-wrapper-2">Membership Date</div>
                  <div className="span-wrapper-3">{formattedDate ? formattedDate : "---"}</div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="span-wrapper-2">Profile</div>
                <div className="span-wrapper-3">Registration Complete</div>
              </div>
            </div>
          </Col>

          <Col lg={4}>
            <div className="overlap-group-4">
              <div className="div-3">
                <span>Lab/Test reports recorded with us: </span>
                <span className="countoflabtestrecords"> {TestReportDetail ? TestReportDetail.count : "4"} </span>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="span-wrapper-4">First Lab report date</div>
                  <div className="element-2">{TestReportDetail?.firstDay ? formatted(TestReportDetail.firstDay) : "05/04/2019"}</div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="span-wrapper-4">Latest Lab report date</div>
                  <div className="element-2">{TestReportDetail?.firstDay ? formatted(TestReportDetail.lastDay) : "08/07/2023"}</div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={4}>
            <div className="overlap-group-4">
              <div className="div-3">
                <span>Medical History recorded with us: </span>
                <span className="countoflabtestrecords"> {MedicalHistoryDetails ? MedicalHistoryDetails.count : "3"}</span>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="span-wrapper-4">First Medical History date</div>
                  <div className="element-2">{MedicalHistoryDetails?.firstDay ? formatted(MedicalHistoryDetails.firstDay) : "05/04/2019"}</div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="span-wrapper-4">Latest Medical History date</div>
                  <div className="element-2">{MedicalHistoryDetails?.lastDay ? formatted(MedicalHistoryDetails.lastDay) : "08/07/2023"}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ padding: "0px 0px 20px 0px" }}>
          <Col lg={4}>
            <div className="overlap-6">
              <p className="div-3">
                <span>Other Reports: </span>
                <span className="countoflabtestrecords"> {otherReportsCount} </span>
              </p>
            </div>
          </Col>

          <Col lg={4}>
            <div>
              <div className="overlap-6">
                <p className="div-3">
                  <span>Surveys: </span>
                  <span className="countoflabtestrecords"> None initiated</span>
                </p>
              </div>
            </div>
          </Col>

          <Col lg={4}>
            <div>
              <div className="no-of-times-you-have-wrapper">
                <div className="div-3">
                  <span>No of times you have logged into the Health Portal since you joined on {loginDetails.joinedDate ? formatted(loginDetails.joinedDate) : "09/10/2023"}:</span>
                  <span className="countoflabtestrecords"> {loginDetails ? loginDetails.loginCount : "8"} </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ padding: "20px 0px 20px 0px" }}>
          <Col lg={4}>
            <div className="your-participation-wrapper">
              <p className="your-participation">
                <span>Your PPS score is </span>
                <span className="ppsscore">
                  {PPSscore}
                  <sup>th</sup> percentile
                </span>
              </p>
            </div>
          </Col>
          <Col lg={8}>
            <div className="howtoCompare">How you compare against other Health Enthusiasts in your Generation ?</div>
            <p className="you-are-in-the-lower">
              <span>
                You are in the lower&nbsp;
                <b>
                  {PPSscore}
                  <sup>th</sup>
                </b>
                &nbsp;percentile among other participants in our network
              </span>
              <span className="bottomtext"> This means {100 - PPSscore}% of participants(in the same generation </span>
              <span className="bottomtext"> {generationType ? generationType : "Millenial"} </span>
              <span className="bottomtext">{profileDetails ? ` and ${profileDetails.profile.gender}` : ""} </span>
              <span className="bottomtext">
                as yourself) in our network are participating more actively than you to improve their health. Please read in FAQ how you can maximize your PPS and health benefits through our health
                services.
              </span>
            </p>
          </Col>
        </Row>
      </div>
      <InfoModel
        open={isOpen}
        setOpen={setIsOpen}
        buttonText="Ok"
        navigateTo="/dashboard?isSignedUp=true"
        maxWidth="xs"
        heading={strings.header.bronzeHealthPlan}
        headerIcon={<CheckCircleIcon style={{ color: "green", fontSize: "40px" }} />}
      >
        <div className="popup-description">
          {strings.purchasedPlan.youHaveSuccessfully} <br />
          <span className="good-luck">{strings.purchasedPlan.goodLuck}</span>
        </div>
      </InfoModel>
    </div>
  );
};

export default Dashboard;
