import React from "react";
import "./LogoutModal.css";
import { useNavigate } from "react-router";
import { clearCookies } from "../../configration/HandleCookies";

const LogoutModal = ({ onClose }) => {
  const navigate = useNavigate();
  const Logout = () => {
    // clearCookies();
    sessionStorage.clear();
    navigate("/homeBeforeLogin");
    // window.location.reload();
  };
  return (
    <div className="logout">
      <div className="description">Are you sure you want to log out ?</div>
      <div className="description">Logging out will end your current session and you will need to sign in again to access your account.</div>
      <div></div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "20px" }}>
        <div
          className="no"
          onClick={onClose}
        >
          No
        </div>
        <div
          className="yes"
          onClick={Logout}
        >
          Yes
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
