import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { getLifestyleRiskAssessmentApi } from "../../../constants/apiList";
import "../LifeStyleRiskAssessment.css";
import ReactSpeedometer from "react-d3-speedometer";
import { mapRiskLevelToValue } from "../../../util/Helper";
import Swal from "sweetalert2";

const RiskScore = ({RiskScoreNext}) => {
  const [lifeStyleData, setLifeStyleData] = useState({});

  const getLifestyleRiskAssessment = async () => {
    await AxiosInstance.get(`${getLifestyleRiskAssessmentApi}`)
      .then((res) => {
        setLifeStyleData(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getLifestyleRiskAssessment();
  }, []);

  const matchingObjects = {};
    for (const [key, value] of Object.entries(lifeStyleData)) {
      if (value !== null && typeof value === "object") {
        const objValues = Object.values(value);
        if (objValues.includes(lifeStyleData.overallLifestyleRiskScore)) {
          matchingObjects[key] = value;
        }
      }
    }
  const matchingObjectsArray = Object.values(matchingObjects);
  const keysArray = matchingObjectsArray.map((obj) => Object.keys(obj));
  
 function getColorByLabel(label) {
  switch (label) {
    case "Very Low":
      return "#008000";
    case "Low":
      return "#90EE90";
    case "Medium":
      return "#CADB2A";
    case "Moderate":
      return "#FFA500";
    case "High":
      return "#ff0000";
    case "No Risk":
      return "#916D98";
    case "None":
        return "#916D98";  
    case "N/A":
      return "#1C80E3";
    default:
      return null;
  }
 }
  
  const HandleOverweightNote = () => {
    Swal.fire({
      text: "Overweight individuals experience elevation in the risks of CAD and hypertension, type 2 diabetes as well as several types of cancer compared with lean individuals (BMI less than 23). We recommend that you engage in moderate to high intensity exercises regularly, improve your diet and take other necessary measures to reduce your weight to bring down BMI over the next 3-6 months to less than 25 if not less than 23. This will immensely improve your health and prevent lifestyle diseases.",
    });
  }

  const HandleUnderweightNote = () => {
    Swal.fire({
      text: "Consult your physician regarding your weight Eat healthy food and try to increase your weight by few kgs to bring your weight to a healthy level which means BMI between 18 to 23 and no more than 25.",
    });
  }

  const handleNoRiskNote = () => {
     Swal.fire({
       text: "Continue to lead an active lifestyle and eat a healthy diet and take other necessary measures to maintain your weight and keep BMI between 18-23.",
     });
  }

  const handlePhysicalRiskMap = () => {
      Swal.fire({
        text: "This is good as this reduces the risk of heart diseases, stroke, type-3 diabetes, hypertension, dementia, and several types of cancer. We recommend that you try to read more benefits by increasing activity slowly to 300 minutes per week. Also ensure to include muscle and bone strengthening and stretching exercises on at least 2-3 days per week ( Note: This is only for 18-30 years old people with no previous history of heart disease or stroke).",
      });
  }

  const handlePhysicalRiskMap2 = () => {
     Swal.fire({
       text: "American Heart Association recommends getting at least 150 minutes per week of moderate intensity aerobic activity or 75 minutes per week of vigorous aerobic activity, or a combination of both to lower the risk of obesity, heart diseases, stroke, type-3 diabetes, hypertension, dementia, and several types of cancer.",
     });
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <p style={{ float: "right" }}>
            ( <b>No-Risk : </b> <span className="risk-level-circle" style={{ background: getColorByLabel("No Risk") }}></span> )
          </p>
          <TableContainer
            component={Paper}
            className="risk-management-table"
          >
            <Table
              aria-label="a dense table"
              size="small"
            >
              <TableHead style={{ backgroundColor: "grey" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Risk (Y/N)</TableCell>
                  <TableCell align="center">Risk Level</TableCell>
                  <TableCell align="center">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="data-heading">
                  <TableCell colSpan={3}>A. Personal & Family History</TableCell>
                  <TableCell> {!!lifeStyleData.personalRiskDate ? lifeStyleData.personalRiskDate : "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Personal</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {!!lifeStyleData.personalRisk ? "Y" : "N"}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("personalAndFamilyRiskMap") && (
                      lifeStyleData?.personalRisk ? ( Object.keys(lifeStyleData?.personalAndFamilyRiskMap).map((key, index) => (
                          <div key={index}> {<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.personalAndFamilyRiskMap[key]) }}></span>}</div>
                        ))) : (
                          <span className="risk-level-circle" style={{ background: getColorByLabel("No Risk") }}></span>
                        )
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Family</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {!!lifeStyleData.familyRisk ? "Y" : "N"}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("personalAndFamilyRiskMap") && (
                      lifeStyleData?.familyRisk ? (
                        Object.keys(lifeStyleData?.personalAndFamilyRiskMap).map((key) => (
                          <span key={key} className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.personalAndFamilyRiskMap[key]) }}></span>
                        ))
                      ) : (
                        <span className="risk-level-circle" style={{ background: getColorByLabel("No Risk") }}></span>
                      )
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow className="data-heading">
                  <TableCell colSpan={3}>B. Lifestyle/Habits</TableCell>
                  <TableCell>{!!lifeStyleData?.personalRiskDate ? lifeStyleData?.personalRiskDate : "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Overweight/Obesity</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("overweightObesityValue") && (
                      <>
                        {Object.keys(lifeStyleData?.overweightObesityValue).map((key, val) => {
                          return <>{key === "true" ? "Y" : "N"}</>;
                        })}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("overweightObesityValue") && (
                      <>
                        {Object.keys(lifeStyleData?.overweightObesityValue).map((key) => (
                          <>{<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.overweightObesityValue[key]) }}></span>}</>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Tobacco</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {!!lifeStyleData?.tobaccoRisk ? "Y" : "N"}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("tobaccoRiskScore") && (
                      <>
                        {Object.keys(lifeStyleData?.tobaccoRiskScore).map((key) => (
                          <>{<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.tobaccoRiskScore[key]) }}></span>}</>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Alcohol consumption</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {!!lifeStyleData?.alcoholConsumptionRisk ? "Y" : "N"}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("alcoholConsumptionRiskScore") && (
                      <>
                        {Object.keys(lifeStyleData?.alcoholConsumptionRiskScore).map((key) => (
                          <>{<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.alcoholConsumptionRiskScore[key]) }}></span>}</>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Unhealthy Diet</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("unhealthyDietRiskMap") && (
                      <>
                        {Object.keys(lifeStyleData?.unhealthyDietRiskMap).map((key) => (
                          <span key={key}>{key == "true" ? "Y" : "N"}</span>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("unhealthyDietRiskMap") && (
                      <>
                        {Object.keys(lifeStyleData?.unhealthyDietRiskMap).map((key) => (
                          <span key={key}>{<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.unhealthyDietRiskMap[key]) }}></span>}</span>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Sedentary/Lack of Exercise</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("sedentaryLackOfExerciseMapValue") && (
                      <>
                        {Object.keys(lifeStyleData?.sedentaryLackOfExerciseMapValue).map((key, val) => {
                          return <>{key === "true" ? "Y" : "N"}</>;
                        })}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("sedentaryLackOfExerciseMapValue") && (
                      <>
                        {Object.keys(lifeStyleData?.sedentaryLackOfExerciseMapValue).map((key) => (
                          <>{<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.sedentaryLackOfExerciseMapValue[key]) }}></span>}</>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Drugs</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {!!lifeStyleData?.drugsRisk ? "Y" : "N"}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("drugsRiskScore") && (
                      lifeStyleData?.drugsRisk ? (
                        Object.keys(lifeStyleData?.drugsRiskScore).map((key) => (
                          <span key={key} className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.drugsRiskScore[key]) }}></span>
                        ))
                      ) : (
                        <span className="risk-level-circle" style={{ background: getColorByLabel("No Risk") }}></span>
                      )
                    )}
                    
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Sleep</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {!!lifeStyleData?.sleepRisk ? "Y" : "N"}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("sleepRiskScore") && (
                      <>
                        {Object.keys(lifeStyleData?.sleepRiskScore).map((key) => (
                          <>{<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData.sleepRiskScore[key]) }}></span>}</>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Self Monitoring</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("selfMonitoringRiskScore") && (
                      <>
                        {Object.keys(lifeStyleData.selfMonitoringRiskScore).map((key) => (
                          <span key={key}>{key == "true" ? "Y" : "N"}</span>
                        ))}
                      </>
                    )}
                   {/* {lifeStyleData.hasOwnProperty("selfMonitoringRiskScore") && !!lifeStyleData?.selfMonitoringRiskScore ? "Y" : "N"} */}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    {lifeStyleData.hasOwnProperty("selfMonitoringRiskScore") && (
                      <>
                        {Object.keys(lifeStyleData?.selfMonitoringRiskScore).map((key) => (
                          <>{<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.selfMonitoringRiskScore[key]) }}></span>}</>
                        ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                {/* <TableRow className="data-heading">
                  <TableCell colSpan={4}>C. Environment</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Employment</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Living arrangement</TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    N/A
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                  >
                    -
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{display: "flex", alignItems: "center", gap: "20px", marginTop: "15px"}}>
            <div style={{fontWeight: "500" , fontSize: "18px"}}>Overall Lifestyle Risk Score : </div>
            <div
              className="risk-management-footer"
              style={{ display: "flex", alignItems: "center", border: "1px solid black", padding: "15px", backgroundColor: getColorByLabel(lifeStyleData?.overallLifestyleRiskScore) }}>
             <h5> {lifeStyleData?.overallLifestyleRiskScore}</h5>
              {/* <TextField
                name="risk-score"
                size="small"
                value={lifeStyleData?.overallLifestyleRiskScore}
                sx={{
                  backgroundColor: "#fff",
                  border: "1px solid #e5e5e5",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              /> */}
            </div>
          </div>

        </Col>
        <Col lg={6}>
          <div className="meter-content-wrapper" style={{ border: "1px solid black" }}>
            <div className="meter-wrapper">
              <ReactSpeedometer
                width={400}
                height={270}
                minValue={0}
                maxValue={120}
                needleHeightRatio={0.7}
                value={mapRiskLevelToValue(lifeStyleData?.overallLifestyleRiskScore)}
                currentValueText="Risk Level"
                customSegmentLabels={[
                  {
                    text: "Very Low",
                    position: "OUTSIDE",
                    color: "#C60176",
                  },
                  {
                    text: "Low",
                    position: "OUTSIDE",
                    color: "#C60176",
                  },
                  {
                    text: "Medium",
                    position: "OUTSIDE",
                    color: "#C60176",
                  },
                  {
                    text: "Moderate",
                    position: "OUTSIDE",
                    color: "#C60176",
                  },
                  {
                    text: "High",
                    position: "OUTSIDE",
                    color: "#C60176",
                  },
                ]}
                ringWidth={47}
                segmentColors={["#008000", "#90EE90", "#CADB2A", "#FFA500", "#ff0000"]}
                needleTransitionDuration={3333}
                segmentTransitionDuration={2000}
                needleTransition="easeElastic"
                needleColor={"#F05E22"}
                textColor={"#F05E22"}
                innerRadius={0}
              />
            </div>

            <div className="meter-content-inner">
              {lifeStyleData.hasOwnProperty("personalAndFamilyRiskMap") && (
                <>
                  {Object.keys(lifeStyleData?.personalAndFamilyRiskMap).map((key) => (
                    <>
                      <div
                        className="meter-head"
                        key={key}
                      >
                        <h6>P&F History of Disease: {key}</h6>
                        <h6>
                          Risk Level:
                          {<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData.personalAndFamilyRiskMap[key]) }}></span>}
                        </h6>
                      </div>
                      <div className="meter-content">
                         {lifeStyleData?.personalAndFamilyRiskCategoryComment}
                        {/* {key === "1PRF" && lifeStyleData.personalAndFamilyRiskMap[key] === "Low" ? (
                          <p>
                            You have indicated that you have high cholesterol (or hypertension) but none of your family members had or/have any lifestyle and related diseases. Unfortunately, this
                            still puts you at a low risk of having Diabetes, CVD, and related diseases in the long run. You will need to take extra precautions to safeguard yourself from these
                            diseases.{" "}
                          </p>
                        ) : key === "1 in F" && lifeStyleData.personalAndFamilyRiskMap[key] === "Low" ? (
                          <p>
                            You have indicated you do not have any of the lifestyle diseases currently but your__________ had or/have___________. Unfortunately, this still puts you at a low risk of
                            having Diabetes, CVD, and related diseases in the long run. You will need to take extra precautions to safeguard yourself from these diseases.
                          </p>
                        ) : key === "2PRF+1 in F" && lifeStyleData.personalAndFamilyRiskMap[key] === "Medium" ? (
                          <p>
                            You have indicated that you have hypertension and high cholesterol and your_________ had/have Diabetes or ___________ Unfortunately, this puts you at an elevated risk of
                            having Diabetes, CVD, and related diseases in the long run. You will always need to be extra vigilant about your health and take extra precautions to safeguard yourself
                            from these diseases.{" "}
                          </p>
                        ) : key === "1PRF+2 in F" && lifeStyleData.personalAndFamilyRiskMap[key] === "Medium" ? (
                          <p>
                            You have indicated that you have hypertension and high cholesterol and your_________ had/have Diabetes or ___________ Unfortunately, this puts you at an elevated risk of
                            having Diabetes, CVD, and related diseases in the long run. You will always need to be extra vigilant about your health and take extra precautions to safeguard yourself
                            from these diseases.{" "}
                          </p>
                        ) : key === "2PRF+2 in F" && lifeStyleData.personalAndFamilyRiskMap[key] === "Moderate" ? ( */}
                         
                        {/* // ) : (
                        //   <p>
                        //     You have indicated that you have or had ___________ (stroke, heart disease, Heat Attack, Cancer) This puts you at an elevated health risk. Please continue to take all your
                        //     medications as prescribed by your physician and maintain a healthy lifestyle which includes eating healthy food, moderate physical exercising of more than 150 minutes per
                        //     week and sleeping for 7 to 8 hours every day.{" "}
                        //   </p>
                        // )} */}
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
            <div className="meter-content-inner">
              {lifeStyleData.hasOwnProperty("bmiMap") && (
                <>
                  {Object.keys(lifeStyleData?.bmiMap).map((key) => (
                    <>
                      <div className="meter-head" key={key}>
                        <h6>BMI: {key}</h6>
                        <h6>Risk Level: {<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.bmiMap[key]) }}></span>}</h6>
                      </div>
                      <div className="meter-content">
                        {lifeStyleData?.bmiRiskCategoryRiskComment}
                        {/* {lifeStyleData.bmiMap[key] === "Moderate" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Obese" category as per BMI index (International).
                          </p>
                        ) : lifeStyleData.bmiMap[key] === "Medium" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Overweight" category as per BMI index (International).
                          </p>
                        ) : lifeStyleData.bmiMap[key] === "Low" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Overweight" category as per BMI index (International). <a href="#" onClick={HandleOverweightNote}><b>Note</b></a>
                          </p>
                        ) : lifeStyleData.bmiMap[key] === "Underweight" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Underweight" category. <a href="#" onClick={HandleUnderweightNote}><b>Note</b></a>
                          </p>
                        ) : lifeStyleData.bmiMap[key] === "Extreme-High" && key == "Obese" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Extreme Obese" category.
                          </p>
                        ) : lifeStyleData.bmiMap[key] === "Moderate" && key === "Obese" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Obese" category.
                          </p>
                        ) :lifeStyleData.bmi && lifeStyleData.bmi[key] === "Medium" && key === "Overweight Class 2" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Overweight class 2" category.
                          </p>
                        ) : lifeStyleData.bmi && lifeStyleData.bmi[key] === "Low" && key === "Overweight Class 1" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Overweight class 1" category.
                          </p>
                        ) :lifeStyleData.bmi && lifeStyleData.bmi[key] === "Very low" && key === "Normal" ? (
                          <p>
                            Congratulations! Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Normal Weight" category.
                          </p>
                        ) : lifeStyleData.bmiMap[key] === "Very low" && key === "Underweight" ? (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "Underweight" category.
                          </p>
                        ) : (
                          <p>
                            Your weight is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; AND your calculated BMI is &nbsp;
                            {lifeStyleData.hasOwnProperty("bmiMapValue") && (
                              <>
                                {Object.keys(lifeStyleData.bmiMapValue).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.bmiMapValue[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            . This puts you in the "No Risk" category.<a href="#" onClick={handleNoRiskNote}><b>Note</b></a>
                          </p>
                        )} */}
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
            <div className="meter-content-inner">
              {lifeStyleData?.hasOwnProperty("dietTypeMap") && (
                <>
                  {Object.keys(lifeStyleData?.dietTypeMap).map((key) => (
                    <>
                      <div className="meter-head" key={key}>
                        <h6>Diet Type: {key}</h6>
                        <h6>Risk Level: {<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.dietTypeMap[key]) }}></span>}</h6>
                      </div>
                      <div className="meter-content">
                        {lifeStyleData?.dietRiskCategoryComment}
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
            <div className="meter-content-inner">
              {lifeStyleData.hasOwnProperty("physicalExerciseMap") && (
                <>
                  {Object.keys(lifeStyleData?.physicalExerciseMap).map((key) => (
                    <>
                      <div className="meter-head" key={key}>
                        <h6>Physical Exercise: {key}</h6>
                        <h6>Risk Level: {<span className="risk-level-circle" style={{ background: getColorByLabel(lifeStyleData?.physicalExerciseMap[key]) }}></span>}</h6>
                      </div>
                      <div className="meter-content">
                        {lifeStyleData?.physicalExerciseRiskCategoryComment}
                        {/* {key === "Active" && lifeStyleData.physicalExerciseMap[key] === "No Risk" ? (
                          <p>
                            It looks like you have an active lifestyle as you have indicated that you engage in moderate intensity exercises for more than{" "}
                            {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; minutes every week. (or involve in vigorous intensity exercises for more than{" "}
                            {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.physicalExerciseRiskMap[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; minutes every week).<a href="#" onClick={handlePhysicalRiskMap}><b>Note</b></a>
                          </p>
                        ) : key === "Low" && lifeStyleData.physicalExerciseMap[key] === " Low Risk" ? (
                          <p>
                            It looks like you live a fairly active lifestyle as you have indicated that that you engage in moderate intensity exercises for
                            {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; minutes every week. (or involve in vigorous intensity exercises for more than{" "}
                            {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.physicalExerciseRiskMap[key]}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; minutes every week).<a href="#" onClick={handlePhysicalRiskMap2}></a>
                          </p>
                        ) : key === "Almost None to None" && lifeStyleData.physicalExerciseMap[key] === "Medium" ? (
                          <p>
                            It looks like you have almost sedentary lifestyle as you have indicated that you undertake only about &nbsp;
                            {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; minutes of moderate intensity exercises per week.<a href="#" onClick={handlePhysicalRiskMap2}></a>
                            {/* {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.physicalExerciseRiskMap[key]}
                                  </span>
                                ))}
                              </>
                            )} */}
                            {/* &nbsp; minutes every week). */}
                          {/* </p>
                        ) : (
                          <p>
                            It looks like you have a sedentary lifestyle as you have indicated that you do not engage in any kind of physical exercise. Also, you have indicated sitting time more than
                            &nbsp;
                            {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {key}
                                  </span>
                                ))}
                              </>
                            )}
                            &nbsp; hours daily.<a href="#" onClick={handlePhysicalRiskMap2}></a> */}
                            {/* {lifeStyleData.hasOwnProperty("physicalExerciseRiskMap") && (
                              <>
                                {Object.keys(lifeStyleData.physicalExerciseRiskMap).map((key) => (
                                  <span className="innerValues" key={key}>
                                    {lifeStyleData.physicalExerciseRiskMap[key]}
                                  </span>
                                ))}
                              </>
                            )} */}
                            {/* &nbsp; minutes every week). */}
                          {/* </p>
                        )} */} 
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {lifeStyleData?.summary && <Row>
        <Col>
          <div className="meter-content-wrapper mb-5" style={{ border: "1px solid black" }}>
            <div className="meter-content-inner summery">
              <div className="meter-head">
                <h6>Summary </h6>
              </div>
              <div className="meter-content">
                <p>
                  {lifeStyleData?.summary}
                  {/* Apart from Personal and Family history of disease, Unhealthy diet, lack of sufficient exercise and Obesity you have also scored considerable risk factors because of consumption of
                  alcohol, tobacco, and less than adequate sleep. All these risk factors together raise your risk to a <span style={{ color: "red" }}>“HIGH”</span> category for diseases like Diabetes,
                  CVD, Hypertension, and certain forms of cancers (if you already are not suffering from one of these) */}
                </p>
                {/* <p>Please consult your physician for proper preventive tests and medications. Also, make informed lifestyle changes to reduce your risk level.</p> */}
              </div>
              <div className="clickHereText">
                please <button className="clickHereButton" onClick={RiskScoreNext}>click here</button>  to see how your lifestyle habits puts you at risk for various diseases.
              </div>
            </div>
          </div>
        </Col>
      </Row>} 
    </>
  );
};

export default RiskScore;
