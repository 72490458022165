import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TestResultModal from "./TestResultModal";
import { MdCheckCircle } from "react-icons/md";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import InfoModel from "../../shared/simpleModel/InfoModel";
import { toast } from "react-toastify";

const OnlineConsultation = () => {
  const location = useLocation();
  // const { order } = location.state || {};
  const [order, setOrder] = useState(location?.state?.order || {});
  const [iViewsResultModalOpen, setIsViewResultModalOpen] = useState(false);
  const [review, setReview] = useState("");
  const [additionalTestsRequired, setAdditionalTestsRequired] = useState("");
  const [testFields, setTestFields] = useState([""]);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [communication, setCommunication] = useState();
  const [answers, setAnswers] = useState([]);
  const [answererrors, setAnswerErrors] = useState({});
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);

  const handleReviewChange = (e) => setReview(e.target.value);

  useEffect(() => {
    if (order && Object.keys(order).length > 0) {
      AxiosInstance.get(`doctor/getCommunication/${order.orderId}`)
        .then((response) => {
          if (response != null) {
            if (response.doctorReview != null) {
              const updatedCommunication = {
                ...response,
                doctorReview: {
                  ...response.doctorReview,
                  testRecommended: response.doctorReview.testRecommended
                    ? response.doctorReview.testRecommended.split(",")
                    : [],
                },
              };
              setCommunication(updatedCommunication);
              setReview(response.doctorReview.review);
            }
          } else {
            setCommunication(null);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(order).length === 0) {
      setOrder(location?.state?.order || {});
    }
  }, [location.state]);

  const handleRadioChange = (e) => {
    setAdditionalTestsRequired(e.target.value);
    setErrors((prev) => ({ ...prev, additionalTestsRequired: "" }));
    if (e.target.value === "No") {
      setTestFields([""]); // Reset test fields if 'No' is selected
      setErrors((prev) => ({ ...prev, testFields: "" }));
    }
  };

  const handleTestFieldChange = (index, value) => {
    const updatedTestFields = [...testFields];
    updatedTestFields[index] = value;
    setTestFields(updatedTestFields);
    setErrors((prev) => ({ ...prev, testFields: "" }));
  };

  const addTestField = () => {
    setTestFields([...testFields, ""]);
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!review.trim()) {
      newErrors.review = "Doctor review is required.";
      isValid = false;
    }

    if (!additionalTestsRequired) {
      newErrors.additionalTestsRequired =
        "Please select if additional tests are required.";
      isValid = false;
    }

    if (
      additionalTestsRequired === "Yes" &&
      testFields.some((field) => !field.trim())
    ) {
      newErrors.testFields = "All test fields must be filled out.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateAnswers = () => {
    let isValid = true;
    let newErrors = {};

    communication.communicationView.forEach((item) => {
      const answer = answers.find((ans) => ans.id === item.id);
      if (!answer || answer.answer.trim() === "") {
        newErrors[item.id] = "This field is required";
        isValid = false;
      }
    });

    setAnswerErrors(newErrors);
    return isValid;
  };

  const handleChange = (id, value) => {
    setAnswerErrors((prevErrors) => ({
      ...prevErrors,
      [id]: value.trim() === "" ? "This field is required" : null, // Reset the error if the field is empty, or remove it
    }));
    setAnswers((prevAnswers) => {
      // Check if the answer with the same id already exists
      const existingAnswerIndex = prevAnswers.findIndex((ans) => ans.id === id);

      if (existingAnswerIndex !== -1) {
        // Update the existing answer
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answer = value;
        return updatedAnswers;
      } else {
        // Add a new answer object
        return [...prevAnswers, { id, answer: value }];
      }
    });
  };
  const downloadPreventiveReport = () => {
    console.log("url: ", order?.preventiveTestReportView?.filePath);
    const url = order?.preventiveTestReportView?.filePath;
    if (url === null) {
      return;
    }

    const downloadApi = "test-report/downloadPreventive";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  const handleAnswerSubmit = () => {
    if (validateAnswers()) {
      // if (validateForm()) {
      console.log("answers", answers); // This will print the array of answers with their ids
      const userDetails = sessionStorage.getItem("userDetails");
      const parsedData = JSON.parse(userDetails);
      const userType = parsedData.userType;
      // if (additionalTestsRequired == "Yes") {
      //   const combinedArray = [
      //     ...communication?.doctorReview?.testRecommended,
      //     ...testFields.filter((test) => test !== ""),
      //   ];
      //   const sendReviewData = {
      //     orderId: order.orderId,
      //     review: review,
      //     testRecommended: combinedArray.join(","),
      //   };
      //   AxiosInstance.post("doctor/sendReviewForTestReport", sendReviewData)
      //     .then((response) => {})
      //     .catch((err) => {
      //       console.log("err: ", err);
      //     });
      // }
      AxiosInstance.post(
        `doctor/sendMassage/${order.orderId}/${userType}`,
        answers
      )
        .then((response) => {
          setIsSubmitted(true);
          setAnswerSubmitted(true);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
      // }

      // Call your API or save the data here
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Clear the form or perform other actions
      const sendReviewData = {
        orderId: order.orderId,
        review: review,
        testRecommended: testFields.join(","),
      };
      console.log("reviewData", sendReviewData);
      AxiosInstance.post("doctor/sendReviewForTestReport", sendReviewData)
        .then((response) => {
          setIsSubmitted(true);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  const toggleViewResultModal = () => {
    setIsViewResultModalOpen(!iViewsResultModalOpen);
  };

  console.log(
    "online consultation order",
    order,
    testFields,
    additionalTestsRequired
  );

  const closeReportModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <InfoModel
        open={modalIsOpen}
        setOpen={closeReportModal}
        maxWidth={"md"}
        contentLabel="Image Popup"
        haveCloseIcon={true}
      >
        {reportUrl && (
          <iframe
            src={reportUrl}
            title="Report Viewer"
            style={{ width: "100%", height: "600px", border: "none" }}
          />
        )}
      </InfoModel>
      {order && Object.keys(order).length > 0 ? (
        <>
          {isSubmitted ? (
            answerSubmitted ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    You have successfully submitted your response to the
                    questions raised by the client for this paticular test
                    report review.
                  </Typography>
                  <div style={{ fontSize: "16px" }}>
                    This completes the order placed by the patient.
                  </div>
                  <div style={{ fontSize: "16px" }}>
                    Thanks for your Review!
                  </div>
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    You have successfully submitted your review to the patient.
                  </Typography>
                  <div style={{ fontSize: "16px" }}>
                    Please note that the Patient may send you queries, if they
                    have any questions after going through your comments or need
                    any further clarifications.
                  </div>
                </div>
              </Box>
            )
          ) : (
            <>
              <TableContainer
                style={{ marginTop: "2%", border: "1px solid black" }}
              >
                <Table>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: " rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>ID</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Patient Name</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: " rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Gender</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Service Type</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Test Report</b>
                      </p>
                    </TableCell>

                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>Test Result</b>
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(83, 83, 233)",
                        color: "white",
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID</b>
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableBody>
                    <TableRow
                    // onClick={() => handleRowClick(orders, index)}
                    >
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order.orderId}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order.consumerName}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order.consumerGender}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order.orderType}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={downloadPreventiveReport}
                      >
                        View
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={toggleViewResultModal}
                      >
                        View
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bolder",
                          border: "1px solid lightgrey",
                          fontSize: "20px",
                        }}
                      >
                        {order?.preventiveTestReportView?.lrId}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal
                isOpen={iViewsResultModalOpen}
                toggle={toggleViewResultModal}
                centered
              >
                <ModalHeader
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid lightgrey",
                  }}
                  toggle={toggleViewResultModal}
                >
                  <div>Test Result</div>
                </ModalHeader>
                <ModalBody
                  style={{
                    maxHeight: "80vh", // Adjust the height as needed
                    overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
                  }}
                >
                  <TestResultModal testResultData={order}></TestResultModal>
                </ModalBody>
              </Modal>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  marginTop: 2,
                  padding: 2,
                  border: "1px solid #ccc",
                  borderRadius: 2,
                }}
              >
                <TextField
                  label="Doctor Review"
                  value={
                    communication?.doctorReview?.review
                      ? communication.doctorReview.review
                      : review || ""
                  }
                  onChange={handleReviewChange}
                  placeholder="Write your review here"
                  required
                  multiline
                  rows={4}
                  error={!!errors.review}
                  helperText={errors.review}
                />

                {communication?.doctorReview && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <Typography component="div" variant="h6">
                        Additional Tests Recommended by You:
                      </Typography>
                      {(communication?.doctorReview?.testRecommended?.length ===
                        0 ||
                        !communication?.doctorReview?.testRecommended) && (
                        <Typography
                          variant="h6"
                          sx={{
                            color: "green",
                            fontWeight: "bold",
                            marginLeft: 2,
                          }}
                        >
                          None
                        </Typography>
                      )}
                    </Box>

                    {communication?.doctorReview?.testRecommended?.length >
                      0 && (
                      <Box>
                        {communication.doctorReview.testRecommended.map(
                          (testField, index) => (
                            <TextField
                              key={index}
                              value={testField}
                              placeholder={`Test ${index + 1}`}
                              sx={{
                                marginBottom: 1,
                                marginRight: 1,
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                  backgroundColor: "#d3d3d37a",
                                },
                              }}
                              disabled
                            />
                          )
                        )}
                      </Box>
                    )}
                  </Box>
                )}

                {communication?.doctorReview && (
                  <Box
                    sx={{
                      padding: "24px",
                      backgroundColor: "#1c1c1c", // Dark background
                      borderRadius: "16px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
                      border: "1px solid #333",
                    }}
                  >
                    {communication?.communicationView?.map((item, index) => (
                      <Box key={item.id} sx={{ marginBottom: "24px" }}>
                        {/* Question Heading */}
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#ffa726", // Warm orange for headings
                            fontWeight: "bold",
                          }}
                        >
                          {`Question ${index + 1} raised by patient`}
                        </Typography>
                        {/* Question Text */}
                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            fontWeight: "bold",
                            color: "#ffffff", // White text for contrast
                            backgroundColor: "#424242", // Dark gray background for the question
                            padding: "12px",
                            borderRadius: "8px",
                            border: "1px solid #555",
                          }}
                        >
                          {item.question}
                        </Typography>

                        {/* Your Response Heading */}
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "8px",
                            color: "#80cbc4", // Soft teal for response heading
                          }}
                        >
                          Your Response
                        </Typography>

                        {/* Answer TextField */}
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          placeholder="Type your response here..."
                          value={
                            answers.find((ans) => ans.id === item.id)?.answer ||
                            ""
                          }
                          onChange={(e) =>
                            handleChange(item.id, e.target.value)
                          }
                          error={!!answererrors[item.id]}
                          helperText={
                            answererrors[item.id] ? answererrors[item.id] : ""
                          }
                          InputProps={{
                            style: {
                              color: "#ffffff", // White color for the text when typing
                              fontWeight: "bold", // Bold font weight
                            },
                          }}
                          sx={{
                            backgroundColor: "#333", // Darker background for the answer input
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#666", // Matching dark border
                              },
                              "&:hover fieldset": {
                                borderColor: "#ffa726", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#ffa726", // Border color on focus
                              },
                            },
                          }}
                        />
                        <Divider
                          sx={{ marginTop: "16px", borderColor: "#444" }}
                        />
                      </Box>
                    ))}

                    {/* Submit Button */}
                  </Box>
                )}

                {(communication?.communicationView === null ||
                  communication?.communicationView === undefined) && (
                  <FormControl
                    component="fieldset"
                    required
                    error={!!errors.additionalTestsRequired}
                  >
                    <FormLabel component="legend">
                      Additional Tests Required?
                    </FormLabel>
                    <RadioGroup
                      value={additionalTestsRequired}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {errors.additionalTestsRequired && (
                      <Typography color="error" variant="caption">
                        {errors.additionalTestsRequired}
                      </Typography>
                    )}
                  </FormControl>
                )}

                {additionalTestsRequired === "Yes" && (
                  <>
                    <Box>
                      {testFields.map((testField, index) => (
                        <TextField
                          key={index}
                          value={testField}
                          onChange={(e) =>
                            handleTestFieldChange(index, e.target.value)
                          }
                          placeholder={`Test ${index + 1}`}
                          required
                          sx={{ marginBottom: 1, marginRight: 1 }}
                          error={!!errors.testFields}
                          helperText={
                            index === testFields.length - 1
                              ? errors.testFields
                              : ""
                          }
                        />
                      ))}
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addTestField}
                      >
                        Add Test
                      </Button>
                    </Box>
                  </>
                )}

                {communication?.communicationView ? (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleAnswerSubmit}
                    sx={{
                      marginTop: "24px",
                      backgroundColor: "#4caf50", // Success green color
                      "&:hover": {
                        backgroundColor: "#66bb6a", // Lighter green on hover
                      },
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button type="submit" variant="contained" color="success">
                    Submit
                  </Button>
                )}
              </Box>
            </>
          )}
        </>
      ) : (
        <strong>No Patient For Online Consultation</strong>
      )}
    </>
  );
};
export default OnlineConsultation;
