import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Box } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import ElapsedTimeGraphYearly from "./TrendsToWatchGraphs/ElapsedTimeGraphYearly";
import HealthIssuesFrequencyYearly from "./TrendsToWatchGraphs/HealthIssuesFrequencyYearly";
import HospitalVisitGraphYearly from "./TrendsToWatchGraphs/HospitalVisitGraphYearly";
import MedicinesCloudGraphYearly from "./TrendsToWatchGraphs/MedicinesCloudGraphYearly";
import SymptomsCloudGraphYearly from "./TrendsToWatchGraphs/SymptomsCloudGraphYearly";
import "./style.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

export const TrendsToWatch = () => {
  const [currentTrendData, setCurrentTrendData] = useState();
  const [yearlyTopSymptoms, setYearlyTopSymptoms] = useState();
  const [progress, setProgress] = useState();
  const [formDay, setFromDay] = useState();
  const [formYear, setFromYear] = useState();
  const [toDay, setToDay] = useState();
  const [toYear, setToYear] = useState();
  const [averageHealthyDays, setAverageHealthyDays] = useState();
  const [symptoms, setSymptoms] = useState();
  const [medicalIssues, setMedicalIssues] = useState();
  let trendType = "Uptrend";

  const getTrendsToWatch = () => {
    AxiosInstance.get("report/getHealthTrends")
      .then((response) => {
        console.log('response: ', response);
        setProgress(response?.averageHealthyDayDto.progress);
        setFromDay(response?.averageHealthyDayDto.fromDay);
        setFromYear(response?.averageHealthyDayDto.fromYear);
        setToDay(response?.averageHealthyDayDto.toDay);
        setToYear(response?.averageHealthyDayDto.toYear);
        setAverageHealthyDays(response?.averageHealthyDayDto.averageHealthyDays);
        setSymptoms(response?.symptoms);
        setMedicalIssues(response?.majorHealthIssue);
        console.log("pregre", response.majorHealthIssue);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    }
  useEffect(() => {
    getTrendsToWatch();
  }, []);
 
  return (
    <>
      <div className="trending-charts-card-wrapper">
        <h3>Trending Charts</h3>
      </div>
      <Row>
        <Col lg={8}>
          <Row>
            <Col md={6}>
              <div className="chart-item">
                <HospitalVisitGraphYearly setCurrentTrendData={setCurrentTrendData} />
              </div>
            </Col>
            <Col md={6}>
              <div className="chart-item">
                <ElapsedTimeGraphYearly />
              </div>
            </Col>
            <Col md={6}>
              <div className="chart-item">
                <SymptomsCloudGraphYearly setYearlyTopSymptoms={setYearlyTopSymptoms} />
              </div>
            </Col>
            <Col md={6}>
              <div className="chart-item">
                <MedicinesCloudGraphYearly />
              </div>
            </Col>
            <Col>
              <div className="chart-item">
                <HealthIssuesFrequencyYearly yearlyTopSymptoms={yearlyTopSymptoms} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={4}>
          <div className="element-overview">
            <div className="div-my-health-wrapper">
              <div className="graph-card-title">My Health Trends Explained</div>

              <div className="overlap-group-4">
                {trendType === "Uptrend" ? (
                  <>
                    <Typography className="mb-2" sx={{ fontWeight: 600 }}>
                      Good news! You are experiencing a period of positive health trends as explained below:
                    </Typography>{" "}
                    <Box
                      component="ul"
                      className="health-trend-list"
                      sx={{
                        gap: "30px",
                      }}
                    >
                      <Box component="li" className="health-trend-content">
                        The frequency of hospital visits each year for medical issues as well as the <b>average healthy day</b> before you have a medical issue that requires you to visit a physician
                        is showing a positive trend or improvement. The average healthy days has <b>{progress}</b> from <b>{formDay}</b> days in <b>{formYear}</b> to <b>{toDay}</b> days in{" "}
                        <b>{toYear}</b> which is a &nbsp;
                        <b>{averageHealthyDays}</b>. The trendline in the chart shows gradual improvement.
                      </Box>
                      {(symptoms === "" || symptoms === null) ? (
                         <Box
                        component="li"
                        className="health-trend-content"
                      >
                        The last chart shows that you have better control over <b>cough</b> and <b>cold</b> issues now as compared to previous years.
                      </Box>
                      ) : ""}
                      {medicalIssues != "" ? (
                        <Box component="li" className="health-trend-content">
                          Your medical records : {medicalIssues}
                        </Box>
                      ) : (
                        <Box component="li" className="health-trend-content">
                          Your medical records <b>do not</b> indicate any major health issue or disease.
                        </Box>
                      )}
                      <Box component="li" className="health-trend-content">
                        Note: Please ensure you are storing all your medical records in the app to continue to get accurate insight into your health risks.
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography className="mb-2">Unfortunately, it looks like you are experiencing a negative health trend presently as explained below:</Typography>
                    <Box
                      component="ul"
                      className="health-trend-list"
                      sx={{
                        padding: "0 0",
                        listStyle: "none",
                        gap: "30px",
                      }}
                    >
                      <Box component="li" className="health-trend-content">
                        The frequency of hospital visits each year for medical issues as well as the average healthy day before you have a medical issue that requires you to visit a physician is
                        showing a negative trend. The average healthy days have decreased from 40 days in 2000 to 20 days in 2023 which is a 100% decrease.
                      </Box>
                      <Box component="li" className="health-trend-content">
                        The charts also show that this year you are facing high sugar issues. This is a new medical issue that needs your additional attention. Make appropriate lifestyle changes to
                        control it.
                      </Box>
                      <Box component="li" className="health-trend-content">
                        Note: Please ensure you are storing all your medical records in the app to continue to get accurate insight into your health risks.
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
