import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAccessToken } from "../configration/HandleCookies";

const ProfilePic = ({ profileInformation, initialProfile, refreshProfile }) => {
  const [imageUrl, setImageUrl] = useState();

  // let token = getAccessToken();
  let token = window.sessionStorage.getItem("token");

  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const downloadProfileData = () => {
    fetch(`${process.env.REACT_APP_SERVER_URL}profile/download-profile-pic`, requestOptions)
      .then((response) => {
        if (response.status == 204) {
          return;
        }
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const contentDispositionHeader = response.headers.get("Content-Disposition");
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setImageUrl(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    downloadProfileData();
  }, [refreshProfile]);

  const lastName = initialProfile && profileInformation?.lastName != null ? profileInformation?.lastName[0] : ""

  return (
    <div className="user-profile-image">
      {imageUrl ? (
        <img
          className="frame-29"
          alt="Frame"
          src={imageUrl}
        />
      ) : (
        <div className="profile">{initialProfile ? profileInformation?.firstName[0] + lastName : profileInformation?.profile?.firstName[0] + profileInformation?.profile?.lastName[0]}</div>
      )}
    </div>
  );
};

export default ProfilePic;
