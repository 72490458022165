import React, { useState } from "react";
import "./ForgotPassword.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ToastMessage from "../../shared/ToastMessage";
import { toast } from "react-toastify";
import IndiaFlag from "../../img/india-flag.png";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");

  const onNumberChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setNumber(result);
  };

  const forgotPassword = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/auth/sendotp/${number}`)
      .then((response) => {
        toast.success("Otp sent Successfully");
        setTimeout(() => {
          navigate(`/verification-1/${number}`);
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="forgot-password">
      <div className="overlap-8">
        <ToastMessage></ToastMessage>
        <div className="group-18">
          <img
            className="health-buddy"
            alt="Health buddy"
            src="/img/AppLogoWithName.png"
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center", padding: "30px 30px 30px 30px" }}>
          <div className="rectangle-6">
            <div className="span-wrapper-3">
              <span className="text-wrapper-35">Forgot Password</span>
            </div>
            <div className="kindly-provide-a">
              <span className="text-wrapper-36">We will send you the 4 digit verfication code</span>
            </div>

            <div className="group-17">
              <div className="text-wrapper-23">Phone Number</div>

              <div className="overlap-group-7">
                <img
                  className="icon-feather-phone-3"
                  alt="Icon feather phone"
                  src={IndiaFlag}
                />
                <input
                  type="tel"
                  onChange={onNumberChange}
                  value={number}
                  className="phoneNumberInput"
                  placeholder="Phone Number"
                  maxLength="10"
                ></input>
              </div>
            </div>

            <button
              className={`${number === "" ? "group-30disabled" : "group-30"}`}
              disabled={number === ""}
              onClick={forgotPassword}
            >
              Send OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
