[
    {
      "id": 1,
      "text": "The Lab Partner called you within 60 minutes of your order placement in the Uncurl app"
    },
    {
      "id": 2,
      "text": "The Lab was able to schedule your home collection as per your preferred date and time"
    },
    {
      "id": 3,
      "text": "The Lab Technician collected the sample on time as per scheduled Date and Time"
    },
    {
      "id":4,
      "text": "Was the Lab Technician Late?",
      "type": "boolean"
    },
    {
      "id":5,
      "text": "How late was he in minutes",
      "type": "number",
      "unit": "minutes"
    },
    {
      "id": 6,
      "text": "The sample was collected appropriately and there was no pain or other effect afterwards?"
    },
    {
      "id": 7,
      "text": "The Home sample collection technician was professional in his dealing with you"
    },
    {
      "id": 8,
      "text": "Test scheduling person gave you any special instructions required for the test, like fasting etc."
    },
    {
      "id": 9,
      "text": "You were notified by Uncurl: health about availability of your test report no later than 48 hours from sample collection time"
    },
    {
      "id": 10,
      "text": "The Test Report was easy to understand and gave you the details you needed or your Doctor asked for"
    },
    {
      "id": 11,
      "text": "Overall you feel that you had an excellent experience on this order"
    },
    {
      "id": 12,
      "text": "You feel that you got the best value for your money on this order"
    },
    {
      "id": 13,
      "text": "Please write below anything specific you would like for us to know about this test order experience",
      "type": "text"
    }
  ]
  