import React from "react";
import { Link, NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AppLogoWithName from "../../../static/img/AppLogoWithName.png";
import Button from "@mui/material/Button";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const HeaderBeforeLogin = () => {
  const headerMenuBeforeLogin = [
    {
      menuName: "Home",
      menuIcon: <DashboardIcon />,
      menuLink: "/",
    },
    {
      menuName: "Subscription",
      menuIcon: <CalendarMonthOutlinedIcon />,
      menuLink: "/SubscriptionPageBeforeLogin",
    },
  ];

  return (
    <div style={{ display: "flex", alignItems: "center", backgroundColor: "white", position: "sticky", top: "0", zIndex: "100" }}>
      <div className="overlap-3">
        <img
          className="group-3"
          alt="Group"
          src={AppLogoWithName}
        />
      </div>
      <div className="div-topnav">
        <div className="header-menu">
          {headerMenuBeforeLogin.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.menuLink}
                className="header-menu-item"
              >
                {item.menuIcon}
                {item.menuName}
              </NavLink>
            );
          })}
        </div>
        <div className="header-menu-right">
          Already have a account ? &nbsp;&nbsp;
          <Link to="/role-page">
            <Button
              variant="outlined"
              sx={{
                ":hover": {
                  bgcolor: "#5353e9",
                  color: "#fff",
                },
                borderColor: "#5353e9",
                color: "#5353e9",
              }}
            >
              Login
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderBeforeLogin;
