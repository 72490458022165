/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconSettings } from "../IconSettings";
import "./style.css";

export const NavsSettings = ({ property1, style }) => {
  return (
    <div className={`navs-settings property-1-10-${property1}`} style={style}>
      <div className="menu-item-4">
        <div className="frame-11">
          <div className="frame-12">
            <div className="frame-13">
              {property1 === "default" && (
                <img className="icon-filled-setting" alt="Icon filled setting" src="/img/icon-filled-setting.png" />
              )}

              {property1 === "variant-2" && (
                <IconSettings
                  iconSettings="/img/icon-settings.png"
                  style={{
                    height: "21px",
                    left: "unset",
                    minWidth: "21px",
                    position: "relative",
                    top: "unset",
                    width: "unset",
                  }}
                />
              )}

              <div className="settings">Settings</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsSettings.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
};
