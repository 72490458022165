import React, { useState } from "react";
import "./HelpCenter.css";
import { MyUsage } from "../../components/MyUsage/MyUsage";
import { PaymentHistory } from "../../components/PaymentHistory/PaymentHistory";
import { MySubscription } from "../../components/MySubscription/MySubscription";
import FAQ from "../../components/FAQ/FAQ";
import ContactUsModal from "../../components/ContactUsModal/ContactUsModal";
import InfoModel from "../../shared/simpleModel/InfoModel";
import calendarIcon from "../../../static/img/calendar.png";
import clockIcon from "../../../static/img/clock.png";
import walletMinusIcon from "../../../static/img/wallet-minus.png";
import userTag from "../../../static/img/user-tag.png";
import questionIcon from "../../../static/img/question.png";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export const HelpCenter = () => {
  const [ContactUsInfoModel, setContactUsInfoModel] = useState(false);
  const [FAQInfoModel, setFAQInfoModel] = useState(false);
  const [YourSubscriptionInfoModel, setYourSubscriptionInfoModel] = useState(false);
  const [MyUsageInfoModel, setMyUsageInfoModel] = useState(false);
  const [PaymentHistoryInfoModel, setPaymentHistoryInfoModel] = useState(false);
  const navigate = useNavigate();

  const ContactUsModel = () => {
    setContactUsInfoModel((prev) => !prev);
  };

  const onContactUsModalClose = () => {
    setContactUsInfoModel((prev) => !prev);
  };

  const FAQModel = () => {
    setFAQInfoModel((prev) => !prev);
  };

  const YourSubscriptionModel = () => {
    setYourSubscriptionInfoModel((prev) => !prev);
  };

  const MyUsageModel = () => {
    setMyUsageInfoModel((prev) => !prev);
  };

  const PaymentHistoryModel = () => {
    setPaymentHistoryInfoModel((prev) => !prev);
  };

  const MenuPageItems = [
    {
      title: "Your Subscription",
      titleIcon: calendarIcon,
      actionType: "button",
      callFun: YourSubscriptionModel,
    },
    {
      title: "My Usage",
      titleIcon: clockIcon,
      actionType: "button",
      callFun: MyUsageModel,
    },
    {
      title: "Payment History",
      titleIcon: walletMinusIcon,
      actionType: "button",
      callFun: PaymentHistoryModel,
    },
    {
      title: "FAQ's",
      titleIcon: questionIcon,
      actionType: "button",
      callFun: FAQModel,
    },
    {
      title: "Contact Us",
      titleIcon: userTag,
      actionType: "button",
      callFun: ContactUsModel,
    },
  ];

  return (
    <>
      <div className="helpCenterWrapper">
        <h2 className="pageTitle">Help Center</h2>
        <p className="text-wrapper-113">Let us know how we can help you.</p>
        <div className="menuPageWrapper">
          {MenuPageItems.map((item, index) => {
            return (
              <div
                className="menuPageItem"
                onClick={item?.callFun}
              >
                <div className="menuPageImg">
                  <img src={item?.titleIcon} />
                </div>
                <p>{item?.title}</p>
                <div className="ellipse-21"></div>
              </div>
            );
          })}
        </div>
        <Button
          variant="contained"
          onClick={() => navigate("/menu")}
          sx={{
            ":hover": {
              bgcolor: "#5353e9",
              color: "#fff",
            },
            borderColor: "#5353e9",
            color: "#fff",
            bgcolor: "#5353e9",
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            margin: "0 auto",
            textTransform: "capitalize",
            marginTop: "20px",
          }}
        >
          Back
        </Button>
      </div>
      <InfoModel
        open={YourSubscriptionInfoModel}
        setOpen={setYourSubscriptionInfoModel}
        buttonText="Ok"
        heading="My Subscription"
      >
        <MySubscription />
      </InfoModel>
      <InfoModel
        open={MyUsageInfoModel}
        setOpen={setMyUsageInfoModel}
        buttonText="Ok"
        heading="My Usage"
      >
        <MyUsage />
      </InfoModel>
      <InfoModel
        open={PaymentHistoryInfoModel}
        setOpen={setPaymentHistoryInfoModel}
        buttonText="Ok"
        heading="Payment History"
      >
        <PaymentHistory />
      </InfoModel>
      <InfoModel
        open={FAQInfoModel}
        setOpen={setFAQInfoModel}
        buttonText="Ok"
        maxWidth="lg"
        heading="FAQ’s"
      >
        <FAQ />
      </InfoModel>
      <InfoModel
        open={ContactUsInfoModel}
        setOpen={setContactUsInfoModel}
        buttonText="Ok"
        maxWidth="xl"
        heading="Contact Us"
      >
        <div className="popup-description">
          <ContactUsModal onContactUsModalClose={onContactUsModalClose} />
        </div>
      </InfoModel>
    </>
  );
};
