export const whatActionData = [
  { G1: ["Test your urine for albumin to have a complete picture of your overall kidney health"] },
  {
    G2: [
      "Test your urine for albumin to have a complete picture of your overall kidney health",
      "Repeat your eGFR test in 3 months to check if your eGFR remains lower than 90",
      "Take medication that may help slow progression of kidney disease. ",
    ],
  },
  {
    G3a: [
      "Test your urine for albumin to have a complete picture of your overall kidney health",
      "Repeat your eGFR test in 3 months to check if your eGFR remains lower than 90",
      "Take medication that may help slow progression of kidney disease. ",
      "Adjust any current medications due to reduced kidney function",
      "Get nutritional and dietary counseling to help support kidney function and overall health",
    ],
  },
  {
    G3b: [
      "Test your urine for albumin to have a complete picture of your overall kidney health",
      "Repeat your eGFR test in 3 months to check if your eGFR remains lower than 90",
      "Take medication that may help slow progression of kidney disease. ",
      "Adjust any current medications due to reduced kidney function",
      "Get nutritional and dietary counseling to help support kidney function and overall health",
    ],
  },
  {
    G4: [
      "Test your urine for albumin to have a complete picture of your overall kidney health",
      "Repeat your eGFR test in 3 months to check if your eGFR remains lower than 90",
      "Take medication that may help slow progression of kidney disease. ",
      "Adjust any current medications due to reduced kidney function",
      "Get nutritional and dietary counseling to help support kidney function and overall health",
      "Start seeing a kidney specialist (nephrologist)",
      "Learn more about end-stage kidney disease and treatment options",
    ],
  },
  {
    G5: [
      "Test your urine for albumin to have a complete picture of your overall kidney health",
      "Repeat your eGFR test in 3 months to check if your eGFR remains lower than 90",
      "Adjust any current medications due to reduced kidney function",
      "Get nutritional and dietary counseling to help support kidney function and overall health",
      "Start seeing a kidney specialist (nephrologist)",
      "Learn more about end-stage kidney disease and treatment options",
      "Be evaluated for a kidney transplant and be placed on a kidney transplant list",
    ],
  },
];
