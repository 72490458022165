import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import ToastMessage from "../../shared/ToastMessage";
import { toast } from "react-toastify";
import { BorderColor } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add"; 
import RemoveIcon from "@mui/icons-material/Remove";

const BloodSugar = () => {
  const date = new Date().toISOString().split("T")[0];
  const [fastingFrom, setFastingFrom] = useState("");
  const [fastingTo, setFastingTo] = useState("");
  const [preMealFrom, setPreMealFrom] = useState("");
  const [preMealTo, setPreMealTo] = useState("");
  const [postMealFrom, setPostMealFrom] = useState("");
  const [postMealTo, setPostMealTo] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [medicationRows, setMedicationRows] = useState(1);
  const [preBreakfastValue, setPreBreakfastValue] = useState("");
  const [postBreakfastValue, setPostBreakfastValue] = useState("");
  const [preLunchValue, setPreLunchValue] = useState("");
  const [postLunchValue, setPostLunchValue] = useState("");
  const [preDinnerValue, setPreDinnerValue] = useState("");
  const [postDinnerValue, setPostDinnerValue] = useState("");
  const [preBedTimeValue, setPreBedTimeValue] = useState("");
  const [postBedTimeValue, setPostBedTimeValue] = useState("");
  const [medicineValuePreBreak, setMedicineValuePreBreak] = useState(""); 
  const [medicineValueTypePreBreak, setMedicineValueTypePreBreak] = useState(""); 
  const [medicineValuePostBreak, setMedicineValuePostBreak] = useState("");
  const [medicineValueTypePostBreak, setMedicineValueTypePostBreak] = useState(""); 
  const [medicineValuePreLunch, setMedicineValuePreLunch] = useState("");
  const [medicineValueTypePreLunch, setMedicineValueTypePreLunch] = useState(""); 
  const [medicineValuePostLunch, setMedicineValuePostLunch] = useState("");
  const [medicineValueTypePostLunch, setMedicineValueTypePostLunch] = useState(""); 
  const [medicineValuePreDinner, setMedicineValuePreDinner] = useState("");
  const [medicineValueTypePreDinner, setMedicineValueTypePreDinner] = useState(""); 
  const [medicineValuePostDinner, setMedicineValuePostDinner] = useState("");
  const [medicineValueTypePostDinner, setMedicineValueTypePostDinner] = useState(""); 
  const [medicineValuePreBedTime, setMedicineValuePreBedTime] = useState("");
  const [medicineValueTypePreBedTime, setMedicineValueTypePreBedTime] = useState("");
  const [medicineValuePostBedTime, setMedicineValuePostBedTime] = useState("");
  const [medicineValueTypePostBedTime, setMedicineValueTypePostBedTime] = useState("");
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);
  
  const [BloodSugarData, setBloodSugarData] = useState([
    {
      title: "Blood Sugar",
      preBreakfast: "",
      postBreakfast: "",
      preLunch: "",
      postLunch: "",
      preDinner: "",
      postDinner: "",
      preBedtime: "",
      postBedtime: "",
    },
    {
      title: "Time",
      preBreakfast: "",
      postBreakfast: "",
      preLunch: "",
      postLunch: "",
      preDinner: "",
      postDinner: "",
      preBedtime: "",
      postBedtime: "",
    },
    {
      title: "Meds",
      meds: [
        {
          preBreakfast: "",
          postBreakfast: "",
          preLunch: "",
          postLunch: "",
          preDinner: "",
          postDinner: "",
          preBedtime: "",
          postBedtime: "",
        },
      ],
    },
    {
      title: "Carb (grams)",
      preBreakfast: "",
      postBreakfast: "",
      preLunch: "",
      postLunch: "",
      preDinner: "",
      postDinner: "",
      preBedtime: "",
      postBedtime: "",
    },
  ]);
  let medsData;
  const addMedicationRow = (value, timeType) => {
    if (preBreakfastValue) {
      setPreBreakfastValue("");
    }
    if (postBreakfastValue) {
      setPostBreakfastValue("");
    }
    if (preLunchValue) {
      setPreLunchValue("");
    }
    if (postLunchValue) {
      setPostLunchValue("");
    }
    if (preBedTimeValue) {
      setPreBedTimeValue("");
    }
    if (postBedTimeValue) {
      setPostBedTimeValue("");
    }
    if (preDinnerValue) {
      setPreDinnerValue("");
    }
    if (postDinnerValue) {
      setPostDinnerValue("");
    }
  const updatedData = [...BloodSugarData];
  const medsIndex = updatedData.findIndex((item) => item.title === "Meds");

  if (medsIndex !== -1) {
    medsData = updatedData[medsIndex].meds[0];
    medsData[timeType] = medsData[timeType] ? `${medsData[timeType]},${value}` : value;
    setBloodSugarData(updatedData);
  }
    if (timeType == "preBreakfast") {
      setMedicineValuePreBreak(medsData[timeType]);
      setMedicineValueTypePreBreak("Pre BreakFast: ");
    }
    if (timeType == "postBreakfast") {
      setMedicineValuePostBreak(medsData[timeType]);
      setMedicineValueTypePostBreak("Post BreakFast: ");
    }
    if (timeType == "preLunch") {
      setMedicineValuePreLunch(medsData[timeType]);
      setMedicineValueTypePreLunch("Pre Lunch: ");
    }
     if (timeType == "postLunch") {
       setMedicineValuePostLunch(medsData[timeType]);
       setMedicineValueTypePostLunch("Post Lunch: ");
     }
      if (timeType == "preDinner") {
        setMedicineValuePreDinner(medsData[timeType]);
        setMedicineValueTypePreDinner("Pre Dinner: ");
      }
    if (timeType == "postDinner") {
      setMedicineValuePostDinner(medsData[timeType]);
      setMedicineValueTypePostDinner("Post Dinner: ");
    }
     if (timeType == "preBedtime") {
       setMedicineValuePreBedTime(medsData[timeType]);
       setMedicineValueTypePreBedTime("Pre BedTime: ");
     }
    if (timeType == "postBedtime") {
      setMedicineValuePostBedTime(medsData[timeType]);
      setMedicineValueTypePostBedTime("Post BedTime: ");
    }
  };
  
  const handleChange = (index, field, value) => {
    const updatedData = [...BloodSugarData];
    updatedData[index] = {
      ...updatedData[index],
      [field]: value,
    };
    setBloodSugarData(updatedData);
  };
  // const transformedData = BloodSugarData.reduce((acc, curr) => {
  //   const { title, ...rest } = curr;
  //   const transformedObject = Object.entries(rest).reduce(
  //     (obj, [key, value]) => {
  //       const newKey = `${title.toLowerCase()}${key
  //         .charAt(0)
  //         .toUpperCase()}${key.slice(1)}`;
  //       return { ...obj, [newKey]: value };
  //     },
  //     {}
  //   );
  //   return { ...acc, ...transformedObject };
  // }, {});

  // const BloodSugarData = [
  //   {
  //     title: "Blood Sugar",
  //     preBreakfast: <input style={{ width: "100px" }}></input>,
  //     postBreakfast: <input style={{ width: "100px" }}></input>,
  //     preLunch: <input style={{ width: "100px" }}></input>,
  //     postLunch: <input style={{ width: "100px" }}></input>,
  //     preDinner: <input style={{ width: "100px" }}></input>,
  //     postDinner: <input style={{ width: "100px" }}></input>,
  //     preBedtime: <input style={{ width: "100px" }}></input>,
  //     postBedtime: <input style={{ width: "100px" }}></input>,
  //   },
  //   {
  //     title: "Time",
  //     preBreakfast: <input style={{ width: "100px" }}></input>,
  //     postBreakfast: <input style={{ width: "100px" }}></input>,
  //     preLunch: <input style={{ width: "100px" }}></input>,
  //     postLunch: <input style={{ width: "100px" }}></input>,
  //     preDinner: <input style={{ width: "100px" }}></input>,
  //     postDinner: <input style={{ width: "100px" }}></input>,
  //     preBedtime: <input style={{ width: "100px" }}></input>,
  //     postBedtime: <input style={{ width: "100px" }}></input>,
  //   },
  //   {
  //     title: "Meds",
  //     preBreakfast: <input style={{ width: "100px" }}></input>,
  //     postBreakfast: <input style={{ width: "100px" }}></input>,
  //     preLunch: <input style={{ width: "100px" }}></input>,
  //     postLunch: <input style={{ width: "100px" }}></input>,
  //     preDinner: <input style={{ width: "100px" }}></input>,
  //     postDinner: <input style={{ width: "100px" }}></input>,
  //     preBedtime: <input style={{ width: "100px" }}></input>,
  //     postBedtime: <input style={{ width: "100px" }}></input>,
  //   },
  //   {
  //     title: "Carb",
  //     preBreakfast: <input style={{ width: "100px" }}></input>,
  //     postBreakfast: <input style={{ width: "100px" }}></input>,
  //     preLunch: <input style={{ width: "100px" }}></input>,
  //     postLunch: <input style={{ width: "100px" }}></input>,
  //     preDinner: <input style={{ width: "100px" }}></input>,
  //     postDinner: <input style={{ width: "100px" }}></input>,
  //     preBedtime: <input style={{ width: "100px" }}></input>,
  //     postBedtime: <input style={{ width: "100px" }}></input>,
  //   },
  // ];

  const handleSubmit = () => {
    // Here you can use BloodSugarData to submit the form
    setFormSubmitted(true);
    const formData = {
      bloodSugarPreBreakfast: parseInt(BloodSugarData[0].preBreakfast),
      bloodSugarPostBreakfast: parseInt(BloodSugarData[0].postBreakfast),
      preBreakfastTime: BloodSugarData[1].preBreakfast,
      postBreakfastTime: BloodSugarData[1].postBreakfast,
      preBreakfastMeds: BloodSugarData[2].meds[0].preBreakfast,
      postBreakfastMeds: BloodSugarData[2].meds[0].postBreakfast,
      preBreakfastCarbs: BloodSugarData[3].preBreakfast,
      postBreakfastCarbs: BloodSugarData[3].postBreakfast,
      bloodSugarPreLunch: parseInt(BloodSugarData[0].preLunch),
      bloodSugarPostLunch: parseInt(BloodSugarData[0].postLunch),
      preLunchTime: BloodSugarData[1].preLunch,
      postLunchTime: BloodSugarData[1].postLunch,
      preLunchMeds: BloodSugarData[2].meds[0].preLunch,
      postLunchMeds: BloodSugarData[2].meds[0].postLunch,
      preLunchCarbs: BloodSugarData[3].preLunch,
      postLunchCarbs: BloodSugarData[3].postLunch,
      bloodSugarPreDinner: parseInt(BloodSugarData[0].preDinner),
      bloodSugarPostDinner: parseInt(BloodSugarData[0].postDinner),
      preDinnerTime: BloodSugarData[1].preDinner,
      postDinnerTime: BloodSugarData[1].postDinner,
      preDinnerMeds: BloodSugarData[2].meds[0].preDinner,
      postDinnerMeds: BloodSugarData[2].meds[0].postDinner,
      preDinnerCarbs: BloodSugarData[3].preDinner,
      postDinnerCarbs: BloodSugarData[3].postDinner,
      bloodSugarPreBedTime: parseInt(BloodSugarData[0].preBedtime),
      bloodSugarPostBedTime: parseInt(BloodSugarData[0].postBedtime),
      preBedTimeTime: BloodSugarData[1].preBedtime,
      postBedTimeTime: BloodSugarData[1].postBedtime,
      preBedTimeMeds: BloodSugarData[2].meds[0].preBedtime,
      preBedTimeCarbs: BloodSugarData[3].preBedtime,
      postBedTimeCarbs: BloodSugarData[3].postBedtime,
      postBedTimeMeds: BloodSugarData[2].meds[0].postBedtime,
    };

    const TargetFormData = {
      fastingFrom: fastingFrom,
      fastingTo: fastingTo,
      preMealFrom: preMealFrom,
      preMealTo: preMealTo,
      postMealFrom: postMealFrom,
      postMealTo: postMealTo,
    };

    AxiosInstance.post("dailyLog/saveDailyBloodSugarLog", formData)
      .then((response) => {
        if (fastingFrom.length > 3 || fastingTo.length > 3 || preMealFrom.length > 3 || preMealTo.length > 3 || postMealFrom.length > 3 || postMealTo.length > 3) {
          toast.error("Blood Glucose Ranges cannot be Greater then 3 number");
        } else if (
          formData.bloodSugarPreBreakfast > 400 ||
          formData.bloodSugarPreBreakfast < 0 ||
          formData.bloodSugarPostBreakfast > 400 ||
          formData.bloodSugarPostBreakfast < 0 ||
          formData.bloodSugarPreLunch > 400 ||
          formData.bloodSugarPreLunch < 0 ||
          formData.bloodSugarPostLunch > 400 ||
          formData.bloodSugarPostLunch < 0 ||
          formData.bloodSugarPreDinner > 400 ||
          formData.bloodSugarPreDinner < 0 ||
          formData.bloodSugarPostDinner > 400 ||
          formData.bloodSugarPostDinner < 0 ||
          formData.bloodSugarPreBedTime > 400 ||
          formData.bloodSugarPreBedTime < 0 ||
          formData.bloodSugarPostBedTime > 400 ||
          formData.bloodSugarPostBedTime < 0
        ) {
          toast.error("Enter valid Blood Sugar range ( 0 - 400 )");
        } else {
          toast.success("Your Data Has been recorded Successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    AxiosInstance.post("dailyLog/saveBloodGlucoseTargetRanges", TargetFormData)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    AxiosInstance.get("dailyLog/getDailyLogBloodSugar")
      .then((response) => {
        // setProfileDetails(response);
        setFastingFrom(
          response?.fastingFrom ? response.fastingFrom : fastingFrom
        );
        setFastingTo(response?.fastingTo ? response.fastingTo : fastingTo);
        setPreMealFrom(
          response?.preMealFrom ? response.preMealFrom : preMealFrom
        );
        setPreMealTo(response?.preMealTo ? response.preMealTo : preMealTo);
        setPostMealFrom(
          response?.postMealFrom ? response.postMealFrom : postMealFrom
        );
        setPostMealTo(response?.postMealTo ? response.postMealTo : postMealTo);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);
   useEffect(() => {
     AxiosInstance.get("profile/get")
       .then((response) => {
         setFamilyInfo(response?.profile);
       })
       .catch((error) => {
         console.error("Error:", error);
       });
      AxiosInstance.get("/family/getPatientFamilyProfile")
        .then((response) => {
          setFamilyRelation(response);
        })
        .catch((error) => {
          console.log(error);
        });
   }, []);
  return (
    <>
      {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
        <>
          <h4 style={{ color: "blue", marginTop: "-35px" }}>
            Please fill this form for your child{" "}
            <span style={{ fontSize: "xx-large" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
          </h4>
          <br />
        </>
      )}
      <ToastMessage></ToastMessage>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5>Target Blood Glucose Ranges</h5>

        <div style={{ marginLeft: "5px" }}>Today's date : {new Date().toLocaleDateString()}</div>
      </div>
      <div style={{ marginTop: "25px" }}>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={6} md={1}>
            Fasting :
          </Grid>
          <Grid item xs={6} md={11}>
            <input type="number" style={{ textAlign: "center", marginRight: "5px", width: "100px" }} value={fastingFrom} onChange={(e) => setFastingFrom(e.target.value)}></input>
            mg/dl &nbsp; to
            <input type="number" style={{ textAlign: "center", marginRight: "5px", marginLeft: "5px", width: "100px" }} value={fastingTo} onChange={(e) => setFastingTo(e.target.value)}></input>
            mg/dl
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={6} md={1}>
            Pre meal :
          </Grid>
          <Grid item xs={6} md={11}>
            <input type="number" style={{ textAlign: "center", marginRight: "5px", width: "100px" }} value={preMealFrom} onChange={(e) => setPreMealFrom(e.target.value)}></input>
            mg/dl &nbsp; to
            <input type="number" style={{ textAlign: "center", marginRight: "5px", marginLeft: "5px", width: "100px" }} value={preMealTo} onChange={(e) => setPreMealTo(e.target.value)}></input>
            mg/dl
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={6} md={1}>
            Post meal:
          </Grid>
          <Grid item xs={6} md={11}>
            <input type="number" style={{ textAlign: "center", marginRight: "5px", width: "100px" }} value={postMealFrom} onChange={(e) => setPostMealFrom(e.target.value)}></input>
            mg/dl &nbsp; to
            <input type="number" style={{ textAlign: "center", marginRight: "5px", marginLeft: "5px", width: "100px" }} value={postMealTo} onChange={(e) => setPostMealTo(e.target.value)}></input>
            mg/dl
          </Grid>
        </Grid>
        {console.log("familyRelation", familyRelation?.relationShipWithUser)}
        {(familyRelation?.relationShipWithUser == "son" || familyRelation?.relationShipWithUser == "daughter")  ? (
          <div style={{ border: "1px solid", padding: "15px", width: "60%", marginLeft: "auto", marginTop: "-8%" }}>
            <p style={{ color: "red" }}>
              Your child <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span> needs routine
              glucose check starting at about 2 hours of age because of following risk factor(s):
            </p>
            <ul style={{ listStyle: "disc", color: "red" }}>
              <li>Diabetes in the family (mother) during or before pregnancy. </li>
            </ul>
            <p>
              Because of the above risk factors, blood sugar may be measured several times during the first and second days of birth. Thereafter, ensure that blood sugar is measured on an
              annual basis and recorded here for future monitoring of risk and preventive treatment at the right age. Please record the blood glucose levels in the daily log below along with date of
              measurement and save the data. Meds and carbs may not be required or applicable at this time.
            </p>
          </div>
        ) : (
          ""
        )}
        <br />
        <span style={{ float: "right", color: "red", fontSize: "17px" }}>Note: Click on + icon to add more Medicines</span>
      </div>

      <div>
        <TableContainer className="risk-table-wrapper">
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#aaa" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" colSpan={2}>
                  Breakfast
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Lunch
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Dinner
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Bedtime
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead sx={{ backgroundColor: "#aaa" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Pre</TableCell>
                <TableCell align="center">Post</TableCell>
                <TableCell align="center">Pre</TableCell>
                <TableCell align="center">Post</TableCell>
                <TableCell align="center">Pre</TableCell>
                <TableCell align="center">Post</TableCell>
                <TableCell align="center">Pre</TableCell>
                <TableCell align="center">Post</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {BloodSugarData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.preBreakfast.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title === "Meds" ? preBreakfastValue : row.preBreakfast}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPreBreakfastValue(e.target.value);
                        } else {
                          handleChange(index, "preBreakfast", e.target.value);
                        }
                      }}
                    />
                    {row.title === "Meds" && <Button onClick={() => addMedicationRow(preBreakfastValue, "preBreakfast")} variant="text" size="small" startIcon={<AddIcon />}></Button>}
                  </TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.postBreakfast.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title == "Meds" ? postBreakfastValue : row.postBreakfast}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPostBreakfastValue(e.target.value);
                        } else {
                          handleChange(index, "postBreakfast", e.target.value);
                        }
                      }}
                    />
                    {row.title === "Meds" && <Button onClick={() => addMedicationRow(postBreakfastValue, "postBreakfast")} variant="text" size="small" startIcon={<AddIcon />}></Button>}
                  </TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.preLunch.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title == "Meds" ? preLunchValue : row.preLunch}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPreLunchValue(e.target.value);
                        } else {
                          handleChange(index, "preLunch", e.target.value);
                        }
                      }}
                    />
                    {row.title === "Meds" && <Button onClick={() => addMedicationRow(preLunchValue, "preLunch")} variant="text" size="small" startIcon={<AddIcon />}></Button>}
                  </TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.postLunch.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title == "Meds" ? postLunchValue : row.postLunch}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPostLunchValue(e.target.value);
                        } else {
                          handleChange(index, "postLunch", e.target.value);
                        }
                      }}
                    />
                    {row.title === "Meds" && <Button onClick={() => addMedicationRow(postLunchValue, "postLunch")} variant="text" size="small" startIcon={<AddIcon />}></Button>}
                  </TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.preDinner.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title == "Meds" ? preDinnerValue : row.preDinner}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPreDinnerValue(e.target.value);
                        } else {
                          handleChange(index, "preDinner", e.target.value);
                        }
                      }}
                    />
                    {row.title === "Meds" && <Button onClick={() => addMedicationRow(preDinnerValue, "preDinner")} variant="text" size="small" startIcon={<AddIcon />}></Button>}
                  </TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.postDinner && row.postDinner.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title == "Meds" ? postDinnerValue : row.postDinner}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPostDinnerValue(e.target.value);
                        } else {
                          handleChange(index, "postDinner", e.target.value);
                        }
                      }}
                    />
                    {row.title === "Meds" && <Button onClick={() => addMedicationRow(postDinnerValue, "postDinner")} variant="text" size="small" startIcon={<AddIcon />}></Button>}
                  </TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.preBedtime && row.preBedtime.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title == "Meds" ? preBedTimeValue : row.preBedtime}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPreBedTimeValue(e.target.value);
                        } else {
                          handleChange(index, "preBedtime", e.target.value);
                        }
                      }}
                    />
                    <span> {row.title === "Meds" && <Button onClick={() => addMedicationRow(preBedTimeValue, "preBedtime")} variant="text" size="small" startIcon={<AddIcon />}></Button>}</span>
                  </TableCell>
                  <TableCell align="center">
                    <input
                      style={{
                        width: "100px",
                        border: formSubmitted && row.title === "Blood Sugar" && row.postBedtime && row.postBedtime.length > 3 ? "2px solid red" : "",
                        textAlign: "center",
                      }}
                      value={row.title == "Meds" ? postBedTimeValue : row.postBedtime}
                      type={row.title === "Time" ? "time" : row.title === "Blood Sugar" ? "number" : "text"}
                      onChange={(e) => {
                        if (row.title === "Meds") {
                          setPostBedTimeValue(e.target.value);
                        } else {
                          handleChange(index, "postBedtime", e.target.value);
                        }
                      }}
                    />
                    {row.title === "Meds" && <Button onClick={() => addMedicationRow(postBedTimeValue, "postBedtime")} variant="text" size="small" startIcon={<AddIcon />}></Button>}
                  </TableCell>
                  {/* Repeat for other fields */}
                </TableRow>
              ))}
              {/* {BloodSugarData.map((row) => (
                <TableRow>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="center">{row.preBreakfast}</TableCell>
                  <TableCell align="center">{row.postBreakfast}</TableCell>
                  <TableCell align="center">{row.preDinner}</TableCell>
                  <TableCell align="center">{row.postDinner}</TableCell>
                  <TableCell align="center">{row.preLunch}</TableCell>
                  <TableCell align="center">{row.postLunch}</TableCell>
                  <TableCell align="center">{row.preBedtime}</TableCell>
                  <TableCell align="center">{row.postBedtime}</TableCell>
                </TableRow>
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <span className="Medicineee">
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePreBreak}</span>&nbsp;
          {medicineValuePreBreak}&nbsp;&nbsp;
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePostBreak}</span>&nbsp;
          {medicineValuePostBreak}
          <br />
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePreLunch}</span>&nbsp;
          {medicineValuePreLunch}&nbsp;&nbsp;
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePostLunch}</span>&nbsp;
          {medicineValuePostLunch}
          <br />
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePreDinner}</span>&nbsp;
          {medicineValuePreDinner}&nbsp;&nbsp;
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePostDinner}</span>&nbsp;
          {medicineValuePostDinner}
          <br />
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePreBedTime}</span>&nbsp;
          {medicineValuePreBedTime}&nbsp;&nbsp;
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>{medicineValueTypePostBedTime}</span> &nbsp;
          {medicineValuePostBedTime}&nbsp;
        </span>
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <Button
          onClick={handleSubmit}
          sx={{
            background: "#5353e9",
            color: "#fff",
            minWidth: "180px",
            "&:hover": { background: "#c60176" },
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default BloodSugar;
