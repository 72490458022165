import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getKidneyRiskAssessment } from "../../constants/apiList";
import {
  formatRiskFactor,
  getACRDescription,
  getAverageEGFR,
  getGFRDescription,
} from "../../util/Helper";
import "./KidneyRiskAssessment.css";
import { tableData, tableData2 } from "./tableData";
import { whatActionData } from "./whatActionData";
import Swal from "sweetalert2";

const KidneyRiskAssessment = () => {
  const [riskFactorGFR, setRiskFactorGFR] = useState();
  const [actionText, setActionText] = useState();
  const [rowData, setRowData] = useState(tableData2);
  // const [gfrValue, setGFRValue] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  // const [reviewFlag, setReviewFlag] = useState(false)

  function getActionDescription(groupIdentifier) {
    const actionObj = whatActionData.find((obj) => obj[groupIdentifier]);
    if (actionObj) {
      return actionObj[groupIdentifier];
    } else {
      return [];
    }
  }

  const data = [
    { color: "#F05E22", label: "kidney failure" },
    { color: "#FAA63B", label: "kidney disease" },
    { color: "#CADB2A", label: "early stage kidney disease" },
    { color: "#009484", label: "Normal" },
  ];

  useEffect(() => {
    if (!!riskFactorGFR?.gfStage && !!riskFactorGFR?.acrCategory) {
      setRowData((prev) =>
        [...tableData2].map((element) => {
          if (
            element.persistentLabel == riskFactorGFR?.gfStage &&
            Object.keys(element).includes(riskFactorGFR?.acrCategory)
          ) {
            element[riskFactorGFR?.acrCategory].value = true;
            if (Object.values(element).some((item) => item.value === true)) {
              Object.entries(element).forEach(([key, value]) => {
                if (typeof value === "object" && value.value === true) {
                  setSelectedData({
                    [key]: value,
                  });
                }
              });
            }
          }
          return element;
        })
      );
    }
    // setActionText(getActionDescription(riskFactorGFR?.gfStage));
    setActionText(getActionDescription(riskFactorGFR?.gfStage));
  }, [riskFactorGFR]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(getKidneyRiskAssessment);
        setRiskFactorGFR(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const riskFactor = Math.round(
    ((riskFactorGFR?.gfr - getAverageEGFR(riskFactorGFR?.age)) /
      getAverageEGFR(riskFactorGFR?.age)) *
      100
  );
  const formattedRiskFactor = formatRiskFactor(riskFactor);

  const sendReviewFlag = () => {
    // setReviewFlag(true);
    Swal.fire({
      title: "Your Response has been saved!",
      icon: "success",
    });
  };

  return (
    <div className="cardiac-wrapper mb-5 pb-5">
      <Row>
        <Col lg={5}>
          <h6 className="cardiac-title">GFR Stage in KDIGO</h6>
          <div style={{ marginBottom: "10px" }}>
            <span className="dateOfAssessment">
              Date Of Assessment : 18/01/2024
            </span>
            <TableContainer
              component={Paper}
              className="risk-table-wrapper"
              sx={{ marginBottom: "20px" }}
            >
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#aaa" }}>
                  <TableRow>
                    <TableCell>Risk Factor</TableCell>
                    <TableCell align="center">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={riskFactorGFR && riskFactorGFR?.age}>
                    <TableCell>Patient Age</TableCell>
                    <TableCell align="center">
                      {riskFactorGFR?.age ? riskFactorGFR?.age : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow key={riskFactorGFR && riskFactorGFR?.Gender}>
                    <TableCell>Gender</TableCell>
                    <TableCell align="center">
                      {riskFactorGFR?.gender ? riskFactorGFR?.gender : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow key={riskFactorGFR && riskFactorGFR?.age}>
                    <TableCell>
                      Creatinine :{" "}
                      {riskFactorGFR?.creatinine?.[
                        Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                      ]
                        ? riskFactorGFR?.creatinine?.[
                            Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                          ]
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {riskFactorGFR &&
                      Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                        ? Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="kidney-result">
              <div className="cardiac-title resultHeading">Results : </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <label className="GfrResults" htmlFor="GFR_EPI">
                    GFR_EPI:
                  </label>
                  <p className="resultDescription">{riskFactorGFR?.gfr}</p>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <label className="GfrResults" htmlFor="GF_Stage">
                    GF Stage:
                  </label>
                  <p className="resultDescription">{riskFactorGFR?.gfStage}</p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <label className="GfrResults" htmlFor="Description">
                Description:
              </label>
              <p className="resultDescription">
                {" "}
                {getGFRDescription(riskFactorGFR?.gfStage)}
              </p>
            </div>
          </div>
          <div>
            <h6 className="cardiac-title">
              Urinary Albumin Creatinine Ratio (UACR)
            </h6>
            <span className="dateOfAssessment">
              Date Of Assessment : 12/12/2023
            </span>
            <TableContainer
              component={Paper}
              className="risk-table-wrapper"
              sx={{ marginBottom: "20px" }}
            >
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#aaa" }}>
                  <TableRow>
                    <TableCell>Risk Factor</TableCell>
                    <TableCell align="center">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={riskFactorGFR && riskFactorGFR?.reportDate}>
                    <TableCell>Collection Date & Time</TableCell>
                    <TableCell align="center">
                      {riskFactorGFR?.reportDate
                        ? riskFactorGFR?.reportDate
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={
                      riskFactorGFR && Object.keys(riskFactorGFR?.albumin ?? {})[0]
                    }
                  >
                    <TableCell>
                      Albumin :{" "}
                      {riskFactorGFR?.albumin?.[
                        Object.keys(riskFactorGFR?.albumin ?? {})[0]
                      ]
                        ? riskFactorGFR?.albumin[
                            Object.keys(riskFactorGFR?.albumin ?? {})[0]
                          ]
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {riskFactorGFR && Object.keys(riskFactorGFR?.albumin ?? {})[0]
                        ? Object.keys(riskFactorGFR?.albumin ?? {})[0]
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={
                      riskFactorGFR && Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                    }
                  >
                    <TableCell>
                      Creatinine :{" "}
                      {riskFactorGFR?.creatinine?.[
                        Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                      ]
                        ? riskFactorGFR?.creatinine[
                            Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                          ]
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {riskFactorGFR &&
                      Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                        ? Object.keys(riskFactorGFR?.creatinine ?? {})[0]
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="kidney-result">
              <p className="resultHeading">Results: </p>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <label className="GfrResults" htmlFor="ACR">
                  ACR:
                </label>
                <p className="resultDescription">{riskFactorGFR?.acr}</p>
              </div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <label className="GfrResults" htmlFor="Category">
                  Category:
                </label>
                <p className="resultDescription">
                  {riskFactorGFR?.acrCategory}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <label className="GfrResults" htmlFor="Description">
                Description:
              </label>
              <p className="resultDescription">
                {" "}
                {getACRDescription(riskFactorGFR?.acrCategory)}
              </p>
            </div>
          </div>
        </Col>
        <Col lg={7}>
          <div className="prognosis-table-wrapper">
            <table border="1" className="new-table">
              <thead>
                <tr>
                  <td colSpan={4} rowSpan={4}>
                    Prognosis of CKD by GFR and Albuminuria Categories: KDIGO
                    2012
                  </td>
                  <td colSpan={4}>
                    Persistent albuminuria categories Description and rangeame
                  </td>
                </tr>
                <tr>
                  <td className={tableData[1].cellClass}>
                    {tableData[1].persistentLabel}
                  </td>
                  <td className={tableData[2].cellClass}>
                    {tableData[2].persistentLabel}
                  </td>
                  <td className={tableData[3].cellClass}>
                    {tableData[3].persistentLabel}
                  </td>
                </tr>
                <tr>
                  {tableData[1].persistentText && (
                    <td className={tableData[1].cellClass}>
                      {tableData[1].persistentText}
                    </td>
                  )}
                  {tableData[2].persistentText && (
                    <td className={tableData[2].cellClass}>
                      {tableData[2].persistentText}
                    </td>
                  )}
                  {tableData[3].persistentText && (
                    <td className={tableData[3].cellClass}>
                      {tableData[3].persistentText}
                    </td>
                  )}
                </tr>
                <tr>
                  {tableData[1].persistentRange && (
                    <td className={tableData[1].cellClass}>
                      {tableData[1].persistentRange}
                    </td>
                  )}
                  {tableData[2].persistentRange && (
                    <td className={tableData[2].cellClass}>
                      {tableData[2].persistentRange}
                    </td>
                  )}
                  {tableData[3].persistentRange && (
                    <td className={tableData[3].cellClass}>
                      {tableData[3].persistentRange}
                    </td>
                  )}
                </tr>
              </thead>
              <tbody>
                {rowData.map((item, index) => {
                  return (
                    <tr key={index}>
                      {index == 0 && (
                        <td rowSpan={6} className="gfr-rotate-text">
                          GFR categories (ml/min/ 1.73 m2) Description and range
                        </td>
                      )}
                      <td className="grey-cell">{item.persistentLabel}</td>
                      <td className="grey-cell">{item.persistentText}</td>
                      <td className="grey-cell">{item.persistentRange}</td>
                      <td
                        style={{
                          backgroundColor: item.A1.value ? item.A1.color : "",
                          height: "50px",
                        }}
                      >
                        {item.A1.value}{" "}
                      </td>
                      <td
                        style={{
                          backgroundColor: item.A2.value ? item.A2.color : "",
                          height: "50px",
                        }}
                      >
                        {item.A2.value}{" "}
                      </td>
                      <td
                        style={{
                          backgroundColor: item.A3.value ? item.A3.color : "",
                          height: "50px",
                        }}
                      >
                        {item.A3.value}{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <span className="starInformation">
              <span className="greenColor colorBox"></span> Low Risk (if no
              other markers of kidney disease, no CKD);{" "}
              <span className="yellowColor colorBox"></span> moderately
              increased risk; <span className="orangeColor colorBox"></span>{" "}
              High Risk; <span className="redColor colorBox"></span> Very High
              Risk.
            </span>
          </div>
        </Col>
      </Row>
      <Divider
        component="div"
        role="presentation"
        sx={{ bgcolor: "#B2BEB5", margin: "20px 0", height: "2px" }}
      />
      <Row style={{ paddingBottom: "60px" }}>
        <Col md={5}>
          <div className="relative-egfr-block">
            <ReactSpeedometer
              height={200}
              needleHeightRatio={0.7}
              value={riskFactorGFR?.gfr}
              currentValueText={`GFR Value : ${riskFactorGFR?.gfr}`}
              minValue={0}
              maxValue={120}
              customSegmentStops={[0, 15, 60, 90, 120]}
              segmentColors={["#F05E22", "#FAA63B", "#CADB2A", "#009484"]}
              ringWidth={47}
              needleTransitionDuration={3333}
              needleTransition="easeElastic"
              needleColor={"#F05E22"}
              textColor={"#F05E22"}
              innerRadius={0}
              segmentTransitionDuration={2000}
            />
            <div className="labels-footer">
              {data.map((entry, index) => (
                <p key={index}>
                  <span style={{ backgroundColor: `${entry.color}` }}></span>
                  {entry.label}
                </p>
              ))}
            </div>
          </div>
          <p style={{ fontSize: "22px", fontWeight: 700 }}>
            Relative eGFR :{" "}
            {riskFactorGFR?.age > 18 ? `${riskFactor}%` : "Not Applicable"}
          </p>
          <p style={{ fontSize: "14px", fontWeight: 500 }}>
            Baseline eGFR :{" "}
            {riskFactorGFR?.age > 18
              ? getAverageEGFR(riskFactorGFR && riskFactorGFR?.age)
              : "Not Applicable"}
          </p>
          <p style={{ fontWeight: 600 }}>
            {riskFactorGFR?.age > 18 ? formattedRiskFactor : ""}
          </p>
          <br />
          <p style={{ fontWeight: 500, fontSize: "16px", color: "red" }}>
            Reference: National Kidney foundation.
          </p>
        </Col>
        <Col md={7}>
          <h5 style={{ fontWeight: 600 }}>
            What Actions you need to take. (Based on eGFR)
          </h5>
          <TableContainer
            component={Paper}
            className="risk-table-wrapper"
            sx={{ marginBottom: "20px" }}
          >
            <Table size="small">
              <TableBody>
                {actionText &&
                  actionText.map((item) => {
                    return (
                      <TableRow
                        key={riskFactorGFR && riskFactorGFR?.reportDate}
                      >
                        <TableCell style={{ fontSize: "16px" }}>
                          {item}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <h5
            style={{
              color:
                selectedData && selectedData[riskFactorGFR?.acrCategory].color,
            }}
          >
            Please consult your physician immediately and show him/her these
            results.
          </h5>
          {/* <input
            type="text"
            name="gfrValue"
            onChange={(e) => setGFRValue(e.target.value)}
          /> */}
          <br></br>
        </Col>
      </Row>
      <center>
        <button
          onClick={sendReviewFlag}
          style={{ marginTop: "-6%", width: "25%" }}
          type="button"
          class="btn btn-primary"
        >
          Reviewed and Understood
        </button>
      </center>
    </div>
  );
};

export default KidneyRiskAssessment;
