// generateDynamicContent.js
const HealthOutlookDynamicContent = (dynamicValues) => {
  const { days, symptoms, medicines, seasonalOccurrences, majorIllness } = dynamicValues;

  const accordions = [
    {
      id: "panel1",
      question: "How often do I see a Physician?",
      content: `Data reported to us suggest you have a medical issue on an average every <b><u>${days}</u></b> days for which you need to see a physician.`,
    },
    {
      id: "panel2",
      question: "What are some of the common medical issues that make me see a physician?",
      content: `(${symptoms.join(", ")}) seems to be symptoms that bother you too often.`,
    },
    {
      id: "panel3",
      question: "What medicines have I been taking frequently?",
      content: `You seem to be prescribed more often (${medicines.join(", ")}) for your common symptoms.`,
    },
    {
      id: "panel4",
      question: "Is there any seasonal occurrences of my medical conditions or symptoms?",
      content: `Yes, it looks like, '${seasonalOccurrences}' you need to take extra precaution as at this time of the year you mostly suffer from cough and cold and need to see a physician. This is based on last 4 years of data that we have for you.`,
    },
  ];

  if (majorIllness === "none") {
    accordions.push({
      id: "panel5",
      question: "Am I suffering from any major illness or disease?",
      content: `<b>Good news!</b><br> Your completed profile and medical reports including prescriptions do not show that you were diagnosed with any disease.`,
    });
  } else {
    accordions.push({
      id: "panel5",
      question: "Am I suffering from any major illness or disease?",
      content: `You were diagnosed with '${majorIllness}' Dec'14. Continue to take medicines as prescribed by your doctor.`,
    });
  }

  return accordions;
};

export default HealthOutlookDynamicContent;
