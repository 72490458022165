import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MyPatients from "./MyPatients";
import ConsultantPatients from "./ConsultantPatients";

const PatientsTab = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={handleTabChange}
        sx={{ backgroundColor: "steelblue" }}
      >
        <Tab
          label="Regular Patients"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 0 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
        <Tab
          label="Patients For Consultation"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 1 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
      </Tabs>
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <MyPatients></MyPatients>
        </Box>
      )}
      {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <ConsultantPatients></ConsultantPatients>
        </Box>
      )}
    </>
  );
};
export default PatientsTab;
