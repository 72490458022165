import React, { useEffect, useState } from "react";
import "./MySubscription.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Row, Col } from "reactstrap";
import InfoModel from "../../shared/simpleModel/InfoModel";
import CancelSubscriptionModalModal from "../CancelSubscriptionModal/CancelSubscriptionModal";

export const MySubscription = () => {
  const [MySubscription, setMySubscription] = useState();
  const [CancelSubscriptionInfoModel, setCancelSubscriptionInfoModel] = useState(false);

  const CancelSubscriptionModel = () => {
    setCancelSubscriptionInfoModel((prev) => !prev);
  };

  const onClose = () => {
    setCancelSubscriptionInfoModel((prev) => !prev);
  };

  useEffect(() => {
    AxiosInstance.get("subscription/get-detail")
      .then((response) => {
        // Handle the successful response here
        setMySubscription(response);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  }, []);

  const formatDate = (inputDate) => {
    const originalDate = new Date(inputDate);
    const day = originalDate.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if necessary
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Get month (add 1 as months are zero-indexed) and pad with leading zero if necessary
    const year = originalDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="my-subscription">
      <div className="div-10">
        <div style={{ padding: "20px" }}>
          <div className="div-history-status-2">
            <div className="span-wrapper-11">
              <span className="text-wrapper-63">Current Health Plan</span>
            </div>

            <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
              <div className="span-wrapper-12">
                <span className="text-wrapper-65">{MySubscription?.subscriptionDetail && MySubscription.subscriptionDetail.subscriptionType}</span>
              </div>
            </div>

            <Row>
              <Col lg={4}>
                <div className="span-wrapper-11">
                  <span className="text-wrapper-63">Amount</span>
                </div>
              </Col>

              <Col lg={4}>
                <div className="span-wrapper-11">
                  <span className="text-wrapper-63">Start Date</span>
                </div>
              </Col>

              <Col lg={4}>
                <div className="span-wrapper-11">
                  <span className="text-wrapper-63">End Date</span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <div className="span-wrapper-12">
                  <span className="text-wrapper-65">₹ 1543</span>
                </div>
              </Col>

              <Col lg={4}>
                <div className="span-wrapper-12">
                  <span className="text-wrapper-65">{MySubscription?.subscriptionDetail && formatDate(MySubscription.subscriptionDetail.startDate)}</span>
                </div>
              </Col>

              <Col lg={4}>
                <div className="span-wrapper-12">
                  <span className="text-wrapper-65">{MySubscription?.subscriptionDetail && formatDate(MySubscription.subscriptionDetail.endDate)}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div
          className="cancel-subscription"
          onClick={CancelSubscriptionModel}
        >
          <span style={{ cursor: "pointer" }}>Cancel Subscription</span>
        </div>
        <InfoModel
          open={CancelSubscriptionInfoModel}
          setOpen={setCancelSubscriptionInfoModel}
          heading="Confirm Cancel Subscription"
          maxWidth="xs"
        >
          <div className="popup-description">
            <CancelSubscriptionModalModal onClose={onClose}></CancelSubscriptionModalModal>
          </div>
        </InfoModel>
      </div>
    </div>
  );
};
