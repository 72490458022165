import { Navigate, Outlet } from "react-router-dom";
import { getAccessToken } from "../../configration/HandleCookies";
const ProtectedRoutes = () => {
  // let auth = { token: getAccessToken() };
  let token = window.sessionStorage.getItem("token");
  // return auth.token ? <Navigate to="/home" /> : <Outlet></Outlet>;
  return <Outlet />;
};

export default ProtectedRoutes;
