import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import ToastMessage from "../../shared/ToastMessage";
import "./ChangePassword.css";

export const ChangePassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const navigate = useNavigate();

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password" && value.trim() && error2) {
      setError2("");
    }
    if (name === "confirmPassword" && value.trim() && error3) {
      setError3("");
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formValues.password.includes(" ")) {
      setError2("Blank spaces are not allowed.");
      return;
    }

    if (!formValues.password.trim()) {
      // If message is empty, show error message and prevent form submission
      setError2("Please enter the Password");
      return;
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{6,}$/;
    if (!regex.test(formValues.password)) {
      setError2("Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 6 characters long.");
      return;
    }

    if (formValues.confirmPassword.includes(" ")) {
      setError3("Blank spaces are not allowed.");
      return;
    }

    if (!formValues.confirmPassword.trim()) {
      // If message is empty, show error message and prevent form submission
      setError3("Please enter the Confirm Password");
      return;
    }

    if (!regex.test(formValues.confirmPassword)) {
      setError3("Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 6 characters long.");
      return;
    }

    AxiosInstance.post("forgot-password", formValues)
      .then((response) => {
        toast.success(response);
        setTimeout(() => {
          navigate("/login-email");
        }, 1000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="ChangePassword">
      <div className="overlap-8">
        <ToastMessage></ToastMessage>
        <div className="group-18">
          <img
            className="health-buddy"
            alt="Health buddy"
            src="/img/AppLogoWithName.png"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", padding: "30px 30px 30px 30px" }}>
          <div className="rectangle-6">
            <div className="span-wrapper-3">Change Password</div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src="/img/lock.png"
                alt="lock"
                className="lock"
              ></img>
            </div>

            <form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    type={showNewPassword ? "text" : "password"}
                    fullWidth
                    name="password"
                    margin="normal"
                    value={formValues.password}
                    onChange={handleChange}
                    error={Boolean(error2)}
                    helperText={error2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleNewPassword}
                            tabIndex={-1}
                          >
                            {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),

                      // Disable the password reveal eye icon
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    margin="normal"
                    name="confirmPassword"
                    value={formValues.confirmPassword}
                    onChange={handleChange}
                    error={Boolean(error3)}
                    helperText={error3}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmPassword}
                            tabIndex={-1}
                          >
                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{ marginTop: "20px", padding: "10px", backgroundColor: "#5353e9" }}
              >
                Update
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
