import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const GymMembershipOrders = () => {
  return (
    <div>
      <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
        <Table>
          <TableRow>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Order Id</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Booking Date</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Booking Time</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Name</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Phone Number</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Plan Name</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Membership Price</b>
              </p>
            </TableCell>
          </TableRow>
          <TableBody>
            <TableRow
            // onClick={() => handleRowClick(orders, index)}
            >
              <TableCell
                style={{
                  fontWeight: "bolder",
                  border: "1px solid lightgrey",
                  fontSize: "20px",
                }}
              >
                SEFV1233
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bolder",
                  border: "1px solid lightgrey",
                  fontSize: "20px",
                }}
              >
                12-01-2025
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bolder",
                  border: "1px solid lightgrey",
                  fontSize: "20px",
                }}
              >
                11:00 AM{" "}
              </TableCell>

              <TableCell
                style={{
                  fontWeight: "bolder",
                  border: "1px solid lightgrey",
                  fontSize: "20px",
                }}
              >
                Mayank
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bolder",
                  border: "1px solid lightgrey",
                  fontSize: "20px",
                }}
              >
                8853969325
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bolder",
                  border: "1px solid lightgrey",
                  fontSize: "20px",
                }}
              >
                3 Months
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bolder",
                  border: "1px solid lightgrey",
                  fontSize: "20px",
                }}
              >
                5000
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GymMembershipOrders;
