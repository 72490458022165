import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  Alert,
} from "reactstrap";
import questionsData from "./questions.json";
import "./Feedback.css";
import { toast } from "react-toastify";
import {
  getOrdersFeedbackApi,
  ordersFeedbackApi,
} from "../../constants/apiList";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import ToastMessage from "../../shared/ToastMessage";

const Feedback = ({ orderId, setModal }) => {
  const [responses, setResponses] = useState({});
  const [questions, setQuestions] = useState([]);
  const [order, setOrder] = useState(orderId || 75);
  const [errors, setErrors] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [isFeedbackAvailable, setIsFeedbackAvailable] = useState(false); // New state
  const [description, setDescription] = useState("");

  useEffect(() => {
    setQuestions(questionsData);
    getOrderFeedbackData();
  }, []);

  const handleChange = (e, id) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  const orderFeedbackData = async (payloadData) => {
    await AxiosInstance.post(`${ordersFeedbackApi}/${orderId}`, payloadData)
      .then((response) => {
        toast.success("Feedback submitted successfully");
        setModal(false);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const getOrderFeedbackData = async () => {
    await AxiosInstance.get(`${getOrdersFeedbackApi}/${orderId}`)
      .then((response) => {
        const feedbackData = response.orderFeedbackList;
        setFeedbackData(response);
        if (response) {
          setIsFeedbackAvailable(true);
          const initialResponses = feedbackData.reduce((acc, feedback) => {
            acc[feedback.questionId] = feedback.feedbackAnswer;
            return acc;
          }, {});
          setResponses(initialResponses);
          setDescription(response.description || "");
        }
        // toast.success(response.message);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const generatePayload = (responses) => {
    const orderFeedbackList = [];

    Object.entries(responses).forEach(([questionId, feedbackAnswer]) => {
      orderFeedbackList.push({
        id: 0,
        orderId: orderId,
        questionId: parseInt(questionId),
        feedbackAnswer,
      });
      // }
    });

    return {
      orderFeedbackList,
      description,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = [];

    visibleQuestions.forEach((question) => {
      if (question.id !== 13 && !responses[question.id]) {
        newErrors.push(`Question ${question.id} is required`);
      }
    });

    if (newErrors.length > 0) {
      setErrors(newErrors);
    } else {
      setErrors([]);
      const payload = generatePayload(responses);
      orderFeedbackData(payload);
    }
  };

  const renderOptions = (id) => (
    <div className="allRadioBtn">
      <FormGroup check>
        <Label check>
          <Input
            className="radioBtn"
            type="radio"
            name={id}
            value="Strongly Agree"
            onChange={(e) => handleChange(e, id)}
            checked={responses[id] === "Strongly Agree"}
            disabled={isFeedbackAvailable}
          />{" "}
          Strongly Agree
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            className="radioBtn"
            type="radio"
            name={id}
            value="Agree"
            onChange={(e) => handleChange(e, id)}
            checked={responses[id] === "Agree"}
            disabled={isFeedbackAvailable}
          />{" "}
          Agree
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            className="radioBtn"
            type="radio"
            name={id}
            value="Neutral"
            onChange={(e) => handleChange(e, id)}
            checked={responses[id] === "Neutral"}
            disabled={isFeedbackAvailable}
          />{" "}
          Neutral
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            className="radioBtn"
            type="radio"
            name={id}
            value="Disagree"
            onChange={(e) => handleChange(e, id)}
            checked={responses[id] === "Disagree"}
            disabled={isFeedbackAvailable}
          />{" "}
          Disagree
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            className="radioBtn"
            type="radio"
            name={id}
            value="Strongly Disagree"
            onChange={(e) => handleChange(e, id)}
            checked={responses[id] === "Strongly Disagree"}
            disabled={isFeedbackAvailable}
          />{" "}
          Strongly Disagree
        </Label>
      </FormGroup>
    </div>
  );

  const renderBooleanOptions = (id) => (
    <div className="d-flex p-1">
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            className="radioBtn"
            name={id}
            value="Yes"
            onChange={(e) => handleChange(e, id)}
            checked={responses[id] === "Yes"}
            disabled={isFeedbackAvailable}
          />{" "}
          Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            className="radioBtn"
            name={id}
            value="No"
            onChange={(e) => handleChange(e, id)}
            checked={responses[id] === "No"}
            disabled={isFeedbackAvailable}
          />{" "}
          No
        </Label>
      </FormGroup>
    </div>
  );

  const renderInputBox = (id) => (
    <FormGroup check>
      <Label check>
        <Input
          type="number"
          name={id}
          onChange={(e) => handleChange(e, id)}
          value={responses[id] || ""}
          disabled={isFeedbackAvailable}
        />
      </Label>
    </FormGroup>
  );

  // Filter questions based on the condition for question 5
  const visibleQuestions = questions.filter(
    (question) => question.id !== 5 || responses[4] === "Yes"
  );

  return (
    <Container>
      <ToastMessage></ToastMessage>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="orderId">
            <b>Order ID: {order}</b>
          </Label>
        </FormGroup>
        {errors.length > 0 && (
          <Alert color="danger">
            {errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </Alert>
        )}
        {visibleQuestions.map((question, index) => (
          <Card className="m-2 p-1" key={question.id}>
            <FormGroup>
              <span className="questionTextStyle">
                <Label>({index + 1}) &nbsp;</Label>
                <Label>{question.text}</Label>
              </span>
              {question.type === "text" ? (
                <Input
                  type="textarea"
                  className="p-2"
                  name="description"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={isFeedbackAvailable}
                />
              ) : question.type === "boolean" ? (
                renderBooleanOptions(question.id)
              ) : question.type === "number" ? (
                renderInputBox(question.id)
              ) : (
                renderOptions(question.id)
              )}
            </FormGroup>
          </Card>
        ))}
        <br />
        <Button type="submit" color="primary" disabled={isFeedbackAvailable}>
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default Feedback;
