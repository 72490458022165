import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { searchPatient } from "../../constants/apiList";
import ToastMessage from "../../shared/ToastMessage";
import PatientMigrationForm from "./PatientMigrationForm";
import { searchPatientSchema } from "./PatientMigrationSchemas";
import PatientMigrationTable from "./PatientMigrationTable";
import "./style.css";

const PatientMigration = () => {
  const [patients, setPatients] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchPatientSchema),
  });

  const onSubmit = async (data) => {
    const searchData = Object.values(data).every((value) => value === "");
    if (searchData) {
      toast.error("Please enter first name and last name or Patient Id");
    } else {
      try {
        const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${searchPatient}?firstName=${data?.firstName}&lastName=${data?.lastName}&hwPatientId=${data.hwPatientId}`);
        setPatients(result.data.data);
        if (result.data.data.length <= 0) {
          toast.success("No found with given details");
        }
      } catch (error) {
        console.log("🚀 ~ error:", error);
        // toast.error(error.response.data.message);
      }
    }
  };

  const SelectItemHandler = (item) => {
    setSelectedPatient(item);
    setIsOpen(true);
  };

  const columns = [
    { label: "UHID", field: "uhid" },
    { label: "Patient ID", field: "id" },
    { label: "Registration Date", field: "registrationDate" },
    { label: "Full Name", field: "fullName" },
    { label: "Age", field: "age" },
  ];

  return (
    <>
      <ToastMessage />
      <div
        className="patients-search-form"
        style={{ marginBottom: "20px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                size="small"
                type="text"
                fullWidth
                className="search-field"
                inputRef={field.ref}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name"
                type="text"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                size="small"
                fullWidth
                className="search-field"
                inputRef={field.ref}
              />
            )}
          />
          <Controller
            name="hwPatientId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="UHID"
                error={!!errors.hwPatientId}
                helperText={errors.hwPatientId?.message}
                size="small"
                type="number"
                fullWidth
                className="search-field"
                inputRef={field.ref}
              />
            )}
          />
          <Button
            variant="contained"
            type="submit"
            style={{ minWidth: "fit-content" }}
          >
            Search Patient
          </Button>
        </form>
      </div>
      <div className="searched-patients">
        <PatientMigrationTable
          items={patients}
          SelectItemHandlerFun={SelectItemHandler}
          columns={columns}
          actionBtnText="Select Patient"
        />
      </div>
      <PatientMigrationForm
        selectedPatient={selectedPatient}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </>
  );
};

export default PatientMigration;
