/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconOverview = ({ style, iconOverview = "/img/icon-overview-1.png" }) => {
  return <img className="icon-overview" style={style} alt="Icon overview" src={iconOverview} />;
};

IconOverview.propTypes = {
  iconOverview: PropTypes.string,
};
