import React from "react";
import { Controller } from "react-hook-form";
import "./formStyle.css";

function SelectField({ name, label, options, fieldLabel, className, labelClass, control, errors, defaultValue, icon, ...rest }) {
  const { customHandleChange } = rest;
  return (
    <div className="select-field">
      <label className={labelClass}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className="inputWithIcon">
            <select
              className={`custom-select-arrow ${className}`}
              defaultValue={defaultValue}
              {...field}
              {...rest}
              name={name}
              onChange={(e) => {
                field.onChange(e);
                if (!!customHandleChange) {
                  customHandleChange(e.target.value);
                }
              }}
            >
              <option value="">{fieldLabel}</option>
              {options?.map((option) => {
                return (
                  <option
                    key={option?.value}
                    value={option?.value}
                  >
                    {option?.name}
                  </option>
                );
              })}
            </select>
            {icon && (
              <img
                src={icon}
                alt="Icon"
              />
            )}
          </div>
        )}
      />
    </div>
  );
}

export default SelectField;
