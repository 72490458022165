import * as Yup from "yup";

import { strings } from "../constants/strings";

// Regular expressions ...............
const alphaOnlyRegex = /^(?=.*[a-zA-Z])(?=.*[^0-9])[a-zA-Z\s]*$/;
const alphaAndNumberOnlyRegex = /^(?=.*[a-zA-Z])(?=.*[^0-9])[\w\d\s]*$/;
const alphaNumberAndSymbolRegex = /^(?=.*[a-zA-Z])(?=.*[^0-9])[a-zA-Z\d\s\S]*$/;
const blankSpaceAnywhereRegex = /\s/;

/**
 * Custom validation for blank spaces
 */
Yup.addMethod(Yup.string, "blankSpaces", function (message) {
  return this.test("blankSpaces", message, function (value) {
    if (!value) {
      return true;
    }
    if (value.length - value.trimEnd().length > 2) {
      return false;
    }
    if (value.length - value.trimStart().length > 2) {
      return false;
    }

    return true;
  });
});

const getValidationRegex = ({ allowSymbol, allowNumbers }) => {
  if (allowSymbol) {
    return alphaNumberAndSymbolRegex;
  }
  if (allowNumbers) {
    return alphaAndNumberOnlyRegex;
  }
  return alphaOnlyRegex;
};
const getValidationMessage = ({ allowSymbol, allowNumbers }, field) => {
  if (allowSymbol) {
    return strings.validation.nameCanContainSymbols(field);
  }
  if (allowNumbers) {
    return strings.validation.nameCanContainNumber(field);
  }
  return strings.validation.nameCanContainAlpha(field);
};
export const nameValidation = (field, option = {}) => {
  if (option.optional) {
    return Yup.string()
      .blankSpaces(strings.validation.blankSpace(field))
      .matches(getValidationRegex(option), {
        message: getValidationMessage(option, field),
        excludeEmptyString: true,
      });
  }

  return Yup.string().required(strings.validation.fieldRequired(field)).blankSpaces(strings.validation.blankSpace(field)).matches(getValidationRegex(option), getValidationMessage(option, field));
};

export const passwordValidation = (field) =>
  Yup.string()
    .required(strings.validation.fieldRequired(field))
    .min(6, strings.validation.passwordMin(field))
    .test("blank_space_anywhere", strings.validation.blankSpaceNotAllowed, (value) => {
      return !blankSpaceAnywhereRegex.test(value);
    })
    .test("password_policy", strings.validation.passwordNtFollowGuide(field), (value) => {
      const { hasUpperCase, hasLowerCase, hasNumber, hasSymbol } = parsePassword(value);
      return [hasUpperCase, hasLowerCase, hasNumber, hasSymbol].filter(Boolean).length >= 3;
    });

export const phoneValidation = (field, { optional } = {}) => {
  if (optional) {
    return Yup.string()
      .matches(/^[0-9]+$/, {
        message: strings.validation.invalidPhone(field),
        excludeEmptyString: true,
      })
      .test("min_required", strings.validation.minPhoneLen(field), (value) => {
        if (value?.length === 0) {
          return true;
        } else {
          return value && value?.length >= 10;
        }
      });
  }

  return Yup.string()
    .required(strings.validation.fieldRequired(field))
    .matches(/^[0-9]+$/, strings.validation.invalidPhone(field))
    .min(10, strings.validation.minPhoneLen(field));
};
export const parsePassword = (value) => {
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  const hasSymbol = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);

  return { hasLowerCase, hasUpperCase, hasNumber, hasSymbol };
};
