import { Grid } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import InfoModel from "../../shared/simpleModel/InfoModel";
import "./AddMoreFormMain.css";
import HospitalList from "./HospitalList";
import LabsAndTestCenterList from "./LabsAndTestCenterList";
import PhysiciansAndSpecialistsList from "./PhysiciansAndSpecialistsList";
import { strings } from "../../constants/strings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Step2 = ({ uhid }) => {
  const [open, setOpen] = useState(false);
  const [genrateUhid, setGenrateUhid] = useState("");

  const profile_Generate_upid = async () => {
    await AxiosInstance.get(`profile/generate-upid`)
      .then((response) => {
        setOpen(true);
        setGenrateUhid(response);
        // window.sessionStorage.setItem("UhId",O JSON.stringify(response));
        // window.localStorage.setItem("isMedicalVisited", true);
        toast.success("Registration Success");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <form className="registration_tier1">
        <div className="health_Service_Main_wrapper">
          <h2 className="pageTitle">Health Service Providers Information</h2>
          <HospitalList />
          <LabsAndTestCenterList />
          <PhysiciansAndSpecialistsList />
        </div>
      </form>
      <Grid
        container
        spacing={0}
        mb={0}
        mt={3}
        justifyContent="flex-end"
        display={"flex"}
        gap={5}
      >
        <div className="submit-button">
          <button
            onClick={profile_Generate_upid}
            className="base"
          >
            Register Now
          </button>
        </div>
      </Grid>
      <InfoModel
        open={open}
        setOpen={setOpen}
        navigateTo={`${!!uhid ? `/medical-history` : `/medical-history?isUhidGenerated=${true}`}`}
        heading={strings.message.registrationCompleted}
        buttonText="Ok"
        headerIcon={<CheckCircleIcon style={{ color: "green", fontSize: "40px" }} />}
      >
        <div className="popup-description">
          <p>
            {strings.message.profileCreated}
            <span className="uhid-number">{uhid ? uhid : genrateUhid}</span>.
            <br />
            <b>Your UHID is generated, and you can see this in your profile.</b>
          </p>
        </div>
      </InfoModel>
    </>
  );
};

export default Step2;
