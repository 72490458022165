import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardText, FormGroup, Label, Input } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import "./Corporate.css";

const CorporateInvoiceModal = ({ order }) => {
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    // Calculate and set the grand total
    const totalAmount = order?.invoiceList?.reduce((acc, plan) => {
      const totalWithGst = plan.totalPrice * (1 + 0.18);
      return acc + totalWithGst;
    }, 0);

    setGrandTotal(Math.round(totalAmount)); // Set rounded grand total
  }, [order]);

  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/corporate-invoice`;
    return encodeURI(newPath); // Encode the URL
  };
  const handlePayment = async () => {
    await AxiosInstance.post(
      `payment/corporate-subscription-payment/${grandTotal}/${
        order.invoiceId
      }?successURL=${encodeURIComponent(getPaymentSuccessBaseUrl())} `
    )
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };
  console.log("specific order", order);
  return (
    <>
      <div className="PurchaseOrderHeading">
        Invoice For Uncurl:health Subscription
      </div>
      <Row>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail" style={{ marginBottom: "0px" }}>
              <b>Phone</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value={7506753773}
              type="number"
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail" style={{ marginBottom: "0px" }}>
              <b>Email</b>
            </Label>
            <Input disabled value="anjan@livingscopehealth.com" type="email" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail" style={{ marginBottom: "0px" }}>
              <b>State</b>
            </Label>
            <Input disabled value="Maharastra" type="text" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail" style={{ marginBottom: "0px" }}>
              <b>GSTIN</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="27AAFCL3118G1ZP"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail" style={{ marginBottom: "0px" }}>
              <b>Address</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="HD 090, Chromium, Milind Nagar, Powai-400076"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr style={{ border: "1px solid #000000" }}></hr>
      <Card className="LabOrderDetailCard">
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="todayDate" style={{ marginBottom: "0px" }}>
                <b>Date:</b>
              </Label>
              <div>
                {order.invoiceDate
                  ? formatDateToDDMMYYYY(order.invoiceDate)
                  : ""}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientType" style={{ marginBottom: "0px" }}>
                <b>Type of Client:</b>
              </Label>
              <div>{order.clientType}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientName" style={{ marginBottom: "0px" }}>
                <b>Client Name:</b>
              </Label>
              <div>{order.clientName}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientEmail" style={{ marginBottom: "0px" }}>
                <b>Client Email:</b>
              </Label>
              <div>{order.clientEmail}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientContactNo" style={{ marginBottom: "0px" }}>
                <b>Client Contact No:</b>
              </Label>
              <div>{order.clientPhoneNumber}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientAddress" style={{ marginBottom: "0px" }}>
                <b>Client Address:</b>
              </Label>
              <div>{order.clientAddress}</div>
            </FormGroup>
          </Col>
        </Row>
      </Card>

      <TableContainer
        component={Paper}
        sx={{ border: "1px solid black", borderRadius: "5px" }}
        elevation={5}
        className="mt-3 mb-3"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Plan Name
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                No. of Employees
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Total Price
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                GST (18%)
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.invoiceList?.map((plans, index) => (
              <TableRow>
                <TableCell sx={{ borderRight: "1px solid black" }}>
                  {plans.planName}
                </TableCell>
                <>
                  <TableCell
                    //   rowSpan={testNames.length}
                    sx={{ borderRight: "1px solid black" }}
                  >
                    {plans.planCategory}
                  </TableCell>
                  <TableCell
                    //   rowSpan={testNames.length}
                    sx={{ borderRight: "1px solid black" }}
                  >
                    {plans.numberOfEmployees}
                  </TableCell>
                  <TableCell
                    //   rowSpan={testNames.length}
                    sx={{ borderRight: "1px solid black" }}
                  >
                    {plans.totalPrice}
                  </TableCell>
                  <TableCell
                    //   rowSpan={testNames.length}
                    sx={{ borderRight: "1px solid black" }}
                  >
                    {(plans.totalPrice * 0.18).toFixed(2)}
                  </TableCell>
                  <TableCell
                  // rowSpan={testNames.length}
                  >
                    {(plans.totalPrice * (1 + 0.18)).toFixed(2)}
                  </TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Row>
        <b style={{ textDecoration: "underline" }}>Notes to Lab:</b>
        <ul className="Labnotes">
          <li>
            Please call our client promptly at the contact number provided above
            to coordinate the tests collection as per date and time mentioned
            above
          </li>
          <li>
            After tests are done and reports are available, please upload them
            on Uncurl: health app platform.
          </li>
          <li>
            If there are any questions regarding this order or the PO amount
            shown above does not match as per your quote, then please contact
            Living Scope Health at the number provided and resolve.
            <b>
              You are not supposed to discuss anything with our client regarding
              this purchase order or the amount stated in it
            </b>
          </li>
          <li>
            Invoice also to be attached on Uncurl: health app platform. Payment
            will be done though our payment portal within seven days after
            submission of invoice and all test reports on our Uncurl: health
            platform.
          </li>
        </ul>

        <div>
          <b>
            Note: This is an electronically generated order and so does not
            require any signature for approval
          </b>
        </div>
      </Row> */}

      {/* Grand Total Row */}
      <Row>
        <Col
          md={12}
          style={{
            textAlign: "right",
            padding: "1rem",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Grand Total: ₹{grandTotal}
        </Col>
      </Row>

      <Row>
        <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handlePayment}
            color="secondary"
            variant="contained"
            sx={{ textAlign: "center" }}
          >
            Pay Now
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CorporateInvoiceModal;
