import React, { useEffect, useState } from "react";
import labimg from "./Images/lab2.jpeg";
import labimg2 from "./Images/lab3.jpeg";
import { Card } from "reactstrap";

const DieticianHomeScreen = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Welcome Dietician Brand Partner
      </h2>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Card
          style={{
            width: "40rem",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img src={labimg} alt="Lab" />
        </Card>
        <Card
          style={{
            width: "40rem",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img src={labimg2} alt="Lab" />
        </Card>
      </div>
    </>
  );
};
export default DieticianHomeScreen;
