import React from "react";
import { Row, Col } from "reactstrap";
import "./DiabetesCalculator.css";
import AmericanDiabetes from "./components/AmericanDiabetes";
import IndianDiabetes from "./components/IndianDiabetes";

const DiabetesCalculator = () => {
  return (
    <div className="cardiac-wrapper mb-3">
      <Row className="mb-3">
        <Col lg={6}>
          <AmericanDiabetes />
        </Col>
        <Col lg={6}>
          <IndianDiabetes />
        </Col>
      </Row>
    </div>
  );
};

export default DiabetesCalculator;
