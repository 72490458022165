import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { IconFilledMedical } from "../../components/IconFilledMedical";
import HealthIssuesFrequency from "./HealthIssuesFrequency";
import MedicinesCloudGraph from "./MedicinesCloudGraph";
import SymptomsCloudGraph from "./SymptomsCloudGraph";
import TotalHospitalVisitGraph from "./TotalHospitalVisitGraph";
import VisitsMeanTimeGraph from "./VisitsMeanTimeGraph";
import HealthOutlookDynamicContent from "./dummyData/HealthOutlookDynamicContent";
import "./style.css";
import { getHealthoutLook } from "../../constants/apiList";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

export const ElementOverview = () => {
  const [expanded, setExpanded] = useState(false);
  const [dynamicValues, setDynamicValues] = useState(null);
  const [majorSymptomsData, setMajorSymptomsData] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const currentDate = new Date();
  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
  const [oftenSeePhysician, setOftenSeePhysician] = useState();
  const [medicalIssues, setMedicalIssues] = useState();
  const [medicineTakeFrequently, setMedicineTakeFrequently] = useState();
  const [seasonalOccurrence, setSeasonalOccurrence] = useState();
  const [sufferingMajorDisease, setSufferingMajorDisease] = useState();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    const getHealthOutLook = async () => {
      await AxiosInstance.get(`${getHealthoutLook}`)
        .then((response) => {
          setOftenSeePhysician(response?.oftenSeePhysician);
          setMedicalIssues(response?.medicalIssues);
          setMedicineTakeFrequently(response?.medicineTakeFrequently);
          setSeasonalOccurrence(response?.seasonalOccurrence);
          setSufferingMajorDisease(response?.sufferingMajorDisease);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    };
  useEffect(() => {
    getHealthOutLook();
    const fetchedDynamicValues = {
      days: 40,
      symptoms: ["Fever", "Cough"],
      medicines: ["DOLO", "ADVIL"],
      seasonalOccurrences: "November",
      majorIllness: "none",
    };
    setDynamicValues(fetchedDynamicValues);
  }, []);

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  if (!dynamicValues) {
    return <p>Loading...</p>;
  }

  const accordions = HealthOutlookDynamicContent(dynamicValues);

  return (
    <>
      <div className="element-overview">
        <div className="div-main">
          <div className="div-welcome-heading">
            <div className="div-welcome-heading-2">
              <div className="heading">Welcome back, {userDetails?.firstName}! 💫</div>
              <h6 className="total-year">All Data are based on From Year 1991 to 2024</h6>
              <div className="div-date">
                <div className="div-date-inner">
                  <img
                    className="icon-2"
                    alt="Icon"
                    src="/img/icon-5.png"
                  />
                  <p className="date">Latest updated: {formattedDate}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="div-main-parent">
            <div className="div-main-child">
              <div className="div-row-2">
                <Row>
                  {/* <Col lg={4}>
                    <div className="div-condition-status">
                      <div style={{ padding: "20px" }}>
                        <span className="icon-filled-medical-wrapper">
                          <IconFilledMedical iconFilledMedical="/img/icon-filled-medical-condition.png" />
                        </span>
                        <h5 className="h-1">Present medical condition : Diabetes</h5>
                        <h5 className="h-1">Health Risk Assessment : N/A</h5>
                        <h5 className="h-1">Overall Lifestyle Risk Score : Moderate</h5>
                        <h5 className="h-1">Heart Health : Medium Risk</h5>
                        <h5 className="h-1">Kidney Health : Low Risk</h5>
                        <h5 className="h-1">Diabetes : N/A</h5>
                        <h5 className="h-1">Liver Fibrosis : N/A</h5>
                      </div>
                      <div className="img-wrapper">
                        <img
                          style={{ height: "115px" }}
                          alt="Group"
                          src="/img/group-1000004489-1.png"
                        />
                      </div>
                    </div>
                  </Col> */}
                  <Col lg={6}>
                    <div className="div-chart-inner">
                      <TotalHospitalVisitGraph />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="div-chart-inner-2">
                      <VisitsMeanTimeGraph />
                    </div>
                  </Col>
                </Row>
                <div className="div-charts"></div>
              </div>
              <div className="div-row">
                <Row>
                  <Col lg={8}>
                    <div className="div-column-2">
                      <div className="frame-25">
                        <Row>
                          <Col lg={6}>
                            <SymptomsCloudGraph setMajorSymptomsData={setMajorSymptomsData} setCurrentMonth={setCurrentMonth} />
                          </Col>
                          <Col lg={6}>
                            <MedicinesCloudGraph />
                          </Col>
                        </Row>
                      </div>
                      <HealthIssuesFrequency
                        majorSymptomsData={majorSymptomsData}
                        currentMonth={currentMonth}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="div-my-health-wrapper">
                      <div className="graph-card-title">My Health Outlook</div>
                      <div class="accordions-container">
                        <div class="accordion">
                          <div class="accordion-item">
                            <div class="accordion-header">How often do I see a Physician?</div>
                            <div class="accordion-content">
                              Data reported to us suggest you have a medical issue on an average every{" "}
                              <b>
                                <u>{oftenSeePhysician}</u>
                              </b>{" "}
                              days for which you need to see a physician.
                            </div>
                          </div>
                          <div class="accordion-item">
                            <div class="accordion-header">What are some of the common medical issues that make me see a physician?</div>
                            <div class="accordion-content">{medicalIssues}</div>
                          </div>
                          <div class="accordion-item">
                            <div class="accordion-header">What medicines have I been taking frequently?</div>
                            <div class="accordion-content">{medicineTakeFrequently}</div>
                          </div>
                          {dynamicValues.majorIllness === "none" ? (
                            <div class="accordion-item">
                              <div class="accordion-header">Is there any seasonal occurrences of my medical conditions or symptoms?</div>
                              <div class="accordion-content">{seasonalOccurrence}
                              </div>
                            </div>
                          ) : (
                            <div class="accordion-item">
                              <div class="accordion-header">Is there any seasonal occurrences of my medical conditions or symptoms?</div>
                              <div class="accordion-content">{seasonalOccurrence}</div>
                            </div>
                          )}
                          {sufferingMajorDisease === null ? (
                          <div class="accordion-item">
                            <div class="accordion-header">Am I suffering from any major illness or disease?</div>
                            <div class="accordion-content">
                              <b>Good news!</b>
                              <br /> Your completed profile and medical reports including prescriptions do not show that you were diagnosed with any disease.
                            </div>
                          </div>
                          ) : (<div class="accordion-item">
                            <div class="accordion-header">Am I suffering from any major illness or disease?</div>
                            <div class="accordion-content">{sufferingMajorDisease}</div>
                          </div>
                          )}
                        </div>
                      </div>

                      {/* {accordions.map((accordion) => {
                        const markup = { __html: accordion.content };
                        return (
                          <Accordion
                            key={accordion.id}
                            className={`overlap-group-4 ${expanded === accordion.id ? "blue-border" : ""}`}
                            expanded={expanded === accordion.id}
                            onChange={handleChange(accordion.id)}
                            defaultExpanded={expanded === accordion.id}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`${accordion.id}-content`}
                              id={`${accordion.id}-header`}
                            >
                              <Typography className="ques">{accordion.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails dangerouslySetInnerHTML={markup} />
                          </Accordion>
                        );
                      })} */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
