import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ModelPopUp(props) {
  const { children, isOpen, setIsOpen, heading, handleSubmit, footer, maxWidth, closeReset } = props;
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setIsOpen(false);
      closeReset && closeReset();
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth={maxWidth}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        // PaperProps={{ sx: { borderRadius: "50px" } }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="popUp_title"
          className="header-title"
        >
          {heading}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
        {handleSubmit && (
          <DialogActions>
            <button
              onClick={handleSubmit}
              className="universal-btn"
            >
              Submit
            </button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
