import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

const PatientMigrationTable = ({ items, SelectItemHandlerFun, columns, actionBtnText }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ height: `${items.length > 0 ? "450px" : "auto"}` }}
    >
      <Table
        stickyHeader
        size="small"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field}>{column.label}</TableCell>
            ))}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length > 0 ? (
            items.map((item, index) => (
              <TableRow
                key={index}
                // selected={selectedItem && selectedItem.id === item.id}
              >
                {columns.map((column) => (
                  <TableCell key={column.field}>{item[column.field]}</TableCell>
                ))}
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => SelectItemHandlerFun(item)}
                  >
                    {actionBtnText}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                align="center"
                colSpan={columns.length + 1}
              >
                No Patient Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientMigrationTable;
