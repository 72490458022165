import React from "react";
import "./Corporate.css"; // Import the styles

const Stairs = ({ allEmployeeRisk }) => {
  const steps = [
    { label: "Very Low", count: allEmployeeRisk?.riskScore?.veryLow || 0 },
    { label: "Low", count: allEmployeeRisk?.riskScore?.low || 0 },
    { label: "Medium", count: allEmployeeRisk?.riskScore?.medium || 0 },
    { label: "Moderate", count: allEmployeeRisk?.riskScore?.moderate || 0 },
    { label: "High", count: allEmployeeRisk?.riskScore?.high || 0 },
  ];

  const colors = ["#28a745", "#90EE90", "#CADB2A", "#FFA500", "#dc3545"];

  return (
    <>
      <div className="stairs-container">
        {steps.map((step, index) => (
          <>
            <div
              key={index}
              className="step-wrapper"
              style={{ marginTop: `${index * -15}px` }}
            >
              <div
                className="step-count"
                style={{ color: colors[index % colors.length] }}
              >
                {step.count}
              </div>
              <div
                className="step"
                style={{
                  borderColor: colors[index % colors.length],
                }}
              >
                <div
                  className="step-label"
                  style={{ color: colors[index % colors.length] }}
                >
                  {step.label}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>

      <div className="total">
        Total Employees Subscription = {allEmployeeRisk?.employeeCount}
      </div>
    </>
  );
};

export default Stairs;
