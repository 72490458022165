import react, { useEffect, useState } from 'react';
import { AxiosInstance } from '../../configration/AxiosConfiguration';
import { getPaymentDetailApi } from '../../constants/apiList';
import { Col, Row, Card, CardText } from 'reactstrap';
const OrderPaymentDetail = ({ paymentId, amount, createdOn }) => {
    const [paymentDetail, setPaymentDetail] = useState([]);
    const getPaymentDetail = async () => {
        await AxiosInstance.get(`${getPaymentDetailApi}/${paymentId}`)
            .then((response) => {
                setPaymentDetail(response);
            })
            .catch((error) => {
                console.log('error: ', error);
            });
    }
    useEffect(() => {
        getPaymentDetail();
    }, []);
    return (
        <>
            <Card
                style={{
                    padding: "15px",
                    border: "1px solid black",
                    margin: "10px 0px 10px 0px",
                }}
                elevation={5}
            >
                <Row>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Total Amount : </b>{amount}</CardText>
                    </Col>
                    <Col lg={6}>
                        <CardText className="mt-1"><b>Created Date : </b>{createdOn}</CardText>
                    </Col>
                </Row>
            </Card>
            {paymentDetail.map((test) => (
                <Card
                    style={{
                        padding: "15px",
                        border: "1px solid black",
                        margin: "10px 0px 10px 0px",
                    }}
                    elevation={5}
                >
                    <Row>
                        <Col lg={6}>
                            <CardText className="mt-1"><b>Lab Name : </b>{test.labName}</CardText>
                        </Col>
                        <Col lg={6}>
                            <CardText className="mt-1" ><b>Test Price : </b>{test.testPrice} INR</CardText>
                        </Col>
                        {/* <Col lg={6}>
                            <CardText className="mt-1"><b>Lab Address : </b> {test.labName}</CardText>
                        </Col> */}
                        <Col lg={6}>
                            <CardText className="mt-1"><b>Order Id : </b>{test.orderId}</CardText>
                        </Col>
                        <Col lg={12}>
                            <div className="mt-1">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><b>Sr No.</b></th>
                                            <th><b>Test Name</b></th>
                                            <th><b>Test Price</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {test.testDetail.map((detail, idx) => (
                                            <tr key={idx}>
                                                <td>{idx + 1}</td>
                                                <td>{detail.testName}</td>
                                                <td>{detail.testPrice ? `${detail.testPrice} INR` : 'Price not available'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Card>
            ))}
        </>
    );
}
export default OrderPaymentDetail;