import {
  Box,
    Button,
    Card,
    Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { getColorByLabel } from "../../../util/Helper";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import ThingsYouCanChange from "./ThingsYouCanChange.jpg";
import ThingsYouCantChange from "./ThingsYouCantChange.jpg";
import SocialFactors from "./SocialFactors.jpg";
import Spinner from "../../../shared/Spinner"

const LifeStyleModificationRecommendation = () => {
  const [diagnosis , setDiagnosis] = useState([]);
  const [loading , setIsLoading] = useState(false);
  const getDiagnosisData = async () => {
  await  AxiosInstance.get("riskAssessment/getLifestyleModification")
  .then((response) => {
    if (response != null) {
      setIsLoading(false);
      setDiagnosis(response);
    } else {
      setIsLoading(false);
      setDiagnosis([]);
    }
  })
  .catch((err) => {
    setIsLoading(false);
    console.log("err: ", err);
  });
  }
  useEffect(()=> {
    setIsLoading(true);
    getDiagnosisData()
  },[]);
  console.log("diagnosis" , diagnosis);
  return (
     <div>
      {loading ? <Spinner></Spinner> : diagnosis && Object.entries(diagnosis).length > 0 ?  
      <>
        <Row className="d-flex">
          {diagnosis?.listOfRisk?.map((disease , index) => (
            <Col lg={6} className="d-flex" key={disease.diseaseName}>
              <Card className="flex-fill" style={{ borderRadius: "16px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                      background: "linear-gradient(to right, #f7f9fc, #ffffff)",}} elevation={5}>
                  <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: "#1976d2",
                        marginBottom: "16px",
                      }}
                    >
                      Factors Used to Calculate {disease.diseaseName}
                    </Typography>

                    <TableContainer
              component={Paper}
              sx={{ boxShadow: "none" }}
            >
              <Table  sx={{
                  borderCollapse: "separate",
                  borderSpacing: 0,
                  "& th, & td": {
                    borderRight: "1px solid #e0e0e0",
                    borderBottom: "1px solid #e0e0e0",
                  }
                }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        backgroundColor: "#f7f9fc",
                        color: "#333",
                        padding: "8px",
                        textAlign: "center"
                      }}
                    >
                      Risk Category
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        backgroundColor: "#f7f9fc",
                        color: "#333",
                        padding: "8px",
                        textAlign: "center"
                      }}
                    >
                      Risk Factors
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        backgroundColor: "#f7f9fc",
                        color: "#333",
                        padding: "8px",
                        textAlign: "center"
                      }}
                    >
                      Intensity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <React.Fragment key={index}>
                      {/* Things You Can't Change */}
                      {disease.unmodifiableDiseaseMappingValues.length > 0 && (
                        <>
                            <TableRow>
                            <TableCell
                              rowSpan={disease.unmodifiableDiseaseMappingValues.length + 1}
                              sx={{ fontWeight: "bold", fontSize: "16px", verticalAlign: "middle" , padding: "8px" , textAlign: "center" }}
                            >
                              Things You Can't Change
                              <Box mt={1}>
                                <img
                                  src={ThingsYouCantChange}
                                  alt="Things You Can't Change"
                                  style={{ width: "60%", borderRadius: "8px" }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                          {disease.unmodifiableDiseaseMappingValues.map((item, idx) => (
                            <TableRow key={idx}>
                              <TableCell sx={{textAlign: "center" , padding: "8px"}}>{item.riskFactor}</TableCell>
                              <TableCell sx={{textAlign: "center" , padding: "8px"}}>
                                <Box
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    backgroundColor: getColorByLabel(item.riskValue),
                                    display: "inline-block",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    border: "2px solid black"
                                  }}
                                >
                                </Box>
                              </TableCell>
                            </TableRow>))}
                        </>
                      )}

                      {/* Things You Can Change */}
                      {disease.modifiableDiseaseMappingValues.length > 0 && (
                        <>
                          <TableRow>
                            <TableCell
                              rowSpan={disease.modifiableDiseaseMappingValues.length + 1}
                              sx={{ fontWeight: "bold", fontSize: "16px", verticalAlign: "middle" , padding: "8px" , textAlign: "center"}}
                            >
                              Things You Can Change
                              <Box mt={1}>
                                <img
                                  src={ThingsYouCanChange}
                                  alt="Things You Can Change"
                                  style={{ width: "60%", borderRadius: "8px" }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                            {disease.modifiableDiseaseMappingValues.map((item, idx) => (
                            <TableRow key={idx}>
                              <TableCell sx={{textAlign: "center" , padding: "8px"}}>{item.riskFactor}</TableCell>
                              <TableCell sx={{textAlign: "center" , padding: "8px"}}>
                                <Box
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    backgroundColor: getColorByLabel(item.riskValue),
                                    display: "inline-block",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    border: "2px solid black"
                                  }}
                                >
                                </Box>
                              </TableCell>
                            </TableRow>))}
                        </>
                      )}

                      {/* Social Factors */}
                      {disease.socialFactorsValues.length > 0 && (
                        <>
                          <TableRow>
                            <TableCell
                              rowSpan={disease.socialFactorsValues.length + 1}
                              sx={{ fontWeight: "bold", fontSize: "16px", verticalAlign: "middle" , padding: "8px" , textAlign: "center"}}
                            >
                              Social Factors
                              <Box mt={1}>
                                <img
                                  src={SocialFactors}
                                  alt="Social Factors"
                                  style={{ width: "60%", borderRadius: "8px" }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                              {disease.socialFactorsValues.map((item, idx) => (
                            <TableRow key={idx}>
                              <TableCell sx={{textAlign: "center" , padding: "8px"}}>{item.riskFactor}</TableCell>
                              <TableCell sx={{textAlign: "center" , padding: "8px"}}>
                                <Box
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    backgroundColor: getColorByLabel(item.riskValue),
                                    display: "inline-block",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    border: "2px solid black"
                                  }}
                                >
                                </Box>
                              </TableCell>
                            </TableRow>))}
                        </>
                      )}
                    </React.Fragment>
                  {/* ))} */}
              
                </TableBody>
              </Table>
            </TableContainer>

                {/* <Row className="mt-2 mb-2">
                  <Col lg={4}>Risk Category</Col>
                  <Col lg={6}>Risk Factors</Col>
                  <Col lg={2} style={{ textAlign: "center" }}>Intensity</Col>
                </Row>


                <Row className="mt-2">
                  <Col lg={4}>
                    <div><b>Things You Can't Change</b></div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <ul style={{ listStyle: "outside" }}>
                        {risk.unmodifiableDiseaseMappingValues.map((item, index) => (
                          <li key={`unmodifiable-${index}`}>{item.riskFactor}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  <Col lg={2} style={{ textAlign: "center" }}>
                    {risk.unmodifiableDiseaseMappingValues.map((item, idx) => (
                      <div key={idx}>
                        <span
                          className="risk-level-circle"
                          style={{ background: getColorByLabel(item.riskValue) }}
                        >
                        </span>
                      </div>
                    ))}
                  </Col>
                </Row>

                  


                <Row className="mt-2">
                  <Col lg={4}>
                    <div><b>Things You Can Change</b></div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <ul style={{ listStyle: "outside" }}>
                        {risk.modifiableDiseaseMappingValues.map((item, index) => (
                          <li key={`modifiable-${index}`}>{item.riskFactor}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  <Col lg={2} style={{ textAlign: "center" }}>
                    {risk.modifiableDiseaseMappingValues.map((item, idx) => (
                      <div key={idx}>
                        <span
                          className="risk-level-circle"
                          style={{ background: getColorByLabel(item.riskValue) }}
                        >
                        </span>
                      </div>
                    ))}
                  </Col>
                </Row>


                {risk.socialFactorsValues.length > 0 && (
                  <Row className="mt-2">
                    <Col lg={4}>
                      <div>
                        <b>Social Factors</b>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <ul style={{ listStyle: "outside" }}>
                        {risk.socialFactorsValues.map((item, idx) => (
                          <li key={idx}>{item.riskFactor}</li>
                        ))}
                      </ul>
                    </Col>
                    <Col lg={2} style={{ textAlign: "center" }}>
                      {risk.socialFactorsValues.map((item, idx) => (
                        <div key={idx}>
                          <span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel(item.riskValue) }}
                          >
                          </span>
                        </div>
                      ))}
                    </Col>
                  </Row>
                )} */}

              </Card>
            </Col>
          ))}
          {diagnosis?.listOfRisk?.length > 1 ? 
          <Col lg={12}>
            <div className="meter-content-wrapper mb-5" style={{padding: "15px"}}>
              <div className="meter-content-inner summery">
                <div className="meter-head">
                  <h4>Common Risk Factors</h4>
                </div>
                <div className="meter-content">
                  <p>
                  The following are common risk factors for both the diseases for which you are at risk because of Lifestyle Habits.
                  </p>
                  <div style={{marginTop: "10px"}}>
                    <ul style={{listStyle: "disc"}}>
                      {diagnosis?.commonRiskFactors?.map((commonRiskFactor,index) => (
                        <li key={index}><h6>{commonRiskFactor}</h6></li>
                      ))}
                    
                    </ul> 
                  </div>
                  <p style={{marginTop: "5px" , marginBottom: "5px"}}>
                  These are modifiable risk factors and you should take concrete steps to work on these to reduce your risk for both the diseases.
                  </p>
                  <p style={{color: "green" , fontSize: "inherit" , fontWeight: "600"}}>
                  Please remember pevention is 100 times better than cure .
                  </p>
                </div>
                
              </div>
            </div>
          </Col> :
          <Col lg={6}>
           <div className="meter-content-wrapper" style={{height: "100%" , marginTop: "0px" , padding: "15px"}}>
              <div className="meter-content-inner summery">
                <div className="meter-head">
                  <h4> Risk Factors</h4>
                </div>
                <div className="meter-content">
                  <p>
                  The following are risk factors for disease for which you are at risk because of Lifestyle Habits.
                  </p>
                  <div style={{marginTop: "10px"}}>
                    <ul style={{listStyle: "disc"}}>
                      {diagnosis?.commonRiskFactors?.map((commonRiskFactor,index) => (
                        <li key={index}><h6>{commonRiskFactor}</h6></li>
                      ))}
                    
                    </ul> 
                  </div>
                  <p style={{marginTop: "5px" , marginBottom: "5px"}}>
                  These are modifiable risk factors and you should take concrete steps to work on these to reduce your risk for disease.
                  </p>
                  <p style={{color: "green" , fontSize: "inherit" , fontWeight: "600"}}>
                  Please remember pevention is 100 times better than cure .
                  </p>
                </div>
                
              </div>
            </div>
          </Col>
            }
          
        </Row>



              {/* <div className="meter-content-wrapper mb-5">
            <div className="meter-content-inner summery">
              <div className="meter-head">
                <h4> Risk Factors</h4>
              </div>
              <div className="meter-content">
                <p>
                The following are common risk factors for both the diseases for which you are at risk because of Lifestyle Habits.
                </p>
                <p style={{marginTop: "10px" , marginBottom: "10px"}}>
                These are modifiable risk factors and you should take concrete steps to work on these to reduce your risk for both the diseases.
                </p>
                <p>
                Please remember pevention is 100 times better than cure .
                </p>
              </div>
              <div style={{marginTop: "20px"}}>
                <h6>High Blood Pressure</h6>
                <h6>Physical Inactivity</h6>
                <h6>Diet</h6>
              </div>
            </div>
              </div> */}
      </> : <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    No Diagnosis Available
                  </Typography>
                </div>
              </Box>}
     
</div>
)};

export default LifeStyleModificationRecommendation;