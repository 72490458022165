export const vaccineReportsData = [
    {
      // age: "Birth",
      entries: [
        { vaccine: "BCG" }
        // { vaccine: "OPV-0" },
        // { vaccine: "Hep B-1 (Birth Dose)" },
      ],
      preferredDate: ["", "", ""],
      maxDate: ["", "", ""],
      plannedDate: ["", "", ""],
      actualDate: ["", "", ""],
      hospitalName: ["", "", ""],
      doctorName: ["", "", ""],
      status: ["", "", ""]
    },
    {
      // age: "6 week",
      entries: [
        { vaccine: "DTWP/DTaP-1" },
        { vaccine: "IPV-1" },
        { vaccine: "Hib-1" },
        { vaccine: "Hep B-2" },
        { vaccine: "Rota-1" },
        { vaccine: "PCV-1" },
      ],
      preferredDate: ["", "", "", "", ""],
      maxDate: ["", "", "", ""],
      plannedDate: ["", "", "", ""],
      actualDate: ["", "", "", ""],
      hospitalName: ["", "", "", ""],
      doctorName: ["", "", "", ""],
      status: ["", "", "", ""]
    },
    {
      // age: "10 week",
      entries: [
        { vaccine: "DTWP/DTaP-2" },
        { vaccine: "IPV-2" },
        { vaccine: "Hib-2" },
        { vaccine: "Hep B-3" },
        { vaccine: "Rota-2" },
        { vaccine: "PCV-2" },
      ],
      preferredDate: ["", "", "", "", ""],
      maxDate: ["", "", "", ""],
      plannedDate: ["", "", "", ""],
      actualDate: ["", "", "", ""],
      hospitalName: ["", "", "", ""],
      doctorName: ["", "", "", ""],
      status: ["", "", "", ""]
    },
    {
      // age: "14 week",
      entries: [
        { vaccine: "DTWP/DTaP-3" },
        { vaccine: "IPV-3" },
        { vaccine: "Hib-3" },
        { vaccine: "Hep B-4" },
        { vaccine: "Rota-3" },
        { vaccine: "PCV-3" },
      ],
      preferredDate: ["", "", "", "", ""],
      maxDate: ["", "", "", ""],
      plannedDate: ["", "", "", ""],
      actualDate: ["", "", "", ""],
      hospitalName: ["", "", "", ""],
      doctorName: ["", "", "", ""],
      status: ["", "", "", ""]
    },
    {
      // age: "6 Month",
      entries: [{ vaccine: "Influenza(IIV)-1" }],
      preferredDate: [""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "7 Month",
      entries: [
        { vaccine: "Influenza(IIV)-2" },
        { vaccine: "1 dose every year till 5 year" },
        { vaccine: "Year 2" },
        { vaccine: "Year 3" },
        { vaccine: "Year 4" },
        { vaccine: "Year 5" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "6-9 month",
      entries: [{ vaccine: "TCV" }],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "9 month",
      entries: [
        { vaccine: "MMR-1" },
        { vaccine: "MCV-1" },
        { vaccine: "MCV-1" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "12 month",
      entries: [
        { vaccine: "Hep-1" },
        { vaccine: "MCV-2" },
        { vaccine: "JE-1" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "13 month",
      entries: [{ vaccine: "JE-2" }],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "15 month",
      entries: [
        { vaccine: "MMR-2" },
        { vaccine: "Vercella-1" },
        { vaccine: "PCV booster" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "16-18 month",
      entries: [
        { vaccine: "DTWP/DTaP-B1" },
        { vaccine: "Hib-B1" },
        { vaccine: "IPV-B1" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "18-19 month",
      entries: [
        { vaccine: "Hep A-2 (for killed vaccine only" },
        { vaccine: "Vericella-2" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "4-6 year",
      entries: [
        { vaccine: "DTWP/DTaP-B2" },
        { vaccine: "IPV-B2" },
        { vaccine: "MMR-3" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
    {
      // age: "10-15 year",
      entries: [
        { vaccine: "Tdap" },
        { vaccine: "HPV-1" },
        { vaccine: "HPV-2" },
      ],
      preferredDate: ["", ""],
      maxDate: [""],
      plannedDate: [""],
      actualDate: [""],
      hospitalName: [""],
      doctorName: [""],
      status: [""]
    },
  ];