import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./CardiacRiskAssessment.css";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  TextField,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Diversity2TwoTone } from "@mui/icons-material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getCardiacRiskAssessment } from "../../constants/apiList";
import Swal from "sweetalert2";

const CardiacRiskAssessment = () => {
  const [systolic, setSystolic] = useState("");
  const [firstDegreeRelative, setfirstDegreeRelative] = useState("");
  const [patientAge, setpatientAge] = useState("");
  const [cardiacRiskData, setCardiacRiskData] = useState({});
  const [riskAssessmentData, setRiskAssessmentData] = useState([]);
  const [recalculate, setRecalculate] = useState(false);
  const [familyMember, setFamilyMember] = useState("");
  const [familyMemberAge, setfamilyMemberAge] = useState();
  console.log("cardiacRiskData: ", cardiacRiskData);

  const handlefamilyMemberChange = (event) => {
    setFamilyMember(event.target.value);
  };

  const handleFirstDegreeRelativeChange = (event) => {
    setfirstDegreeRelative(event.target.value);
  };

  const handlePatientAgeChange = (event) => {
    setpatientAge(event.target.value);
  };

  const handleChange = (e) => {
    setSystolic(e.target.value);
    setRecalculate(true);
  };
  const cardiacRiskController = async () => {
    await AxiosInstance.get(`${getCardiacRiskAssessment}`)
      .then((res) => {
        setCardiacRiskData(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    cardiacRiskController();
  }, []);

  // if (cardiacRiskData.hdlc && Object.keys(cardiacRiskData.hdlc).length > 0) {
  //   const Hdlcvalue =
  //     cardiacRiskData && parseFloat(Object.keys(cardiacRiskData?.hdlc)[0]);
  //   const hdlcdate = cardiacRiskData && Object.values(cardiacRiskData?.hdlc)[0];

  //   // Format the date to "21-Jan-2024"
  //   const hdlcdateObj = new Date(hdlcdate);
  //   const hdlcoptions = { year: "numeric", month: "short", day: "2-digit" };
  //   const HdlcformattedDate = hdlcdateObj
  //     .toLocaleDateString("en-GB", hdlcoptions)
  //     .replace(/ /g, "-");
  //   const riskAssessmentData = [
  //     {
  //       name: "Gender",
  //       value: cardiacRiskData.gender,
  //       riskPoint: 6.0,
  //       date: "-",
  //     },
  //     {
  //       name: "Age",
  //       value: cardiacRiskData.age,
  //       riskPoint: 6.0,
  //       date: "21-Jan-2024",
  //     },
  //     {
  //       name: "Smoker(Y/N)",
  //       value: cardiacRiskData.smoker,
  //       riskPoint: 9.0,
  //       date: "21-Jan-2024",
  //     },
  //     {
  //       name: "Diabetes (Y/N)",
  //       value: cardiacRiskData.diabetes,
  //       riskPoint: 9.0,
  //       date: "21-Jan-2024",
  //     },
  //     {
  //       name: "HDL-C(mmol/L)",
  //       value: Hdlcvalue,
  //       riskPoint: 9.0,
  //       date: HdlcformattedDate,
  //     },
  //     {
  //       name: "Total Cholesterol",
  //       value: cardiacRiskData.totalCholestrol,
  //       riskPoint: 9.0,
  //       date: "21-Jan-2024",
  //     },
  //     {
  //       name: "Systolic Blood Pressure(mmHg)",
  //       value: 90,
  //       riskPoint: 9.0,
  //       date: "21-Jan-2024",
  //       isInput: true,
  //     },
  //   ];
  // } else {
  //   console.error("Invalid HDL-C data");
  // }
  const extractData = (hdlc) => {
    if (hdlc && Object.keys(hdlc).length > 0) {
      const value = parseFloat(Object.keys(hdlc)[0]);
      const date = Object.values(hdlc)[0];
      const dateObj = new Date(date);
      const options = { year: "numeric", month: "short", day: "2-digit" };
      const formattedDate = dateObj
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, "-");
      return { value, formattedDate };
    }
    return { value: null, formattedDate: null };
  };

  const extractFamilyMemberData = (modifiedCvdRiskPerson) => {
    if (
      modifiedCvdRiskPerson &&
      Object.keys(modifiedCvdRiskPerson).length > 0
    ) {
      const familyMember = Object.keys(modifiedCvdRiskPerson)[0];
      const familyMemberAge = modifiedCvdRiskPerson[familyMember];
      return { familyMember, familyMemberAge };
    }
    return { familyMember: null, familyMemberAge: null };
  };

  useEffect(() => {
    if (Object.keys(cardiacRiskData).length > 0) {
      const { value: HdlcValue, formattedDate: HdlcFormattedDate } =
        extractData(cardiacRiskData.hdlc);
      const {
        value: totalCholestrolValue,
        formattedDate: totalCholestrolFormattedDate,
      } = extractData(cardiacRiskData.totalCholesterol);

      const {
        value: systolicBloodPressureValue,
        formattedDate: systolicBloodPressureDate,
      } = extractData(cardiacRiskData.systolicBloodPressure);

      const updatedRiskAssessmentData = [
        {
          name: "Gender",
          value: cardiacRiskData.gender,
          riskPoint: cardiacRiskData.genderPoint,
          date: "-",
        },
        {
          name: "Age",
          value: cardiacRiskData.age,
          riskPoint: cardiacRiskData.agePoint,
          date: "-",
        },
        {
          name: "Smoker(Y/N)",
          value: cardiacRiskData.smoker,
          riskPoint: cardiacRiskData.smokerPoint,
          date: "-",
        },
        {
          name: "Diabetes (Y/N)",
          value: cardiacRiskData.diabetes,
          riskPoint: cardiacRiskData.diabetesPoint,
          date: "-",
        },
        {
          name: "HDL-C(mmol/L)",
          value: HdlcValue,
          riskPoint: cardiacRiskData.hdlcPoint,
          date: HdlcFormattedDate,
        },
        {
          name: "Total Cholesterol",
          value: totalCholestrolValue,
          riskPoint: cardiacRiskData.totalCholesterolPoint,
          date: totalCholestrolFormattedDate,
        },
        {
          name: "Systolic Blood Pressure(mmHg)",
          value: systolicBloodPressureValue,
          riskPoint: cardiacRiskData.systolicBloodPressurePoint,
          date: systolicBloodPressureDate,
          isInput: true,
        },
      ];
      setSystolic(systolicBloodPressureValue);
      setRiskAssessmentData(updatedRiskAssessmentData);
      if (
        Object.keys(cardiacRiskData).length > 0 &&
        cardiacRiskData.modifiedCvdRiskPerson != null
      ) {
        setfirstDegreeRelative("yes");
        setpatientAge(
          cardiacRiskData.age > "29" && cardiacRiskData.age < "60"
            ? "yes"
            : "no"
        );
      } else {
        setfirstDegreeRelative("no");
      }
      const { familyMember, familyMemberAge } = extractFamilyMemberData(
        cardiacRiskData.modifiedCvdRiskPerson
      );
      setFamilyMember(familyMember);
      setfamilyMemberAge(familyMemberAge);
    }
  }, [cardiacRiskData]);
  const ReviewAndUnderstood = () => {
    const lrid = "CVD-02-01";
    AxiosInstance.post(`/riskAssessment/reviewAndUnderstood/${lrid}`)
      .then((response) => {
        console.log("response", response);
        Swal.fire({
          text: "Your Response has been Saved!",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleRecalculate = () => {
    const { value: HdlcValue, formattedDate: HdlcFormattedDate } = extractData(
      cardiacRiskData.hdlc
    );
    const {
      value: totalCholestrolValue,
      formattedDate: totalCholestrolFormattedDate,
    } = extractData(cardiacRiskData.totalCholesterol);
    const recalculateData = {
      gender: cardiacRiskData.gender,
      age: parseInt(cardiacRiskData.age),
      smoker: cardiacRiskData.smoker,
      diabetes: cardiacRiskData.diabetes,
      hdlc: parseFloat(HdlcValue),
      totalCholesterol: parseFloat(totalCholestrolValue),
      systolicBloodPressure: systolic,
    };
    console.log("recalculate", recalculateData);
    AxiosInstance.post(
      "/riskAssessment/cardiacRiskAssessmentCalculator",
      recalculateData
    )
      .then((response) => {
        console.log("response", response);
        setCardiacRiskData(response);
        Swal.fire({
          text: "Your Result has been Recalculated",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="cardiac-wrapper mb-3">
      <Row className="mb-3">
        <Col lg={6}>
          <h4 className="cardiac-title">
            FRAMANGHAM Risk Score For CVD Risk Assessment
          </h4>
          <div className="resultText">
            <label>Date Of Assessment :</label>
            <input
              type="date"
              name="risk-score-date"
              className="risk-date-field"
            />
          </div>
          <TableContainer className="risk-table-wrapper">
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#aaa" }}>
                <TableRow>
                  <TableCell>Risk Factor</TableCell>
                  <TableCell align="center">Value</TableCell>
                  <TableCell align="center">Risk Points</TableCell>
                  <TableCell align="center">Date (If Applicable)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {riskAssessmentData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell scope="row">{row.name}</TableCell>
                    {row.isInput ? (
                      <TableCell align="center">
                        <input
                          type="text"
                          className="table-input"
                          style={{ width: "50px" }}
                          value={systolic}
                          onChange={handleChange}
                        />
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        {typeof row.value === "boolean"
                          ? !!row.value
                            ? "Y"
                            : "N"
                          : row.value}
                      </TableCell>
                    )}
                    <TableCell align="center">{row.riskPoint}</TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p className="small-note">
            Note : The physician can input latest value of systolic blood
            pressure and recalculate cardiac risk level.
          </p>
          {recalculate && (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                onClick={handleRecalculate}
                sx={{
                  background: "#5353e9",
                  color: "#fff",
                  minWidth: "180px",
                  "&:hover": { background: "#c60176" },
                }}
              >
                Recalculate
              </Button>
            </div>
          )}
        </Col>
        <Col lg={6}>
          <h4 className="cardiac-title"> Modified FRS</h4>
          <div className="resultText">
            <label>Date Of Assessment :</label>
            <input type="date" className="risk-date-field" />
          </div>
          <div className="modified-frs-wrapper">
            <FormControl sx={{ display: "contents" }}>
              <FormLabel className="formLabel">
                Any of your first-degree relative( parents, siblings or
                children) had positive history of premature CVD?
              </FormLabel>
              <RadioGroup
                row
                aria-label="agree"
                name="agree"
                value={firstDegreeRelative}
                onChange={handleFirstDegreeRelativeChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {firstDegreeRelative == "yes" && (
              <FormControl sx={{ display: "contents" }}>
                <FormLabel className="formLabel">
                  Is current age of patient between 30-59? :
                </FormLabel>
                <RadioGroup
                  aria-label="agree"
                  name="agree"
                  value={patientAge}
                  onChange={handlePatientAgeChange}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            )}
            {patientAge == "yes" && (
              <>
                <FormControl sx={{ display: "contents" }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    className="formLabel"
                  >
                    Who ?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={familyMember}
                    onChange={handlefamilyMemberChange}
                  >
                    <FormControlLabel
                      value="Mother"
                      control={<Radio />}
                      label="Mother"
                    />
                    <FormControlLabel
                      value="Father"
                      control={<Radio />}
                      label="Father"
                    />
                    <FormControlLabel
                      value="Brother"
                      control={<Radio />}
                      label="Brother"
                    />
                    <FormControlLabel
                      value="Sister"
                      control={<Radio />}
                      label="Sister"
                    />
                    <FormControlLabel
                      value="Son"
                      control={<Radio />}
                      label="Son"
                    />
                    <FormControlLabel
                      value="Daughter"
                      control={<Radio />}
                      label="Daughter"
                    />
                  </RadioGroup>
                </FormControl>

                <span>At What Age</span>
                <input
                  className="table-input"
                  value={familyMemberAge}
                  style={{
                    width: "50px",
                    textIndent: "10px",
                    marginLeft: "10px",
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>

      <div>
        <Row>
          <Col lg={6}>
            <h4 className="results cardiac-title">Results</h4>
            <Card className="result-card">
              <div className="result-head-wrapper">
                <div className="result-head">
                  <span className="frsResults">Total Risk Score : </span>
                  <span>{cardiacRiskData.riskPoint}</span>
                </div>
                <div className="result-head">
                  <span className="frsResults">10 Year CVD Risk (%) : </span>
                  <span>{cardiacRiskData.cvdRiskPercentage}</span>
                </div>
                <div className="result-head">
                  <span className="frsResults">Heart Age : </span>
                  <span>{cardiacRiskData.heartAge}</span>
                </div>
                <div className="result-head">
                  <span className="frsResults">Risk Level : </span>
                  {cardiacRiskData.riskLevel && (
                    <span>{cardiacRiskData.riskLevel.riskLevel}</span>
                  )}
                </div>
                <div className="result-head">
                  <span className="frsResults">
                    Modified 10 Year CVD risk % :
                  </span>
                  <span>
                    {cardiacRiskData.modifiedCvdRiskPercentage
                      ? cardiacRiskData.modifiedCvdRiskPercentage
                      : 0}
                  </span>
                </div>
              </div>
              <p className="resultText">
                Your risk of having a heart attack or stroke within the next 10
                years is:
                <span className="heartAttackText">
                  {cardiacRiskData.cvdRiskPercentage}
                </span>
              </p>
              <p className="resultText">
                Your 10-year FRS score :
                <span className="heartAttackText">
                  {cardiacRiskData.riskPoint}
                </span>
              </p>
              <p className="resultText">
                The score of a healthy person with same age , diabetes and
                smoking condition : <span className="heartAttackText">NA</span>
              </p>
              <p className="resultText">
                Relative risk* : <span className="heartAttackText"> NA</span>
              </p>
            </Card>
            <p className="small-note">
              * This means your risk of having heart attack or stroke is
              <span className="heartAttackText">23</span> times more or less
              than a person of same age , diabetes and smoking condition
            </p>
          </Col>
          <Col lg={6}>
            <h4 className="cardiac-title">
              Treatment Recommendations (To be determined and filled by
              Physician)
            </h4>
            <Card className="result-card">
              <div className="treatment-field-wrapper">
                <div className="treatment-field">
                  <span>Lipid Targets : LDL-C :</span>
                  <input className="treatment-input" type="text" name="ldl-c" />
                </div>
                <div className="treatment-field">
                  <span>or Apo-B :</span>
                  <input className="treatment-input" type="text" name="apo-b" />
                </div>
                <div className="treatment-field">
                  <span>Physician Name :</span>
                  <input
                    className="treatment-input"
                    type="text"
                    name="physician-name"
                  />
                </div>
                <div className="treatment-field">
                  <span>Date :</span>
                  <input type="date" className="treatment-input" />
                </div>
              </div>
            </Card>
            <p className="small-note">
              * <span className="highlight-value">apoB:</span> apolipoprotein B
              stat,<span className="highlight-value">CVD:</span> cardiovascular
              disease,
              <span className="highlight-value">FRS:</span> Framingham Risk
              Score,<span className="highlight-value">HDL-C:</span> high-density
              lipoprotein cholesterol,
              <span className="highlight-value">LDL-C:</span> low-density
              lipoprotein cholesterol.
            </p>
          </Col>
        </Row>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <Button
            onClick={ReviewAndUnderstood}
            sx={{
              background: "#5353e9",
              color: "#fff",
              minWidth: "180px",
              "&:hover": { background: "#c60176" },
            }}
          >
            Reviewed and Understood
          </Button>
        </div>
        <div className="resultText">
          <span style={{ color: "red" }}>Caution :</span> Consider LDL-C 1.8
          mmol/L for subjects with acute coronary syndrome (ACS) within past 3
          months
        </div>

        <Row>
          <Col lg={1}>Reference:</Col>
          <Col lg={11}>
            <p className="small-note">
              Adapted from: D’Agostino RB et al.(i). General cardiovascular risk
              profile for use in primary care. The Framingham Heart Study. Circ
              2008;117:743-53
            </p>
            <p className="small-note">
              Adapted from: Genest J et al.(i). 2009 Canadian Cardiovascular
              Society/Canadian guidelines for the diagnosis and treatment of
              dyslipidemia and prevention of cardiovascular disease in the
              adult. Can J Cardiol. 2009;25(10):567-579.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CardiacRiskAssessment;
