import React from "react";
import LabHistoryTable from "./LabHistoryTable";
import "./style.css";

export const ElementChronologyOfLabTests = () => {
  return (
    <>
      <h2 className="pageTitle">Attach Lab Records</h2>
      <LabHistoryTable />
    </>
  );
};
