import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getPerformanceRating } from "../../constants/apiList";
import TablePagination from "@mui/material/TablePagination";
import "./style.css";

const DieticianPerformanceTable = () => {
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const getPerformance = async () => {
    await AxiosInstance.get(`${getPerformanceRating}`)
      .then((response) => {
        console.log("response: ", response);
        if (response != null) {
          setRowData(response);
        } else {
          setRowData("");
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  //   useEffect(() => {
  //     getPerformance();
  //   }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <h3>Performance</h3>
      <p style={{ marginTop: "1%" }}>
        <b>
          Your performance will be assessed by our client for each order. A
          Performance rating on a scale of 1 to 5 will be shown here along with
          comments from client. An Example is shown below.
        </b>
      </p>
      <TableContainer>
        <Table style={{ border: "1px solid lightgrey", marginTop: "20px" }}>
          <TableHead>
            <TableRow>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                Date
              </TableCell>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                LSH Order ID
              </TableCell>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                Patient ID
              </TableCell>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                Performance Rating
              </TableCell>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                Comments for Clients
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length === 0 ? (
              <TableRow>
                {/* <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", border: "1px solid lightgrey" }}
                >
                  <b>No data available</b>
                </TableCell> */}
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  22/03/2023
                </TableCell>
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  123
                </TableCell>
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  639
                </TableCell>
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  4
                </TableCell>
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  Good
                </TableCell>
              </TableRow>
            ) : (
              rowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index + page * rowsPerPage}>
                    <TableCell style={{ border: "1px solid lightgrey" }}>
                      {row.createdDate}
                    </TableCell>
                    <TableCell style={{ border: "1px solid lightgrey" }}>
                      {row.orderId}
                    </TableCell>
                    <TableCell style={{ border: "1px solid lightgrey" }}>
                      {row.patientId}
                    </TableCell>
                    <TableCell style={{ border: "1px solid lightgrey" }}>
                      {row.performanceRating}
                    </TableCell>
                    <TableCell style={{ border: "1px solid lightgrey" }}>
                      {row.clientComment}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default DieticianPerformanceTable;
