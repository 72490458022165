import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Col, Input, Row, Toast } from "reactstrap";
import { Checkbox, FormGroup, Grid, MenuItem, Select, TextField, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import Swal from "sweetalert2";
import { event } from "react-ga";

const GymGeneralInfo = ({ onBack }) => {
  const [environmentStep, setEnvironmentStep] = useState(0);
  const [sizeOfGym, setSizeOfGym] = useState("");
  const [gymestablised, setGymEstablised] = useState("");
  const [locationOfGym, setLocationOfGym] = useState("");
  const [isGroundLevel, setIsGroundLevel] = useState("");
  const [femaleStaffMemberPresent, setFemaleStaffMemberPresent] = useState("");
  const [describeGym, setDescribeGym] = useState("");
  const [gymTime, setGymTime] = useState("");
  const [staffMemberPresent, setStaffMemberPresent] = useState("");
  const [femaleOutfitSafety, setFemaleOutfitSafety] = useState("");
  const [memberAgeGroup, setMemberAgeGroup] = useState("");
  const [compositionInGender, setCompositionInGender] = useState("");
  const [memberGymAttract, setMemberGymAttract] = useState("");
  const [machineInstruction, setMachineInstruction] = useState("");
  const [timeLimitOnCardio, setTimeLimitOnCardio] = useState("");
  const [explainCardioTimeLimit, setExplainCardioTimeLimit] = useState("");
  const [otherFacility, setOtherFacility] = useState("");
  const [introductoryClinic, setIntroductoryClinic] = useState("");
  const [separateTraning, setSeparateTraining] = useState("");
  const [initialFitnessOrientation, setInitialFitnessOrientation] = useState("");
  const [areaDedicated, setAreaDedicated] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldErrors2, setFieldErrors2] = useState({});
  const [fieldErrors3, setFieldErrors3] = useState({});
  const [fieldErrors4, setFieldErrors4] = useState({});
  const [fieldErrors5, setFieldErrors5] = useState({});
  const [fieldErrors6, setFieldErrors6] = useState({});
  const [fieldErrors7, setFieldErrors7] = useState({});
  const [fieldErrors8, setFieldErrors8] = useState({});
  const [gymOfferTesting, setGymOfferTesting] = useState("");
  const [freeTrailMembership, setFreeTrailMembership] = useState("");
  const [freeTrailMembershipExplain, setFreeTrailMembershipExplain] = useState("");
  const [getTour, setGetTour] = useState("");
  const [getTourTime, setGetTourTime] = useState("");
  const [refundMembership, setRefundMembership] = useState("");
  const [differentMembersipPlan, setDifferentMembersipPlan] = useState("");
  const [explainDifferentMembershipPlan, setExplainDifferentMembershipPlan] = useState("");
  const [trainersQualified, setTrainersQualified] = useState("");
  const [areMaleTrainers, setAreMaleTrainers] = useState("");
  const [maleTrainerExpirence, setMaleTrainerExpirence] = useState("")
  const [maleWeekdaysFrom, setMaleWeekdaysFrom] = useState("");
  const [maleWeekdaysTo, setMaleWeekdaysTo] = useState("");
  const [maleWeekendsFrom, setMaleWeekendsFrom] = useState("");
  const [maleWeekendsTo, setMaleWeekendsTo] = useState("");
  const [femaleWeekdaysFrom, setFemaleWeekdaysFrom] = useState("");
  const [femaleWeekdaysTo, setFemaleWeekdaysTo] = useState("");
  const [femaleWeekendsFrom, setFemaleWeekendsFrom] = useState("");
  const [femaleWeekendsTo, setFemaleWeekendsTo] = useState("");
  const [femaleTrainerExpirence, setFemaleTrainerExpirence] = useState("");
  const [areFemaleTrainer, setAreFemaleTrainer] = useState("");
  const [anyManager, setAnyManager] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerQualification, setManagerQualification] = useState("");
  const [managerExpirence, setManagerExpirence] = useState("");
  const [managerAward, setManaegrAward] = useState("");
  const [explainManagerAward, setExplainManagerAward] = useState("");
  const [gymMaintainCleanliness, setGymMaintainCleanliness] = useState("");
  const [explainCleanliness, setExplainCleanliness] = useState("");
  const [cleaningStationPlaced, setCleaningStationPlaced] = useState("");
  const [hygieneStandard, setHygieneStandard] = useState("")
  const [properlyMaintained, setProperlyMaintained] = useState("")
  const [explainProperlyMaintained, setExplainProperlyMaintained] = useState("")
  const [gymOfferAmenities, setGymOfferAmenities] = useState("")
  const [kidsArea, setKidsArea] = useState("")
  const [onlyLadiesWorkoutSpace, setOnlyLadiesWorkoutSpace] = useState("")
  const [sauna, setSauna] = useState("")
  const [functionalTrainingArea, setFunctionalTrainingArea] = useState("")
  const [personalTrainer, setPersoanlTrainer] = useState("")
  const [wifi, setWifi] = useState("");
  const [proShop, setProShop] = useState("");
  const [otherAmenities, setOtherAmenities] = useState("");
  const [variousTrainingProgram, setVariousTrainingProgram] = useState("")
  const [groupFitnessClasses, setGroupFitnessClasses] = useState("")
  const [extraCharge, setExtraCharge] = useState("")
  const [promotionalCurrentPlan, setPromotionalCurrentPlan] = useState("")
  const [paymentPlan, setPaymentPlan] = useState("");
  const [programList, setProgramList] = useState("");
  const [attachPhoto, setAttachPhoto] = useState("")
  const [kindOfEquipment, setKindOfEquipment] = useState("")

  const handleGymEstablised = (event) => {
    setGymEstablised(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      gymestablised: false,
    }));
  };

  const handleIsGroundLevel = (event) => {
    const value = event.target.value === "true";
    setIsGroundLevel(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      isGroundLevel: false,
    }));
  };
  const handleSizeOfGym = (event) => {
    setSizeOfGym(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      sizeOfGym: false,
    }));
  };

  const handleLocationOfGym = (event) => {
    setLocationOfGym(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      locationOfGym: false,
    }));
  };

  const handleDescribeGym = (event) => {
    setDescribeGym(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      describeGym: false,
    }));
  };

  const handleGymTime = (event) => {
    setGymTime(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      gymTime: false,
    }));
  };

  const handleStaffMemberPresent = (event) => {
    setStaffMemberPresent(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      staffMemberPresent: false,
    }));
  };

  const handleTimeLimitCardio = (event) => {
    const value = event.target.value === "true";
    setTimeLimitOnCardio(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      timeLimitOnCardio: false,
    }));
  };

  const handleExplaincardioTimeLimit = (event) => {
    setExplainCardioTimeLimit(event.target.value);
     setFieldErrors2((prevErrors) => ({
       ...prevErrors,
       explainCardioTimeLimit: false,
     }));
  }

  const handleFemaleOutfitSafety = (event) => {
    setFemaleOutfitSafety(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      femaleOutfitSafety: false,
    }));
  };

  const handleMemberAgeGroup = (event) => {
    setMemberAgeGroup(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      memberAgeGroup: false,
    }));
  };
  const handleCompositionInGender = (event) => {
    setCompositionInGender(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      compositionInGender: false,
    }));
  };

  const handleMemberGymAttract = (event) => {
    setMemberGymAttract(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      memberGymAttract: false,
    }))
  }

  const handleMachineInstruction = (event) => {
    setMachineInstruction(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      machineInstruction: false,
    }));
  };

  const handleOtherFacility = (event) => {
    setOtherFacility(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      otherFacility: false,
    }));
  };

  const handleIntroductoryClinic = (event) => {
    const value = event.target.value === "true";
    setIntroductoryClinic(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      introductoryClinic: false,
    }));
  };

  const handleAreaDedicated = (event) => {
    const value = event.target.value === "true";
    setAreaDedicated(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      areaDedicated: false,
    }));
  };

  const handleGymOfferTesting = (event) => {
    const value = event.target.value === "true";
    setGymOfferTesting(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      gymOfferTesting: false,
    }));
  };
  const handleFreeTrailMembership = (event) => {
    const value = event.target.value === "true";
    setFreeTrailMembership(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      freeTrailMembership: false,
    }));
  };
  const handleFreeTrailMembershipExplain = (event) => {
    setFreeTrailMembershipExplain(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      freeTrailMembershipExplain: false,
    }));
  };

  const handleGetTour = (event) => {
    const value = event.target.value === "true";
    setGetTour(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      getTour: false,
    }));
  };
  const handleGetTourTime = (event) => {
    setGetTourTime(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      getTourTime: false,
    }));
  };
  const handleRefundMembership = (event) => {
    const value = event.target.value === "true";
    setRefundMembership(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      refundMembership: false,
    }));
  };
  const handleDifferentMembershipPlan = (event) => {
    const value = event.target.value === "true";
    setDifferentMembersipPlan(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      differentMembersipPlan: false,
    }));
  };
  const handleExplainDifferentMembershipPlan = (event) => {
    setExplainDifferentMembershipPlan(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      explainDifferentMembershipPlan: false,
    }));
  };

  const handleFemaleStaffMemberPresent = (event) => {
    setFemaleStaffMemberPresent(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      femaleStaffMemberPresent: false,
    }));
  };
  const handleTrainerQualified = (event) => {
    const value = event.target.value === "true";
    setTrainersQualified(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      trainersQualified: false,
    }));
  };
  const handleAreMaleTrainers = (event) => {
    const value = event.target.value === "true";
    setAreMaleTrainers(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      areMaleTrainers: false,
    }));
  };

  const handleMaleTrainerExpirence = (event) => {
     setMaleTrainerExpirence(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      maleTrainerExpirence: false,
    }));
  }
    const handleMaleWeekdaysFrom = (event) => {
      setMaleWeekdaysFrom(event.target.value);
      setFieldErrors4((prevErrors) => ({
        ...prevErrors,
        maleWeekdaysFrom: false,
      }));
    };
     const handleMaleWeekdaysTo = (event) => {
       setMaleWeekdaysTo(event.target.value);
       setFieldErrors4((prevErrors) => ({
         ...prevErrors,
         maleWeekdaysTo: false,
       }));
     };
     const handleMaleWeekendsFrom = (event) => {
       setMaleWeekendsFrom(event.target.value);
       setFieldErrors4((prevErrors) => ({
         ...prevErrors,
         maleWeekendsFrom: false,
       }));
     };
     const handleMaleWeekendsTo = (event) => {
       setMaleWeekendsTo(event.target.value);
       setFieldErrors4((prevErrors) => ({
         ...prevErrors,
         maleWeekendsTo: false,
       }));
     };
      const handleFemaleWeekdaysFrom = (event) => {
        setFemaleWeekdaysFrom(event.target.value);
        setFieldErrors4((prevErrors) => ({
          ...prevErrors,
          femaleWeekdaysFrom: false,
        }));
      };
      const handleFemaleWeekdaysTo = (event) => {
        setFemaleWeekdaysTo(event.target.value);
        setFieldErrors4((prevErrors) => ({
          ...prevErrors,
          femaleWeekdaysTo: false,
        }));
      };
      const handleFemaleWeekendsFrom = (event) => {
        setFemaleWeekendsFrom(event.target.value);
        setFieldErrors4((prevErrors) => ({
          ...prevErrors,
          femaleWeekendsFrom: false,
        }));
      };
      const handleFemaleWeekendsTo = (event) => {
        setFemaleWeekendsTo(event.target.value);
        setFieldErrors4((prevErrors) => ({
          ...prevErrors,
          femaleWeekendsTo: false,
        }));
      };
        
    const handleFemaleTrainerExpirence = (event) => {
     setFemaleTrainerExpirence(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      femaleTrainerExpirence: false,
    }));
  }

  const handleAreFemaleTrainer = (event) => {
    const value = event.target.value === "true";
    setAreFemaleTrainer(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      areFemaleTrainer: false,
    }));
  };

   const handleKidsAres = (event) => {
     const value = event.target.value === "true";
     setKidsArea(value);
     setFieldErrors6((prevErrors) => ({
       ...prevErrors,
       kidsArea: false,
     }));
   };
   const handleOnlyLadiesWorkoutSpace = (event) => {
     const value = event.target.value === "true";
     setOnlyLadiesWorkoutSpace(value);
     setFieldErrors6((prevErrors) => ({
       ...prevErrors,
       onlyLadiesWorkoutSpace: false,
     }));
   };

  const handleSauna = (event) => {
    const value = event.target.value === "true";
    setSauna(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      sauna: false,
    }));
  };
   const handleFunctionalTrainingArea = (event) => {
     const value = event.target.value === "true";
     setFunctionalTrainingArea(value);
     setFieldErrors6((prevErrors) => ({
       ...prevErrors,
       functionalTrainingArea: false,
     }));
   };
   const handlePersonalTrainer = (event) => {
     const value = event.target.value === "true";
     setPersoanlTrainer(value);
     setFieldErrors6((prevErrors) => ({
       ...prevErrors,
       personalTrainer: false,
     }));
   };
   const handleWifi = (event) => {
     const value = event.target.value === "true";
     setWifi(value);
     setFieldErrors6((prevErrors) => ({
       ...prevErrors,
       wifi: false,
     }));
   };
   const handleProShop = (event) => {
     const value = event.target.value === "true";
     setProShop(value);
     setFieldErrors6((prevErrors) => ({
       ...prevErrors,
       proShop: false,
     }));
   };
   const handleOtherAmenities = (event) => {
     setOtherAmenities(event.target.value);
   };

  const handleSeparateTraining = (event) => {
    const value = event.target.value === "true";
    setSeparateTraining(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      separateTraning: false,
    }));
  };
  const handleInitialFitnessOrientation = (event) => {
    const value = event.target.value === "true";
    setInitialFitnessOrientation(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      initialFitnessOrientation: false,
    }));
  };
  const handleAnyManager = (event) => {
    const value = event.target.value === "true";
    setAnyManager(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      anyManager: false,
    }));
  };

  const handleManagerName = (event) => {
    setManagerName(event.target.value);
     setFieldErrors4((prevErrors) => ({
       ...prevErrors,
       managerName: false,
     }));
  };
  const handleManagerQualification = (event) => {
    setManagerQualification(event.target.value);
     setFieldErrors4((prevErrors) => ({
       ...prevErrors,
       managerQualification: false,
     }));
  };

  const handleManagerExpirence = (event) => {
    setManagerExpirence(event.target.value);
     setFieldErrors4((prevErrors) => ({
       ...prevErrors,
       managerExpirence: false,
     }));
  };

  const handleManagerAward = (event) => {
    const value = event.target.value === "true";
    setManaegrAward(value);
     setFieldErrors4((prevErrors) => ({
       ...prevErrors,
       managerAward: false,
     }));
  };
  const handleExplainManagerAward = (event) => {
    setExplainManagerAward(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      explainManagerAward: false,
    }));
  };
  const handleGymMaintainCleanliness = (event) => {
    const value = event.target.value === "true";
    setGymMaintainCleanliness(value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      gymMaintainCleanliness: false,
    }));
  };

  const handleExplainCleanliness = (event) => {
    setExplainCleanliness(event.target.value);
     setFieldErrors5((prevErrors) => ({
       ...prevErrors,
       explainCleanliness: false,
     }));
  };

  const handleCleaningStationPlaced = (event) => {
    setCleaningStationPlaced(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      cleaningStationPlaced: false,
    }));
  };

   const handleHygieneStandard = (event) => {
     setHygieneStandard(event.target.value);
     setFieldErrors5((prevErrors) => ({
       ...prevErrors,
       hygieneStandard: false,
     }));
   };
  
   const handleProperlyMaintained = (event) => {
     const value = event.target.value === "true";
     setProperlyMaintained(value);
     setFieldErrors5((prevErrors) => ({
       ...prevErrors,
       properlyMaintained: false,
     }));
   };

   const handleExplainProperlyMaintained = (event) => {
     setExplainProperlyMaintained(event.target.value);
     setFieldErrors5((prevErrors) => ({
       ...prevErrors,
       explainProperlyMaintained: false,
     }));
   };
  
    const handleGymOfferAmenities = (event) => {
      const value = event.target.value === "true";
      setGymOfferAmenities(value);
      setFieldErrors6((prevErrors) => ({
        ...prevErrors,
        gymOfferAmenities: false,
      }));
    };
  const handleVariousTrainingProgram = (event) => {
    setVariousTrainingProgram(event.target.value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      variousTrainingProgram: false,
    }));
  };
     const handleGroupFitnessClasses = (event) => {
       const value = event.target.value === "true";
       setGroupFitnessClasses(value);
       setFieldErrors7((prevErrors) => ({
         ...prevErrors,
         groupFitnessClasses: false,
       }));
     };
  const handleExtraCharge = (event) => {
    const value = event.target.value === "true";
    setExtraCharge(value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      extraCharge: false,
    }));
  };
   const handlePromotionalCurrentPlan = (event) => {
     const value = event.target.value === "true";
     setPromotionalCurrentPlan(value);
     setFieldErrors8((prevErrors) => ({
       ...prevErrors,
       promotionalCurrentPlan: false,
     }));
   };
   const handlePaymentPlan = (event) => {
     const value = event.target.value === "true";
     setPaymentPlan(value);
     setFieldErrors8((prevErrors) => ({
       ...prevErrors,
       paymentPlan: false,
     }));
   };
    const handleProgramList = (event) => {
      const file = event.target.files[0];
      setProgramList("fileHere");
       setFieldErrors7((prevErrors) => ({
         ...prevErrors,
         programList: false,
      }));
    };
    const handleAttachPhoto = (event) => {
      const file = event.target.files[0];
      setAttachPhoto("fileHere");
    };

  const handleEnvironmentStep1 = () => {
    const errors = {};

    if (gymestablised === "") {
      errors.gymestablised = true;
    }
    if (sizeOfGym === "") {
      errors.sizeOfGym = true;
    }
    if (locationOfGym === "") {
      errors.locationOfGym = true;
    }
    if (isGroundLevel === "") {
      errors.isGroundLevel = true;
    }
    if (describeGym === "") {
      errors.describeGym = true;
    }
    if (gymTime === "") {
      errors.gymTime = true;
    }
    if (staffMemberPresent === "") {
      errors.staffMemberPresent = true;
    }
    if (femaleStaffMemberPresent === "") {
      errors.femaleStaffMemberPresent = true;
    }
    if (femaleOutfitSafety === "") {
      errors.femaleOutfitSafety = true;
    }
    if (memberAgeGroup === "") {
      errors.memberAgeGroup = true;
    }
    if (compositionInGender === "") {
      errors.compositionInGender = true;
    }
    if (memberGymAttract === "") {
      errors.memberGymAttract = true;
    }
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
        const formData = {
          gymestablised: gymestablised,
          sizeOfGym: sizeOfGym,
          locationOfGym: locationOfGym,
          isGroundLevel: isGroundLevel,
          describeGym: describeGym,
          gymTime: gymTime,
          staffMemberPresent: staffMemberPresent,
          femaleStaffMemberPresent: femaleStaffMemberPresent,
          femaleOutfitSafety: femaleOutfitSafety,
          memberAgeGroup: memberAgeGroup,
          compositionInGender: compositionInGender,
          memberGymAttract: memberGymAttract,
          kindOfEquipment: rows,
          machineInstruction: machineInstruction,
          timeLimitOnCardio: timeLimitOnCardio,
          explainCardioTimeLimit: explainCardioTimeLimit,
          otherFacility: otherFacility,
          introductoryClinic: introductoryClinic,
          separateTraning: separateTraning,
          initialFitnessOrientation: initialFitnessOrientation,
          areaDedicated: areaDedicated,
          gymOfferTesting: gymOfferTesting,
          freeTrailMembership: freeTrailMembership,
          freeTrailMembershipExplain: freeTrailMembershipExplain,
          getTour: getTour,
          getTourTime: getTourTime,
          refundMembership: refundMembership,
          differentMembersipPlan: differentMembersipPlan,
          explainDifferentMembershipPlan: explainDifferentMembershipPlan,
          trainersQualified: trainersQualified,
          areMaleTrainers: areMaleTrainers,
          maleTrainerExpirence: maleTrainerExpirence,
          maleWeekdaysFrom: maleWeekdaysFrom,
          maleWeekdaysTo: maleWeekdaysTo,
          maleWeekendsFrom: maleWeekendsFrom,
          maleWeekendsTo: maleWeekendsTo,
          areFemaleTrainer: areFemaleTrainer,
          femaleTrainerExpirence: femaleTrainerExpirence,
          femaleWeekdaysFrom: femaleWeekdaysTo,
          femaleWeekendsFrom: femaleWeekendsTo,
          femaleWeekendsTo: femaleWeekendsTo,
          anyManager: anyManager,
          managerName: managerName,
          managerQualification: managerQualification,
          managerExpirence: managerExpirence,
          managerAward: managerAward,
          explainManagerAward: explainManagerAward,
          gymMaintainCleanliness: gymMaintainCleanliness,
          explainCleanliness: explainCleanliness,
          cleaningStationPlaced: cleaningStationPlaced,
          hygieneStandard: hygieneStandard,
          properlyMaintained: properlyMaintained,
          explainProperlyMaintained: explainProperlyMaintained,
          gymOfferAmenities: gymOfferAmenities,
          kidsArea: kidsArea,
          onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
          sauna: sauna,
          functionalTrainingArea: functionalTrainingArea,
          personalTrainer: personalTrainer,
          wifi: wifi,
          proShop: proShop,
          otherAmenities: otherAmenities,
          variousTrainingProgram: variousTrainingProgram,
          groupFitnessClasses: groupFitnessClasses,
          extraCharge: extraCharge,
          promotionalCurrentPlan: promotionalCurrentPlan,
          paymentPlan: paymentPlan,
        };
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep2 = () => {
    const errors2 = {};
    if (machineInstruction === "") {
      errors2.machineInstruction = true;
    }
    if (timeLimitOnCardio === "") {
      errors2.timeLimitOnCardio = true;
    }
    if (timeLimitOnCardio === true) {
      if (explainCardioTimeLimit === "") {
        errors2.explainCardioTimeLimit = true;
      }
    }
    if (otherFacility === "") {
      errors2.otherFacility = true;
    }
    const equipmentErrors = rows
      .map((row, index) => {
        const rowErrors = {};
        if (!row.equipment || !row.type || !row.equipmentOld) {
          rowErrors.kindOfEquipment = "Please fill all fields for this equipment entry.";
        }
        if (Object.keys(rowErrors).length > 0) {
          return { index, errors: rowErrors };
        }
        return null;
      })
      .filter((error) => error !== null);
    // If any row has validation errors, add them to the main errors object
    if (equipmentErrors.length > 0) {
      errors2.kindOfEquipment = equipmentErrors.map((error) => ({
        index: error.index,
        message: error.errors.kindOfEquipment,
      }));
    }
    setFieldErrors2(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymestablised: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        staffMemberPresent: staffMemberPresent,
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        kindOfEquipment: rows,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraning: separateTraning,
        initialFitnessOrientation: initialFitnessOrientation,
        areaDedicated: areaDedicated,
        gymOfferTesting: gymOfferTesting,
        freeTrailMembership: freeTrailMembership,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour,
        getTourTime: getTourTime,
        refundMembership: refundMembership,
        differentMembersipPlan: differentMembersipPlan,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified,
        areMaleTrainers: areMaleTrainers,
        maleTrainerExpirence: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer,
        femaleTrainerExpirence: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsTo,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExpirence: managerExpirence,
        managerAward: managerAward,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities,
        kidsArea: kidsArea,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
        sauna: sauna,
        functionalTrainingArea: functionalTrainingArea,
        personalTrainer: personalTrainer,
        wifi: wifi,
        proShop: proShop,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses,
        extraCharge: extraCharge,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
      };
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep3 = () => {
    const errors2 = {};
    if (introductoryClinic === "") {
      errors2.introductoryClinic = true;
    }
    if (separateTraning === "") {
      errors2.separateTraning = true;
    }
    if (initialFitnessOrientation === "") {
      errors2.initialFitnessOrientation = true;
    }
    if (areaDedicated === "") {
      errors2.areaDedicated = true;
    }
    if (gymOfferTesting === "") {
      errors2.gymOfferTesting = true;
    }
    if (freeTrailMembership === "") {
      errors2.freeTrailMembership = true;
    }
    if (freeTrailMembership == true) {
      if (freeTrailMembershipExplain === "") {
        errors2.freeTrailMembershipExplain = true;
      }
    }
    if (getTour === "") {
      errors2.getTour = true;
    }
    if (getTour == true) {
      if (getTourTime === "") {
        errors2.getTourTime = true;
      }
    }
    if (refundMembership === "") {
      errors2.refundMembership = true;
    }
    if (differentMembersipPlan === "") {
      errors2.differentMembersipPlan = true;
    }
    if (differentMembersipPlan == true) {
      if (explainDifferentMembershipPlan === "") {
        errors2.explainDifferentMembershipPlan = true;
      }
    }

    setFieldErrors3(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
        const formData = {
          gymestablised: gymestablised,
          sizeOfGym: sizeOfGym,
          locationOfGym: locationOfGym,
          isGroundLevel: isGroundLevel,
          describeGym: describeGym,
          gymTime: gymTime,
          staffMemberPresent: staffMemberPresent,
          femaleStaffMemberPresent: femaleStaffMemberPresent,
          femaleOutfitSafety: femaleOutfitSafety,
          memberAgeGroup: memberAgeGroup,
          compositionInGender: compositionInGender,
          memberGymAttract: memberGymAttract,
          kindOfEquipment: rows,
          machineInstruction: machineInstruction,
          timeLimitOnCardio: timeLimitOnCardio,
          explainCardioTimeLimit: explainCardioTimeLimit,
          otherFacility: otherFacility,
          introductoryClinic: introductoryClinic,
          separateTraning: separateTraning,
          initialFitnessOrientation: initialFitnessOrientation,
          areaDedicated: areaDedicated,
          gymOfferTesting: gymOfferTesting,
          freeTrailMembership: freeTrailMembership,
          freeTrailMembershipExplain: freeTrailMembershipExplain,
          getTour: getTour,
          getTourTime: getTourTime,
          refundMembership: refundMembership,
          differentMembersipPlan: differentMembersipPlan,
          explainDifferentMembershipPlan: explainDifferentMembershipPlan,
          trainersQualified: trainersQualified,
          areMaleTrainers: areMaleTrainers,
          maleTrainerExpirence: maleTrainerExpirence,
          maleWeekdaysFrom: maleWeekdaysFrom,
          maleWeekdaysTo: maleWeekdaysTo,
          maleWeekendsFrom: maleWeekendsFrom,
          maleWeekendsTo: maleWeekendsTo,
          areFemaleTrainer: areFemaleTrainer,
          femaleTrainerExpirence: femaleTrainerExpirence,
          femaleWeekdaysFrom: femaleWeekdaysTo,
          femaleWeekendsFrom: femaleWeekendsTo,
          femaleWeekendsTo: femaleWeekendsTo,
          anyManager: anyManager,
          managerName: managerName,
          managerQualification: managerQualification,
          managerExpirence: managerExpirence,
          managerAward: managerAward,
          explainManagerAward: explainManagerAward,
          gymMaintainCleanliness: gymMaintainCleanliness,
          explainCleanliness: explainCleanliness,
          cleaningStationPlaced: cleaningStationPlaced,
          hygieneStandard: hygieneStandard,
          properlyMaintained: properlyMaintained,
          explainProperlyMaintained: explainProperlyMaintained,
          gymOfferAmenities: gymOfferAmenities,
          kidsArea: kidsArea,
          onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
          sauna: sauna,
          functionalTrainingArea: functionalTrainingArea,
          personalTrainer: personalTrainer,
          wifi: wifi,
          proShop: proShop,
          otherAmenities: otherAmenities,
          variousTrainingProgram: variousTrainingProgram,
          groupFitnessClasses: groupFitnessClasses,
          extraCharge: extraCharge,
          promotionalCurrentPlan: promotionalCurrentPlan,
          paymentPlan: paymentPlan,
        };
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep4 = () => {
    const errors2 = {};

    if (trainersQualified === "") {
      errors2.trainersQualified = true
    }
    if (areMaleTrainers === "") {
      errors2.areMaleTrainers = true;
    }
    if (areMaleTrainers == true) {
      if (maleTrainerExpirence === "") {
        errors2.maleTrainerExpirence = true;
      }
      if (maleWeekdaysFrom === "") {
        errors2.maleWeekdaysFrom = true;
      }
      if (maleWeekdaysTo === "") {
        errors2.maleWeekdaysTo = true;
      }
      if (maleWeekendsFrom === "") {
        errors2.maleWeekendsFrom = true;
      }
      if (maleWeekendsTo === "") {
        errors2.maleWeekendsTo = true;
      }
    }
    if (areFemaleTrainer === "") {
      errors2.areFemaleTrainer = true;
    }
    if (areFemaleTrainer == true) {
      if (femaleTrainerExpirence === "") {
        errors2.femaleTrainerExpirence = true;
      }
      if (femaleWeekdaysFrom === "") {
        errors2.femaleWeekdaysFrom = true;
      }
      if (femaleWeekdaysTo === "") {
        errors2.femaleWeekdaysTo = true;
      }
      if (femaleWeekendsFrom === "") {
        errors2.femaleWeekendsFrom = true;
      }
      if (femaleWeekendsTo === "") {
        errors2.femaleWeekendsTo = true;
      }
    }
    if (anyManager === "") {
      errors2.anyManager = true;
    }
    if (anyManager == true) {
      if (managerName === "") {
        errors2.managerName = true;
      }
      if (managerQualification === "") {
        errors2.managerQualification = true;
      }
      if (managerExpirence === "") {
        errors2.managerExpirence = true;
      }
      if (managerAward === "") {
        errors2.managerAward = true;
      }
      if (managerAward == true) {
        if (explainManagerAward === "") {
          errors2.explainManagerAward = true;
        }
      }
    }
    setFieldErrors4(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
       const formData = {
         gymestablised: gymestablised,
         sizeOfGym: sizeOfGym,
         locationOfGym: locationOfGym,
         isGroundLevel: isGroundLevel,
         describeGym: describeGym,
         gymTime: gymTime,
         staffMemberPresent: staffMemberPresent,
         femaleStaffMemberPresent: femaleStaffMemberPresent,
         femaleOutfitSafety: femaleOutfitSafety,
         memberAgeGroup: memberAgeGroup,
         compositionInGender: compositionInGender,
         memberGymAttract: memberGymAttract,
         kindOfEquipment: rows,
         machineInstruction: machineInstruction,
         timeLimitOnCardio: timeLimitOnCardio,
         explainCardioTimeLimit: explainCardioTimeLimit,
         otherFacility: otherFacility,
         introductoryClinic: introductoryClinic,
         separateTraning: separateTraning,
         initialFitnessOrientation: initialFitnessOrientation,
         areaDedicated: areaDedicated,
         gymOfferTesting: gymOfferTesting,
         freeTrailMembership: freeTrailMembership,
         freeTrailMembershipExplain: freeTrailMembershipExplain,
         getTour: getTour,
         getTourTime: getTourTime,
         refundMembership: refundMembership,
         differentMembersipPlan: differentMembersipPlan,
         explainDifferentMembershipPlan: explainDifferentMembershipPlan,
         trainersQualified: trainersQualified,
         areMaleTrainers: areMaleTrainers,
         maleTrainerExpirence: maleTrainerExpirence,
         maleWeekdaysFrom: maleWeekdaysFrom,
         maleWeekdaysTo: maleWeekdaysTo,
         maleWeekendsFrom: maleWeekendsFrom,
         maleWeekendsTo: maleWeekendsTo,
         areFemaleTrainer: areFemaleTrainer,
         femaleTrainerExpirence: femaleTrainerExpirence,
         femaleWeekdaysFrom: femaleWeekdaysTo,
         femaleWeekendsFrom: femaleWeekendsTo,
         femaleWeekendsTo: femaleWeekendsTo,
         anyManager: anyManager,
         managerName: managerName,
         managerQualification: managerQualification,
         managerExpirence: managerExpirence,
         managerAward: managerAward,
         explainManagerAward: explainManagerAward,
         gymMaintainCleanliness: gymMaintainCleanliness,
         explainCleanliness: explainCleanliness,
         cleaningStationPlaced: cleaningStationPlaced,
         hygieneStandard: hygieneStandard,
         properlyMaintained: properlyMaintained,
         explainProperlyMaintained: explainProperlyMaintained,
         gymOfferAmenities: gymOfferAmenities,
         kidsArea: kidsArea,
         onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
         sauna: sauna,
         functionalTrainingArea: functionalTrainingArea,
         personalTrainer: personalTrainer,
         wifi: wifi,
         proShop: proShop,
         otherAmenities: otherAmenities,
         variousTrainingProgram: variousTrainingProgram,
         groupFitnessClasses: groupFitnessClasses,
         extraCharge: extraCharge,
         promotionalCurrentPlan: promotionalCurrentPlan,
         paymentPlan: paymentPlan,
       };
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep5 = () => {
    const errors2 = {};

    if (gymMaintainCleanliness === "") {
      errors2.gymMaintainCleanliness = true;
    }
    if (gymMaintainCleanliness == true) {
      if (explainCleanliness === "") {
        errors2.explainCleanliness = true;
      }
    }
    if (cleaningStationPlaced === "") {
      errors2.cleaningStationPlaced = true;
    }
    if (hygieneStandard === "") {
      errors2.hygieneStandard = true;
    }
    if (properlyMaintained === "") {
      errors2.properlyMaintained = true;
    }
    if (properlyMaintained == true) {
      if (explainProperlyMaintained === "") {
        errors2.explainProperlyMaintained = true;
      }
    }
    setFieldErrors5(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
        const formData = {
          gymestablised: gymestablised,
          sizeOfGym: sizeOfGym,
          locationOfGym: locationOfGym,
          isGroundLevel: isGroundLevel,
          describeGym: describeGym,
          gymTime: gymTime,
          staffMemberPresent: staffMemberPresent,
          femaleStaffMemberPresent: femaleStaffMemberPresent,
          femaleOutfitSafety: femaleOutfitSafety,
          memberAgeGroup: memberAgeGroup,
          compositionInGender: compositionInGender,
          memberGymAttract: memberGymAttract,
          kindOfEquipment: rows,
          machineInstruction: machineInstruction,
          timeLimitOnCardio: timeLimitOnCardio,
          explainCardioTimeLimit: explainCardioTimeLimit,
          otherFacility: otherFacility,
          introductoryClinic: introductoryClinic,
          separateTraning: separateTraning,
          initialFitnessOrientation: initialFitnessOrientation,
          areaDedicated: areaDedicated,
          gymOfferTesting: gymOfferTesting,
          freeTrailMembership: freeTrailMembership,
          freeTrailMembershipExplain: freeTrailMembershipExplain,
          getTour: getTour,
          getTourTime: getTourTime,
          refundMembership: refundMembership,
          differentMembersipPlan: differentMembersipPlan,
          explainDifferentMembershipPlan: explainDifferentMembershipPlan,
          trainersQualified: trainersQualified,
          areMaleTrainers: areMaleTrainers,
          maleTrainerExpirence: maleTrainerExpirence,
          maleWeekdaysFrom: maleWeekdaysFrom,
          maleWeekdaysTo: maleWeekdaysTo,
          maleWeekendsFrom: maleWeekendsFrom,
          maleWeekendsTo: maleWeekendsTo,
          areFemaleTrainer: areFemaleTrainer,
          femaleTrainerExpirence: femaleTrainerExpirence,
          femaleWeekdaysFrom: femaleWeekdaysTo,
          femaleWeekendsFrom: femaleWeekendsTo,
          femaleWeekendsTo: femaleWeekendsTo,
          anyManager: anyManager,
          managerName: managerName,
          managerQualification: managerQualification,
          managerExpirence: managerExpirence,
          managerAward: managerAward,
          explainManagerAward: explainManagerAward,
          gymMaintainCleanliness: gymMaintainCleanliness,
          explainCleanliness: explainCleanliness,
          cleaningStationPlaced: cleaningStationPlaced,
          hygieneStandard: hygieneStandard,
          properlyMaintained: properlyMaintained,
          explainProperlyMaintained: explainProperlyMaintained,
          gymOfferAmenities: gymOfferAmenities,
          kidsArea: kidsArea,
          onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
          sauna: sauna,
          functionalTrainingArea: functionalTrainingArea,
          personalTrainer: personalTrainer,
          wifi: wifi,
          proShop: proShop,
          otherAmenities: otherAmenities,
          variousTrainingProgram: variousTrainingProgram,
          groupFitnessClasses: groupFitnessClasses,
          extraCharge: extraCharge,
          promotionalCurrentPlan: promotionalCurrentPlan,
          paymentPlan: paymentPlan,
        };
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep6 = () => {
    const errors2 = {};
    if (gymOfferAmenities === "") {
      errors2.gymOfferAmenities = true;
    }
    if (gymOfferAmenities == true) {
      if (kidsArea === "") {
        errors2.kidsArea = true;
      }
      if (onlyLadiesWorkoutSpace === "") {
        errors2.onlyLadiesWorkoutSpace = true;
      }
      if (sauna === "") {
        errors2.sauna = true;
      }
      if (functionalTrainingArea === "") {
        errors2.functionalTrainingArea = true;
      }
      if (personalTrainer === "") {
        errors2.personalTrainer = true;
      }
      if (wifi === "") {
        errors2.wifi = true;
      }
      if (proShop === "") {
        errors2.proShop = true;
      }
    }
    setFieldErrors6(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
        const formData = {
          gymestablised: gymestablised,
          sizeOfGym: sizeOfGym,
          locationOfGym: locationOfGym,
          isGroundLevel: isGroundLevel,
          describeGym: describeGym,
          gymTime: gymTime,
          staffMemberPresent: staffMemberPresent,
          femaleStaffMemberPresent: femaleStaffMemberPresent,
          femaleOutfitSafety: femaleOutfitSafety,
          memberAgeGroup: memberAgeGroup,
          compositionInGender: compositionInGender,
          memberGymAttract: memberGymAttract,
          kindOfEquipment: rows,
          machineInstruction: machineInstruction,
          timeLimitOnCardio: timeLimitOnCardio,
          explainCardioTimeLimit: explainCardioTimeLimit,
          otherFacility: otherFacility,
          introductoryClinic: introductoryClinic,
          separateTraning: separateTraning,
          initialFitnessOrientation: initialFitnessOrientation,
          areaDedicated: areaDedicated,
          gymOfferTesting: gymOfferTesting,
          freeTrailMembership: freeTrailMembership,
          freeTrailMembershipExplain: freeTrailMembershipExplain,
          getTour: getTour,
          getTourTime: getTourTime,
          refundMembership: refundMembership,
          differentMembersipPlan: differentMembersipPlan,
          explainDifferentMembershipPlan: explainDifferentMembershipPlan,
          trainersQualified: trainersQualified,
          areMaleTrainers: areMaleTrainers,
          maleTrainerExpirence: maleTrainerExpirence,
          maleWeekdaysFrom: maleWeekdaysFrom,
          maleWeekdaysTo: maleWeekdaysTo,
          maleWeekendsFrom: maleWeekendsFrom,
          maleWeekendsTo: maleWeekendsTo,
          areFemaleTrainer: areFemaleTrainer,
          femaleTrainerExpirence: femaleTrainerExpirence,
          femaleWeekdaysFrom: femaleWeekdaysTo,
          femaleWeekendsFrom: femaleWeekendsTo,
          femaleWeekendsTo: femaleWeekendsTo,
          anyManager: anyManager,
          managerName: managerName,
          managerQualification: managerQualification,
          managerExpirence: managerExpirence,
          managerAward: managerAward,
          explainManagerAward: explainManagerAward,
          gymMaintainCleanliness: gymMaintainCleanliness,
          explainCleanliness: explainCleanliness,
          cleaningStationPlaced: cleaningStationPlaced,
          hygieneStandard: hygieneStandard,
          properlyMaintained: properlyMaintained,
          explainProperlyMaintained: explainProperlyMaintained,
          gymOfferAmenities: gymOfferAmenities,
          kidsArea: kidsArea,
          onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
          sauna: sauna,
          functionalTrainingArea: functionalTrainingArea,
          personalTrainer: personalTrainer,
          wifi: wifi,
          proShop: proShop,
          otherAmenities: otherAmenities,
          variousTrainingProgram: variousTrainingProgram,
          groupFitnessClasses: groupFitnessClasses,
          extraCharge: extraCharge,
          promotionalCurrentPlan: promotionalCurrentPlan,
          paymentPlan: paymentPlan,
        };
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep7 = () => {
    const errors2 = {};
    if (variousTrainingProgram === "") {
      errors2.variousTrainingProgram = true;
    }
    if (groupFitnessClasses === "") {
      errors2.groupFitnessClasses = true;
    }
    if (extraCharge === "") {
      errors2.extraCharge = true;
    }
    // if (programList === "") {
    //   errors2.programList = true;
    // }
    setFieldErrors7(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
        const formData = {
          gymestablised: gymestablised,
          sizeOfGym: sizeOfGym,
          locationOfGym: locationOfGym,
          isGroundLevel: isGroundLevel,
          describeGym: describeGym,
          gymTime: gymTime,
          staffMemberPresent: staffMemberPresent,
          femaleStaffMemberPresent: femaleStaffMemberPresent,
          femaleOutfitSafety: femaleOutfitSafety,
          memberAgeGroup: memberAgeGroup,
          compositionInGender: compositionInGender,
          memberGymAttract: memberGymAttract,
          kindOfEquipment: rows,
          machineInstruction: machineInstruction,
          timeLimitOnCardio: timeLimitOnCardio,
          explainCardioTimeLimit: explainCardioTimeLimit,
          otherFacility: otherFacility,
          introductoryClinic: introductoryClinic,
          separateTraning: separateTraning,
          initialFitnessOrientation: initialFitnessOrientation,
          areaDedicated: areaDedicated,
          gymOfferTesting: gymOfferTesting,
          freeTrailMembership: freeTrailMembership,
          freeTrailMembershipExplain: freeTrailMembershipExplain,
          getTour: getTour,
          getTourTime: getTourTime,
          refundMembership: refundMembership,
          differentMembersipPlan: differentMembersipPlan,
          explainDifferentMembershipPlan: explainDifferentMembershipPlan,
          trainersQualified: trainersQualified,
          areMaleTrainers: areMaleTrainers,
          maleTrainerExpirence: maleTrainerExpirence,
          maleWeekdaysFrom: maleWeekdaysFrom,
          maleWeekdaysTo: maleWeekdaysTo,
          maleWeekendsFrom: maleWeekendsFrom,
          maleWeekendsTo: maleWeekendsTo,
          areFemaleTrainer: areFemaleTrainer,
          femaleTrainerExpirence: femaleTrainerExpirence,
          femaleWeekdaysFrom: femaleWeekdaysTo,
          femaleWeekendsFrom: femaleWeekendsTo,
          femaleWeekendsTo: femaleWeekendsTo,
          anyManager: anyManager,
          managerName: managerName,
          managerQualification: managerQualification,
          managerExpirence: managerExpirence,
          managerAward: managerAward,
          explainManagerAward: explainManagerAward,
          gymMaintainCleanliness: gymMaintainCleanliness,
          explainCleanliness: explainCleanliness,
          cleaningStationPlaced: cleaningStationPlaced,
          hygieneStandard: hygieneStandard,
          properlyMaintained: properlyMaintained,
          explainProperlyMaintained: explainProperlyMaintained,
          gymOfferAmenities: gymOfferAmenities,
          kidsArea: kidsArea,
          onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
          sauna: sauna,
          functionalTrainingArea: functionalTrainingArea,
          personalTrainer: personalTrainer,
          wifi: wifi,
          proShop: proShop,
          otherAmenities: otherAmenities,
          variousTrainingProgram: variousTrainingProgram,
          groupFitnessClasses: groupFitnessClasses,
          extraCharge: extraCharge,
          promotionalCurrentPlan: promotionalCurrentPlan,
          paymentPlan: paymentPlan,
        };
      // AxiosInstance.post("form/addEnvironmentSketch", formData)
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });
      setEnvironmentStep((prev) => prev + 1);
    }
  };

  const handleEnvironmentStepBack = () => {
    setEnvironmentStep((prev) => prev - 1);
  };

  const GymFromSubmit = (e) => {
    e.preventDefault();
    const errors3 = {};
    if (promotionalCurrentPlan === "") {
      errors3.promotionalCurrentPlan = true;
    }
    if (paymentPlan === "") {
      errors3.paymentPlan = true;
    }

    setFieldErrors8(errors3);
    
 if (Object.keys(errors3).length > 0) {
   Swal.fire({
     icon: "error",
     title: "Oops...",
     text: "Fill all the Mandatory Fields!",
   });
 } else {
    const formData = {
      gymestablised: gymestablised,
      sizeOfGym: sizeOfGym,
      locationOfGym: locationOfGym,
      isGroundLevel: isGroundLevel,
      describeGym: describeGym,
      gymTime: gymTime,
      staffMemberPresent: staffMemberPresent,
      femaleStaffMemberPresent: femaleStaffMemberPresent,
      femaleOutfitSafety: femaleOutfitSafety,
      memberAgeGroup: memberAgeGroup,
      compositionInGender: compositionInGender,
      memberGymAttract: memberGymAttract,
      kindOfEquipment: rows,
      machineInstruction: machineInstruction,
      timeLimitOnCardio: timeLimitOnCardio,
      explainCardioTimeLimit: explainCardioTimeLimit,
      otherFacility: otherFacility,
      introductoryClinic: introductoryClinic,
      separateTraning: separateTraning,
      initialFitnessOrientation: initialFitnessOrientation,
      areaDedicated: areaDedicated,
      gymOfferTesting: gymOfferTesting,
      freeTrailMembership: freeTrailMembership,
      freeTrailMembershipExplain: freeTrailMembershipExplain,
      getTour: getTour,
      getTourTime: getTourTime,
      refundMembership: refundMembership,
      differentMembersipPlan: differentMembersipPlan,
      explainDifferentMembershipPlan: explainDifferentMembershipPlan,
      trainersQualified: trainersQualified,
      areMaleTrainers: areMaleTrainers,
      maleTrainerExpirence: maleTrainerExpirence,
      maleWeekdaysFrom: maleWeekdaysFrom,
      maleWeekdaysTo: maleWeekdaysTo,
      maleWeekendsFrom: maleWeekendsFrom,
      maleWeekendsTo: maleWeekendsTo,
      areFemaleTrainer: areFemaleTrainer,
      femaleTrainerExpirence: femaleTrainerExpirence,
      femaleWeekdaysFrom: femaleWeekdaysTo,
      femaleWeekendsFrom: femaleWeekendsTo,
      femaleWeekendsTo: femaleWeekendsTo,
      anyManager: anyManager,
      managerName: managerName,
      managerQualification: managerQualification,
      managerExpirence: managerExpirence,
      managerAward: managerAward,
      explainManagerAward: explainManagerAward,
      gymMaintainCleanliness: gymMaintainCleanliness,
      explainCleanliness: explainCleanliness,
      cleaningStationPlaced: cleaningStationPlaced,
      hygieneStandard: hygieneStandard,
      properlyMaintained: properlyMaintained,
      explainProperlyMaintained: explainProperlyMaintained,
      gymOfferAmenities: gymOfferAmenities,
      kidsArea: kidsArea,
      onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace,
      sauna: sauna,
      functionalTrainingArea: functionalTrainingArea,
      personalTrainer: personalTrainer,
      wifi: wifi,
      proShop: proShop,
      otherAmenities: otherAmenities,
      variousTrainingProgram: variousTrainingProgram,
      groupFitnessClasses: groupFitnessClasses,
      extraCharge: extraCharge,
      promotionalCurrentPlan: promotionalCurrentPlan,
      paymentPlan: paymentPlan,
    };
   // AxiosInstance.post("form/addEnvironmentSketch", formData)
   //   .then((response) => {
   //     Swal.fire({
   //       icon: "success",
   //       title: "Success",
   //       text: "Your Form Submitted Successfully!",
   //     });
   //     setTimeout(() => {
   //       onBack();
   //     }, 1000);
   //   })
   //   .catch((error) => {
   //     console.log(error);
   //   });
 }
  };
  
 const [rows, setRows] = useState([
   { equipment: "", type: "", equipmentOld: "" },
 ]);

const handleAddRow = () => {
  if (!rows[0].equipment || !rows[0].type || !rows[0].equipmentOld) {
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      kindOfEquipment: true,
    }));
    return;
  }
  const newRow = { equipment: "", type: "", equipmentOld: "" };

  setRows((prevRows) => {
    const updatedRows = [...prevRows, newRow];
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      kindOfEquipment: false,
    }));

    return updatedRows;
  });
};
const isAddButtonDisabled = !rows[0].equipment || !rows[0].type || !rows[0].equipmentOld;

const handleDeleteRow = (index) => {
  setRows((prevRows) => {
    const updatedRows = prevRows.filter((_, i) => i !== index);
    if (updatedRows.length === 0) {
      setFieldErrors2((prevErrors) => ({
        ...prevErrors,
        kindOfEquipment: true,
      }));
    } else {
      setFieldErrors2((prevErrors) => ({
        ...prevErrors,
        kindOfEquipment: false,
      }));
    }

    return updatedRows;
  });
};
  
 const handleInputChange = (e, index, field) => {
   const newRows = [...rows];
   newRows[index][field] = e.target.value;
   setRows(newRows);
 };
  return (
    <div className="profile_section">
      <ToastMessage></ToastMessage>

      {environmentStep == 0 && (
        <Grid container spacing={2} mb={5}>
          <Grid item>
            <div className="register_title">
              <h2>GYM PARTNER QUESTIONNAIRE</h2>
            </div>
          </Grid>
        </Grid>
      )}

      <form onSubmit={GymFromSubmit} className="gym_from">
        {environmentStep == 0 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>1. General Questions </h4>
                </div>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.gymestablised ? "red" : "inherit",
                    }}
                  >
                    1.1 When was this gym established ?
                  </FormLabel>
                  <input
                    type="date"
                    style={{ textIndent: "10px" }}
                    className="form-control"
                    value={gymestablised}
                    onChange={(e) => {
                      handleGymEstablised(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.sizeOfGym ? "red" : "inherit",
                    }}
                  >
                    1.2 What is the size of the gym in terms of sq. ft. area?
                  </FormLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={sizeOfGym}
                    onChange={(e) => {
                      handleSizeOfGym(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.locationOfGym ? "red" : "inherit",
                    }}
                  >
                    1.3 What is the location?{" "}
                  </FormLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={locationOfGym}
                    onChange={(e) => {
                      handleLocationOfGym(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.isGroundLevel ? "red" : "inherit",
                    }}
                  >
                    1.4 Is the gym at the ground level?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={isGroundLevel} name="radio-buttons-group" onChange={handleIsGroundLevel}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.describeGym ? "red" : "inherit",
                    }}
                  >
                    1.5 Please describe. Give a picture of the gym.
                  </FormLabel>
                  <div style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}>
                    <textarea
                      name="licenses"
                      value={describeGym}
                      onChange={(e) => {
                        // handleLicenseChange(index, e.target.value);
                        // handleLicenses(e);
                        handleDescribeGym(e);
                      }}
                      type="text"
                      style={{ width: "40rem" }}
                      // style={{
                      //   borderColor: index == 0 ? (fieldErrors ? "red" : "lightgrey") : "lightgrey",
                      // }}
                    />
                    <label htmlFor="licenses" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline" }}>
                      Attach photo
                    </label>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      id="licenses"
                      value={attachPhoto}
                      className="file-upload-input"
                      name="licenses"
                      style={{ display: "none" }}
                      onChange={handleAttachPhoto}
                    />
                    {/* {license.documentLink && (
                    <div>
                      <p>{license.documentLink}</p>
                    </div>
                  )} */}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.gymTime ? "red" : "inherit",
                    }}
                  >
                    1.6 What is the general time of the gym ?
                  </FormLabel>
                  <input
                    type="time"
                    className="form-control"
                    value={gymTime}
                    onChange={(e) => {
                      handleGymTime(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.staffMemberPresent ? "red" : "inherit",
                    }}
                  >
                    1.7 How many staff members are present at any time in the gym ?
                  </FormLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={staffMemberPresent}
                    onChange={(e) => {
                      handleStaffMemberPresent(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.femaleStaffMemberPresent ? "red" : "inherit",
                    }}
                  >
                    1.8 Are female staff members always present to assist female members in case of any need?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={femaleStaffMemberPresent} name="radio-buttons-group" onChange={handleFemaleStaffMemberPresent}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.femaleOutfitSafety ? "red" : "inherit",
                    }}
                  >
                    1.9 How does the outfit ensure safety and security of all its members especially female members? Please elaborate on safety and security measures.
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={femaleOutfitSafety}
                    onChange={(e) => {
                      handleFemaleOutfitSafety(e);
                    }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.memberAgeGroup ? "red" : "inherit",
                    }}
                  >
                    1.10 What is the typical age group of all the present members ?
                  </FormLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={memberAgeGroup}
                    onChange={(e) => {
                      handleMemberAgeGroup(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.compositionInGender ? "red" : "inherit",
                    }}
                  >
                    1.11 What is the present composition in terms of gender of all present members? (% female vs male members).
                  </FormLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={compositionInGender}
                    onChange={(e) => {
                      handleCompositionInGender(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={5}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.memberGymAttract ? "red" : "inherit",
                    }}
                  >
                    1.12 Some gyms attract hardcore bodybuilders, some gyms attract members that may not be heavily invested in real change, and some gyms attract “true” fitness communities. What kind
                    of members mostly your gym attracts or has?
                  </FormLabel>
                  <div
                    style={{
                      display: "flex",
                      color: fieldErrors.memberGymAttract ? "red" : "inherit",
                    }}
                  >
                    <select
                      value={memberGymAttract}
                      style={{ borderRadius: "5px" }}
                      onChange={(e) => {
                        handleMemberGymAttract(e);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Hardcore bodybuilders">Hardcore bodybuilders</option>
                      <option value="Not heavily invested in read change">Not heavily invested in read change</option>
                      <option value="True fitness communities">True fitness communities</option>
                      <option value="Mixture of all three ">Mixture of all three </option>
                    </select>
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={onBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep1}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 1 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>2. About Equipment </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel style={{ color: fieldErrors2.kindOfEquipment ? "red" : "inherit" }}>
                    2.1 What kind of equipment are available in the gym? List typical equipment available for fitness and strength training. How old are the equipment?
                  </FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name of the equipment</TableCell>
                      <TableCell>Type of Exercise/fitness</TableCell>
                      <TableCell>How old?</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input type="text" value={row.equipment} onChange={(e) => handleInputChange(e, index, "equipment")} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.type} onChange={(e) => handleInputChange(e, index, "type")} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.equipmentOld} onChange={(e) => handleInputChange(e, index, "equipmentOld")} className="form-control" />
                        </TableCell>
                        <TableCell>
                          {/* Only show the Add and Delete buttons for the last row */}
                          {index === rows.length - 1 && (
                            <>
                              <Button variant="contained" color="primary" onClick={handleAddRow} disabled={isAddButtonDisabled}>
                                Add
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              {rows.length > 1 && (
                                <Button variant="contained" color="secondary" onClick={() => handleDeleteRow(index)}>
                                  Delete
                                </Button>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <br></br>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.machineInstruction ? "red" : "inherit",
                    }}
                  >
                    2.2 Are there easy to understand instruction/pictures available or posted on each machine explaining its usage and operation?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={machineInstruction}
                    onChange={(e) => {
                      handleMachineInstruction(e);
                    }}
                    style={{ width: "85%" }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.timeLimitOnCardio ? "red" : "inherit",
                    }}
                  >
                    2.3 Is there is a sign-up sheet or a time limit on cardio equipment?
                  </FormLabel>
                  <FormGroup>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={timeLimitOnCardio} name="radio-buttons-group" onChange={handleTimeLimitCardio}>
                      <FormControlLabel value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio />} label="No" />
                    </RadioGroup>
                    {timeLimitOnCardio && (
                      <>
                        <FormLabel
                          style={{
                            color: fieldErrors2.explainCardioTimeLimit ? "red" : "inherit",
                          }}
                        >
                          Please explain.
                        </FormLabel>
                        <input
                          type="text"
                          className="form-control"
                          value={explainCardioTimeLimit}
                          onChange={(e) => {
                            handleExplaincardioTimeLimit(e);
                          }}
                        ></input>
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <label
                  style={{
                    color: fieldErrors2.otherFacility ? "red" : "inherit",
                  }}
                >
                  2.4 Please describe all other facilities available to members such as changing rooms, personal lockers (if any), Sauna, etc.
                </label>
                <textarea
                  type="text"
                  value={otherFacility}
                  onChange={(e) => {
                    handleOtherFacility(e);
                  }}
                  className="form-control"
                ></textarea>
              </Grid>
            </Grid>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep2}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 2 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>3. Membership, Training and Testing </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.introductoryClinic ? "red" : "inherit",
                    }}
                  >
                    3.1 Is there any introductory clinic to help members get started?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={introductoryClinic} name="radio-buttons-group" onChange={handleIntroductoryClinic}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.separateTraning ? "red" : "inherit",
                    }}
                  >
                    3.2 Is separate training provided on complex equipment for proper usage?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={separateTraning} name="radio-buttons-group" onChange={handleSeparateTraining}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.initialFitnessOrientation ? "red" : "inherit",
                    }}
                  >
                    3.3 Is an initial fitness orientation, screening or body assessment provided to each member right after joining?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={initialFitnessOrientation} name="radio-buttons-group" onChange={handleInitialFitnessOrientation}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.areaDedicated ? "red" : "inherit",
                    }}
                  >
                    3.4 Are there areas dedicated to stretching or turf areas for training?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={areaDedicated} name="radio-buttons-group" onChange={handleAreaDedicated}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.gymOfferTesting ? "red" : "inherit",
                    }}
                  >
                    3.5 Does the gym offer body fat testing, cardiovascular testing, and strength and flexibility testing
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={gymOfferTesting} name="radio-buttons-group" onChange={handleGymOfferTesting}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.freeTrailMembership ? "red" : "inherit",
                    }}
                  >
                    3.6 Is there any free trial membership available to check out the gym ?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={freeTrailMembership} name="radio-buttons-group" onChange={handleFreeTrailMembership}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {freeTrailMembership == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors3.freeTrailMembershipExplain ? "red" : "inherit",
                        }}
                      >
                        How many days or hours? What kind of membership plans are available? What is included, please briefly mention.{" "}
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={freeTrailMembershipExplain}
                        onChange={(e) => {
                          handleFreeTrailMembershipExplain(e);
                        }}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.getTour ? "red" : "inherit",
                    }}
                  >
                    3.7 Can I get a Tour of the gym before signing up for the membership?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={getTour} name="radio-buttons-group" onChange={handleGetTour}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {getTour == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors3.getTourTime ? "red" : "inherit",
                        }}
                      >
                        When can I come for a quick tour?
                      </FormLabel>
                      <input
                        type="time"
                        className="form-control"
                        value={getTourTime}
                        onChange={(e) => {
                          handleGetTourTime(e);
                        }}
                      ></input>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.refundMembership ? "red" : "inherit",
                    }}
                  >
                    3.8 Can I cancel and get refund of membership fees, if for some reason, after few days of training, I do not want to continue with my membership anymore?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={refundMembership} name="radio-buttons-group" onChange={handleRefundMembership}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.differentMembersipPlan ? "red" : "inherit",
                    }}
                  >
                    3.9 Is there only one Basic Membership plan or different levels of membership offered by the gym that offers different services as part of the membership level?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={differentMembersipPlan} name="radio-buttons-group" onChange={handleDifferentMembershipPlan}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {differentMembersipPlan && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors3.explainDifferentMembershipPlan ? "red" : "inherit",
                        }}
                      >
                        {" "}
                        What are the various membership plans, charges and services available?
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={explainDifferentMembershipPlan}
                        onChange={(e) => {
                          handleExplainDifferentMembershipPlan(e);
                        }}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep3}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 3 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>4. About Trainers </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.trainersQualified ? "red" : "inherit",
                    }}
                  >
                    4.1 Are the Trainers Qualified?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={trainersQualified} name="radio-buttons-group" onChange={handleTrainerQualified}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.areMaleTrainers ? "red" : "inherit",
                    }}
                  >
                    4.2 Are there Male trainers?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={areMaleTrainers} name="radio-buttons-group" onChange={handleAreMaleTrainers}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {areMaleTrainers == true && (
              <>
                <Grid container spacing={2} mb={5}>
                  <Grid item md={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.maleTrainerExpirence ? "red" : "inherit",
                      }}
                    >
                      4.2.1 What are the qualification and experience level of all Male trainers in the gym?
                    </FormLabel>
                    <textarea
                      type="text"
                      className="form-control"
                      value={maleTrainerExpirence}
                      onChange={(e) => {
                        handleMaleTrainerExpirence(e);
                      }}
                    ></textarea>
                  </Grid>
                  <Grid item md={6}>
                    <FormLabel>4.2.2 What days and time Male trainers are available?</FormLabel>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label style={{ color: fieldErrors4.maleWeekdaysFrom || fieldErrors4.maleWeekdaysTo ? "red" : "inherit" }}>Weekdays (Monday to Friday):</label>
                      <div>
                        From : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={maleWeekdaysFrom} onChange={(e) => handleMaleWeekdaysFrom(e)} />
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={maleWeekdaysTo} onChange={(e) => handleMaleWeekdaysTo(e)} />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label style={{ color: fieldErrors4.maleWeekendsFrom || fieldErrors4.maleWeekendsTo ? "red" : "inherit" }}>Weekends (Saturday and Sunday):</label>
                      <div>
                        From :&nbsp;
                        <input type="time" style={{ width: "30%" }} value={maleWeekendsFrom} onChange={(e) => handleMaleWeekendsFrom(e)} /> &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={maleWeekendsTo} onChange={(e) => handleMaleWeekendsTo(e)} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.areFemaleTrainer ? "red" : "inherit",
                    }}
                  >
                    4.3 Are Female trainers available?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={areFemaleTrainer} name="radio-buttons-group" onChange={handleAreFemaleTrainer}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {areFemaleTrainer == true && (
              <>
                <Grid container spacing={2} mb={5}>
                  <Grid item md={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.femaleTrainerExpirence ? "red" : "inherit",
                      }}
                    >
                      4.3.1 What are the qualification and experience level of all Female trainers in the gym?
                    </FormLabel>
                    <textarea
                      type="text"
                      className="form-control"
                      value={femaleTrainerExpirence}
                      onChange={(e) => {
                        handleFemaleTrainerExpirence(e);
                      }}
                    ></textarea>
                  </Grid>
                  <Grid item md={6}>
                    <FormLabel>4.3.2 What days and time Female trainers are available?</FormLabel>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label style={{ color: fieldErrors4.femaleWeekdaysFrom || fieldErrors4.femaleWeekdaysTo ? "red" : "inherit" }}>Weekdays (Monday to Friday):</label>
                      <div>
                        From : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={femaleWeekdaysFrom} onChange={(e) => handleFemaleWeekdaysFrom(e)} />
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={femaleWeekdaysTo} onChange={(e) => handleFemaleWeekdaysTo(e)} />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label style={{ color: fieldErrors4.femaleWeekendsFrom || fieldErrors4.femaleWeekendsTo ? "red" : "inherit" }}>Weekends (Saturday and Sunday):</label>
                      <div>
                        From :&nbsp;
                        <input type="time" style={{ width: "30%" }} value={femaleWeekendsFrom} onChange={(e) => handleFemaleWeekendsFrom(e)} /> &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={femaleWeekendsTo} onChange={(e) => handleFemaleWeekendsTo(e)} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.anyManager ? "red" : "inherit",
                    }}
                  >
                    4.4 Is there any Manager/Lead Trainer who manages the entire operations of the gym?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={anyManager} name="radio-buttons-group" onChange={handleAnyManager}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl>
                  {anyManager == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerName ? "red" : "inherit",
                        }}
                      >
                        4.4.1 Name of the Manager/Lead/Trainer of the gym
                      </FormLabel>
                      <input
                        type="text"
                        value={managerName}
                        onChange={(e) => {
                          handleManagerName(e);
                        }}
                      ></input>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerQualification ? "red" : "inherit",
                        }}
                      >
                        4.4.2 Enter Qualification
                      </FormLabel>
                      <textarea
                        type="text"
                        value={managerQualification}
                        onChange={(e) => {
                          handleManagerQualification(e);
                        }}
                      ></textarea>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerExpirence ? "red" : "inherit",
                        }}
                      >
                        4.4.3 Enter Expirence
                      </FormLabel>
                      <textarea
                        type="text"
                        value={managerExpirence}
                        onChange={(e) => {
                          handleManagerExpirence(e);
                        }}
                      ></textarea>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerAward ? "red" : "inherit",
                        }}
                      >
                        4.4.4 Won any award or other recognitions
                      </FormLabel>
                      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={managerAward} name="radio-buttons-group" onChange={handleManagerAward}>
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                    </>
                  )}
                  {managerAward == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors4.explainManagerAward ? "red" : "inherit",
                        }}
                      >
                        4.4.4.1 Mention award/recognitions
                      </FormLabel>
                      <textarea
                        type="text"
                        value={explainManagerAward}
                        onChange={(e) => {
                          handleExplainManagerAward(e);
                        }}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep4}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 4 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>5. Cleanliness Standards </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.gymMaintainCleanliness ? "red" : "inherit",
                    }}
                  >
                    5.1 Does the gym maintain good sanitation and cleanliness standard?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={gymMaintainCleanliness} name="radio-buttons-group" onChange={handleGymMaintainCleanliness}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {gymMaintainCleanliness == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors5.explainCleanliness ? "red" : "inherit",
                        }}
                      >
                        How is the cleaning schedule like?
                      </FormLabel>
                      <input
                        type="text"
                        style={{ width: "50%" }}
                        className="form-control"
                        value={explainCleanliness}
                        onChange={(e) => {
                          handleExplainCleanliness(e);
                        }}
                      ></input>
                    </>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.cleaningStationPlaced ? "red" : "inherit",
                    }}
                  >
                    5.2 Are sanitation and cleaning stations placed around the GYM and well-stocked.
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={cleaningStationPlaced} name="radio-buttons-group" onChange={handleCleaningStationPlaced}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.hygieneStandard ? "red" : "inherit",
                    }}
                  >
                    5.3 How do staff members enforce standard of hygiene?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={hygieneStandard}
                    onChange={(e) => {
                      handleHygieneStandard(e);
                    }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.properlyMaintained ? "red" : "inherit",
                    }}
                  >
                    5.4 Are locker rooms, showers, toilets, sinks, kept clean and properly maintained?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={properlyMaintained} name="radio-buttons-group" onChange={handleProperlyMaintained}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {properlyMaintained == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors5.explainProperlyMaintained ? "red" : "inherit",
                        }}
                      >
                        How often they are cleaned throughout the day?
                      </FormLabel>
                      <textarea
                        type="text"
                        style={{ width: "50%" }}
                        className="form-control"
                        value={explainProperlyMaintained}
                        onChange={(e) => {
                          handleExplainProperlyMaintained(e);
                        }}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep5}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 5 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>6. AMENITIES </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors6.gymOfferAmenities ? "red" : "inherit",
                    }}
                  >
                    6.1 Does the Gym offer any other amenities?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={gymOfferAmenities} name="radio-buttons-group" onChange={handleGymOfferAmenities}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {gymOfferAmenities == true && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label"> 6.2 Does it offer ?</FormLabel>
                      <Row>
                        <Col lg={10}></Col>
                        <Col lg={2}>
                          <div
                            style={{
                              display: "flex",
                              gap: "65px",
                              marginTop: "-35px",
                            }}
                          >
                            <div style={{ fontWeight: "bold" }}>Yes</div>
                            <div style={{ fontWeight: "bold" }}>No</div>
                          </div>
                        </Col>
                      </Row>
                      <FormGroup>
                        <FormControl style={{ width: "100%" }}>
                          {/* Question 1 */}
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.kidsArea ? "red" : "inherit",
                                }}
                              >
                                1. A kid's area
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="multitask"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                onChange={handleKidsAres}
                                value={kidsArea}
                              >
                                <FormControlLabel value={"true"} control={<Radio />} />
                                <FormControlLabel value={"false"} control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.onlyLadiesWorkoutSpace ? "red" : "inherit",
                                }}
                              >
                                2. Ladies-only workout space
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="exhausted"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={onlyLadiesWorkoutSpace}
                                onChange={handleOnlyLadiesWorkoutSpace}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.sauna ? "red" : "inherit",
                                }}
                              >
                                3. A sauna
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="schedule"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={sauna}
                                onChange={handleSauna}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.functionalTrainingArea ? "red" : "inherit",
                                }}
                              >
                                4. A functional training area
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="focus"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={functionalTrainingArea}
                                onChange={handleFunctionalTrainingArea}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.personalTrainer ? "red" : "inherit",
                                }}
                              >
                                5. Personal trainers
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="timeOff"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={personalTrainer}
                                onChange={handlePersonalTrainer}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.wifi ? "red" : "inherit",
                                }}
                              >
                                6. WiFi
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="imbalance"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={wifi}
                                onChange={handleWifi}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.proShop ? "red" : "inherit",
                                }}
                              >
                                7. A pro shop
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="guilty"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={proShop}
                                onChange={handleProShop}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                8. Other amenities
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <input
                                type="text"
                                style={{ width: "8rem", borderBottom: "1px solid #000" }}
                                className="form-control"
                                value={otherAmenities}
                                onChange={(e) => {
                                  handleOtherAmenities(e);
                                }}
                              ></input>
                            </Col>
                          </Row>
                        </FormControl>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep6}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 6 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>7. CLASSES AND PROGRAMS </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors7.variousTrainingProgram ? "red" : "inherit",
                    }}
                  >
                    7.1 What various classes and training programs offered at the gym?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={variousTrainingProgram}
                    onChange={(e) => {
                      handleVariousTrainingProgram(e);
                    }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors7.groupFitnessClasses ? "red" : "inherit",
                    }}
                  >
                    7.2 Are group fitness classes offered at the gym?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={groupFitnessClasses} name="radio-buttons-group" onChange={handleGroupFitnessClasses}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors7.extraCharge ? "red" : "inherit",
                    }}
                  >
                    7.3 Does the gym charges extra for Group, studio classes, or training programs?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={extraCharge} name="radio-buttons-group" onChange={handleExtraCharge}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.programList ? "red" : "inherit",
                    }}
                  >
                    7.4 Can I get a list of various classes and programs and their duration, time, charges, trainer name, etc.?
                  </FormLabel>
                  <div style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}>
                    <label htmlFor="programList" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline" }}>
                      Attach Program List
                    </label>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      id="programList"
                      className="file-upload-input"
                      name="programList"
                      style={{ display: "none" }}
                      value={programList}
                      onChange={handleProgramList}
                    />
                    {/* {license.documentLink && (
                    <div>
                      <p>{license.documentLink}</p>
                    </div>
                  )} */}
                  </div>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep7}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 7 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>8. COST AND FEES </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors8.promotionalCurrentPlan ? "red" : "inherit",
                    }}
                  >
                    8.1 Is the gym running any promotional plan currently?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={promotionalCurrentPlan} name="radio-buttons-group" onChange={handlePromotionalCurrentPlan}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors8.paymentPlan ? "red" : "inherit",
                    }}
                  >
                    8.2 Is there a payment plan available to pay the membership fees?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={paymentPlan} name="radio-buttons-group" onChange={handlePaymentPlan}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <h6>* START TODAY ON YOUR “FITNESS AND LIFESTYLE DISEASE PREVENTION” PLAN WITH YOUR 7 DAY FREE PASS FROM LIVING SCOPE HEALTH. </h6>
            <br></br>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={GymFromSubmit}>
                  Submit
                </button>
              </div>
            </Grid>
          </>
        )}
      </form>
    </div>
  );
};

export default GymGeneralInfo;
