import React from "react";

export const Delete = ({ style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M19.25 5.48177C16.1975 5.17927 13.1267 5.02344 10.065 5.02344C8.25 5.02344 6.435 5.1151 4.62 5.29844L2.75 5.48177"
        stroke="#E65A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79199 4.55551L7.99366 3.35467C8.14033 2.48384 8.25033 1.83301 9.79949 1.83301H12.2012C13.7503 1.83301 13.8695 2.52051 14.007 3.36384L14.2087 4.55551"
        stroke="#E65A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.279 8.37793L16.6832 17.6088C16.5824 19.0479 16.4999 20.1663 13.9424 20.1663H8.05737C5.49987 20.1663 5.41737 19.0479 5.31654 17.6088L4.7207 8.37793"
        stroke="#E65A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.46875 15.125H12.5212" stroke="#E65A5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.70801 11.458H13.2913" stroke="#E65A5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
