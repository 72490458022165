// import React from "react";
// import ReactDOMClient from "react-dom/client";
// import { App } from "./App";

// const app = document.getElementById("app");
// const root = ReactDOMClient.createRoot(app);
// root.render(<App />);

import React from "react";
import ReactDOMClient from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
