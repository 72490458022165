import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import InputField from "../InputField";
import AddAllergies from "./AddAllergies";
import AddSurgicalHistory from "./AddSurgicalHistory";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import Swal from "sweetalert2";

const SilverStep1New = ({ onBack }) => {
  const [formData, setFormData] = useState({
    presentHealthConcerns: "",
    allergiesFlag: false,
    allergies: "",
    personalMedicalHistory: "",
    surgicalFlag: false,
    surgicalHistory: "",
    familyHistory: "",
    familyDiseasesKnowledgeFlag: false,
  });
  const [formStep, setFormStep] = useState(0);
  const [familyData, setFamilyData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const validationSchemaStep1 = Yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(validationSchemaStep1),
  });

  const { handleSubmit, control, formState, getValues, watch, setValue, reset, trigger } = methods;

  const checkBoxes = [
    "Congenital Heart Disease",
    "Lung Cancer",
    "Cervical Cancer",
    "Prostrate Cancer",
    "Colorectal Cancer",
    "Brest Cancer",
    "Hepatitis A, B, or C",
    "Myocardial Infarction (Heart Attack)",
    "Stroke",
    "Hypertension (High Blood Pressure)",
    "Hypotension (Low Blood Pressure)",
    "Coagulation (Bleeding/Clotting)",
    "Diabetes",
    "High Cholesterol",
    "Alcoholism",
    "Depression/Suicide Attempt",
    "Rheumatoid arthritis",
  ];

  const FamilyNames = ["Mother", "Father", "Brother", "Sister", "Son", "Daughter", "Maternal Grandmother", "Maternal GrandFather", "Paternal Grandmother", "Paternal GrandFather"];
  const diseaseOptions = ["Asthma", "Diabetes", "Hypertension", "Cardiac", "Stroke", "Lungs/COPD", "Cancer", "Colon Polyps", "Kidney Disease", "Depression"];
  const historyColumn = ["Living Status", "At What Age ?", "Asthma", "Diabetes", "High/Low BP", "Cardiac", "Stroke", "Lungs/COPD", "Cancer", "Colon Polyps", "Kidney Disease", "Depression"];

  const formNextStepHandler = async (e) => {
    e.preventDefault();
    if (formData.allergiesFlag == null || formData.surgicalFlag == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
       const changedFormData = {
         ...formData,
         personalMedicalHistory: personalMedicalHistoryString,
         familyHistory: modifiedHistoryFamily,
       };
      // const personalMedicalHistoryString = formData.personalMedicalHistory ? formData.personalMedicalHistory.join(";") : formData.personalMedicalHistory;
      let modifiedHistoryFamily = JSON.stringify(familyData);
       AxiosInstance.post("form/addPersonalFamilyHistory", changedFormData)
         .then((response) => {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Your Form Data is Submitted Successfully!",
              });
           setTimeout(() => {
             onBack();
           }, 1000);
         })
         .catch((error) => {
           console.log(error);
         });
       reset();
       setTimeout(() => {
         onBack();
       }, 1000);
      setFormStep((prevActiveStep) => prevActiveStep + 1);
      // if (relationship == "self") {
      //   setFormStep((prevActiveStep) => prevActiveStep + 1);
      // }
    }
  };

  const formPrevStepHandler = (e) => {
    e.preventDefault();
    setFormStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "familyDiseasesKnowledgeFlag") {
      setFormData((prevState) => ({
        ...prevState,
        familyDiseasesKnowledgeFlag: checked,
      }));
    } else if (type === "checkbox") {
      const updatedMedicalHistory = checked ? [...formData.personalMedicalHistory, value] : formData.personalMedicalHistory.filter((item) => item !== value);
      setFormData((prevState) => ({
        ...prevState,
        personalMedicalHistory: updatedMedicalHistory,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    const boolValue = value === "true";
    setFormData((prevState) => ({
      ...prevState,
      [name]: boolValue,
    }));
  };

  const handleDiseaseChange = (name, disease) => {
    setFamilyData((prevState) => {
      const updatedDiseases = prevState[name]?.diseases || "";

      let newDiseases;
      if (updatedDiseases.includes(disease)) {
        newDiseases = updatedDiseases
          .split(";")
          .filter((item) => item !== disease)
          .join(";");
      } else {
        newDiseases = updatedDiseases ? `${updatedDiseases};${disease}` : disease;
      }
      return {
        ...prevState,
        [name]: {
          ...prevState[name],
          diseases: newDiseases,
        },
      };
    });
  };

  const handleAgeChange = (name, age) => {
    setFamilyData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        age: age,
      },
    }));
  };

  const handleLivingStatusChange = (name, livingStatus) => {
    setFamilyData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        livingStatus: livingStatus,
      },
    }));
  };

  const onSubmit = () => {
    const personalMedicalHistoryString = formData.personalMedicalHistory ? formData.personalMedicalHistory.join(";") : formData.personalMedicalHistory;
    let modifiedHistoryFamily = JSON.stringify(familyData);
    if (Object.keys(familyData).length === 0 && !formData.familyDiseasesKnowledgeFlag) {
      setErrorMessage("Please Confirm That your family does not have any of the above diseases");
      return;
    }
    for (const member in familyData) {
      if (familyData[member].diseases !== "" && formData.familyDiseasesKnowledgeFlag) {
        setErrorMessage("Please Unchecked the checkbox because your family member have disease");
        return;
      }
    }
    let hasDisease = Object.values(familyData).some((member) => member.diseases !== "");
    if (!hasDisease && !formData.familyDiseasesKnowledgeFlag) {
      setErrorMessage("Please Confirm That your family does not have any of the above diseases");
      return;
    }
    const changedFormData = {
      ...formData,
      personalMedicalHistory: personalMedicalHistoryString,
      familyHistory: modifiedHistoryFamily,
    };
    AxiosInstance.post("form/addPersonalFamilyHistory", changedFormData)
      .then((response) => {
        toast.success("Your Form is Submitted Successfully");
        setTimeout(() => {
          onBack();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
    reset();
    setTimeout(() => {
      onBack();
    }, 1000);
  };

  return (
    <div className="profile_section">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="registration_tier1"
        >
          {formStep == 0 && (
            <>
              <Grid
                container
                spacing={4}
                sx={{ marginTop: 0 }}
              >
                <Grid
                  xs={12}
                  item
                  sx={{ marginBottom: "30px !important" }}
                >
                  <div className="register_title">
                    <h2>Registration</h2>
                    <h5>Tier 2</h5>
                  </div>
                </Grid>
                <Grid
                  xs={6}
                  item
                >
                  <InputField
                    name="presentHealthConcerns"
                    label="Present Health Concerns (If Any)"
                    control={control}
                    value={formData.presentHealthConcerns}
                    onChange={handleChange}
                    className={`inputField`}
                  />
                </Grid>
                {/* //  Radio button */}
                <Grid
                  xs={6}
                  item
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      style={{ fontSize: "16px", fontWeight: 500, color: "black" }}
                    >
                      Do you have any allergy ?
                    </FormLabel>
                    <RadioGroup
                      aria-label="options"
                      name="allergiesFlag"
                      value={formData.allergiesFlag}
                      onChange={handleRadioChange}
                      row
                      sx={{ flexWrap: "nowrap" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!!formData.allergiesFlag ? (
                  <Grid
                    xs={12}
                    item
                  >
                    <AddAllergies
                      AllergiesStep={formData.allergies}
                      onUpdateAllergies={setFormData}
                      selectedOption={formData.allergiesFlag}
                    />
                  </Grid>
                ) : null}
                <Grid
                  xs={12}
                  item
                >
                  <div className="health_Service_Providers_Information mt-4">
                    <div className="info_header_wrapper">
                      <div className="info_provider_content">
                        <h3>Personal Medical History</h3>
                        <p>Please indicate whether you have had any of the following medical problems.</p>
                      </div>
                    </div>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {checkBoxes.map((item, index) => (
                        <div
                          key={index}
                          className="history-checkbox"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formData.personalMedicalHistory.includes(item)}
                                id={item}
                                value={item}
                                onChange={handleChange}
                              />
                            }
                            label={item}
                          />
                        </div>
                      ))}
                    </Box>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  item
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      style={{ fontSize: "16px", fontWeight: 500, color: "black" }}
                    >
                      Any surgeries in the past ?
                    </FormLabel>
                    <RadioGroup
                      aria-label="options"
                      name="surgicalFlag"
                      value={formData.surgicalFlag}
                      onChange={handleRadioChange}
                      row
                      sx={{ flexWrap: "nowrap" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  xs={12}
                  item
                >
                  <AddSurgicalHistory
                    Surgeries={formData.surgeries}
                    onUpdateSurgeries={setFormData}
                    selectedOption={formData.surgicalFlag}
                  />
                </Grid>
                <Grid
                  xs={12}
                  item
                  justifyContent="space-between"
                  display={"flex"}
                >
                  <div className="submit-button">
                    <button
                      className="base"
                      onClick={onBack}
                    >
                      Back
                    </button>
                  </div>
                  <div className="submit-button">
                    <button
                      type="button"
                      className="base"
                      onClick={formNextStepHandler}
                    >
                      Next
                    </button>
                  </div>
                  {/* { relationship != "self"
                    <div className="submit-button">
                      <button
                        type="button"
                        className="base"
                        onClick={formNextStepHandler}
                      >
                        Save
                      </button>
                    </div>
                  } */}
                </Grid>
              </Grid>
            </>
          )}
          {formStep == 1 &&
            //relationship != "self"
              (
            <>
              <div className="info_header_wrapper">
                <div className="info_provider_content">
                  <h3>Family History</h3>
                  <p>
                    Please indicate with a check (√) who in your family has had the following conditions. In the first column please indicate their living status. L = Living, D = Deceased, U =
                    Unknown.
                  </p>
                </div>
              </div>
              <div style={{ marginBottom: "20px" }}>
                Note: Please talk to your family members if you are not sure as this information is vital to predict any potential lifestyle diseases you may have
              </div>
              <TableContainer
                component={Paper}
                style={{ maxHeight: 450, marginBottom: "30px" }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#E5E5EB" }}>
                      <TableCell></TableCell>
                      {historyColumn.map((columName) => {
                        return <TableCell style={{ color: "#757575", width: "50px", whiteSpace: "nowrap" }}>{columName}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {FamilyNames.map((familyMemberName, index) => {
                      const familyMemberData = familyData[familyMemberName];
                      const livingStatus = familyMemberData?.livingStatus || "";
                      const age = familyMemberData?.age || "";
                      const diseases = familyMemberData?.diseases || "";

                      return (
                        <>
                          <TableRow key={index}>
                            <TableCell>{familyMemberName}</TableCell>
                            <TableCell>
                              <select
                                value={livingStatus}
                                onChange={(e) => handleLivingStatusChange(familyMemberName, e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="L">Living</option>
                                <option value="D">Deceased</option>
                                <option value="U">Unknown</option>
                              </select>
                            </TableCell>
                            <TableCell>
                              <div style={{ display: "flex" }}>
                                <input
                                  style={{
                                    width: "50px",
                                    textIndent: "10px",
                                    marginRight: "5px",
                                  }}
                                  value={age}
                                  onChange={(e) => handleAgeChange(familyMemberName, e.target.value)}
                                ></input>
                                <span>yrs</span>
                              </div>
                            </TableCell>
                            {diseaseOptions.map((disease, diseaseIndex) => (
                              <>
                                <TableCell key={diseaseIndex}>
                                  <Checkbox
                                    checked={diseases.includes(disease)}
                                    onChange={() => handleDiseaseChange(familyMemberName, disease)}
                                  />
                                </TableCell>
                              </>
                            ))}
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item>
                <div>
                  <span style={{ color: "black", fontWeight: "500" }}>Note:</span>
                  If you are sure that none of your family members ever had any of the above disease then please confirm below
                </div>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={formData.familyDiseasesKnowledgeFlag} />}
                  label="I confirm that no one in my family ever had any of the above diseases to the best of my knowledge"
                  value={formData.familyDiseasesKnowledgeFlag}
                  name="familyDiseasesKnowledgeFlag"
                  onChange={handleChange}
                />
                {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
              </Grid>
              <Grid
                xs={12}
                item
                justifyContent="space-between"
                display={"flex"}
              >
                <div className="submit-button">
                  <button
                    className="base"
                    onClick={formPrevStepHandler}
                  >
                    Back
                  </button>
                </div>
                <div className="submit-button">
                  <button
                    type="submit"
                    className="base"
                  >
                    <CircularProgress
                      size={18}
                      sx={{ color: "white", marginRight: "10px" }}
                    />
                    Submit
                  </button>
                </div>
              </Grid>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default SilverStep1New;
