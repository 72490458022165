import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState, useRef } from "react";
import { Fade, Roll, Zoom } from "react-reveal";
import { Link, useNavigate } from "react-router-dom";
import healthPoster from "../../img/health-poster.jpg";
import "./style.css";
import Button from "@mui/material/Button";
import AppLogoWithName from "../../../static/img/AppLogoWithName.png";
import mobileImage from "../../img/iphone-image.png";
import AppStoreImg from "../../img/App_Store.png";
import GooglePlayStore from "../../img/Google_Play_Store.png";

export const Landing = () => {
  const navigate = useNavigate();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const videoRefs = [useRef(null), useRef(null)];
  const videoSources = ["../img/AnimationScreen1.mp4", "https://uncurlhealth-videos.s3.amazonaws.com/celebrity_video.mp4"];
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleVideoEnd = () => {
    if (currentVideoIndex < videoSources.length - 1) {
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
      setShowSkipButton(false);
    } else {
      navigate("/homeBeforeLogin");
    }
  };

  const interval = setInterval(() => {
    const video = videoRefs[currentVideoIndex].current;
    if (video && !video.paused && video.currentTime >= 10) {
      setShowSkipButton(true);

      clearInterval(interval);
    }
  }, 1000);

  useEffect(() => {
    const video = videoRefs[currentVideoIndex].current;
    if (screenSize.width > 800) {
      video.currentTime = 0;
    }

    return () => {
      clearInterval(interval);
    };
  }, [currentVideoIndex]);

  const handleSkip = () => {
    const video = videoRefs[currentVideoIndex].current;
    if (video) {
      video.currentTime = video.duration;
      video.pause();
      setShowSkipButton(false);
      handleVideoEnd();
    }
  };

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      if (screenSize.width > 800) {
        const video = videoRefs[currentVideoIndex].current;
        clearInterval(interval);
        video.currentTime = 0;
      }

      setIsVisible(false);
    }, 6000);

    return () => clearTimeout(fadeOutTimer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="landing">
      {screenSize.width < 800 ? (
        <div className="mobile-view-section">
          <div className="brand-logo">
            <img src={AppLogoWithName} />
          </div>
          <div className="mobile-view-details">
            <div className="mobile-view-col app-area-wrapper">
              <div className="app-title-subtitle">
                <h4>for a better experience</h4>
              </div>
              <div className="download-app-title">
                <h4>download</h4>
                <h5>our app</h5>
              </div>
              <div className="download-app-images">
                <Link to="https://apps.apple.com/us/app/uncurl-health/id6467471257">
                  <img src={AppStoreImg} />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.livingscopehealth.uncurl">
                  <img src={GooglePlayStore} />
                </Link>
              </div>
            </div>
            <div className="mobile-view-col mobile-app-image">
              <img src={mobileImage} />
            </div>
          </div>
        </div>
      ) : (
        <div className="overlap-22">
          <Container fluid>
            <div className="overlap-23">
              <Grid container>
                <div className="health-brand-logo">
                  <img
                    className="group-39"
                    alt="Group"
                    src="/img/AppLogoWithName.png"
                  />
                </div>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                >
                  <Fade
                    when={isVisible}
                    duration={1000}
                    collapse
                  >
                    <div className="health-landing-left">
                      <div className="landing-content">
                        <Zoom
                          bottom
                          cascade
                          delay={1000}
                        >
                          <h1 className={`main-heading mb-3`}>What's On Your Mind?</h1>
                        </Zoom>
                        <Roll
                          left
                          delay={2000}
                        >
                          <span className="text-wrapper-53">Health?</span>
                        </Roll>
                        <Zoom delay={3000}>
                          <p className="text-wrapper-52">Learn how we are transforming ‘Health’! </p>
                        </Zoom>
                        <Zoom delay={4000}>
                          <p className="text-wrapper-54">
                            Listen to our <span className="brand-story">Brand Story</span>
                          </p>
                        </Zoom>
                      </div>
                    </div>
                  </Fade>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <div
                    className="group-wrapper"
                    style={{ display: isVisible ? "none" : "block" }}
                  >
                    <Zoom delay={2000}>
                      <div className="video-wrapper">
                        {videoSources.map((videoSource, index) => (
                          <video
                            key={index}
                            ref={videoRefs[index]}
                            controls
                            className="health-video"
                            autoPlay
                            id="autoPlay"
                            poster={healthPoster}
                            muted={true}
                            style={{ display: currentVideoIndex === index ? "block" : "none" }}
                          >
                            <source
                              src={videoSource}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ))}
                        <span className="unmute-tool">Please unmute your speaker 🔇</span>
                      </div>
                    </Zoom>
                    {showSkipButton && (
                      <div style={{ textAlign: "center", marginTop: "10px", clear: "both" }}>
                        <Button
                          variant="contained"
                          onClick={handleSkip}
                          sx={{
                            ":hover": {
                              bgcolor: "#5353e9",
                              color: "#fff",
                            },
                            borderColor: "#5353e9",
                            color: "#fff",
                            bgcolor: "#5353e9",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "14px",
                            margin: "0 auto",
                            textTransform: "capitalize",
                          }}
                        >
                          Skip Video
                        </Button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};
