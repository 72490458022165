import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Modal,
  IconButton,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Spinner from "../../shared/Spinner";
import CloseIcon from "@mui/icons-material/Close";

// Sample data

const CorporateWallet = () => {
  const [subscribedEmployees, setsubscribedEmployees] = useState([]);
  const [loadingSubscribed, setloadingSubscribed] = useState(false);
  const [leftEmployees, setleftEmployees] = useState([]);
  const [loadingLeft, setloadingLeft] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleOpenModal = (employeesList) => {
    setSelectedEmployees(employeesList);
    setOpen(true);
  };

  const handleCloseModal = () => setOpen(false);

  const SubscribedEmployees = async () => {
    await AxiosInstance.get("corporate/get-subscribed-employees")
      .then((response) => {
        if (response != null) {
          setsubscribedEmployees(response);
        }
        setloadingSubscribed(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setloadingSubscribed(false);
      });
  };
  const LeftEmployees = async () => {
    await AxiosInstance.get("corporate/get-left-employees")
      .then((response) => {
        if (response != null) {
          setleftEmployees(response);
        }
        setloadingLeft(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setloadingLeft(false);
      });
  };
  useEffect(() => {
    setloadingSubscribed(true);
    setloadingLeft(true);
    SubscribedEmployees();
    LeftEmployees();
  }, []);

  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };
  function formatInvoiceDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "short", year: "2-digit" };

    // Format the date to "Nov'24" or "Jan'25"
    return date.toLocaleDateString("en-US", options).replace(" ", "'");
  }
  console.log("subscribed", subscribedEmployees);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            backgroundColor: "#e0f7fa",
            color: "#00796b",
            padding: "8px 8px",
            borderRadius: 1,
            fontWeight: "bold",
            boxShadow: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.1rem",
            border: "5px solid teal",
          }}
        >
          Credit in Wallet: {leftEmployees?.creditAmount}
        </span>
      </Box>
      <Grid container spacing={2}>
        {/* Employees Subscribed Table */}
        <Grid item md={6}>
          <TableContainer
            component={Paper}
            sx={{ bgcolor: "#ffebcc", height: "100%" }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ bgcolor: "#ff8c00", color: "#fff", padding: 1 }}
            >
              EMPLOYEES SUBSCRIBED
            </Typography>
            <Table size="small" sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "10px" }}>Date/Month</TableCell>
                  <TableCell sx={{ padding: "10px" }}>
                    No. of Employees Subscribed
                  </TableCell>
                  <TableCell sx={{ padding: "10px" }}>Amount Paid</TableCell>
                  <TableCell sx={{ padding: "10px" }}>Payment Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingSubscribed ? (
                  <TableRow>
                    <TableCell style={{ marginTop: "10px" }} colSpan={4}>
                      <Spinner></Spinner>
                    </TableCell>
                  </TableRow>
                ) : subscribedEmployees.length > 0 ? (
                  subscribedEmployees.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.invoiceDate
                          ? formatInvoiceDate(row.invoiceDate)
                          : ""}
                      </TableCell>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.numberOfEmployees}
                      </TableCell>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.amountPaid}
                      </TableCell>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.paymentDate
                          ? formatDateToDDMMYYYY(row.paymentDate)
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{
                        textAlign: "center",
                        fontSize: "large",
                        fontWeight: 500,
                        color: "#c60176",
                        margin: "50px",
                      }}
                    >
                      No Data Availabe
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Employees Left Table */}
        <Grid item md={6}>
          <TableContainer
            component={Paper}
            sx={{ bgcolor: "#e6f2ff", height: "100%" }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ bgcolor: "#0073e6", color: "#fff", padding: 1 }}
            >
              EMPLOYEES LEFT THE ORGANIZATION
            </Typography>

            <Table size="small" sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "10px" }}>Month</TableCell>
                  <TableCell sx={{ padding: "10px" }}>
                    No. of Employees Left
                  </TableCell>
                  <TableCell sx={{ padding: "10px" }}>Unused Amount</TableCell>
                  <TableCell sx={{ padding: "10px" }}>Cum Amount</TableCell>
                  <TableCell sx={{ padding: "10px" }}>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingLeft ? (
                  <TableRow>
                    <TableCell style={{ marginTop: "10px" }} colSpan={5}>
                      <Spinner></Spinner>
                    </TableCell>
                  </TableRow>
                ) : leftEmployees?.monthlyLeftCount?.length > 0 ? (
                  leftEmployees?.monthlyLeftCount?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.date ? formatInvoiceDate(row.date) : ""}
                      </TableCell>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.numberOfEmployees}
                      </TableCell>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.unusedAmount}
                      </TableCell>
                      <TableCell sx={{ padding: "10px" }}>
                        {row.cumAmount}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          color: "#0073e6",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenModal(row.employeesList)}
                      >
                        See Full List
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      style={{
                        textAlign: "center",
                        fontSize: "large",
                        fontWeight: 500,
                        color: "#c60176",
                        margin: "50px",
                      }}
                    >
                      No Data Availabe
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="employee-details-modal"
          >
            <Box
              sx={{
                width: "90%",
                bgcolor: "white",
                p: 3,
                borderRadius: 2,
                m: "auto",
                mt: 5,
                boxShadow: 24,
                position: "relative",
              }}
            >
              <IconButton
                onClick={handleCloseModal}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "#0073e6",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                align="center"
                sx={{ mb: 2, color: "#0073e6" }}
              >
                Employee Details
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" sx={{ whiteSpace: "nowrap" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Employee ID
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        First Name
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Last Name
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Phone Number
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Plan Name
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        City
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Subscription Date
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Unused Amount
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#f0f0f0", fontWeight: "bold" }}
                      >
                        Left Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedEmployees.map((employee, index) => (
                      <TableRow key={index}>
                        <TableCell>{employee.employeeId}</TableCell>
                        <TableCell>{employee.firstName}</TableCell>
                        <TableCell>{employee.lastName}</TableCell>
                        <TableCell>{employee.phoneNumber}</TableCell>
                        <TableCell>{employee.email}</TableCell>
                        <TableCell>{employee.planName}</TableCell>
                        <TableCell>{employee.locationCity}</TableCell>
                        <TableCell>{employee.dateOfSubscription}</TableCell>
                        <TableCell
                          sx={{ color: "orangered", fontWeight: "700" }}
                        >
                          {employee.status}
                        </TableCell>
                        <TableCell>{employee.unusedAmount}</TableCell>
                        <TableCell>{employee.employeeLeftDate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
};

export default CorporateWallet;
