import React, { useState } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Spinner from "../../shared/Spinner";

export const SelfTestReport = () => {
  const [isTestReportLoading, setIsTestReportLoading] = useState(false);
  const [fileName, setFileName] = useState(null);

  const handleTestReportSubmitWitoutPrescription = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file?.name);
    if (!file) {
      toast.error("Please select a file before submitting.");
      return;
    }
    let fileCheck = fileName?.split(".")?.pop();
    if (fileCheck == "gif" || fileCheck == "jfif") {
      toast.error("Gif and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      setIsTestReportLoading(true);
      AxiosInstance.post("test-report/upload", formData)
        .then((response) => {
          console.log("test report response", response);
          toast.success("Document Uploaded Successfully");
          setIsTestReportLoading(false);
          setFileName(null);
        })
        .catch((error) => {
          toast.error("An error occurred while uploading the file.");
          setIsTestReportLoading(false);
        });
    }
  };
  return (
    <>
      <Row>
        <Col>
          <div>
            <p
              className="text-style"
              style={{ fontSize: "18px", marginBottom: "5px" }}
            >
              Please attach the test report for which you do not have Hospital
              visit
            </p>
            <span>
              <label
                htmlFor="testReportfileUpload"
                style={{
                  cursor: "pointer",
                  color: "#5353e9",
                  textDecoration: "underline",
                }}
              >
                Attach Test Report
              </label>
              {isTestReportLoading == true ? (
                <div>
                  <Spinner />
                  <br />
                  <p style={{ textAlign: "center" }}>
                    Please wait your Test Report is uploading
                  </p>
                </div>
              ) : (
                ""
              )}
            </span>
            <div style={{ display: "none" }}>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="testReportfileUpload"
                className="file-upload-input"
                name="file"
                onChange={(event) =>
                  handleTestReportSubmitWitoutPrescription(event)
                }
              />
            </div>
            {/* <button
                    onClick={uploadTestReport}
                    className="universal-btn"
                  >
                    + Attach Test Report
                  </button> */}
          </div>
        </Col>
      </Row>
    </>
  );
};
