import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../LifeStyleRiskAssessment.css";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { NavLink, useLocation } from "react-router-dom";
import NutritionistCard from "./NutritionistCard";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Button, Grid, Typography } from "@mui/material";

const PreventiveCareServices = ({
  PreventiveCareRecommendationNext,
  FullRecommendationNext,
}) => {
  const [preventiveCare, setPreventiveCare] = useState([]);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [nutritionistData, setnutritionistData] = useState([]);
  const [open, setOpen] = useState(false);

  const handleNutritionistSelect = (doctor) => {
    setSelectedDoctor(doctor); // Set the selected doctor data
    setOpen(true);
    setModal(!modal);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const surcharge = 0;
  const taxRate = 0.18;
  const tax = selectedDoctor && parseInt(selectedDoctor.chatCharge * taxRate);
  const total =
    selectedDoctor && parseInt(selectedDoctor.chatCharge + surcharge + tax);

  useEffect(() => {
    AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices")
      .then((response) => {
        if (response != null) {
        setPreventiveCare(response);
        } else {
        setPreventiveCare([]);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };

  const handlePayment = () => {
    const amount = parseInt(total);
    const dietitianId = selectedDoctor.userId;

    AxiosInstance.post(
      `/payment/dietitian-create-order/${amount}/${dietitianId}?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`
    )
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setOpen(!open);
  };

  const handlePreventiveTest = (lrid) => {
    const LRID = lrid;
    AxiosInstance.post(`/test-labs/create-preventive-cart/Lipid%20Profile/${LRID}`)
      .then((response) => {
        console.log("preventive", response);
        setPreventiveCare(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }
    useEffect(() => {
      AxiosInstance.get("profile/get")
        .then((response) => {
          setFamilyInfo(response?.profile);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
     AxiosInstance.get("dietitian/getAllDietitianDetail")
      .then((response) => {
        if (response) {
          setnutritionistData(response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    }, []);

    const bookNutritionistDetail = () => {
      setModal(!modal);
    };


    // const nutritionistData = [
    //   {
    //     userId: 1179,
    //     firstName: "Deepak",
    //     lastName: "Lohar",
    //     practicingAs: "bgb",
    //     educationQualifications: "MBBS",
    //     everWorkedAsClinicalDietitian: "Yes",
    //     bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    //     yearsOfExperience: "4",
    //     amount: 255,
    //     tax: 18
    // },

    // {
    //     userId: 1187,
    //     firstName: "Mayank",
    //     lastName: "xyz",
    //     practicingAs: "Consultant Physician ",
    //     educationQualifications: "MD",
    //     everWorkedAsClinicalDietitian: "No",
    //     bio: "Passed MBBS (Gold Medalist) in 2017, Passed MD medicine in 2022, Working as consultant physician since last 2 years in Manjalpur Hospital and Spandan multispeciality Hospital",
    //     yearsOfExperience: "6",
    //     amount: 325,
    //     tax: 18
    // },
    // ]

  return (
    <>
      {preventiveCare.length > 0 && (
        <div className="">
          {preventiveCare?.map((item, index) => (
            <>
              <table class="table table-bordered" style={{ border: "2px solid black" }}>
                <thead class="table-dark">
                  <tr>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition :</b> {item.medicalCondition}
                      </p>
                    </th>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Date Recommended :</b> {item.dateRecommended}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test :</b> {item.preventiveTest}
                      </p>
                    </th>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID :</b> {item.lrid}
                      </p>
                    </td>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Importance</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}> {item.importance}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Why You?</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.whyYou}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Recommendation</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.recommendation}</p>
                    </th>
                  </tr>
                  {familyInfo?.age >= 3 && (
                    <>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Book for Test - "Lipid Profile"</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      Our lab Partner, "XYZ" provides medical tests and diagnostic in your area and can provide you with home collection services.You get <b>20% - 30% discount </b>when you book for
                      lab tests form out Lab Partners through our app. Click below to book for the test.
                      <NavLink to={"/lab-services?page=testcart"}>
                        <center>
                          <button type="button" className="btn btn-success" onClick={() => handlePreventiveTest(item.lrid)}>
                            Book
                          </button>
                        </center>
                      </NavLink>
                    </th>
                  </tr>
                    </>
                  )}
                </tbody>
              </table>
              <br></br>
            </>
          ))}
          <table class="table table-bordered" style={{ border: "2px solid black" }}>
                <thead class="table-dark">
                  <tr>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition :</b> Dengue
                      </p>
                    </th>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Date Recommended :</b> 8-10-2024
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test :</b> Dengue test
                      </p>
                    </th>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID :</b> DR01
                      </p>
                    </td>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Importance</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>very important</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Why You?</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>cwgrt</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Recommendation</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>xdewrgt</p>
                    </th>
                  </tr>
                  {familyInfo?.age >= 3 && (
                    <>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Book for Test - "Nurtitionist"</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      {/* Our lab Partner, "XYZ" provides medical tests and diagnostic in your area and can provide you with home collection services.You get <b>20% - 30% discount </b>when you book for
                      lab tests form out Lab Partners through our app. Click below to book for the test. */}
                      {/* <NavLink to={"/lab-services?page=testcart"}> */}
                        <center>
                          <button type="button" className="btn btn-success" onClick={() => bookNutritionistDetail()}>
                            Book Nutritionist
                          </button>
                        </center>
                      {/* </NavLink> */}
                    </th>
                  </tr>
                    </>
                  )}
                </tbody>
              </table>

              <div>
                <Modal
                isOpen={modal}
                toggle={toggle}
                centered
                style={{
                  maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
                  minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
                  width: "auto", // Automatically adjusts the width based on content
                }}
                >
                <ModalHeader
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid lightgrey",
                  }}
                  toggle={toggle}
                >
                  <center>Select Nurtitionist/Dietitian to Book</center>
                </ModalHeader>
                <ModalBody
                  style={{
                    padding: "20px",
                    maxHeight: "80vh", // Adjust the height as needed
                    overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
                  }}
                >
                  <NutritionistCard
                    nutritionistData={nutritionistData}
                    onDoctorSelect={handleNutritionistSelect}
                  ></NutritionistCard>
                  {/* <OrderDetailModel /> */}
                </ModalBody>
                </Modal>
              </div>
              <Modal
                isOpen={open}
                toggle={handleClose}
                centered
                modalClassName="custom-modal"
              >
                <ModalHeader
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid lightgrey",
                  }}
                  toggle={handleClose}
                >
                  <Typography variant="h6" component="h2">
                    Complete your Payment
                  </Typography>
                </ModalHeader>
                <ModalBody className="modal-body-centered">
                  <Typography
                    variant="h5"
                    sx={{ color: "purple" }}
                    component="h5"
                  >
                    Uncurl: Health Services
                  </Typography>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="h6">Order Value:</Typography>
                      <Typography variant="body1">Surcharge:</Typography>
                      <Typography variant="body1">Tax (18%):</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="h6">
                        ₹{selectedDoctor && selectedDoctor.chatCharge}
                      </Typography>
                      <Typography variant="body1">₹{surcharge}</Typography>
                      <Typography variant="body1">
                        ₹{tax && tax.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Line above the Total */}
                  <Box
                    sx={{
                      borderTop: "2px solid lightgrey",
                      mt: 2,
                      mb: 2,
                      width: "100%",
                    }}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        ₹{total && total.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Line below the Total */}
                  <Box
                    sx={{
                      borderTop: "2px solid lightgrey",
                      mt: 2,
                      mb: 2,
                      width: "100%",
                    }}
                  />
                  <Typography variant="body1" sx={{ mb: 3 }}>
                    This amount will be charged to your account.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#5353e9",
                      "&:hover": { backgroundColor: "#c60176" },
                    }}
                    onClick={handlePayment} // Add your payment handling function here
                  >
                    Confirm & Pay
                  </Button>
                </ModalBody>
              </Modal>
        </div>


      )}
    </>
  );
};

export default PreventiveCareServices;
