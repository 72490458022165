import React from 'react';
import './NotificationModel'; // Create a CSS file for styling

const NameCircle = ({ name }) => {
  // Extract first letters of first name and last name
  const [firstName, lastName] = name.split(' ');
  const initials = `${firstName[0]}${lastName[0]}`;

  // Define a random background color for the circle
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  return (
    <div className="name-circle" style={{ backgroundColor: randomColor }}>
      {initials}
    </div>
  );
};

export default NameCircle;
