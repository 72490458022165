import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import ToastMessage from "../../shared/ToastMessage";

const PatientReviewForm = ({ selectedReport, onRefresh, setIsReviewFormOpen, isDummyData }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      patientReview: "",
      doctorReviewFlag: false,
      patientReviewFlag: true,
      testReportId: 0,
    },
  });

  useEffect(() => {
    setValue("testReportId", selectedReport?.id || 0);
    setValue("patientReview", selectedReport?.patientReview || "");
  }, [selectedReport]);

  const onSubmit = async (data) => {
    await AxiosInstance.post(`test-report/updateReview`, data)
      .then((res) => {
        toast.success("Review Submitted successfully");
        onRefresh();
        setIsReviewFormOpen(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
    reset();
  };

  return (
    <>
      <ToastMessage />
      <div className="review-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <textarea {...register("patientReview")} />
          <Button
            variant="contained"
            type="submit"
            disabled={isDummyData}
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};

export default PatientReviewForm;
