import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getProfile } from "../../configration/HandleCookies";
import InfoModel from "../../shared/simpleModel/InfoModel";
import AddToCart from "../AddToCart/AddToCart";
import HeaderBeforeLogin from "../HomePageBeforeLogin/HeaderBeforeLogin";
import { SignUpBeforePay } from "../SignUpBeforePay";
import "./SubscriptionPageBeforeLogin.css";
import {
  getPaymentCancelBaseUrl,
  getPaymentSuccessBaseUrl,
} from "../../util/utils";

export const SubscriptionPageBeforeLogin = () => {
  const [isInfoModeOpen, setIsInfoModeOpen] = useState(false);
  const [signUpInfoModeOpen, setsignUpInfoModeOpen] = useState(false);
  const [step, setStep] = useState(0);

  const addToCart = () => {
    setStep((step) => step + 1);
  };

  // const userRegistered = JSON.parse(getProfile());
  const userRegistered = window.sessionStorage.getItem("userDetails");

  useEffect(() => {
    if (userRegistered) {
      setStep(JSON.parse(userRegistered));
    }
  }, []);

  // function getPayemtSuccesBaseUrl() {
  //   const baseUrl = window.location.href;
  //   let arr = baseUrl.split("/");
  //   let newPath = arr[0] + "//" + arr[2] + `/dashboard?isUserSubscribed=${true}`;
  //   return newPath;
  // }

  // function getPayemtCancelBaseUrl() {
  //   const baseUrl = window.location.href;
  //   let arr = baseUrl.split("/");
  //   let newPath = arr[0] + "//" + arr[2] + "/homeBeforeLogin";
  //   return newPath;
  // }

  const createCheckoutSession = async () => {
    await AxiosInstance.get(
      `payment/create-checkout-session/1?successURL=${getPaymentSuccessBaseUrl()}&cancelURL=${getPaymentCancelBaseUrl()}`
    )
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onPayClick = () => {
    if (userRegistered) {
      createCheckoutSession();
    } else {
      setsignUpInfoModeOpen((prev) => !prev);
    }
  };

  const AddToCartPopup = () => {
    setIsInfoModeOpen((prev) => !prev);
  };

  const onSignUpCloseModal = () => {
    setsignUpInfoModeOpen((prev) => !prev);
  };

  return (
    <div className="SubscriptionPageBeforeLogin">
      <HeaderBeforeLogin />
      <div className="div">
        <Container maxWidth="lg">
          {step == 0 && (
            <Row>
              <Col lg={6}>
                <div className="overlap-group">
                  <div className="tier-BRONZE">Tier 1 - BRONZE</div>
                  <div className="store-your-medical">
                    Store your medical records on Cloud for safe and easy access
                    whenever and wherever you need.
                  </div>
                  <div className="rs-per-user">
                    <span className="text-wrapper">₹109</span>
                    <span className="text-wrapper-2"> per user/month </span>
                  </div>
                  <div className="subsgroup-wrapper">
                    <button className="addtocart" onClick={addToCart}>
                      Add to Cart
                    </button>
                  </div>
                  <div>
                    <div className="subsgroup-3">
                      <img
                        className="akar-icons-circle"
                        alt="Akar icons circle"
                        src="/img/akar-icons-circle-check-fill-6.png"
                      />
                      <span className="substext-wrapper-3">
                        Get a quick view of all your hospital visits
                      </span>
                    </div>
                    <div className="subsgroup-3">
                      <img
                        className="akar-icons-circle"
                        alt="Akar icons circle"
                        src="/img/akar-icons-circle-check-fill-6.png"
                      />
                      <span className="substext-wrapper-3">
                        Store and access all your health records
                      </span>
                    </div>
                    <div className="subsgroup-3">
                      <img
                        className="akar-icons-circle"
                        alt="Akar icons circle"
                        src="/img/akar-icons-circle-check-fill-6.png"
                      />
                      <span className="substext-wrapper-3">
                        Store and access all your Lab/ test reports
                      </span>
                    </div>
                    <div className="subsgroup-3">
                      <img
                        className="akar-icons-circle"
                        alt="Akar icons circle"
                        src="/img/akar-icons-circle-check-fill-6.png"
                      />
                      <span className="substext-wrapper-3">
                        {" "}
                        A comprehensive view of each and every hospital visit
                        recorded with us.
                      </span>
                    </div>
                  </div>
                  <p className="start-your-health">
                    Start your Health Journey TODAY
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="overlap-group">
                  <div className="tier-BRONZE">Tier 2 - SILVER</div>
                  <div className="understand-and">
                    Understand and Manage your Health
                  </div>
                  <div className="subsgroup-wrapper">
                    <button className="addtocart" onClick={AddToCartPopup}>
                      Add to Cart
                    </button>
                  </div>
                  <InfoModel
                    open={isInfoModeOpen}
                    setOpen={setIsInfoModeOpen}
                    buttonText="Ok"
                  >
                    <div className="popup-description">
                      <p>
                        Please join BRONZE health service first. You will be
                        promoted to join this advanced health service only after
                        you have received a minimum participation performance
                        score (PPS) in Bronze service, showing your personal
                        commitment to improve your health.
                      </p>
                    </div>
                  </InfoModel>
                  <div style={{ marginTop: "60px" }}>
                    <div className="subsgroup-3">
                      <img
                        className="akar-icons-circle"
                        alt="Akar icons circle"
                        src="/img/akar-icons-circle-check-fill-6.png"
                      />
                      <span className="substext-wrapper-3">
                        All Bronze Level Services included
                      </span>
                    </div>
                    <div className="subsgroup-3">
                      <img
                        className="akar-icons-circle"
                        alt="Akar icons circle"
                        src="/img/akar-icons-circle-check-fill-6.png"
                      />
                      <span className="substext-wrapper-3">
                        Get a quick overview of your health
                      </span>
                    </div>
                    <div className="subsgroup-3">
                      <img
                        className="akar-icons-circle"
                        alt="Akar icons circle"
                        src="/img/akar-icons-circle-check-fill-6.png"
                      />
                      <span className="substext-wrapper-3">
                        Understand your health trends to manage risks
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {step == 1 && (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <AddToCart
                  onPayClick={onPayClick}
                  userDetails={userRegistered}
                ></AddToCart>
              </div>

              <InfoModel
                open={signUpInfoModeOpen}
                setOpen={setsignUpInfoModeOpen}
                heading="Please Sign Up First"
              >
                <SignUpBeforePay
                  onSignUpCloseModal={onSignUpCloseModal}
                ></SignUpBeforePay>
              </InfoModel>
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

// export const SubscriptionPageBeforeLogin;
