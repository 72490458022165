import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Col, Row } from "reactstrap";
import { Button } from "@mui/material";
import "./ChangePasswordModal.css";
import ToastMessage from "../../shared/ToastMessage";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const ChangePasswordModal = ({ onChangePasswordModelClose }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");

  const handleToggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword" && value.trim() && error1) {
      setError1("");
    }
    if (name === "password" && value.trim() && error2) {
      setError2("");
    }
    if (name === "confirmPassword" && value.trim() && error3) {
      setError3("");
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formValues.oldPassword.trim()) {
      // If message is empty, show error message and prevent form submission
      setError1("Please enter the Current Password");
      return;
    }

    if (formValues.password.includes(" ")) {
      setError2("Blank spaces are not allowed.");
      return;
    }

    if (!formValues.password.trim()) {
      // If message is empty, show error message and prevent form submission
      setError2("Please enter the Password");
      return;
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{6,}$/;

    // Check if the password meets the criteria
    if (!regex.test(formValues.password)) {
      setError2("Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 6 characters long.");
      return;
    }

    if (formValues.confirmPassword.includes(" ")) {
      setError3("Blank spaces are not allowed.");
      return;
    }

    if (!formValues.confirmPassword.trim()) {
      // If message is empty, show error message and prevent form submission
      setError3("Please enter the Confirm Password");
      return;
    }

    AxiosInstance.post("change-password", formValues)
      .then((response) => {
        // Handle the successful response here
        toast.success(response);
        setTimeout(() => {
          onChangePasswordModelClose();
        }, 1000);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  return (
    <div className="ChangePasswordModal">
      <ToastMessage></ToastMessage>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="/img/lock.png"
          alt="lock"
          className="lock"
        ></img>
      </div>

      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <TextField
              label="Current Password"
              variant="outlined"
              type={showOldPassword ? "text" : "password"}
              fullWidth
              name="oldPassword"
              margin="normal"
              value={formValues.oldPassword}
              onChange={handleChange}
              error={Boolean(error1)}
              helperText={error1}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleOldPassword}
                      tabIndex={-1}
                    >
                      {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),

                // Disable the password reveal eye icon
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <TextField
              label="New Password"
              variant="outlined"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              name="password"
              margin="normal"
              value={formValues.password}
              onChange={handleChange}
              error={Boolean(error2)}
              helperText={error2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleNewPassword}
                      tabIndex={-1}
                    >
                      {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),

                // Disable the password reveal eye icon
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <TextField
              label="Confirm Password"
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              margin="normal"
              name="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleChange}
              error={Boolean(error3)}
              helperText={error3}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConfirmPassword}
                      tabIndex={-1}
                    >
                      {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),

                // Disable the password reveal eye icon
              }}
            />
          </Col>
        </Row>

        {/* <Row>
                <Col lg={12}>
                <TextField
                    fullWidth
                    label="Message"
                    variant="outlined"
                    name="message"
                    multiline
                    rows={2}
                    value={formValues.message}
                    onChange={handleChange}
                    margin="normal"
                    error={Boolean(error)}
                    helperText={error}
                />
                </Col>
            </Row> */}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          style={{ marginTop: "20px", padding: "10px", backgroundColor: "#5353e9" }}
        >
          Update
        </Button>
      </form>
    </div>
  );
};

export default ChangePasswordModal;
