import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis } from "recharts";
import { IconTrendDown } from "../../components/IconTrendDown";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getHospitalVisitMonthlyApi } from "../../constants/apiList";
import CustomXAxisTick from "../../shared/CustomXAxisTick";
import { usePagination } from "../../util/utils";

const TotalHospitalVisitGraph = () => {
  const [totalVisits, setTotalVisits] = useState();
  const [visitData, setVisitData] = useState([]);
  const [highlightedLabel, setHighlightedLabel] = useState(null);
  const [lastMonthYear, setLastMonthYear] = useState([]);

  const { currentPage, totalItems, totalPages, currentData, handleNextPage, handlePreviousPage } = usePagination(visitData, (item = 4));

  const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const handleAxisLabel = (event) => {
    const selectedValue = event.value;
    const result = visitData?.find((entry) => {
      return entry.monthYear === event.value;
    });
    if (result) {
      setTotalVisits(result);
      const [month, year] = result.monthYear.split("'");
      const newMonthYear = parseInt(year, 10) - 1;
      setLastMonthYear(month + "'" + newMonthYear);
      setHighlightedLabel(selectedValue);
    }
  };

  const getHospitalVisitMonthly = async () => {
    AxiosInstance.get(`${getHospitalVisitMonthlyApi}`)
      .then((res) => {
        const modifiedData = res.map((item) => ({
          ...item,
          monthYear: `${monthAbbreviations[item.month - 1]}'${String(item.year).slice(2)}`,
        }));
        setVisitData(modifiedData);
      })

      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (visitData.length > 0) {
      setTotalVisits(visitData[visitData.length - 1]);
      setHighlightedLabel(visitData[visitData.length - 1]?.monthYear);
    }
  }, [visitData]);

  useEffect(() => {
    getHospitalVisitMonthly();
  }, []);

  return (
    <>
      <div className="graph-card-title">
        How often I'm Visiting to hospital as of (<span className="month-year-as-of">{totalVisits?.monthYear}</span>)
      </div>
      <div className="legend-data-wrapper">
        <div className="legend-data">
          <p>
            Cumulative : {totalVisits?.monthlyVisitsCumsum}
            <span className="monthly-count">Monthly Count : {totalVisits?.monthlyVisits}</span>
          </p>
          <p>
            Visit Percent : <span style={{ color: `${totalVisits?.ytmPctChangePercent > 0 ? "green" : "red"}` }}>{totalVisits?.ytmPctChangePercent}%</span>{" "}
            <IconTrendDown iconTrendDown={`${totalVisits?.ytmPctChangePercent > 0 ? "/img/icon-trend-down.png" : "/img/icon-trend-up-red.png"}`} />
            <span className="compare-to">Compare to {lastMonthYear}</span>
          </p>
        </div>
      </div>
      <div style={{ margin: "0 auto", width: "100%", display: "flex", alignItems: "end" }}>
        <IconButton
          onClick={handlePreviousPage}
          disabled={currentPage - 1 < 0}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "10px", fontSize: "10px" }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <div className="chart-wrapper">
          <ResponsiveContainer
            width="100%"
            height={150}
          >
            <LineChart
              data={currentData}
              margin={{
                top: 20,
                right: 28,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 5" />
              <XAxis
                dataKey="monthYear"
                interval={"preserveStartEnd"}
                textAnchor="center"
                cursor="pointer"
                onClick={handleAxisLabel}
                tick={
                  <CustomXAxisTick
                    highlightedLabel={highlightedLabel}
                    boxWidth={40}
                  />
                }
              />
              <Line
                dataKey="monthlyVisitsCumsum"
                stroke="red"
                strokeWidth={2}
                label={{ position: "top" }}
                dot={true}
              />
              <Line
                dataKey="monthlyVisits"
                stroke="blue"
                strokeWidth={2}
                label={{ position: "top" }}
                dot={true}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage + 1 >= totalPages}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "10px", fontSize: "10px" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <div className="x-axis-color-label">
        <p>
          Visit Cumulative<span style={{ color: "red", marginLeft: "5px", fontSize: "20px" }}>●</span>
        </p>
        <p>
          Monthly Visit Count<span style={{ color: "blue", marginLeft: "5px", fontSize: "20px" }}>●</span>
        </p>
      </div>
      <hr className="card-notes-divider" />
      <div className="graph-footer">
        <p className="graph-note">Note: Please click on the month to see the data.</p>
      </div>
    </>
  );
};

export default TotalHospitalVisitGraph;
