import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Delete } from "../../icons/DeleteIcon";
import ModelPopUp from "../../shared/model/ModelPopUp";
import "./AddMoreFormMain.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";

function HospitalList() {
  const [hospitals, setHospitals] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [hospitalsnew, setHospitalsnew] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cityNames, setCityNames] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const validationSchema = yup.object().shape({
    hospitalName: yup.string().required("Please enter Hospital / PCP / Clinic"),
    city: yup.string().required("Please enter Location / Address"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { hospitalName: "", city: "" },
  });

  const addMoreHospitalFields = () => {
    setIsOpen(true);
  };

  const resetForm = () => {
    reset({
      city: null, // Reset the "city" field
    });
  };

  const AddHospitalList = async (data) => {
    await AxiosInstance.post("hospital/add-new-hospital", data)
      .then((response) => {
        toast.success("Hospital Added successfully");
        setIsOpen(false);
        setLoading(true);
        reset({ city: "" });
        reset();
        resetForm();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getHospitalsList = async () => {
    await AxiosInstance.get("hospital/list")
      .then((response) => {
        setHospitals(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHospitalsList();
  }, [loading]);

  const getHospitalsCity = async () => {
    if (selectedValue?.label?.length >= 3) {
      await AxiosInstance.get(`master-hospital?search=${encodeURIComponent(selectedValue?.label)}`)
        .then((data) => {
          setHospitalsnew(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedValue?.label?.trim() === "") {
      setHospitalsnew([]);
      return;
    }
    setLoading(true);
    getHospitalsCity();
  }, [selectedValue]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const deleteHopital = (hospitalId) => {
    const Id = hospitalId;
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this Hospital?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (hospitalId) => {
        if (hospitalId.isConfirmed) {
          await AxiosInstance.delete(`hospital/delete/${Id}`).then((response) => {
            setLoading((prev) => !prev);
            toast.success(response);
          });
        }
      });
  };

  const getCityList = async () => {
    try {
      const response = await AxiosInstance.get("lab/city/list");
      setCityNames(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCityList();
  }, []);

  const structuredCityData = cityNames.map((city) => ({
    label: city,
  }));

  return (
    <>
      <div className="health_Service_Providers_Information">
        <div className="info_header_wrapper">
          <div className="info_provider_content">
            <h3>Hospitals</h3>
            <p>Please list the hospitals, PCP's you usually visit for consultations.</p>
          </div>
          <div className="info_provider_button">
            <button
              type="button"
              onClick={addMoreHospitalFields}
            >
              + Add Hospital
            </button>
          </div>
        </div>
        <div className="add_more_header_wrapper">
          <div className="field_header equal-column">Hospital / PCP /Clinic</div>
          <div className="field_header equal-column">Location / Address</div>
          <div className="field_header small-column"></div>
        </div>
        {hospitals &&
          hospitals.map((input, index) => {
            return (
              <div
                key={index}
                className="add_more_fields_wrapper"
              >
                <p className="equal-column tableData">{input.hospitalName}</p>
                <p className="equal-column tableData">{input.city}</p>
                <button
                  type="button"
                  className="remove_button small-column tableData"
                  onClick={() => deleteHopital(input.id)}
                >
                  <Delete />
                </button>
              </div>
            );
          })}
      </div>
      <ModelPopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading="Add New Hospital"
        handleSubmit={handleSubmit(AddHospitalList)}
      >
        <form className="hospital-doctor-add-form">
          <div className={`form-group mt-3 ${errors.city ? "required-border" : ""}`}>
            <label>Please enter Location / Address</label>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  options={structuredCityData}
                  getOptionLabel={(option) => option?.label || ""}
                  clearOnEscape={true}
                  onChange={(e, newValue) => {
                    field.onChange(newValue?.label);
                    setSelectedValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={true}
                      label="Please enter Location / Address"
                      className="invalid-input-field"
                    />
                  )}
                />
              )}
            />
            <p className="error">{errors.city?.message}</p>
          </div>
          <div className={`form-group mt-3 ${errors.hospitalName ? "required-border" : ""}`}>
            <label>Hospital / PCP / Clinic</label>
            <Controller
              name="hospitalName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-select-currency"
                  select
                  label="Select"
                  fullWidth
                >
                  {hospitalsnew.length === 0 ? (
                    <MenuItem
                      className="no-data-found"
                      value=""
                    >
                      No record found
                    </MenuItem>
                  ) : (
                    hospitalsnew.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.hospitalName}
                      >
                        {option.hospitalName}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              )}
            />
            <p className="error">{errors.hospitalName?.message}</p>
          </div>
        </form>
      </ModelPopUp>
    </>
  );
}

export default HospitalList;
