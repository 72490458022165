import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Col, Row } from "reactstrap";
import React, { useEffect, useState, useSyncExternalStore } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const DailyLogTrendingCharts = () => {
  const [BPTrendingData, setBPTrendingData] = useState([]);
  // const [BloodSugarTrendingData, setBloodSugarTrendingData] = useState();
  const [preBloodSugarTableData, setPreBloodSugarTableData] = useState([]);
  const [postBloodSugarTableData, setPostBloodSugarTableData] = useState([]);
  const [bmiTableData, setbmiTableData] = useState([]);
  const [motherDetail, setMotherDetail] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null)
  const [familyInfo, setFamilyInfo] = useState(null);
  useEffect(() => {
    const separateData = (data) => {
      const preData = [];
      const postData = [];

      data.forEach((entry) => {
        const date = new Date(entry.createdOn).toISOString().split("T")[0];

        const preEntry = {
          date: date,
          preBreakfast:
            entry.bloodSugarPreBreakfast !== null
              ? entry.bloodSugarPreBreakfast.toString()
              : "",
          preLunch:
            entry.bloodSugarPreLunch !== null
              ? entry.bloodSugarPreLunch.toString()
              : "",
          preDinner:
            entry.bloodSugarPreDinner !== null
              ? entry.bloodSugarPreDinner.toString()
              : "",
          preBedtime:
            entry.bloodSugarPreBedTime !== null
              ? entry.bloodSugarPreBedTime.toString()
              : "",
        };

        const postEntry = {
          date: date,
          postBreakfast:
            entry.bloodSugarPostBreakfast !== null
              ? entry.bloodSugarPostBreakfast.toString()
              : "",
          postLunch:
            entry.bloodSugarPostLunch !== null
              ? entry.bloodSugarPostLunch.toString()
              : "",
          postDinner:
            entry.bloodSugarPostDinner !== null
              ? entry.bloodSugarPostDinner.toString()
              : "",
          postBedtime:
            entry.bloodSugarPostBedTime !== null
              ? entry.bloodSugarPostBedTime.toString()
              : "",
        };

        preData.push(preEntry);
        postData.push(postEntry);
      });

      setPreBloodSugarTableData(preData);
      setPostBloodSugarTableData(postData);
    };
    AxiosInstance.get("dailyLog/getBloodPressureTrendingData")
      .then((response) => {
        setBPTrendingData(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    
    AxiosInstance.get("dailyLog/getBmiChartData")
      .then((response) => {
        setbmiTableData(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    AxiosInstance.get("dailyLog/getBloodSugarTrendingData")
      .then((response) => {
        separateData(response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    AxiosInstance.get("/family/getChildMotherDetail")
      .then((response) => {
        setMotherDetail(response);
        console.log("mother", motherDetail)
      })
      .catch((error) => {
        console.log(error);
      });
      AxiosInstance.get("/family/getPatientFamilyProfile")
        .then((response) => {
          setFamilyRelation(response);
        })
        .catch((error) => {
          console.log(error);
        });
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  // const PreBloodSugarTableData = [
  //   {
  //     date: "01-Mar-23",
  //     preBreakfast: "132",
  //     preLunch: "82",
  //     preDinner: "79",
  //     preBedtime: "70",
  //   },
  //   {
  //     date: "02-Mar-23",
  //     preBreakfast: "132",
  //     preLunch: "82",
  //     preDinner: "79",
  //     preBedtime: "70",
  //   },
  //   {
  //     date: "03-Mar-23",
  //     preBreakfast: "132",
  //     preLunch: "82",
  //     preDinner: "79",
  //     preBedtime: "70",
  //   },
  //   {
  //     date: "04-Mar-23",
  //     preBreakfast: "132",
  //     preLunch: "82",
  //     preDinner: "79",
  //     preBedtime: "70",
  //   },
  // ];
  // const PostBloodSugarTableData = [
  //   {
  //     date: "01-Mar-23",
  //     postBreakfast: "132",
  //     postLunch: "82",
  //     postDinner: "70",
  //     postBedtime: "65",
  //   },
  //   {
  //     date: "02-Mar-23",
  //     postBreakfast: "132",
  //     postLunch: "82",
  //     postDinner: "70",
  //     postBedtime: "65",
  //   },
  //   {
  //     date: "03-Mar-23",
  //     postBreakfast: "132",
  //     postLunch: "82",
  //     postDinner: "70",
  //     postBedtime: "65",
  //   },
  //   {
  //     date: "04-Mar-23",
  //     postBreakfast: "132",
  //     postLunch: "82",
  //     postDinner: "70",
  //     postBedtime: "65",
  //   },
  // ];
  // const BpTableData = [
  //   {
  //     date: "01-Mar-23",
  //     systolic: "132",
  //     diastolic: "82",
  //   },
  //   {
  //     date: "02-Mar-23",
  //     systolic: "128",
  //     diastolic: "88",
  //   },
  //   {
  //     date: "03-Mar-23",
  //     systolic: "140",
  //     diastolic: "90",
  //   },
  //   {
  //     date: "04-Mar-23",
  //     systolic: "132",
  //     diastolic: "81",
  //   },
  // ];

  return (
    <>
      {/* {familyInfo?.age >= 0 && familyInfo < 3 ? (
        motherDetail?.diabetes?.startsWith("Diabetes") && motherDetail?.hyperTension?.startsWith("Hypertension") ? (
          <>
            <div>
              <h5 style={{ marginTop: "2%", marginLeft: "35%" }}>My Blood Sugar Trending</h5>
              <Row style={{ marginTop: "20px" }}>
                <Col lg={6}>
                  <div style={{ marginLeft: "35%", fontSize: "21px" }}>Pre</div>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                    <LineChart
                      width={Math.max(500, preBloodSugarTableData.length * 50)}
                      height={350}
                      data={preBloodSugarTableData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={preBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" dataKey="preBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                      <Line type="monotone" dataKey="preLunch" stroke="#82ca9d" name="Lunch" />
                      <Line type="monotone" dataKey="preDinner" stroke="#FF8042" name="Dinner" />
                      <Line type="monotone" dataKey="preBedtime" stroke="#0088FE" name="Bedtime" />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                    <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                    <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                    <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                  </div>
                </Col>
                <Col lg={6}>
                  <div style={{ marginLeft: "35%", fontSize: "21px" }}>Post</div>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                    <LineChart
                      width={Math.max(500, postBloodSugarTableData.length * 50)}
                      height={350}
                      data={postBloodSugarTableData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={postBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" dataKey="postBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                      <Line type="monotone" dataKey="postLunch" stroke="#82ca9d" name="Lunch" />
                      <Line type="monotone" dataKey="postDinner" stroke="#FF8042" name="Dinner" />
                      <Line type="monotone" dataKey="postBedtime" stroke="#0088FE" name="Bedtime" />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                    <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                    <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                    <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                  </div>
                </Col>
              </Row>
            </div>
            <hr></hr>
            <Row>
              <Col lg={6}>
                <div style={{ borderRight: "1px solid #bdbdbd", height: "90%" }}>
                  <h5 style={{ marginTop: "5%" }}>My BP Trending</h5>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                    <LineChart
                      width={Math.max(500, BPTrendingData.length * 50)}
                      height={350}
                      data={BPTrendingData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" name="Systolic Blood Pressure AM" dataKey="systolicBloodPressureAm" stroke="#82ca9d" />
                      <Line type="monotone" name="Systolic Blood Pressure PM" dataKey="systolicBloodPressurePm" strokeDasharray="5 5" stroke="#82ca9d" />
                      <br></br>
                      <Line type="monotone" name="Diastolic Blood Pressure AM" dataKey="diastolicBloodPressureAm" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line type="monotone" name="Diastolic Blood Pressure PM" dataKey="diastolicBloodPressurePm" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure PM</span>
                    <br />
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure PM</span>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : motherDetail?.hyperTension?.startsWith("Hypertension") ? (
          <>
            <Row>
              <Col lg={6}>
                <div style={{ borderRight: "1px solid #bdbdbd", height: "90%" }}>
                  <h5 style={{ marginTop: "5%" }}>My BP Trending</h5>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                    <LineChart
                      width={Math.max(500, BPTrendingData.length * 50)}
                      height={350}
                      data={BPTrendingData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" name="Systolic Blood Pressure AM" dataKey="systolicBloodPressureAm" stroke="#82ca9d" />
                      <Line type="monotone" name="Systolic Blood Pressure PM" dataKey="systolicBloodPressurePm" strokeDasharray="5 5" stroke="#82ca9d" />
                      <br></br>
                      <Line type="monotone" name="Diastolic Blood Pressure AM" dataKey="diastolicBloodPressureAm" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line type="monotone" name="Diastolic Blood Pressure PM" dataKey="diastolicBloodPressurePm" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure PM</span>
                    <br />
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure PM</span>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div>
            <h5 style={{ marginTop: "2%", marginLeft: "35%" }}>My Blood Sugar Trending</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col lg={6}>
                <div style={{ marginLeft: "35%", fontSize: "21px" }}>Pre</div>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                  <LineChart
                    width={Math.max(500, preBloodSugarTableData.length * 50)}
                    height={350}
                    data={preBloodSugarTableData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={preBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" dataKey="preBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                    <Line type="monotone" dataKey="preLunch" stroke="#82ca9d" name="Lunch" />
                    <Line type="monotone" dataKey="preDinner" stroke="#FF8042" name="Dinner" />
                    <Line type="monotone" dataKey="preBedtime" stroke="#0088FE" name="Bedtime" />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                  <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                  <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                  <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                </div>
              </Col>
              <Col lg={6}>
                <div style={{ marginLeft: "35%", fontSize: "21px" }}>Post</div>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                  <LineChart
                    width={Math.max(500, postBloodSugarTableData.length * 50)}
                    height={350}
                    data={postBloodSugarTableData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={postBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" dataKey="postBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                    <Line type="monotone" dataKey="postLunch" stroke="#82ca9d" name="Lunch" />
                    <Line type="monotone" dataKey="postDinner" stroke="#FF8042" name="Dinner" />
                    <Line type="monotone" dataKey="postBedtime" stroke="#0088FE" name="Bedtime" />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                  <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                  <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                  <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                </div>
              </Col>
            </Row>
          </div>
        )
      ) : (
        <>
          {" "}
          <div>
            <h5 style={{ marginTop: "2%", marginLeft: "35%" }}>My Blood Sugar Trending</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col lg={6}>
                <div style={{ marginLeft: "35%", fontSize: "21px" }}>Pre</div>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                  <LineChart
                    width={Math.max(500, preBloodSugarTableData.length * 50)}
                    height={350}
                    data={preBloodSugarTableData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={preBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" dataKey="preBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                    <Line type="monotone" dataKey="preLunch" stroke="#82ca9d" name="Lunch" />
                    <Line type="monotone" dataKey="preDinner" stroke="#FF8042" name="Dinner" />
                    <Line type="monotone" dataKey="preBedtime" stroke="#0088FE" name="Bedtime" />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                  <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                  <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                  <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                </div>
              </Col>
              <Col lg={6}>
                <div style={{ marginLeft: "35%", fontSize: "21px" }}>Post</div>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                  <LineChart
                    width={Math.max(500, postBloodSugarTableData.length * 50)}
                    height={350}
                    data={postBloodSugarTableData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={postBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" dataKey="postBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                    <Line type="monotone" dataKey="postLunch" stroke="#82ca9d" name="Lunch" />
                    <Line type="monotone" dataKey="postDinner" stroke="#FF8042" name="Dinner" />
                    <Line type="monotone" dataKey="postBedtime" stroke="#0088FE" name="Bedtime" />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                  <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                  <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                  <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                </div>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <Row>
            <Col lg={6}>
              <div style={{ borderRight: "1px solid #bdbdbd", height: "90%" }}>
                <h5 style={{ marginTop: "5%" }}>My BP Trending</h5>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                  <LineChart
                    width={Math.max(500, BPTrendingData.length * 50)}
                    height={350}
                    data={BPTrendingData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" name="Systolic Blood Pressure AM" dataKey="systolicBloodPressureAm" stroke="#82ca9d" />
                    <Line type="monotone" name="Systolic Blood Pressure PM" dataKey="systolicBloodPressurePm" strokeDasharray="5 5" stroke="#82ca9d" />
                    <br></br>
                    <Line type="monotone" name="Diastolic Blood Pressure AM" dataKey="diastolicBloodPressureAm" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" name="Diastolic Blood Pressure PM" dataKey="diastolicBloodPressurePm" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure AM</span> &nbsp;
                  <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure PM</span>
                  <br />
                  <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure AM</span> &nbsp;
                  <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure PM</span>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )} */}
      {familyInfo?.age >= 0 && familyInfo?.age < 3 ? (
        motherDetail?.diabetes?.startsWith("Diabetes") && motherDetail?.hyperTension?.startsWith("Hypertension") ? (
          // Both Diabetes and Hypertension present
          <>
            <div>
              <h5 style={{ marginTop: "2%", marginLeft: "35%" }}>My Blood Sugar Trending</h5>
              <Row style={{ marginTop: "20px" }}>
                <Col lg={6}>
                  <div style={{ marginLeft: "35%", fontSize: "21px" }}>Pre</div>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                    <LineChart
                      width={Math.max(500, preBloodSugarTableData.length * 50)}
                      height={350}
                      data={preBloodSugarTableData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={preBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" dataKey="preBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                      <Line type="monotone" dataKey="preLunch" stroke="#82ca9d" name="Lunch" />
                      <Line type="monotone" dataKey="preDinner" stroke="#FF8042" name="Dinner" />
                      <Line type="monotone" dataKey="preBedtime" stroke="#0088FE" name="Bedtime" />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                    <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                    <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                    <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                  </div>
                </Col>
                <Col lg={6}>
                  <div style={{ marginLeft: "35%", fontSize: "21px" }}>Post</div>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                    <LineChart
                      width={Math.max(500, postBloodSugarTableData.length * 50)}
                      height={350}
                      data={postBloodSugarTableData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={postBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" dataKey="postBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                      <Line type="monotone" dataKey="postLunch" stroke="#82ca9d" name="Lunch" />
                      <Line type="monotone" dataKey="postDinner" stroke="#FF8042" name="Dinner" />
                      <Line type="monotone" dataKey="postBedtime" stroke="#0088FE" name="Bedtime" />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                    <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                    <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                    <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                  </div>
                </Col>
              </Row>
            </div>
            <hr></hr>
            <Row>
              <Col lg={6}>
                <div style={{ borderRight: "1px solid #bdbdbd", height: "90%" }}>
                  <h5 style={{ marginTop: "5%" }}>My BP Trending</h5>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                    <LineChart
                      width={Math.max(500, BPTrendingData.length * 50)}
                      height={350}
                      data={BPTrendingData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" name="Systolic Blood Pressure AM" dataKey="systolicBloodPressureAm" stroke="#82ca9d" />
                      <Line type="monotone" name="Systolic Blood Pressure PM" dataKey="systolicBloodPressurePm" strokeDasharray="5 5" stroke="#82ca9d" />
                      <br></br>
                      <Line type="monotone" name="Diastolic Blood Pressure AM" dataKey="diastolicBloodPressureAm" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line type="monotone" name="Diastolic Blood Pressure PM" dataKey="diastolicBloodPressurePm" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure PM</span>
                    <br />
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure PM</span>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h5 style={{ marginTop: "5%" }}>My BMI Trending</h5>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                    <LineChart
                      width={Math.max(500, bmiTableData.length * 50)}
                      height={350}
                      data={bmiTableData.slice().reverse()}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" name="BMI" dataKey="bmi" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- BMI</span>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : motherDetail?.hyperTension?.startsWith("Hypertension") ? (
          // Only Hypertension present
          <>
            {" "}
            <hr></hr>
            <Row>
              <Col lg={6}>
                <div style={{ borderRight: "1px solid #bdbdbd", height: "90%" }}>
                  <h5 style={{ marginTop: "5%" }}>My BP Trending</h5>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                    <LineChart
                      width={Math.max(500, BPTrendingData.length * 50)}
                      height={350}
                      data={BPTrendingData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" name="Systolic Blood Pressure AM" dataKey="systolicBloodPressureAm" stroke="#82ca9d" />
                      <Line type="monotone" name="Systolic Blood Pressure PM" dataKey="systolicBloodPressurePm" strokeDasharray="5 5" stroke="#82ca9d" />
                      <br></br>
                      <Line type="monotone" name="Diastolic Blood Pressure AM" dataKey="diastolicBloodPressureAm" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line type="monotone" name="Diastolic Blood Pressure PM" dataKey="diastolicBloodPressurePm" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure PM</span>
                    <br />
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure AM</span> &nbsp;
                    <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure PM</span>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h5 style={{ marginTop: "5%" }}>My BMI Trending</h5>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                    <LineChart
                      width={Math.max(500, bmiTableData.length * 50)}
                      height={350}
                      data={bmiTableData.slice().reverse()}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" name="BMI" dataKey="bmi" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- BMI</span>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : motherDetail?.diabetes?.startsWith("Diabetes") ? (
          // Only Diabetes present
          <>
            {" "}
            <div>
              <h5 style={{ marginTop: "2%", marginLeft: "35%" }}>My Blood Sugar Trending</h5>
              <Row style={{ marginTop: "20px" }}>
                <Col lg={6}>
                  <div style={{ marginLeft: "35%", fontSize: "21px" }}>Pre</div>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                    <LineChart
                      width={Math.max(500, preBloodSugarTableData.length * 50)}
                      height={350}
                      data={preBloodSugarTableData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={preBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" dataKey="preBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                      <Line type="monotone" dataKey="preLunch" stroke="#82ca9d" name="Lunch" />
                      <Line type="monotone" dataKey="preDinner" stroke="#FF8042" name="Dinner" />
                      <Line type="monotone" dataKey="preBedtime" stroke="#0088FE" name="Bedtime" />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                    <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                    <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                    <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                  </div>
                </Col>
                <Col lg={6}>
                  <div style={{ marginLeft: "35%", fontSize: "21px" }}>Post</div>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                    <LineChart
                      width={Math.max(500, postBloodSugarTableData.length * 50)}
                      height={350}
                      data={postBloodSugarTableData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={postBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" dataKey="postBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                      <Line type="monotone" dataKey="postLunch" stroke="#82ca9d" name="Lunch" />
                      <Line type="monotone" dataKey="postDinner" stroke="#FF8042" name="Dinner" />
                      <Line type="monotone" dataKey="postBedtime" stroke="#0088FE" name="Bedtime" />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                    <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                    <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                    <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              {" "}
              <Col lg={6}>
                <div>
                  <h5 style={{ marginTop: "5%" }}>My BMI Trending</h5>
                  <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                    <LineChart
                      width={Math.max(500, bmiTableData.length * 50)}
                      height={350}
                      data={bmiTableData.slice().reverse()}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                      <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                      <Tooltip />
                      <Legend wrapperStyle={{ paddingTop: "25px" }} />
                      <Line type="monotone" name="BMI" dataKey="bmi" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                    </LineChart>
                  </div>
                  <div>
                    <span style={{ color: "#8884d8" }}> -o- BMI</span>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : null
      ) : (
        // Age >= 3
        <>
          <div>
            <h5 style={{ marginTop: "2%", marginLeft: "35%" }}>My Blood Sugar Trending</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col lg={6}>
                <div style={{ marginLeft: "35%", fontSize: "21px" }}>Pre</div>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                  <LineChart
                    width={Math.max(500, preBloodSugarTableData.length * 50)}
                    height={350}
                    data={preBloodSugarTableData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={preBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" dataKey="preBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                    <Line type="monotone" dataKey="preLunch" stroke="#82ca9d" name="Lunch" />
                    <Line type="monotone" dataKey="preDinner" stroke="#FF8042" name="Dinner" />
                    <Line type="monotone" dataKey="preBedtime" stroke="#0088FE" name="Bedtime" />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                  <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                  <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                  <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                </div>
              </Col>
              <Col lg={6}>
                <div style={{ marginLeft: "35%", fontSize: "21px" }}>Post</div>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
                  <LineChart
                    width={Math.max(500, postBloodSugarTableData.length * 50)}
                    height={350}
                    data={postBloodSugarTableData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={postBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" dataKey="postBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                    <Line type="monotone" dataKey="postLunch" stroke="#82ca9d" name="Lunch" />
                    <Line type="monotone" dataKey="postDinner" stroke="#FF8042" name="Dinner" />
                    <Line type="monotone" dataKey="postBedtime" stroke="#0088FE" name="Bedtime" />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
                  <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
                  <span style={{ color: "#FF8042" }}> -o- Dinner</span>
                  <span style={{ color: "#0088FE" }}> -o- BedTime</span>
                </div>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <Row>
            <Col lg={6}>
              <div style={{ borderRight: "1px solid #bdbdbd", height: "90%" }}>
                <h5 style={{ marginTop: "5%" }}>My BP Trending</h5>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                  <LineChart
                    width={Math.max(500, BPTrendingData.length * 50)}
                    height={350}
                    data={BPTrendingData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" name="Systolic Blood Pressure AM" dataKey="systolicBloodPressureAm" stroke="#82ca9d" />
                    <Line type="monotone" name="Systolic Blood Pressure PM" dataKey="systolicBloodPressurePm" strokeDasharray="5 5" stroke="#82ca9d" />
                    <br></br>
                    <Line type="monotone" name="Diastolic Blood Pressure AM" dataKey="diastolicBloodPressureAm" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" name="Diastolic Blood Pressure PM" dataKey="diastolicBloodPressurePm" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure AM</span> &nbsp;
                  <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure PM</span>
                  <br />
                  <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure AM</span> &nbsp;
                  <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure PM</span>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <h5 style={{ marginTop: "5%" }}>My BMI Trending</h5>
                <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
                  <LineChart
                    width={Math.max(500, bmiTableData.length * 50)}
                    height={350}
                    data={bmiTableData.slice().reverse()}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                    <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ paddingTop: "25px" }} />
                    <Line type="monotone" name="BMI" dataKey="bmi" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
                  </LineChart>
                </div>
                <div>
                  <span style={{ color: "#8884d8" }}> -o- BMI</span>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {/* <div>
        <h5 style={{ marginTop: "2%", marginLeft: "35%" }}>My Blood Sugar Trending</h5>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6}>
            <div style={{ marginLeft: "35%", fontSize: "21px" }}>Pre</div>
            <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
              <LineChart
                width={Math.max(500, preBloodSugarTableData.length * 50)}
                height={350}
                data={preBloodSugarTableData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={preBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                <Tooltip />
                <Legend wrapperStyle={{ paddingTop: "25px" }} />
                <Line type="monotone" dataKey="preBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                <Line type="monotone" dataKey="preLunch" stroke="#82ca9d" name="Lunch" />
                <Line type="monotone" dataKey="preDinner" stroke="#FF8042" name="Dinner" />
                <Line type="monotone" dataKey="preBedtime" stroke="#0088FE" name="Bedtime" />
              </LineChart>
            </div>
            <div>
              <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
              <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
              <span style={{ color: "#FF8042" }}> -o- Dinner</span>
              <span style={{ color: "#0088FE" }}> -o- BedTime</span>
            </div>
          </Col>
          <Col lg={6}>
            <div style={{ marginLeft: "35%", fontSize: "21px" }}>Post</div>
            <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden" }}>
              <LineChart
                width={Math.max(500, postBloodSugarTableData.length * 50)}
                height={350}
                data={postBloodSugarTableData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} interval={postBloodSugarTableData.length > 10 ? 0 : "preserveStartEnd"} />
                <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                <Tooltip />
                <Legend wrapperStyle={{ paddingTop: "25px" }} />
                <Line type="monotone" dataKey="postBreakfast" stroke="#8884d8" activeDot={{ r: 8 }} name="Breakfast" />
                <Line type="monotone" dataKey="postLunch" stroke="#82ca9d" name="Lunch" />
                <Line type="monotone" dataKey="postDinner" stroke="#FF8042" name="Dinner" />
                <Line type="monotone" dataKey="postBedtime" stroke="#0088FE" name="Bedtime" />
              </LineChart>
            </div>
            <div>
              <span style={{ color: "#8884d8" }}> -o- Breakfast</span>
              <span style={{ color: "#82ca9d" }}> -o- Lunch</span>
              <span style={{ color: "#FF8042" }}> -o- Dinner</span>
              <span style={{ color: "#0088FE" }}> -o- BedTime</span>
            </div>
          </Col>
        </Row>
      </div>
      <hr></hr>
      <Row>
        <Col lg={6}>
          <div style={{ borderRight: "1px solid #bdbdbd", height: "90%" }}>
            <h5 style={{ marginTop: "5%" }}>My BP Trending</h5>
            <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
              <LineChart
                width={Math.max(500, BPTrendingData.length * 50)}
                height={350}
                data={BPTrendingData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                <Tooltip />
                <Legend wrapperStyle={{ paddingTop: "25px" }} />
                <Line type="monotone" name="Systolic Blood Pressure AM" dataKey="systolicBloodPressureAm" stroke="#82ca9d" />
                <Line type="monotone" name="Systolic Blood Pressure PM" dataKey="systolicBloodPressurePm" strokeDasharray="5 5" stroke="#82ca9d" />
                <br></br>
                <Line type="monotone" name="Diastolic Blood Pressure AM" dataKey="diastolicBloodPressureAm" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" name="Diastolic Blood Pressure PM" dataKey="diastolicBloodPressurePm" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
              </LineChart>
            </div>
            <div>
              <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure AM</span> &nbsp;
              <span style={{ color: "#82ca9d" }}> -o- Systolic Blood Pressure PM</span>
              <br />
              <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure AM</span> &nbsp;
              <span style={{ color: "#8884d8" }}> -o- Diastolic Blood Pressure PM</span>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <h5 style={{ marginTop: "5%" }}>My BMI Trending</h5>
            <div style={{ width: "100%", overflowX: "auto", maxWidth: "500px", overflowY: "hidden", marginTop: "5%" }}>
              <LineChart
                width={Math.max(500, bmiTableData.length * 50)}
                height={350}
                data={bmiTableData.slice().reverse()}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="date" angle={-45} textAnchor="end" tick={{ fontSize: 10 }} allowDataOverflow />
                <YAxis domain={[0, 400]} tickCount={5}></YAxis>
                <Tooltip />
                <Legend wrapperStyle={{ paddingTop: "25px" }} />
                <Line type="monotone" name="BMI" dataKey="bmi" stroke="#8884d8" strokeDasharray="5 5" activeDot={{ r: 8 }} />
              </LineChart>
            </div>
            <div>
              <span style={{ color: "#8884d8" }}> -o- BMI</span>
            </div>
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default DailyLogTrendingCharts;
