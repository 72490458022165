import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Input, Row } from "reactstrap";
import "./LifeStyle.css";
import { Card } from "@mui/material";
import Spinner from "../../shared/Spinner";

const ChildrenDiet = () => {
  const [ChildrenDiet, setChildrenDiet] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("form/lifestyleSketchDietForChildren")
      .then((response) => {
        setChildrenDiet(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  console.log("diet", ChildrenDiet);
  return (
    <div>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Child's Diet</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    1. Does your child have any medical problems :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.medicalProblem ? "Yes" : "No"}
                  </span>
                </Col>
                {ChildrenDiet?.medicalProblem && (
                  <Col lg="6">
                    <label className="questionLabel">
                      2. Please list or describe :
                    </label>
                    <span className="inputDiet">
                      {ChildrenDiet?.describeMedicalProblem}
                    </span>
                  </Col>
                )}
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    3. Dental problems or cavities :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.dentalProblem ? "Yes" : "No"}
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    4. Does your child take any medicine :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.takeMedicine ? "Yes" : "No"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                {ChildrenDiet?.takeMedicine && (
                  <Col lg="6">
                    <label className="questionLabel">5. Please list :</label>
                    <span className="inputDiet">
                      {ChildrenDiet?.takeMedicineList}
                    </span>
                  </Col>
                )}
                <Col lg="6">
                  <label className="questionLabel">
                    6. Is your child on a special diet such as Vegetarian or
                    Macrobiotic :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.spacialDiet ? "Yes" : "No"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                {ChildrenDiet?.spacialDiet && (
                  <Col lg="6">
                    <label className="questionLabel">7. Please list :</label>
                    <span className="inputDiet">
                      {ChildrenDiet?.spacialDietList}
                    </span>
                  </Col>
                )}
                <Col lg="6">
                  <label className="questionLabel">
                    8. You limit the following in your child's diet :{" "}
                  </label>
                  <span className="inputDiet">{ChildrenDiet?.dietLimit}</span>
                </Col>
              </Row>
              <Row className="mb-2">
                {ChildrenDiet?.dietLimitReason && (
                  <Col lg="6">
                    <label className="questionLabel">9. Reason :</label>
                    <span className="inputDiet">
                      {ChildrenDiet?.dietLimitReason}
                    </span>
                  </Col>
                )}

                <Col lg="6">
                  <label className="questionLabel">
                    10. Your child takes the following :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.takeVitamins}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    11. The amount of milk your child drinks each day is :
                  </label>
                  <span className="inputDiet">{ChildrenDiet?.milkDrink}</span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    12. Types of milk your child drinks :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.whichMilkDrink}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    13. Other beverages your child drinks :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.drinkBeverages}
                  </span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    14. You add water to these beverages :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.drinkBeveragesAddWater ? "Yes" : "No"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    15. Child drink plain water :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.drinkPlainWater ? "Yes" : "No"}
                  </span>
                </Col>

                {ChildrenDiet?.drinkPlainWater && (
                  <Col lg="6">
                    <label className="questionLabel">
                      16. How much each day :{" "}
                    </label>
                    <span className="inputDiet">
                      {ChildrenDiet?.drinkPlainWaterHowMuch}
                    </span>
                  </Col>
                )}
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    17. Child having any problems with :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.problemWithFood ? "Yes" : "No"}
                  </span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    18. Your child is allergic to the following foods :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.whichAllergicFoods}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    19. Child having any problems with :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.problemWithFood ? "Yes" : "No"}
                  </span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    20. Your child is allergic to the foods :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.allergicFood ? "Yes" : "No"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                {ChildrenDiet?.allergicFood && (
                  <Col lg="6">
                    <label className="questionLabel">
                      21. Allergic Foods :
                    </label>
                    <span className="inputDiet">
                      {ChildrenDiet?.whichAllergicFoods ? "Yes" : "No"}
                    </span>
                  </Col>
                )}

                <Col lg="6">
                  <label className="questionLabel">
                    22. No. of meals your child eats each day :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.eatHowManyMealsEachDay ? "Yes" : "No"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    23. The number of times your child eats snacks each day is :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.eatSnacksTimesInDay}
                  </span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    24. Foods your child eats for snacks :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.eatForSnacks}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    25. Your child eats at fast food places such as Burger King
                    or McDonald's :{" "}
                    <span className="inputDiet">
                      {ChildrenDiet?.eatFastFoodTime}
                    </span>
                  </label>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    26. Your child eats any of the following foods :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.doesYourChildEatAnyFood16A}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    27. Your child eats the following foods :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.doesYourChildEatAnyFood16B}
                  </span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    28.Smoking occurs inside your home :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.anyOneSmokeInsideHome ? "Yes" : "No"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    29. Your child eats the following :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.doesYourChildEatAnyFood16C}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Check how often your child eats the foods listed below </h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    1. Meats, chicken, fish :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.eatMeatChickenFish}
                  </span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    2. Grains (pasta, rice, bread, cereal, tortilla) :{" "}
                  </label>
                  <span className="inputDiet">{ChildrenDiet?.eatGrains}</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">3. Fruits :</label>
                  <span className="inputDiet">{ChildrenDiet?.eatFruits}</span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">4. Eggs :</label>
                  <span className="inputDiet">{ChildrenDiet?.eatEggs}</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">5. Vegetables :</label>
                  <span className="inputDiet">
                    {ChildrenDiet?.eatVegetables}
                  </span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">6. Peanut butter :</label>
                  <span className="inputDiet">
                    {ChildrenDiet?.eatPeanutButter}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">7. Cheese :</label>
                  <span className="inputDiet">{ChildrenDiet?.eatCheese}</span>
                </Col>

                <Col lg="6">
                  <label className="questionLabel">
                    8. Beans (pinto, kidney, etc) :
                  </label>
                  <span className="inputDiet">{ChildrenDiet?.eatBeans}</span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>What you ate in the last 7 seven days</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    1. Number of meals in a day :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.numberOfMealsPerDay}
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    2. What was included in the meals :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.includedMeals}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    3. How would you describe your diet :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.yourChildDiet}
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    4. Do you have breakfast regularly :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.breakfastRegularly}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">5. My appetite is :</label>
                  <span className="inputDiet">{ChildrenDiet?.appetite}</span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    6. What time do you have dinner :
                  </label>
                  <span className="inputDiet">{ChildrenDiet?.dinnerTime}</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    7. Number of snacks per day :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.numberOfSnackPerDay}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Diet Choices and Behaviour</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. Mostly prefer Vegetarian food and try to have more
                    vegetables and fruits in my daily diet.
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.preferVegetarianFood}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    2. Tries to avoid processed meat (Hamburgers, sausages, Hot
                    dogs, etc.) :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.avoidProcessedMeat}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    3. Tries to avoid highly processed foods (convenience meals,
                    fast goods, cookies, chips, pretzels, etc) :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.avoidHighlyProcessedFoods}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    4. Does not drink sugar sweetened beverages (Pepsi, coke,
                    etc.) :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.doNotDrinkSugarSweetenedBeverage}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={9}>
                  <label className="questionLabel">
                    5. Regularly consumes whole grains, legumes(Peas and beans)
                    and nuts(walnuts, almond, pecans, etc.) and seeds (Chia,
                    flax, pumpkin,, etc.) as part of my healthy diet practice:
                  </label>
                </Col>
                <Col lg={3}>
                  <span className="inputDiet">
                    {ChildrenDiet?.regularlyConsumeWholeGrainsLegumes}
                  </span>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    6. Eats a diet which is low in added sugars, sodium,
                    saturated fats, trans fats, and cholesterol :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.eatDietWhichIsLowInAddedSugars}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    7. Consciously try to limit or decrease salt in my meals :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.limitOrDecreaseSaltInMyMeals}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    8. Consciously try to eat food or fruits that are high in
                    fiber :
                  </label>
                  <span className="inputDiet">{ChildrenDiet?.highInFiber}</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    9. Consciously try to limit or decrease sugar in my diet :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.limitOrDecreaseSugarInMyDiet}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    10. About 30% of my meals (breakfast, lunch and dinner) in a
                    day consist of vegetables :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.dailyVegetableInTake}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    11. About 20% of my meals (breakfast, lunch and dinner) in a
                    day consist of fruits :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.dailyFruitsInTake}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    12. About 25% of my meals (breakfast, lunch and dinner) in a
                    day consist of healthy proteins (fish, poultry, beans, nuts
                    and seeds, soya, pulses, Tofu, chicken etc.){" "}
                    <span className="inputDiet">
                      {ChildrenDiet?.dailyHealthyProteinsInTake}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    13. About 25% of my meals (breakfast, lunch and dinner) in a
                    day consist of whole grains (whole wheat bread, whole grains
                    pasta, brown rice etc.){" "}
                    <span className="inputDiet">
                      {ChildrenDiet?.dailyWholeGrainsInTake}
                    </span>
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>General questions</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    1. What is your current height (cm) :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.currentHeight}
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    2. What is your current weight (kg) :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.currentWeight}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">3. BMI : </label>
                  <span className="inputDiet">{ChildrenDiet?.bmi}</span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    4. From whom have you received diet counselling :
                  </label>
                  <span className="inputDiet">{ChildrenDiet?.concernDiet}</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6">
                  <label className="questionLabel">
                    5. Would you like to consult with a dietitian in our network
                    :{" "}
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.consultDietitianInNetwork ? "Yes" : "No"}
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    6. Regarding your child's diet :
                  </label>
                  <span className="inputDiet">
                    {ChildrenDiet?.termOfChildDiet}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                {ChildrenDiet?.termOfChildDiet ===
                  "Strictly vegetarian (Eggs not included)" && (
                  <Col lg="6">
                    <label className="questionLabel">
                      7. This is included in your Child's diet :{" "}
                    </label>
                    <span className="inputDiet">
                      {ChildrenDiet?.itemsIncludeInDiet}
                    </span>
                  </Col>
                )}
                {ChildrenDiet?.termOfChildDiet ===
                  "Eggetarian (veg + eggs)" && (
                  <Col lg="6">
                    <label className="questionLabel">
                      7. The frequency with which your child eats eggs is :{" "}
                    </label>
                    <span className="inputDiet">
                      {ChildrenDiet?.oftenEatEggs}
                    </span>
                  </Col>
                )}
                {ChildrenDiet?.termOfChildDiet ===
                  "Mixed (includes veg, meat and poultry)" && (
                  <Col lg="6">
                    <label className="questionLabel">
                      7. The frequency with which your child eats meat is :{" "}
                    </label>
                    <span className="inputDiet">
                      {ChildrenDiet?.oftenEatMeat}
                    </span>
                  </Col>
                )}
                {ChildrenDiet?.termOfChildDiet ===
                  "Mixed (includes veg, meat and poultry)" && (
                  <Col lg="6">
                    <label className="questionLabel">
                      8. Mostly included in your child's diet :
                    </label>
                    <span className="inputDiet">
                      {ChildrenDiet?.mixedIncludedInYourDiet}
                    </span>
                  </Col>
                )}
              </Row>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default ChildrenDiet;
