import React, { useEffect, useRef, useState } from "react";
import { GrRefresh } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance, Axios } from "../../configration/AxiosConfiguration";
import { strings } from "../../constants/strings";
import VectorPlusPurple from "../../img/Vector-plus-purple.png";
import iconAttachment from "../../img/icon-attachment.png";
import iconMinusSquare from "../../img/icon-minus-square.png";
import iconPlusGreen from "../../img/icon-plus-green.png";
import MUITable from "../../shared/MUITable";
import PageTitle from "../../shared/PageTitle";
import SampleIllustration from "../../shared/SampleIllustration";
import Spinner from "../../shared/Spinner";
import ModelPopUp from "../../shared/model/ModelPopUp";
import AddMedicalReports from "./AddMedicalReports";
import MedicalHistoryTimeline from "./MedicalHistoryTimeline";
import SampleReport from "./SampleReport";
import "./style.css";
import axios from "axios";

export const MedicalHistory = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isMedicineOpen, setIsMedicineOpen] = useState(false);
  const [otherdocIds, setOtherdocIds] = useState([]);
  const [fileResponse, setFileResponse] = useState()
  const [addMedicineFormData, setAddMedicineFormData] = useState([
    {
      medicineName: "",
      strength: "",
      days: 0,
    },
  ]);

  const [filterDate, setFilterDate] = useState("");
  const [addTestFormData, setTestFormData] = useState([{ id: 1, name: "n1", value: "", showAddBtn: true }]);
  const [medicalFormData, setMedicalFormData] = useState({
    hospitalVisitDate: "",
    isOutPatient: true,
    admissionDate: "",
    releaseDate: "",
    hospitalName: "",
    city: "",
    physicianName: "",
    medicalCondition: "",
    diagnosis: "",
    followupDate: "",
    followupComment: "",
    file: "",
    testNames: [],
    otherDocumentIds: otherdocIds,
    initialExamination: {
      bpDia: "",
      bpSys: "",
      comment: "",
      height: "",
      weight: "",
      spo2reading: "",
      temperature: "",
      pulse: "",
      respiratoryRate: "",
    },
    diagnosisReport: {
      allergies: "",
      allergiesFlag: "",
      diagnosis: "",
      followupDate: "",
      healthAlert: "",
      medicalCondition: "",
      notes: "",
      symptoms: "",
    },
    medicineInfo: [
      {
        medicineName: "",
        strength: "",
        days: 0,
      },
    ],
  });

  const [formErrors, setFormErrors] = useState({
    hospitalName: "",
    medicalCondition: "",
    followupDate: "",
    medicineInfo: "",
    admissionDate: "",
    releaseDate: "",
    medicineName: "",
    strength: "",
    days: "",
    file: "",
  });
  const [isReportCopy, setIsReportCopy] = useState("");
  const [hospitalVisitList, setHospitalVisitList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDummyData, setIsDummyData] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [isLipidLoader, setisLipidLoader] = useState(false);

  const navigate = useNavigate();

  const addPrescriptionPopupHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const handleIsHospitalCopyChange = (event) => {
    setIsReportCopy(event.target.value);
    setMedicalFormData({
      hospitalVisitDate: "",
      isOutPatient: medicalFormData.isOutPatient,
      admissionDate: "",
      releaseDate: "",
      hospitalName: "",
      city: "",
      physicianName: "",
      medicalCondition: "",
      diagnosis: "",
      followupDate: "",
      followupComment: "",
      file: "",
      testNames: [],
      medicineInfo: [],
      initialExamination: {
        bpDia: "",
        bpSys: "",
        comment: "",
        height: "",
        weight: "",
        spo2reading: "",
        temperature: "",
        pulse: "",
        respiratoryRate: "",
      },
      diagnosisReport: {
        allergies: "",
        allergiesFlag: "",
        diagnosis: "",
        followupDate: "",
        healthAlert: "",
        medicalCondition: "",
        notes: "",
        symptoms: "",
      },
    });
    setTestFormData([{ id: 1, name: "n1", value: "", showAddBtn: true }]);
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const getPrescriptionRespnse = () => {
    AxiosInstance.get("visit/newVisit")
    .then((response) => {
      console.log("prescriptionResponse" , response);
      setMedicalFormData(
        {
            ...response,
            // isOutPatient: medicalFormData.isOutPatient,
            diagnosis: response.diagnosisReport?.diagnosis ?? "",
            medicalCondition: response.diagnosisReport?.medicalCondition,
            // followupDate: response.data.data.diagnosisReport?.followupDate,
            // followupComment: response.data.data.diagnosisReport?.advice
          }
        );
      setTestFormData(
        (response.testNames || []).map((obj, index) => {
          return { id: index, name: "n" + index, value: obj, showAddBtn: response.testNames.length - 1 === index };
        })
      );
    })
    .catch((error) => {
      toast.error(error);
    });
  }

  const handleFileChange = async (event) => {
    console.log("Event triggered:", event);
    const file = event.target.files[0];
    setSelectedFile(file);
    let fileType = file?.name?.split(".")?.pop();
    if (fileType == "gif" || fileType == "jfif" || fileType == "csv" || fileType == "xls") {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("outPatientFlag", medicalFormData.isOutPatient);
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      setIsLoading(true);
      // AxiosInstance.post("/prescription/parse", formData)
      //   .then((response) => {
      //     console.log("responseMedicalData", response);
      //     if (!response) {
      //       toast.error("API response has no data available.");
      //     } else {
      //       toast.success("Medical History uploaded successfully");
      //     }
      //     setIsLoading(false);
      //     setMedicalFormData({
      //       ...response,
      //       isOutPatient: medicalFormData.isOutPatient,
      //       diagnosisReport: response?.diagnosisReport?.diagnosis,
      //       medicalCondition: response?.diagnosisReport?.medicalCondition,
      //       followupDate: response?.diagnosisReport?.followupDate,
      //     });

      //     setTestFormData(
      //       response.testNames.map((obj, index) => {
      //         return { id: index, name: "n" + index, value: obj, showAddBtn: response.testNames.length - 1 == index };
      //       })
      //     );
      //     setSelectedFile({ ...response, file: "" });
      //   })
      //   .catch((error) => {
      //     toast.error(error);
      //     setIsLoading(false);
      //   });
      try {
        // const response = await Promise.race([
        //   AxiosInstance.post("prescription/parse", formData),
        //   new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), 80000))
        // ]);
        // const response = await AxiosInstance.post("prescription/parse", formData);
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}prescription/parse`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + window.sessionStorage.getItem("token"),
          },
        });
  
        console.log("Full response object:", response);
        getPrescriptionRespnse(); // Log full response object
  
        if (!response) {
          toast.error("API response has no data available.");
        } else {
          console.log("Response data:", response); // Log response data
          toast.success("Prescription uploaded successfully");

          // const formattedFollowupDate = response.data.data.diagnosisReport?.followupDate 
          // ? formatDateToInput(response.data.data.diagnosisReport.followupDate) 
          // : null;
  
          
  
          // setSelectedFile({ ...response.data.data, file: "" });
        }
  
        setIsLoading(false);
      } catch (response) {
        console.error("API Error:", response); // Log error
      //   if (!response) {
      //     toast.error("API response has no data available.");
      //   } else {
      //     setMedicalFormData({
      //     ...response,
      //     isOutPatient: medicalFormData.isOutPatient,
      //     diagnosisReport: response.diagnosisReport?.diagnosis,
      //     medicalCondition: response.diagnosisReport?.medicalCondition,
      //     followupDate: response.diagnosisReport?.followupDate,
      //   });
      //   toast.success("Medical History uploaded successfully");
      //   setTestFormData(
      //     (response.testNames || []).map((obj, index) => {
      //       return { id: index, name: "n" + index, value: obj, showAddBtn: response.testNames.length - 1 === index };
      //     })
      //   );

      //   setSelectedFile({ ...response, file: "" });
      // }
        // if (error.message === 'Request timeout') {
        //   toast.error("The request took too long to complete.");
        // } else {
        //   toast.error(error.message || "An error occurred while uploading the file");
        // }
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...medicalFormData };
    if (name.includes("medicineInfo.")) {
      const [parentField, nestedField] = name.split(".");
      updatedFormData[parentField][nestedField] = value;
    } else {
      updatedFormData[name] = value;
    }
    setMedicalFormData(updatedFormData);
  };

  const addMedicinePopUpHandler = () => {
    setAddMedicineFormData([
      {
        medicineName: "",
        strength: "",
        days: 0,
      },
    ]);
    setIsMedicineOpen((prev) => !prev);
  };

  const AddMedicineFormHandler = (index, event) => {
    let data = [...addMedicineFormData];
    data[index][event.target.name] = event.target.value;
    setAddMedicineFormData(data);
  };

  const addMedicineFieldsHandler = () => {
    let newfield = {
      medicineName: "",
      strength: "",
      days: 0,
    };

    const foundIndexOfMadicineInfo = addMedicineFormData.findIndex((element) => element.medicineName == "" || element.strength == "" || element.days == "");

    if (foundIndexOfMadicineInfo == -1) {
      setAddMedicineFormData([...addMedicineFormData, newfield]);
      setFormErrors(false);
    }
  };

  useEffect(() => {
    if (isMedicineOpen == false) {
      setFormErrors(false);
    }

    if (isOpen == false) {
      setFormErrors(false);
      setSelectedFile("");
      setMedicalFormData({
        hospitalVisitDate: "",
        isOutPatient: true,
        admissionDate: "",
        releaseDate: "",
        hospitalName: "",
        city: "",
        physicianName: "",
        medicalCondition: "",
        diagnosis: "",
        followupDate: "",
        followupComment: "",
        file: "",
        testNames: [],
        initialExamination: {
          bpDia: "",
          bpSys: "",
          comment: "",
          height: "",
          weight: "",
          spo2reading: "",
          temperature: "",
          pulse: "",
          respiratoryRate: "",
        },
        diagnosisReport: {
          allergies: "",
          allergiesFlag: "",
          diagnosis: "",
          followupDate: "",
          healthAlert: "",
          medicalCondition: "",
          notes: "",
          symptoms: "",
        },
        medicineInfo: [
          {
            medicineName: "",
            strength: "",
            days: 0,
          },
        ],
      });
    }
  }, [isOpen]);

  const addMedicineSubmitHandler = () => {
    let errors = {};
    {
      addMedicineFormData?.map((input, index) => {
        let data = [...addMedicineFormData][index];
        errors = {};
        if (data?.medicineName?.trim() == "") {
          errors.medicineName = "Medicine Name is required";
        }
        if (/[^a-zA-Z0-9_\s]/?.test(data?.medicineName)) {
          errors.medicineName = "Medicine Name can only contain alphanumeric or a combination of alphabets.";
        }
        if (data?.strength?.trim() == "") {
          errors.strength = "Strength is required";
        }
        if (/[^a-zA-Z0-9_\s]/?.test(data?.strength)) {
          errors.strength = "Strength can only contain alphanumeric or a combination of alphabets.";
        }
        if (data?.days == "") {
          errors.days = "Days is required";
        }
        if (data?.days < 0) {
          errors.days = "Days can not contain Negative Value";
        }
        if (data?.days == -0) {
          errors.days = "Days is required";
        }
      });
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    medicalFormData.medicineInfo = [...medicalFormData.medicineInfo, ...addMedicineFormData];
    toast.success("Medicine Added Successfully");
    setIsMedicineOpen((prev) => !prev);
  };

  const addTestFormHandler = (inputObj, event) => {
    inputObj.value = event.target.value;
    setTestFormData(
      addTestFormData.map((ele) => {
        if (ele.id == inputObj.id) {
          ele.value = event.target.value;
        }
        return ele;
      })
    );
  };

  const addTestFieldsHandler = () => {
    let newfield = { id: addTestFormData.length + 1, name: "n" + addTestFormData.length + 1, value: "", showAddBtn: true };
    let lastelement = addTestFormData[addTestFormData.length - 1];
    setTestFormData([
      ...addTestFormData.map((ele) => {
        if (ele.id == lastelement.id) {
          ele.showAddBtn = false;
        }
        return ele;
      }),
      newfield,
    ]);
  };

  const removeTestFieldsHandler = (inputObj, event) => {
    const itemRemove = inputObj.id;
    setTestFormData((current) => current.filter((test) => test.id !== itemRemove));
  };

  const addMedicalHistorySubmitHandler = async (event) => {
    event.preventDefault();
    const errors = {};
    if (medicalFormData?.isOutPatient == true) {
      if (!medicalFormData?.hospitalVisitDate || !medicalFormData?.hospitalVisitDate.trim()) {
        errors.hospitalVisitDate = "Hospital Visit Date is required";
      }
    }

    if (!selectedFile) {
      errors.file = "Please attach your Prescription copy";
    }

    if (medicalFormData?.hospitalName === null || !medicalFormData?.hospitalName?.trim()) {
      errors.hospitalName = "Hospital Name is required";
    }
    if (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.hospitalName)) {
      errors.hospitalName = "Hospital Name can only contain alphabets and numbers. It must start with a letter.";
    }
    if (/[^a-zA-Z_\s,]/.test(medicalFormData?.city)) {
      errors.city = "City Name can only contain alphabets.";
    }
    if (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.physicianName)) {
      errors.physicianName = "Physician Name can only contain alphabets and numbers. It must start with a letter.";
    }
    if (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData?.diagnosis)) {
      errors.diagnosis = "Diagnosis / Remarks From Doctor can only contain alphabets and numbers. It must start with a letter.";
    }
    if (!medicalFormData?.medicalCondition || !medicalFormData?.medicalCondition.trim()) {
      errors.medicalCondition = "Medical Condition is required";
    }
    if (!/^[a-zA-Z][a-zA-Z0-9_\s,]*$/.test(medicalFormData?.medicalCondition)) {
      errors.medicalCondition = "Medical Condition can only contain alphabets, numbers, spaces, underscores, and commas. It must start with a letter.";
    }
    if (!medicalFormData?.followupDate || !medicalFormData?.followupDate.trim()) {
      errors.followupDate = "Followup Date is required";
    }
    if (medicalFormData?.isOutPatient == false || medicalFormData?.isOutPatient == false) {
      if (!medicalFormData?.admissionDate || !medicalFormData?.admissionDate.trim()) {
        errors.admissionDate = "Admission Date is required";
      }
      if (!medicalFormData?.releaseDate || !medicalFormData?.releaseDate.trim()) {
        errors.releaseDate = "Release Date is required";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      toast.error("Please fill all mandatory fields");
      return;
    }

    medicalFormData.testNames = addTestFormData?.map((ele) => ele.value);
    let payLoad = new FormData();
    payLoad.append("hospitalVisitDate", medicalFormData?.isOutPatient == false ? medicalFormData?.admissionDate : medicalFormData?.hospitalVisitDate);
    if (!medicalFormData.isOutPatient) {
      payLoad.append("admissionDate", medicalFormData?.admissionDate);
      payLoad.append("releaseDate", medicalFormData?.releaseDate);
    }
    payLoad.append("isOutPatient", medicalFormData?.isOutPatient);
    payLoad.append("hospitalName", medicalFormData?.hospitalName);
    payLoad.append("city", medicalFormData?.city);
    payLoad.append("testNames", medicalFormData?.testNames);
    payLoad.append("physicianName", medicalFormData?.physicianName);
    payLoad.append("medicineInfo", JSON.stringify(medicalFormData?.medicineInfo));
    payLoad.append("medicalCondition", medicalFormData?.medicalCondition);
    payLoad.append("diagnosis", medicalFormData?.diagnosis);
    payLoad.append("followupDate", medicalFormData?.followupDate);
    payLoad.append("followupComment",medicalFormData?.followupComment);
    payLoad.append("initialExamination", JSON.stringify(medicalFormData?.initialExamination));
    payLoad.append("diagnosisReport", JSON.stringify(medicalFormData?.diagnosisReport));
    payLoad.append("file", selectedFile);
    if (isReportCopy == "true") {
      payLoad.append("file", selectedFile);
    }
    if (otherdocIds.length > 0) {
      payLoad.append("otherDocumentIds", otherdocIds);
    }
    console.log("payload" , payLoad);
    await AxiosInstance.post("visit/save", payLoad, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      setIsOpen((prev) => !prev);
      // getHospitalVisitList();
      toast.success("Medical History uploaded successfully");
      setMedicalFormData({
        hospitalVisitDate: "",
        isOutPatient: true,
        admissionDate: "",
        releaseDate: "",
        hospitalName: "",
        city: "",
        physicianName: "",
        medicalCondition: "",
        diagnosis: "",
        followupDate: "",
        followupComment: "",
        file: "",
        testNames: [],
        initialExamination: {
          bpDia: "",
          bpSys: "",
          comment: "",
          height: "",
          weight: "",
          spo2reading: "",
          temperature: "",
          pulse: "",
          respiratoryRate: "",
        },
        diagnosisReport: {
          allergies: "",
          allergiesFlag: "",
          diagnosis: "",
          followupDate: "",
          healthAlert: "",
          medicalCondition: "",
          notes: "",
          symptoms: "",
        },
        medicineInfo: [
          {
            medicineName: "",
            strength: "",
            days: 0,
          },
        ],
      });
      setSelectedFile(null);
      setTestFormData([{ id: 1, name: "n1", value: "", showAddBtn: true }]);
      setIsReportCopy("");
    });
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setFilterDate(selectedDate);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getHospitalVisitList = () => {
    setIsLoading(true);
    AxiosInstance.get("visit/list")
      .then((response) => {
        console.log("response: ", response);
        setIsDummyData(response[0].sampleData);
        if (response != undefined) {
          setHospitalVisitList(response);
        } else {
          toast.error("No Response Received");
        }
        setIsLoading(false);
        setFilterDate("");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // useEffect(() => {
  //   getHospitalVisitList();
  // }, []);

  const ref = useRef(null);

  useEffect(() => {
    if (formErrors) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    }
  }, [formErrors]);

  const today = new Date().toISOString().split("T")[0];

  const getNextDay = (dateString) => {
    if (!dateString) return; // Handle case where hospitalVisitDate is not set
    const hospitalVisitDate = new Date(dateString);
    const nextDay = new Date(hospitalVisitDate);
    nextDay.setDate(nextDay.getDate() + 1);
    const nextDayString = nextDay.toISOString().split("T")[0];
    return nextDayString;
  };

  const columns = [
    { id: "visitId", label: "Visit ID", isLink: true },
    { id: "hospitalVisitDate", label: "Visit Date", isLink: false },
    { id: "isOutPatient", label: "Visit Type", isLink: false },
    { id: "hospitalName", label: "Hospital Name", isLink: false },
    { id: "physicianName", label: "Physician Name", isLink: false },
    { id: "medicineInfo", label: "Medicine Info", isArray: true, isLink: false },
    { id: "testNames", label: "Test Names", isArray: true, isLink: false },
    { id: "diagnosis", label: "Medical Condition", isLink: false },
  ];

  const handleLinkClick = (visitId, visitDate) => {
    navigate(`/case-history?visitId=${visitId}&visitDate=${visitDate}`);
  };

  useEffect(() => {
    if (!!selectedRowData && selectedRowData.columnName === "visitId") {
      handleLinkClick(selectedRowData.rowData.visitId, selectedRowData.rowData.hospitalVisitDate);
    }
  }, [selectedRowData]);


// const handleFileSubmit = async (event) => {
//   event.preventDefault();

//   // const formData = new FormData();
//   // formData.append("file_name", "3.pdf");
//   const fileName = "3.pdf";

//   try {
//     const res = await AxiosInstance.post(`lipid-profile-parser${fileName}`);
//     // setResponse(res.data);
//       if (!response) {
//         toast.error("API response has no data available.");
//       } else {
//         toast.success("Medical History uploaded successfully");
//       }
//       setIsLoading(false);
//   } catch (error) {
//     console.error("Error calling the API", error);
//   }
// };
const handleFileSubmit = async (event) => {
  event.preventDefault();
  // const file = event.target.files[0];
  // const fileName = "3.pdf";
  console.log("tokenn", window.sessionStorage.getItem("token"));
  setisLipidLoader(true)
  try {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}prescription/lipid-profile-parser/3.pdf`,null, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + window.sessionStorage.getItem("token"),
    },
  });
  console.log("responseFileeee: ", response?.data?.data);
  setFileResponse(response?.data?.data);
  toast.success("Report Uploaded");
  setisLipidLoader(false)
  }
  catch(error) {
    console.error("Api error",error);
    setisLipidLoader(false);
  }
};
  console.log("fileresponse", fileResponse)
  console.log("medical", medicalFormData)

  return (
    <>
      <PageTitle pageTitle={strings?.medicalHistory?.createYourOwnMHR} />
      <Row style={{ alignItems: "end", marginBottom: "20px" }}>
        <Col md={8}>
          <div className="text-content mb-0">
            <p className="text-style" style={{ fontSize: "18px" }}>
              {strings?.medicalHistory?.pleaseAdd34MedicalHistory}
            </p>
          </div>
        </Col>
        {/* <Col md={4} className="upload-button mt-3" style={{ border: "2px solid lightgrey", borderRadius: "12px", padding: "10px" }}>
          {isLipidLoader == true ? (
              <div>
                <Spinner />
                <br />
                <p>Please wait your Lipid Test Report is uploading</p>
              </div>
            ) : (
              <>
                <span>
                <label
                  style={{
                    cursor: "pointer",
                    color: "rgb(83, 83, 233)",
                    textDecoration: "underline",
                  }}
                  htmlFor="file-upload-input" // add an ID to the input field and reference it here
                >
                  Attach Report
                </label>

            <div>
              <input
                id="file-upload-input" // add an ID to the input field
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                className="file-upload-input"
                onChange={(event) => handleFileSubmit(event)}
                style={{ display: "none" }}
              />
            </div>
                </span>
              </>
            )}
          
          {fileResponse && <span style={{ marginTop: "-8%"}}>
            <Row>
              <Col lg={6}><b>HDL Cholesterol</b> : {fileResponse?.hdlCholesterol}</Col>
              <Col lg={6}><b>LDL Cholesterol</b> : {fileResponse?.ldlCholesterol} </Col>
            </Row>
            <Row>
              <Col lg={6}> <b>Total Cholesterol</b> : {fileResponse?.totalCholesterol}</Col>
              <Col lg={6}><b>Triglycerides</b> : {fileResponse?.triglycerides}</Col>
            </Row>
            </span>}
           
        </Col> */}
      </Row>
      <ModelPopUp isOpen={isMedicineOpen} setIsOpen={setIsMedicineOpen} handleSubmit={addMedicineSubmitHandler} heading="Add Medicine">
        <div className="add-medicine-table-form">
          <div className="add-medicine-btn">
            <button type="button" className="universal-btn" onClick={addMedicineFieldsHandler}>
              + Add Medicine
            </button>
          </div>
          <div className="add-medicine-form-head">
            <label>Medicine name</label>
            <label>Strength</label>
            <label>No. of Days</label>
          </div>
          {addMedicineFormData?.map((input, index) => {
            return (
              <div className="add-medicine-form-inputs" key={`index - ${index}`}>
                <div className={`medicine-input ${formErrors?.medicineName && input?.medicineName == "" ? "required-border" : ""}`}>
                  <input name="medicineName" placeholder="Medicine Name" value={input?.medicineName} onChange={(event) => AddMedicineFormHandler(index, event)} autoComplete="off" />
                  {input?.medicineName == "" || "/[^a-zA-Z0-9_s]/" || formErrors?.medicineName ? <p className="error">{formErrors?.medicineName}</p> : ""}
                </div>
                <div className={`medicine-input ${formErrors?.strength && input?.strength == "" ? "required-border" : ""}`}>
                  <input type="text" name="strength" placeholder="Strength" value={input?.strength} onChange={(event) => AddMedicineFormHandler(index, event)} autoComplete="off" />
                  {input?.strength == "" || "/[^a-zA-Z0-9_s]/" || formErrors?.strength ? <p className="error">{formErrors?.strength}</p> : ""}
                </div>
                <div className={`medicine-input ${formErrors?.days && input?.days == "" ? "required-border" : ""}`}>
                  <input type="number" name="days" placeholder="Days" value={input?.days} onChange={(event) => AddMedicineFormHandler(index, event)} autoComplete="off" min="0" />
                  {input?.days == "" || formErrors?.days ? <p className="error">{formErrors?.days}</p> : ""}
                </div>
              </div>
            );
          })}
        </div>
      </ModelPopUp>
      <form encType="multipart/form-data" ref={ref} onSubmit={addMedicalHistorySubmitHandler}>
        <div className="visit-type-wrapper">
          <p className="input-label">Visit Type</p>
          <div className="visit-types">
            <label className="custom-radio">
              <input type="radio" name="isOutPatient" value={true} defaultChecked={medicalFormData.isOutPatient} onChange={handleInputChange} />
              <div className="radio-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
                  <path d="M1 3.99992L3.83 6.82992L9.5 1.16992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              Outpatient (OPD)
            </label>
            <label className="custom-radio">
              <input type="radio" name="isOutPatient" value={false} onChange={handleInputChange} />
              <div className="radio-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
                  <path d="M1 3.99992L3.83 6.82992L9.5 1.16992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              Inpatient/Hospitalized
            </label>
          </div>
        </div>
        {/* <div className="visit-type-wrapper">
            <p className="input-label">
              {medicalFormData?.isOutPatient == "true" ? "Do you have a copy of your prescription report or summary." : "Do you have a copy of your hospital discharge/ Release report or summary."}
            </p>
            <div className="visit-types">
              <label className="custom-radio">
                <input
                  type="radio"
                  id="yes"
                  name="isReportCopy"
                  value={true}
                  checked={isReportCopy == "true"}
                  onChange={handleIsHospitalCopyChange}
                />
                <div className="radio-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M1 3.99992L3.83 6.82992L9.5 1.16992"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Yes
              </label>
              <label className="custom-radio">
                <input
                  type="radio"
                  id="no"
                  name="isReportCopy"
                  value={false}
                  onChange={handleIsHospitalCopyChange}
                  checked={isReportCopy == "false"}
                />
                <div className="radio-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M1 3.99992L3.83 6.82992L9.5 1.16992"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                No
              </label>
            </div>
          </div> */}

        {/* {isReportCopy == "true" ? ( */}
        <>
          <p style={{ marginTop: "30px" }}>
            {medicalFormData?.isOutPatient == true ? strings?.addMedicalHistorySheet?.pleaseAttachPrescription : strings?.addMedicalHistorySheet?.pleaseAttachDischarge}
          </p>
          <div className="medical-file-upload">
            <input type="file" accept=".jpg, .jpeg, .pdf, .png" id="fileUpload" className="file-upload-input" name="file" onChange={handleFileChange} onClick={onInputClick} />
            {isLoading == true ? (
              <div>
                <Spinner />
                <br />
                <p>Please wait your prescription is uploading</p>
              </div>
            ) : (
              <>
                <img src={VectorPlusPurple} alt={VectorPlusPurple} />
                Attach
              </>
            )}
          </div>
          {selectedFile == "" ? <p className="error">{formErrors?.file}</p> : ""}
          <div>{selectedFile?.name == 0 ? "(Upload pdf , jpeg or png)" : selectedFile?.name}</div>
          <p className="sample-report">
            <a href="#" onClick={() => openModal()}>
              <img src={iconAttachment} alt={iconAttachment} />
              <span className="link-button-color">Sample report</span>
            </a>
            .
          </p>
          {isModalOpen && <SampleReport isOpen={isModalOpen} onClose={closeModal} setIsModalOpen={setIsModalOpen} showSampleReport={true} />}
        </>
        {/* ) : (
            ""
          )} */}
        {/* {isReportCopy == "true" ? ( */}
        <div className="medical-form-wrapper">
          {medicalFormData?.isOutPatient ? (
            <>
              <div className="inline-input-wrapper input-item-space">
                <div className={`medical-input-box input-date-icon ${!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate ? "required-border" : ""}`}>
                  <label className="input-label">Hospital Visit Date *</label>
                  <input
                    className="input-box"
                    type="date"
                    name="hospitalVisitDate"
                    value={medicalFormData?.hospitalVisitDate}
                    placeholder="Select Date"
                    onChange={handleInputChange}
                    max={today}
                    minDate={new Date()} // Optionally set minimum date
                    onKeyDown={(e) => e.preventDefault()}
                    autoFocus
                  />
                </div>
              </div>
              {!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate !== undefined && formErrors?.hospitalVisitDate !== null ? (
                <p className="error">{formErrors?.hospitalVisitDate}</p>
              ) : null}
              {/* {medicalFormData?.hospitalVisitDate == "" && formErrors?.hospitalVisitDate ? <p className="error">{formErrors?.hospitalVisitDate}</p> : ""}  */}
            </>
          ) : (
            <div className="inline-input-wrapper input-item-space">
              <div className={`medical-input-box input-date-icon ${!medicalFormData?.admissionDate && formErrors?.admissionDate ? "required-border" : ""}`}>
                <label className="input-label">Admission Date *</label>
                <input className="input-box" type="date" name="admissionDate" value={medicalFormData?.admissionDate || ""} placeholder="Select Date" onChange={handleInputChange} max={today} />
                {!medicalFormData?.admissionDate && formErrors?.admissionDate !== undefined && formErrors?.admissionDate !== null ? <p className="error">{formErrors?.admissionDate}</p> : null}
              </div>
              <div className={`medical-input-box input-date-icon ${!medicalFormData?.releaseDate && formErrors?.releaseDate ? "required-border" : ""}`}>
                <label className="input-label">Release Date *</label>
                <input
                  className="input-box"
                  type="date"
                  name="releaseDate"
                  value={medicalFormData?.releaseDate}
                  placeholder="Select Date"
                  onChange={handleInputChange}
                  min={medicalFormData?.admissionDate}
                />
                {medicalFormData?.releaseDate == "" || (medicalFormData?.releaseDate === null && formErrors?.releaseDate) ? <p className="error">{formErrors?.releaseDate}</p> : ""}
              </div>
            </div>
          )}
          <div className={`medical-input-box input-item-space ${!medicalFormData?.hospitalName && formErrors?.hospitalName ? "required-border" : ""}`}>
            <label className="input-label">Hospital Name *</label>
            <input className={`input-box`} type="text" value={medicalFormData?.hospitalName} name="hospitalName" placeholder="Hospital Name" onChange={handleInputChange} autoFocus />
            {!medicalFormData?.hospitalName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.hospitalName) && formErrors?.hospitalName) ? (
              <p className="error">{formErrors?.hospitalName}</p>
            ) : (
              ""
            )}
          </div>
          <div className={`medical-input-box input-item-space ${!medicalFormData?.city && formErrors?.city ? "required-border" : ""}`}>
            <label className="input-label">Hospital City</label>
            <input className="input-box" type="text" name="city" value={medicalFormData?.city} placeholder="Hospital Address" onChange={handleInputChange} />
            {!medicalFormData?.city || (!/^[a-zA-Z_\s,]*$/.test(medicalFormData?.city) && formErrors?.city) ? <p className="error">{formErrors?.city}</p> : ""}
          </div>
          <div className={`medical-input-box input-item-space ${!medicalFormData?.physicianName && formErrors?.physicianName ? "required-border" : ""}`}>
            <label className="input-label">Physician/Doctor Name</label>
            <input className="input-box" type="text" name="physicianName" value={medicalFormData?.physicianName} placeholder="Physician/Doctor Name" onChange={handleInputChange} />
            {!medicalFormData?.physicianName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.physicianName) && formErrors?.physicianName) ? (
              <p className="error">{formErrors?.physicianName}</p>
            ) : (
              ""
            )}
          </div>
          <div className={`medical-input-box input-item-space ${!medicalFormData?.medicalCondition && formErrors?.medicalCondition ? "required-border" : ""}`}>
            <label className="input-label">Medical condition / Issue *</label>
            <input className="input-box" type="text" name="medicalCondition" value={medicalFormData?.medicalCondition} placeholder="Medical condition / Issue" onChange={handleInputChange} />
          </div>
          {!medicalFormData?.medicalCondition || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData.medicalCondition) && formErrors?.medicalCondition) ? (
            <p className="error">{formErrors?.medicalCondition}</p>
          ) : (
            ""
          )}
          <div className={`medical-input-box input-item-space ${!medicalFormData?.diagnosis && formErrors?.diagnosis ? "required-border" : ""}`}>
            <label className="input-label">Diagnosis / Remarks from Doctor</label>
            <input className="input-box" type="text" name="diagnosis" value={medicalFormData?.diagnosis} placeholder="Diagnosis / Remarks from Doctor" onChange={handleInputChange} />
            {!medicalFormData?.diagnosis || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData?.diagnosis) && formErrors?.diagnosis) ? <p className="error">{formErrors?.diagnosis}</p> : ""}
          </div>
          <div className={`medical-input-box input-date-icon input-item-space ${!medicalFormData?.followupDate && formErrors?.followupDate ? "required-border" : ""}`}>
            <label className="input-label">Follow up Date *</label>
            <input
              className="input-box"
              type="date"
              name="followupDate"
              value={medicalFormData?.followupDate}
              placeholder="Follow up Date"
              onChange={handleInputChange}
              min={getNextDay(medicalFormData?.hospitalVisitDate)}
              minDate={new Date()} // Optionally set minimum date
              onKeyDown={(e) => e.preventDefault()}
            />
            {!medicalFormData?.followupDate && formErrors?.followupDate ? <p className="error">{formErrors?.followupDate}</p> : ""}
          </div>
          <div className={`medical-input-box input-date-icon input-item-space`}>
                <label className="input-label">Follow up Comment</label>
                <input
                  className="input-box"
                  type="text"
                  name="followupComment"
                  value={medicalFormData?.followupComment}
                  placeholder="Follow up Comment"
                  onChange={handleInputChange}
                />
          </div>
          <br />
          <div className="input-plus-minus-wrapper">
            <label className="input-label">Any tests prescribed by Doctor during this visit?</label>
            {addTestFormData?.map((obj, index) => {
              return (
                <div className="medical-input-box input-item-space input-plus-minus" key={index}>
                  <input
                    className="input-box"
                    type="text"
                    id={obj.id}
                    name={obj.name}
                    placeholder="Test"
                    onChange={(event) => {
                      const textValue = event.target.value.replace(/[0-9]/g, "");
                      addTestFormHandler(obj, { ...event, target: { ...event.target, value: textValue } });
                    }}
                    value={obj.value}
                  />
                  {obj.showAddBtn && (
                    <button type="button" className={!obj.value ? "disableAddBtn" : "enableAddBtn"} disabled={!obj.value ? true : false} onClick={addTestFieldsHandler}>
                      <img src={iconPlusGreen} />
                    </button>
                  )}
                  {!!obj.value && !obj.showAddBtn && (
                    <button className="addTestRemoveBtn" type="button" onClick={(event) => removeTestFieldsHandler(obj, event)}>
                      <img src={iconMinusSquare} />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          <div className="add-medicine-button-wrapper">
            <button type="button" className="add-medicine-button" onClick={addMedicinePopUpHandler}>
              <img src={VectorPlusPurple} />
              Add Medicine
            </button>
          </div>
          <div className="medicine-list-wrapper">
            {medicalFormData?.medicineInfo?.map((item, index) => {
              if (item?.medicineName !== "") {
                return (
                  <div className="medicine-item" key={index}>
                    <div className="medicine-type">
                      <p className="medicine-title">Medicine Name</p>
                      <p className="medicine-name">{item?.medicineName}</p>
                    </div>
                    <div className="medicine-doss">
                      <p className="medicine-mg">{item?.strength}</p>
                      <p className="medicine-days">{item?.days} Days</p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        {/* ) : (
            ""
          )} */}
        {/* {isReportCopy == "false" ? <p>Please fill the below form</p> : ""} */}
        {/* {isReportCopy == "false" ? (
            <div className="medical-form-wrapper">
              {medicalFormData.isOutPatient == "true" ? (
                <div className="inline-input-wrapper input-item-space">
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate ? "required-border" : ""}`}>
                    <label className="input-label">Hospital Visit Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      value={medicalFormData?.hospitalVisitDate}
                      name="hospitalVisitDate"
                      onChange={handleInputChange}
                      max={today}
                      minDate={new Date()} // Optionally set minimum date
                      onKeyDown={(e) => e.preventDefault()}
                    />
                    {!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate !== undefined && formErrors?.hospitalVisitDate !== null ? (
                      <p className="error">{formErrors?.hospitalVisitDate}</p>
                    ) : null}
                     {medicalFormData?.hospitalVisitDate == "" && formErrors?.hospitalVisitDate ? <p className="error">{formErrors?.hospitalVisitDate}</p> : ""} 
                   </div>
                </div>
              ) : (
                <div className="inline-input-wrapper input-item-space">
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.admissionDate && formErrors?.admissionDate ? "required-border" : ""}`}>
                    <label className="input-label">Admission Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      name="admissionDate"
                      value={medicalFormData?.admissionDate}
                      placeholder="Select Date"
                      onChange={handleInputChange}
                      max={today}
                    />
                    {!medicalFormData?.admissionDate && formErrors?.admissionDate ? <p className="error">{formErrors?.admissionDate}</p> : null}
                  </div>
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.releaseDate && formErrors?.releaseDate ? "required-border" : ""}`}>
                    <label className="input-label">Release Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      name="releaseDate"
                      value={medicalFormData?.releaseDate}
                      placeholder="Select Date"
                      onChange={handleInputChange}
                      min={medicalFormData?.admissionDate}
                    />
                    {medicalFormData?.releaseDate == "" || (medicalFormData?.releaseDate === null && formErrors?.releaseDate) ? <p className="error">{formErrors?.releaseDate}</p> : ""}
                  </div>
                </div>
              )}
              <div className={`medical-input-box input-item-space ${!medicalFormData?.hospitalName && formErrors?.hospitalName ? "required-border" : ""}`}>
                <label className="input-label">Hospital Name *</label>
                <input
                  className="input-box"
                  type="text"
                  value={medicalFormData?.hospitalName}
                  name="hospitalName"
                  placeholder="Hospital Name"
                  onChange={handleInputChange}
                  autoFocus
                />
                {!medicalFormData?.hospitalName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.hospitalName) && formErrors?.hospitalName) ? (
                  <p className="error">{formErrors?.hospitalName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.city && formErrors?.city ? "required-border" : ""}`}>
                <label className="input-label">Hospital City</label>
                <input
                  className="input-box"
                  type="text"
                  name="city"
                  value={medicalFormData?.city}
                  placeholder="Hospital Address"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.city || (!/^[a-zA-Z_\s,]*$/.test(medicalFormData?.city) && formErrors?.city) ? <p className="error">{formErrors?.city}</p> : ""}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.physicianName && formErrors?.physicianName ? "required-border" : ""}`}>
                <label className="input-label">Physician/Doctor Name</label>
                <input
                  className="input-box"
                  type="text"
                  name="physicianName"
                  value={medicalFormData?.physicianName}
                  placeholder="Physician/Doctor Name"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.physicianName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.physicianName) && formErrors?.physicianName) ? (
                  <p className="error">{formErrors?.physicianName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.medicalCondition && formErrors?.medicalCondition ? "required-border" : ""}`}>
                <label className="input-label">Medical condition / Issue</label>
                <input
                  className="input-box"
                  type="text"
                  name="medicalCondition"
                  value={medicalFormData?.medicalCondition}
                  placeholder="Medical condition / Issue"
                  onChange={handleInputChange}
                />
              </div>
              {!medicalFormData?.medicalCondition || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData.medicalCondition) && formErrors?.medicalCondition) ? (
                <p className="error">{formErrors?.medicalCondition}</p>
              ) : (
                ""
              )}
              <div className={`medical-input-box input-item-space ${!medicalFormData?.diagnosis && formErrors?.diagnosis ? "required-border" : ""}`}>
                <label className="input-label">Diagnosis / Remarks from Doctor</label>
                <input
                  className="input-box"
                  type="text"
                  name="diagnosis"
                  value={medicalFormData?.diagnosis}
                  placeholder="Diagnosis / Remarks from Doctor"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.diagnosis || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData?.diagnosis) && formErrors?.diagnosis) ? <p className="error">{formErrors?.diagnosis}</p> : ""}
              </div>
              <div className={`medical-input-box input-date-icon input-item-space ${!medicalFormData?.followupDate && formErrors?.followupDate ? "required-border" : ""}`}>
                <label className="input-label">Follow up Date *</label>
                <input
                  className="input-box"
                  type="date"
                  name="followupDate"
                  value={medicalFormData?.followupDate}
                  placeholder="Follow up Date"
                  onChange={handleInputChange}
                  min={getNextDay(medicalFormData?.hospitalVisitDate)}
                />
                {!medicalFormData?.followupDate && formErrors?.followupDate ? <p className="error">{formErrors?.followupDate}</p> : ""}
              </div>
              <br />
              <div className="input-plus-minus-wrapper">
                <label className="input-label">Any tests prescribed by Doctor during this visit?</label>
                {addTestFormData?.map((obj, index) => {
                  return (
                    <div
                      className="medical-input-box input-item-space input-plus-minus"
                      key={index}
                    >
                      <input
                        className="input-box"
                        type="text"
                        id={obj.id}
                        name={obj.name}
                        placeholder="Test"
                        onChange={(event) => {
                          const textValue = event.target.value.replace(/[0-9]/g, "");
                          addTestFormHandler(obj, { ...event, target: { ...event.target, value: textValue } });
                        }}
                        value={obj.value}
                      />
                      {obj.showAddBtn && (
                        <button
                          type="button"
                          className={!obj.value ? "disableAddBtn" : "enableAddBtn"}
                          disabled={!obj.value ? true : false}
                          onClick={addTestFieldsHandler}
                        >
                          <img src={iconPlusGreen} />
                        </button>
                      )}
                      {!!obj.value && !obj.showAddBtn && (
                        <button
                          className="addTestRemoveBtn"
                          type="button"
                          onClick={(event) => removeTestFieldsHandler(obj, event)}
                        >
                          <img src={iconMinusSquare} />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="add-medicine-button-wrapper">
                <button
                  type="button"
                  className="add-medicine-button"
                  onClick={addMedicinePopUpHandler}
                >
                  <img src={VectorPlusPurple} />
                  Add Medicine
                </button>
              </div>
              <div className="medicine-list-wrapper">
                {medicalFormData?.medicineInfo.map((item, index) => {
                  if (item?.medicineName !== "") {
                    return (
                      <div
                        className="medicine-item"
                        key={index}
                      >
                        <div className="medicine-type">
                          <p className="medicine-title">Medicine Name</p>
                          <p className="medicine-name">{item.medicineName}</p>
                        </div>
                        <div className="medicine-doss">
                          <p className="medicine-mg">{item.strength}</p>
                          <p className="medicine-days">{item.days}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            ""
        )} */}
        <AddMedicalReports hospitalVisitList={hospitalVisitList} setOtherdocIds={setOtherdocIds} otherdocIds={otherdocIds} isOpen={isOpen} />
        <center>
          {" "}
          <button className="universal-btn" style={{ minWidth: "20%" }} type="submit">
            Submit
          </button>
        </center>
      </form>

      {/* {hospitalVisitList?.slice(0, 1).map((isDummyData, index) => {
        if (isDummyData?.sampleData == true) {
          return (
            <SampleIllustration
              key={index}
              titleHeader={strings?.sampleIllustrationMedicalHistory?.title}
              noteText1={strings?.sampleIllustrationMedicalHistory?.theInformationPresented}
              sampleText={strings?.sampleIllustrationMedicalHistory?.sampleBasedOnDummyData}
              noteText2={strings?.sampleIllustrationMedicalHistory?.andIsShownJustToGIveYouAnIdea}
            />
          );
        }
      })} */}
      {/* {hospitalVisitList !== undefined ? (
        <MedicalHistoryTimeline
          hospitalVisitList={hospitalVisitList != undefined ? hospitalVisitList : hospitalVisitList}
          isDummyData={hospitalVisitList?.length > 0 ? false : true}
        />
      ) : (
        ""
      )}  */}
      {/* <Row className="mb-4">
        <Col></Col>
        <Col>
          {hospitalVisitList?.length > 3 ? (
            <>
              <span className="date-filter-box input-date-icon date-type-field-small">
                <label>Filter by Date</label>
                <input
                  type="date"
                  name="filterDate"
                  value={filterDate}
                  onChange={handleDateChange}
                />
                <span onClick={getHospitalVisitList}>
                  <GrRefresh className="refresh-list" />
                </span>
              </span>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>  */}

      {/* {hospitalVisitList && (
        <div className="hospital-visit-list">
          <MUITable
            isLoading={isLoading}
            filterDate={filterDate}
            data={hospitalVisitList}
            columns={columns}
            setSelectedRowData={setSelectedRowData}
          />
        </div>
      )}  */}

      {/* <ModelPopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={addMedicalHistorySubmitHandler}
        heading="Add Medical History"
      >
        <form
          encType="multipart/form-data"
          ref={ref}
        >
          <div className="visit-type-wrapper">
            <p className="input-label">Visit Type</p>
            <div className="visit-types">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="isOutPatient"
                  value={"true"}
                  defaultChecked={medicalFormData.isOutPatient}
                  onChange={handleInputChange}
                />
                <div className="radio-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M1 3.99992L3.83 6.82992L9.5 1.16992"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Outpatient
              </label>
              <label className="custom-radio">
                <input
                  type="radio"
                  name="isOutPatient"
                  value={"false"}
                  onChange={handleInputChange}
                />
                <div className="radio-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M1 3.99992L3.83 6.82992L9.5 1.16992"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Inpatient/Hospitalized
              </label>
            </div>
          </div>
          <div className="visit-type-wrapper">
            <p className="input-label">
              {medicalFormData?.isOutPatient == "true" ? "Do you have a copy of your prescription report or summary." : "Do you have a copy of your hospital discharge/ Release report or summary."}
            </p>
            <div className="visit-types">
              <label className="custom-radio">
                <input
                  type="radio"
                  id="yes"
                  name="isReportCopy"
                  value={true}
                  checked={isReportCopy == "true"}
                  onChange={handleIsHospitalCopyChange}
                />
                <div className="radio-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M1 3.99992L3.83 6.82992L9.5 1.16992"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Yes
              </label>
              <label className="custom-radio">
                <input
                  type="radio"
                  id="no"
                  name="isReportCopy"
                  value={false}
                  onChange={handleIsHospitalCopyChange}
                  checked={isReportCopy == "false"}
                />
                <div className="radio-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M1 3.99992L3.83 6.82992L9.5 1.16992"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                No
              </label>
            </div>
          </div>

          {isReportCopy == "true" ? (
            <>
              <p>{medicalFormData?.isOutPatient == "true" ? strings?.addMedicalHistorySheet?.pleaseAttachPrescription : strings?.addMedicalHistorySheet?.pleaseAttachDischarge}</p>
              <div className="medical-file-upload">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .pdf, .png"
                  id="fileUpload"
                  className="file-upload-input"
                  name="file"
                  onChange={handleFileChange}
                  onClick={onInputClick}
                />
                {isLoading == true ? (
                  <div>
                    <Spinner />
                    <br />
                    <p>Please wait your prescription is uploading</p>
                  </div>
                ) : (
                  <>
                    <img
                      src={VectorPlusPurple}
                      alt={VectorPlusPurple}
                    />
                    Attach
                  </>
                )}
              </div>
              {selectedFile == "" ? <p className="error">{formErrors?.file}</p> : ""}
              <div>{selectedFile?.name == 0 ? "(Upload pdf , jpeg or png)" : selectedFile?.name}</div>
              <p className="sample-report">
                <a
                  href="#"
                  onClick={() => openModal()}
                >
                  <img
                    src={iconAttachment}
                    alt={iconAttachment}
                  />
                  <span className="link-button-color">Sample report</span>
                </a>
              </p>
              {isModalOpen && (
                <SampleReport
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  setIsModalOpen={setIsModalOpen}
                  showSampleReport={true}
                />
              )}
            </>
          ) : (
            ""
          )}
          {isReportCopy == "true" ? (
            <div className="medical-form-wrapper">
              {medicalFormData?.isOutPatient == "true" ? (
                <>
                  <div className="inline-input-wrapper input-item-space">
                    <div className={`medical-input-box input-date-icon ${!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate ? "required-border" : ""}`}>
                      <label className="input-label">Hospital Visit Date *</label>
                      <input
                        className="input-box"
                        type="date"
                        name="hospitalVisitDate"
                        value={medicalFormData?.hospitalVisitDate}
                        placeholder="Select Date"
                        onChange={handleInputChange}
                        max={today}
                        minDate={new Date()} // Optionally set minimum date
                        onKeyDown={(e) => e.preventDefault()}
                        autoFocus
                      />
                    </div>
                  </div>
                  {!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate !== undefined && formErrors?.hospitalVisitDate !== null ? (
                    <p className="error">{formErrors?.hospitalVisitDate}</p>
                  ) : null}
                  {medicalFormData?.hospitalVisitDate == "" && formErrors?.hospitalVisitDate ? <p className="error">{formErrors?.hospitalVisitDate}</p> : ""} 
                </>
              ) : (
                <div className="inline-input-wrapper input-item-space">
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.admissionDate && formErrors?.admissionDate ? "required-border" : ""}`}>
                    <label className="input-label">Admission Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      name="admissionDate"
                      value={medicalFormData?.admissionDate || ""}
                      placeholder="Select Date"
                      onChange={handleInputChange}
                      max={today}
                    />
                    {!medicalFormData?.admissionDate && formErrors?.admissionDate !== undefined && formErrors?.admissionDate !== null ? <p className="error">{formErrors?.admissionDate}</p> : null}
                  </div>
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.releaseDate && formErrors?.releaseDate ? "required-border" : ""}`}>
                    <label className="input-label">Release Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      name="releaseDate"
                      value={medicalFormData?.releaseDate}
                      placeholder="Select Date"
                      onChange={handleInputChange}
                      min={medicalFormData?.admissionDate}
                    />
                    {medicalFormData?.releaseDate == "" || (medicalFormData?.releaseDate === null && formErrors?.releaseDate) ? <p className="error">{formErrors?.releaseDate}</p> : ""}
                  </div>
                </div>
              )}
              <div className={`medical-input-box input-item-space ${!medicalFormData?.hospitalName && formErrors?.hospitalName ? "required-border" : ""}`}>
                <label className="input-label">Hospital Name *</label>
                <input
                  className={`input-box`}
                  type="text"
                  value={medicalFormData?.hospitalName}
                  name="hospitalName"
                  placeholder="Hospital Name"
                  onChange={handleInputChange}
                  autoFocus
                />
                {!medicalFormData?.hospitalName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.hospitalName) && formErrors?.hospitalName) ? (
                  <p className="error">{formErrors?.hospitalName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.city && formErrors?.city ? "required-border" : ""}`}>
                <label className="input-label">Hospital City</label>
                <input
                  className="input-box"
                  type="text"
                  name="city"
                  value={medicalFormData?.city}
                  placeholder="Hospital Address"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.city || (!/^[a-zA-Z_\s,]*$/.test(medicalFormData?.city) && formErrors?.city) ? <p className="error">{formErrors?.city}</p> : ""}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.physicianName && formErrors?.physicianName ? "required-border" : ""}`}>
                <label className="input-label">Physician/Doctor Name</label>
                <input
                  className="input-box"
                  type="text"
                  name="physicianName"
                  value={medicalFormData?.physicianName}
                  placeholder="Physician/Doctor Name"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.physicianName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.physicianName) && formErrors?.physicianName) ? (
                  <p className="error">{formErrors?.physicianName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.medicalCondition && formErrors?.medicalCondition ? "required-border" : ""}`}>
                <label className="input-label">Medical condition / Issue *</label>
                <input
                  className="input-box"
                  type="text"
                  name="medicalCondition"
                  value={medicalFormData?.medicalCondition}
                  placeholder="Medical condition / Issue"
                  onChange={handleInputChange}
                />
              </div>
              {!medicalFormData?.medicalCondition || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData.medicalCondition) && formErrors?.medicalCondition) ? (
                <p className="error">{formErrors?.medicalCondition}</p>
              ) : (
                ""
              )}
              <div className={`medical-input-box input-item-space ${!medicalFormData?.diagnosis && formErrors?.diagnosis ? "required-border" : ""}`}>
                <label className="input-label">Diagnosis / Remarks from Doctor</label>
                <input
                  className="input-box"
                  type="text"
                  name="diagnosis"
                  value={medicalFormData?.diagnosis}
                  placeholder="Diagnosis / Remarks from Doctor"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.diagnosis || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData?.diagnosis) && formErrors?.diagnosis) ? <p className="error">{formErrors?.diagnosis}</p> : ""}
              </div>
              <div className={`medical-input-box input-date-icon input-item-space ${!medicalFormData?.followupDate && formErrors?.followupDate ? "required-border" : ""}`}>
                <label className="input-label">Follow up Date *</label>
                <input
                  className="input-box"
                  type="date"
                  name="followupDate"
                  value={medicalFormData?.followupDate}
                  placeholder="Follow up Date"
                  onChange={handleInputChange}
                  min={getNextDay(medicalFormData?.hospitalVisitDate)}
                  minDate={new Date()} // Optionally set minimum date
                  onKeyDown={(e) => e.preventDefault()}
                />
                {!medicalFormData?.followupDate && formErrors?.followupDate ? <p className="error">{formErrors?.followupDate}</p> : ""}
              </div>
              <br />
              <div className="input-plus-minus-wrapper">
                <label className="input-label">Any tests prescribed by Doctor during this visit?</label>
                {addTestFormData?.map((obj, index) => {
                  return (
                    <div
                      className="medical-input-box input-item-space input-plus-minus"
                      key={index}
                    >
                      <input
                        className="input-box"
                        type="text"
                        id={obj.id}
                        name={obj.name}
                        placeholder="Test"
                        onChange={(event) => {
                          const textValue = event.target.value.replace(/[0-9]/g, "");
                          addTestFormHandler(obj, { ...event, target: { ...event.target, value: textValue } });
                        }}
                        value={obj.value}
                      />
                      {obj.showAddBtn && (
                        <button
                          type="button"
                          className={!obj.value ? "disableAddBtn" : "enableAddBtn"}
                          disabled={!obj.value ? true : false}
                          onClick={addTestFieldsHandler}
                        >
                          <img src={iconPlusGreen} />
                        </button>
                      )}
                      {!!obj.value && !obj.showAddBtn && (
                        <button
                          className="addTestRemoveBtn"
                          type="button"
                          onClick={(event) => removeTestFieldsHandler(obj, event)}
                        >
                          <img src={iconMinusSquare} />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="add-medicine-button-wrapper">
                <button
                  type="button"
                  className="add-medicine-button"
                  onClick={addMedicinePopUpHandler}
                >
                  <img src={VectorPlusPurple} />
                  Add Medicine
                </button>
              </div>
              <div className="medicine-list-wrapper">
                {medicalFormData?.medicineInfo?.map((item, index) => {
                  if (item?.medicineName !== "") {
                    return (
                      <div
                        className="medicine-item"
                        key={index}
                      >
                        <div className="medicine-type">
                          <p className="medicine-title">Medicine Name</p>
                          <p className="medicine-name">{item?.medicineName}</p>
                        </div>
                        <div className="medicine-doss">
                          <p className="medicine-mg">{item?.strength}</p>
                          <p className="medicine-days">{item?.days} Days</p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          {isReportCopy == "false" ? <p>Please fill the below form</p> : ""}
          {isReportCopy == "false" ? (
            <div className="medical-form-wrapper">
              {medicalFormData.isOutPatient == "true" ? (
                <div className="inline-input-wrapper input-item-space">
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate ? "required-border" : ""}`}>
                    <label className="input-label">Hospital Visit Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      value={medicalFormData?.hospitalVisitDate}
                      name="hospitalVisitDate"
                      onChange={handleInputChange}
                      max={today}
                      minDate={new Date()} // Optionally set minimum date
                      onKeyDown={(e) => e.preventDefault()}
                    />
                    {!medicalFormData?.hospitalVisitDate && formErrors?.hospitalVisitDate !== undefined && formErrors?.hospitalVisitDate !== null ? (
                      <p className="error">{formErrors?.hospitalVisitDate}</p>
                    ) : null}
                     {medicalFormData?.hospitalVisitDate == "" && formErrors?.hospitalVisitDate ? <p className="error">{formErrors?.hospitalVisitDate}</p> : ""} 
                   </div>
                </div>
              ) : (
                <div className="inline-input-wrapper input-item-space">
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.admissionDate && formErrors?.admissionDate ? "required-border" : ""}`}>
                    <label className="input-label">Admission Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      name="admissionDate"
                      value={medicalFormData?.admissionDate}
                      placeholder="Select Date"
                      onChange={handleInputChange}
                      max={today}
                    />
                    {!medicalFormData?.admissionDate && formErrors?.admissionDate ? <p className="error">{formErrors?.admissionDate}</p> : null}
                  </div>
                  <div className={`medical-input-box input-date-icon ${!medicalFormData?.releaseDate && formErrors?.releaseDate ? "required-border" : ""}`}>
                    <label className="input-label">Release Date *</label>
                    <input
                      className="input-box"
                      type="date"
                      name="releaseDate"
                      value={medicalFormData?.releaseDate}
                      placeholder="Select Date"
                      onChange={handleInputChange}
                      min={medicalFormData?.admissionDate}
                    />
                    {medicalFormData?.releaseDate == "" || (medicalFormData?.releaseDate === null && formErrors?.releaseDate) ? <p className="error">{formErrors?.releaseDate}</p> : ""}
                  </div>
                </div>
              )}
              <div className={`medical-input-box input-item-space ${!medicalFormData?.hospitalName && formErrors?.hospitalName ? "required-border" : ""}`}>
                <label className="input-label">Hospital Name *</label>
                <input
                  className="input-box"
                  type="text"
                  value={medicalFormData?.hospitalName}
                  name="hospitalName"
                  placeholder="Hospital Name"
                  onChange={handleInputChange}
                  autoFocus
                />
                {!medicalFormData?.hospitalName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.hospitalName) && formErrors?.hospitalName) ? (
                  <p className="error">{formErrors?.hospitalName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.city && formErrors?.city ? "required-border" : ""}`}>
                <label className="input-label">Hospital City</label>
                <input
                  className="input-box"
                  type="text"
                  name="city"
                  value={medicalFormData?.city}
                  placeholder="Hospital Address"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.city || (!/^[a-zA-Z_\s,]*$/.test(medicalFormData?.city) && formErrors?.city) ? <p className="error">{formErrors?.city}</p> : ""}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.physicianName && formErrors?.physicianName ? "required-border" : ""}`}>
                <label className="input-label">Physician/Doctor Name</label>
                <input
                  className="input-box"
                  type="text"
                  name="physicianName"
                  value={medicalFormData?.physicianName}
                  placeholder="Physician/Doctor Name"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.physicianName || (!/^[a-zA-Z][a-zA-Z0-9_\s.]*$/.test(medicalFormData?.physicianName) && formErrors?.physicianName) ? (
                  <p className="error">{formErrors?.physicianName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={`medical-input-box input-item-space ${!medicalFormData?.medicalCondition && formErrors?.medicalCondition ? "required-border" : ""}`}>
                <label className="input-label">Medical condition / Issue</label>
                <input
                  className="input-box"
                  type="text"
                  name="medicalCondition"
                  value={medicalFormData?.medicalCondition}
                  placeholder="Medical condition / Issue"
                  onChange={handleInputChange}
                />
              </div>
              {!medicalFormData?.medicalCondition || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData.medicalCondition) && formErrors?.medicalCondition) ? (
                <p className="error">{formErrors?.medicalCondition}</p>
              ) : (
                ""
              )}
              <div className={`medical-input-box input-item-space ${!medicalFormData?.diagnosis && formErrors?.diagnosis ? "required-border" : ""}`}>
                <label className="input-label">Diagnosis / Remarks from Doctor</label>
                <input
                  className="input-box"
                  type="text"
                  name="diagnosis"
                  value={medicalFormData?.diagnosis}
                  placeholder="Diagnosis / Remarks from Doctor"
                  onChange={handleInputChange}
                />
                {!medicalFormData?.diagnosis || (!/^[a-zA-Z][a-zA-Z0-9_\s]*$/.test(medicalFormData?.diagnosis) && formErrors?.diagnosis) ? <p className="error">{formErrors?.diagnosis}</p> : ""}
              </div>
              <div className={`medical-input-box input-date-icon input-item-space ${!medicalFormData?.followupDate && formErrors?.followupDate ? "required-border" : ""}`}>
                <label className="input-label">Follow up Date *</label>
                <input
                  className="input-box"
                  type="date"
                  name="followupDate"
                  value={medicalFormData?.followupDate}
                  placeholder="Follow up Date"
                  onChange={handleInputChange}
                  min={getNextDay(medicalFormData?.hospitalVisitDate)}
                />
                {!medicalFormData?.followupDate && formErrors?.followupDate ? <p className="error">{formErrors?.followupDate}</p> : ""}
              </div>
              <br />
              <div className="input-plus-minus-wrapper">
                <label className="input-label">Any tests prescribed by Doctor during this visit?</label>
                {addTestFormData?.map((obj, index) => {
                  return (
                    <div
                      className="medical-input-box input-item-space input-plus-minus"
                      key={index}
                    >
                      <input
                        className="input-box"
                        type="text"
                        id={obj.id}
                        name={obj.name}
                        placeholder="Test"
                        onChange={(event) => {
                          const textValue = event.target.value.replace(/[0-9]/g, "");
                          addTestFormHandler(obj, { ...event, target: { ...event.target, value: textValue } });
                        }}
                        value={obj.value}
                      />
                      {obj.showAddBtn && (
                        <button
                          type="button"
                          className={!obj.value ? "disableAddBtn" : "enableAddBtn"}
                          disabled={!obj.value ? true : false}
                          onClick={addTestFieldsHandler}
                        >
                          <img src={iconPlusGreen} />
                        </button>
                      )}
                      {!!obj.value && !obj.showAddBtn && (
                        <button
                          className="addTestRemoveBtn"
                          type="button"
                          onClick={(event) => removeTestFieldsHandler(obj, event)}
                        >
                          <img src={iconMinusSquare} />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="add-medicine-button-wrapper">
                <button
                  type="button"
                  className="add-medicine-button"
                  onClick={addMedicinePopUpHandler}
                >
                  <img src={VectorPlusPurple} />
                  Add Medicine
                </button>
              </div>
              <div className="medicine-list-wrapper">
                {medicalFormData?.medicineInfo.map((item, index) => {
                  if (item?.medicineName !== "") {
                    return (
                      <div
                        className="medicine-item"
                        key={index}
                      >
                        <div className="medicine-type">
                          <p className="medicine-title">Medicine Name</p>
                          <p className="medicine-name">{item.medicineName}</p>
                        </div>
                        <div className="medicine-doss">
                          <p className="medicine-mg">{item.strength}</p>
                          <p className="medicine-days">{item.days}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
        <AddMedicalReports
          hospitalVisitList={hospitalVisitList}
          setOtherdocIds={setOtherdocIds}
          otherdocIds={otherdocIds}
          isOpen={isOpen}
        />
      </ModelPopUp>   */}
    </>
  );
};
