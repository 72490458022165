import React from "react";

const SampleIllustration = ({ titleHeader, noteText1, sampleText, noteText2, anomaly, lastText }) => {
  return (
    <>
      <div className="sample-illustration-wrapper  mb-4">
        <div className="illustration-head">{titleHeader}</div>
        <div className="illustration-text">
          <p>
            {noteText1} <span className="text-red">{sampleText}</span>
            {noteText2}&nbsp;
            <b>{anomaly}</b>
            {lastText}
          </p>
        </div>
      </div>
    </>
  );
};

export default SampleIllustration;
