import React from "react";
import { Controller } from "react-hook-form";

function PhoneInputField({ name, control, label, fieldLabel, labelClass, isDisabled, icon, inputclass, ...rest }) {
  return (
    <>
      <label className={`${labelClass}`}>{label}</label>
      <div className="custom-phone-input">
        <div className="icon">
          <img
            src={icon}
            alt="Icon"
          />
        </div>
        <div className="country-code">+91</div>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <input
              {...field}
              {...rest}
              disabled={isDisabled ? true : false}
            />
          )}
        />
      </div>
    </>
  );
}

export default PhoneInputField;
