/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconSolidPassword = ({ style, iconSolidPassword = "/img/icon-solid-password-3.png" , className }) => {
  return <img className={`icon-solid-password ${className}`} style={style} alt="Icon solid password" src={iconSolidPassword} />;
};

IconSolidPassword.propTypes = {
  iconSolidPassword: PropTypes.string,
};
