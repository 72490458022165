import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IconMail } from "../../components/IconMail";
import { IconSolidPassword } from "../../components/IconSolidPassword";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { setCookies } from "../../configration/HandleCookies";
import ToastMessage from "../../shared/ToastMessage";
import "./style.css";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
export const LoginEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceType, setDeviceType] = useState("Unknown");
  const [deviceLocation, setDeviceLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const getDeviceType = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/Windows/i)) {
      return "Windows";
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      return "Mac";
    } else {
      return "Unknown";
    }
  };

  const fetchCityName = async (latitude, longitude) => {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    );
    const city =
      response.data.address.city ||
      response.data.address.town ||
      response.data.address.village;
    return city;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const name = await getDeviceType();
        setDeviceType(name);
        const position = await getCurrentLocation();
        const city = await fetchCityName(
          position.coords.latitude,
          position.coords.longitude
        );
        setDeviceLocation(city);
        const isMobile = () => {
          return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        };
        setDeviceName(isMobile() ? "Mobile" : "Web");
        // setDeviceType(getDeviceType());
      } catch (error) {
        console.error("Error getting device information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const getSubscriptions = async () => {
    return await AxiosInstance.get(`subscription/get-detail`)
      .then((response) => {
        return response.subscriptionStatus;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    await AxiosInstance.post("api/auth/signin", {
      email,
      password,
      deviceName,
      deviceType,
      deviceLocation,
    })
      .then(async (response) => {
        // setCookies(response);
        window.sessionStorage.setItem("token", response?.token);
        window.sessionStorage.setItem("userDetails", JSON.stringify(response));
        toast.success("Logged in successfully");
        let subscriptionResponse = await getSubscriptions();
        if (
          subscriptionResponse == "NO_SUBSCRIPTION" ||
          subscriptionResponse == "EXPIRED_SUBSCRIPTION"
        ) {
          toast.success("You don't have an active subscription");
          // navigate(`/buy-subscription`);
          navigate(`/SubscriptionPageBeforeLogin`);
        } else {
          const initialURL = sessionStorage.getItem("initialURL");
          setTimeout(() => {
            if (initialURL) {
              navigate(initialURL);
              // sessionStorage.removeItem("initialURL"); // Optionally clear the initialURL after redirect
            } else if (response?.userType === "Doctor") {
              navigate(`/my-patients`);
            } else if (response?.userType === "TESTLAB") {
              navigate(`/lab-screen`);
            } else {
              navigate(`/home?isSignedUp=${true}&userType=Other`);
            }
          }, 1000);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const initialSurveyURL = sessionStorage.getItem("initialURL");

  return (
    <div className="login-email">
      <div className="overlap-8">
        <ToastMessage></ToastMessage>
        <div className="group-18">
          <img
            className="health-buddy"
            alt="Health buddy"
            src="/img/AppLogoWithName.png"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "30px 0px 10px 0px",
          }}
        >
          <div className="overlap-9">
            <div className="loginHeading">
              <div>Login</div>
              {!initialSurveyURL && (
                <div>
                  <Link to="/role-page" style={{ fontSize: "medium" }}>
                    Choose Role <SupervisorAccountIcon />
                  </Link>
                </div>
              )}
            </div>
            {!initialSurveyURL && (
              <div className="group-13">
                <div className="overlap-group-6">
                  <div className="overlap-10">
                    <div className="text-wrapper-14">With Email</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    {" "}
                    <Link to="/login-otp" className="text-wrapper-15">
                      With OTP
                    </Link>{" "}
                  </div>
                </div>
              </div>
            )}

            <form onSubmit={login}>
              <div className="group-17">
                <div className="text-wrapper-23">Email</div>
                <div className="group-15">
                  <div className="overlap-group-7">
                    <IconMail iconMail="/img/icon-mail.png" />
                    <input
                      type="email"
                      onChange={onEmailChange}
                      className="emailinput"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div className="group-14">
                <div className="text-wrapper-17">Password</div>
                <div className="group-15">
                  <div className="overlap-group-7">
                    <IconSolidPassword iconSolidPassword="/img/icon-solid-password.png" />
                    <input
                      type={showPassword ? "text" : "password"}
                      onChange={onPasswordChange}
                      className="emailinput"
                      placeholder="Enter Your password"
                    />
                    {showPassword == false ? (
                      <VisibilityOffIcon onClick={togglePasswordVisibility} />
                    ) : (
                      <RemoveRedEyeIcon onClick={togglePasswordVisibility} />
                    )}
                  </div>
                </div>
              </div>
              {!initialSurveyURL && (
                <div className="text-wrapper-16">
                  <Link to="/forgot-password" style={{ color: "#5353e9" }}>
                    Forgot Password
                  </Link>
                </div>
              )}

              <button
                className={`${email === "" ? "group-16disabled" : "group-16"}`}
                disabled={email === ""}
                type="submit"
              >
                {loading && <CircularProgress />}
                {!loading && "Submit"}
              </button>
            </form>

            {!initialSurveyURL && (
              <p className="don-t-have-an">
                <span className="text-wrapper-24">Don’t have an account? </span>
                <Link to="/sign-up">
                  <span className="signup">Sign Up</span>
                </Link>
              </p>
            )}

            {!initialSurveyURL && (
              <p className="by-clicking-here-i">
                <span className="byclick">
                  By clicking here, I state that i have read and understood the{" "}
                </span>
                <a href="https://livingscopehealth.com/terms/" target="blank">
                  <span className="termsandcondition">
                    terms &amp; conditions
                  </span>
                </a>
                <span className="byclick"> and </span>
                <a href="https://livingscopehealth.com/privacy/" target="blank">
                  <span className="termsandcondition">privacy policy</span>
                </a>
              </p>
            )}

            {initialSurveyURL && (
              <p className="by-clicking-here-i" style={{color: "black"}}>
                Best viewed in Google Chrome browser
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
