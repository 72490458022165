import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import Spinner from "../../../shared/Spinner";

const AppointmentScheduler = ({
  selectedDietician,
  onAppointmentSelect,
  type,
}) => {
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [slots, setSlots] = useState([]);
  const [loading, seLoading] = useState(false);

  useEffect(() => {
    if (selectedDietician) {
      seLoading(true);
      AxiosInstance.get(
        `dietitian/get-dietitian-session/${selectedDietician.userId}`
      )
        .then((response) => {
          console.log("API Response:", response);
          if (response != null && response) {
            setSlots(response);
          } else {
            setSlots([]);
          }
          seLoading(false);
        })
        .catch((err) => {
          seLoading(false);
          console.error("Error fetching sessions:", err);
        });
    }
    // Update current time every minute
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);
    return () => clearInterval(interval);
  }, [selectedDietician]);

  const handleDateChange = (direction) => {
    if (direction === "next") {
      setSelectedDate(selectedDate.add(1, "day"));
    } else if (direction === "prev" && !selectedDate.isSame(today, "day")) {
      setSelectedDate(selectedDate.subtract(1, "day"));
    }
  };

  const handleTimeSelect = (slot) => {
    setSelectedTime(`${slot.startTime} - ${slot.endTime}`);
    setSelectedSessionId(slot.id); // Save the session ID
  };

  // Filter slots by selected date and time
  const filteredSlots = slots.filter((slot) => {
    const slotDate = dayjs(slot.scheduleDate);
    const slotStartTime = dayjs(`${slot.scheduleDate}T${slot.startTime}`);
    const now = dayjs();

    // Only show slots for the selected date and time that are in the future
    return slotDate.isSame(selectedDate, "day") && slotStartTime.isAfter(now);
  });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Box p={3} display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={2}
            sx={{
              background:
                "#fff linear-gradient(259deg, #e2fdfc, #e1d9fc) 0 0 no-repeat padding-box",
              padding: "10px",
            }}
          >
            {/* Left Side: Dietician Name and Price */}
            <Box>
              <Typography variant="h6" fontWeight="bold">
                {selectedDietician.firstName} {selectedDietician.lastName}
              </Typography>
              <Typography variant="subtitle1">
                ₹
                {type === "Audio"
                  ? selectedDietician.audioCharge
                  : selectedDietician.videoCharge}
              </Typography>
            </Box>

            {/* Right Side: Today's Date, Day, and Current Time */}
            <Box textAlign="right">
              <Typography variant="subtitle1" fontWeight="bold">
                {currentTime.format("ddd")}, {currentTime.format("DD/MM/YYYY")}
              </Typography>
              <Typography variant="subtitle1">
                <AccessTimeOutlinedIcon
                  sx={{
                    color: "blue",
                    fontSize: "16px",
                  }}
                />{" "}
                {currentTime.format("hh:mm A")}
              </Typography>
            </Box>
          </Box>
          {/* Date Navigation and Picker */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={3}
          >
            <IconButton
              onClick={() => handleDateChange("prev")}
              disabled={selectedDate.isSame(today, "day")}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedDate}
                minDate={today}
                onChange={(newDate) => setSelectedDate(newDate)}
                format="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <IconButton onClick={() => handleDateChange("next")}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>

          {/* Time Slot Selection */}
          {filteredSlots.length > 0 && (
            <Typography variant="h6" color="textSecondary" className="mb-3">
              Available Time Slots
            </Typography>
          )}
          {filteredSlots.length > 0 ? (
            <Grid container spacing={1} justifyContent="center" mb={3}>
              {filteredSlots.map((slot) => (
                <Grid item key={slot.id}>
                  <Button
                    variant={
                      selectedTime === `${slot.startTime} - ${slot.endTime}`
                        ? "contained"
                        : "outlined"
                    }
                    color={
                      selectedTime === `${slot.startTime} - ${slot.endTime}`
                        ? "primary"
                        : "default"
                    }
                    onClick={() => handleTimeSelect(slot)}
                  >
                    {dayjs(slot.startTime, "HH:mm").format("hh:mm A")} -{" "}
                    {dayjs(slot.endTime, "HH:mm").format("hh:mm A")}
                  </Button>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box mt={3} textAlign="center">
              <Typography variant="h6" color="error" fontWeight="bold">
                No sessions available for{" "}
                {selectedDate.format("dddd, DD/MM/YYYY")}
              </Typography>
            </Box>
          )}

          {/* Book Appointment Button */}
          {selectedTime && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                onAppointmentSelect({
                  sessionId: selectedSessionId, // Send selected session ID
                  selectedTime,
                })
              }
            >
              Book Appointment
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default AppointmentScheduler;
