export const strings = {
  button: {
    ok: "Ok",
    submit: "Submit",
    login: "Login",
    logout: "Logout",
    sendOtp: "Send OTP",
    sendEnquiry: "Send Inquiry",
    next: "Next",
    continue: "Continue",
    attach: "Attach",
    addMedicine: "Add Medicine",
    uploadEmailTestReport: "Upload/Email Test Reports",
    registerNow: "Register Now",
    update: "Update",
    goToDashboard: "Go to Dashboard",
    upgradeSubs: "Upgrade Subscription",
    addToCart: "Add to Cart",
    tryAgain: "Try Again",
    attachMedicalReports: "Attach Medical Reports",
    addPrescriptionReports: "Add Prescription Reports",
    attachDischargeReport: "Attach Discharge Report",
    attachPrescriptionReport: "Attach Prescription Report",
    yes: "Yes",
    no: "No",
    pay: "Pay Now",
    deleteAccount: "Delete Account",
    createAccount: "Create Account",
  },
  common: {
    noResultFound: "No Result Found",
    uploadPdfJpg: "Upload pdf, jpeg or png",
  },
  contactUs: {
    email: "\n Email Us - ",
    call: "\n Call us - ",
    contactUs: "Contact Us",
    home: "Home - ",
    corporateOffice: "Corporate Office",
    regionalOffice: "Regional Office",
    customerCare: "Customer Care",
    askUsAny: "Ask US Any Questions:-",
  },
  status: {
    tier1: "Tier 1",
  },
  tag: {
    sample: "SAMPLE",
  },
  sampleIllustration: {
    title: "SAMPLE ILLUSTRATION",
    theInformationPresented: "Note: The information presented below is just a ",
    sampleBasedOnDummyData: "SAMPLE based on dummy data ",
    andIsShowJustCase:
      "and is shown just to give you an idea how your entire hospital visit information and data will be summarised for you. This report is automatically prepared and presented as soon as we receive your actual lab test reports, and you fill in details regarding your hospital visits on the Medical History screen.",
    isShownTo:
      "It is shown to give you an idea how your lab test reports data will be analyzed and summarized for you. This will be done as soon as we receive the actual test reports from you or directly from the lab.\nThe report below will show the status of each test date. If any test value is high or low for any given date, then the status for that date will be shown as ",
    anomaly: '"Anomaly" ',
    andExplanationWillBePresented: "and an explanation will be presented below. See a sample below.",
    andIsShownJustToGive: "and is shown just to give you an idea how your lab test reports data will be analyzed and summarized for you as soon as we receive your actual lab test reports.",
    theSamplePresentation: "The sample presentation will be replaced with your actual data once we receive the reports.",
    andIsShownJustToGIveYouAnIdea: "and is shown just to give you an idea how your hospital visit information will be analyzed and summarized for you as soon as you add any medical history.",
  },
  sampleIllustrationMedicalHistory: {
    title: "SAMPLE ILLUSTRATION",
    theInformationPresented: "Note: The information presented below is just a ",
    sampleBasedOnDummyData: "SAMPLE based on dummy data ",
    andIsShowJustCase:
      "and is shown just to give you an idea how your entire hospital visit information and data will be summarised for you. This report is automatically prepared and presented as soon as we receive your actual lab test reports, and you fill in details regarding your hospital visits on the Medical History screen.",
    isShownTo:
      "It is shown to give you an idea how your lab test reports data will be analyzed and summarized for you. This will be done as soon as we receive the actual test reports from you or directly from the lab.\nThe report below will show the status of each test date. If any test value is high or low for any given date, then the status for that date will be shown as ",
    anomaly: '"Anomaly" ',
    andExplanationWillBePresented: "and an explanation will be presented below. See a sample below.",
    andIsShownJustToGive: "and is shown just to give you an idea how your lab test reports data will be analyzed and summarized for you as soon as we receive your actual lab test reports.",
    theSamplePresentation: "The sample presentation will be replaced with your actual data once we receive the reports.",
    andIsShownJustToGIveYouAnIdea: "and is shown just to give you an idea how your hospital visit information will be analyzed and summarized for you as soon as you add any medical history.",
  },
  sampleIllustrationTestReports: {
    title: "SAMPLE ILLUSTRATION",
    theInformationPresented: "Note: The information presented below is just a ",
    sampleBasedOnDummyData: "SAMPLE based on dummy data ",
    andIsShowJustCase:
      "and is shown just to give you an idea how your Lab test reports data will be analysed and summarised for you as soon as we receive your actual lab test reports from you or directly from the lab. The report below will show the status of each test date. If any test value is high or low on any given date, then the status for that date will be shown as",
    anomaly: " “Anomaly” ",
    lastText: "and an explanation will be presented below. See a sample below.",
    isShownTo:
      "It is shown to give you an idea how your lab test reports data will be analyzed and summarized for you. This will be done as soon as we receive the actual test reports from you or directly from the lab.\nThe report below will show the status of each test date. If any test value is high or low for any given date, then the status for that date will be shown as ",
    anomaly: '"Anomaly" ',
    andExplanationWillBePresented: "and an explanation will be presented below. See a sample below.",
    andIsShownJustToGive: "and is shown just to give you an idea how your lab test reports data will be analyzed and summarized for you as soon as we receive your actual lab test reports.",
    theSamplePresentation: "The sample presentation will be replaced with your actual data once we receive the reports.",
    andIsShownJustToGIveYouAnIdea: "and is shown just to give you an idea how your hospital visit information will be analyzed and summarized for you as soon as you add any medical history.",
  },
  sampleIllustrationLabHistory: {
    title: "SAMPLE ILLUSTRATION",
    theInformationPresented: "Note: The information presented below is just a ",
    sampleBasedOnDummyData: "SAMPLE based on dummy data ",
    andIsShowJustCase:
      "and is shown just to give you an idea how your Lab History data will be summarized for you once we receive your actual lab test reports. The sample presentation will be replaced with your actual data once we receive the reports.",
    isShownTo:
      "It is shown to give you an idea how your lab test reports data will be analyzed and summarized for you. This will be done as soon as we receive the actual test reports from you or directly from the lab.\nThe report below will show the status of each test date. If any test value is high or low for any given date, then the status for that date will be shown as ",
    anomaly: '"Anomaly" ',
    andExplanationWillBePresented: "and an explanation will be presented below. See a sample below.",
    andIsShownJustToGive: "and is shown just to give you an idea how your lab test reports data will be analyzed and summarized for you as soon as we receive your actual lab test reports.",
    theSamplePresentation: "The sample presentation will be replaced with your actual data once we receive the reports.",
    andIsShownJustToGIveYouAnIdea: "and is shown just to give you an idea how your hospital visit information will be analyzed and summarized for you as soon as you add any medical history.",
  },
  sampleIllustrationCaseHistory: {
    title: "SAMPLE ILLUSTRATION",
    theInformationPresented: "Note: The information presented below is just a ",
    sampleBasedOnDummyData: "SAMPLE based on dummy data ",
    andIsShowJustCase:
      "and is shown just to give you an idea how your entire hospital visit information and data will be summarised for you. This report is automatically prepared and presented as soon as we receive your actual lab test reports, and you fill in details regarding your hospital visits on the Medical History screen.",
  },
  checkInternetConnection: {
    noInternetConnection: "It seems there is something wrong with your internet connection. Please connect to the internet.",
    oops: "Ooops!",
  },
  field: {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    phoneNo: "Phone Number",
    dateOfBirth: "Date of Birth",
    email: "Email",
    password: "Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    loginPassword: "Password",
    currentPassword: "Current Password",
    filterText: "Filter by Date",
    search: "Search",
    registrationDate: "Registration Date",
    generation: "Generation",
    age: "Age",
    sex: "Sex",
    gender: "Gender",
    selectCountry: "Select Country",
    state: "State",
    city: "City",
    zipCode: "Zip Code",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    alternatePhoneNo: "Alternate Phone Number",
    aadharNumber: "Aadhar Number",
    hospitalName: "Hospital Name",
    hospitalCity: "Hospital City",
    followUpDate: "Follow up Date",
    diagnosisRemarksFromDoctor: "Diagnosis/ Remarks from Doctor",
    medicalConditionIssue: "Medical condition/Issue",
    physicianDoctorName: "Physician/Doctor Name",
    name: "Name",
    message: "Message",
    test1: "test 1",
    test: "Test",
    medicineName: "Medicine Name",
    Strength: "Strength",
    days: "Days",
    selectDate: "Select Date",
    locationAddress: "Location/Address",
    hospitalPcpClinic: "Hospital/PCP/Clinic",
    Min8char: "Min 8 char",
    specialization: "Specialization",
    admissionDate: "Admission Date",
    releaseDate: "Release Date",
    hospitalVisitDate: "Hospital Visit Date",
    hospital: "Hospital",
    lab: "Lab/Test Center",
    visitDateList: "Available dates",
    filterByDate: "Filter By Date",
    selectVisitDate: "Select your visit or admission date from the list",
  },
  link: {
    login: "Login",
    signup: "Signup",
    termsAndCondition: "Terms & Conditions",
    policy: "Privacy Policy",
    ForgotPassword: "Forgot Password",
  },
  plan: {
    tier1Bronze: "Tier-1: BRONZE ",
    tier2Silver: "Tier-2: SILVER",
    manageAllYourHealth: "Store and Manage Medical Records",
    learnMore: "Learn More",
    understandStartManaging: "Understand Risk Factors in your Health",
    startForFree: "Start Here",
    exploreTheServices: "Explore the Services",
    watchHere: "Watch Here",
  },
  landing: {
    whatIsOnYourMind: "What's on your mind?",
    listenToOurBrandStory: "Listen to our Brand Story",
    health: "Health?",
    learnHowWe: "Learn how we are transforming, “Health”!",
  },
  signup: {
    createNewAccount: "Create New Account",
    iAgreeToThe: "I agree to the ",
    getYourHealth: "Get your health notifications on WhatsApp.",
    alreadyHaveAccount: "Already have an account? ",
    paymentSummary: "Payment Summary",
    bronzeHealthService: "Bronze Health Service (per month)",
    itemTotal1Year: "Annual Subscription",
    platformCharges: "Platform Charges",
    taxesAndFee: "Taxes and Fee",
    totalAmountTo: "Total Amount to Pay",
    trial: "Trial of the plan",
    im18: "I am 18 years old or more.",
    total: "Total",
    gst: "GST (18%)",
  },
  login: {
    withEmail: "With Email",
    withOtp: "With OTP",
    logIn: "Login",
    signUp: "Don’t have an account?",
    about: "By clicking Submit above, I state that i have read and understood the",
    and: "and",
    msgLogInOtp: "Kindly provide a valid phone number as you will receive a message containing the OTP code.",
  },
  forgotPassword: {
    forgotPassword: "Forgot Password",
    weWillSend: "We will send you the 4 digit verification code.",
  },
  verification: {
    verifyAccount: "Verify Account!",
    digitCode: "4-Digit Code",
    resendCode: "Resend Code in",
    didNotReceived: "Didn’t receive code? ",
    resend: "Resend",
    verifyBtnText: "Verify",
  },
  home: {
    manageYourHealth: "Explore our Health Services",
    alreadyHaveAn: "Already have an account? ",
  },
  bronzePlan: {
    title: "Bronze Health Plan",
    desc: "<<BRONZE HEALTH: Managing Medical Records\n\n\n<b><<What is the Intent?<b>\n\nThe intent of this health service is <b><<“Managing Medical Records”,<b> meaning we want people to start taking charge of their health by first managing their medical records. In this regard, this is the foundation for our more advanced health services powered through Artificial Intelligence (AI) technologies. Your health journey cannot start without this foundational step.\n\n\n<b><<What Does it include?<b>\n\nThe service enables you to store all your medical records and history, for example, prescription records, lab or test reports, X Ray, MRI or other imaging records and reports, Health issues diagnosis reports, other hospital visit records, etc. in our secured <b><<“MR VAULT”<b> on AWS Cloud. Your records are 100% secured and confidential on AWS cloud which complies with stringent HIPAA act of US. This act protects patient health information from being disclosed without the patient’s consent of knowledge.\n\n\n<b><<Why is this Required?<b>\n\nThe biggest issue with our health lies in the fact that we don’t keep all our medical records in one place for easy reference in the future. These medical records carry significantly important health data that can be systematically and continuously analyzed to manage and even improve your health. Often this kind of medical history is also required by physicians or specialists for better diagnosis or treatment in emergency situations. More importantly, this will give you a sense of control of your own medical records and eventually health. Also, having all medical records in one place ensures easy access to a required medical record in the future as and when you or your doctor needs it. You don’t have to hunt for your record and depend on labs or hospitals to provide you with the same. As such, most hospitals or even labs won’t be able to provide you with the needed record so easily when you need it the most.",
    headline: "Good Luck with your health Journey!",
  },
  dashboard: {
    howYouCompare: "How you compare against other Health Enthusiasts in your Generation?",
    pps: "Participation Performance Score(PPS) ",
    noneInitiated: "None initiated",
    xrayMri: "X-ray, MRI",
    yourPPScore: (score) => `Your PPS is ${score}th Percentile`,
    theParticipationPerformance: "The Participation Performance Score(PPS) report presented below is just a",
    sampleIllustration: " SAMPLE ILLUSTRATION ",
    basedOnDummyData: " based on dummy data. This will be replaced by your actual performance score based on your participation on our platform. Please first fill the registration form in ",
    profileScreen: "Profile Screen",
    followedBy: " followed by ",
    medicalScreen: "Medical History",
    and: "and",
    labScreen: "Lab History",
    inRespectiveScreen: " in respective screens. You will then see your personalized report here. ",
    thisGivesYouPicture:
      "This gives you a picture of where you are currently on your journey towards achieving full control and ownership of your health by constantly participating and sharing needed medical information and records with us. This is the essential step to achieve healthy living in the future. A high score here implies that you are well on your way to fully utilizing our services to maximize your health benefits.",
    profile: "Profile",
    memberShipLevel: "Membership Level",
    memberShipDate: "Membership Date",
    registrationComplete: "Registration Complete",
    registrationIncomplete: "Registration Incomplete",
    labTestReports: "Lab/Test reports recorded with us: ",
    firstLabReportDate: "First Lab report date",
    latestLabReportDate: "Latest Lab report date",
    medicalHistoryRecorded: "Medical History recorded with us: ",
    firstMedicalHistoryDate: "First Medical History date",
    latestMedicalHistoryDate: "Latest Medical History date",
    otherReports: "Other Reports: ",
    surveys: "Surveys: ",
    male: "Male",
    noOfTimesYouHave: "No of times you have logged into the Health Portal since you joined on",
    youAreInTheLower1: (score) => `You are in the lower ${score}th percentile among other participants in our network `,
    youAreInTheLower2: (score) => `This means ${score}% of participants(in the same generation`,
    millennial: "Millennials",
    youAreInTheLower3:
      " as yourself) in our network are participating more actively than you to improve their health. Please read in FAQ how you can maximize your PPS and health benefits through our health services.",
  },
  purchasedPlan: {
    youHaveSuccessfully:
      "You have successfully signed up for the Bronze Health service plan. We congratulate you for taking the first step towards controlling and managing your health proactively with our services.",
    goodLuck: "Good luck!",
  },
  header: {
    signup: "Sign Up",
    testValue: "Test Values",
    testReport: "Test Reports",
    newsFeed: "News Feed",
    termsAndCondition: "Terms & Conditions",
    termsAndPolicy: "Terms & Policies",
    videos: "Videos",
    brandStory: "Brand Story",
    home: "Home",
    subscriptionPlan: "Subscription Plans",
    myUsage: "My Usage",
    healthServiceProvider: "Health Service Providers",
    bronzeHealthPlan: "Bronze Health Plan",
    helpCenter: "Help Center",
    settingsAndPrivacy: "Settings & Privacy",
    mySubscription: "My Subscriptions",
    paymentHistory: "Payment History",
    changePassword: "Change Password",
    contactUs: "Contact Us",
    labHistory: "Lab History",
    medicalHistory: "Medical History",
    caseHistory: "Case History",
    notifications: "Notifications",
    preventiveRecord: "Preventive Test Records",
    cart: "My Cart",
  },
  privacyPolicy: {
    privacyPolicy: "Privacy Policy",
  },
  termsAndPolicy:
    "I am writing to provide you with an explanation regarding a recent anomaly that occurred in our system. We have identified an issue that requires further investigation and analysis to determine the root cause and implement an appropriate solution.\n\nDuring our routine operations, our team noticed an anomaly in [specific area/aspect]. This anomaly is currently under investigation, and we are working diligently to gather all the necessary information to understand its nature and impact fully.\n\nWe want to assure you that our experts are actively engaged in resolving this issue. We are conducting thorough assessments, running diagnostic tests, and collaborating with relevant stakeholders to identify the cause and develop an effective solution. While we are committed to resolving this anomaly as quickly as possible, please bear with us as the process may take some time to ensure accuracy and reliability.\n\nWe understand the importance of maintaining seamless operations and the impact any disruption may have on our stakeholders. Rest assured, we are treating this matter with the utmost priority and dedicating all available resources to rectify the situation prompt",
  tab: {
    home: "Home",
    subscription: "Subscriptions",
    profile: "Profile",
    menu: "Menu",
    dashboard: "Dashboard",
  },
  validation: {
    fieldRequired: (field) => `Please enter the ${field}`,
    selectRequired: (field) => `Please select the ${field}`,
    onlyNumber: (field) => `${field} can only contain numbers`,
    invalidEmail: "Please enter the valid email address",
    releaseDateMust: "Release Date must be greater than or equal to Admission Date",
    followUpDateMust: "Follow up date must be greater than or equal to",
    passwordMustContain: "Password must contain at least 8 chars",
    invalidMobileNumber: "Please enter a valid Phone Number",
    acceptTerms: "Please read and accept the Terms & Conditions and Privacy Policy",
    passwordNotMatch: "Password did not match",
    invalidCity: "City can only contain alphabets",
    invalidZipCode: "Please enter the valid Zip Code",
    minPhoneLen: (field) => `${field} must be minimum of 10 numbers`,
    invalidPhone: (field) => `${field} can only contain numbers`,
    passwordMin: (field) => `${field} must contains at least 6 characters`,
    passwordNtFollowGuide: (field) => `${field} must contains three of the four categories. Uppercase character, lowercase character, numeric value and a special character`,
    nameCanContainAlpha: (field) => `${field} can only contain alphabets`,
    nameCanContainNumber: (field) => `${field} can only contain alphabets or a combination of alphabets and numbers`,
    nameCanContainSymbols: (field) => `${field} can only contain alphabets or a combination of alphabets, symbols and numbers`,
    blankSpace: (field) => `${field} cannot contain excessive blank spaces`,
    minChar: (field, min) => `${field} must be at least ${min} characters long`,
    ageAgreement: "Please accept the age agreement",
    blankSpaceNotAllowed: "Blank spaces are not allowed",
    atLeastOneMedicine: "Please add at least one medicine",
  },
  message: {
    documentInvalidFile: "Selected file type is not allowed. Please upload pdf, png or jpg file.",
    exceedFileSize: (size) => `File size exceeds the allowed limit of ${size / 1024 / 1024}MB.`,
    pleaseJoinBronze:
      "Please join BRONZE health service first. You will be promoted to join this advanced health service only after you have achieved a minimum participation performance score(PPS) in Bronze service, showing your personal commitment to improve your health.",
    registrationCompleted: "Registration Completed",
    yourUhidIs: "Your UHID is genrated and you can see this UHID in your profile ",
    accountCreated: "Account created successfully",
    loginSuccess: "Logged in successfully",
    deleteSuccessfully: "Your account has been deleted successfully",
    passwordSuccessfullyChanged: "Password successfully changed.",
    requestResetPasswordSuccess: "We have sent a reset password link to your email address. Please check your inbox.",
    deleteConfirmation: "Confirm Deletion",
    logOutConfirmation: "Confirm Logout",
    logoutConfirmationDesc: "Are you sure you want to log out? Logging out will end your current session and you will need to sign in again to access your account.",
    deleteConfirmationDesc: "Are you sure you want to delete this record? This action cannot be undone.",
    deleteAccountConfirmation: "Are you sure you want to delete your account ? This action cannot be undone. All of your data and subscriptions will be erased.",
    selectedDateDataNotAvailable: "There is not any record available for selected date",
    documentNotAvailable: "Document not available",
    planActivation: "Plan Activation",
    notHavePlan: "You do not have any active plan. Please complete your payment to activate your plan",
    beforeMakingPayment: "Before making a payment, we kindly request that you begin by creating your account.",
  },
  uploadEmailTestReportSheet: {
    uploadTestReport: "Upload Test Reports",
    emailTestReport: "Email Test Reports",
    pleaseAttachBelowAny:
      "Please attach below any medical test reports you may have with you. Please remember that the more you provide us with every test report, medical diagnosis, prescription records, X-ray, MRI, and other hospital records, the better our AI technology works in accurately assessing your health. By continuously tracking, monitoring, and analyzing these medical records we will be able to develop an accurate biographical sketch of your health and eventually predict and prevent diseases for you. We will eventually even recommend lifestyle changes so that you can enjoy better health.  So, we recommend that you reach out to all testing labs that you may have visited or used for medical tests at least in the last 5 years and ask them for all your old test records and then upload a copy here.",
    pleaseAttachOnlyLabTest: "(Note: Please attach only Lab test reports here. Other medical reports can be attached in the Medical History page).",
    IfConvenientYou: "If convenient you may simply email the test report as an attachment to the email address below. Please ensure that the attached test report is not password protected.",
    youMayEven: "You may even simply forward the email (with test report attached) that you may have received from the lab.",
    uploadHere: "Upload here",
  },
  medicalHistory: {
    visitType: "Visit Type",
    outPatient: "Outpatient",
    inPatient: "Inpatient",
    admissionDate: "Admission Date",
    releaseDate: "Release Date",
    addMedicalHistory: "Add Medical History",
    medical: "Medical History",
    filter: "Filter by Date",
    Prescription: "Prescription(s)",
    solo: "solo & Other",
    visitDate: "Visit Date",
    visitId: "Visit Id",
    testRecommended: "Test Recommended",
    testDone: "Test Done",
    hospitalName: "Hospital Name",
    medicalIssue: "Medical Issue",
    medicines: "Medicines",
    storeOtherMedicalRecords: "Store other Medical Records",
    otherMedicalRecords: "Other Medical Records",
    createYourOwnMHR: "Store all your Hospital Records.",
    pleaseAdd34MedicalHistory:
      "Please add prescription reports (past visits and any future visits) and any other medical records from your hospital visit. You will see a synopsis of your hospital visit in Digital Wallet. All records are stored on AWS(Amazon) cloud in a secured container and privacy, security and confidentiality 100% maintained.",
    pleaseClickAndAttach: "Please click and attach any other medical reports such as X-Ray, MRI, CT SCAN (other than prescription report) below.",
  },
  labHistory: {
    pleaseUploadOrMail:
      "Please upload or mail all test reports for tests recommended to you by your doctor in last 3-5 years for all the hospital visits that you have listed in the Medical History screen of the App.",
    Lab: "Lab History",
    collectionDate: "Collection Date",
    tests: "Tests",
    id: "ID:",
  },
  preventiveRecord: {
    preventiveString: "Please attach all test Reports for Preventive test recommendations made in the app. You can see a snapshot of the report then in your MR - Digital Wallet.",
  },
  tests: {
    angiography: "Angiography",
    urine: "Urine",
    asthma: "Asthma",
    sugar: "Sugar",
  },
  changePassword: {
    changePassword: "Change Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    conformPassword: "Confirm Password",
  },
  drawer: {
    healthBuddy: "Uncurl: health",
    mainMenu: "MAIN MENU",
    chronologyOfEvents: "Chronology of Events",
    labHistory: "Lab History",
    medicalHistory: "Medical History",
    caseHistory: "Case History",
    testReport: "Test Reports",
    outLook: "Quick Outlook",
    trendsToWatch: "Trends To Watch",
    testValue: "Test Values",
    trendingChart: "Trending Charts",
    healthTrend: "Health Trends Explained",
    logout: "Logout",
  },
  registration: {
    registration: "Registration",
    generationCaption: "Select your generation according to your birth year.",
    genderCaption: "Select your gender",
    address: "Address",
    contact: "Contact",
    selectYourCountry: "Select your country",
    selectYourState: "Select your state",
  },
  trendingCharts: {
    trendingChart: "Trending Charts",
    checkValueToDetermine: "Check values to determine the best treatment",
    hemoglobin: "Hemoglobin",
    wbc: "WBC",
  },
  profilePopup: {
    fillRegistrationForm: "Fill Profile Form",
    thisGivesYou:
      "This gives you a picture of where you are currently on your journey towards achieving full control and ownership of your health by constantly participating and sharing needed medical information and records with us. This is the essential step to achieve healthy living in the future. A high score here implies that you are well on your way to fully utilizing our services to maximize your health benefits.",
  },
  notifications: {
    notification: "Notifications",
    unread: "Unread",
    read: "Read",
  },
  addMedicalHistorySheet: {
    addMedicalHistory: "Add Medical History",
    labCollectionDate: "Lab collection Date",
    sampleReport: "Sample Report",
    visitType: "Visit Type",
    anyTestsPrescribed: "Any tests prescribed by Doctor during this visit?",
    medicinesPrescriptions: "Medicines/Prescriptions",
    pleaseAttachBelow:
      "Please attach below prescription reports from your hospital visits. We will then try to auto-populate the form below with the information from the prescription report for you. We recommend that you then review the auto-populated information below and correct as needed and fill in the information that we are unable to auto-populate.",
    note: "Note:",
    ifYouDo:
      "If you do not have any prescription report then please proceed to manually fill in the form below for each hospital visit as much as you can. Please fill in for each hospital visit. As you fill in this information you will see that the information will be summarised on the first page of this screen for your easy reference in the future.",
    outpatient: "Outpatient",
    inpatientHospitalized: "Inpatient/Hospitalized",
    doYouHaveDischarge: "Do you have a copy of your hospital discharge/ Release report or summary.",
    doYouHavePrescriptionReport: "Do you have a copy of your prescription report or summary.",
    pleaseAttachPrescription:
      "Please attach your prescription report and we will try to auto populate the form below for you. Please check and correct the auto populated information as needed, and click on the submit button to store your record in your personalized digital wallet. You can access and even print if you need to.",
    pleaseAttachDischarge:
      "Please attach your discharge report and we will try to auto populate the form below for you. Please check and correct the auto populated information as needed, and click on the submit button to store your record in your personalized digital wallet. You can access and even print if you need to.",
    yes: "Yes",
    no: "No",
    pleasFillForm: "Please fill the below form",
  },
  addHistoryPopup: {
    addMedicine: "Add Medicine",
    strength: "Strength",
    nofDays: "No. of Days",
  },
  settings: {
    changePassword: "Change Password",
    loginAlerts: "Login Alerts",
    inAppNotifications: "In-App Notifications",
    email: "Email",
    deleteAccount: "Delete Account",
  },
  testReportScreen: {
    selectDate: "Select Date",
    noReportsAvailable: "There No Reports Available of This Date",
    dr: "Dr. ",
    status: "Status",
    report: "Report",
    seeAttachment: "See Attachment",
    CollectionDate: "Collection Date",
    anomalyExplanation: "Anomaly Explanation",
    goodNewsAll:
      "Good news! All your test results are perfectly normal on this report.\n\nWe recommend that you still review the report with your doctor if these tests were suggested as a part of diagnosis of certain medical condition.",
    actionNeededReview: "Action Needed\nReview this test report with your doctor and take any medicines as prescribed by your doctor.",
  },
  testReportWalkthrough: {
    fillRegistrationForm: "Fill Profile Form",
    uploadTestReports: "Upload Test Reports",
    pleaseClickAnd: "Please click and fill out Hospital visit information and upload medical reports here",
  },
  menu: {
    menu: "Menu",
    brandStory: "Brand Story",
    videos: "VIDEOS",
    newsFeed: "News Feed",
    helpCenter: "Help Center",
    settings: "Settings & Privacy",
    terms: "Terms & Policies",
  },
  helpCenter: {
    yourSubscription: "Your Subscriptions",
    myUsage: "My Usage",
    paymentHistory: "Payment History",
    faqs: "FAQ's",
    contactUs: "Contact Us",
  },
  healthTrendsExplain: {
    consultYourPhysician: "Consult your physician",
    consultDescription: "Hemoglobin values are showing gradual dip and is a point of concern. Please consult your physician.",
    wbcValueExceeded: "WBC values exceeded",
    wbcValueDescription: "Last two WBC values have exceeded the upper threshold value.",
    notice: "Notice",
    noticeDescription:
      "You may refer to knowledge Zone for more information on various health parameters. Also to understand what impact they may have specifically on your short or long Term health.",
  },
  caseHistory: {
    filterByDate: "Filter by Date",
    attendingPhysician: "Primary Physician",
    visitType: "Visit Type",
    visitID: "Visit ID",
    admissionDate: "Admission date",
    releaseDate: "Release date",
    hospitalVisitDate: "Hospital visit date",
    hospitalName: "Hospital name",
    icdCode: "ICD Code:",
    diagnosis: "Diagnosis",
    temp: "Temp",
    spo2: "SPO2",
    height: "Height (cm)",
    diastolic: "Diastolic",
    bp: "BP: Systolic",
    weight: "Weight",
    rr: "RR",
    pulse: "Pulse",
    medicines: "Medicines",
    testRecommended: "Tests Recommended",
    testDone: "Tests Done",
    followUpDate: "Follow up Date:",
    physicianNotes: "Physician Notes",
    reports: "Reports",
    prescriptions: "Prescriptions",
    labsTests: "Labs / Tests",
    other: "Other",
    medicalCondition: "Medical Condition",
    initialExaminationFindings: "Initial Examination Findings",
    outPatient: "Outpatient",
    inPatient: "Inpatient",
    dischargeReleaseSummary: "Discharge / Release Summary",
  },
  healthOutlook: {
    medicalConditionStatus: "Medical Condition Status",
    none: "None 🎉",
    totalHospitalVisit: "Total Hospital Visits",
    lastUpdate: "Last update : ",
    thisYear: "100 this year",
    symptomWordCloud: "Symptom Word Cloud",
    visitsMeanTime: "Visits Mean Time",
    days: "/Days",
    healthiestSpan: "Healthiest Span",
    heathiestSpanTime: "Jan'22 - May'22",
    worstSpanTime: "Dec'22 - May'23",
    worstSpan: "Worst Span",
    now: "Now",
    twoYear: "2y",
    fiveYear: "5y",
    medicinesWordCloud: "Medicines Word Cloud",
    medicalIssues: "Medical Issues",
    myHealthOutlook: "My Health Outlook",
  },
  healthServiceProviders: {
    select: "Select",
    selectCity: "Select City",
    selectYourCity: "Select your city",
    selectYourHospital: "Select your",
    continueWith: "Continue with",
    addNew: "Add New",
    hospitalName: "Hospital Name",
    labName: "Lab Name",
    hospital: "Hospitals",
    hospitalSingular: "Hospital",
    labTestCenters: "Labs/Test Centers",
    physiciansSpecialists: "Physicians/Specialists",
    physicianSpecialist: "Physician/Specialist",
    healthServiceProviderInfo: "Health Service Providers Information",
    uhId: "UHID: ",
    pleaseListHospitals: "Please list hospitals that you typically go for medical consultations.",
    pleaseListLabs: "Please list Labs/ Test centers that you typically use for all your tests.",
    pleaseListAll: "Please list all doctors that you typically see for all your medical consultations",
  },
  paymentHistory: {
    refNo: "Ref No:",
    paidOn: "Paid on",
    paymentHistory: "Payment history",
    paymentMode: "Payment Mode: ",
    amount: "Amount: ",
  },
  faqs: {
    frequentlyAsked: "Frequently Asked Questions (FAQs)",
    features: "Features",
    howDoesThe: "How does the various levels of health services work?",
    weHaveVariousLevel:
      "We have various levels of health services like Bronze, Silver, and more. More advanced level of health services will be launched in the future. Each health service has distinct features designed to give you specific set of insights about your health. You start with our basic health service like Bronze and then move to advanced health services to get better and deeper health insights. The eventual aim is to help you manage your lifestyle choices and prevent chronic diseases from affecting your health and life.\n\nIn our Advanced Health Plans, Powerful AI models continuously scans and investigates all your health records to create a holistic picture of your health for future health predictions and disease prevention.",
    whichHealthServicePlan: "Which health service plan should I sign up for?",
    weRecommendYou: "We recommend you start with our basic plan i.e., “Bronze” first, before moving on to other advanced health service plans.",
    howCanIGetCustomize: "How can I get customized health insights so that I can better manage and control my health and live a healthy life?",
    theBenefitsOfOurHealth:
      "The benefits of our health services depend on you taking charge of your health first. This basically means you will have to actively participate throughout your association with us. We need all your historical medical records and test reports. This means past, present and future medical records.\n\nWe need to know what kind of health issues you had in the past. From time to time, we will require you to participate in our customized health surveys to know how you are doing. The more you tell us about yourself and your past health issues and provide us with all medical records and test reports, the better and more accurate will be our AI model’s prediction about your current and future health risks. We will also be able to give you suitable preventive health measures including. lifestyle choices to improve your health disease prevention.\n\nOnce you have given us historical medical records and test reports, you have to then keep adding medical records and test reports as and when you visit a hospital or clinic or get any tests done. Ideally you should add information regarding hospital visit or tests done within one week of you visit to the hospital or lab for tests.",
    howDoImprove: "How do I improve my Participation Performance Score(PPS) to get maximum health benefits?",
    yourParticipationScore:
      "Your Participation Performance Score(PPS) is simply calculated based on how much of your past as well as present medical records as well as hospital visit information (such as medical issues, prescription records, Doctor diagnosis, tests recommended) you have provided to us. Your score is converted to percentile score by comparing against score obtained by all members within your same generation (age group) and gender. You get customized health insights including risk factors, lifestyle recommendations etc. based On all these records. We cannot provide you with customized health insights unless you participate and provide us with these records for our AI platform’s pattern and deeper analysis. The PPS score is continuously assessed and upgraded as your keep adding these information in the future.",
  },
  mySubscription: {
    headerTitle: "My Subscription",
    currentPlan: "Current Health Plan",
    amount: "Amount",
    startDate: "Start Date",
    endDate: "End Date",
    cancelSubscription: "Cancel Subscription",
    freeTrialPlan: " (Free 30-Day trial Plan)",
  },
  subscriptionPlan: {
    duration: " per user/month",
    freeTrial: "(after 30 days free trial)",
    subTitle: "(Included all taxes and Apple fees)",
    cancelSubs: "Cancel Subscription",
    psScore: "PPS score is less than 5",
    alertDesc: "you can't upgrade to Silver plan.Please refer dashboard to understand your PPS score",
  },
  myUsage: {
    plan: "Plan",
    sessions: "Sessions",
    totalDayTime: "Total Day Time",
  },
};
