import React, { useEffect, useState } from "react";
import HeaderBeforeLogin from "../screens/HomePageBeforeLogin/HeaderBeforeLogin";
import "../screens/HomePageBeforeLogin/HomePageBeforeLogin.css";
import Container from "@mui/material/Container";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { createCheckoutSessionWithoutToken, getSubscriptionDetailFlag, getUserDetailsByPatientId } from "../constants/apiList";
import { toast } from "react-toastify";
import ToastMessage from "../shared/ToastMessage";
import { Link, NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

const UserDetails = ({ label, value }) => (
  <div className="paying-user-colum">
    <div className="user-label">{label}</div>
    <div className="user-value">{value}</div>
  </div>
);

const PaymentByWhatsappNotification = () => {
  const searchParams = new URLSearchParams(location.search);
  const getPid = searchParams.get("pId");
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);

  const getUserDetail = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}${getUserDetailsByPatientId}/${getPid}`)
      .then((res) => {
        setUserDetail(res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getSubscriptionStatus = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}${getSubscriptionDetailFlag}/${getPid}`)
      .then((res) => {
        setSubscriptionStatus(res.data.data);
        if (!!res.data.data) {
          toast.success("Your plan is already activated, please click on login to use the system");
        }
      })
      .catch((error) => {});
  };

  function getPaymentSuccessBaseUrl() {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = arr[0] + "//" + arr[2] + `/login-email`;
    return newPath;
  }

  function getPaymentCancelBaseUrl() {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = arr[0] + "//" + arr[2] + "/homeBeforeLogin";
    return newPath;
  }

  const paymentHandler = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}${createCheckoutSessionWithoutToken}/1/${getPid}?successURL=${getPaymentSuccessBaseUrl()}&cancelURL=${getPaymentCancelBaseUrl()}`)
      .then((response) => {
        window.location.href = response.data.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getUserDetail();
    getSubscriptionStatus();
  }, []);

  return (
    <div className="home">
      <ToastMessage />
      <HeaderBeforeLogin />
      <div className="plan-details-section">
        <Container maxWidth="lg">
          <div className="paying-user-details">
            <h5 className="mb-3">User Details</h5>
            <UserDetails
              label="First Name"
              value={userDetail?.firstName}
            />
            <UserDetails
              label="Last Name"
              value={userDetail?.lastName}
            />
            <UserDetails
              label="Email"
              value={userDetail?.email}
            />
            <UserDetails
              label="Phone Number"
              value={userDetail?.phoneNumber}
            />
            <UserDetails
              label="Subscription Status "
              value={subscriptionStatus ? "Active" : "In-Active"}
            />
            {!!subscriptionStatus ? (
              <Link to="/login-email">
                <button className="universal-btn">
                  {loading && (
                    <CircularProgress
                      sx={{ color: "#fff" }}
                      size={20}
                    />
                  )}
                  Login
                </button>
              </Link>
            ) : (
              <button
                className="universal-btn"
                onClick={paymentHandler}
              >
                {loading && (
                  <CircularProgress
                    sx={{ color: "#fff" }}
                    size={20}
                  />
                )}
                {!loading && "Pay Now"}
              </button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PaymentByWhatsappNotification;
