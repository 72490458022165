import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import documentText from "../../../static/img/document-text.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getAccessToken } from "../../configration/HandleCookies";
import Spinner from "../../shared/Spinner";
import ModelPopUp from "../../shared/model/ModelPopUp";
import InfoModel from "../../shared/simpleModel/InfoModel";

const LabsTestDocumentPopup = (props) => {
  const { isModalOpenForLabTest, setIsModalOpenForLabTest, reportTitle, visitData } = props;
  const [getDocLabTest, setGetDocLabTest] = useState([]);
  const [viewDocs, setViewDocs] = useState("");
  const [isInfoModeOpen, setIsInfoModeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getDocument = () => {
    AxiosInstance.get(`test-report/list?visitId=${visitData?.visitId}`)
      .then((response) => {
        setGetDocLabTest(response);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    getDocument();
  }, []);

  // let token = getAccessToken();
  let token = window.sessionStorage.getItem("token");

  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const handleDocDownload = (docLink) => {
    setIsLoading(true);
    const downloadApi = "test-report/download";
    fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${docLink}`, requestOptions)
      .then((responseDoc) => {
        if (!responseDoc.ok) {
          throw new Error(`Network response was not ok: ${responseDoc.status}`);
        }
        setIsInfoModeOpen(true);
        const contentDispositionHeader = responseDoc.headers.get("Content-Disposition");
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            responseDoc.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setViewDocs(blobUrl);
              setIsLoading(false);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
            setIsLoading(false);
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error fetching or downloading the file:", error);
        setIsLoading(false);
      });
  };

  return (
    <ModelPopUp isOpen={isModalOpenForLabTest} setIsOpen={setIsModalOpenForLabTest} heading={`${reportTitle ? reportTitle : "Sample Report"}`}>
       {getDocLabTest.length === 0 && (
          <span className="click-on-icon-img">No Test Report Found</span>
      )}
      {getDocLabTest.length != 0 && (
      <div>
        <span className="click-on-icon-img">Click on below icon to view attachment</span>
        <div style={{ display: "flex", marginTop: "10px" }}>
          {getDocLabTest.map((resDoc, index) => {
            let docLink = resDoc?.testReport?.link;
            return (
              <div
                style={{ marginRight: "10px" }}
                className="documentIconReport" onClick={() => handleDocDownload(docLink)}
              >
                <img
                  className="link-icon"
                  src={documentText}
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            );
          })}
        </div>
      </div>
      )}
      {isInfoModeOpen && (
        <InfoModel
          open={isInfoModeOpen}
          setOpen={setIsInfoModeOpen}
          maxWidth={"md"}
          contentLabel="Image Popup"
        >
          {!isLoading && (
            <div className="otherDocImg">
              {/* <img src={`${viewDocs}`} /> */}
              <object
                data={`${viewDocs}#toolbar=0&navpanes=0`}
                type="application/pdf"
                style={{ height: "100vh", width: "100%" }}
                scrolling="no"
              >
                <p>
                  It appears you don't have a PDF plugin for this browser. You can <a href={`${viewDocs}`}>click here to download the PDF file.</a>
                </p>
              </object>
            </div>
          )}
          {isLoading && <Spinner />}
        </InfoModel>
      )}
    </ModelPopUp>
  );
};

export default LabsTestDocumentPopup;
