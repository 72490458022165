import React from "react";

const CustomXAxisTick = ({ x, y, payload, highlightedLabel, boxWidth }) => {
  return (
    <foreignObject
      x={x - 10}
      y={y - 5}
      width={boxWidth}
      height={20}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      <div
        style={{
          backgroundColor: highlightedLabel === payload.value ? "#5353e9" : "transparent",
          borderRadius: `3px`,
          color: highlightedLabel === payload.value ? "#fff" : "#000",
          fontSize: "12px",
        }}
      >
        {payload.value}
      </div>
    </foreignObject>
  );
};

export default CustomXAxisTick;
