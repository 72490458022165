import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";

const PhysiotherapistProfile = () => {
  const [formData, setFormData] = useState({
    name: "",
    drMailId: "",
    drContactNumber: "",
    ownClinic: "no",
    clinicContactNumber: "",
    clinicName: "",
    clinicAddress: "",
    registeredPhysiotherapist: "no",
    educationalBackground: "",
    specialization: "",
    guidelines: "no",
    whichGuidelines: "",
    conditionsTreated: "",
    clientsWorkedWith: "",
    yearsOfExperience: "",
    onlineSessions: "no",
    affiliatedHospital: "no",
    hospitalExperienceYears: "",
    numberOfHospitals: "",
    hospitalName: "",
    homeVisit: "no",
    ageGroupServed: "",
    googleRating: "",
    willingToProvideReferences: "no",
    medicalConditions: [],
    consultingRate: "",
    packageServices: "no",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setsubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testimonialFiles, setTestimonialFiles] = useState([]);
  const [apiTestimonialFiles, setApiTestimonialFiles] = useState([]);
  const [fileUploading, setfileUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name]; // Remove the error for the current field
      return updatedErrors;
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    setTestimonialFiles((prevFiles) => [...prevFiles, ...files]);
    // Clear error when valid files are selected

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.testimonialFiles;
      return updatedErrors;
    });

    // Call POST API
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    setfileUploading(true);

    // Replace this with your Axios API call
    AxiosInstance.post("physician/testimonial-file-upload", formData)
      .then((response) => {
        toast.success("Files uploaded successfully");
        setfileUploading(false);
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
        toast.error("Failed to upload files. Please try again.");
        setfileUploading(false);
      });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedConditions;

    if (value === "Other") {
      updatedConditions = checked
        ? [...formData.medicalConditions, "Other"]
        : formData.medicalConditions.filter(
            (condition) => condition !== "Other"
          );
      setFormData({
        ...formData,
        medicalConditions: updatedConditions,
        otherCondition: checked ? formData.otherCondition : "", // Reset "Other" input if unchecked
      });

      // Clear validation if unchecked
      if (!checked) {
        setErrors((prevErrors) => ({ ...prevErrors, otherCondition: "" }));
      }
    } else {
      updatedConditions = checked
        ? [...formData.medicalConditions, value]
        : formData.medicalConditions.filter((condition) => condition !== value);
      setFormData({ ...formData, medicalConditions: updatedConditions });
    }

    if (
      updatedConditions.length > 0 &&
      !(updatedConditions.length === 1 && updatedConditions[0] === "")
    ) {
      setErrors((prevErrors) => ({ ...prevErrors, medicalConditions: "" }));
    }
  };

  const handleOtherConditionChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      otherCondition: value,
    }));

    // Clear validation when user starts typing
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, otherCondition: "" }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.drContactNumber)
      newErrors.drContactNumber = "Contact number is required";
    if (!formData.drMailId) newErrors.drMailId = "Email is required";
    if (formData.ownClinic === "yes" && !formData.clinicName)
      newErrors.clinicName = "Clinic name is required";
    if (formData.ownClinic === "yes" && !formData.clinicAddress)
      newErrors.clinicAddress = "Clinic address is required";
    if (formData.ownClinic === "yes" && !formData.clinicContactNumber)
      newErrors.clinicContactNumber = "Clinic contact number is required";
    if (formData.guidelines === "yes" && !formData.whichGuidelines)
      newErrors.whichGuidelines = "Guidelines are required";
    if (
      formData.affiliatedHospital === "yes" &&
      !formData.hospitalExperienceYears
    ) {
      newErrors.hospitalExperienceYears =
        "Experience in years/months is required";
    }
    if (formData.affiliatedHospital === "yes" && !formData.numberOfHospitals) {
      newErrors.numberOfHospitals = "Number of hospitals is required";
    }
    if (formData.affiliatedHospital === "yes" && !formData.hospitalName) {
      newErrors.hospitalName = "Hospital Name is required";
    }
    if (!formData.ageGroupServed.trim())
      newErrors.ageGroupServed = "Age group is required.";

    if (!formData.googleRating.trim())
      newErrors.googleRating = "Google rating is required.";
    // if (formData.medicalConditions.length === 0)
    //   newErrors.medicalConditions = "Please select at least one condition.";
    const filteredMedicalConditions = formData.medicalConditions.filter(
      (condition) => condition.trim() !== ""
    );

    if (filteredMedicalConditions.length === 0) {
      newErrors.medicalConditions = "Please select at least one condition.";
    } else {
      // Add filteredMedicalConditions to the form data to be sent
      formData.medicalConditions = filteredMedicalConditions;
    }
    if (!formData.consultingRate.trim())
      newErrors.consultingRate = "Consulting rate is required.";

    if (
      formData.willingToProvideReferences === "yes" &&
      apiTestimonialFiles.length === 0 &&
      testimonialFiles.length === 0
    ) {
      newErrors.testimonialFiles = "Please attach the files.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      let finalMedicalConditions = [...formData.medicalConditions];

      // Validation for "Other"
      if (
        finalMedicalConditions.includes("Other") &&
        (!formData.otherCondition || formData.otherCondition.trim() === "")
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          otherCondition: "Please type the disease.",
        }));
        return;
      }

      // Replace "Other" with custom input
      if (finalMedicalConditions.includes("Other") && formData.otherCondition) {
        finalMedicalConditions = finalMedicalConditions.map((condition) =>
          condition === "Other" ? formData.otherCondition : condition
        );
      }
      const booleanFields = [
        "registeredPhysiotherapist",
        "guidelines",
        "onlineSessions",
        "ownClinic",
        "affiliatedHospital",
        "homeVisit",
        "willingToProvideReferences",
        "packageServices",
      ];

      // Convert yes/no fields to boolean and keep other fields unchanged
      const submittedData = {
        fullName: formData.name,
        email: formData.drMailId,
        contactNumber: formData.drContactNumber,
        clinicName: formData.clinicName || null,
        clinicContactNumber: formData.clinicContactNumber || null,
        clinicAddress: formData.clinicAddress || null,
        educationalBackground: formData.educationalBackground,
        specialization: formData.specialization,
        whichGuidelines: formData.whichGuidelines,
        conditionsTreated: formData.conditionsTreated,
        clientsWorkedWith: formData.clientsWorkedWith,
        yearsOfExperience: formData.yearsOfExperience,
        hospitalExperienceYears: formData.hospitalExperienceYears,
        ageGroupServed: formData.ageGroupServed,
        googleRating: formData.googleRating,
        numberOfHospitals: formData.numberOfHospitals,
        hospitalName: formData.hospitalName,
        // medicalConditions: formData.medicalConditions.join(", "),
        medicalConditions: finalMedicalConditions.join(", "),
        consultingRate: formData.consultingRate,
        ...booleanFields.reduce((acc, field) => {
          if (formData[field] === "yes") {
            acc[field] = true;
          } else if (formData[field] === "no") {
            acc[field] = false;
          } else {
            acc[field] = formData[field];
          }
          return acc;
        }, {}),
      };

      console.log("Form Submitted", submittedData);
      AxiosInstance.post("physician/create-profile", submittedData)
        .then((response) => {
          setsubmitted(true);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
    // Call an API to submit formData if needed
  };

  const predefinedConditions = [
    "Obesity",
    "Hypertension",
    "Diabetes",
    "CVD",
    "Cancers",
    "Chronic Respiratory diseases",
    "PCOS/ PCOD",
    "Thyroid",
  ];

  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("physician/get-profile")
      .then((response) => {
        if (response != null) {
          const conditionsFromAPI = response.medicalConditions
            .split(",")
            .map((condition) => condition.trim());

          const matchedConditions = conditionsFromAPI.filter((condition) =>
            predefinedConditions.includes(condition)
          );
          const unmatchedConditions = conditionsFromAPI.filter(
            (condition) => !predefinedConditions.includes(condition)
          );

          setFormData({
            name: response.fullName || "",
            drMailId: response.email || "",
            drContactNumber: response.contactNumber || "",
            ownClinic: response.ownClinic ? "yes" : "no",
            clinicName: response.clinicName || "",
            clinicContactNumber: response.clinicContactNumber || "",
            clinicAddress: response.clinicAddress || "",
            registeredPhysiotherapist: response.registeredPhysiotherapist
              ? "yes"
              : "no",
            educationalBackground: response.educationalBackground || "",
            specialization: response.specialization || "",
            guidelines: response.guidelines ? "yes" : "no",
            whichGuidelines: response.whichGuidelines || "",
            conditionsTreated: response.conditionsTreated || "",
            clientsWorkedWith: response.clientsWorkedWith || "",
            yearsOfExperience: response.yearsOfExperience || "",
            onlineSessions: response.onlineSessions ? "yes" : "no",
            affiliatedHospital: response.affiliatedHospital ? "yes" : "no",
            hospitalExperienceYears: response.hospitalExperienceYears || "",
            numberOfHospitals: response.numberOfHospitals || "",
            hospitalName: response.hospitalName || "",
            homeVisit: response.homeVisit ? "yes" : "no",
            ageGroupServed: response.ageGroupServed || "",
            googleRating: response.googleRating || "",
            willingToProvideReferences: response.willingToProvideReferences
              ? "yes"
              : "no",
            medicalConditions: matchedConditions,
            otherCondition: unmatchedConditions.join(", ") || "",
            consultingRate: response.consultingRate || "",
            packageServices: response.packageServices ? "yes" : "no",
          });

          // Automatically check "Other" if there are unmatched conditions
          if (unmatchedConditions.length > 0) {
            setFormData((prev) => ({
              ...prev,
              medicalConditions: [...prev.medicalConditions, "Other"],
            }));
          }
          setApiTestimonialFiles(response.testimonialFile || []);
        } else {
          const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
          if (userDetails) {
            setFormData({
              ...formData,
              name: `${userDetails.firstName} ${userDetails.lastName}`,
              drMailId: userDetails.email || "",
              drContactNumber: userDetails.phoneNumber || "",
            });
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setLoading(false);
      });
  }, []);

  console.log("Form Submitted before", formData);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mx: "auto",
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: "background.paper",
      }}
    >
      {submitted ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 2,
          }}
        >
          <div>
            <div>
              <MdCheckCircle size={50} color="green" />
            </div>
            <Typography variant="h6" sx={{ color: "green" }}>
              Profile has been submitted Successfully.
            </Typography>
          </div>
        </Box>
      ) : (
        <>
          <Typography variant="h4" mb={2}>
            Physiotherapist Profile
          </Typography>

          {/* Name */}
          {loading ? (
            <Spinner></Spinner>
          ) : (
            <>
              <Row>
                <Col lg={6}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f5f5f5", // Light grey background
                        color: "#000", // Ensure content is visible
                        fontSize: "1rem", // Increase font size for readability
                        fontWeight: "bold", // Make text bold for better visibility
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#888", // Optional: Grey label color to match the style
                      },
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <TextField
                    label="Dr. Contact Number"
                    name="drContactNumber"
                    value={formData.drContactNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.drContactNumber}
                    helperText={errors.drContactNumber}
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f5f5f5", // Light grey background
                        color: "#000", // Ensure content is visible
                        fontSize: "1rem", // Increase font size for readability
                        fontWeight: "bold", // Make text bold for better visibility
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#888", // Optional: Grey label color to match the style
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <TextField
                    label="Dr. Mail ID"
                    name="drMailId"
                    value={formData.drMailId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.drMailId}
                    helperText={errors.drMailId}
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f5f5f5", // Light grey background
                        color: "#000", // Ensure content is visible
                        fontSize: "1rem", // Increase font size for readability
                        fontWeight: "bold", // Make text bold for better visibility
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#888", // Optional: Grey label color to match the style
                      },
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you have your own clinic?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="ownClinic"
                      value={formData.ownClinic}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              {formData.ownClinic === "yes" && (
                <>
                  <Row>
                    <Col lg={6}>
                      <TextField
                        label="Clinic Name"
                        name="clinicName"
                        value={formData.clinicName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.clinicName}
                        helperText={errors.clinicName}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Clinic Contact Number"
                        name="clinicContactNumber"
                        value={formData.clinicContactNumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.clinicContactNumber}
                        helperText={errors.clinicContactNumber}
                      />
                    </Col>
                    <Col lg={12}>
                      <TextField
                        label="Clinic Address with Pincode"
                        name="clinicAddress"
                        value={formData.clinicAddress}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.clinicAddress}
                        helperText={errors.clinicAddress}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {/* Are you a registered physiotherapist? */}
              <Row>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Are you a registered physiotherapist?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="registeredPhysiotherapist"
                      value={formData.registeredPhysiotherapist}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
                <Col lg={6}>
                  <TextField
                    label="What is your educational background for providing physiotherapy services?"
                    name="educationalBackground"
                    value={formData.educationalBackground}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <TextField
                    label="What is your physical therapy specialization in?"
                    name="specialization"
                    value={formData.specialization}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you follow any specific guidelines for your practice?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="guidelines"
                      value={formData.guidelines}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              {formData.guidelines === "yes" && (
                <Row>
                  <Col lg="12">
                    <TextField
                      label="Which guideline ?"
                      name="whichGuidelines"
                      value={formData.whichGuidelines}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.whichGuidelines}
                      helperText={errors.whichGuidelines}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col lg={6}>
                  <TextField
                    label="What medical conditions or diseases have you successfully treated?"
                    name="conditionsTreated"
                    value={formData.conditionsTreated}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
                <Col lg={6}>
                  <TextField
                    label="How many clients have you worked with in your career?"
                    name="clientsWorkedWith"
                    value={formData.clientsWorkedWith}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <TextField
                    label="How many years of experience do you have?"
                    name="yearsOfExperience"
                    value={formData.yearsOfExperience}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you offer online workout sessions?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="onlineSessions"
                      value={formData.onlineSessions}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  {" "}
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Are you affiliated with any hospital?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="affiliatedHospital"
                      value={formData.affiliatedHospital}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you provide home visit sessions?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="homeVisit"
                      value={formData.homeVisit}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              {formData.affiliatedHospital === "yes" && (
                <>
                  <Row>
                    <Col lg={6}>
                      <TextField
                        label="Experience (Years/Months)"
                        name="hospitalExperienceYears"
                        value={formData.hospitalExperienceYears}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.hospitalExperienceYears}
                        helperText={errors.hospitalExperienceYears}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Number of Hospitals"
                        name="numberOfHospitals"
                        value={formData.numberOfHospitals}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.numberOfHospitals}
                        helperText={errors.numberOfHospitals}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Hospital Names"
                        name="hospitalName"
                        value={formData.hospitalName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.hospitalName}
                        helperText={errors.hospitalName}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row>
                <Col lg={6}>
                  <TextField
                    label="Which age group of people have you provided your services so far?"
                    name="ageGroupServed"
                    value={formData.ageGroupServed}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.ageGroupServed}
                    helperText={errors.ageGroupServed}
                  />
                </Col>
                <Col lg={6}>
                  <TextField
                    label="What is your Google rating?"
                    name="googleRating"
                    value={formData.googleRating}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.googleRating}
                    helperText={errors.googleRating}
                  />
                </Col>
              </Row>

              {/* Willing to Provide References */}

              <Row>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Are you willing to provide references or testimonials from
                      2-3 patients or clients of yours?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="willingToProvideReferences"
                      value={formData.willingToProvideReferences}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {formData.willingToProvideReferences === "yes" && (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          style={{ marginTop: "10px", maxWidth: "200px" }}
                        >
                          {fileUploading ? (
                            <CircularProgress
                              size={24}
                              color="inherit"
                            ></CircularProgress>
                          ) : (
                            "Attach Testimonials"
                          )}
                          <input
                            type="file"
                            multiple
                            hidden
                            onChange={handleFileChange}
                          />
                        </Button>

                        {/* Display selected files */}
                        {(apiTestimonialFiles.length > 0 ||
                          testimonialFiles.length > 0) && (
                          <center style={{ marginTop: "10px" }}>
                            <b>List Of Attached Testimonials</b>
                          </center>
                        )}
                        <List>
                          {apiTestimonialFiles.length > 0 &&
                            apiTestimonialFiles.map((fileName, index) => (
                              <ListItem key={`api-${index}`}>
                                <Typography
                                  style={{
                                    color: "#007bff",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {fileName}
                                </Typography>
                              </ListItem>
                            ))}
                          {testimonialFiles.length > 0 &&
                            testimonialFiles.map((file, index) => (
                              <ListItem key={index}>
                                <Typography
                                  style={{
                                    color: "#007bff",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {file.name}
                                </Typography>
                              </ListItem>
                            ))}
                        </List>

                        {/* Error message */}
                        {errors.testimonialFiles && (
                          <Typography
                            color="error"
                            style={{ marginTop: "10px" }}
                          >
                            {errors.testimonialFiles}
                          </Typography>
                        )}
                      </>
                    )}
                  </FormControl>
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Which of the following medical conditions you have
                      experience in treating?
                    </FormLabel>
                    <FormGroup>
                      {[
                        "Obesity",
                        "Hypertension",
                        "Diabetes",
                        "CVD",
                        "Cancers",
                        "Chronic Respiratory diseases",
                        "PCOS/ PCOD",
                        "Thyroid",
                        "Other",
                      ].map((condition) => (
                        <FormControlLabel
                          key={condition}
                          control={
                            <Checkbox
                              value={condition}
                              checked={
                                //   formData.medicalConditions.includes(
                                //   condition
                                // )
                                condition === "Other"
                                  ? formData.medicalConditions.includes("Other")
                                  : formData.medicalConditions.includes(
                                      condition
                                    )
                              }
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={condition}
                        />
                      ))}
                      {formData.medicalConditions.includes("Other") && (
                        <TextField
                          label="Please specify"
                          value={formData.otherCondition || ""}
                          onChange={(e) =>
                            handleOtherConditionChange(e.target.value)
                          }
                          margin="normal"
                          fullWidth
                          error={!!errors.otherCondition}
                          helperText={errors.otherCondition}
                        />
                      )}
                    </FormGroup>
                    {errors.medicalConditions && (
                      <Typography color="error">
                        {errors.medicalConditions}
                      </Typography>
                    )}
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <TextField
                    label="What is your first one-time typical consulting charge?"
                    name="consultingRate"
                    value={formData.consultingRate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.consultingRate}
                    helperText={errors.consultingRate}
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you give monthly, two-month, three-month, and other
                      packages?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="packageServices"
                      value={formData.packageServices}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default PhysiotherapistProfile;
