import Cookies from "js-cookie";

const clearCookies = async () => {
  Cookies.remove("_acct_kn", {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
  });
  Cookies.remove("profile", {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
  });
  // Cookies.remove("company_code", {
  //   path: "/",
  //   domain: process.env.REACT_APP_SERVER_URL,
  // });
  // Cookies.remove("_reft_kn", {
  //   path: "/",
  //   domain: process.env.REACT_APP_SERVER_URL,
  // });
};

const getAccessToken = () => {
  return Cookies.get("_acct_kn");
};
const getProfile = () => {
  return Cookies.get("profile") ? Cookies.get("profile") : null;
};
const getCompanyCode = () => {
  return Cookies.get("company_code");
};
const getRefreshToken = () => {
  return Cookies.get("_reft_kn");
};
const setCookies = (loginResponse) => {
  Cookies.set("_acct_kn", loginResponse.token, {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
    // expires: convertSecondsToDay(loginResponse.token.expires_in),
    // httpOnly : true
  });
  // Cookies.set("_reft_kn", loginResponse.token.refresh_token, {
  //   path: "/",
  //   domain: process.env.REACT_APP_SERVER_URL,
  //   expires: convertSecondsToDay(loginResponse.token.expires_in),
  //   // httpOnly : true
  // });
  Cookies.set("profile", JSON.stringify(loginResponse), {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
    // expires: convertSecondsToDay(loginResponse.token.expires_in),
  });
  // Cookies.set("company_code", loginResponse.profile.attributes.company_code, {
  //   path: "/",
  //   domain: process.env.REACT_APP_SERVER_URL,
  //   expires: convertSecondsToDay(loginResponse.token.expires_in),
  //   // httpOnly : true
  // });
};

const convertSecondsToDay = (seconds) => {
  return Math.floor(seconds / (3600 * 24));
};

export { clearCookies, getAccessToken, getCompanyCode, getProfile, getRefreshToken, setCookies };
