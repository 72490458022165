import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SendOtpToPatient, migratePatient, patientOtpValidate } from "../../constants/apiList";
import Spinner from "../../shared/Spinner";
import ModelPopUp from "../../shared/model/ModelPopUp";
import OtpTimer from "./OtpTimer";
import { migratePatientSchema } from "./PatientMigrationSchemas";

const PatientMigrationForm = (props) => {
  const { selectedPatient, setIsOpen, isOpen } = props;
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(migratePatientSchema),
  });

  const sendOtpHandler = async () => {
    const mobileNumber = getValues("mobileNumber");
    if (mobileNumber === "") {
      toast.error("Mobile Number is required");
    } else if (mobileNumber.length != 10) {
      toast.error("Mobile Number should be 10 digit");
    } else {
      try {
        const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${SendOtpToPatient}/${mobileNumber}`);
        if (result.data.message === "OTP sent Successfully") {
          toast.success(result.data.message);
          setIsOtpSend(true);
        } else {
          toast.error(result.data.message);
        }
        setIsOtpSend(true);
      } catch (error) {
        console.error("error", error);
      }
    }
  };

  const verifyOtpHandler = async () => {
    const mobileNumber = getValues("mobileNumber");
    const verifyOtp = getValues("verifyOtp");
    try {
      const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${patientOtpValidate}/${mobileNumber}/${verifyOtp}`);
      if (result.data.message === "OTP Verified Successfully") {
        toast.success(result.data.message);
        setIsOtpVerified(true);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onSubmit = async (data) => {
    const { verifyOtp, ...restData } = data;
    setIsLoading(true);
    try {
      const result = await axios.post(`${process.env.REACT_APP_SERVER_URL}${migratePatient}`, restData);
      if (result.data.status === "Success") {
        toast.success(result.data.message);
        setIsOpen(false);
        setIsLoading(false);
        setIsOtpVerified(false);
        setIsOtpSend(false);
        // if (!result.data.message === "Record Exists by given Email") {
        reset();
        // }
      } else {
        toast.error(result.data.message);
        setIsLoading(false);
        // setIsOpen(false);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleTimeout = () => {
    // Callback when the timer reaches 0
    setIsOtpSend(false);
  };

  useEffect(() => {
    setValue("firstName", selectedPatient?.firstName);
    setValue("middleName", selectedPatient?.middleName);
    setValue("lastName", selectedPatient?.lastName);
    setValue("hwPatientIds", [selectedPatient?.id]);
  }, [selectedPatient, isOpen]);

  return (
    <>
      <ModelPopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading="Migrate Patient"
        fullWidth
        closeReset={reset}
      >
        <div className="patients-migration-form">
          <div
            className="other-info"
            style={{ marginBottom: "20px" }}
          >
            <span>UHID: {selectedPatient?.uhid}</span>
            <span>Patient ID: {selectedPatient?.id}</span>
            <span>Age: {selectedPatient?.age}</span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field-wrapper">
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    type="text"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    size="small"
                    fullWidth
                    inputRef={field.ref}
                  />
                )}
              />
              <Controller
                name="middleName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Middle Name"
                    type="text"
                    error={!!errors.middleName}
                    helperText={errors.middleName?.message}
                    size="small"
                    fullWidth
                    inputRef={field.ref}
                  />
                )}
              />
            </div>
            <div className="field-wrapper">
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    type="text"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                    inputRef={field.ref}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    type="email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    size="small"
                    fullWidth
                    inputRef={field.ref}
                  />
                )}
              />
            </div>
            <div className="field-wrapper">
              <Controller
                name="mobileNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Mobile Number"
                    type="number"
                    error={!!errors.mobileNumber}
                    helperText={errors.mobileNumber?.message}
                    size="small"
                    fullWidth
                    className="migrate-number-field"
                    inputRef={field.ref}
                    disabled={isOtpSend}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ minWidth: "fit-content" }}
                            onClick={sendOtpHandler}
                            size="small"
                            disabled={isOtpSend}
                          >
                            {isOtpSend ? (
                              <OtpTimer
                                onTimeout={handleTimeout}
                                isOpen={isOpen}
                              />
                            ) : (
                              "Send OTP"
                            )}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="verifyOtp"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="verify OTP"
                    type="number"
                    error={!!errors.verifyOtp}
                    helperText={errors.verifyOtp?.message}
                    size="small"
                    fullWidth
                    className="migrate-number-field"
                    inputRef={field.ref}
                    disabled={!isOtpSend}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ minWidth: "fit-content" }}
                            onClick={verifyOtpHandler}
                            size="small"
                            disabled={!isOtpSend}
                          >
                            Verify OTP
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="field-wrapper">
              <Controller
                name="secretKey"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Secret Key"
                    type={showPassword ? "text" : "password"}
                    error={!!errors.secretKey}
                    helperText={errors.secretKey?.message}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <Button
              variant="contained"
              type="submit"
              style={{ minWidth: "fit-content" }}
              // disabled={!isOtpVerified}
            >
              Migrate Patient
            </Button>
          </form>
          {isLoading && <Spinner />}
        </div>
      </ModelPopUp>
    </>
  );
};

export default PatientMigrationForm;
