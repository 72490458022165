import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import Diet from "./Diet";
import DietIntervention from "./DietIntervention";
import { useLocation } from "react-router-dom";
import ChildrenDiet from "./ChildrenDiet";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const Nutrition = () => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [familyInfo, setFamilyInfo] = useState();
  const [familyRelation, setFamilyRelation] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    if (location.state && location.state.tabIndex !== undefined) {
      setCurrentTabIndex(location.state.tabIndex);
    }
    if (location.state?.selectedOrder) {
      setSelectedOrder(location.state.selectedOrder); // Set the order when navigating
    }
  }, [location.state]);
  const handleOrderSubmit = () => {
    setSelectedOrder(null);
  };

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        if (response != null) {
          setFamilyInfo(response.profile);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div style={{}}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Tab
            label="My Diet"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "end",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
          <Tab
            label="Diet Intervention"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            {familyInfo?.age > 3 &&
            familyInfo?.age <= 21 &&
            familyRelation !== null ? (
              <ChildrenDiet />
            ) : familyInfo?.age > 21 && familyRelation === null ? (
              <Diet />
            ) : null}
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <DietIntervention
              selectedOrder={selectedOrder}
              onSubmit={handleOrderSubmit}
            ></DietIntervention>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Nutrition;
