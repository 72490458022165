import { MenuItem, TextField } from "@mui/material";
import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Delete } from "../../icons/DeleteIcon";
import ModelPopUp from "../../shared/model/ModelPopUp";
import "./AddMoreFormMain.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";

function SurgicalHistory({Surgeries , onUpdateSurgeries , selectedOption , handleRadioChange , fieldError}) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    hospitalName: yup.string().required("Please enter Hospital Name"),
    surgeryName: yup.string().required("Please enter Surgery"),
    date: yup.string().required("Please enter date"),
  });


  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { hospitalName: "", surgeryName: "" , date: "" },
  });

  const addMoreSurgeryFields = () => {
    setIsOpen(true);
  };

  const AddSurgicalHistoryList = async (data) => {
    const surgeryName = watch("surgeryName");
    const hospitalName = watch("hospitalName");
    const date = watch("date")

  // Create object with form values
  const surgery = {
    "surgeryName": surgeryName,
    "hospitalName": hospitalName,
    "date": date
  };
  onUpdateSurgeries([...Surgeries, surgery]);

  // Reset the form fields
  reset({
    surgeryName: "",
    hospitalName: "",
    date: ""
  });

  // Notify user of successful addition
      toast.success("Surgery added successfully");
       setIsOpen(false);
        setLoading(true);
        reset();
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const deleteSurgery = (indexToDelete) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this Hospital?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedSurgeries = Surgeries.filter((surgery, index) => index !== indexToDelete);
          onUpdateSurgeries(updatedSurgeries);
          toast.success("Surgery deleted successfully");
        }
      });
  };

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend" style={{fontSize:"16px" , fontWeight: 500 , color: fieldError.surgicalFlag ? "red" : "black"}}>Any surgeries in the past ?</FormLabel>
        <RadioGroup
          aria-label="options"
          name="options"
          value={selectedOption}
          onChange={handleRadioChange}
          row
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
     </div>
     {selectedOption == true && ( 
      <div className="health_Service_Providers_Information">
        <div className="info_header_wrapper">
          <div className="info_provider_content">
            <h3>Surgical History</h3>
            <p>Please list all prior surgeries and dates.</p>
          </div>
          <div className="info_provider_button">
            <button
              type="button"
              onClick={addMoreSurgeryFields}
            >
              + Add Surgery
            </button>
          </div>
        </div>
        <div className="add_more_header_wrapper">
          <div className="field_header equal-column">Surgery</div>
          <div className="field_header equal-column">Hospital Name</div>
          <div className="field_header equal-column">Date</div>
          <div className="field_header small-column"></div>
        </div>
        {Surgeries &&
          Surgeries.map((input, index) => {
            return (
              <div
                key={index}
                className="add_more_fields_wrapper"
              >
                <p className="equal-column tableData">{input.surgeryName}</p>
                <p className="equal-column tableData">{input.hospitalName}</p>
                <p className="equal-column tableData">{input.date}</p>
                <button
                  type="button"
                  className="remove_button small-column tableData"
                  onClick={() => deleteSurgery(index)}
                >
                  <Delete />
                </button>
              </div>
            );
          })}
      </div>
     )}
      <ModelPopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading="Add New Surgery"
        handleSubmit={handleSubmit(AddSurgicalHistoryList)}
      >
        <form className="hospital-doctor-add-form">
          <div className={`form-group mt-3 ${errors.surgeryName ? "required-border" : ""}`}>
            <label>Surgery Name</label>
            <Controller
              name="surgeryName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus={true}
                  // label="Please enter Surgery"
                  className="invalid-input-field"
                  fullWidth
                />
              )}
            />
            <p className="error">{errors.surgeryName?.message}</p>
          </div>
          <div className={`form-group mt-3 ${errors.hospitalName ? "required-border" : ""}`}>
            <label>Hospital Name</label>
            <Controller
              name="hospitalName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-select-currency"
                  fullWidth
                >
                </TextField>
              )}
            />
            <p className="error">{errors.hospitalName?.message}</p>
          </div>
          <div className={`form-group mt-3 ${errors.date ? "required-border" : ""}`}>
            <label>Date</label>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  id="outlined-select-currency"
                  fullWidth
                >
                </TextField>
              )}
            />
            <p className="error">{errors.date?.message}</p>
          </div>
          
        </form>
      </ModelPopUp>
    </>
  );
}

export default SurgicalHistory;
