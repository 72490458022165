import React from "react";
import "./BronzeHealthPopup.css";

const BronzeHealthPopup = ({onClose}) => {
    return (
        <>
        <div className="bronzeHealthPopup">
            <div className="BronzeheaderContainer">
                <div className="BronzeHealthPlanHeading" style={{color : "#5353e9"}}>
                  BRONZE HEALTH SERVICE: Managing Health Records
                </div>

                {/* <div onClick={onClose} style={{cursor: "pointer"}}>
                    <img src="/img/close-circle.png"></img>
                </div> */}
            </div>

            <div>

              <div style={{padding: "0px 0px 10px 0px"}}>
                <div className="question">What is the Intent?</div>
                <div className="answer" style={{marginBottom: "10px"}}>The intent of this health service is <span className="question"> "Managing Health Records" </span> , meaning we want people to start taking charge of their health by first managing their health records. In this regard, this is the foundation for our more advanced health services powered through Artificial Intelligence (AI) technologies. Your health journey cannot start without this foundational step. </div>
               </div>

               <div style={{padding: "0px 0px 10px 0px"}}>
                <div className="question">What Does it include? </div>
                <div className="answer" style={{marginBottom: "10px"}}>The service enables you to store all your medical records and history, for example, prescription records, lab or test reports, X Ray, MRI or other imaging records and reports, Health issues diagnosis reports, other hospital visit records, etc. in our secured <span className="question">“MR VAULT”</span>  On-Cloud. Your records are 100% secured and confidential “on-cloud” which complies with stringent HIPAA act of US. This act protects patient health information from being disclosed without the patient’s consent of knowledge.  </div>
               </div>

               <div style={{padding: "0px 0px 10px 0px"}}>
                <div className="question">Why is this Required? </div>
                <div className="answer" style={{marginBottom: "10px"}}>The biggest issue with our health lies in the fact that we don’t keep all our health records in one place for easy reference in the future. These health records carry significantly important health data that can be systematically and continuously analyzed to manage and even improve our health. Often this kind of medical history is also required by physicians or specialists for better diagnosis or treatment in emergency situations. More importantly, this will give you a sense of control of your own health records and eventually health. Also, having all medical records in one place ensures easy access to a required medical record in the future as and when you or your doctor needs it. You don’t have to hunt for your record and depend on labs or hospitals to provide you with the same. As such, most hospitals or even labs won’t be able to provide you with the needed record so easily when you need it the most.   </div>
               </div>

               <div style={{padding: "0px 0px 10px 0px" , textAlign: "center"}}>
                <div className="BronzeHealthPlanHeading" style={{color :"green"}}>Good Luck with your health journey!</div>
               </div>
            </div>
        </div>
        </>
    )
}

export default BronzeHealthPopup