/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconFilledHelp = ({ style, iconFilledHelp = "/img/icon-filled-help-2.png" }) => {
  return <img className="icon-filled-help" style={style} alt="Icon filled help" src={iconFilledHelp} />;
};

IconFilledHelp.propTypes = {
  iconFilledHelp: PropTypes.string,
};
