/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconBenchmark } from "../IconBenchmark";
import "./style.css";

export const NavsBecnchmark = ({ property1, style }) => {
  return (
    <div className={`navs-becnchmark property-1-7-${property1}`} style={style}>
      <div className="menu-item-3">
        <div className="frame-8">
          <div className="frame-9">
            <div className="frame-10">
              <IconBenchmark
                iconBenchmark={property1 === "variant-2" ? "/img/icon-benchmark.png" : "/img/icon-benchmark-5.png"}
                style={{
                  height: "21px",
                  left: "unset",
                  minWidth: "21px",
                  position: "relative",
                  top: "unset",
                  width: "unset",
                }}
              />
              <div className="benchmarks-risk">Benchmarks &amp; Risk</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsBecnchmark.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
};
