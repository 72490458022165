import React from "react"

const NewsFeedModal = () => {
  return (
    <div>
        <video
            id="video"
            autoPlay
            loop
            style={{height: "400px"}}
        >
            <source
            src="/img/ComingSoon.mp4"
            type="video/mp4"
            />
            Your browser does not support the video tag.
        </video>
    </div>
  )
}

export default NewsFeedModal