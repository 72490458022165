import { useState, useEffect } from "react";

const OtpTimer = ({ onTimeout, isOpen }) => {
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    if (!isOpen) {
      setSeconds(0);
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(interval);
          onTimeout();
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isOpen, onTimeout]);

  return <div>{`${Math.floor(seconds / 60)}:${(seconds % 60).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}`}</div>;
};

export default OtpTimer;
