/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconFilledEvents = ({ style, iconFilledEvents = "/img/icon-filled-events-2.png" }) => {
  return <img className="icon-filled-events" style={style} alt="Icon filled events" src={iconFilledEvents} />;
};

IconFilledEvents.propTypes = {
  iconFilledEvents: PropTypes.string,
};
