import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const MedicalHistoryTimeline = ({ hospitalVisitList, isDummyData }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [fullDate, getFullDate] = useState([]);
  const daysToShow = 6;

  const goToPreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - daysToShow);
    setCurrentDate(newDate);
    renderCalendarDays(newDate);
  };

  const goToNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + daysToShow);
    setCurrentDate(newDate);
    renderCalendarDays(newDate);
  };

  const renderCalendarDays = (newDate) => {
    const days = [];
    getFullDate(days);
    for (let i = 0; i < daysToShow; i++) {
      const date = new Date(newDate);
      date.setDate(date.getDate() + i);
      days.push(date);
    }
  };

  useEffect(() => {
    renderCalendarDays(
      hospitalVisitList?.slice(0, 1).map((date) => {
        return date?.hospitalVisitDate;
      })
    );
    setCurrentDate(hospitalVisitList[0]?.hospitalVisitDate);
  }, []);

  return (
    <div className="timeline-calender-container">
      <div className="navigation">
        <TableContainer
          key=""
          component={Paper}
          className="main-table-container"
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="caption table"
            stickyHeader
          >
            <TableHead>
              <TableRow className="calendar-table-row">
                <TableCell className="table-header-tablecell">
                  <span
                    className="prev-next-button"
                    onClick={goToPreviousWeek}
                  >
                    <KeyboardArrowLeftIcon />
                  </span>
                </TableCell>
                {fullDate?.map((day, index) => (
                  <TableCell
                    key={index}
                    className="table-header-tablecell"
                  >
                    {day.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}
                  </TableCell>
                ))}
                <TableCell className="table-header-tablecell">
                  <span
                    className="prev-next-button"
                    onClick={goToNextWeek}
                  >
                    <KeyboardArrowRightIcon />
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "0px", borderBottom: "none" }}></TableCell>
                {fullDate?.map((row, index) => {
                  const targetDate = new Date(row);
                  const matchingObjects = hospitalVisitList?.filter((obj) => {
                    const hospitalVisitDate = new Date(obj.hospitalVisitDate);
                    const collectionDateDay = hospitalVisitDate?.getDate();
                    const collectionDateMonth = hospitalVisitDate?.getMonth();
                    const collectionDateYear = hospitalVisitDate?.getFullYear();
                    const targetDateDay = targetDate?.getDate();
                    const targetDateMonth = targetDate?.getMonth();
                    const targetDateYear = targetDate?.getFullYear();
                    return collectionDateDay === targetDateDay && collectionDateMonth === targetDateMonth && collectionDateYear === targetDateYear;
                  });

                  return (
                    <>
                      <TableCell
                        key={index}
                        className="calendar-table-cell"
                        style={{ borderBottom: "none" }}
                      >
                        {matchingObjects?.map((row, index) => {
                          let valNew = row?.diagnosis?.medicalCondition?.split(",");
                          return (
                            <div key={index}>
                              {valNew?.map((item, index) => {
                                return (
                                  <div
                                    className="test-done"
                                    key={index}
                                  >
                                    <p>{item}</p>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </TableCell>
                    </>
                  );
                })}
                <TableCell style={{ width: "0px", borderBottom: "none" }}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MedicalHistoryTimeline;
