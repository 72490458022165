import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Input, Row } from "reactstrap";
import "./LifeStyle.css";
import { Card } from "@mui/material";
import Spinner from "../../shared/Spinner";

const Environment = () => {
  const [myEnvironment, setMyEnvironment] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("form/environmentSketch")
      .then((response) => {
        if (response) {
          setMyEnvironment(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  console.log("diet", myEnvironment);
  return (
    <div>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-2 dietHeadings">
              <h5>Your Environment</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    1. This applies to you :
                  </label>
                  <span className="inputDiet">
                    {myEnvironment?.workProfile}
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    2. I am quite happy and contented in life and free from any
                    kind of Psychological stress :
                    <span className="inputDiet">
                      {myEnvironment?.freeFromPsychologicalStress
                        ? "Yes"
                        : "No"}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    3. Where I live, the air is relatively clean and free from
                    dust or chemicals :
                    <span className="inputDiet">
                      {myEnvironment?.pollutedAir ? "Yes" : "No"}
                    </span>
                  </label>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    4. Time you spend in traffic every day to go to workplace or
                    place of study :{" "}
                    <span className="inputDiet">
                      {myEnvironment?.timeSpentInTraffic}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    5. Hours you typically spend daily on a laptop for your work
                    (for students and working professionals only) :{" "}
                    <span className="inputDiet">
                      {myEnvironment?.timeSpentOnLaptop}
                    </span>
                  </label>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    6. Members in your family (living with you) :
                  </label>
                  <span className="inputDiet">
                    {myEnvironment?.familyMembers?.split(";").join(", ")}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    7. Your Living Arrangement :
                  </label>
                  <span className="inputDiet">
                    {myEnvironment?.livingArrangement}
                  </span>
                </Col>
                <Col lg={6}>
                  <label className="questionLabel">
                    8. You have problems with (Hearing , Speech , Sight) :
                  </label>
                  <span className="inputDiet">
                    {myEnvironment?.problemsWith == "None Of the Above"
                      ? "None"
                      : myEnvironment?.problemsWith}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={6}>
                  <label className="questionLabel">
                    9. Have you ever feel depressed :
                  </label>
                  <span className="inputDiet">
                    {myEnvironment?.fellDepressed}
                  </span>
                </Col>
                {myEnvironment?.fellDepressed == "yes" && (
                  <Col lg={6}>
                    <label className="questionLabel">
                      10. If you ever feel depressed then what is your level of
                      intensity of depression (1 = very low , 2 = low , 3 =
                      Medium , 4 = Moderate , 5 = High ) :
                      <span className="inputDiet">
                        {myEnvironment?.intensityOfDepression}
                      </span>
                    </label>
                  </Col>
                )}
              </Row>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default Environment;
