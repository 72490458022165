import React from "react";
import AppLogoWithName from "../../../static/img/AppLogoWithName.png";
import maintenance from "../../img/maintenance-cover.png";
import livingScope from "../../img/living-scope-health-brand.png";
import Container from "@mui/material/Container";

const Maintenance = () => {
  return (
    <div class="maintenance-mode">
      <Container>
        <div className="maintenance-mode-inner">
          <div className="maintenance-left">
            <img
              src={maintenance}
              className="mb-3"
            />
          </div>
          <div className="maintenance-right">
            <div className="uncurl-brand">
              <img src={AppLogoWithName} />
            </div>
            <h1>We'll be back soon!</h1>
            <p>
              We are currently undergoing scheduled maintenance. <br />
              <br />
              Thank you for your patience.
            </p>
            <img
              src={livingScope}
              className="mt-3"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Maintenance;
