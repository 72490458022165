import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { searchPatientMobileSchema } from "./PatientMigrationSchemas";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import PatientMigrationTable from "./PatientMigrationTable";
import { addTrialSubscription, getSignupUser } from "../../constants/apiList";
import ToastMessage from "../../shared/ToastMessage";

const NewPatientMigration = () => {
  const [searchPatient, setSearchPatient] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchPatientMobileSchema),
  });

  const onSubmit = async (data) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${getSignupUser}/${data?.mobileNumber}`);
      setSearchPatient(result.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const columns = [
    { label: "First Name", field: "firstName" },
    { label: "Last Name", field: "lastName" },
    { label: "Email", field: "email" },
    { label: "Phone Number", field: "phoneNumber" },
    { label: "Subscription Status", field: "subscription" },
  ];

  const SelectItemHandler = async (item) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_SERVER_URL}${addTrialSubscription}/${item?.phoneNumber}`);
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <ToastMessage />
      <div
        className="patients-search-form"
        style={{ marginBottom: "20px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="mobileNumber"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  autoComplete: "new-password",
                  type: "number",
                }}
                label="Mobile Number"
                error={!!errors.mobileNumber}
                helperText={errors.mobileNumber?.message}
                size="small"
                fullWidth
                className="search-field"
                inputRef={field.ref}
                maxLength={10}
                minLength={10}
              />
            )}
          />
          <Button
            variant="contained"
            type="submit"
            style={{ minWidth: "fit-content" }}
          >
            Search Patient
          </Button>
        </form>
      </div>
      <div className="searched-patients">
        <PatientMigrationTable
          items={searchPatient}
          SelectItemHandlerFun={SelectItemHandler}
          columns={columns}
          actionBtnText="Add Trial Subscription"
        />
      </div>
    </>
  );
};

export default NewPatientMigration;
