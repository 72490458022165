export const columns = [
  {
    id: "label",
    label: "Risk Factors/Disease",
  },
  {
    id: "ckd",
    label: "CKD",
  },
  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "chronicRespiratoryDisease",
    label: "Chronic respiratory disease",
  },
  {
    id: "cervical",
    label: "Cervical",
  },
  {
    id: "lung",
    label: "Lung",
  },
  {
    id: "breast",
    label: "Breast",
  },
  {
    id: "prostrate",
    label: "Prostrate",
  },
  {
    id: "colorectal",
    label: "Colorectal",
  },
  {
    id: "cld",
    label: "Chronic Liver Disease (CLD)",
  },
  {
    id: "overWeightObesity",
    label: "Over Weight Obesity",
  },
  {
    id: "highBloodPressure",
    label: "High Blood Pressure",
  }
];

export const diabetesColumns = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "Diabetes",
  },
  {
    id: "ckd",
    label: "CKD",
  },
  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "colorectal",
    label: "Colorectal",
  },
];

export const highBPColumns = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "High BP",
  },
  {
    id: "ckd",
    label: "CKD",
  },
  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
];

export const overweightColumns = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "Overweight/Obesity",
  },
  {
    id: "ckd",
    label: "CKD",
  },
  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "breast",
    label: "Breast",
  },
  {
    id: "prostrate",
    label: "Prostrate",
  },
];

export const abnormalColumns = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "abnormalBloodLipids",
  },

  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
];

export const tobaccoColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "tobaccoUse",
  },

  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "cervical",
    label: "Cervical",
  },
  {
    id: "lung",
    label: "Lung",
  },
];

export const physicalInactivityColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "physicalInactivity",
  },

  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "breast",
    label: "Breast",
  },
  {
    id: "colorectal",
    label: "Colorectal",
  },
];

export const unhealthyDietColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "unhealthyDiet",
  },

  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "prostrate",
    label: "Prostrate",
  },
  {
    id: "colorectal",
    label: "Colorectal",
  },
];

export const alcoholUseColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "alcoholUse",
  },

  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "breast",
    label: "Breast",
  },
  {
    id: "cld",
    label: "CLD",
  },
];

export const sugarConsumptionColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "sugarConsumption",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
];

export const fiberConsumptionColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "fiberConsumption",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "prostrate",
    label: "Prostrate",
  },
];

export const psychologicalStressColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "psychologicalStress",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
];

export const smokingColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "smoking",
  },
  {
    id: "chronicRespiratoryDisease",
    label: "ChronicRespiratoryDisease",
  },
];

export const airPollutionInfectionsColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "airPollutionInfections",
  },
  {
    id: "chronicRespiratoryDisease",
    label: "ChronicRespiratoryDisease",
  },
  {
    id: "lung",
    label: "Lung",
  },
];

export const dustAndChemicalColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "dustAndChemical",
  },
  {
    id: "chronicRespiratoryDisease",
    label: "ChronicRespiratoryDisease",
  },
];

export const PovertyColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "Poverty",
  },
  {
    id: "cervical",
    label: "Cervical",
  },
];

export const humanVirusInfectionColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "humanPapillomaVirusInfection",
  },
  {
    id: "cervical",
    label: "Cervical",
  },
];

export const hormoneTherapiesColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "hormoneTherapies",
  },
  {
    id: "breast",
    label: "Breast",
  },
];

export const reproductiveHistoryColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "reproductiveHistory",
  },
  {
    id: "breast",
    label: "Breast",
  },
];

export const useIntravenousDrugsColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "useIntravenousDrugs",
  },
  {
    id: "cld",
    label: "CLD",
  },
];

export const painKillersUseColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "painKillersUse",
  },
  {
    id: "cld",
    label: "CLD",
  },
];

export const MetabolicSyndromeColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "MetabolicSyndrome",
  },

  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "cld",
    label: "CLD",
  },
];

export const exposedToxicColumn = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "excessAbdominalWeight",
  },
  {
    id: "cld",
    label: "CLD",
  },
];

export const familyHistory = [
  {
    id: "label",
    label: "Risk Factors/Disease",
    labelId: "Family History",
  },
  {
    id: "cvd",
    label: "CVD",
  },
  {
    id: "diabetes",
    label: "Diabetes",
  },
  {
    id: "cld",
    label: "CLD",
  },
  {
    id: "chronicRespiratoryDisease",
    label: "ChronicRespiratoryDisease",
  },
  {
    id: "cervical",
    label: "Cervical",
  },
  {
    id: "lung",
    label: "Lung",
  },
  {
    id: "breast",
    label: "Breast",
  },
  {
    id: "prostrate",
    label: "Prostrate",
  },
  {
    id: "colorectal",
    label: "Colorectal",
  },
];
