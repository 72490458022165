import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const PhysiotherapistInvoice = () => {
  const rows = [
    {
      monthYear: "March 2024",
      totalOrder: "45",
      totalAmount: "9450",
      attachInvoice: "",
      invoiceDate: "2024-05-20",
      invoiceAmount: "9450",
      totalConfirmed: "Yes",
      invoiceApproval: "Approved",
      comments: "Invoice Ready to be Paid",
      accountMgrComment: "N/A",
      status: "To be paid",
    },
    {
      monthYear: "March 2024",
      totalOrder: "45",
      totalAmount: "9450",
      attachInvoice: "",
      invoiceDate: "2024-05-15",
      invoiceAmount: "9650",
      totalConfirmed: "No",
      invoiceApproval: "Not Approved",
      comments: "Please call our Account Manager to Resolve",
      accountMgrComment: "approved",
      status: "Paid Date",
    },
  ];
  return (
    <>
      <h3>Invoice</h3>
      <p style={{ marginTop: "1%" }}>
        <b>
          An example is shown below for invoice. System will automatically
          generate the invoice information and you will be able to attach your
          invoice for payment as per agreed terms and condition.
        </b>
      </p>
      <TableContainer>
        <Table style={{ border: "1px solid lightgrey", marginTop: "20px" }}>
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Invoice Month/Year</TableCell>
              <TableCell className="tableCell">Total Orders</TableCell>
              <TableCell className="tableCell">Total Amount</TableCell>
              <TableCell className="tableCell">Attach Invoice</TableCell>
              <TableCell className="tableCell">Invoice Date</TableCell>
              <TableCell className="tableCell">Invoice Amount</TableCell>
              <TableCell className="tableCell">Total Confirmed?</TableCell>
              <TableCell className="tableCell">Invoice Approval</TableCell>
              <TableCell className="tableCell">Comments</TableCell>
              <TableCell className="tableCell">Account Mgr. Comments</TableCell>
              <TableCell className="tableCell">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.monthYear}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.totalOrder}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.totalAmount}
                </TableCell>
                <TableCell style={{ border: "1px solid black" }}>
                  <input type="file" />
                </TableCell>
                <TableCell style={{ border: "1px solid black" }}>
                  {row.invoiceDate}
                </TableCell>
                <TableCell style={{ border: "1px solid black" }}>
                  {row.invoiceAmount}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.totalConfirmed}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.invoiceApproval}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.comments}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.accountMgrComment}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid black",
                    backgroundColor: "lightgray",
                  }}
                >
                  {row.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default PhysiotherapistInvoice;
