import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./formStyle.css";
import { Checkbox, FormGroup, Grid, TextField } from "@mui/material";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Col, Row, Toast } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { FlashOnTwoTone } from "@mui/icons-material";

const LifeStyleSketch = ({ onBack, fetchlifestyleSketchData }) => {
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [diet, setDiet] = useState("");
  const [otherdietValue, setOtherdietValue] = useState("");
  const [receivedDietCounselling, setreceivedDietCounselling] = useState("");
  const [numberOfMeals, setnumberOfMeals] = useState("");
  const [numberOfSnacks, setnumberOfSnacks] = useState("");
  const [doYouHaveBreakfastRegularly, setdoYouHaveBreakfastRegularly] =
    useState(null);
  const [timeOfDinner, settimeOfDinner] = useState("");
  const [appetite, setAppetite] = useState("");
  const [includedInTheMeals, setincludedInTheMeals] = useState("");
  const [otherMealsValue, setotherMealsValue] = useState("");
  const [selectDiet, setselectDiet] = useState("");
  const [includedInTheDiet, setincludedInTheDiet] = useState("");
  const [mixedIncludedInYourDiet, setmixedIncludedInYourDiet] = useState("");
  const [consultWithDietitian, setconsultWithDietitian] = useState(null);
  const [moderateIntensityExerciseDays, setmoderateIntensityExerciseDays] =
    useState(null);
  const [vigorousIntensityExerciseDays, setvigorousIntensityExerciseDays] =
    useState(null);
  const [MildIntensityExerciseDays, setMildIntensityExerciseDays] =
    useState(null);
  const [
    averageTimeForVigorousActivities,
    setaverageTimeForVigorousActivities,
  ] = useState("");
  const [averageTimeForMildActivities, setaverageTimeForMildActivities] =
    useState("");
  const [averageTimeForActivities, setaverageTimeForActivities] = useState("");
  const [fastWalkingDays, setfastWalkingDays] = useState(null);
  const [averageTimeForFastWalking, setaverageTimeForFastWalking] =
    useState("");
  const [
    comparisonLastThreeMonthsToSevenDays,
    setcomparisonLastThreeMonthsToSevenDays,
  ] = useState("");
  const [selectedActivity, setselectedActivity] = useState("");
  const [hoursOfExercise, sethoursOfExercise] = useState("");
  const [
    lastSevenDays30minutesOfExercise,
    setlastSevenDays30minutesOfExercise,
  ] = useState();
  const [totalHoursOfSitting, settotalHoursOfSitting] = useState("");
  const [usedTobaccoProducts, setusedTobaccoProducts] = useState(null);
  const [drinkAlcohol, setdrinkAlcohol] = useState(null);
  const [howOftenConsumeAlcohol, sethowOftenConsumeAlcohol] = useState("");
  const [drinkDoYouPrefer, setdrinkDoYouPrefer] = useState("");
  const [drinksPerDay, setdrinksPerDay] = useState("");
  const [usedRecreationalDrugs, setusedRecreationalDrugs] = useState(null);
  const [drugType, setdrugType] = useState("");
  const [smoked, setSmoked] = useState(null);
  const [areYouExSmoker, setareYouExSmoker] = useState();
  const [smokingPerDay, setsmokingPerDay] = useState("");
  const [hoursOfSleep, setHoursOfSleep] = useState("");
  const [sleepAroundSameTime, setsleepAroundSameTime] = useState("");
  const [satisfiedWithSleep, setsatisfiedWithSleep] = useState("");
  const [difficultyWithSleep, setdifficultyWithSleep] = useState("");
  const [feelRefreshedAfterSleep, setfeelRefreshedAfterSleep] = useState("");
  const [preventiveScreeningTestDone, setpreventiveScreeningTestDone] =
    useState("");
  const [glucometerDevice, setGlucometerDevice] = useState("");
  const [bpDevice, setbpDevice] = useState("");
  const [bloodSugarCheckedLast, setbloodSugarCheckedLast] = useState("");
  const [bloodPressureCheckedLast, setbloodPressureCheckedLast] = useState("");
  const [
    concernRegardingIllnessTreatment,
    setconcernRegardingIllnessTreatment,
  ] = useState("");
  const [preferLowCarbDiet, setpreferLowCarbDiet] = useState("");
  const [preferVegetarianFood, setpreferVegetarianFood] = useState("");
  const [avoidProcessedMeat, setavoidProcessedMeat] = useState("");
  const [avoidHighlyProcessedFoods, setavoidHighlyProcessedFoods] =
    useState("");
  const [
    doNotDrinkSugarSweetenedBeverages,
    setdoNotDrinkSugarSweetenedBeverages,
  ] = useState("");
  const [
    regularlyConsumeWholeGrainsLegumes,
    setregularlyConsumeWholeGrainsLegumes,
  ] = useState("");
  const [drinkCoffee, setdrinkCoffee] = useState("");
  const [limitIntakeOfDairyProducts, setlimitIntakeOfDairyProducts] =
    useState("");
  const [eatDietWhichIsLowInAddedSugars, seteatDietWhichIsLowInAddedSugars] =
    useState("");
  const [oftenEatMeat, setoftenEatMeat] = useState("");
  const [oftenEatYogurt, setoftenEatYogurt] = useState("");
  const [oftenEatLowFatMilk, setoftenEatLowFatMilk] = useState("");
  const [oftenEatCheese, setoftenEatCheese] = useState("");
  const [oftenEatEggs, setoftenEatEggs] = useState("");
  const [oftenEatFortifiedCereals, setoftenEatFortifiedCereals] = useState("");
  const [areYouTakingFolate, setareYouTakingFolate] = useState(null);
  const [howMuchFolate, sethowMuchFolate] = useState();
  const [limitOrDecreaseSaltInMyMeals, setlimitOrDecreaseSaltInMyMeals] =
    useState("");
  const [highInFiber, sethighInFiber] = useState("");
  const [limitOrDecreaseSugarInMyDiet, setlimitOrDecreaseSugarInMyDiet] =
    useState("");
  const [profile, setProfile] = useState(null);
  const [familyHistory, setFamilyHistory] = useState(null);
  const [LifestyleStep, setLifestyleStep] = useState(0);
  const [errorHeight, setErrorHeight] = useState("");
  const [errorWeight, setErrorWeight] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldErrors2, setFieldErrors2] = useState({});
  const [fieldErrors3, setFieldErrors3] = useState({});
  const [fieldErrors4, setFieldErrors4] = useState({});
  const [fieldErrors5, setFieldErrors5] = useState({});
  const [familyRelation, setFamilyRelation] = useState();
  const [familyInfo, setFamilyInfo] = useState();
  const [lifestyleSketchCompleteFlag, setlifestyleSketchCompleteFlag] =
    useState(null);
  const handleoftenEatMeatChange = (event) => {
    setoftenEatMeat(event.target.value);
  };

  const handlemixedIncludedInYourDietChange = (event) => {
    const value = event.target.value;
    setmixedIncludedInYourDiet((prevMembers) => {
      if (prevMembers.includes(value)) {
        return prevMembers.filter((member) => member !== value);
      } else {
        return [...prevMembers, value];
      }
    });
  };

  const handleselectDietChange = (event) => {
    setselectDiet(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      selectDiet: false,
    }));
  };

  const handlelimitOrDecreaseSugarInMyDietChange = (event) => {
    setlimitOrDecreaseSugarInMyDiet(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      limitOrDecreaseSugarInMyDiet: false,
    }));
  };

  const handlehighInFiberChange = (event) => {
    sethighInFiber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      highInFiber: false,
    }));
  };

  const handlelimitOrDecreaseSaltInMyMealsChange = (event) => {
    setlimitOrDecreaseSaltInMyMeals(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      limitOrDecreaseSaltInMyMeals: false,
    }));
  };

  const handlehowMuchFolateChange = (event) => {
    sethowMuchFolate(event.target.value);
  };
  const handleareYouTakingFolateChange = (event) => {
    const value = event.target.value === "true";
    setareYouTakingFolate(value);
  };

  const handledrinksPerDayChange = (event) => {
    setdrinksPerDay(event.target.value);
  };

  const handledrinkDoYouPreferChange = (event) => {
    setdrinkDoYouPrefer(event.target.value);
  };

  const handledrugTypeChange = (event) => {
    setdrugType(event.target.value);
  };

  const handleoftenEatFortifiedCerealsChange = (event) => {
    setoftenEatFortifiedCereals(event.target.value);
  };

  const handleoftenEatEggsChange = (event) => {
    setoftenEatEggs(event.target.value);
  };

  const handleoftenEatCheeseChange = (event) => {
    setoftenEatCheese(event.target.value);
  };

  const handleoftenEatLowFatMilkChange = (event) => {
    setoftenEatLowFatMilk(event.target.value);
  };

  const handleoftenEatYogurtChange = (event) => {
    setoftenEatYogurt(event.target.value);
  };

  const handleeatDietWhichIsLowInAddedSugarsChange = (event) => {
    seteatDietWhichIsLowInAddedSugars(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      eatDietWhichIsLowInAddedSugars: false,
    }));
  };

  const handlelimitIntakeOfDairyProductsChange = (event) => {
    setlimitIntakeOfDairyProducts(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      limitIntakeOfDairyProducts: false,
    }));
  };

  const handledrinkCoffeeChange = (event) => {
    setdrinkCoffee(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      drinkCoffee: false,
    }));
  };

  const handleregularlyConsumeWholeGrainsLegumesChange = (event) => {
    setregularlyConsumeWholeGrainsLegumes(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      regularlyConsumeWholeGrainsLegumes: false,
    }));
  };

  const handledoNotDrinkSugarSweetenedBeveragesChange = (event) => {
    setdoNotDrinkSugarSweetenedBeverages(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      doNotDrinkSugarSweetenedBeverages: false,
    }));
  };

  const handleavoidHighlyProcessedFoodstChange = (event) => {
    setavoidHighlyProcessedFoods(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      avoidHighlyProcessedFoods: false,
    }));
  };

  const handleavoidProcessedMeatChange = (event) => {
    setavoidProcessedMeat(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      avoidProcessedMeat: false,
    }));
  };

  const handlepreferVegetarianFoodChange = (event) => {
    setpreferVegetarianFood(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      preferVegetarianFood: false,
    }));
  };

  const handlepreferLowCarbDietChange = (event) => {
    setpreferLowCarbDiet(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      preferLowCarbDiet: false,
    }));
  };

  const handleareYouExSmokerChange = (event) => {
    const value = event.target.value === "true";
    setareYouExSmoker(value);
  };

  const handlesmokingPerDayChange = (event) => {
    setsmokingPerDay(event.target.value);
  };

  const handlesmokedChange = (event) => {
    const value = event.target.value === "true";
    setSmoked(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      smoked: false,
    }));
  };

  console.log("prof", profile);

  const handletotalHoursOfSitting = (event) => {
    const selectedValue = event.target.value;
    settotalHoursOfSitting(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      totalHoursOfSitting: false,
    }));
  };

  const handleVigorousIntensityExerciseDaysChange = (event) => {
    const selectedValue = event.target.value;
    setvigorousIntensityExerciseDays(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      vigorousIntensityExerciseDays: false,
    }));
  };
  const handleMildIntensityExerciseDaysChange = (event) => {
    const selectedValue = event.target.value;
    setMildIntensityExerciseDays(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      MildIntensityExerciseDays: false,
    }));
  };

  const handleaverageTimeForMildActivitiesChange = (event) => {
    setaverageTimeForMildActivities(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      averageTimeForMildActivities: false,
    }));
  };

  const handleaverageTimeForVigorousActivitiesChange = (event) => {
    setaverageTimeForVigorousActivities(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      averageTimeForVigorousActivities: false,
    }));
  };

  const handlemoderateIntensityExerciseDaysChange = (event) => {
    const selectedValue = event.target.value;
    setmoderateIntensityExerciseDays(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      moderateIntensityExerciseDays: false,
    }));
  };

  const handleaverageTimeForActivitiesChange = (event) => {
    const selectedValue = event.target.value;
    setaverageTimeForActivities(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      averageTimeForActivities: false,
    }));
  };

  const handlefastWalkingDaysChange = (event) => {
    const selectedValue = event.target.value;
    setfastWalkingDays(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      fastWalkingDays: false,
    }));
  };

  const handleaverageTimeForFastWalkingChange = (event) => {
    const selectedValue = event.target.value;
    setaverageTimeForFastWalking(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      averageTimeForFastWalking: false,
    }));
  };

  const handlecomparisonLastThreeMonthsToSevenDaysChange = (event) => {
    setcomparisonLastThreeMonthsToSevenDays(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      comparisonLastThreeMonthsToSevenDays: false,
    }));
  };

  const handleusedTobaccoProductsChange = (event) => {
    const value = event.target.value === "true";
    setusedTobaccoProducts(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      usedTobaccoProducts: false,
    }));
  };

  const handlehowOftenConsumeAlcoholChange = (event) => {
    sethowOftenConsumeAlcohol(event.target.value);
  };

  const handledrinkAlcoholChange = (event) => {
    const value = event.target.value === "true";
    setdrinkAlcohol(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      usedTobaccoProducts: false,
    }));
  };

  const handleusedRecreationalDrugsChange = (event) => {
    const value = event.target.value === "true";
    setusedRecreationalDrugs(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      usedRecreationalDrugs: false,
    }));
  };

  const handlesleepAroundSameTimeChange = (event) => {
    setsleepAroundSameTime(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      sleepAroundSameTime: false,
    }));
  };

  const handlesatisfiedWithSleepChange = (event) => {
    setsatisfiedWithSleep(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      satisfiedWithSleep: false,
    }));
  };

  const handledifficultyWithSleepChange = (event) => {
    setdifficultyWithSleep(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      difficultyWithSleep: false,
    }));
  };

  const handlefeelRefreshedAfterSleepChange = (event) => {
    setfeelRefreshedAfterSleep(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      feelRefreshedAfterSleep: false,
    }));
  };

  const handleconcernRegardingIllnessTreatmentChange = (event) => {
    const selectValue = event.target.value;
    setconcernRegardingIllnessTreatment(selectValue);
  };

  const onHeightChange = (e) => {
    const newHeight = parseFloat(e.target.value);
    setHeight(newHeight);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      height: false,
    }));
  };

  const onWeightChange = (e) => {
    const newWeight = parseFloat(e.target.value);
    setWeight(newWeight);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weight: false,
    }));
  };

  const handleselectedActivityCheckboxChange = (option) => {
    if (option === "None") {
      // If "None" is selected, uncheck all other options
      setselectedActivity(["None"]);
    } else {
      // Toggle the selected state for the clicked option
      setselectedActivity((prevOptions) => {
        if (prevOptions.includes("None")) {
          // If 'None' was previously selected, remove it
          return [option];
        } else if (prevOptions.includes(option)) {
          // If the option is already selected, remove it
          return prevOptions.filter((item) => item !== option);
        } else {
          // If the option is not selected, add it to the array
          return [...prevOptions, option];
        }
      });
    }
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      selectedActivity: false,
    }));
  };

  const handleLifestyleStep = () => {
    setLifestyleStep((prev) => prev + 1);
  };

  const handleLifestyleStepBack = () => {
    setLifestyleStep((prev) => prev - 1);
  };

  const handlerecieveddietcounsellingChange = (event) => {
    const selectedValue = event.target.value;
    setreceivedDietCounselling(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      receivedDietCounselling: false,
    }));
  };

  const handlenumberOfMealsChange = (event) => {
    const selectedValue = event.target.value;
    setnumberOfMeals(selectedValue);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      numberOfMeals: false,
    }));
  };

  const handlenumberOfSnacksChange = (event) => {
    const selectedValue = event.target.value;
    setnumberOfSnacks(selectedValue);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      numberOfSnacks: false,
    }));
  };

  const handledoYouHaveBreakfastRegularlyChange = (event) => {
    const selectedValue = event.target.value;
    setdoYouHaveBreakfastRegularly(selectedValue);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      doYouHaveBreakfastRegularly: false,
    }));
  };

  const handletimeOfDinnerChange = (event) => {
    const selectedValue = event.target.value;
    settimeOfDinner(selectedValue);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      timeOfDinner: false,
    }));
  };

  const handleAppetiteChange = (event) => {
    const selectedValue = event.target.value;
    setAppetite(selectedValue);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      appetite: false,
    }));
  };

  const handleconsultWithDietitianChange = (event) => {
    const selectedValue = event.target.value;
    setconsultWithDietitian(selectedValue);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      consultWithDietitian: false,
    }));
  };

  const handlehoursOfExerciseChange = (event) => {
    const selectedValue = event.target.value;
    sethoursOfExercise(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      hoursOfExercise: false,
    }));
  };

  const handlelastSevenDays30minutesOfExerciseChange = (event) => {
    const selectedValue = event.target.value;
    setlastSevenDays30minutesOfExercise(selectedValue);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      lastSevenDays30minutesOfExercise: false,
    }));
  };

  const handlepreventiveScreeningTestDoneChange = (event) => {
    const selectedValue = event.target.value;
    setpreventiveScreeningTestDone(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      preventiveScreeningTestDone: false,
    }));
  };

  const handleglucometerDeviceChange = (event) => {
    const selectedValue = event.target.value;
    setGlucometerDevice(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      glucometerDevice: false,
    }));
  };

  const handlebpDeviceChange = (event) => {
    const selectedValue = event.target.value;
    setbpDevice(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      bpDevice: false,
    }));
  };

  const handlebloodSugarCheckedLastChange = (event) => {
    const selectedValue = event.target.value;
    setbloodSugarCheckedLast(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      bloodSugarCheckedLast: false,
    }));
  };

  const handlebloodPressureCheckedLastChange = (event) => {
    const selectedValue = event.target.value;
    setbloodPressureCheckedLast(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      bloodPressureCheckedLast: false,
    }));
  };

  const handlehoursOfSleepChange = (event) => {
    const selectedValue = event.target.value;
    setHoursOfSleep(selectedValue);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      hoursOfSleep: false,
    }));
  };

  const handleDietChange = (event) => {
    const value = event.target.value;

    // Toggle the selected family member
    setDiet((prevMembers) => {
      if (prevMembers.includes(value)) {
        return prevMembers.filter((member) => member !== value);
      } else {
        return [...prevMembers, value];
      }
    });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      diet: false,
    }));

    // If "Other" is selected, clear otherValue
    if (value !== "Other") {
      setOtherdietValue("");
    }
  };

  // const handleincludedInTheMealsChange = (event) => {
  //   const value = event.target.value;

  //   // Toggle the selected family member
  //   setincludedInTheMeals((prevMembers) => {
  //     if (prevMembers.includes(value)) {
  //       return prevMembers.filter((member) => member !== value);
  //     } else {
  //       return [...prevMembers, value];
  //     }
  //   });
  // };
  const handleincludedInTheMealsChange2 = (event) => {
    const other = event.target.value;
    setotherMealsValue(other);
  };

  const handleInputBlur = () => {
    const trimmedValue = otherMealsValue.trim();
    if (trimmedValue !== "" && !includedInTheMeals.includes(trimmedValue)) {
      setincludedInTheMeals([
        ...includedInTheMeals,
        "otherValue:" + trimmedValue,
      ]);
    }
  };

  const handleInputBlurDiet = () => {
    const trimmedValue = otherdietValue.trim();
    if (trimmedValue !== "" && !diet.includes(trimmedValue)) {
      setDiet([...diet, "otherValue:" + trimmedValue]);
    }
  };

  const handleincludedInTheMealsChange = (event) => {
    const { value, type, checked } = event.target;

    if (type === "checkbox") {
      if (checked) {
        setincludedInTheMeals((prevMembers) => [...prevMembers, value]);
      } else {
        setincludedInTheMeals((prevMembers) =>
          prevMembers.filter((member) => member !== value)
        );
        if (value === "Other") {
          setotherMealsValue("");
        }
      }
    } else if (type === "text") {
      const otherValue = `Other;${value}`;
      setotherMealsValue(value);
      if (checked && includedInTheMeals.includes("Other")) {
        setincludedInTheMeals((prevMembers) => {
          const updatedMembers = prevMembers.map((member) => {
            if (typeof member === "string" && member.startsWith("Other")) {
              return otherValue;
            }
            return member;
          });
          return updatedMembers;
        });
      } else {
        setincludedInTheMeals((prevMembers) => {
          const filteredMembers = prevMembers.filter(
            (member) => !member.startsWith("Other")
          );
          if (value !== "") {
            return [...filteredMembers, otherValue];
          }
          return filteredMembers;
        });
      }
    }
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      includedInTheMeals: false,
    }));
  };
  function onBlurHeight(event) {
    const newHeight = event.target.value;
    if (isNaN(newHeight) || newHeight < 0 || newHeight.length > 3) {
      setErrorHeight("Please enter valid Height (not more then 3 number)");
    } else {
      setHeight(newHeight);
      setErrorHeight("");
    }
  }
  function onBlurWeight(event) {
    const newWeight = event.target.value;
    if (isNaN(newWeight) || newWeight < 0 || newWeight.length > 3) {
      setErrorWeight("Please enter valid Weight (not more then 3 number)");
    } else {
      setWeight(newWeight);
      setErrorWeight("");
    }
  }
  const handleincludedInTheDietChange = (event) => {
    const value = event.target.value;

    // Toggle the selected family member
    setincludedInTheDiet((prevMembers) => {
      if (value === "None Of the Above") {
        return prevMembers.includes(value) ? [] : [value];
      } else {
        const updatedMembers = prevMembers.includes(value)
          ? prevMembers.filter((member) => member !== value)
          : [...prevMembers, value];

        return updatedMembers.filter(
          (member) => member !== "None Of the Above"
        );
      }
    });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      includedInTheDiet: false,
    }));
  };

  const handleOtherValueChange2 = (event) => {
    setOtherdietValue(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      diet: false,
    }));
  };

  const calculateBmi = (height, weight) => {
    if (!height || !weight) return "";
    const bmi = weight / ((height / 100) * (height / 100));
    return parseFloat(bmi.toFixed(1));
  };

  const handleSave1 = (e) => {
    e.preventDefault();
    let errors = {};
    if (profile?.age < 3) {
      if (numberOfMeals == "") {
        errors.numberOfMeals = true;
      }
      if (includedInTheMeals == "") {
        errors.includedInTheMeals = true;
      }
      if (diet == "") {
        errors.diet = true;
      }
      if (doYouHaveBreakfastRegularly == null) {
        errors.doYouHaveBreakfastRegularly = true;
      }
      if (appetite == "") {
        errors.appetite = true;
      }
      if (timeOfDinner == "") {
        errors.timeOfDinner = true;
      }
      if (numberOfSnacks == "") {
        errors.numberOfSnacks = true;
      }
      if (height == null) {
        errors.height = true;
      }
      if (weight == null) {
        errors.weight = true;
      }
    }
    if (profile?.age >= 3) {
      if (numberOfMeals == "") {
        errors.numberOfMeals = true;
      }
      if (includedInTheMeals == "") {
        errors.includedInTheMeals = true;
      }
      if (diet == "") {
        errors.diet = true;
      }
      if (doYouHaveBreakfastRegularly == null) {
        errors.doYouHaveBreakfastRegularly = true;
      }
      if (appetite == "") {
        errors.appetite = true;
      }
      if (timeOfDinner == "") {
        errors.timeOfDinner = true;
      }
      if (numberOfSnacks == "") {
        errors.numberOfSnacks = true;
      }
      if (height == null) {
        errors.height = true;
      }
      if (weight == null) {
        errors.weight = true;
      }
      if (receivedDietCounselling == "") {
        errors.receivedDietCounselling = true;
      }
      if (consultWithDietitian == null) {
        errors.consultWithDietitian = true;
      }
      if (preferLowCarbDiet == "") {
        errors.preferLowCarbDiet = true;
      }
      if (preferVegetarianFood == "") {
        errors.preferVegetarianFood = true;
      }
      if (avoidProcessedMeat == "") {
        errors.avoidProcessedMeat = true;
      }
      if (avoidHighlyProcessedFoods == "") {
        errors.avoidHighlyProcessedFoods = true;
      }
      if (doNotDrinkSugarSweetenedBeverages == "") {
        errors.doNotDrinkSugarSweetenedBeverages = true;
      }
      if (regularlyConsumeWholeGrainsLegumes == "") {
        errors.regularlyConsumeWholeGrainsLegumes = true;
      }
      if (drinkCoffee == "") {
        errors.drinkCoffee = true;
      }
      if (limitIntakeOfDairyProducts == "") {
        errors.limitIntakeOfDairyProducts = true;
      }
      if (eatDietWhichIsLowInAddedSugars == "") {
        errors.eatDietWhichIsLowInAddedSugars = true;
      }
      if (limitOrDecreaseSaltInMyMeals == "") {
        errors.limitOrDecreaseSaltInMyMeals = true;
      }
      if (highInFiber == "") {
        errors.highInFiber = true;
      }
      if (limitOrDecreaseSugarInMyDiet == "") {
        errors.limitOrDecreaseSugarInMyDiet = true;
      }
      if (selectDiet == "") {
        errors.selectDiet = true;
      }
    }
    if (
      selectDiet == "Strictly vegetarian (Eggs not included)" &&
      includedInTheDiet == ""
    ) {
      errors.includedInTheDiet = true;
    }
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      // (
      //   !numberOfMeals ||
      //   (Array.isArray(includedInTheMeals) && includedInTheMeals.length === 0) ||
      //   (Array.isArray(diet) && diet.length === 0) ||
      //   (Array.isArray(includedInTheDiet) && includedInTheDiet.length === 0) ||
      //   doYouHaveBreakfastRegularly === null ||
      //   !appetite ||
      //   !timeOfDinner ||
      //   !numberOfSnacks ||
      //   !height ||
      //   !weight ||
      //   !receivedDietCounselling ||
      //   consultWithDietitian === null ||
      //   preferLowCarbDiet == "" ||
      //   preferVegetarianFood == "" ||
      //   avoidProcessedMeat == "" ||
      //   avoidHighlyProcessedFoods == "" ||
      //   doNotDrinkSugarSweetenedBeverages == "" ||
      //   regularlyConsumeWholeGrainsLegumes == "" ||
      //   drinkCoffee == "" ||
      //   limitIntakeOfDairyProducts == "" ||
      //   eatDietWhichIsLowInAddedSugars == "" ||
      //   limitOrDecreaseSaltInMyMeals == "" ||
      //   highInFiber == "" ||
      //   limitOrDecreaseSugarInMyDiet == ""
      // )
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
      return;
    } else {
      const formData = {
        numberOfMealsPerDay: numberOfMeals,
        itemsIncludedInMeals: includedInTheMeals
          ? includedInTheMeals.join(";")
          : includedInTheMeals,
        describeDiet: diet ? diet.join(";") : diet,
        regularBreakfast: doYouHaveBreakfastRegularly
          ? doYouHaveBreakfastRegularly
          : false,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnacksPerDay: numberOfSnacks,
        height: height,
        weight: weight,
        bmi: calculateBmi(height, weight),
        receivedDietCounselling: receivedDietCounselling,
        consultDietitianInNetwork: consultWithDietitian
          ? consultWithDietitian
          : false,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverages: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        selectDiet: selectDiet,
        oftenEatMeat: oftenEatMeat,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        oftenEatEggs: oftenEatEggs,
        moderateIntensityExerciseDays: moderateIntensityExerciseDays,
        averageTimeForActivities: averageTimeForActivities,
        fastWalkingDays: fastWalkingDays,
        averageTimeForFastWalking: averageTimeForFastWalking,
        daysForVigorousIntensityExerciseForTenMinutes:
          vigorousIntensityExerciseDays,
        timeSpentInVigorousIntensityExercise: averageTimeForVigorousActivities,
        daysForMildIntensityExerciseForTenMinutes: MildIntensityExerciseDays,
        timeSpentInMildIntensityExercise: averageTimeForMildActivities,
        comparisonLastThreeMonthsToSevenDays:
          comparisonLastThreeMonthsToSevenDays,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        activityUndertakeRegularly: selectedActivity
          ? selectedActivity.join(";")
          : selectedActivity,
        hoursExerciseInLastSevenDays: hoursOfExercise,
        exerciseAtLeastThirtyMinutesLastSevenDays:
          lastSevenDays30minutesOfExercise,
        usedTobaccoProducts: usedTobaccoProducts,
        drinkAlcohol: drinkAlcohol ? drinkAlcohol : false,
        howOftenDoYouConsumeAlcohol: drinkAlcohol ? howOftenConsumeAlcohol : "",
        drinkDoYouPrefer: drinkAlcohol ? drinkDoYouPrefer : "",
        drinksPerDay: drinkAlcohol ? drinksPerDay : "",
        areYouTakingFolate: areYouTakingFolate ? areYouTakingFolate : false,
        howMuchFolate: areYouTakingFolate ? howMuchFolate : null,
        usedRecreationalDrugs: usedRecreationalDrugs
          ? usedRecreationalDrugs
          : false,
        drugType: usedRecreationalDrugs ? drugType : "",
        everSmokedPuff: smoked ? smoked : false,
        areYouExSmoker: smoked ? areYouExSmoker : false,
        smokingPerDay: smoked ? smokingPerDay : "",
        timeSpentInSittingPerDay: totalHoursOfSitting,
        hoursOfSleepingPerDay: hoursOfSleep,
        sleepAroundSameTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        feelRefreshedAfterSleep: feelRefreshedAfterSleep,
        preventiveScreeningTestsDone: preventiveScreeningTestDone,
        doYouHaveGlucometerAndUsedIt: glucometerDevice,
        doYouHaveBloodPressureDeviceAndUsedIt: bpDevice,
        lastBloodSugarChecked: bloodSugarCheckedLast,
        lastBloodPressureChecked: bloodPressureCheckedLast,
        concernRegardingIllnessTreatment: concernRegardingIllnessTreatment,
        lifestyleSketchCompleteFlag: lifestyleSketchCompleteFlag ? true : false,
      };
      console.log("formDaaaaa", formData.diet);
      if (profile?.age >= 3) {
        AxiosInstance.post("form/addLifestyleSketchDiet", formData)
          .then((response) => {
            console.log("response: ", response);
          })
          .catch((error) => {
            console.log(error);
          });
        setLifestyleStep((prev) => prev + 1);
      }
      if (profile?.age < 3) {
        AxiosInstance.post("form/addLifestyleSketchDiet", formData)
          .then((response) => {
            console.log("response: ", response);
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire({
          icon: "success",
          text: "Form Submitted Successfully",
        });
        setTimeout(() => {
          onBack();
        }, 1000);
      }
    }
  };

  const handleSave2 = (e) => {
    e.preventDefault();
    let errors2 = {};
    if (
      moderateIntensityExerciseDays == null ||
      moderateIntensityExerciseDays == undefined
    ) {
      errors2.moderateIntensityExerciseDays = true;
    }
    if (averageTimeForActivities == "") {
      errors2.averageTimeForActivities = true;
    }
    if (fastWalkingDays == null || fastWalkingDays == undefined) {
      errors2.fastWalkingDays = true;
    }
    if (averageTimeForFastWalking == "") {
      errors2.averageTimeForFastWalking = true;
    }
    if (
      vigorousIntensityExerciseDays == null ||
      vigorousIntensityExerciseDays == undefined
    ) {
      errors2.vigorousIntensityExerciseDays = true;
    }
    if (averageTimeForVigorousActivities == "") {
      errors2.averageTimeForVigorousActivities = true;
    }
    if (
      MildIntensityExerciseDays == null ||
      MildIntensityExerciseDays == undefined
    ) {
      errors2.MildIntensityExerciseDays = true;
    }
    if (averageTimeForMildActivities == "") {
      errors2.averageTimeForMildActivities = true;
    }
    if (comparisonLastThreeMonthsToSevenDays == "") {
      errors2.comparisonLastThreeMonthsToSevenDays == true;
    }
    if (selectedActivity == "") {
      errors2.selectedActivity == true;
    }
    if (hoursOfExercise == "") {
      errors2.hoursOfExercise == true;
    }
    if (!lastSevenDays30minutesOfExercise) {
      errors2.lastSevenDays30minutesOfExercise == true;
    }
    if (totalHoursOfSitting == "") {
      errors2.totalHoursOfSitting = true;
    }
    setFieldErrors2(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        numberOfMealsPerDay: numberOfMeals,
        itemsIncludedInMeals: includedInTheMeals
          ? includedInTheMeals.join(";")
          : includedInTheMeals,
        describeDiet: diet ? diet.join(";") : diet,
        regularBreakfast: doYouHaveBreakfastRegularly
          ? doYouHaveBreakfastRegularly
          : false,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnacksPerDay: numberOfSnacks,
        height: height,
        weight: weight,
        bmi: calculateBmi(height, weight),
        receivedDietCounselling: receivedDietCounselling,
        consultDietitianInNetwork: consultWithDietitian
          ? consultWithDietitian
          : false,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverages: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        selectDiet: selectDiet,
        oftenEatMeat: oftenEatMeat,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        oftenEatEggs: oftenEatEggs,
        moderateIntensityExerciseDays: moderateIntensityExerciseDays
          ? moderateIntensityExerciseDays
          : 0,
        averageTimeForActivities: averageTimeForActivities,
        fastWalkingDays: fastWalkingDays ? fastWalkingDays : 0,
        averageTimeForFastWalking: averageTimeForFastWalking,
        daysForVigorousIntensityExerciseForTenMinutes:
          vigorousIntensityExerciseDays ? vigorousIntensityExerciseDays : 0,
        timeSpentInVigorousIntensityExercise: averageTimeForVigorousActivities,
        daysForMildIntensityExerciseForTenMinutes: MildIntensityExerciseDays
          ? MildIntensityExerciseDays
          : 0,
        timeSpentInMildIntensityExercise: averageTimeForMildActivities,
        comparisonLastThreeMonthsToSevenDays:
          comparisonLastThreeMonthsToSevenDays,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        activityUndertakeRegularly: selectedActivity
          ? selectedActivity.join(";")
          : selectedActivity,
        hoursExerciseInLastSevenDays: hoursOfExercise,
        exerciseAtLeastThirtyMinutesLastSevenDays:
          lastSevenDays30minutesOfExercise
            ? lastSevenDays30minutesOfExercise
            : 0,
        usedTobaccoProducts: usedTobaccoProducts,
        drinkAlcohol: drinkAlcohol ? drinkAlcohol : false,
        howOftenDoYouConsumeAlcohol: drinkAlcohol ? howOftenConsumeAlcohol : "",
        drinkDoYouPrefer: drinkAlcohol ? drinkDoYouPrefer : "",
        drinksPerDay: drinkAlcohol ? drinksPerDay : "",
        areYouTakingFolate: areYouTakingFolate ? areYouTakingFolate : false,
        howMuchFolate: areYouTakingFolate ? howMuchFolate : null,
        usedRecreationalDrugs: usedRecreationalDrugs
          ? usedRecreationalDrugs
          : false,
        drugType: usedRecreationalDrugs ? drugType : "",
        everSmokedPuff: smoked ? smoked : false,
        areYouExSmoker: smoked ? areYouExSmoker : false,
        smokingPerDay: smoked ? smokingPerDay : "",
        timeSpentInSittingPerDay: totalHoursOfSitting,
        hoursOfSleepingPerDay: hoursOfSleep,
        sleepAroundSameTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        feelRefreshedAfterSleep: feelRefreshedAfterSleep,
        preventiveScreeningTestsDone: preventiveScreeningTestDone,
        doYouHaveGlucometerAndUsedIt: glucometerDevice,
        doYouHaveBloodPressureDeviceAndUsedIt: bpDevice,
        lastBloodSugarChecked: bloodSugarCheckedLast,
        lastBloodPressureChecked: bloodPressureCheckedLast,
        concernRegardingIllnessTreatment: concernRegardingIllnessTreatment,
        lifestyleSketchCompleteFlag: lifestyleSketchCompleteFlag ? true : false,
      };
      AxiosInstance.post("form/addLifestyleSketchDiet", formData)
        .then((response) => {
          console.log("response: ", response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave3 = (e) => {
    e.preventDefault();
    let errors3 = {};
    if (usedTobaccoProducts == null) {
      errors3.usedTobaccoProducts = true;
    }
    if (drinkAlcohol == null) {
      errors3.drinkAlcohol = true;
    }
    if (usedRecreationalDrugs == null) {
      errors3.usedRecreationalDrugs = true;
    }
    if (smoked == null) {
      errors3.smoked = true;
    }
    setFieldErrors3(errors3);
    if (Object.keys(errors3).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields111.",
      });
      if (drinkAlcohol == true) {
        console.log("Alcohollll");
        if (
          howOftenConsumeAlcohol === null ||
          drinkDoYouPrefer === null ||
          drinksPerDay === null
        ) {
          console.log("Alcohollllconsumeeee");
          Swal.fire({
            icon: "error",
            text: "Please fill in all mandatory fields.",
          });
        }
      }
      if (usedRecreationalDrugs == true) {
        console.log("usedRecreationalDrugs", usedRecreationalDrugs);
        if (!drugType) {
          console.log("drug");
          Swal.fire({
            icon: "error",
            text: "Please fill in all mandatory fields.",
          });
        }
      }
      if (smoked == true) {
        console.log("smoked", smoked);
        if (smokingPerDay == "") {
          console.log("smoledayy");
          Swal.fire({
            icon: "error",
            text: "Please fill in all mandatory fields.",
          });
        }
      } else {
        if (areYouExSmoker) {
          console.log("exsmokerr", areYouExSmoker);
          Swal.fire({
            icon: "error",
            text: "Please fill in all mandatory fields.",
          });
        }
      }
    } else {
      const formData = {
        numberOfMealsPerDay: numberOfMeals,
        itemsIncludedInMeals: includedInTheMeals
          ? includedInTheMeals.join(";")
          : includedInTheMeals,
        describeDiet: diet ? diet.join(";") : diet,
        regularBreakfast: doYouHaveBreakfastRegularly
          ? doYouHaveBreakfastRegularly
          : false,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnacksPerDay: numberOfSnacks,
        height: height,
        weight: weight,
        bmi: calculateBmi(height, weight),
        receivedDietCounselling: receivedDietCounselling,
        consultDietitianInNetwork: consultWithDietitian
          ? consultWithDietitian
          : false,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverages: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        selectDiet: selectDiet,
        oftenEatMeat: oftenEatMeat,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        oftenEatEggs: oftenEatEggs,
        moderateIntensityExerciseDays: moderateIntensityExerciseDays
          ? moderateIntensityExerciseDays
          : 0,
        averageTimeForActivities: averageTimeForActivities,
        fastWalkingDays: fastWalkingDays ? fastWalkingDays : 0,
        averageTimeForFastWalking: averageTimeForFastWalking,
        daysForVigorousIntensityExerciseForTenMinutes:
          vigorousIntensityExerciseDays ? vigorousIntensityExerciseDays : 0,
        timeSpentInVigorousIntensityExercise: averageTimeForVigorousActivities,
        daysForMildIntensityExerciseForTenMinutes: MildIntensityExerciseDays
          ? MildIntensityExerciseDays
          : 0,
        timeSpentInMildIntensityExercise: averageTimeForMildActivities,
        comparisonLastThreeMonthsToSevenDays:
          comparisonLastThreeMonthsToSevenDays,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        activityUndertakeRegularly: selectedActivity
          ? selectedActivity.join(";")
          : selectedActivity,
        hoursExerciseInLastSevenDays: hoursOfExercise,
        exerciseAtLeastThirtyMinutesLastSevenDays:
          lastSevenDays30minutesOfExercise
            ? lastSevenDays30minutesOfExercise
            : 0,
        usedTobaccoProducts: usedTobaccoProducts ? usedTobaccoProducts : false,
        drinkAlcohol: drinkAlcohol ? drinkAlcohol : false,
        howOftenDoYouConsumeAlcohol: drinkAlcohol ? howOftenConsumeAlcohol : "",
        drinkDoYouPrefer: drinkAlcohol ? drinkDoYouPrefer : "",
        drinksPerDay: drinkAlcohol ? drinksPerDay : "",
        areYouTakingFolate: areYouTakingFolate ? areYouTakingFolate : false,
        howMuchFolate: areYouTakingFolate ? howMuchFolate : null,
        usedRecreationalDrugs: usedRecreationalDrugs
          ? usedRecreationalDrugs
          : false,
        drugType: usedRecreationalDrugs ? drugType : "",
        everSmokedPuff: smoked ? smoked : false,
        areYouExSmoker: smoked ? areYouExSmoker : false,
        smokingPerDay: smoked ? smokingPerDay : "",
        timeSpentInSittingPerDay: totalHoursOfSitting,
        hoursOfSleepingPerDay: hoursOfSleep,
        sleepAroundSameTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        feelRefreshedAfterSleep: feelRefreshedAfterSleep,
        preventiveScreeningTestsDone: preventiveScreeningTestDone,
        doYouHaveGlucometerAndUsedIt: glucometerDevice,
        doYouHaveBloodPressureDeviceAndUsedIt: bpDevice,
        lastBloodSugarChecked: bloodSugarCheckedLast,
        lastBloodPressureChecked: bloodPressureCheckedLast,
        concernRegardingIllnessTreatment: concernRegardingIllnessTreatment,
        lifestyleSketchCompleteFlag: lifestyleSketchCompleteFlag ? true : false,
      };
      AxiosInstance.post("form/addLifestyleSketchDiet", formData)
        .then((response) => {
          console.log("response: ", response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave4 = (e) => {
    e.preventDefault();
    let error4 = {};
    if (hoursOfSleep == "") {
      error4.hoursOfSleep = true;
    }
    if (sleepAroundSameTime == "") {
      error4.sleepAroundSameTime = true;
    }
    if (satisfiedWithSleep == "") {
      error4.satisfiedWithSleep = true;
    }
    if (difficultyWithSleep == "") {
      error4.difficultyWithSleep = true;
    }
    if (feelRefreshedAfterSleep == "") {
      error4.feelRefreshedAfterSleep = true;
    }
    setFieldErrors4(error4);
    if (Object.keys(error4).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        numberOfMealsPerDay: numberOfMeals,
        itemsIncludedInMeals: includedInTheMeals
          ? includedInTheMeals.join(";")
          : includedInTheMeals,
        describeDiet: diet ? diet.join(";") : diet,
        regularBreakfast: doYouHaveBreakfastRegularly
          ? doYouHaveBreakfastRegularly
          : false,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnacksPerDay: numberOfSnacks,
        height: height,
        weight: weight,
        bmi: calculateBmi(height, weight),
        receivedDietCounselling: receivedDietCounselling,
        consultDietitianInNetwork: consultWithDietitian
          ? consultWithDietitian
          : false,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverages: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        selectDiet: selectDiet,
        oftenEatMeat: oftenEatMeat,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        oftenEatEggs: oftenEatEggs,
        moderateIntensityExerciseDays: moderateIntensityExerciseDays
          ? moderateIntensityExerciseDays
          : 0,
        averageTimeForActivities: averageTimeForActivities,
        fastWalkingDays: fastWalkingDays ? fastWalkingDays : 0,
        averageTimeForFastWalking: averageTimeForFastWalking,
        daysForVigorousIntensityExerciseForTenMinutes:
          vigorousIntensityExerciseDays ? vigorousIntensityExerciseDays : 0,
        timeSpentInVigorousIntensityExercise: averageTimeForVigorousActivities,
        daysForMildIntensityExerciseForTenMinutes: MildIntensityExerciseDays
          ? MildIntensityExerciseDays
          : 0,
        timeSpentInMildIntensityExercise: averageTimeForMildActivities,
        comparisonLastThreeMonthsToSevenDays:
          comparisonLastThreeMonthsToSevenDays,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        activityUndertakeRegularly: selectedActivity
          ? selectedActivity.join(";")
          : selectedActivity,
        hoursExerciseInLastSevenDays: hoursOfExercise,
        exerciseAtLeastThirtyMinutesLastSevenDays:
          lastSevenDays30minutesOfExercise
            ? lastSevenDays30minutesOfExercise
            : 0,
        usedTobaccoProducts: usedTobaccoProducts ? usedTobaccoProducts : false,
        drinkAlcohol: drinkAlcohol ? drinkAlcohol : false,
        howOftenDoYouConsumeAlcohol: drinkAlcohol ? howOftenConsumeAlcohol : "",
        drinkDoYouPrefer: drinkAlcohol ? drinkDoYouPrefer : "",
        drinksPerDay: drinkAlcohol ? drinksPerDay : "",
        areYouTakingFolate: areYouTakingFolate ? areYouTakingFolate : false,
        howMuchFolate: areYouTakingFolate ? howMuchFolate : null,
        usedRecreationalDrugs: usedRecreationalDrugs
          ? usedRecreationalDrugs
          : false,
        drugType: usedRecreationalDrugs ? drugType : "",
        everSmokedPuff: smoked ? smoked : false,
        areYouExSmoker: smoked ? areYouExSmoker : false,
        smokingPerDay: smoked ? smokingPerDay : "",
        timeSpentInSittingPerDay: totalHoursOfSitting,
        hoursOfSleepingPerDay: hoursOfSleep,
        sleepAroundSameTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        feelRefreshedAfterSleep: feelRefreshedAfterSleep,
        preventiveScreeningTestsDone: preventiveScreeningTestDone,
        doYouHaveGlucometerAndUsedIt: glucometerDevice,
        doYouHaveBloodPressureDeviceAndUsedIt: bpDevice,
        lastBloodSugarChecked: bloodSugarCheckedLast,
        lastBloodPressureChecked: bloodPressureCheckedLast,
        concernRegardingIllnessTreatment: concernRegardingIllnessTreatment,
        lifestyleSketchCompleteFlag: lifestyleSketchCompleteFlag ? true : false,
      };
      AxiosInstance.post("form/addLifestyleSketchDiet", formData)
        .then((response) => {
          console.log("response: ", response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave5 = (e) => {
    e.preventDefault();
    let error5 = {};
    if (preventiveScreeningTestDone == "") {
      error5.preventiveScreeningTestDone = true;
    }
    if (glucometerDevice == "") {
      error5.glucometerDevice = true;
    }
    if (bpDevice == "") {
      error5.bpDevice = true;
    }
    if (bloodSugarCheckedLast == "") {
      error5.bloodSugarCheckedLast = true;
    }
    if (bloodPressureCheckedLast == "") {
      error5.bloodPressureCheckedLast = true;
    }
    setFieldErrors5(error5);
    if (Object.keys(error5).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        numberOfMealsPerDay: numberOfMeals,
        itemsIncludedInMeals: includedInTheMeals
          ? includedInTheMeals.join(";")
          : includedInTheMeals,
        describeDiet: diet ? diet.join(";") : diet,
        regularBreakfast: doYouHaveBreakfastRegularly
          ? doYouHaveBreakfastRegularly
          : false,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnacksPerDay: numberOfSnacks,
        height: height,
        weight: weight,
        bmi: calculateBmi(height, weight),
        receivedDietCounselling: receivedDietCounselling,
        consultDietitianInNetwork: consultWithDietitian
          ? consultWithDietitian
          : false,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverages: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        selectDiet: selectDiet,
        oftenEatMeat: oftenEatMeat,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        oftenEatEggs: oftenEatEggs,
        moderateIntensityExerciseDays: moderateIntensityExerciseDays
          ? moderateIntensityExerciseDays
          : 0,
        averageTimeForActivities: averageTimeForActivities,
        fastWalkingDays: fastWalkingDays ? fastWalkingDays : 0,
        averageTimeForFastWalking: averageTimeForFastWalking,
        daysForVigorousIntensityExerciseForTenMinutes:
          vigorousIntensityExerciseDays ? vigorousIntensityExerciseDays : 0,
        timeSpentInVigorousIntensityExercise: averageTimeForVigorousActivities,
        daysForMildIntensityExerciseForTenMinutes: MildIntensityExerciseDays
          ? MildIntensityExerciseDays
          : 0,
        timeSpentInMildIntensityExercise: averageTimeForMildActivities,
        comparisonLastThreeMonthsToSevenDays:
          comparisonLastThreeMonthsToSevenDays,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        activityUndertakeRegularly: selectedActivity
          ? selectedActivity.join(";")
          : selectedActivity,
        hoursExerciseInLastSevenDays: hoursOfExercise,
        exerciseAtLeastThirtyMinutesLastSevenDays:
          lastSevenDays30minutesOfExercise
            ? lastSevenDays30minutesOfExercise
            : 0,
        usedTobaccoProducts: usedTobaccoProducts ? usedTobaccoProducts : false,
        drinkAlcohol: drinkAlcohol ? drinkAlcohol : false,
        howOftenDoYouConsumeAlcohol: drinkAlcohol ? howOftenConsumeAlcohol : "",
        drinkDoYouPrefer: drinkAlcohol ? drinkDoYouPrefer : "",
        drinksPerDay: drinkAlcohol ? drinksPerDay : "",
        areYouTakingFolate: areYouTakingFolate ? areYouTakingFolate : false,
        howMuchFolate: areYouTakingFolate ? howMuchFolate : null,
        usedRecreationalDrugs: usedRecreationalDrugs
          ? usedRecreationalDrugs
          : false,
        drugType: usedRecreationalDrugs ? drugType : "",
        everSmokedPuff: smoked ? smoked : false,
        areYouExSmoker: smoked ? areYouExSmoker : false,
        smokingPerDay: smoked ? smokingPerDay : "",
        timeSpentInSittingPerDay: totalHoursOfSitting,
        hoursOfSleepingPerDay: hoursOfSleep,
        sleepAroundSameTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        feelRefreshedAfterSleep: feelRefreshedAfterSleep,
        preventiveScreeningTestsDone: preventiveScreeningTestDone,
        doYouHaveGlucometerAndUsedIt: glucometerDevice,
        doYouHaveBloodPressureDeviceAndUsedIt: bpDevice,
        lastBloodSugarChecked: bloodSugarCheckedLast,
        lastBloodPressureChecked: bloodPressureCheckedLast,
        concernRegardingIllnessTreatment: concernRegardingIllnessTreatment,
        lifestyleSketchCompleteFlag: lifestyleSketchCompleteFlag ? true : false,
      };
      AxiosInstance.post("form/addLifestyleSketchDiet", formData)
        .then((response) => {
          console.log("response: ", response);
        })
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave6 = (e) => {
    e.preventDefault();
    const formData = {
      numberOfMealsPerDay: numberOfMeals,
      itemsIncludedInMeals: includedInTheMeals
        ? includedInTheMeals.join(";")
        : includedInTheMeals,
      describeDiet: diet ? diet.join(";") : diet,
      regularBreakfast: doYouHaveBreakfastRegularly
        ? doYouHaveBreakfastRegularly
        : false,
      appetite: appetite,
      dinnerTime: timeOfDinner,
      numberOfSnacksPerDay: numberOfSnacks,
      height: height,
      weight: weight,
      bmi: calculateBmi(height, weight),
      receivedDietCounselling: receivedDietCounselling,
      consultDietitianInNetwork: consultWithDietitian
        ? consultWithDietitian
        : false,
      preferLowCarbDiet: preferLowCarbDiet,
      preferVegetarianFood: preferVegetarianFood,
      avoidProcessedMeat: avoidProcessedMeat,
      avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
      doNotDrinkSugarSweetenedBeverages: doNotDrinkSugarSweetenedBeverages,
      regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
      drinkCoffee: drinkCoffee,
      limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
      eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
      limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
      highInFiber: highInFiber,
      limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
      selectDiet: selectDiet,
      oftenEatMeat: oftenEatMeat,
      oftenEatYogurt: oftenEatYogurt,
      oftenEatLowFatMilk: oftenEatLowFatMilk,
      oftenEatCheese: oftenEatCheese,
      oftenEatFortifiedCereals: oftenEatFortifiedCereals,
      oftenEatEggs: oftenEatEggs,
      moderateIntensityExerciseDays: moderateIntensityExerciseDays
        ? moderateIntensityExerciseDays
        : 0,
      averageTimeForActivities: averageTimeForActivities,
      fastWalkingDays: fastWalkingDays ? fastWalkingDays : 0,
      averageTimeForFastWalking: averageTimeForFastWalking,
      daysForVigorousIntensityExerciseForTenMinutes:
        vigorousIntensityExerciseDays ? vigorousIntensityExerciseDays : 0,
      timeSpentInVigorousIntensityExercise: averageTimeForVigorousActivities,
      daysForMildIntensityExerciseForTenMinutes: MildIntensityExerciseDays
        ? MildIntensityExerciseDays
        : 0,
      timeSpentInMildIntensityExercise: averageTimeForMildActivities,
      comparisonLastThreeMonthsToSevenDays:
        comparisonLastThreeMonthsToSevenDays,
      itemsIncludeInDiet: includedInTheDiet
        ? includedInTheDiet.join(";")
        : includedInTheDiet,
      mixedIncludedInYourDiet: mixedIncludedInYourDiet
        ? mixedIncludedInYourDiet.join(";")
        : mixedIncludedInYourDiet,
      activityUndertakeRegularly: selectedActivity
        ? selectedActivity.join(";")
        : selectedActivity,
      hoursExerciseInLastSevenDays: hoursOfExercise,
      exerciseAtLeastThirtyMinutesLastSevenDays:
        lastSevenDays30minutesOfExercise ? lastSevenDays30minutesOfExercise : 0,
      usedTobaccoProducts: usedTobaccoProducts ? usedTobaccoProducts : false,
      drinkAlcohol: drinkAlcohol ? drinkAlcohol : false,
      howOftenDoYouConsumeAlcohol: drinkAlcohol ? howOftenConsumeAlcohol : "",
      drinkDoYouPrefer: drinkAlcohol ? drinkDoYouPrefer : "",
      drinksPerDay: drinkAlcohol ? drinksPerDay : "",
      areYouTakingFolate: areYouTakingFolate ? areYouTakingFolate : false,
      howMuchFolate: areYouTakingFolate ? howMuchFolate : null,
      usedRecreationalDrugs: usedRecreationalDrugs
        ? usedRecreationalDrugs
        : false,
      drugType: usedRecreationalDrugs ? drugType : "",
      everSmokedPuff: smoked ? smoked : false,
      areYouExSmoker: smoked ? areYouExSmoker : false,
      smokingPerDay: smoked ? smokingPerDay : "",
      timeSpentInSittingPerDay: totalHoursOfSitting,
      hoursOfSleepingPerDay: hoursOfSleep,
      sleepAroundSameTime: sleepAroundSameTime,
      satisfiedWithSleep: satisfiedWithSleep,
      difficultyWithSleep: difficultyWithSleep,
      feelRefreshedAfterSleep: feelRefreshedAfterSleep,
      preventiveScreeningTestsDone: preventiveScreeningTestDone,
      doYouHaveGlucometerAndUsedIt: glucometerDevice,
      doYouHaveBloodPressureDeviceAndUsedIt: bpDevice,
      lastBloodSugarChecked: bloodSugarCheckedLast,
      lastBloodPressureChecked: bloodPressureCheckedLast,
      concernRegardingIllnessTreatment: concernRegardingIllnessTreatment,
      lifestyleSketchCompleteFlag: true,
    };
    AxiosInstance.post("form/addLifestyleSketchDiet", formData)
      .then((response) => {
        if (!formData.numberOfMealsPerDay) {
          toast.error("Fill all the Mandatory Fields");
        }
        Swal.fire({
          icon: "success",
          text: "Form Submitted Successfully",
        });
        setTimeout(() => {
          onBack();
        }, 1000);
        fetchlifestyleSketchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let lastValue1, lastValue2;
  let value1, value2;
  useEffect(() => {
    AxiosInstance.get("form/lifestyleSketchDiet")
      .then((response) => {
        const match = response.describeDiet.match(/otherValue:([^\s;]+)/g);
        if (match) {
          lastValue1 = match[match.length - 1];
          value1 = lastValue1.slice(11);
          setOtherdietValue(value1);
        } else {
          console.log("No value found");
        }
        const match1 =
          response.itemsIncludedInMeals.match(/otherValue:([^\s;]+)/g);
        if (match1) {
          lastValue2 = match1[match1.length - 1];
          value2 = lastValue2.slice(11);
          setotherMealsValue(value2);
        } else {
          console.log("No value found");
        }
        setHeight(response?.height ? response.height : height);
        setWeight(response?.weight ? response.weight : weight);
        setreceivedDietCounselling(
          response?.receivedDietCounselling
            ? response.receivedDietCounselling
            : receivedDietCounselling
        );
        setnumberOfMeals(
          response?.numberOfMealsPerDay
            ? response.numberOfMealsPerDay
            : numberOfMeals
        );
        setnumberOfSnacks(
          response?.numberOfSnacksPerDay
            ? response.numberOfSnacksPerDay
            : numberOfSnacks
        );
        setdoYouHaveBreakfastRegularly(
          response?.regularBreakfast != null
            ? response.regularBreakfast
            : doYouHaveBreakfastRegularly
        );
        settimeOfDinner(
          response?.dinnerTime ? response.dinnerTime : timeOfDinner
        );
        setAppetite(response?.appetite ? response.appetite : appetite);
        setDiet(
          response?.describeDiet ? response.describeDiet.split(";") : diet
        );
        setincludedInTheMeals(
          response?.itemsIncludedInMeals
            ? response.itemsIncludedInMeals.split(";")
            : includedInTheMeals
        );
        setconsultWithDietitian(
          response?.consultDietitianInNetwork != null
            ? response.consultDietitianInNetwork
            : consultWithDietitian
        );
        setmoderateIntensityExerciseDays(
          response?.moderateIntensityExerciseDays !== undefined &&
            response.moderateIntensityExerciseDays !== null
            ? response.moderateIntensityExerciseDays
            : moderateIntensityExerciseDays
        );
        setaverageTimeForActivities(
          response?.averageTimeForActivities
            ? response.averageTimeForActivities
            : averageTimeForActivities
        );
        setfastWalkingDays(
          // response?.fastWalkingDays ? response.fastWalkingDays : fastWalkingDays
          response?.fastWalkingDays !== undefined &&
            response.fastWalkingDays !== null
            ? response.fastWalkingDays
            : fastWalkingDays
        );
        setaverageTimeForFastWalking(
          response?.averageTimeForFastWalking
            ? response.averageTimeForFastWalking
            : averageTimeForFastWalking
        );
        setvigorousIntensityExerciseDays(
          response?.daysForVigorousIntensityExerciseForTenMinutes !==
            undefined &&
            response.daysForVigorousIntensityExerciseForTenMinutes !== null
            ? response.daysForVigorousIntensityExerciseForTenMinutes
            : vigorousIntensityExerciseDays
        );
        setMildIntensityExerciseDays(
          response?.daysForMildIntensityExerciseForTenMinutes !== undefined &&
            response.daysForMildIntensityExerciseForTenMinutes !== null
            ? response.daysForMildIntensityExerciseForTenMinutes
            : MildIntensityExerciseDays
        );
        setaverageTimeForVigorousActivities(
          response?.timeSpentInVigorousIntensityExercise
            ? response.timeSpentInVigorousIntensityExercise
            : averageTimeForVigorousActivities
        );
        setaverageTimeForMildActivities(
          response?.timeSpentInMildIntensityExercise
            ? response.timeSpentInMildIntensityExercise
            : averageTimeForMildActivities
        );
        settotalHoursOfSitting(
          response?.timeSpentInSittingPerDay
            ? response.timeSpentInSittingPerDay
            : totalHoursOfSitting
        );
        setcomparisonLastThreeMonthsToSevenDays(
          response?.comparisonLastThreeMonthsToSevenDays
            ? response.comparisonLastThreeMonthsToSevenDays
            : comparisonLastThreeMonthsToSevenDays
        );
        setselectedActivity(
          response?.activityUndertakeRegularly
            ? response.activityUndertakeRegularly.split(";")
            : selectedActivity
        );
        sethoursOfExercise(
          response?.hoursExerciseInLastSevenDays
            ? response.hoursExerciseInLastSevenDays
            : hoursOfExercise
        );
        setlastSevenDays30minutesOfExercise(
          response?.exerciseAtLeastThirtyMinutesLastSevenDays
            ? response.exerciseAtLeastThirtyMinutesLastSevenDays
            : lastSevenDays30minutesOfExercise
        );
        setusedTobaccoProducts(
          response?.usedTobaccoProducts !== null
            ? response.usedTobaccoProducts
            : usedTobaccoProducts
        );
        setdrinkAlcohol(
          response?.drinkAlcohol !== null ? response.drinkAlcohol : drinkAlcohol
        );
        setdrinkDoYouPrefer(
          response?.drinkDoYouPrefer
            ? response.drinkDoYouPrefer
            : drinkDoYouPrefer
        );
        sethowOftenConsumeAlcohol(
          response?.howOftenDoYouConsumeAlcohol
            ? response.howOftenDoYouConsumeAlcohol
            : howOftenConsumeAlcohol
        );
        setdrinksPerDay(
          response?.drinksPerDay ? response.drinksPerDay : drinksPerDay
        );
        setusedRecreationalDrugs(
          response?.usedRecreationalDrugs !== null
            ? response.usedRecreationalDrugs
            : usedRecreationalDrugs
        );
        setdrugType(response?.drugType ? response.drugType : drugType);
        setSmoked(
          response?.everSmokedPuff !== null ? response.everSmokedPuff : smoked
        );
        setareYouExSmoker(
          response?.areYouExSmoker !== null
            ? response.areYouExSmoker
            : areYouExSmoker
        );
        setsmokingPerDay(
          response?.smokingPerDay ? response.smokingPerDay : smokingPerDay
        );
        setHoursOfSleep(
          response?.hoursOfSleepingPerDay
            ? response.hoursOfSleepingPerDay
            : hoursOfSleep
        );
        setsleepAroundSameTime(
          response?.sleepAroundSameTime
            ? response.sleepAroundSameTime
            : sleepAroundSameTime
        );
        setsatisfiedWithSleep(
          response?.satisfiedWithSleep
            ? response.satisfiedWithSleep
            : satisfiedWithSleep
        );
        setdifficultyWithSleep(
          response?.difficultyWithSleep
            ? response.difficultyWithSleep
            : difficultyWithSleep
        );
        setfeelRefreshedAfterSleep(
          response?.feelRefreshedAfterSleep
            ? response.feelRefreshedAfterSleep
            : feelRefreshedAfterSleep
        );
        setpreventiveScreeningTestDone(
          response?.preventiveScreeningTestsDone
            ? response.preventiveScreeningTestsDone
            : preventiveScreeningTestDone
        );
        setGlucometerDevice(
          response?.doYouHaveGlucometerAndUsedIt
            ? response.doYouHaveGlucometerAndUsedIt
            : glucometerDevice
        );
        setbpDevice(
          response?.doYouHaveBloodPressureDeviceAndUsedIt
            ? response.doYouHaveBloodPressureDeviceAndUsedIt
            : bpDevice
        );
        setbloodSugarCheckedLast(
          response?.lastBloodSugarChecked
            ? response.lastBloodSugarChecked
            : bloodSugarCheckedLast
        );
        setbloodPressureCheckedLast(
          response?.lastBloodPressureChecked
            ? response.lastBloodPressureChecked
            : bloodPressureCheckedLast
        );
        setconcernRegardingIllnessTreatment(
          response?.concernRegardingIllnessTreatment
            ? response.concernRegardingIllnessTreatment
            : concernRegardingIllnessTreatment
        );
        setpreferLowCarbDiet(
          response?.preferLowCarbDiet
            ? response.preferLowCarbDiet
            : preferLowCarbDiet
        );
        setpreferVegetarianFood(
          response?.preferVegetarianFood
            ? response.preferVegetarianFood
            : preferVegetarianFood
        );
        setavoidProcessedMeat(
          response?.avoidProcessedMeat
            ? response.avoidProcessedMeat
            : avoidProcessedMeat
        );
        setavoidHighlyProcessedFoods(
          response?.avoidHighlyProcessedFoods
            ? response.avoidHighlyProcessedFoods
            : avoidHighlyProcessedFoods
        );
        setdoNotDrinkSugarSweetenedBeverages(
          response?.doNotDrinkSugarSweetenedBeverages
            ? response.doNotDrinkSugarSweetenedBeverages
            : doNotDrinkSugarSweetenedBeverages
        );
        setregularlyConsumeWholeGrainsLegumes(
          response?.regularlyConsumeWholeGrainsLegumes
            ? response.regularlyConsumeWholeGrainsLegumes
            : regularlyConsumeWholeGrainsLegumes
        );
        setdrinkCoffee(
          response?.drinkCoffee ? response.drinkCoffee : drinkCoffee
        );
        setlimitIntakeOfDairyProducts(
          response?.limitIntakeOfDairyProducts
            ? response.limitIntakeOfDairyProducts
            : limitIntakeOfDairyProducts
        );
        seteatDietWhichIsLowInAddedSugars(
          response?.eatDietWhichIsLowInAddedSugars
            ? response.eatDietWhichIsLowInAddedSugars
            : eatDietWhichIsLowInAddedSugars
        );
        setincludedInTheDiet(
          response?.itemsIncludeInDiet
            ? response.itemsIncludeInDiet.split(";")
            : includedInTheDiet
        );
        setmixedIncludedInYourDiet(
          response?.mixedIncludedInYourDiet
            ? response.mixedIncludedInYourDiet.split(";")
            : mixedIncludedInYourDiet
        );
        setoftenEatYogurt(
          response?.oftenEatYogurt ? response.oftenEatYogurt : oftenEatYogurt
        );
        setoftenEatLowFatMilk(
          response?.oftenEatLowFatMilk
            ? response.oftenEatLowFatMilk
            : oftenEatLowFatMilk
        );
        setoftenEatCheese(
          response?.oftenEatCheese ? response.oftenEatCheese : oftenEatCheese
        );
        setoftenEatEggs(
          response?.oftenEatEggs ? response.oftenEatEggs : oftenEatEggs
        );
        setoftenEatFortifiedCereals(
          response?.oftenEatFortifiedCereals
            ? response.oftenEatFortifiedCereals
            : oftenEatFortifiedCereals
        );
        setareYouTakingFolate(
          response?.areYouTakingFolate !== null
            ? response.areYouTakingFolate
            : areYouTakingFolate
        );
        sethowMuchFolate(
          response?.howMuchFolate ? response.howMuchFolate : howMuchFolate
        );
        setlimitOrDecreaseSaltInMyMeals(
          response?.limitOrDecreaseSaltInMyMeals
            ? response.limitOrDecreaseSaltInMyMeals
            : limitOrDecreaseSaltInMyMeals
        );
        sethighInFiber(
          response?.highInFiber ? response.highInFiber : highInFiber
        );
        setlimitOrDecreaseSugarInMyDiet(
          response?.limitOrDecreaseSugarInMyDiet
            ? response.limitOrDecreaseSugarInMyDiet
            : limitOrDecreaseSugarInMyDiet
        );
        setselectDiet(response?.selectDiet ? response.selectDiet : selectDiet);
        setoftenEatMeat(
          response?.oftenEatMeat ? response.oftenEatMeat : oftenEatMeat
        );
        setlifestyleSketchCompleteFlag(response.lifestyleSketchCompleteFlag);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setProfile(response?.profile);
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("preferLowCarbDiet", preferLowCarbDiet);
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        setFamilyHistory(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const getRelationship = () => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        console.log("responseFamily: ", response);
        setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getRelationship();
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleModerateIntensityClick = () => {
    const moderateIntensityActivities = [
      "Walking two miles in 30 minutes.",
      "Biking five miles in 30 minutes.",
      "Swimming laps for 20 minutes.",
      "Running one and a half miles in 15 minutes.",
      "Doing water aerobics for 30 minutes.",
      "Playing volleyball for 45 minutes.",
      "Playing basketball for 20 minutes.",
      "Jumping rope for 15 minutes.",
      "Walking stairs for 15 minutes.",
      "Washing your car for 45 minutes to an hour.",
      "Gardening for 30 to 45 minutes.",
      "Raking leaves for 30 minutes.",
      "Dancing for 30 minutes.",
    ];

    const formattedActivities = moderateIntensityActivities
      .map((activity, index) => `${index + 1}. ${activity}`)
      .join("\n");

    Swal.fire({
      title: "Activities for Moderate Intensity",
      html: `<pre>${formattedActivities}</pre>`,
      icon: "info",
    });
  };

  const handleVigorousIntensityClick = () => {
    const vergorousIntensityActivities = [
      "Hiking",
      "Jogging at 6 mph",
      "Shoveling",
      "Carrying heavy loads",
      "Bicycling fast (14-16 mph)",
      "Basketball game",
      "Soccer game",
      "Tennis singles",
      "Cricket",
    ];
    const formattedActivities = vergorousIntensityActivities
      .map((activity, index) => `${index + 1}. ${activity}`)
      .join("\n");

    Swal.fire({
      title: "Activities for Vigorous Intensity",
      html: `<pre>${formattedActivities}</pre>`,
      icon: "info",
    });
  };

  const handleMildIntensityClick = () => {
    const mildIntensityActivities = [
      "Walking slowly",
      "Sitting using computer",
      "Standing light work (cooking, washing dishes)",
      "Fishing sitting",
      "Playing most instrument",
    ];
    const formattedActivities = mildIntensityActivities
      .map((activity, index) => `${index + 1}. ${activity}`)
      .join("\n");

    Swal.fire({
      title: "Activities for Mild Intensity",
      html: `<pre>${formattedActivities}</pre>`,
      icon: "info",
    });
  };

  console.log("lifestyleSketchCompleteFlag", lifestyleSketchCompleteFlag);

  return (
    <div className="profile_section">
      <ToastMessage></ToastMessage>
      <form className="registration_tier1">
        {LifestyleStep == 5 ? (
          ""
        ) : (
          <span style={{ float: "right", color: "red" }}>
            Note : All Fields are Mandatory
          </span>
        )}
        {LifestyleStep == 0 && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item>
                {/* {profile?.age < 3 && (
                  <>
                    <h4 style={{ color: "red" }}>
                      The health Insights/Information presented below is for
                      your child {profile?.firstName}
                    </h4>
                  </>
                )} */}
                {familyRelation?.accessNeeded == true ? (
                  <span style={{ color: "red", fontSize: "x-large" }}>
                    Note : Ask{" "}
                    <span style={{ fontSize: "larger" }}>
                      {familyInfo?.firstName &&
                        familyInfo.firstName.charAt(0).toUpperCase() +
                          familyInfo.firstName.slice(1).toLowerCase()}
                    </span>{" "}
                    to fill this form.
                  </span>
                ) : (
                  ""
                )}
                <div className="register_title" style={{ marginTop: "15px" }}>
                  <h4>1. Diet</h4>
                </div>
                <div>
                  {" "}
                  A. Think of what you ate last week (7 days) and answer the
                  following.
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.numberOfMeals ? "red" : "inherit",
                    }}
                  >
                    Number of meals in a day.
                  </FormLabel>
                  <Select
                    value={numberOfMeals}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlenumberOfMealsChange}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"More than 4"}>More than 4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.includedInTheMeals ? "red" : "inherit",
                    }}
                  >
                    What was included in the meals ?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheMeals.includes(
                            "Vegetables only"
                          )}
                        />
                      }
                      label="Vegetables only"
                      value="Vegetables only"
                      onChange={handleincludedInTheMealsChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheMeals.includes(
                            "Mixed (Veg + Nonveg)"
                          )}
                        />
                      }
                      label="Mixed (Veg + Nonveg)"
                      value="Mixed (Veg + Nonveg)"
                      onChange={handleincludedInTheMealsChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheMeals.includes("Eggs")}
                        />
                      }
                      label="Eggs"
                      value="Eggs"
                      onChange={handleincludedInTheMealsChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheMeals.includes("Fish")}
                        />
                      }
                      label="Fish"
                      value="Fish"
                      onChange={handleincludedInTheMealsChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheMeals.includes("Dairy")}
                        />
                      }
                      label="Dairy"
                      value="Dairy"
                      onChange={handleincludedInTheMealsChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheMeals.includes("Fresh Fruits")}
                        />
                      }
                      label="Fresh Fruits"
                      value="Fresh Fruits"
                      onChange={handleincludedInTheMealsChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheMeals.includes("Other")}
                        />
                      }
                      label="Other"
                      value="Other"
                      onChange={handleincludedInTheMealsChange}
                    />
                  </FormGroup>
                  {includedInTheMeals.includes("Other") && (
                    <input
                      type="text"
                      value={otherMealsValue}
                      onChange={handleincludedInTheMealsChange2}
                      onBlur={handleInputBlur}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.diet ? "red" : "inherit",
                    }}
                  >
                    <span>How would you describe your diet?</span>
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={diet.includes("Regular")} />}
                      label="Regular"
                      value="Regular"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={diet.includes("Diabetic")} />}
                      label="Diabetic"
                      value="Diabetic"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={diet.includes("Low Salt")} />}
                      label="Low Salt"
                      value="Low Salt"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={diet.includes("Low Fat")} />}
                      label="Low Fat"
                      value="Low Fat"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={diet.includes("Low Cholesterol")} />
                      }
                      label="Low Cholesterol"
                      value="Low Cholesterol"
                      onChange={handleDietChange}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox checked={diet.includes("High in Fiber")} />
                      }
                      label="High in Fiber"
                      value="High in Fiber"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={diet.includes("Other")} />}
                      label="Other"
                      value="Other"
                      onChange={handleDietChange}
                    />
                  </FormGroup>
                  {diet.includes("Other") && (
                    <input
                      type="text"
                      value={otherdietValue}
                      onChange={handleOtherValueChange2}
                      onBlur={handleInputBlurDiet}
                    />
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.doYouHaveBreakfastRegularly
                        ? "red"
                        : "inherit",
                    }}
                  >
                    <span>Do you have breakfast regularly?</span>
                  </FormLabel>
                  <Select
                    value={doYouHaveBreakfastRegularly}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handledoYouHaveBreakfastRegularlyChange}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={"true"}>Yes</MenuItem>
                    <MenuItem value={"false"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.appetite ? "red" : "inherit",
                    }}
                  >
                    <span>My appetite is :</span>
                  </FormLabel>
                  <Select
                    value={appetite}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleAppetiteChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Very Poor"}>Very Poor</MenuItem>
                    <MenuItem value={"Poor"}>Poor</MenuItem>
                    <MenuItem value={"Average"}>Average</MenuItem>
                    <MenuItem value={"Good"}>Good</MenuItem>
                    <MenuItem value={"Very Good"}>Very Good</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.timeOfDinner ? "red" : "inherit",
                    }}
                  >
                    <span> What time do you have dinner ?</span>
                  </FormLabel>
                  <Select
                    value={timeOfDinner}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handletimeOfDinnerChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Between 5-6pm"}>Between 5-6pm</MenuItem>
                    <MenuItem value={"6-7pm"}>6-7pm</MenuItem>
                    <MenuItem value={"7-8pm"}>7-8pm</MenuItem>
                    <MenuItem value={"8-9pm"}>8-9pm</MenuItem>
                    <MenuItem value={"9-10pm"}>9-10pm</MenuItem>
                    <MenuItem value={"10-11pm"}>10-11pm</MenuItem>
                    <MenuItem value={"After 11pm"}>After 11pm</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.numberOfSnacks ? "red" : "inherit",
                    }}
                  >
                    Number of snacks per day.
                  </FormLabel>
                  <Select
                    value={numberOfSnacks}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlenumberOfSnacksChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"More than 4"}>More than 4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>B. General Questions</div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <label
                  style={{
                    color: fieldErrors.height ? "red" : "inherit",
                  }}
                >
                  <span>What is your current height(cm) ?</span>
                </label>
                <input
                  type="number"
                  onChange={(event) => {
                    onHeightChange(event);
                    onBlurHeight(event);
                  }}
                  value={height}
                ></input>
                {errorHeight && (
                  <div className="error" style={{ color: "red" }}>
                    {errorHeight}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <label
                  style={{
                    color: fieldErrors.weight ? "red" : "inherit",
                  }}
                >
                  <span> What is your current weight(kg) ?</span>
                </label>
                <input
                  type="number"
                  onChange={(event) => {
                    onWeightChange(event);
                    onBlurWeight(event);
                  }}
                  value={weight}
                ></input>
                {errorWeight && (
                  <div className="error" style={{ color: "red" }}>
                    {errorWeight}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={1}>
                <label>BMI</label>
                <input
                  disabled
                  style={{ backgroundColor: "lightgray", textIndent: "10px" }}
                  value={calculateBmi(height, weight)}
                ></input>
              </Grid>
              {/* {age >= 2 && ( */}
              {profile?.age >= 3 && (
                <>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <FormLabel
                        style={{
                          color: fieldErrors.receivedDietCounselling
                            ? "red"
                            : "inherit",
                        }}
                      >
                        From whom have you received diet counselling ?
                      </FormLabel>
                      <Select
                        value={receivedDietCounselling}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={handlerecieveddietcounsellingChange}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value={"None"}>None</MenuItem>
                        <MenuItem value={"Doctor"}>Doctor</MenuItem>
                        <MenuItem value={"Dietitian"}>Dietitian</MenuItem>
                        <MenuItem value={"Family Members / Friends"}>
                          Family Members / Friends
                        </MenuItem>
                        <MenuItem value={"Online"}>Online</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <FormLabel
                        style={{
                          color: fieldErrors.consultWithDietitian
                            ? "red"
                            : "inherit",
                        }}
                      >
                        Would you like to consult with a dietitian in our
                        network ?
                      </FormLabel>
                      <Select
                        value={consultWithDietitian}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={handleconsultWithDietitianChange}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"true"}>Yes</MenuItem>
                        <MenuItem value={"false"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            {profile?.age >= 3 && (
              <>
                <Grid container spacing={2} mb={2}>
                  <Grid item>
                    <div>
                      Please rate your Diet choices and behavior on a scale of 1
                      to 5 for questions 1 to 12 as explained below WHERE (1=
                      Strongly agree. 2= Agree 3= Neutral 4= Disagree 5=
                      Strongly disagree).
                    </div>
                  </Grid>
                </Grid>

                <div className="desktop-view">
                  <Row>
                    <Col lg={7}></Col>
                    <Col lg={5}>
                      <div style={{ display: "flex", gap: "85px" }}>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="desktop-view">
                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.preferLowCarbDiet
                              ? "red"
                              : "inherit",
                          }}
                        >
                          1. I prefer to have a low carb diet.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={preferLowCarbDiet}
                          onChange={handlepreferLowCarbDietChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.preferVegetarianFood
                              ? "red"
                              : "inherit",
                          }}
                        >
                          2. I mostly prefer Vegetarian food and try to have
                          more vegetables and fruits in my daily diet.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          value={preferVegetarianFood}
                          onChange={handlepreferVegetarianFoodChange}
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.avoidProcessedMeat
                              ? "red"
                              : "inherit",
                          }}
                        >
                          3. I try to avoid processed meat (Hamburgers,
                          sausages, Hot dogs, etc.).
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          value={avoidProcessedMeat}
                          onChange={handleavoidProcessedMeatChange}
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.avoidHighlyProcessedFoods
                              ? "red"
                              : "inherit",
                          }}
                        >
                          4. I try to avoid highly processed foods (convenience
                          meals, fast goods, cookies, chips, pretzels, etc).
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={avoidHighlyProcessedFoods}
                          onChange={handleavoidHighlyProcessedFoodstChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.doNotDrinkSugarSweetenedBeverages
                              ? "red"
                              : "inherit",
                          }}
                        >
                          5. I do not drink sugar sweetened beverages (Pepsi,
                          coke, etc.).
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={doNotDrinkSugarSweetenedBeverages}
                          onChange={
                            handledoNotDrinkSugarSweetenedBeveragesChange
                          }
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color:
                              fieldErrors.regularlyConsumeWholeGrainsLegumes
                                ? "red"
                                : "inherit",
                          }}
                        >
                          6. I regularly consume whole grains, legumes(Peas
                          and beans) and nuts and seeds (Chia, flax, pumpkin,,
                          etc.) as part of my healthy diet practice.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={regularlyConsumeWholeGrainsLegumes}
                          onChange={
                            handleregularlyConsumeWholeGrainsLegumesChange
                          }
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.drinkCoffee ? "red" : "inherit",
                          }}
                        >
                          7. I drink coffee at least once daily.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={drinkCoffee}
                          onChange={handledrinkCoffeeChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.limitIntakeOfDairyProducts
                              ? "red"
                              : "inherit",
                          }}
                        >
                          8. I like to limit intake of dairy products (cheese,
                          cream, Butter and Ghee, Yogurt, etc.) in my diet but
                          do take few times every week.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={limitIntakeOfDairyProducts}
                          onChange={handlelimitIntakeOfDairyProductsChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.eatDietWhichIsLowInAddedSugars
                              ? "red"
                              : "inherit",
                          }}
                        >
                          9. I eat a diet which is low in added sugars, sodium,
                          saturated fats, trans fats, and cholesterol.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={eatDietWhichIsLowInAddedSugars}
                          onChange={handleeatDietWhichIsLowInAddedSugarsChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.limitOrDecreaseSaltInMyMeals
                              ? "red"
                              : "inherit",
                          }}
                        >
                          10. I consciously try to limit or decrease salt in my
                          meals.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={limitOrDecreaseSaltInMyMeals}
                          onChange={handlelimitOrDecreaseSaltInMyMealsChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.highInFiber ? "red" : "inherit",
                          }}
                        >
                          11. I consciously try to eat food or fruits that are
                          high in fiber.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={highInFiber}
                          onChange={handlehighInFiberChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.limitOrDecreaseSugarInMyDiet
                              ? "red"
                              : "inherit",
                          }}
                        >
                          12. I consciously try to limit or decrease sugar in my
                          diet.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: "45px",
                          }}
                          value={limitOrDecreaseSugarInMyDiet}
                          onChange={handlelimitOrDecreaseSugarInMyDietChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            // label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            // label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            // label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            // label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            // label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>
                </div>

                <div className="mobile-view">
                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.preferLowCarbDiet
                              ? "red"
                              : "inherit",
                          }}
                        >
                          1. I prefer to have a low carb diet.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={preferLowCarbDiet}
                          onChange={handlepreferLowCarbDietChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.preferVegetarianFood
                              ? "red"
                              : "inherit",
                          }}
                        >
                          2. I mostly prefer Vegetarian food and try to have
                          more vegetables and fruits in my daily diet.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          value={preferVegetarianFood}
                          onChange={handlepreferVegetarianFoodChange}
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.avoidProcessedMeat
                              ? "red"
                              : "inherit",
                          }}
                        >
                          3. I try to avoid processed meat (Hamburgers,
                          sausages, Hot dogs, etc.).
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          value={avoidProcessedMeat}
                          onChange={handleavoidProcessedMeatChange}
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.avoidHighlyProcessedFoods
                              ? "red"
                              : "inherit",
                          }}
                        >
                          4. I try to avoid highly processed foods (convenience
                          meals, fast goods, cookies, chips, pretzels, etc).
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={avoidHighlyProcessedFoods}
                          onChange={handleavoidHighlyProcessedFoodstChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.doNotDrinkSugarSweetenedBeverages
                              ? "red"
                              : "inherit",
                          }}
                        >
                          5. I do not drink sugar sweetened beverages (Pepsi,
                          coke, etc.).
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={doNotDrinkSugarSweetenedBeverages}
                          onChange={
                            handledoNotDrinkSugarSweetenedBeveragesChange
                          }
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color:
                              fieldErrors.regularlyConsumeWholeGrainsLegumes
                                ? "red"
                                : "inherit",
                          }}
                        >
                          6. I regularly consume whole grains, legumes(Peas
                          and beans) and nuts and seeds (Chia, flax, pumpkin,,
                          etc.) as part of my healthy diet practice.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={regularlyConsumeWholeGrainsLegumes}
                          onChange={
                            handleregularlyConsumeWholeGrainsLegumesChange
                          }
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.drinkCoffee ? "red" : "inherit",
                          }}
                        >
                          7. I drink coffee at least once daily.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={drinkCoffee}
                          onChange={handledrinkCoffeeChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.limitIntakeOfDairyProducts
                              ? "red"
                              : "inherit",
                          }}
                        >
                          8. I like to limit intake of dairy products (cheese,
                          cream, Butter and Ghee, Yogurt, etc.) in my diet but
                          do take few times every week.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={limitIntakeOfDairyProducts}
                          onChange={handlelimitIntakeOfDairyProductsChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.eatDietWhichIsLowInAddedSugars
                              ? "red"
                              : "inherit",
                          }}
                        >
                          9. I eat a diet which is low in added sugars, sodium,
                          saturated fats, trans fats, and cholesterol.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={eatDietWhichIsLowInAddedSugars}
                          onChange={handleeatDietWhichIsLowInAddedSugarsChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.limitOrDecreaseSaltInMyMeals
                              ? "red"
                              : "inherit",
                          }}
                        >
                          10. I consciously try to limit or decrease salt in my
                          meals.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={limitOrDecreaseSaltInMyMeals}
                          onChange={handlelimitOrDecreaseSaltInMyMealsChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.highInFiber ? "red" : "inherit",
                          }}
                        >
                          11. I consciously try to eat food or fruits that are
                          high in fiber.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={highInFiber}
                          onChange={handlehighInFiberChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>

                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <Row>
                      <Col lg={7}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.limitOrDecreaseSugarInMyDiet
                              ? "red"
                              : "inherit",
                          }}
                        >
                          12. I consciously try to limit or decrease sugar in my
                          diet.
                        </FormLabel>
                      </Col>
                      <Col lg={5}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            flexDirection: "row",
                            flexWrap: "nowrap",
                          }}
                          value={limitOrDecreaseSugarInMyDiet}
                          onChange={handlelimitOrDecreaseSugarInMyDietChange}
                        >
                          <FormControlLabel
                            value="Strongly agree"
                            control={<Radio />}
                            label="1"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Agree"
                            control={<Radio />}
                            label="2"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Neutral"
                            control={<Radio />}
                            label="3"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Disagree"
                            control={<Radio />}
                            label="4"
                            style={{ display: "flex" }}
                          />
                          <FormControlLabel
                            value="Strongly disagree"
                            control={<Radio />}
                            label="5"
                            style={{ display: "flex" }}
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </FormControl>
                </div>

                <Grid container spacing={2} mb={5} mt={5}>
                  <Grid item xs={12} md={3}>
                    <FormControl>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        style={{
                          color: fieldErrors.selectDiet ? "red" : "inherit",
                        }}
                      >
                        In terms of your diet, select one of the following:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="radio-buttons-group"
                        value={selectDiet}
                        onChange={handleselectDietChange}
                      >
                        <FormControlLabel
                          value="Strictly vegetarian (Eggs not included)"
                          control={<Radio />}
                          label="Strictly vegetarian (Eggs not included)"
                        />
                        <FormControlLabel
                          value="Eggetarian (veg + eggs)"
                          control={<Radio />}
                          label="Eggetarian (veg + eggs)"
                        />
                        <FormControlLabel
                          value="Mixed (includes veg, meat and poultry)"
                          control={<Radio />}
                          label="Mixed (includes veg, meat and poultry)"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {selectDiet.includes(
                    "Strictly vegetarian (Eggs not included)"
                  ) && (
                    <Grid item xs={12} md={3}>
                      <FormControl>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            color: fieldErrors.includedInTheDiet
                              ? "red"
                              : "inherit",
                          }}
                        >
                          Which of these do you include in your diet ?
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={includedInTheDiet.includes("Yogurt")}
                              />
                            }
                            label="Yogurt"
                            value="Yogurt"
                            onChange={handleincludedInTheDietChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={includedInTheDiet.includes(
                                  "Low fat milk"
                                )}
                              />
                            }
                            label="Low fat milk"
                            value="Low fat milk"
                            onChange={handleincludedInTheDietChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={includedInTheDiet.includes("Cheese")}
                              />
                            }
                            label="Cheese"
                            value="Cheese"
                            onChange={handleincludedInTheDietChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={includedInTheDiet.includes(
                                  "Fortified cereals"
                                )}
                              />
                            }
                            label="Fortified cereals"
                            value="Fortified cereals"
                            onChange={handleincludedInTheDietChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={includedInTheDiet.includes(
                                  "None Of the Above"
                                )}
                              />
                            }
                            label="None Of the Above"
                            value="None Of the Above"
                            onChange={handleincludedInTheDietChange}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {selectDiet.includes("Eggetarian (veg + eggs)") && (
                    <Grid item xs={12} md={3}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          How Often do you eat Eggs ?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          value={oftenEatEggs}
                          onChange={handleoftenEatEggsChange}
                        >
                          <FormControlLabel
                            value="Daily"
                            control={<Radio />}
                            label="Daily"
                          />
                          <FormControlLabel
                            value="Once a week"
                            control={<Radio />}
                            label="Once a week"
                          />
                          <FormControlLabel
                            value="Twice a week"
                            control={<Radio />}
                            label="Twice a week"
                          />
                          <FormControlLabel
                            value="Three times a week"
                            control={<Radio />}
                            label="Three times a week"
                          />
                          <FormControlLabel
                            value="> 3 times a week"
                            control={<Radio />}
                            label="> 3 times a week"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {selectDiet.includes(
                    "Mixed (includes veg, meat and poultry)"
                  ) && (
                    <Grid item xs={12} md={3}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          How Often do you eat Meat ?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          value={oftenEatMeat}
                          onChange={handleoftenEatMeatChange}
                        >
                          <FormControlLabel
                            value="Daily"
                            control={<Radio />}
                            label="Daily"
                          />
                          <FormControlLabel
                            value="Once a week"
                            control={<Radio />}
                            label="Once a week"
                          />
                          <FormControlLabel
                            value="Twice a week"
                            control={<Radio />}
                            label="Twice a week"
                          />
                          <FormControlLabel
                            value="Three times a week"
                            control={<Radio />}
                            label="Three times a week"
                          />
                          <FormControlLabel
                            value="> 3 times a week"
                            control={<Radio />}
                            label="> 3 times a week"
                          />
                          <FormControlLabel
                            value="Once a month"
                            control={<Radio />}
                            label="Once a month"
                          />
                          <FormControlLabel
                            value="Less frequently"
                            control={<Radio />}
                            label="Less frequently"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {selectDiet.includes(
                    "Mixed (includes veg, meat and poultry)"
                  ) && (
                    <Grid item xs={12} md={3}>
                      <FormControl>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          // style={{
                          //   color: fieldErrors.diet ? "red" : "inherit",
                          // }}
                        >
                          which of the following you mostly include in your diet
                          ? (check all applicable)
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={mixedIncludedInYourDiet.includes(
                                  "Eggs"
                                )}
                              />
                            }
                            label="Eggs"
                            value="Eggs"
                            onChange={handlemixedIncludedInYourDietChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={mixedIncludedInYourDiet.includes(
                                  "Fish"
                                )}
                              />
                            }
                            label="Fish"
                            value="Fish"
                            onChange={handlemixedIncludedInYourDietChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={mixedIncludedInYourDiet.includes(
                                  "Poultry (chicken, Turkey)"
                                )}
                              />
                            }
                            label="Poultry (chicken, Turkey)"
                            value="Poultry (chicken, Turkey)"
                            onChange={handlemixedIncludedInYourDietChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={mixedIncludedInYourDiet.includes(
                                  "Red Meat (Goat, pork, lamb)"
                                )}
                              />
                            }
                            label="Red Meat (Goat, pork, lamb)"
                            value="Red Meat (Goat, pork, lamb)"
                            onChange={handlemixedIncludedInYourDietChange}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {selectDiet.includes(
                    "Strictly vegetarian (Eggs not included)"
                  ) &&
                    includedInTheDiet.includes("Yogurt") && (
                      <Grid item xs={12} md={3}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            How Often do you eat Yogurt ?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            value={oftenEatYogurt}
                            onChange={handleoftenEatYogurtChange}
                          >
                            <FormControlLabel
                              value="Daily"
                              control={<Radio />}
                              label="Daily"
                            />
                            <FormControlLabel
                              value="Once a week"
                              control={<Radio />}
                              label="Once a week"
                            />
                            <FormControlLabel
                              value="Twice a week"
                              control={<Radio />}
                              label="Twice a week"
                            />
                            <FormControlLabel
                              value="Three times a week"
                              control={<Radio />}
                              label="Three times a week"
                            />
                            <FormControlLabel
                              value="> 3 times a week"
                              control={<Radio />}
                              label="> 3 times a week"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                  {selectDiet.includes(
                    "Strictly vegetarian (Eggs not included)"
                  ) &&
                    includedInTheDiet.includes("Low fat milk") && (
                      <Grid item xs={12} md={3}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            How Often do you eat Low fat milk ?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            value={oftenEatLowFatMilk}
                            onChange={handleoftenEatLowFatMilkChange}
                          >
                            <FormControlLabel
                              value="Daily"
                              control={<Radio />}
                              label="Daily"
                            />
                            <FormControlLabel
                              value="Once a week"
                              control={<Radio />}
                              label="Once a week"
                            />
                            <FormControlLabel
                              value="Twice a week"
                              control={<Radio />}
                              label="Twice a week"
                            />
                            <FormControlLabel
                              value="Three times a week"
                              control={<Radio />}
                              label="Three times a week"
                            />
                            <FormControlLabel
                              value="> 3 times a week"
                              control={<Radio />}
                              label="> 3 times a week"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                  {selectDiet.includes(
                    "Strictly vegetarian (Eggs not included)"
                  ) &&
                    includedInTheDiet.includes("Cheese") && (
                      <Grid item xs={12} md={3}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            How Often do you eat Cheese ?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            value={oftenEatCheese}
                            onChange={handleoftenEatCheeseChange}
                          >
                            <FormControlLabel
                              value="Daily"
                              control={<Radio />}
                              label="Daily"
                            />
                            <FormControlLabel
                              value="Once a week"
                              control={<Radio />}
                              label="Once a week"
                            />
                            <FormControlLabel
                              value="Twice a week"
                              control={<Radio />}
                              label="Twice a week"
                            />
                            <FormControlLabel
                              value="Three times a week"
                              control={<Radio />}
                              label="Three times a week"
                            />
                            <FormControlLabel
                              value="> 3 times a week"
                              control={<Radio />}
                              label="> 3 times a week"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                  {selectDiet.includes(
                    "Strictly vegetarian (Eggs not included)"
                  ) &&
                    includedInTheDiet.includes("Fortified cereals") && (
                      <Grid item xs={12} md={3}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            How Often do you eat Fortified cereals ?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            value={oftenEatFortifiedCereals}
                            onChange={handleoftenEatFortifiedCerealsChange}
                          >
                            <FormControlLabel
                              value="Daily"
                              control={<Radio />}
                              label="Daily"
                            />
                            <FormControlLabel
                              value="Once a week"
                              control={<Radio />}
                              label="Once a week"
                            />
                            <FormControlLabel
                              value="Twice a week"
                              control={<Radio />}
                              label="Twice a week"
                            />
                            <FormControlLabel
                              value="Three times a week"
                              control={<Radio />}
                              label="Three times a week"
                            />
                            <FormControlLabel
                              value="> 3 times a week"
                              control={<Radio />}
                              label="> 3 times a week"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                </Grid>
              </>
            )}

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={onBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                {familyRelation == null ? (
                  <button
                    style={{ minWidth: "200px" }}
                    type="button"
                    className=""
                    onClick={handleSave1}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    style={{ minWidth: "200px" }}
                    type="button"
                    className=""
                    onClick={handleSave1}
                  >
                    Save
                  </button>
                )}
              </div>
            </Grid>
          </>
        )}

        {LifestyleStep == 1 && (
          <>
            {console.log("here", profile)}
            {/* {profile?.age < 18  && (<><span style={{color:"red", fontSize:"larger"}}>Note : Fill this form for {profile?.firstName}.</span></>)}
             {profile?.age >= 18  && (<><span style={{color:"red", fontSize:"larger"}}>Note : Ask {profile?.firstName} to fill this form.</span></>)} */}
            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>
                  <h4>2. Exercise</h4>
                  <p>
                    A.{" "}
                    <a href="#" onClick={handleModerateIntensityClick}>
                      Moderate intensity
                    </a>{" "}
                    &nbsp; exercise includes physical activities that get you
                    breathing harder and your heart beating faster. Examples of
                    exercise include setting aside time for things like:
                    jogging, dancing, bike riding, aerobic classes, swimming,
                    working out to an exercise video. Exercise does not include
                    what you do at work. Use this definition to answer the
                    questions below.
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.moderateIntensityExerciseDays
                        ? "red"
                        : "inherit",
                    }}
                  >
                    How many days in a week do you do moderate intensity
                    exercise for at least 10 minutes at a time without stopping
                    ?
                  </FormLabel>
                  <Select
                    value={moderateIntensityExerciseDays}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlemoderateIntensityExerciseDaysChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.averageTimeForActivities
                        ? "red"
                        : "inherit",
                    }}
                  >
                    On those days, how much time (in minutes) do you spend on
                    average doing the activities ?
                  </FormLabel>
                  <Select
                    value={averageTimeForActivities}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleaverageTimeForActivitiesChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"15"}>15</MenuItem>
                    <MenuItem value={"30"}>30</MenuItem>
                    <MenuItem value={"45"}>45</MenuItem>
                    <MenuItem value={"60"}>60</MenuItem>
                    <MenuItem value={"90"}>90</MenuItem>
                    <MenuItem value={"91"}>&gt; 90 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.fastWalkingDays ? "red" : "inherit",
                    }}
                  >
                    Walking fast (3-4 mph) is also exercise. How many days in a
                    week do you walk fast for at least 10 minutes at a time
                    without stopping ?
                  </FormLabel>
                  <Select
                    value={fastWalkingDays}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlefastWalkingDaysChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.averageTimeForFastWalking
                        ? "red"
                        : "inherit",
                    }}
                  >
                    On those days, how much time (in minutes) did you spend on
                    average walking fast ?
                  </FormLabel>
                  <Select
                    value={averageTimeForFastWalking}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleaverageTimeForFastWalkingChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"15"}>15</MenuItem>
                    <MenuItem value={"30"}>30</MenuItem>
                    <MenuItem value={"45"}>45</MenuItem>
                    <MenuItem value={"60"}>60</MenuItem>
                    <MenuItem value={"90"}>90</MenuItem>
                    <MenuItem value={"91"}>&gt; 90 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>
                  <p>
                    B.{" "}
                    <a href="#" onClick={handleVigorousIntensityClick}>
                      Vigorous Intensity{" "}
                    </a>
                    exercise includes physical activities like Race walking,
                    jogging, running , Bicycling 10 mph or faster , Swimming
                    laps , Aerobic dancing , Jumping rope , Heavy gardening
                    (continuous digging or hoeing with heart rate increases) ,
                    Hiking uphill or with a heavy backpack. Use this definition
                    to answer the questions below.
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.vigorousIntensityExerciseDays
                        ? "red"
                        : "inherit",
                    }}
                  >
                    How many days in a week do you do Vigorous intensity
                    exercise for at least 10 minutes at a time without stopping
                    ?
                  </FormLabel>
                  <Select
                    value={vigorousIntensityExerciseDays}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleVigorousIntensityExerciseDaysChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.averageTimeForVigorousActivities
                        ? "red"
                        : "inherit",
                    }}
                  >
                    On those days, how much time (in minutes) do you spend on
                    average doing the Vigorous activities ?
                  </FormLabel>
                  <Select
                    value={averageTimeForVigorousActivities}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleaverageTimeForVigorousActivitiesChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"15"}>15</MenuItem>
                    <MenuItem value={"30"}>30</MenuItem>
                    <MenuItem value={"45"}>45</MenuItem>
                    <MenuItem value={"60"}>60</MenuItem>
                    <MenuItem value={"90"}>90</MenuItem>
                    <MenuItem value={"91"}>&gt; 90 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>
                  <p>
                    C.{" "}
                    <a href="#" onClick={handleMildIntensityClick}>
                      Regular mild or Mild Intensity{" "}
                    </a>
                    is any physical exercise that gets your heart rate less than
                    50% higher than your resting heart rate. Examples includes-
                    walking slowly, using computer, standing light work
                    (cooking, washing dishes), fishing, playing most
                    instruments, etc. Use this definition to answer the
                    questions below.
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.MildIntensityExerciseDays
                        ? "red"
                        : "inherit",
                    }}
                  >
                    How many days in a week do you do Mild intensity exercise
                    for at least 10 minutes?
                  </FormLabel>
                  <Select
                    value={MildIntensityExerciseDays}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleMildIntensityExerciseDaysChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.averageTimeForMildActivities
                        ? "red"
                        : "inherit",
                    }}
                  >
                    On those days, how much time (in minutes) do you spend on
                    average doing the Mild activities ?
                  </FormLabel>
                  <Select
                    value={averageTimeForMildActivities}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleaverageTimeForMildActivitiesChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"15"}>15</MenuItem>
                    <MenuItem value={"30"}>30</MenuItem>
                    <MenuItem value={"45"}>45</MenuItem>
                    <MenuItem value={"60"}>60</MenuItem>
                    <MenuItem value={"90"}>90</MenuItem>
                    <MenuItem value={"91"}>&gt; 90 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2} mt={2}>
              <Grid item>
                <div>
                  D.{" "}
                  <span style={{ color: "blue" }}> Last Seven Days Trend</span>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.comparisonLastThreeMonthsToSevenDays
                        ? "red"
                        : "inherit",
                    }}
                  >
                    Compared to how physically active you have been over the
                    last 3 months, how would you describe the last 7 days ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={comparisonLastThreeMonthsToSevenDays}
                    onChange={handlecomparisonLastThreeMonthsToSevenDaysChange}
                  >
                    <FormControlLabel
                      value="More active"
                      control={<Radio />}
                      label="More active"
                    />
                    <FormControlLabel
                      value="About the same"
                      control={<Radio />}
                      label="About the same"
                    />
                    <FormControlLabel
                      value="Less Active"
                      control={<Radio />}
                      label="Less Active"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl component="fieldset">
                  <FormLabel
                    style={{
                      color: fieldErrors2.selectedActivity ? "red" : "inherit",
                    }}
                  >
                    What forms of activity do you undertake regularly?
                  </FormLabel>
                  <FormGroup>
                    {[
                      "Walking",
                      "Running",
                      "Yoga",
                      "Aerobic",
                      "Resistance / Weight training",
                      "None",
                    ].map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={selectedActivity.includes(option)}
                            onChange={() =>
                              handleselectedActivityCheckboxChange(option)
                            }
                          />
                        }
                        label={option.charAt(0).toUpperCase() + option.slice(1)} // Capitalize the first letter
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.hoursOfExercise ? "red" : "inherit",
                    }}
                  >
                    How many hours did you exercise in the last 7 days ?
                  </FormLabel>
                  <Select
                    value={hoursOfExercise}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlehoursOfExerciseChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"1-2"}>1-2</MenuItem>
                    <MenuItem value={"2-3"}>2-3</MenuItem>
                    <MenuItem value={"3-4"}>3-4</MenuItem>
                    <MenuItem value={"4-5"}>4-5</MenuItem>
                    <MenuItem value={"6"}>more than 5 hours</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.lastSevenDays30minutesOfExercise
                        ? "red"
                        : "inherit",
                    }}
                  >
                    On how many of the last SEVEN DAYS did you participate in at
                    least 30 minutes of physical activity? (Total minutes of
                    continuous activity, including walking).
                  </FormLabel>
                  <Select
                    value={lastSevenDays30minutesOfExercise}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlelastSevenDays30minutesOfExerciseChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2} mt={2}>
              <Grid item>
                <div>
                  E.
                  <span style={{ color: "blue" }}>Sedentary Exercise</span>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors2.totalHoursOfSitting
                        ? "red"
                        : "inherit",
                    }}
                  >
                    How many total hours do you sit (sitting time) in a day?
                    (include time sitting while working, watching movies or
                    video, talking on mobile, sitting while commuting for work,
                    etc.) ?
                  </FormLabel>
                  <Select
                    value={totalHoursOfSitting}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handletotalHoursOfSitting}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"4"}>&lt; 4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"6"}>6</MenuItem>
                    <MenuItem value={"7"}>7</MenuItem>
                    <MenuItem value={"8"}>8</MenuItem>
                    <MenuItem value={"9"}>9</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"11"}>11</MenuItem>
                    <MenuItem value={"12"}>12</MenuItem>
                    <MenuItem value={"13"}>&gt; 12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleLifestyleStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  style={{ minWidth: "200px" }}
                  type="button"
                  className="base"
                  onClick={handleSave2}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {/* {(relationship == "wife" || relarionship == "mother" || relationship == "father") &&  */}
        {LifestyleStep == 2 && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>
                  <h4>3. Other Habits</h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.usedTobaccoProducts
                        ? "red"
                        : "inherit",
                    }}
                  >
                    Have you ever used tobacco products ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={usedTobaccoProducts}
                    onChange={handleusedTobaccoProductsChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.drinkAlcohol ? "red" : "inherit",
                    }}
                  >
                    Do you drink alcohol? (include beer & wine) ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={drinkAlcohol}
                    onChange={handledrinkAlcoholChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {drinkAlcohol == true && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      How often do you consume Alcohol ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={howOftenConsumeAlcohol}
                      onChange={handlehowOftenConsumeAlcoholChange}
                    >
                      <FormControlLabel
                        value="Only On Social Occasions"
                        control={<Radio />}
                        label="Only On Social Occasions"
                      />
                      <FormControlLabel
                        value="About once a week"
                        control={<Radio />}
                        label="About once a week"
                      />
                      <FormControlLabel
                        value="More than once a week"
                        control={<Radio />}
                        label="More than once a week"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              {drinkAlcohol == true && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Which Drink do you prefer ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={drinkDoYouPrefer}
                      onChange={handledrinkDoYouPreferChange}
                    >
                      <FormControlLabel
                        value="Beer"
                        control={<Radio />}
                        label="Beer"
                      />
                      <FormControlLabel
                        value="Wine"
                        control={<Radio />}
                        label="Wine"
                      />
                      <FormControlLabel
                        value="Hard drink (Gin , brandi , Whisky etc.)"
                        control={<Radio />}
                        label="Hard drink (Gin , brandi , Whisky etc.)"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              {drinkAlcohol == true &&
                howOftenConsumeAlcohol !== "Only On Social Occasions" && (
                  <Grid item xs={12} md={3}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        How many drinks per day ?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="radio-buttons-group"
                        value={drinksPerDay}
                        onChange={handledrinksPerDayChange}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="1"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label="2"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio />}
                          label="3"
                        />
                        <FormControlLabel
                          value={4}
                          control={<Radio />}
                          label="4"
                        />
                        <FormControlLabel
                          value={5}
                          control={<Radio />}
                          label="> 4"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}

              {profile.gender == "female" &&
                familyHistory?.familyHistory &&
                drinksPerDay > 1 && (
                  <Grid item xs={12} md={3}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Are you taking Folate ?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="radio-buttons-group"
                        value={areYouTakingFolate}
                        onChange={handleareYouTakingFolateChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}

              {drinksPerDay > 1 && areYouTakingFolate && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      How Much
                    </FormLabel>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        value={howMuchFolate}
                        type="number"
                        onChange={handlehowMuchFolateChange}
                        style={{ width: "100px", marginRight: "10px" }}
                      ></input>
                      <span>microgram</span>
                    </div>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.usedRecreationalDrugs
                        ? "red"
                        : "inherit",
                    }}
                  >
                    Do you use recreational drugs ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={usedRecreationalDrugs}
                    onChange={handleusedRecreationalDrugsChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {usedRecreationalDrugs == true && (
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <FormLabel>What type of drug?</FormLabel>
                    <Select
                      value={drugType}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handledrugTypeChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"Legal drugs like Statin, etc."}>
                        Legal drugs like Statin, etc.
                      </MenuItem>
                      <MenuItem value={"Illegal drugs (Cocaine, heroin, etc.)"}>
                        Illegal drugs (Cocaine, heroin, Ecstasy, Marijuana,
                        etc.)
                      </MenuItem>
                      <MenuItem
                        value={"Other (Opioids for pain relief, Amphetamines)"}
                      >
                        Other (Opioids for pain relief, Amphetamines)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.smoked ? "red" : "inherit",
                    }}
                  >
                    Do you smoke (even if 1 puff)?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={smoked}
                    onChange={handlesmokedChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {smoked === true && (
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Which of the following applies ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={smokingPerDay}
                      onChange={handlesmokingPerDayChange}
                    >
                      <FormControlLabel
                        value="Light Smoker (less than 10 cigarettes/day)"
                        control={<Radio />}
                        label="Light Smoker (less than 10 cigarettes/day)"
                      />
                      <FormControlLabel
                        value="Moderate Smoker (10 to 19 cigarettes/day )"
                        control={<Radio />}
                        label="Moderate Smoker (10 to 19 cigarettes/day )"
                      />
                      <FormControlLabel
                        value="Heavy Smoker (20 or over cigarettes/day )"
                        control={<Radio />}
                        label="Heavy Smoker (20 or over cigarettes/day )"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              {smoked === false && (
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Are you a ex-smoker ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={areYouExSmoker}
                      onChange={handleareYouExSmokerChange}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleLifestyleStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  style={{ minWidth: "200px" }}
                  type="button"
                  className="base"
                  onClick={handleSave3}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {/* } */}

        {LifestyleStep == 3 && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>
                  <h4>4. Sleep</h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors4.hoursOfSleep ? "red" : "inherit",
                    }}
                  >
                    How many hours of sleep do you typically get in a day ?
                  </FormLabel>
                  <Select
                    value={hoursOfSleep}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlehoursOfSleepChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"3"}>&lt; 4 hours</MenuItem>
                    <MenuItem value={"4"}>4 hours</MenuItem>
                    <MenuItem value={"5"}>5 hours</MenuItem>
                    <MenuItem value={"6"}>6 hours</MenuItem>
                    <MenuItem value={"7"}>7 hours</MenuItem>
                    <MenuItem value={"8"}>8 hours</MenuItem>
                    <MenuItem value={"9"}>9 hours</MenuItem>
                    <MenuItem value={"10"}>&gt; 9 hours</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.sleepAroundSameTime
                        ? "red"
                        : "inherit",
                    }}
                  >
                    I usually go to sleep around the same time everyday.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={sleepAroundSameTime}
                    onChange={handlesleepAroundSameTimeChange}
                  >
                    <FormControlLabel
                      value="Always"
                      control={<Radio />}
                      label="Always"
                    />
                    <FormControlLabel
                      value="Often"
                      control={<Radio />}
                      label="Often"
                    />
                    <FormControlLabel
                      value="Sometimes"
                      control={<Radio />}
                      label="Sometimes"
                    />
                    <FormControlLabel
                      value="Rarely"
                      control={<Radio />}
                      label="Rarely"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.satisfiedWithSleep
                        ? "red"
                        : "inherit",
                    }}
                  >
                    I am satisfied with my sleep.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={satisfiedWithSleep}
                    onChange={handlesatisfiedWithSleepChange}
                  >
                    <FormControlLabel
                      value="Always"
                      control={<Radio />}
                      label="Always"
                    />
                    <FormControlLabel
                      value="Often"
                      control={<Radio />}
                      label="Often"
                    />
                    <FormControlLabel
                      value="Sometimes"
                      control={<Radio />}
                      label="Sometimes"
                    />
                    <FormControlLabel
                      value="Rarely"
                      control={<Radio />}
                      label="Rarely"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.difficultyWithSleep
                        ? "red"
                        : "inherit",
                    }}
                  >
                    I have difficulty falling asleep.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={difficultyWithSleep}
                    onChange={handledifficultyWithSleepChange}
                  >
                    <FormControlLabel
                      value="Always"
                      control={<Radio />}
                      label="Always"
                    />
                    <FormControlLabel
                      value="Often"
                      control={<Radio />}
                      label="Often"
                    />
                    <FormControlLabel
                      value="Sometimes"
                      control={<Radio />}
                      label="Sometimes"
                    />
                    <FormControlLabel
                      value="Rarely"
                      control={<Radio />}
                      label="Rarely"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.feelRefreshedAfterSleep
                        ? "red"
                        : "inherit",
                    }}
                  >
                    I feel refreshed after sleep.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={feelRefreshedAfterSleep}
                    onChange={handlefeelRefreshedAfterSleepChange}
                  >
                    <FormControlLabel
                      value="Always"
                      control={<Radio />}
                      label="Always"
                    />
                    <FormControlLabel
                      value="Often"
                      control={<Radio />}
                      label="Often"
                    />
                    <FormControlLabel
                      value="Sometimes"
                      control={<Radio />}
                      label="Sometimes"
                    />
                    <FormControlLabel
                      value="Rarely"
                      control={<Radio />}
                      label="Rarely"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleLifestyleStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  style={{ minWidth: "200px" }}
                  type="button"
                  className="base"
                  onClick={handleSave4}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {LifestyleStep == 4 && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>
                  <h4>5. Self Monitoring</h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors5.preventiveScreeningTestDone
                        ? "red"
                        : "inherit",
                    }}
                  >
                    {" "}
                    When was the last time you got preventive screening tests
                    done ?
                  </FormLabel>
                  <Select
                    value={preventiveScreeningTestDone}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlepreventiveScreeningTestDoneChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"One Week Ago"}>One Week Ago</MenuItem>
                    <MenuItem value={"One Month Ago"}>One Month Ago</MenuItem>
                    <MenuItem value={"Two Months Ago"}>Two Months Ago</MenuItem>
                    <MenuItem value={"Six Months Ago"}>Six Months Ago</MenuItem>
                    <MenuItem value={"One Year Ago "}>One Year Ago </MenuItem>
                    <MenuItem value={"More than One Year Ago"}>
                      More than One Year Ago
                    </MenuItem>
                    <MenuItem value={"Not Done"}>Not Done</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors5.glucometerDevice ? "red" : "inherit",
                    }}
                  >
                    {" "}
                    Do you have a Glucometer and use the same to regularly
                    monitor blood glucose at home / place of work ?
                  </FormLabel>
                  <Select
                    value={glucometerDevice}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleglucometerDeviceChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"No, do not have Glucometer"}>
                      No, do not have Glucometer
                    </MenuItem>
                    <MenuItem value={"Have Glucometer, but rarely to use it"}>
                      Have Glucometer, but rarely to use it
                    </MenuItem>
                    <MenuItem
                      value={
                        "Have Glucometer, but use it sometimes , when needed"
                      }
                    >
                      Have Glucometer, but use it sometimes , when needed
                    </MenuItem>
                    <MenuItem
                      value={
                        "Have Glucometer, and use it regularly i.e. atleast few times a week"
                      }
                    >
                      Have Glucometer, and use it regularly i.e. atleast few
                      times a week
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors5.bpDevice ? "red" : "inherit",
                    }}
                  >
                    {" "}
                    Do you have a Blood Pressure monitoring device and use the
                    same to regularly monitor BP at home / place of work ?
                  </FormLabel>
                  <Select
                    value={bpDevice}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlebpDeviceChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"No, do not have BP Monitoring Device"}>
                      No, do not have BP Monitoring Device
                    </MenuItem>
                    <MenuItem value={"Have Device, but rarely to use it"}>
                      Have Device, but rarely to use it
                    </MenuItem>
                    <MenuItem
                      value={"Have Device, but use it sometimes , when needed"}
                    >
                      Have Device, but use it sometimes , when needed
                    </MenuItem>
                    <MenuItem
                      value={
                        "Have Device, and use it regularly i.e. atleast few times a week"
                      }
                    >
                      Have Device, and use it regularly i.e. atleast few times a
                      week
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors5.bloodSugarCheckedLast
                        ? "red"
                        : "inherit",
                    }}
                  >
                    When was your blood sugar checked last?
                  </FormLabel>
                  <Select
                    value={bloodSugarCheckedLast}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlebloodSugarCheckedLastChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"One Week Ago"}>One Week Ago</MenuItem>
                    <MenuItem value={"One Month Ago"}>One Month Ago</MenuItem>
                    <MenuItem value={"Two Months Ago"}>Two Months Ago</MenuItem>
                    <MenuItem value={"Six Months Ago"}>Six Months Ago</MenuItem>
                    <MenuItem value={"One Year Ago "}>One Year Ago </MenuItem>
                    <MenuItem value={"More than One Year Ago"}>
                      More than One Year Ago
                    </MenuItem>
                    <MenuItem value={"Not Done"}>Not Done</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors5.bloodPressureCheckedLast
                        ? "red"
                        : "inherit",
                    }}
                  >
                    When was your blood pressure checked last ?
                  </FormLabel>
                  <Select
                    value={bloodPressureCheckedLast}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handlebloodPressureCheckedLastChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"One Week Ago"}>One Week Ago</MenuItem>
                    <MenuItem value={"One Month Ago"}>One Month Ago</MenuItem>
                    <MenuItem value={"Two Months Ago"}>Two Months Ago</MenuItem>
                    <MenuItem value={"Six Months Ago"}>Six Months Ago</MenuItem>
                    <MenuItem value={"One Year Ago "}>One Year Ago </MenuItem>
                    <MenuItem value={"More than One Year Ago"}>
                      More than One Year Ago
                    </MenuItem>
                    <MenuItem value={"Not Done"}>Not Done</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleLifestyleStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  style={{ minWidth: "200px" }}
                  type="button"
                  className="base"
                  onClick={handleSave5}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {LifestyleStep == 5 && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div>
                  <h4>6. Other Questions</h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={12}>
                <label>
                  Do you have any concern regarding your present illness and
                  treatment ?
                </label>
                {/* <TextField></TextField> */}
                <input
                  value={concernRegardingIllnessTreatment}
                  onChange={handleconcernRegardingIllnessTreatmentChange}
                ></input>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleLifestyleStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button className="base" onClick={handleSave6}>
                  Submit
                </button>
              </div>
            </Grid>
            {/* )} */}
          </>
        )}
      </form>
    </div>
  );
};

export default LifeStyleSketch;
