import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import NoOrderImage from "./images/no-order.jpeg";
import { useNavigate } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import { Col, Row } from "reactstrap";

const PhysiotherapistOrders = () => {
  const [rowData, setRowData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showDeclineForm, setShowDeclineForm] = useState(false);
  const [nextConsultDate, setNextConsultDate] = useState("");
  const [nextConsultTime, setNextConsultTime] = useState("");
  const [error, setError] = useState("");

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleAccept = () => {
    navigate("/dietician-online-consultation", {
      state: { order: selectedOrder },
    });
  };

  useEffect(() => {
    // getLabOrderDetail();
    // const today = new Date().toISOString().substr(0, 10);
    // setSelectedDate(today);
    // setIsLoading(true);
    // const userDetails = sessionStorage.getItem("userDetails");
    // const parsedData = JSON.parse(userDetails);
    // const userType = parsedData.userType;
    // console.log("userType", userType);
    // AxiosInstance.get(`dietitian/orderDetails/${userType}`)
    //   .then((response) => {
    //     if (response != null) {
    //       setIsLoading(false);
    //       setOrders(response);
    //     } else {
    //       setIsLoading(false);
    //       setOrders([]);
    //     }
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     console.log("err: ", err);
    //   });
  }, []);

  const handleOpen = (order) => {
    setOpen(true);
    setSelectedOrder(order);
  };
  const handleClose = () => {
    setOpen(false);
    setShowDeclineForm(false);
    setNextConsultDate("");
    setNextConsultTime("");
    setError("");
  };

  const handleDecline = () => {
    setShowDeclineForm(true);
    setError(""); // Clear error on decline
  };

  const handleDateChange = (e) => {
    setNextConsultDate(e.target.value);
    if (e.target.value && nextConsultTime) {
      setError(""); // Clear error if both fields are filled
    }
  };

  const handleTimeChange = (e) => {
    setNextConsultTime(e.target.value);
    if (nextConsultDate && e.target.value) {
      setError(""); // Clear error if both fields are filled
    }
  };

  const handleSubmit = () => {
    if (!nextConsultDate || !nextConsultTime) {
      setError("Please fill in both the date and time.");
    } else {
      setError("");
      console.log(
        "Next Consultation Scheduled:",
        nextConsultDate,
        nextConsultTime
      );
      // Submit the next consultation data here
      handleClose();
    }
  };
  // const handleDateChange = (event) => {
  //   setSelectedDate(event.target.value);
  // };
  const filteredOrders = rowData.filter((order) => order.date === selectedDate);

  console.log("selected ", selectedOrder);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        {/* <div
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          width: "25%",
        }}
      >
        <b>Date :</b>
        <span style={{ marginLeft: "10px" }}>
          <input
            type="date"
            className="form-control"
            id="dateInput"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </span>
      </div> */}
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you your orders from Uncurl: Health</b>
      </p>
      {isLoading ? (
        <Spinner />
      ) : orders?.length > 0 ? (
        <>
          <TableContainer
            style={{ marginTop: "2%", border: "1px solid black" }}
          >
            <Table>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Date</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Time</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>ID</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Service Type</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Status</b>
                  </p>
                </TableCell>
              </TableRow>
              <TableBody>
                {orders?.map((orders, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.createdOn ? formatDate(orders.createdOn) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.time ? formatTime(orders.time) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.orderType}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {orders.statusForPartner === "Dietitian Consult" ||
                      orders.statusForPartner === "Questions" ? (
                        <Button
                          variant="contained"
                          style={{
                            boxShadow: "0px 0px 10px 0px red",
                          }}
                          className="doctor-review-button"
                          onMouseEnter={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 20px 5px red";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 10px 0px red";
                          }}
                          onClick={() => handleOpen(orders)}
                        >
                          {orders.statusForPartner}
                        </Button>
                      ) : (
                        <>
                          {orders.statusForPartner === "Order Closed" ? (
                            <div>
                              {orders.statusForPartner} (
                              {formatDate(orders.closedDate)}/
                              {formatTime(orders.closedTime)})
                            </div>
                          ) : (
                            orders.statusForPartner
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                gutterBottom
              >
                Consultation Details
              </Typography>

              <Box sx={{ mt: 4, mb: 2 }}>
                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Appointment Date:</strong>{" "}
                      {new Date().toLocaleDateString()}
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Appointment Time:</strong>{" "}
                      {new Date().toLocaleTimeString()}
                    </Typography>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Patient Name:</strong> John Doe
                    </Typography>
                  </Col>
                  <Col lg={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", mb: 1 }}
                    >
                      <strong>Type of Call:</strong> Audio
                    </Typography>
                  </Col>
                </Row>
              </Box>

              <Box
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAccept}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDecline}
                >
                  Decline
                </Button>
              </Box>

              {/* Next Consultation Form */}
              {/* {nextConsultDate === "" && (
                <Box mt={3}>
                  <TextField
                    label="Next Consultation Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={nextConsultDate}
                    onChange={(e) => setNextConsultDate(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="Next Consultation Time"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={nextConsultTime}
                    onChange={(e) => setNextConsultTime(e.target.value)}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Box>
              )} */}
              {showDeclineForm && (
                <Box mt={3}>
                  <Typography variant="subtitle1" mb={1}>
                    Schedule Next Consultation
                  </Typography>
                  <Row>
                    <Col lg={6}>
                      <TextField
                        label="Next Consultation Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={nextConsultDate}
                        onChange={handleDateChange}
                        margin="normal"
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Next Consultation Time"
                        type="time"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={nextConsultTime}
                        onChange={handleTimeChange}
                        margin="normal"
                      />
                    </Col>
                  </Row>

                  {error && (
                    <Typography color="error" mt={1}>
                      {error}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                No Orders Available!
              </h4>
              <img src={NoOrderImage} alt="Lab" style={{ width: "25%" }} />
            </span>
          </div>
        </>
      )}
    </>
  );
};
export default PhysiotherapistOrders;
