export const VaccinationsData = {
  BCG: {
    Birth: { label: "", color: "#FFFF9B" },
    "6w": { label: "", color: "#C8E7A3" },
    "10w": { label: "", color: "#C8E7A3" },
    "14w": { label: "", color: "#C8E7A3" },
    "6m": { label: "", color: "#C8E7A3" },
    "7m": { label: "", color: "#C8E7A3" },
    "9m": { label: "", color: "#C8E7A3" },
    "12m": { label: "", color: "#C8E7A3" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "", color: "#C8E7A3" },
    "16-18m": { label: "", color: "#C8E7A3" },
    "18-24m": { label: "", color: "#C8E7A3" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#ffFFff" },
    "15-18y": { label: "", color: "#ffFFff" },
    label: "BCG",
  },
  HepatitisB: {
    Birth: { label: "HB 1", color: "#FFFF9B" },
    "6w": { label: "HB 2", color: "#FFFF9B" },
    "10w": { label: "HB 3", color: "#FFFF9B" },
    "14w": { label: "HB 4", color: "#FFFF9B" },
    "6m": { label: "", color: "#C8E7A3" },
    "7m": { label: "", color: "#C8E7A3" },
    "9m": { label: "", color: "#C8E7A3" },
    "12m": { label: "", color: "#C8E7A3" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "", color: "#C8E7A3" },
    "16-18m": { label: "", color: "#C8E7A3" },
    "18-24m": { label: "", color: "#C8E7A3" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "Hepatitis-B",
  },
  Polio: {
    Birth: { label: "OPV", color: "#FFFF9B" },
    "6w": { label: "IPV 1", color: "#FFFF9B" },
    "10w": { label: "IPV 2", color: "#FFFF9B" },
    "14w": { label: "IPV 3", color: "#FFFF9B" },
    "6m": { label: "", color: "#C8E7A3" },
    "7m": { label: "", color: "#C8E7A3" },
    "9m": { label: "", color: "#C8E7A3" },
    "12m": { label: "", color: "#C8E7A3" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "", color: "#C8E7A3" },
    "16-18m": { label: "IPV B1", color: "#FFFF9B" },
    "18-24m": { label: "", color: "#C8E7A3" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "Polio",
  },
  dtwp: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "DPT 1", color: "#FFFF9B" },
    "10w": { label: "DPT 2", color: "#FFFF9B" },
    "14w": { label: "DPT 3", color: "#FFFF9B" },
    "6m": { label: "", color: "#C8E7A3" },
    "7m": { label: "", color: "#C8E7A3" },
    "9m": { label: "", color: "#C8E7A3" },
    "12m": { label: "", color: "#C8E7A3" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "", color: "#C8E7A3" },
    "16-18m": { label: "DPT B1", color: "#FFFF9B" },
    "18-24m": { label: "", color: "#C8E7A3" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "DTwP/DTaP",
  },
  hib: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "Hib 1", color: "#FFFF9B" },
    "10w": { label: "Hib 2", color: "#FFFF9B" },
    "14w": { label: "Hib 3", color: "#FFFF9B" },
    "6m": { label: "", color: "#C8E7A3" },
    "7m": { label: "", color: "#C8E7A3" },
    "9m": { label: "", color: "#C8E7A3" },
    "12m": { label: "", color: "#C8E7A3" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "", color: "#C8E7A3" },
    "16-18m": { label: "Hib B1", color: "#FFFF9B" },
    "18-24m": { label: "", color: "#C8E7A3" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "Hib",
  },
  pcv: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "PCV 1", color: "#FFFF9B" },
    "10w": { label: "PCV 2", color: "#FFFF9B" },
    "14w": { label: "PCV 3", color: "#FFFF9B" },
    "6m": { label: "", color: "#C8E7A3" },
    "7m": { label: "", color: "#C8E7A3" },
    "9m": { label: "", color: "#C8E7A3" },
    "12m": { label: "PCV B", color: "#FFFF9B", colSpan: 4 },
    "13m": { label: "", color: "#FFFF9B" },
    "15m": { label: "", color: "#FFFF9B" },
    "16-18m": { label: "", color: "#FFFF9B"},
    "18-24m": { label: "", color: "#C8E7A3" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "PCV",
  },
  rotavirus: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "RV 1", color: "#FFFF9B" },
    "10w": { label: "RV 2", color: "#FFFF9B" },
    "14w": { label: "RV 3", color: "#FFFF9B" },
    "6m": { label: "", color: "#C8E7A3" },
    "7m": { label: "", color: "#C8E7A3" },
    "9m": { label: "", color: "#C8E7A3" },
    "12m": { label: "", color: "#ffffff" },
    "13m": { label: "", color: "#ffffff" },
    "15m": { label: "", color: "#ffffff" },
    "16-18m": { label: "", color: "#ffffff" },
    "18-24m": { label: "", color: "#ffffff" },
    "2-4y": { label: "", color: "#ffffff" },
    "4-6y": { label: "", color: "#ffffff" },
    "9-14y": { label: "", color: "#ffffff" },
    "15-18y": { label: "", color: "#ffffff" },
    label: "Rotavirus",
  },
  influenza: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "Dose 1", color: "#FFFF9B" },
    "7m": { label: "Dose 1", color: "#FFFF9B" },
    "9m": { label: "Annual Vaccination", color: "#FFFF9B", colSpan: 8 },
    "12m": { label: "", color: "#ffffff" },
    "13m": { label: "", color: "#ffffff" },
    "15m": { label: "", color: "#ffffff" },
    "16-18m": { label: "", color: "#ffffff" },
    "18-24m": { label: "", color: "#ffffff" },
    "2-4y": { label: "", color: "#ffffff" },
    "4-6y": { label: "", color: "#ffffff" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "Influenza",
  },
  mmr: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "Dose 1", color: "#FFFF9B" },
    "12m": { label: "", color: "#C8E7A3" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "Dose 2", color: "#FFFF9B" },
    "16-18m": { label: "", color: "#ffffff" },
    "18-24m": { label: "", color: "#ffffff" },
    "2-4y": { label: "", color: "#ffffff" },
    "4-6y": { label: "Dose 3", color: "#FFFF9B" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "MMR",
  },
  tcv: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#FFFF9B" },
    "7m": { label: "", color: "#FFFF9B" },
    "9m": { label: "", color: "#FFFF9B" },
    "12m": { label: "", color: "#C8E7A3" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "", color: "#C8E7A3" },
    "16-18m": { label: "", color: "#C8E7A3" },
    "18-24m": { label: "", color: "#C8E7A3" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "TCV",
  },
  HepatitisA: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "Dose 1", color: "#FFFF9B" },
    "13m": { label: "", color: "#C8E7A3" },
    "15m": { label: "", color: "#C8E7A3" },
    "16-18m": { label: "", color: "#C8E7A3" },
    "18-24m": { label: "Dose 2", color: "#FFFF9B" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "Hepatitis-A",
  },
  vericella: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "", color: "#ffffff" },
    "13m": { label: "", color: "#ffffff" },
    "15m": { label: "Dose 1", color: "#FFFF9B" },
    "16-18m": { label: "", color: "#C8E7A3" },
    "18-24m": { label: "Dose 2", color: "#FFFF9B" },
    "2-4y": { label: "", color: "#C8E7A3" },
    "4-6y": { label: "", color: "#C8E7A3" },
    "9-14y": { label: "", color: "#C8E7A3" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "Vericella",
  },
  tdap: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "", color: "#ffffff" },
    "13m": { label: "", color: "#ffffff" },
    "15m": { label: "", color: "#ffffff" },
    "16-18m": { label: "", color: "#ffffff" },
    "18-24m": { label: "", color: "#ffffff" },
    "2-4y": { label: "", color: "#ffffff" },
    "4-6y": { label: "", color: "#ffffff" },
    "9-14y": { label: "", color: "#FFFF9B" },
    "15-18y": { label: "", color: "#C8E7A3" },
    label: "TDaP",
  },
  td: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "", color: "#ffffff" },
    "13m": { label: "", color: "#ffffff" },
    "15m": { label: "", color: "#ffffff" },
    "16-18m": { label: "", color: "#ffffff" },
    "18-24m": { label: "", color: "#ffffff" },
    "2-4y": { label: "", color: "#ffffff" },
    "4-6y": { label: "", color: "#ffffff" },
    "9-14y": { label: "", color: "#ffffff" },
    "15-18y": { label: "", color: "#FFFF9B" },
    label: "Td",
  },
  hpv: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "", color: "#ffffff" },
    "13m": { label: "", color: "#ffffff" },
    "15m": { label: "", color: "#ffffff" },
    "16-18m": { label: "", color: "#ffffff" },
    "18-24m": { label: "", color: "#ffffff" },
    "2-4y": { label: "", color: "#ffffff" },
    "4-6y": { label: "", color: "#ffffff" },
    "9-14y": { label: "", color: "#FFFF9B" },
    "15-18y": { label: "", color: "#FFFF9B" },
    label: "HPV",
  },
  meninggococcal: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "Dose 1", color: "#9BC3E6" },
    "12m": { label: "Dose 2", color: "#9BC3E6" },
    "13m": { label: "", color: "#9BC3E6" },
    "15m": { label: "", color: "#9BC3E6" },
    "16-18m": { label: "", color: "#9BC3E6" },
    "18-24m": { label: "", color: "#9BC3E6" },
    "2-4y": { label: "", color: "#9BC3E6" },
    "4-6y": { label: "", color: "#9BC3E6" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "Meninggococcal",
  },
  je: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "Dose 1", color: "#9BC3E6" },
    "13m": { label: "Dose 2", color: "#9BC3E6" },
    "15m": { label: "", color: "#9BC3E6" },
    "16-18m": { label: "", color: "#9BC3E6" },
    "18-24m": { label: "", color: "#9BC3E6" },
    "2-4y": { label: "", color: "#9BC3E6" },
    "4-6y": { label: "", color: "#9BC3E6" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "JE",
  },
  cholera: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "Dose 1", color: "#9BC3E6" },
    "13m": { label: "Dose 2", color: "#9BC3E6" },
    "15m": { label: "", color: "#9BC3E6" },
    "16-18m": { label: "", color: "#9BC3E6" },
    "18-24m": { label: "", color: "#9BC3E6" },
    "2-4y": { label: "", color: "#9BC3E6" },
    "4-6y": { label: "", color: "#9BC3E6" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "Cholera",
  },
  ppsv23: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#ffffff" },
    "12m": { label: "", color: "#ffffff" },
    "13m": { label: "", color: "#ffffff" },
    "15m": { label: "", color: "#ffffff" },
    "16-18m": { label: "", color: "#ffffff" },
    "18-24m": { label: "", color: "#ffffff" },
    "2-4y": { label: "", color: "#9BC3E6" },
    "4-6y": { label: "", color: "#9BC3E6" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "PPSV 23",
  },
  rabies: {
    Birth: { label: "", color: "#9BC3E6" },
    "6w": { label: "", color: "#9BC3E6" },
    "10w": { label: "", color: "#9BC3E6" },
    "14w": { label: "", color: "#9BC3E6" },
    "6m": { label: "", color: "#9BC3E6" },
    "7m": { label: "", color: "#9BC3E6" },
    "9m": { label: "", color: "#9BC3E6" },
    "12m": { label: "", color: "#9BC3E6" },
    "13m": { label: "", color: "#9BC3E6" },
    "15m": { label: "", color: "#9BC3E6" },
    "16-18m": { label: "", color: "#9BC3E6" },
    "18-24m": { label: "", color: "#9BC3E6" },
    "2-4y": { label: "", color: "#9BC3E6" },
    "4-6y": { label: "", color: "#9BC3E6" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "Rabies",
  },
  yellowfever: {
    Birth: { label: "", color: "#ffffff" },
    "6w": { label: "", color: "#ffffff" },
    "10w": { label: "", color: "#ffffff" },
    "14w": { label: "", color: "#ffffff" },
    "6m": { label: "", color: "#ffffff" },
    "7m": { label: "", color: "#ffffff" },
    "9m": { label: "", color: "#9BC3E6" },
    "12m": { label: "", color: "#9BC3E6" },
    "13m": { label: "", color: "#9BC3E6" },
    "15m": { label: "", color: "#9BC3E6" },
    "16-18m": { label: "", color: "#9BC3E6" },
    "18-24m": { label: "", color: "#9BC3E6" },
    "2-4y": { label: "", color: "#9BC3E6" },
    "4-6y": { label: "", color: "#9BC3E6" },
    "9-14y": { label: "", color: "#9BC3E6" },
    "15-18y": { label: "", color: "#9BC3E6" },
    label: "Yellow Fever",
  },
};

export const VaccinationDescribe = [
  {
    id: 1,
    label: "To be given within 24 h after birth. When this is missed, it can be administered at first contact with health facility. All stable preterm and LBW babies should be administered a birth dose and 3 more doses with pentavalent/hexavalent combination vaccines."
  },
  {
    id: 2,
    label: "An extra dose of Hepatitis B vaccine is permitted as part of a combination vaccine when use of this combination vaccine is necessary."
  },
  {
    id: 3,
    label: "IPV can be given as part of a combination vaccine."
  },
  {
    id: 4,
    label: " 3rd dose of Rota vaccine is not necessary for RV1 (GSK)."
  },
  {
    id: 5,
    label: "Influenza vaccine should be started after 6 months, 2 doses 4 weeks apart, usually in the pre-monsoon period. At other times of the year, the most recent available strain should be used. Annual influenza vaccination should be continued, for all, till 5 y of age. For those at high risk of Influenza related complications, annual vaccination should be continued till 18 years and beyond."
  },
  {
    id: 6,
    label: "Single dose is to be given for the live attenuated Hepatitis A vaccine. The inactivated vaccine needs two doses."
  },
  {
    id: 7,
    label: "2nd dose of Varicella vaccine should be given 3-6 mo after dose 1. In catch up schedule, in those >12 years of age, the 2nd dose is to be given after 4 weeks"
  },
  {
    id: 8,
    label: "Tdap should not be administered as the second booster of DPT at 4-6 y. For delayed 2nd booster, Tdap can be given after 7 y of age. A dose of Tdap is necessary at 10-12 y. If a dose of Tdap was administered at more than or equal to 9 y of age, the adolescent Tdap is not necessary. If Tdap is unavailable/unaffordable, it can be substituted with Td"
  },
  {
    id: 9,
    label: "From 9-14 years, HPV vaccines are recommended as a 2-dose schedule, 6 months apart."
  },
  {
    id: 10,
    label: "9vHPV-Gardasil-9 is approved for boys between 9-14 years of age and females between 9-26 years of age. HPV4-SII is recommended for females and males between 9-26 years of age. Gardasil 4 is licensed till 45 years of age only for females"
  },
  {
    id: 11,
    label: "From 15 y onwards and in immunocompromised subjects at all ages, HPV vaccines are recommended as a 3-dose schedule, 0-2-6 months."
  },
  {
    id: 12,
    label: "Menactra is approved in a 2-dose schedule between 9-23 mo. Minimum interval between two doses should be 3 mo. Menveo is recommended as a single dose schedule after 2 y of age. For those with ongoing exposure to meningococci, boosters are recommended every 5 years."
  },
  {
    id: 13,
    label: "In endemic areas."
  },
];