import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Col, Row } from "reactstrap";
const HistoryOfTest = () => {
  const testsResuts = [
    {
      testName: "Creatinine",
      testValue: "1.1",
      interpretation: "Normal",
      desirableValue: "",
      whatThisMayMean: "Kdiney functioning normally",
    },
    {
      testName: "Billirubin",
      testValue: <p style={{ color: "red" }}>234</p>,
      interpretation: "low",
      desirableValue: "> 292",
      whatThisMayMean: "",
    },
    {
      testName: "FBS",
      testValue: <p style={{ color: "red" }}>120</p>,
      interpretation: "High",
      desirableValue: "< 100",
      whatThisMayMean: "",
    },
    {
      testName: "HbAIC",
      testValue: "90",
      interpretation: "",
      desirableValue: "",
      whatThisMayMean: "",
    },
  ];
  return (
    <div>
      <div></div>
      <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
        <Table>
          <TableRow>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Test Name</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Test Value</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Interpretation</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Desirable Value</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>What This May Mean ?</b>
              </p>
            </TableCell>
          </TableRow>
          <TableBody>
            {testsResuts &&
              testsResuts.map((test, index) => (
                <TableRow
                  key={index}
                  //   onClick={() => handleRowClick(test, index)}
                  // style={{
                  //   cursor: "pointer",
                  //   backgroundColor:
                  //     clickedRowIndex === index ? "red" : "whitesmoke",
                  //   transition: "background-color 0.3s ease",
                  // }}
                  // onMouseEnter={(e) => {
                  //   if (clickedRowIndex !== index)
                  //     e.currentTarget.style.backgroundColor = "red";
                  // }}
                  // onMouseLeave={(e) => {
                  //   if (clickedRowIndex !== index)
                  //     e.currentTarget.style.backgroundColor = "whitesmoke";
                  // }}
                >
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "20px",
                    }}
                  >
                    {test.testName}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "20px",
                    }}
                  >
                    {test.testValue}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "20px",
                    }}
                  >
                    {test.interpretation}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "20px",
                    }}
                  >
                    {test.desirableValue}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "20px",
                    }}
                  >
                    {test.whatThisMayMean}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <Row>
          <Col lg={2}>
            <h5>Final Conclusion: </h5>
          </Col>
          <Col lg={10}>
            You have one or more values that are not normal as shown in red
            font. We recommend that you consult a doctor for an informed
            decision.
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HistoryOfTest;
