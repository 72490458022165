import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import Diet from "./Diet";
import DietIntervention from "./DietIntervention";
import { useLocation } from "react-router-dom";

const Nutrition = () => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    if (location.state && location.state.tabIndex !== undefined) {
      setCurrentTabIndex(location.state.tabIndex);
    }
    if (location.state?.selectedOrder) {
      setSelectedOrder(location.state.selectedOrder); // Set the order when navigating
    }
  }, [location.state]);
  const handleOrderSubmit = () => {
    setSelectedOrder(null);
  };
  return (
    <div style={{}}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Tab
            label="My Diet"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "end",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
          <Tab
            label="Diet Intervention"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <Diet></Diet>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <DietIntervention
              selectedOrder={selectedOrder}
              onSubmit={handleOrderSubmit}
            ></DietIntervention>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Nutrition;
