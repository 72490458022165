import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis } from "recharts";
import { IconTrendDown } from "../../../components/IconTrendDown";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { getHospitalVisitYearlyApi } from "../../../constants/apiList";
import CustomXAxisTick from "../../../shared/CustomXAxisTick";
import calculateTrendLine, { usePagination } from "../../../util/utils";
import { toast } from "react-toastify";

const HospitalVisitGraphYearly = ({ setCurrentTrendData }) => {
  const [visitsDetails, setVisitsDetails] = useState();
  const [visitYearly, setVisitYearly] = useState([]);
  const [highlightedLabel, setHighlightedLabel] = useState(null);
  const { currentPage, totalPages, currentData, handleNextPage, handlePreviousPage } = usePagination(visitYearly, (item = 4));
  const trendLineData = calculateTrendLine(currentData, "year", "monthlyVisits");

  const getHospitalVisitYearly = () => {
    AxiosInstance.get(`${getHospitalVisitYearlyApi}`)
      .then((response) => {
        setVisitYearly(response);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleAxisLabel = (event) => {
    const selectedValue = event.value;
    const result = currentData?.find((entry) => entry?.year === event?.value);
    if (result) {
      setVisitsDetails(result);
      setHighlightedLabel(selectedValue);
    }
  };

  useEffect(() => {
    if (visitYearly?.length > 0) {
      setVisitsDetails(visitYearly[visitYearly?.length - 1]);
      setHighlightedLabel(visitYearly[visitYearly?.length - 1]?.year);
      setCurrentTrendData(currentData);
    }
  }, [visitYearly]);

  useEffect(() => {
    getHospitalVisitYearly();
  }, []);

  return (
    <>
      <div className="graph-card-title">
        How often I'm Visiting hospital (<span className="month-year-as-of">{visitsDetails && visitsDetails?.year}</span>)
      </div>
      <div className="legend-data-wrapper">
        <div className="legend-data">
          <p>Visit Count : {visitsDetails?.monthlyVisits}</p>
          <p>
            Visit Percent : <span style={{ color: `${visitsDetails?.ytmPctChangePercent > 0 ? "green" : "red"}` }}>{visitsDetails?.ytmPctChangePercent}</span>{" "}
            <IconTrendDown iconTrendDown={`${visitsDetails?.ytmPctChangePercent > 0 ? "/img/icon-trend-down.png" : "/img/icon-trend-up-red.png"}`} />
            <span className="compare-to">Compare to {visitsDetails && visitsDetails?.year - 1}</span>
          </p>
        </div>
      </div>
      <div style={{ margin: "0 auto", width: "100%", display: "flex", alignItems: "end" }}>
        <IconButton
          onClick={handlePreviousPage}
          disabled={currentPage - 1 < 0}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "10px", fontSize: "10px" }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <div className="chart-wrapper">
          <ResponsiveContainer
            width="100%"
            height={150}
          >
            <LineChart
              data={trendLineData}
              margin={{
                top: 25,
                right: 28,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 5" />
              <XAxis
                dataKey="year"
                interval={"preserveStartEnd"}
                cursor="pointer"
                onClick={handleAxisLabel}
                tick={
                  <CustomXAxisTick
                    highlightedLabel={highlightedLabel}
                    boxWidth={30}
                  />
                }
              />
              <Line
                dataKey="monthlyVisits"
                stroke="red"
                strokeWidth={2}
                label={{ position: "top" }}
                dot={true}
              />
              <Line
                dataKey="TrendLine"
                stroke="green"
                strokeWidth={2}
                strokeDasharray="10 5"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage + 1 >= totalPages}
          sx={{ padding: "5px", minWidth: "auto", marginBottom: "10px", fontSize: "10px" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <hr />
      <div className="graph-footer">
        <p className="graph-note">Note : Please click on the year to see the data</p>
      </div>
    </>
  );
};

export default HospitalVisitGraphYearly;
