/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconFilledOverview } from "../IconFilledOverview";
import { IconOverview } from "../IconOverview";
import "./style.css";
import { NavLink } from "react-router-dom";

export const NavsOverview = ({ property1, style, frameStyle, iconFilledOverviewIconFilledOverview = "/img/icon-filled-overview.png", overviewStyle }) => {
  return (
    <div
      className={`navs-overview ${property1}`}
      style={style}
    >
      <div className="menu-item">
        <div
          className="frame"
          style={frameStyle}
        >
          <div className="div">
            {property1 === "default" && <IconFilledOverview iconFilledOverview={iconFilledOverviewIconFilledOverview} />}

            {property1 === "variant-2" && <IconOverview iconOverview="/img/icon-overview.png" />}

            <NavLink
              to="/overview"
              className="text-wrapper-106 menu-item overview"
            >
              Quick Overview
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

NavsOverview.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconFilledOverviewIconFilledOverview: PropTypes.string,
};
